import React from 'react';

import TTable, { TfilterTypes } from '../../components/TTable/TTable';

const columns = [
    { key: 'name', name: 'Name', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
    { key: 'description', name: 'Description', resizable: true }
];

const renderTable = (data, onEditRowClick, onDeleteRowClick) => {

    const rows = data.map(b => { return { id: b.id, name: b.name, description: b.description } });

    return (
        <TTable
            showEditRow
            onEditRowClick={onEditRowClick}
            showDeleteRow
            onDeleteRowClick={onDeleteRowClick}
            showFilterButtons
            rows={rows}
            columns={columns}
        />
    );
};

const BookList = ({ data, onEditRowClick, onDeleteRowClick }) => {

    return (
        <div>
            {renderTable(data, onEditRowClick, onDeleteRowClick)}
        </div>
    );
};

export default BookList;