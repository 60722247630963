import React from 'react';
import './TContentContainer.css';



const TContentContainer = props => {

    return (
        <React.Fragment>
            <div className="v-base-page">
                <div className="v-content-container">
                    <div className="card">
                        <div className="card-body">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            
        </React.Fragment>
    );
};

export default TContentContainer;