import React from 'react';
import VDatePicker from '../../../components/VDatePicker/VDatePicker';
import VDropdown from '../../../components/VDropdown/VDropdown';
import VFilterContainer from '../../../components/VFilterContainer/VFilterContainer';
import VTable from '../../../components/VTable/VTable';
import { AddStyles } from '../../../components/VTagInput/cusomized-react-tag-input/ReactTags';
import VTagInput from '../../../components/VTagInput/VTagInput';

class TestPnlSummary extends React.Component {
    directions = ['TR-BG', 'TR-GR', 'TR-GR-BG', 'GR-BG', 'BG-GR'];
    countries = ['TR', 'BG', 'GR']
    summaryTypes = ['LT', 'Daily']

    state = {
        selectedCurrency: { value: 'TRY', label: 'TRY' },
        selectedDataTypes: {},
    }

    getRandom() {
        return Math.floor(Math.random() * 10000) * [1, -1][Math.floor(Math.random() * 2)] + 1;
    }

    getDailyLiquidationTable() {
        return (
            <VTable
                title='Daily Liquidation'
                items={{
                    headers: [[...this.directions.map(d => { return d }), 'Total']],
                    values: [[...this.directions.map(d => {
                        return this.getRandom();
                    }), 'XX']]
                }}
            />
        );
    }

    getSummaryTable() {
        const items = {
            headers: [
                [this.state.selectedCurrency.label, '', '', '',
                ...this.summaryTypes.flatMap(s => this.countries.flatMap((c, cIdx) => this.directions.filter(d => d.includes(c)).map((d, dIdx) => (cIdx || dIdx) ? '' : s)))],
                ['', '', '', '',
                    ...this.summaryTypes.flatMap(s => this.countries.flatMap(c => this.directions.filter(d => d.includes(c)).map((d, dIdx) => dIdx ? '' : c)))],
                ['', 'Sum of Borders', '', '',
                    ...this.summaryTypes.flatMap(s => this.countries.flatMap(c => this.directions.filter(d => d.includes(c)).map(d => d)))],
                ['', 'Previous', 'Today', 'Difference',
                    ...this.summaryTypes.flatMap(s => this.countries.flatMap(c => this.directions.filter(d => d.includes(c)).map(d => '')))],
            ],
            values: []
        };

        for (let i = 0; i < 12; i++) {
            const row = [];
            row.push(i + 1);
            row.push(this.getRandom());
            row.push(this.getRandom());
            row.push(this.getRandom());
            this.summaryTypes.forEach(s => {
                this.countries.forEach(c => {
                    this.directions.filter(d => d.includes(c)).forEach(d => {
                        row.push(this.getRandom());
                    })
                })
            });

            items.values.push(row);
        }

        return (
            <VTable
                title='Summary'
                items={items}
                showTotal
                readonlyColumnIndices={[0]}
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
            />
        );
    }

    getFilters() {
        return (
            <VFilterContainer>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Start Date
                                                </div>
                                        <div>
                                            <VDatePicker
                                                selectedDate={this.state.selectedStartDate}
                                                onSelectedDateChange={(selectedStartDate) => this.setState({ selectedStartDate })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            End Date
                                                </div>
                                        <div>
                                            <VDatePicker
                                                selectedDate={this.state.selectedEndDate}
                                                onSelectedDateChange={(selectedEndDate) => this.setState({ selectedEndDate })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Grouping
                                                </div>
                                        <div>
                                            <VDropdown
                                                width='large'
                                                options={[
                                                    { value: 'Daily', label: 'Daily' },
                                                    { value: 'Weekly', label: 'Weekly' },
                                                    { value: 'Monthly', label: 'Monthly' }
                                                ]}
                                                value={this.state.selectedGrouping}
                                                onSelectedOptionChange={(selectedGrouping) => {
                                                    this.setState({ selectedGrouping })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Currency
                                                </div>
                                        <div>
                                            <VDropdown
                                                width='large'
                                                options={[
                                                    { value: 'TRY', label: 'TRY' },
                                                    { value: 'EUR', label: 'EUR' }
                                                ]}
                                                value={this.state.selectedCurrency}
                                                onSelectedOptionChange={(selectedCurrency) => {
                                                    this.setState({ selectedCurrency })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Border
                                                </div>
                                        <div className="v-filter-taginput">
                                            <VTagInput
                                                addStyle={AddStyles.Textbox}
                                                placeholder='Add Border'
                                                tags={this.state.selectedDirections}
                                                onOrderChanged={this.onselectedDirectionsChanged}
                                                onTagDeleted={this.onselectedDirectionRemoved}
                                                onInternalAddition={this.addselectedDirectionTag}
                                                suggestions={this.state.directionSuggestions}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Counter Party
                                                </div>
                                        <div className="v-filter-taginput">
                                            <VTagInput
                                                addStyle={AddStyles.Textbox}
                                                placeholder='Add Border'
                                                tags={this.state.selectedDirections}
                                                onOrderChanged={this.onselectedDirectionsChanged}
                                                onTagDeleted={this.onselectedDirectionRemoved}
                                                onInternalAddition={this.addselectedDirectionTag}
                                                suggestions={this.state.directionSuggestions}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='col'>
                                <div className="v-filter-group">
                                    <div className="v-filter-label v-label">
                                        Display
                                        </div>
                                </div>
                                {
                                    this.summaryTypes.map(d => {
                                        return (
                                                <div key={d}  className="col v-checkBox checkbox">
                                                    <label>
                                                        <input type="checkbox"
                                                            checked={this.state.selectedDataTypes[d]}
                                                            onChange={() => {
                                                                if (this.state.selectedDataTypes[d])
                                                                    this.setState({ selectedDataTypes: { ...this.state.selectedDataTypes, d: !this.state.selectedDataTypes[d] } });
                                                                else
                                                                    this.setState({ selectedDataTypes: { ...this.state.selectedDataTypes, d: true } });

                                                            }} />
                                                        {d}
                                                    </label>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="v-filter-buttons">
                    <button
                        className="btn v-cancel-button v-filter-button">
                        <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                            </button>
                    <button
                        tabIndex={0}
                        className="btn v-button v-filter-button">
                        <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                </div>
            </VFilterContainer>
        )
    }

    getContent() {
        return (
            <div className='v-infinite-width v-table-list'>
                <div className='container'>
                    <div className='row'>
                        {this.getDailyLiquidationTable()}
                    </div>
                    <div className='row'>
                        {this.getSummaryTable()}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.getFilters()}
                {this.getContent()}
            </React.Fragment>
        )
    }
}

export default TestPnlSummary;