import React from 'react';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

import './TRadioButton.css';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        display: "inline-block",
        fontSize: 10,
        
        alignItems: "center",
        margin: 0,

    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});

const StyledRadio = ((props) => {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
});

class TRadioButton extends React.Component {
    handleChange = (event) => {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    };

    render() {
        return (
            <Paper component="ul" className="radio-items">
                <Typography  className="radio-group-title" color="textSecondary" gutterBottom>
                    {this.props.title}
                </Typography>
                <RadioGroup
                    className="radio-item"
                    aria-label="gender"
                    name="customized-radios"
                    value={this.props.selected}
                    onChange={this.handleChange}
                >
                    { 
                       
                        this.props.items.map(item => (
                            <FormControlLabel
                                style={{marginLeft: "10px", marginRight: "10px"}}
                                key={`${item.value}_from`}
                                value={item.value}
                                control={<StyledRadio key={`${item.value}_styled`} />} label={item.label}
                                disabled={item.value === this.props.selected ? false : this.props.readOnly}
                            />
                        ))
                    }
                </RadioGroup>
            </Paper>
        );
    }
}

export default TRadioButton;