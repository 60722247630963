import React from 'react';
import { connect } from 'react-redux';

import './VUserInfo.css';
import { logout } from '../../actions';
import VSpinner from '../VSpinner/VSpinner';
import { etrmSubRoute } from '../../etrm/utils/Routes';


class VUserInfo extends React.Component {
    state = { showSpinner: false };

    create_logout_action = (event) => {
        this.setState({ showSpinner: true });
        event.preventDefault();
        this.props.logout();
    }

    componentWillUnmount() {
        this.setState({ showSpinner: false });
    }

    render() {
        if (this.state.showSpinner) {
            return <VSpinner show={true}/>;
        }

        return (
            <div className="v-user-info-content v-gray">
                <div className="v-external-link" onClick={() =>  window.open(etrmSubRoute)}>
                    <i aria-hidden="true">
                        <span className="v-font">
                            {"ETRM"}
                        </span>
                    </i>
                </div>
                <div className="v-user-detail">
                    <i className="fa fa-user-circle-o" aria-hidden="true">
                        <span className="v-font">
                            {this.props.userName}
                        </span>
                    </i>
                </div>
                <div className="v-user-log-out">
                    <button onClick={this.create_logout_action}>
                        <i className="fa fa-sign-out v-white v-user-log-out-icon" aria-hidden="true">
                            &nbsp;Log out
                        </i>
                    </button>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        userName: state.auth.userName
    };
};

export default connect(mapStateToProps, { logout })(VUserInfo);