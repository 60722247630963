import React from 'react';

import './VCategory.css';

const VCategory = props => {
    return (
        <div className="v-category">
            <div className="v-category-content">
                {props.children}
            </div>
        </div>
    );
};

export default VCategory;