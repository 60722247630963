import './LTAuctionAllocations.css';

import React from 'react';
import { isEmpty, uniq, orderBy, isEqual, intersection } from 'lodash';

import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import VTable from '../../components/VTable/VTable';
import VVerticalTabs, { VVerticalTab } from '../../components/VVerticalTabs/VVerticalTabs';
import VAccordion, { VAccordionItem } from '../../components/VAccordion/VAccordion';
import { getTwoDaysAheadForGivenDate, extractDate } from '../../helpers/generalHelper';
import { handleApiError, alertError, alertSuccess, alertWarning, specifyErrorMessage } from '../../helpers/errorHelper';
import { portalMessages } from '../../helpers/portalMessages';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import AddLTAllocationModal from '../../modals/AddLTAllocationModal/AddLTAllocationModal';
import ConfirmationModal from '../../modals/ConfirmationModal/ConfirmationModal';
import { getTSO, getLTAuctionAllocation, saveLTAuctionAllocation, deleteLTAuctionAllocation } from '../../apis/vitusApi';


class LTAuctionAllocations extends React.Component {

    spinner = new SpinnerManager(history.location.pathname);
    periodTypes = { '2': 'Monthly', '3': 'Yearly' };
    verticalTableSubColumns = { 'id': 'ID', 'capacity': 'Capacity' };
    valueIsEmpty = (v) => v === "";
    cParties = [];
    unusedColumnSign = '*';
    partialAllocationClasses = ['v-colored-allocation-v1', 'v-colored-allocation-v2'];
    addLTAllocationModalRef = React.createRef();
    verticalTabDateColumnClass = { 0: "v-column-narrow-bold" };
    maxPrice = 500;
    FTRcParty = 'Vitus';
    allocationTypes = { 'FTR': 'FTR', 'LT': 'LT' };
    LTcompany = { value: "Vitus", label: "Vitus Commodities" };

    tabTableColumns = [
        { label: 'Order', index: 0, id: 'order' },
        { label: 'Direction', index: 1, id: 'direction', },
        { label: 'TSO', index: 2, id: 'tso' },
        { label: 'Company', index: 3, id: 'company' },
        { label: 'Period Type', index: 4, id: 'pType' },
        { label: 'Period', index: 5, id: 'period' },
        { label: 'Capacity ID', index: 6, id: 'capacityId' },
        { label: 'Capacity (MWh)', index: 7, id: 'capacity' },
        { label: 'Price', index: 8, id: 'price' },
        { label: 'Currency', index: 9, id: 'currency' },
        { label: 'Settlement', index: 10, id: 'settlement' }
    ];

    tabTableReadOnlyColumns = this.tabTableColumns
        .filter(i => i.id === 'direction' || i.id === 'tso'
            || i.id === 'pType' || i.id === 'currency' || i.id === 'company' || i.id === 'settlement').map(i => i.label);

    tabTCATTableReadOnlyColumns = this.tabTableColumns
        .filter(i => i.id === 'direction' || i.id === 'tso'
            || i.id === 'pType' || i.id === 'order' || i.id === 'currency' || i.id === 'company' || i.id === 'settlement').map(i => i.label);

    tabFTRTableReadOnlyColumns = this.tabTableColumns
        .filter(i => i.id === 'capacityId' || i.id === 'direction'
            || i.id === 'tso' || i.id === 'pType' || i.id === 'order' || i.id === 'currency' || i.id === 'company' || i.id === 'settlement').map(i => i.label);

    tabTableIndexes = {
        "order": this.tabTableColumns.find(i => i.id === 'order').index,
        "direction": this.tabTableColumns.find(i => i.id === 'direction').index,
        "tso": this.tabTableColumns.find(i => i.id === 'tso').index,
        "pType": this.tabTableColumns.find(i => i.id === 'pType').index,
        "period": this.tabTableColumns.find(i => i.id === 'period').index,
        "capacityId": this.tabTableColumns.find(i => i.id === 'capacityId').index,
        "capacity": this.tabTableColumns.find(i => i.id === 'capacity').index,
        "price": this.tabTableColumns.find(i => i.id === 'price').index,
        "currency": this.tabTableColumns.find(i => i.id === 'currency').index,
        "company": this.tabTableColumns.find(i => i.id === 'company').index,
        "settlement": this.tabTableColumns.find(i => i.id === 'settlement').index
    };

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED,
        MissingParameter: {
            'requested_date': portalMessages.INVALIED_REQUESTED_DATE
        },
        InvalidData: {
            'period_end_date': portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_PERIOD_INPUT,
            'period_start_date': portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_PERIOD_INPUT,
            'invalid_period': portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_PERIOD_ORDER
        }
    };

    state = {
        allocationDate: getTwoDaysAheadForGivenDate(new Date()),
        showNeedRefreshModal: false,
        showAddAllocationModal: false,
        disableAllTabs: false,
        selectedTab: '',
        latestRequestedFilter: {},
        activeFilter: [],
        tabTables: {},
        verticalTabTables: {},
        directionMonthlyTSOs: {},
        directionYearlyTSOs: {},
        cPartyInfo: {},
        readOnlyRows: {},
        allocationIDs: {},
        partialAllocations: {},
        showRowDeleteModal: false,
        rowIndexToDelete: null
    }

    componentDidMount() {

        this.spinner.showSpinner('getTSO');

        getTSO()
            .then(response => {

                if (response.data.success) {
                    this.setTSO(response.data.success);
                    this.callGetLTAuctionAllocationAsync(this.createGetLTAllocationRequestBody(this.state.allocationDate))
                }
                else {
                    this.setState({ showNeedRefreshModal: true });
                }

                if (response.data.error)
                    this.showErrorMessage(response.data.error);

            }, error => {
                handleApiError(error);
                this.setState({ showNeedRefreshModal: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('getTSO');
            });

    }

    showErrorMessage(error) {
        const { message } = specifyErrorMessage(error, this.errorMessages);

        if (message)
            alertError(message);
    }

    setTSO(data) {

        let cPartyInfo = {};

        data.tso_list.forEach(cParty => {

            cPartyInfo[cParty.counter_party] = {
                directions: [],
                periods: [],
                monthly_tso_list: {},
                yearly_tso_list: {},
                settlement_list: {}
            }

            cParty.directions.forEach(direction => {
                cPartyInfo[cParty.counter_party].settlement_list[direction.direction] = direction.settlement

                if (this.periodTypes[`${direction.period_type}`] === 'Monthly') {
                    cPartyInfo[cParty.counter_party].directions.push(direction.direction);
                    cPartyInfo[cParty.counter_party].periods.push(direction.period_type);

                    if (!cPartyInfo[cParty.counter_party].monthly_tso_list[direction.direction])
                        cPartyInfo[cParty.counter_party].monthly_tso_list[direction.direction] = [];

                    if (!cPartyInfo[cParty.counter_party].monthly_tso_list[direction.direction].includes({ value: direction.tso, label: direction.tso })) {
                        cPartyInfo[cParty.counter_party].monthly_tso_list[direction.direction].push({ value: direction.tso, label: direction.tso });
                    }
                }

                else if (this.periodTypes[`${direction.period_type}`] === 'Yearly') {
                    cPartyInfo[cParty.counter_party].directions.push(direction.direction);
                    cPartyInfo[cParty.counter_party].periods.push(direction.period_type);

                    if (!cPartyInfo[cParty.counter_party].yearly_tso_list[direction.direction])
                        cPartyInfo[cParty.counter_party].yearly_tso_list[direction.direction] = [];

                    if (!cPartyInfo[cParty.counter_party].yearly_tso_list[direction.direction].includes({ value: direction.tso, label: direction.tso })) {
                        cPartyInfo[cParty.counter_party].yearly_tso_list[direction.direction].push({ value: direction.tso, label: direction.tso });
                    }

                }

            });
        });

        Object.keys(cPartyInfo).forEach(cParty => {
            cPartyInfo[cParty].directions = orderBy(uniq(cPartyInfo[cParty].directions)).map(i => { return { value: i, label: i } });
            cPartyInfo[cParty].periods = orderBy(uniq(cPartyInfo[cParty].periods)).map(i => { return { value: i, label: this.periodTypes[i] } });
        });

        this.cParties = orderBy(Object.keys(cPartyInfo));

        this.setState({ cPartyInfo, selectedTab: this.cParties[0] });
    }

    onShowButtonClick = () => {

        if (!this.state.allocationDate)
            alertError(portalMessages.DATE_SELECTION);
        else
            this.callGetLTAuctionAllocationAsync(this.createGetLTAllocationRequestBody(this.state.allocationDate));
    }

    isCounterPartyTCAT(cParty) {
        return cParty === 'TCAT';
    }

    isCounterPartyFTR(cParty) {
        return cParty === this.FTRcParty;
    }

    callGetLTAuctionAllocationAsync = async (requestBody) => {

        this.spinner.showSpinner('getLTAuctionAllocation');

        try {

            const response = await getLTAuctionAllocation(requestBody);

            if (response.data.success) {

                this.setState({
                    latestRequestedFilter: { ...requestBody },
                    activeFilter: [
                        { label: "Date", value: requestBody.filter.requested_date },
                    ]
                });

                this.setTabTableData(response.data.success);

            } else if (response.data.error) {

                if (response.data.error.default.NotFound) {

                    this.setState({
                        latestRequestedFilter: { ...requestBody },
                        activeFilter: [
                            { label: "Date", value: requestBody.filter.requested_date },
                        ],
                        tabTables: {},
                        verticalTabTables: {},
                        readOnlyRows: {},
                        allocationIDs: {},
                        partialAllocations: {}
                    });

                    alertWarning(portalMessages.LT_AUCTION_ALLOCATIONS.NO_LT_ALLOCATION_FOUND);

                } else {
                    this.showErrorMessage(response.data.error);
                }

            } else {
                alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
            }

        } catch (error) {
            handleApiError(error);

        } finally {
            this.spinner.hideSpinner('getLTAuctionAllocation');
        }

    }

    createGetLTAllocationRequestBody(dateToRequest) {
        return {
            filter: {
                requested_date: extractDate(dateToRequest),
            }
        };
    }

    setTabTableData(allAllocations) {

        this.spinner.showSpinner('setTabTableData');

        let tabTables = {};
        let readOnlyRows = {};

        let TCATAllocations = allAllocations.find(i => this.isCounterPartyTCAT(i.counter_party));

        if (TCATAllocations)
            tabTables['TCAT'] = this.convertJsonToTabTable('TCAT', orderBy(TCATAllocations.data, ['direction', 'period_type', 'period_start_date'], ['asc', 'desc', 'asc']))

        this.cParties.forEach(cParty => {

            let found = allAllocations.find(i => i.counter_party === cParty);

            if (found) {

                if (!this.isCounterPartyTCAT(cParty)) {
                    tabTables[cParty] = this.convertJsonToTabTable(cParty, orderBy(found.data, ['direction', 'allocation_order', 'period_type', 'period_start_date'], ['asc', 'asc', 'desc', 'asc']));

                    if (!this.isCounterPartyFTR(cParty) && TCATAllocations && this.checkCounterPartyContainsTCATDirections(cParty)) {

                        let lastIndexOfTable = tabTables[cParty].values.length - 1;
                        let TCATRowNumberToAdd = tabTables['TCAT'].values.length;

                        readOnlyRows[cParty] = [];

                        for (let i = 1; i <= TCATRowNumberToAdd; i++)
                            readOnlyRows[cParty].push(lastIndexOfTable + i);

                        tabTables[cParty].values = [...tabTables[cParty].values, ...tabTables['TCAT'].values];
                    }
                }


            } else if (!this.isCounterPartyFTR(cParty) && TCATAllocations && this.checkCounterPartyContainsTCATDirections(cParty)) {
                tabTables[cParty] = tabTables['TCAT'];

                if (!this.isCounterPartyTCAT(cParty)) {

                    let TCATRowNumberToAdd = tabTables['TCAT'].values.length;
                    readOnlyRows[cParty] = [];

                    for (let i = 0; i < TCATRowNumberToAdd; i++)
                        readOnlyRows[cParty].push(i);
                }

            } else {
                tabTables[cParty] = null;
                readOnlyRows[cParty] = [];
            }

        });

        this.setState({ tabTables, readOnlyRows });

        this.setPartialAllocationIndexes(tabTables);

        this.setVerticalTabTables(tabTables);

        this.spinner.hideSpinner('setTabTableData');
    }

    checkCounterPartyContainsTCATDirections(cParty) {

        let directions = this.state.cPartyInfo[cParty].directions.map(i => i.value);

        return directions.includes('TR-BG') || directions.includes('BG-TR');
    }

    convertJsonToTabTable(cParty, data) {

        let headers = this.tabTableColumns.map(i => i.label);
        let values = [];

        let i = 0;
        let currentAllocationIDs = {};
        currentAllocationIDs[cParty] = [];

        data.forEach(allocation => {

            values.push([
                allocation.allocation_order ? allocation.allocation_order : this.unusedColumnSign,
                allocation.direction,
                allocation.tso,
                allocation.company === this.LTcompany.value ? this.LTcompany.label : allocation.company,
                this.periodTypes[allocation.period_type],
                `${new Date(allocation.period_start_date).toLocaleDateString('en-GB')} - ${new Date(allocation.period_end_date).toLocaleDateString('en-GB')}`,
                allocation.capacity_id ? allocation.capacity_id : (this.isCounterPartyFTR(cParty) ? this.unusedColumnSign : '-'),
                allocation.capacity,
                allocation.price,
                allocation.currency,
                allocation.settlement
            ]);

            currentAllocationIDs[cParty].push({ index: i, ltAllocationId: allocation.lt_allocation_id });

            i++;
        });

        this.setState({ allocationIDs: { ...this.state.allocationIDs, ...currentAllocationIDs } });

        return {
            headers,
            values
        };
    }

    setPartialAllocationIndexes(tabTables) {

        let partialAllocations = {};

        Object.keys(tabTables).forEach(cParty => {

            if (tabTables[cParty]) {

                partialAllocations[cParty] = [];

                let i = 0;
                let partialKeys = {};

                tabTables[cParty].values.forEach(rowData => {

                    let dates = this.convertPeriodToSingleDates(rowData[this.tabTableIndexes.period]);

                    if (this.periodTypes[`${this.tabTableIndexes.pType}`] === 'Monthly') {

                        let monthlyKey = `${rowData[this.tabTableIndexes.direction]}-${rowData[this.tabTableIndexes.tso]}-${rowData[this.tabTableIndexes.pType]}-${dates.startDate.month}`;

                        if (!partialKeys[monthlyKey]) {
                            partialKeys[monthlyKey] = [];
                            partialKeys[monthlyKey].push(i);

                        } else {
                            partialKeys[monthlyKey].push(i);
                        }
                    }

                    if (this.periodTypes[`${this.tabTableIndexes.pType}`] === 'Yearly') {

                        let yearlyKey = `${rowData[this.tabTableIndexes.direction]}-${rowData[this.tabTableIndexes.tso]}-${rowData[this.tabTableIndexes.pType]}-${dates.startDate.year}`;

                        if (!partialKeys[yearlyKey]) {
                            partialKeys[yearlyKey] = [];
                            partialKeys[yearlyKey].push(i);

                        } else {
                            partialKeys[yearlyKey].push(i);
                        }
                    }

                    i++;
                });

                Object.keys(partialKeys).forEach(key => {

                    if (partialKeys[key].length !== 1)
                        partialAllocations[cParty].push([...partialKeys[key]]);
                })

            }
        });

        this.setState({ partialAllocations });
    }


    setVerticalTabTables(tabTables) {

        this.spinner.showSpinner('setVerticalTabTables');

        let verticalTabTables = {};

        Object.keys(tabTables).forEach(cParty => {

            if (!tabTables[cParty]) {
                verticalTabTables[cParty] = null;

            } else {

                let counterPartyData = {};

                tabTables[cParty].values.forEach(rowValues => {

                    // Vertical table data is grouped by direction 
                    if (!counterPartyData[rowValues[this.tabTableIndexes.direction]])
                        counterPartyData[rowValues[this.tabTableIndexes.direction]] = [];

                    // Find out the days of current period
                    let { startDate, endDate } = this.convertPeriodToSingleDates(rowValues[this.tabTableIndexes.period]);

                    let daysAsEpochTimes = this.getDaysBetweenTwoDates(startDate, endDate);

                    counterPartyData[rowValues[this.tabTableIndexes.direction]].push(
                        {
                            "daysAsEpochTimes": daysAsEpochTimes,
                            "tso": rowValues[this.tabTableIndexes.tso],
                            "pType": rowValues[this.tabTableIndexes.pType],
                            "capacityId": rowValues[this.tabTableIndexes.capacityId],
                            "capacity": rowValues[this.tabTableIndexes.capacity],
                            "allocation_order": rowValues[this.tabTableIndexes.order]
                        }
                    );

                });

                verticalTabTables[cParty] = this.convertJsonToVerticalTable(counterPartyData, cParty);
            }
        });

        this.setState({ verticalTabTables }, () => this.spinner.hideSpinner('setVerticalTabTables'));
    }

    convertJsonToVerticalTable(counterPartyData) {

        let tablesOfDirections = {};

        Object.keys(counterPartyData).forEach(direction => {

            // Create a table for each direction in a counter party
            let headers = [[''], ['']];
            let values = [];

            let datesOfDirection = [];
            counterPartyData[direction].forEach(i => datesOfDirection.push(...i.daysAsEpochTimes));

            let tableDateColumnValues = orderBy(uniq(datesOfDirection));
            tableDateColumnValues.forEach(epochTime => values.push([epochTime]));

            let tsoHeadersOfAdirection = new Set();

            //  A TSO may includes more than one of the same id which corresponds some periods. These periods may have common days.
            // This object represents ouf of these common days if exists.
            let outOfCommonDays = [];

            counterPartyData[direction].forEach(data => {

                // Add first TSO to table
                if (!tsoHeadersOfAdirection.size) {

                    tsoHeadersOfAdirection.add(data.tso);

                    this.createHeader(headers, data.tso);

                    this.addDataToVerticalTable(values, data);

                } else {
                    // Check if previous TSO is same
                    if (tsoHeadersOfAdirection.has(data.tso)) {

                        let firstDayToBeAdded = data.daysAsEpochTimes[0];
                        let firstRowIndexToBeUsed = values.findIndex(rowValues => rowValues[0] === firstDayToBeAdded);

                        // colum indexes of current table's latest values 
                        let latestCapacityIndex = values[0].length - 1;
                        let latestIdIndex = latestCapacityIndex - 1;

                        let dayNumberToAdd = data.daysAsEpochTimes.length;

                        // if there is no common date between previous dates, use same column
                        if (values[firstRowIndexToBeUsed][latestCapacityIndex] === '') {

                            let rowIndex;

                            for (let i = 0; i < dayNumberToAdd; i++) {
                                rowIndex = firstRowIndexToBeUsed + i;

                                values[rowIndex][latestIdIndex] = data.capacityId;
                                values[rowIndex][latestCapacityIndex] = data.capacity;
                            }

                            //  If there is common date, check related date's capacity id. If capacity ids are same for
                            // the same day, add new capacity to the existing capacity, else add values to the outOfCommonDays.
                        } else {

                            let outOfCommonDaysObj = {
                                "daysAsEpochTimes": [],
                                "tso": data.tso,
                                "capacityId": data.capacityId,
                                "capacity": data.capacity
                            };

                            let rowIndex;

                            for (let i = 0; i < dayNumberToAdd; i++) {

                                rowIndex = firstRowIndexToBeUsed + i;

                                if (values[rowIndex][latestIdIndex] === data.capacityId)
                                    values[rowIndex][latestCapacityIndex] += data.capacity;
                                else
                                    outOfCommonDaysObj.daysAsEpochTimes.push(data.daysAsEpochTimes[i]);
                            }

                            if (outOfCommonDaysObj.daysAsEpochTimes.length)
                                outOfCommonDays.push(outOfCommonDaysObj);
                        }

                        // If previous TSO is not same
                    } else {

                        // Check if there are out of common days before adding new TSO 
                        if (outOfCommonDays.length) {
                            this.appendOutOfCommonDays(outOfCommonDays, headers, values);
                            outOfCommonDays = [];
                        }

                        tsoHeadersOfAdirection.add(data.tso);

                        this.createHeader(headers, data.tso);

                        this.addDataToVerticalTable(values, data);
                    }

                }

            });

            // Check if there are out of common days before process completed
            if (outOfCommonDays.length) {
                this.appendOutOfCommonDays(outOfCommonDays, headers, values);
                outOfCommonDays = [];
            }


            // "values" array contains all months value in a single table. To show direction data in accordions that grouped by month-year,
            // parse "values" array according to month-year combinations.
            tablesOfDirections[direction] = this.parseDirectionDataByMonth(headers, values);

        });

        return tablesOfDirections;
    }

    appendOutOfCommonDays(outOfCommonDays, headers, values) {

        let length = outOfCommonDays.length;

        if (length === 1) {

            this.createHeader(headers);

            this.addDataToVerticalTable(values, outOfCommonDays[0]);

        } else {

            // Add first data
            this.createHeader(headers);

            this.addDataToVerticalTable(values, outOfCommonDays[0]);

            for (let i = 1; i < length; i++) {

                let currentData = outOfCommonDays[i];
                let previousData = outOfCommonDays[i - 1];

                // Compare current days with previous days. If there is an intersection, create new subcolumns
                if (isEmpty(intersection(previousData.daysAsEpochTimes, currentData.daysAsEpochTimes))) {

                    let latestCapacityIndex = values[0].length - 1;
                    let latestIdIndex = latestCapacityIndex - 1;

                    values.forEach(rowValues => {

                        if (currentData.daysAsEpochTimes.includes(rowValues[0])) {
                            rowValues[latestIdIndex] = currentData.capacityId;
                            rowValues[latestCapacityIndex] = currentData.capacity;
                        }
                    });

                } else {

                    this.createHeader(headers);

                    this.addDataToVerticalTable(values, currentData);
                }
            }
        }

    }

    createHeader(headers, value = '') {
        // header
        headers[0].push(value);
        headers[0].push('');

        // subheader
        headers[1].push(this.verticalTableSubColumns.id);
        headers[1].push(this.verticalTableSubColumns.capacity);
    }

    addDataToVerticalTable(values, data) {

        values.forEach(rowValues => {

            if (data.daysAsEpochTimes.includes(rowValues[0])) {
                rowValues.push(data.capacityId);
                rowValues.push(data.capacity);

            } else {
                rowValues.push('');
                rowValues.push('');
            }
        });
    }

    parseDirectionDataByMonth(headers, allValues) {

        let totalRowNumber = allValues.length;
        let monthlyValues = {};

        monthlyValues['ALL'] = { headers, values: allValues };

        let i = 0;

        while (i < totalRowNumber) {

            // convert epoch to date
            let dateToCompare = new Date(allValues[i][0]);

            // month name - year
            let currentMonth = `${dateToCompare.toLocaleString('default', { month: 'long' })} ${dateToCompare.getFullYear()}`;

            let valuesOfAMonth = [];

            // 'en-GB' format -> dd/mm/YYYY
            allValues[i][0] = dateToCompare.toLocaleDateString('en-GB');

            valuesOfAMonth.push([...allValues[i]]);

            let j = i + 1;

            while (j < totalRowNumber) {

                let currentDate = new Date(allValues[j][0]);

                if (currentDate.getMonth() === dateToCompare.getMonth()) {

                    allValues[j][0] = currentDate.toLocaleDateString('en-GB');
                    valuesOfAMonth.push([...allValues[j]]);

                } else {
                    break;
                }

                i++;
                j++;
            }

            monthlyValues[currentMonth] = this.removeEmptyColumns(valuesOfAMonth, headers);

            i++;
        }

        return monthlyValues;
    }

    removeEmptyColumns(values, headers) {

        let totalColumnNumber = headers[0].length;

        // If table includes only 'date', 'id' and 'capacity' columns, no need to check out empty values
        if (totalColumnNumber === 3) {
            return { headers, values };

        } else {

            let emptyColumnIndexes = [];

            for (let i = 1; i < totalColumnNumber; i++) {
                if (this.getColumValues(values, i).every(this.valueIsEmpty))
                    emptyColumnIndexes.push(i);
            }

            if (isEmpty(emptyColumnIndexes))
                return { headers, values };


            let newHeaders = [[''], ['']];
            let newValues = [];
            let totalRowNumber = values.length;

            // get date column values
            for (let j = 0; j < totalRowNumber; j++) {
                newValues.push([]);
                newValues[j].push(values[j][0]);
            }

            let headersOfNonEmptyColumns = [];

            // get non empty column values and headers
            for (let index = 1; index < totalColumnNumber; index++) {

                if (!emptyColumnIndexes.includes(index)) {

                    let columnValues = this.getColumValues(values, index);

                    for (let k = 0; k < columnValues.length; k++)
                        newValues[k].push(columnValues[k]);

                    if (index % 2 === 1)
                        headersOfNonEmptyColumns.push(headers[0][index]);
                }

            }

            headersOfNonEmptyColumns.forEach(mainHeader => {
                newHeaders[0].push(mainHeader);
                newHeaders[0].push('');

                newHeaders[1].push(this.verticalTableSubColumns.id);
                newHeaders[1].push(this.verticalTableSubColumns.capacity);
            });

            return { headers: newHeaders, values: newValues };
        }

    }

    getColumValues(tableData, columnIndex) {
        let columnValues = [];
        let rowNumber = tableData.length;

        for (let i = 0; i < rowNumber; i++)
            columnValues.push(tableData[i][columnIndex]);

        return columnValues;
    }

    getDaysBetweenTwoDates(startDate, endDate) {
        let firstDayOfAMonth = new Date(this.state.allocationDate).setDate(1);

        if (startDate < firstDayOfAMonth)
            startDate = firstDayOfAMonth;

        const diffInMs = Math.abs(endDate - startDate);
        const diffInDay = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

        let epochTimes = [];

        let convertedStartDate = new Date(startDate);

        let year = convertedStartDate.getFullYear();
        let month = convertedStartDate.getMonth();
        let day = convertedStartDate.getDate();

        for (let i = 0; i <= diffInDay; i++)
            epochTimes.push(new Date(year, month, day++).getTime());

        return epochTimes;
    }


    validateAddAllocationModalInputs(inputs) {

        if (!inputs.direction) {
            alertError(portalMessages.SELECT_DIRECTION);
            return false;
        }

        if (!inputs.periodType) {
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.SELECT_PERIOD_TYPE);
            return false;
        }

        if (!inputs.tso) {
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.SELECT_TSO);
            return false;
        }

        if (!inputs.startDate) {
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.SELECT_START_DATE);
            return false;
        }

        if (!inputs.endDate) {
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.SELECT_END_DATE);
            return false;
        }

        if (inputs.capacity < 0) {
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.ENTER_CAPACITY);
            return false;
        }

        if (inputs.capacity < 0 || inputs.capacity > 1000) {
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_CAPACITY);
            return false;
        }

        if (inputs.startDate > inputs.endDate) {
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_PERIOD);
            return false;
        }

        if (!(this.isCounterPartyTCAT(this.state.selectedTab) || this.isCounterPartyFTR(this.state.selectedTab))) {

            if (!inputs.order) {
                alertError(portalMessages.LT_AUCTION_ALLOCATIONS.ENTER_ORDER);
                return false;
            }

            if (inputs.order <= 0 || inputs.order > 1000) {
                alertError(portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_ORDER);
                return false;
            }

        }

        if (!inputs.company) {
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.SELECT_COMPANY);
            return false;
        }

        let price = inputs.price;

        if (!price) {
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.ENTER_PRICE);
            return false;
        }

        if (price < 0 || price > this.maxPrice) {
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_PRICE.replace('[MAX_PRICE]', this.maxPrice));
            return false;
        }

        if (price.toString().indexOf('.') > -1 && price.toString().split('.')[1].length > 4) {
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_FRACTIONAL_PRICE);
            return false;
        }

        return true;
    }

    setAllocationType(cParty) {
        return cParty === this.FTRcParty ? this.allocationTypes.FTR : this.allocationTypes.LT;
    }

    createSaveAllocationRequestBody(data, requestType) {

        let body = {
            counter_party: this.state.selectedTab,
            data: []
        }

        if (requestType === 'add') {

            body.data.push({
                allocation_order: data.order ? data.order : null,
                direction: data.direction,
                tso: data.tso,
                period_type: data.periodType,
                period_start_date: extractDate(data.startDate),
                period_end_date: extractDate(data.endDate),
                capacity_id: data.capacityId,
                capacity: data.capacity,
                lt_allocation_id: null,
                price: data.price,
                currency: data.currency,
                settlement: data.settlement,
                allocation_type: this.setAllocationType(this.state.selectedTab),
                company: data.company
            });

        } else {

            let tableValues = [];
            let i = 0;

            if (!(this.isCounterPartyTCAT(this.state.selectedTab) || this.isCounterPartyFTR(this.state.selectedTab)))
                tableValues = this.getNonTCATTableValues(data.values);
            else
                tableValues = data.values;

            tableValues.forEach(rowData => {

                let { startDate, endDate } = this.convertPeriodToSingleDates(rowData[this.tabTableIndexes.period]);

                body.data.push(
                    {
                        allocation_order: rowData[this.tabTableIndexes.order] ? parseInt(`${rowData[this.tabTableIndexes.order]}`.trim()) : null,
                        direction: rowData[this.tabTableIndexes.direction],
                        tso: rowData[this.tabTableIndexes.tso],
                        period_type: parseInt(Object.keys(this.periodTypes).find(i => this.periodTypes[i] === rowData[this.tabTableIndexes.pType])),
                        period_start_date: extractDate(startDate),
                        period_end_date: extractDate(endDate),
                        capacity_id: rowData[this.tabTableIndexes.capacityId] ?
                            (rowData[this.tabTableIndexes.capacityId].trim() ? rowData[this.tabTableIndexes.capacityId].trim() : null) : null,
                        capacity: parseInt(`${rowData[this.tabTableIndexes.capacity]}`.trim()),
                        lt_allocation_id: this.state.allocationIDs[this.state.selectedTab].find(id => id.index === i).ltAllocationId,
                        price: rowData[this.tabTableIndexes.price],
                        currency: rowData[this.tabTableIndexes.currency],
                        settlement: rowData[this.tabTableIndexes.settlement],
                        allocation_type: this.setAllocationType(this.state.selectedTab),
                        company: (rowData[this.tabTableIndexes.company] === this.LTcompany.label ? this.LTcompany.value : rowData[this.tabTableIndexes.company])
                    }
                );

                i++;
            });
        }

        return body;
    }

    callSaveLTAllocationAsync = async (requestBody) => {

        this.spinner.showSpinner('saveLTAllocationService');

        let result = false;

        try {
            let response;

            try {
                response = await saveLTAuctionAllocation(requestBody);
            } catch (error) {
                handleApiError(error);
                return false
            }

            if (response.data.success) {
                result = true;

            } else if (response.data.error) {
                this.showErrorMessage(response.data.error);

            } else {
                alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
            }

            return result

        } finally {
            this.spinner.hideSpinner('saveLTAllocationService');
        }
    }


    onAddAllocation = async (modalInputs) => {

        this.spinner.showSpinner('onAddAlllocation');

        if (this.validateAddAllocationModalInputs(modalInputs)) {

            let result = await this.callSaveLTAllocationAsync(this.createSaveAllocationRequestBody(modalInputs, 'add'));

            if (result) {
                alertSuccess(portalMessages.LT_AUCTION_ALLOCATIONS.SUCCESSFUL_ADD_MESSAGE);
                this.setState({ showAddAllocationModal: false });
                this.callGetLTAuctionAllocationAsync(this.state.latestRequestedFilter);

                if (this.addLTAllocationModalRef.current)
                    this.addLTAllocationModalRef.current.clearStates();
            }
        }

        this.spinner.hideSpinner('onAddAlllocation');
    }

    convertPeriodToSingleDates(period) {

        try {
            let dates = period.split('-');

            let startDateParts = dates[0].trim().split('/');
            let startDate = {
                day: startDateParts[0],
                month: startDateParts[1],
                year: startDateParts[2]
            };

            let endDateParts = dates[1].trim().split('/');
            let endDate = {
                day: endDateParts[0],
                month: endDateParts[1],
                year: endDateParts[2]
            };


            return {
                startDate: new Date(`${startDate.month}/${startDate.day}/${startDate.year}`),
                endDate: new Date(`${endDate.month}/${endDate.day}/${endDate.year}`)
            };

        } catch (error) {
            return {}
        }

    }

    getNonTCATTableValues(allTableValues) {
        let values = [];

        allTableValues.forEach(rowData => {

            if (rowData[this.tabTableIndexes.order] !== this.unusedColumnSign)
                values.push([...rowData]);
        });

        return values;
    }

    validateEditedTableInputs(newTableData, counterParty) {

        let tableValues = [];
        let orders = [];

        if (!(this.isCounterPartyTCAT(counterParty) || this.isCounterPartyFTR(counterParty)))
            tableValues = this.getNonTCATTableValues(newTableData.values);
        else
            tableValues = newTableData.values;

        for (let rowValues of tableValues) {

            let capacity = rowValues[this.tabTableIndexes.capacity];

            if (`${capacity}`.trim() === '' || isNaN(capacity) || capacity < 0 || capacity > 1000) {
                alertError(portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_CAPACITY);
                return false;
            }

            let { startDate, endDate } = this.convertPeriodToSingleDates(rowValues[this.tabTableIndexes.period]);

            if (!startDate || !endDate || startDate === 'Invalid Date' || endDate === 'Invalid Date') {
                alertError(portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_PERIOD_INPUT);
                return false;
            }

            let price = rowValues[this.tabTableIndexes.price];

            if (!price) {
                alertError(portalMessages.LT_AUCTION_ALLOCATIONS.ENTER_PRICE);
                return false;
            }

            if (`${price}`.trim() === '' || isNaN(price) || price < 0 || price > this.maxPrice) {
                alertError(portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_PRICE.replace('[MAX_PRICE]', this.maxPrice));
                return false;
            }

            if (price.toString().indexOf('.') > -1 && price.toString().split('.')[1].length > 4) {
                alertError(portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_FRACTIONAL_PRICE);
                return false;
            }

            orders.push(rowValues[this.tabTableIndexes.order]);
        }

        if (!(this.isCounterPartyTCAT(counterParty) || this.isCounterPartyFTR(counterParty))) {

            for (let order of orders) {
                if (isNaN(order)) {
                    alertError(portalMessages.LT_AUCTION_ALLOCATIONS.INVALID_ORDER);
                    return false;
                }
            }

        }

        return true;
    }

    saveTableChangesAsync = async (newTableData, counterParty) => {

        this.spinner.showSpinner('saveTableChangesAsync');

        try {

            if (this.validateEditedTableInputs(newTableData, counterParty)) {

                let result = await this.callSaveLTAllocationAsync(this.createSaveAllocationRequestBody(newTableData, 'update'));

                if (result) {

                    alertSuccess(portalMessages.LT_AUCTION_ALLOCATIONS.SUCCESSFUL_UPDATE_MESSAGE);
                    this.setState({ showAddAllocationModal: false });
                    await this.callGetLTAuctionAllocationAsync(this.state.latestRequestedFilter);

                    return true;

                } else {
                    return false;
                }

            } else {
                return false;
            }

        } catch (error) {

            alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
            return false

        } finally {
            this.spinner.hideSpinner('saveTableChangesAsync');
        }

    }

    onAddAllocationButtonClick = () => this.setState({ showAddAllocationModal: true });

    onOpenEditMode = () => this.setState({ disableAllTabs: true });

    onCloseEditMode = () => this.setState({ disableAllTabs: false });

    hideAllocationModal = () => this.setState({ showAddAllocationModal: false })

    onTabChange = selectedTab => this.setState({ selectedTab });

    onAllocationDateChange = allocationDate => this.setState({ allocationDate });

    hideRowDeleteModal = () => this.setState({ showRowDeleteModal: false });

    showRowDeleteModal = rowIndexToDelete => this.setState({ showRowDeleteModal: true, rowIndexToDelete });

    confirmRowDelete = () => {
        this.deleteAllocation();

        this.setState({ showRowDeleteModal: false });
    }

    checkTableHasOwnData(cParty, readOnlyRowIndices, tableData) {
        // If table has only TCAT data, hiddens edit and import buttons
        if (!this.isCounterPartyTCAT(cParty)) {
            if (readOnlyRowIndices && tableData)
                return readOnlyRowIndices.length !== tableData.values.length;
            else
                return true;
        }

        return true;
    }

    callDeleteLTAllocationAsync = async (requestBody) => {

        this.spinner.showSpinner('deleteLTAllocationService');

        let result = false;

        try {
            let response;

            try {
                response = await deleteLTAuctionAllocation(requestBody);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success)
                result = true;
            else
                alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);

            return result;

        } finally {
            this.spinner.hideSpinner('deleteLTAllocationService');
        }
    }

    deleteAllocation = async () => {

        this.spinner.showSpinner('deleteAllocationClick');

        let lt_allocation_id = this.state.allocationIDs[this.state.selectedTab].find(i => i.index === this.state.rowIndexToDelete).ltAllocationId;

        let result = await this.callDeleteLTAllocationAsync({ lt_allocation_id });

        if (result) {
            alertSuccess(portalMessages.LT_AUCTION_ALLOCATIONS.SUCCESSFUL_DELETE_MESSAGE);
            this.callGetLTAuctionAllocationAsync(this.state.latestRequestedFilter);
        }

        this.spinner.hideSpinner('deleteAllocationClick');
    }

    onGetRowClass(cParty, rowIndex) {
        let partialAllocationIndexes = this.state.partialAllocations[cParty];

        if (partialAllocationIndexes) {

            for (let indexes of partialAllocationIndexes) {

                if (indexes.includes(rowIndex))
                    return this.partialAllocationClasses[(partialAllocationIndexes.findIndex(i => isEqual(i, indexes))) % 2];
            }
        }

        return '';
    }

    renderVerticalTabContent(counterParty, direction) {
        return Object.keys(this.state.verticalTabTables[counterParty][direction]).map(date => {
            return (
                <VAccordionItem key={date} eventkey={date} title={date}>
                    <VTable
                        title=""
                        simpleNumbers
                        customColumnClasses={this.verticalTabDateColumnClass}
                        items={this.state.verticalTabTables[counterParty][direction][date]}
                    />
                </VAccordionItem >
            );
        });
    }

    renderVerticalTabs(counterParty) {
        return Object.keys(this.state.verticalTabTables[counterParty]).map(direction => {
            return (
                <VVerticalTab key={direction} eventKey={direction} title={direction}>
                    <VAccordion key={direction}>
                        {this.renderVerticalTabContent(counterParty, direction)}
                    </VAccordion>
                </VVerticalTab>
            );
        });
    }

    renderFTRTabContent(counterParty) {
        let items = this.state.tabTables[counterParty];

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 v-lt-allocation-add-button">
                        <button className="btn v-button v-tab-button"
                            disabled={this.state.disableAllTabs}
                            onClick={this.onAddAllocationButtonClick}>
                            <i aria-hidden="true" className="fa fa-plus fa-fw" />
                                Add FTR
                        </button>
                    </div>
                </div>
                {
                    items ?
                        <React.Fragment>
                            <div className="row">
                                <div className="v-infinite-width v-lt-allocation-table">
                                    <VTable
                                        title=""
                                        simpleNumbers
                                        inputType='text'
                                        items={items}
                                        onError={(message) => this.showErrorMessage(message)}
                                        exportFileName={`FTR-${counterParty}-${this.state.latestRequestedFilter.filter.requested_date}`}
                                        readonlyRowIndices={this.state.readOnlyRows[counterParty]}
                                        onSaveChangesAsync={(newTableData) => this.saveTableChangesAsync(newTableData, counterParty)}
                                        readonlyColumns={this.tabFTRTableReadOnlyColumns}
                                        onOpenEditMode={this.onOpenEditMode}
                                        onCloseEditMode={this.onCloseEditMode}
                                        onDeleteRow={this.showRowDeleteModal}
                                        onGetRowClass={(rowIndex) => this.onGetRowClass(counterParty, rowIndex)}
                                        headerButtons={{
                                            showExportExcelButton: true,
                                            showImportExcelButton: this.checkTableHasOwnData(counterParty, this.state.readOnlyRows[counterParty], items),
                                            showEditButton: this.checkTableHasOwnData(counterParty, this.state.readOnlyRows[counterParty], items),
                                            showDeleteTableButton: false
                                        }}
                                        rowButtons={{
                                            showDeleteButton: true
                                        }}
                                    />
                                    {this.state.verticalTabTables && this.state.verticalTabTables[counterParty] ?
                                        <div className="v-lt-allocation-vertical-tabs">
                                            <VVerticalTabs>
                                                {this.renderVerticalTabs(counterParty)}
                                            </VVerticalTabs>
                                        </div>
                                        :
                                        <React.Fragment>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <div className="row">
                            <span className="v-lt-allocation-not-found">
                                No FTR
                            </span>
                        </div>
                }
            </div>
        );
    }

    renderTabContent(counterParty) {
        let items = this.state.tabTables[counterParty];

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 v-lt-allocation-add-button">
                        <button className="btn v-button v-tab-button"
                            disabled={this.state.disableAllTabs}
                            onClick={this.onAddAllocationButtonClick}>
                            <i aria-hidden="true" className="fa fa-plus fa-fw" />
                                Add Allocation
                        </button>
                    </div>
                </div>
                {
                    items ?
                        <React.Fragment>
                            <div className="row">
                                <div className="v-infinite-width v-lt-allocation-table">
                                    <VTable
                                        title=""
                                        simpleNumbers
                                        inputType='text'
                                        items={items}
                                        onError={(message) => this.showErrorMessage(message)}
                                        exportFileName={`LTAuctionAllocation-${counterParty}-${this.state.latestRequestedFilter.filter.requested_date}`}
                                        readonlyRowIndices={this.state.readOnlyRows[counterParty]}
                                        onSaveChangesAsync={(newTableData) => this.saveTableChangesAsync(newTableData, counterParty)}
                                        readonlyColumns={this.isCounterPartyTCAT(counterParty) ? this.tabTCATTableReadOnlyColumns : this.tabTableReadOnlyColumns}
                                        onOpenEditMode={this.onOpenEditMode}
                                        onCloseEditMode={this.onCloseEditMode}
                                        onDeleteRow={this.showRowDeleteModal}
                                        onGetRowClass={(rowIndex) => this.onGetRowClass(counterParty, rowIndex)}
                                        headerButtons={{
                                            showExportExcelButton: true,
                                            showImportExcelButton: this.checkTableHasOwnData(counterParty, this.state.readOnlyRows[counterParty], items),
                                            showEditButton: this.checkTableHasOwnData(counterParty, this.state.readOnlyRows[counterParty], items),
                                            showDeleteTableButton: false
                                        }}
                                        rowButtons={{
                                            showDeleteButton: true
                                        }}
                                    />
                                    {this.state.verticalTabTables && this.state.verticalTabTables[counterParty] ?
                                        <div className="v-lt-allocation-vertical-tabs">
                                            <VVerticalTabs>
                                                {this.renderVerticalTabs(counterParty)}
                                            </VVerticalTabs>
                                        </div>
                                        :
                                        <React.Fragment>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <div className="row">
                            <span className="v-lt-allocation-not-found">
                                No LT allocation for {counterParty}
                            </span>
                        </div>
                }
            </div>
        );
    }

    renderTabs() {
        return this.cParties.map(counterParty => {
            return (
                <VTab key={counterParty} eventKey={counterParty} title={counterParty}>
                    {this.isCounterPartyFTR(counterParty) ? this.renderFTRTabContent(counterParty) : this.renderTabContent(counterParty)}
                </VTab>
            );
        });
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="LT Auction Allocations">
                    <VFilterContainer showActiveFilter activeFilter={this.state.activeFilter}>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                            </div>
                            <div>
                                <VDatePicker
                                    selectedDate={this.state.allocationDate}
                                    onSelectedDateChange={this.onAllocationDateChange}
                                    disabled={this.state.disableAllTabs}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                disabled={this.state.disableAllTabs}
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={this.onShowButtonClick}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                        </div>
                    </VFilterContainer>
                    <VMainContainer>
                        <div className="v-lt-allocation-tabs">
                            <VTabs
                                onSelect={this.onTabChange}
                                disableAllTabs={this.state.disableAllTabs}
                                disabledAllMessage={portalMessages.TAB_SELECTION}>
                                {this.renderTabs()}
                            </VTabs >
                        </div>
                    </VMainContainer>
                </VContentContainer>
                {
                    this.state.showNeedRefreshModal &&
                    <NeedRefreshModal
                        show={this.state.showNeedRefreshModal}
                        message={portalMessages.COULD_NOT_GET_TSO}
                    />
                }
                { this.state.selectedTab && this.state.cPartyInfo && this.state.latestRequestedFilter.filter &&
                    <AddLTAllocationModal
                        ref={this.addLTAllocationModalRef}
                        show={this.state.showAddAllocationModal}
                        onCancel={this.hideAllocationModal}
                        onAdd={this.onAddAllocation}
                        counterParty={this.state.selectedTab}
                        counterPartyInfo={this.state.cPartyInfo}
                        latestRequestedDate={this.state.latestRequestedFilter.filter.requested_date}
                        LTcompany={this.LTcompany.value}
                    />
                }
                { this.state.showRowDeleteModal &&
                    <ConfirmationModal
                        show={this.state.showRowDeleteModal}
                        message={portalMessages.DELETE_ROW}
                        cancelText='Cancel'
                        onHide={this.hideRowDeleteModal}
                        onCancel={this.hideRowDeleteModal}
                        confirmText='Delete'
                        onConfirm={this.confirmRowDelete}
                    />
                }
            </React.Fragment>
        );
    }

}


export default LTAuctionAllocations;