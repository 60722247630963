export const endpoints = {
  getBook: "/getBook",
  saveBook: "/saveBook",
  deleteBook: "/deleteBook",
  getBookDetails: "getBookDetails",
  getMarkets: "/getMarkets",
  getMarketNames: "/getMarketNames",
  saveMarket: "/saveMarket",
  deleteMarket: "/deleteMarket",
  getCompanies: "/getCompanies",
  getCompaniesDetail: "getCompaniesDetail",
  deleteCompany: "/deleteCompany",
  saveCompany: "/saveCompany",
  getContractTypes: "/getContractTypes",
  saveContractType: "/saveContractType",
  deleteContractType: "/deleteContractType",
  getPeriods: "/getPeriods",
  getPeriodsDetail: "/getPeriodsDetail",
  savePeriod: "/savePeriod",
  deletePeriod: "/deletePeriod",
  getPriceCurve: "/getPriceCurve",
  getPriceCurvesDetail: "getPriceCurvesDetail",
  savePriceCurve: "/savePriceCurve",
  deletePriceCurve: "/deletePriceCurve",
  getContractCountry: "/getContractCountry",
  uploadFinancialMarkFile: "/uploadFinancialMarkFile",
  deleteFinancialMarkFile: "/deleteFinancialMarkFile",
  getCountry: "/getCountry",
  getCost: "/getCost",
  getCostsDetail: "/getCostsDetail",
  saveCost: "/saveCost",
  deleteCost: "/deleteCost",
  getContracts: "/getContracts",
  getContract: "/getContract",
  saveContract: "/saveContract",
  deleteContract: "/deleteContract",
  getBackOfficeNotificatons: "/getBackOfficeNotifications",
  getDocuments: "/getDocuments",
  saveDocument: "/saveDocument",
  deleteDocument: "/deleteDocument",
  getCollaterals: "/getCollaterals",
  saveCollateral: "/saveCollateral",
  deleteCollateral: "/deleteCollateral",
  getPnl: "/getPnl",
  getPnlV2: "/getPnlV2",
  importCompanies: "/importCompanies",
  importContracts: "/importContracts",
  getBilling: "/getBilling",
  getNotifications: "/getNotifications",
  markAsSeen: "/markAsSeen",
  getContractPnl: "/getContractPnl",
  getCompanySummaryPnl: "/getCompanySummaryPnl",
  downloadCompanySummaryPnl: "/downloadCompanySummaryPnl",
  getLTPosition: "/getLTPosition",
  downloadPosition: "/downloadPosition",
  getPosAndMark: "/getPosAndMark",
  downloadPosAndMark: "/downloadPosAndMark",
  getPnlStatus: "/getPnlStatus",
  getDistribution: "/getDistribution",
  saveDistribution: "/saveDistribution",
  sendCompanySummaryPnl: "/sendCompanySummaryPnl",
  sendPosAndMark: "sendPosAndMark",
  sendPosition: "sendPosition",
  getEtrmLiquidation:"/getEtrmLiquidation",
  downloadEtrmLiquidation:"/downloadEtrmLiquidation",
  sendEtrmLiquidation: "/sendEtrmLiquidation",
  getApiContracts: "/getApiContracts",
  getApiContract: "/getApiContract",
  deleteApiContract: "/deleteApiContract",
  getEtrmCurrencies: "/getEtrmCurrencies",
  downloadTotalSummaryPnl: "/downloadTotalSummaryPnl",
  sendTotalSummaryPnl: "/sendTotalSummaryPnl"
};
