import _ from 'lodash';

export const sortNominationColumns = (data) => {
    // Sort rules:
    // Groups should be listed with below rules respectively
    // Group 1. Columns starting with TR-BG
    // Group 2. Columns ending with BG-TR
    // Group 3. Others
    // General rules which will be applied to every group inclusively
    // 1. Ascending by country count (i.e 2 country directions first, 3 country directions second, etc.)
    // 2. Alphabetically for the directions with the same country count

    const trbgColumns = sortByCountryCountAndAlphabetically(
        data.filter(d => d.direction.startsWith('TR-BG'))
    );

    const bgtrColumns = sortByCountryCountAndAlphabetically(
        data.filter(d => d.direction.endsWith('BG-TR'))
    );

    const otherColumns = sortByCountryCountAndAlphabetically(
        data.filter(d => !trbgColumns.find(b => b.direction === d.direction) && !bgtrColumns.find(b => b.direction === d.direction))
    );

    return [...trbgColumns, ...bgtrColumns, ...otherColumns];
}


const sortByCountryCountAndAlphabetically = (directionList) => {
    // orderBy sorts array alphabetically
    // sortBy sorts array by country count and preserves alphabetical sort for the directions with same length

    return _.sortBy(
        _.orderBy(directionList, ['direction']),
        [function (o) { return o.direction.split('-').length; }]);
}
