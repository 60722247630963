import './VMessageBox.css';

import React, { useState, useEffect } from 'react';


const VMessageBox = ({ onChange, defaultValue, maxLength, placeholder, disabled, showLimit }) => {

    const [value, setValue] = useState(defaultValue);

    const handleChange = e => setValue(e.target.value);

    useEffect(() => {
        if (onChange)
            onChange(value);

        if (disabled && value !== defaultValue)
            setValue(defaultValue);

    }, [value, onChange, disabled, defaultValue]);

    return (
        <div className="container">
            <div className="row">
                <textarea
                    id="vMessageBox"
                    type="text"
                    className="v-messagebox"
                    maxLength={maxLength}
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            </div>
            { showLimit && !disabled &&
                <div className="d-flex flex-row-reverse">
                    <div className="v-font v-messagebox-limit">{`${value.length}/${maxLength}`}</div>
                </div>
            }
        </div>
    );
};

VMessageBox.defaultProps = {
    defaultValue: '',
    maxLength: 200,
    placeholder: '',
    disabled: false,
    showLimit: false
};

export default VMessageBox;