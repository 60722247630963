import React from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from "@material-ui/core/ListItem";
import Link from '../Link';
import './TSidebar.css';

const TSidebarItem = ({ depthStep = 10, depth = 0, expanded, item, ...rest }) => {
    const [collapsed, setCollapsed] = React.useState(false);
    const { path, label, routes, Icon, onClick: onClickProp } = item;

    function toggleCollapse() {
        setCollapsed(prevValue => !prevValue);
    }

    function onClick(e) {
        if (Array.isArray(routes)) {
            toggleCollapse();
        }
        if (onClickProp) {
            onClickProp(e, item);
        }
    }

    let expandIcon;

    if (Array.isArray(routes) && routes.length) {
        expandIcon = !collapsed ? (
            <ExpandLessIcon
                className={
                    "sidebar-item-expand-arrow sidebar-item-expand-arrow-expanded"
                }
            />
        ) : (
                <ExpandMoreIcon className="sidebar-item-expand-arrow" />
            );
    }

    return (
        <>
            <Link href={path} style={{ color: "black", textDecoration: "none" }}>
                <ListItem
                    className="sidebar-item"
                    onClick={onClick}
                    button
                    dense
                    {...rest}
                >
                    <div
                        style={{ paddingLeft: depth * depthStep }}
                        className="sidebar-item-content"
                    >
                        {Icon && <ListItemIcon><Icon className="sidebar-item-icon" fontSize="small" /></ListItemIcon>}

                        <ListItemText primary={label} />

                    </div>
                    {expandIcon}
                </ListItem>
            </Link>
            <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                {Array.isArray(routes) ? (
                    <List disablePadding dense>
                        {
                            routes.map((subItem, index) => (
                                <React.Fragment key={`${subItem.name}${index}`}>
                                    {subItem === "divider" ? (
                                        <Divider style={{ margin: "6px 0" }} />
                                    ) : (
                                            <TSidebarItem
                                                depth={depth + 1}
                                                depthStep={depthStep}
                                                item={subItem}
                                            />
                                        )}
                                </React.Fragment>
                            ))
                        }
                    </List>
                ) : null}
            </Collapse>
        </>
    );
}

export default TSidebarItem;