import React from 'react';
import TDropdown from '../../components/TDropdown/TDropdown';
import TextField from '@material-ui/core/TextField';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import { alertError } from '../../../helpers/errorHelper';
import { messages } from '../../utils/messages';


class MarketDetail extends React.Component {
    state = {
        originalMarket: null,
        marketId: null,
        marketName: '',
        marketShortName: '',
        selectedPriceCurve: null,
        readOnly: true
    };

    componentDidMount() {
        console.log("this.props", this.props)
        if (this.props.item) {
            this.setState({
                originalMarket: this.props.item,
                marketId: this.props.item.id,
                marketName: this.props.item.name,
                marketShortName: this.props.item.shortName,
                selectedPriceCurve: {
                    id: this.props.item.priceCurveId,
                    name: this.props.item.priceCurveName,
                    label: this.props.item.priceCurveName
                }
            })
        }

        this.setState({
            readOnly: this.props.readOnly ? this.props.readOnly : false
        }) 
    }

    onPriceCurveChange = selectedPriceCurve => {
        this.setState({ selectedPriceCurve: selectedPriceCurve });
    }

    onChangeMarketName = marketName => {
        this.setState({ marketName: marketName })
    }

    onChangeMarketShortName = shortName => {
        this.setState({ marketShortName: shortName })
    }

    onEditButtonClick() {
        this.setState({ readOnly: false })
        this.props.onEditButtonClick(this.state.originalMarket)
    }

    validateInputs() {
        if (!this.state.marketName) {
            alertError(messages.MARKET.EMPTY_MARKET_NAME);
            return false;
        }

        if (!this.state.selectedPriceCurve) {
            alertError(messages.MARKET.EMPTY_PRICE_CURVE);
            return false;
        }

        return true;
    }

    onSaveButtonClick() {
        if (!this.validateInputs()) {
            return;
        }
        let requestBody = {
            market_id: this.state.marketId,
            market_name: this.state.marketName,
            market_short_name: this.state.marketShortName,
            price_curve_id: this.state.selectedPriceCurve.id,
            price_curve_name: this.state.selectedPriceCurve.name
        }
        this.setState({ readOnly: true })
        this.props.onSaveButtonClick(requestBody)
    }

    onCancelButtonClick() {
        if (this.props.item) {
            this.setState({
                marketId: this.state.originalMarket.id,
                marketName: this.state.originalMarket.name,
                marketShortName: this.state.originalMarket.shortName,
                selectedPriceCurve: {
                    id: this.state.originalMarket.priceCurveId,
                    name: this.state.originalMarket.priceCurveName,
                    label: this.state.originalMarket.priceCurveName
                }
            })
        }
        if (this.props.onCancelButtonClick) {
            this.props.onCancelButtonClick(this.state.originalMarket)
        } else {
            this.setState({ readOnly: true })
        }  
    }

    render() {
        return (
            <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ "margin": "12px", display: `${this.state.readOnly ? "block" : "none"}` }}>
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onEditButtonClick()}
                                disabled={!this.state.readOnly}
                            >
                                Edit
                        </button>
                        </AccordionActions>
                        <Divider />
                    </div>
                    <TextField
                        className="text-field"
                        label="Market Name"
                        value={this.state.marketName}
                        onChange={(e) => this.onChangeMarketName(e.target.value)}
                        InputProps={{
                            readOnly: this.state.readOnly,
                            
                            disableUnderline: this.state.readOnly
                        }}
                        
                    />
                    <TextField
                        className="text-field"
                        label="Short Name"
                        value={this.state.marketShortName}
                        onChange={(e) => this.onChangeMarketShortName(e.target.value)}
                        InputProps={{
                            readOnly: this.state.readOnly,
                            
                            disableUnderline: this.state.readOnly
                        }}
                        
                    />
                    <TDropdown
                            label='Price Curve'
                            options={this.props.priceCurves}
                            width="large"
                            value={this.state.selectedPriceCurve}
                            defaultValue={this.state.selectedPriceCurve}
                            onSelectedOptionChange={this.onPriceCurveChange}
                            disabled={this.state.readOnly}
                        />

                    <div style={{ "margin": "12px", display: `${this.state.readOnly ? "none" : "block"}` }}>
                        <Divider />
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-gray-button"
                                onClick={() => this.onCancelButtonClick()}
                            >
                                Cancel
                                </button>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onSaveButtonClick()}
                                disabled={this.state.readOnly}
                            >
                                Save
                             </button>
                        </AccordionActions>
                    </div>
                </div>


            </div>
        );
    }
}

export default MarketDetail;