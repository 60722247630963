import { sortBy } from 'lodash';
import React from 'react';
import VVerticalTabs, { VVerticalTab } from '../../../components/VVerticalTabs/VVerticalTabs';
import { portalMessages } from '../../../helpers/portalMessages';
import AddServicingCompanyModal from '../../../modals/AddServicingCompanyModal/AddServicingCompanyModal';
import ControlLevels from '../ControlLevels/ControlLevels';

class ServicingOffers extends React.Component {
    epiasExchangeName = 'EPIAS';

    state = {
        showAddCompanyModal: false,
        newServicingLevelCompany: '',
        currentCompanyTab: '',
        servicingCompanies: {}
    };

    editEnabled() {
        return this.props.editEnabled;
    }

    showNewServicingLevelModal() {
        this.setState({ showAddCompanyModal: true });
    }

    hideNewServicingLevelModal() {
        this.setState({ showAddCompanyModal: false });
    }

    onAddCompany(company, border) {
        this.setState({ newServicingLevelCompany: company, currentCompanyTab: company, selectedBorder: border });

        this.hideNewServicingLevelModal();
    }

    createServicingLevels(company, servicingCompanyLevels) {
        const {
            servicingList,
            onEditModeClosed,
            getDeleteLevelConfirmationMessage,
            border,
            ...otherProps } = this.props;

        return (
            <ControlLevels
                {...otherProps}
                border={this.props.showBorderOption ? this.state.selectedBorder : border}
                onEditModeClosed={() => this.onEditModeClosed()}
                controlList={sortBy(servicingCompanyLevels, ['level'])}
                hideWrapperBorder
                servicingCompany={company}
                addNewLevel={this.state.newServicingLevelCompany === company}
                getDeleteLevelConfirmationMessage={(level) => getDeleteLevelConfirmationMessage(level, company)}
                createEmptyTable={() => this.createEmptyTable()}
            />
        );
    }

    createEmptyTable() {
        const emptyTable = this.props.jsonToTableConverter([], this.props.showBorderOption ? this.state.selectedBorder : this.props.border);
        let defaultValue = 0;
        if (this.props.exchange === this.epiasExchangeName) {
            defaultValue = this.props.anyPriceSpecialValue;
        }
        const emptyRow = emptyTable.headers[0].slice(1).map(h => defaultValue);
        emptyRow[0] = 0
        emptyRow[3] = 0
        for (let i = 0; i < 24; i++) {
            emptyTable.values[i] = [i + 1, ...emptyRow];
        }

        return emptyTable;
    }

    onEditModeClosed() {
        this.setState({ newServicingLevelCompany: "" });
        this.props.onEditModeClosed();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.servicingList !== this.props.servicingList
            || prevProps.newServicingLevelCompany !== this.props.newServicingLevelCompany) {

            this.prepareCompanies();
        }
    }

    componentDidMount() {
        this.prepareCompanies();
    }

    prepareCompanies = () => {
        const servicingCompanies = {};

        this.props.servicingList.forEach(servicingBorder => {
            if (!servicingCompanies[servicingBorder.servicing_company])
                servicingCompanies[servicingBorder.servicing_company] = [];
            servicingCompanies[servicingBorder.servicing_company].push(servicingBorder);
        });

        let currentCompanyTab = this.state.currentCompanyTab;

        if (!currentCompanyTab || !servicingCompanies[currentCompanyTab])
            currentCompanyTab = Object.keys(servicingCompanies).sort()[0];

        this.setState({ servicingCompanies, currentCompanyTab, newServicingLevelCompany: "" });
    }

    renderVerticalTabs() {
        const servicingCompanies = this.state.servicingCompanies;

        const verticalTabs = Object.keys(servicingCompanies).sort().map(servicingCompany => {
            return (
                <VVerticalTab
                    key={`Servicing_${servicingCompany}`}
                    eventKey={servicingCompany}
                    title={servicingCompany}>
                    {this.createServicingLevels(servicingCompany, servicingCompanies[servicingCompany])}
                </VVerticalTab>
            );
        });

        if (this.state.newServicingLevelCompany && !Object.keys(servicingCompanies).find(c => c === this.state.newServicingLevelCompany)) {
            verticalTabs.push(
                <VVerticalTab
                    key={`Servicing_${this.state.newServicingLevelCompany}`}
                    eventKey={this.state.newServicingLevelCompany}
                    title={this.state.newServicingLevelCompany}>
                    {this.createServicingLevels(this.state.newServicingLevelCompany, [])}
                </VVerticalTab>
            );
        }

        return (
            <VVerticalTabs
                disableAllTabs={this.props.editingInProgress}
                activeKey={this.state.currentCompanyTab}
                onSelect={this.companyChanged}>
                {verticalTabs}
            </VVerticalTabs>
        );
    }

    companyChanged = (c) => {
        if (this.state.currentCompanyTab !== c)
            this.setState({ currentCompanyTab: c });
    }

    render() {
        return (
            <React.Fragment>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'
                            style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                            <div className='col-md-12'>
                                <span
                                    className={`${this.editEnabled() ? 'v-link-button' : 'v-disabled-link-button'}`}
                                    onClick={() => this.showNewServicingLevelModal()}>
                                    <i aria-hidden="true" className="fa fa-plus fa-fw" />
                                    Add Servicing Level
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='v-infinite-width'>
                    <div className='container'>
                        <div className='row'>
                            {
                                !this.state.newServicingLevelCompany && (!this.props.servicingList || this.props.servicingList.length === 0) ?
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            margin: '5px',
                                            padding: '5px',
                                        }}>
                                        {portalMessages.EXCHANGE_OFFERS.NO_SERVICING}
                                    </span>
                                    :
                                    this.renderVerticalTabs()
                            }
                        </div>
                    </div>
                </div>
                <AddServicingCompanyModal
                    dialogClassName="v-modal-50w"
                    showBorderOption={this.props.showBorderOption}
                    defaultCompany={this.state.currentCompanyTab}
                    show={this.state.showAddCompanyModal}
                    companies={this.props.companies}
                    companyBorderPairs={this.props.companyBorderPairs}
                    counterParty={this.props.counterParty}
                    exchange={this.props.exchange}
                    onAdd={(company, border) => this.onAddCompany(company, border)}
                    onCancel={() => this.hideNewServicingLevelModal()}
                />
            </React.Fragment>
        );
    }
}

export default ServicingOffers;