import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MultipleSelect from '../../components/MultipleSelect/MultipleSelect';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from 'lodash';
import { dayNames } from '../../utils/common'

const useStyles = (theme) => ({
    tagItems: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 5,
    },
    title: {
        fontSize: 12,
    },
    fab: {
        margin: 15,
        minHeight: 25,
        width: 25,
        height: 25,
        backgroundColor: '#80d0f7'
    }
});

class PeriodDetail extends React.Component {
    state = {
        originalPeriod: null,
        periodId: null,
        periodName: '',
        selectedWeekdays: [],
        hourList: [],
        selectedHours: [],
        readOnly: true,
        startValue: "0",
        endValue: "23",
        hourError: null,
        periodNameError: null,
        weekdayError: null
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                originalPeriod: this.props.item,
                periodId: this.props.item.id,
                periodName: this.props.item.name,
                selectedWeekdays: this.props.item.weekdayList.map(d => dayNames[d]),
                hourList: this.props.item.hourList
            })
        }

        this.setState({
            readOnly: this.props.readOnly ? this.props.readOnly : false
        })
    }

    onChangeStartHour = event => {
        let { value } = event.target;
        if (value > 23 || value < 0) {
            this.setState({hourError: "Start hour is invalid.Hour must be between [0-23]"})
        } else {
            this.setState({ startValue: value, hourError: null })
        }
        
    }

    onChangeEndHour = event => {
        let { value } = event.target;
        if (value > 23 || value < 0) {
            this.setState({hourError: "End hour is invalid.Hour must be between [0-23]"})
        }
        this.setState({ endValue: value })
    }

    onAddHourClick = () => {
        if (isEmpty(this.state.startValue)) {
            this.setState({hourError: "Start hour is empty!"});
            return;
        } else if (isEmpty(this.state.endValue)) {
            this.setState({hourError: "End hour is empty!"});
            return;
        } else {
            this.setState({hourError: null})
        }

        let toBeAdded = {
            start: parseInt(this.state.startValue),
            end: parseInt(this.state.endValue),
            key: `${this.state.startValue}-${this.state.endValue}`
        }

        if (!this.state.selectedHours.find(i => i.key === toBeAdded.key)) {
            this.setState({ selectedHours: [...this.state.selectedHours, toBeAdded] })
        }
    }

    handleDelete = item => {
        this.setState({ selectedHours: this.state.selectedHours.filter(i => i !== item) });
    };

    onChangeWeekdays = weekdays => {
        this.setState({ selectedWeekdays: weekdays })
    }

    onChangeHours = hours => {
        this.setState({ selectedHours: hours })
    }

    onChangePeriodName = periodName => {
        this.setState({ periodName: periodName })
    }

    onEditButtonClick() {
        this.setState({ readOnly: false })
        this.props.onEditButtonClick(this.state.originalPeriod)
    }

    onSaveButtonClick() {
        if (this.state.periodName.trim() === "") {
            this.setState({periodNameError: "Period name is empty!"});
            return;
        }

        if (!this.state.selectedWeekdays || !this.state.selectedWeekdays.length) {
            this.setState({weekdayError: "Select at least one day!"});
            return;
        }
        let hourList = [];
        this.state.selectedHours.forEach(item => {
            for (let i = item.start; i <= item.end; i++) {
                if (!hourList.includes(i)) {
                    hourList.push(i)
                }
            }
        })

        let requestBody = {
            period_id: this.state.periodId,
            period_name: this.state.periodName,
            weekday_list: this.state.selectedWeekdays.map(d => d.id),
            hour_list: hourList.sort((a, b) => a - b),
        }

        this.setState({ readOnly: true, hourList: hourList })
        this.props.onSaveButtonClick(requestBody)
    }

    onCancelButtonClick() {
        if (this.props.item) {
            this.setState({
                periodId: this.state.originalPeriod.id,
                periodName: this.state.originalPeriod.name,
                selectedWeekdays: this.state.originalPeriod.weekdayList.map(d => dayNames[d]),
                hourList: this.state.originalPeriod.hourList

            })
        }
        if (this.props.onCancelButtonClick) {
            this.props.onCancelButtonClick(this.state.originalPeriod)
        } else {
            this.setState({ readOnly: true })
        }
    }


    render() {
        const { classes } = this.props;
        return (
            <div style={{ display: 'flex', flexDirection: "column" }}>
                <div style={{ "margin": "12px", display: `${this.state.readOnly ? "block" : "none"}` }}>
                    <AccordionActions>
                        <button
                            size="small"
                            className="btn t-orange-button"
                            onClick={() => this.onEditButtonClick()}
                            disabled={!this.state.readOnly}
                        >
                            Edit
                        </button>
                    </AccordionActions>
                    <Divider />
                </div>
                <TextField
                    style={{ width: "50%", margin: "12px" }}
                    label="Period Name"
                    value={this.state.periodName}
                    onChange={(e) => this.onChangePeriodName(e.target.value)}
                    InputProps={{
                        readOnly: this.state.readOnly,
                        disableUnderline: this.state.readOnly
                    }}
                />
                <div>
                    {this.state.periodNameError && <p className="error">{this.state.periodNameError}</p>}
                </div>
                <MultipleSelect
                    readOnly={this.state.readOnly}
                    title="Days"
                    items={dayNames}
                    onChange={this.onChangeWeekdays}
                    selectedItems={this.state.selectedWeekdays}
                >
                </MultipleSelect>
                <div>
                    {this.state.weekdayError && <p className="error">{this.state.weekdayError}</p>}
                </div>
                <Paper component="ul" className={classes.tagItems}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {"Hours"}
                    </Typography>
                    <div style={{fontStyle: "italic", fontSize: "12px"}}>
                        {this.state.readOnly ? (
                            (this.state.hourList).map(item => `H${item}`).join('/')
                        ) : (
                                (this.state.selectedHours).map(item => {
                                    return (<Chip
                                        key={`${item.start}_${item.end}_chip`}
                                        className="tag-item"
                                        size="small"
                                        label={`H${item.start}-H${item.end}`}
                                        onDelete={() => !this.state.readOnly ? this.handleDelete(item) : null}
                                    />);
                                })
                            )
                        }
                    </div>

                    <FormControl component="fieldset" style={{ margin: '35px 0px 10px 10px' }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <div>
                                    <div className="add-hour-div">
                                        <input
                                            type="number"
                                            style={{ display: `${this.state.readOnly ? "none" : "block"}` }}
                                            className="add-hour-input"
                                            value={this.state.startValue}
                                            placeholder="Start Hour"
                                            onChange={this.onChangeStartHour}
                                        />
                                        <input
                                            type="number"
                                            style={{ display: `${this.state.readOnly ? "none" : "block"}` }}
                                            className="add-hour-input"
                                            value={this.state.endValue}
                                            placeholder="End Hour"
                                            onChange={this.onChangeEndHour}
                                        />
                                        <div>
                                            <Fab
                                                style={{ display: `${this.state.readOnly ? "none" : "block"}` }}
                                                size="small"
                                                color="default"
                                                disableFocusRipple={true}
                                                disableRipple={true}
                                                aria-label="add"
                                                className={classes.fab}
                                                onClick={this.onAddHourClick}
                                            >
                                                <AddIcon />
                                            </Fab>
                                        </div>
                                    </div>
                                    <div>
                                        {this.state.hourError && <p className="error">{this.state.hourError}</p>}
                                    </div>
                                    </div>
                                }
                            />
                        </FormGroup>
                    </FormControl>
                </Paper>
                <div style={{ "margin": "12px", display: `${this.state.readOnly ? "none" : "block"}` }}>
                    <Divider />
                    <AccordionActions>
                        <button
                            size="small"
                            className="btn t-gray-button"
                            onClick={() => this.onCancelButtonClick()}
                        >
                            Cancel
                        </button>
                        <button
                            size="small"
                            className="btn t-orange-button"
                            onClick={() => this.onSaveButtonClick()}
                            disabled={this.state.readOnly}
                        >
                            Save
                        </button>
                    </AccordionActions>
                </div>
            </div>
        );
    }
}

export default withStyles(useStyles)(PeriodDetail);