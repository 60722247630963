import '../PnLReport/PnLReport.css';
import '../PnLReport/PnlSummary/PnlSummary.css';
import './DailyLiquidation.css';

import { isEmpty, orderBy, sortBy } from 'lodash';
import React from 'react';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import PnLReport, { dateTypes } from '../PnLReport/PnLReport';
import { createExcel } from '../../helpers/excelHelper';
import { portalMessages } from '../../helpers/portalMessages';
import { getNumberClassBySign } from '../../helpers/generalHelper';

class DailyLiquidation extends React.Component {
    columnClasses = ['v-data-group-1', 'v-data-group-2'];

    referenceData = {
        mainHeader: 'sum_of_borders',
        dateKey: 'dt',
        totalKey: 'today'
    }

    state = {
        hideZeroColumns: true
    }

    getHeaders(data) {
        return this.sortByDate(data[this.referenceData.mainHeader]).map(d => d[this.referenceData.dateKey]);
    }

    renderHeaders(data) {
        return (
            <tr>
                <th className='v-pnl-date-header'>
                    Direction
                </th>
                <th className='v-pnl-total'>
                    Total
                </th>
                {
                    this.getHeaders(data).map((liqDate, dIdx) => {
                        return (
                            <th key={`h_${liqDate}`}
                                className={`${this.columnClasses[dIdx % 2]}`}>
                                {liqDate}
                            </th>
                        );
                    })
                }
            </tr>
        );
    }

    sortByDate(data) {
        return orderBy(data, ['dt'], ['desc']);
    }

    getValues(data) {
        const totalData = data[this.referenceData.mainHeader];

        return [
            ...sortBy(data.directions, ['direction'])
                .map(dirDetails => {
                    let all_zero = dirDetails.data.map(d => d.v === 0).every(r => r)
                    
                    if (all_zero){
                        return;
                    }

                    return [
                        dirDetails.direction,
                        dirDetails.data.reduce((a, b) => a + b.v, 0).toFixed(2),
                        ...this.sortByDate(dirDetails.data).map(dirCell => {
                            return dirCell.v;
                        })
                    ];
                })
            , [
                'Total',
                totalData.reduce((a, b) => a + b[this.referenceData.totalKey], 0).toFixed(2),
                ...this.sortByDate(totalData).map(totalCol => {
                    return totalCol[this.referenceData.totalKey];
                })
            ]].filter(v => v);
    }

    renderRows(data) {
        const totalData = data[this.referenceData.mainHeader];
        const allTotal = totalData.reduce((a, b) => a + b[this.referenceData.totalKey], 0);

        return (
            <React.Fragment>
                {
                    sortBy(data.directions, ['direction']).map(dirDetails => {
                        let rowTotal = parseInt(dirDetails.data.reduce((a, b) => a + b.v, 0));

                        if (this.state.hideZeroColumns && !Number(rowTotal))
                            return null;
                        
                        return (
                            <tr key={`r_${dirDetails.direction}`}>
                                <td className='v-pnl-direction-col'>
                                    {dirDetails.direction}
                                </td>
                                <th className={`v-pnl-total ${getNumberClassBySign(rowTotal)}`}>
                                    {parseInt(rowTotal).toLocaleString()}
                                </th>
                                {
                                    this.sortByDate(dirDetails.data).map(dirCell => {
                                        return (
                                            <td key={`c_${dirDetails.direction}_${dirCell.dt}`} className={`${getNumberClassBySign(dirCell.v)}`}>
                                                { parseInt(dirCell.v).toLocaleString()}
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                        );
                    })
                }
                <tr key={`total_row`}>
                    <td className='v-pnl-direction-col'>
                        Total
                    </td>
                    <th className={`v-pnl-total ${getNumberClassBySign(allTotal)}`}>
                        {parseInt(allTotal).toLocaleString()}
                    </th>
                    {
                        this.sortByDate(totalData).map((totalCol, idx) => {
                            return (
                                <td className={`v-pnl-total ${getNumberClassBySign(totalCol[this.referenceData.totalKey])}`} key={`total_${idx}`}>
                                    {parseInt(totalCol[this.referenceData.totalKey]).toLocaleString()}
                                </td>
                            )
                        })
                    }
                </tr>
            </React.Fragment >
        );
    }

    getTabContent(data) {
        if (!data || isEmpty(data))
            return null;

        if (isEmpty(data[this.referenceData.mainHeader]))
            return (
                <span
                    style={{
                        fontWeight: '600',
                        margin: '5px',
                        padding: '5px',
                    }}>
                    {portalMessages.PNL.NO_LIQUIDATION}
                </span>
            );

        return (
            <React.Fragment>
                <div className='row'>
                    <div className="v-checkBox checkbox pull-right col-3 offset-9">
                        <label style={{ fontWeight: 600, margin: '5px 0 0' }}>
                            <input type="checkbox"
                                checked={this.state.hideZeroColumns}
                                onChange={() => { this.setState({ hideZeroColumns: !this.state.hideZeroColumns }) }} />
                                    Hide empty rows
                        </label>
                    </div>
                </div>
                <div className='v-pnl-summary-table'>
                    <table className="table table-borderless table-responsive">
                        <thead>
                            {this.renderHeaders(data)}
                        </thead>
                        <tbody>
                            {this.renderRows(data)}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }

    getBody(data) {
        return (
            <VTabs>
                <VTab key='Liquidation'
                    eventKey='Liquidation'
                    title='Liquidation'>
                    {this.getTabContent(data)}
                </VTab>
            </VTabs>
        );
    }

    download(data, filter) {
        const fileName = `Daily_Liquidation_${filter.currency}_${filter
            .period_list.map(p => p.toUpperCase()).join('-')}_${filter.start_date}_${filter.end_date}.xlsx`;;


        createExcel(
            [['Direction', 'Total', ...this.getHeaders(data)]],
            this.getValues(data),
            fileName
        );
    }

    render() {
        return (
            <React.Fragment >
                <PnLReport
                    title="Daily Liquidation"
                    pageName="dailyLiquidation"
                    hideGrouping
                    hideInfo
                    customOutput={(pnlSummary) => this.getBody(pnlSummary)}
                    download={(pnlSummary, filter) => { this.download(pnlSummary, filter); }}
                    grouping={dateTypes.Daily}
                />
            </React.Fragment >
        );
    }
}

export default DailyLiquidation;