import './PriceCurveDetailModal.css';

import React, { useState } from 'react';

import TBaseModal from '../../components/TBaseModal/TBaseModal';
import TDropdown from '../../components/TDropdown/TDropdown';
import TFileUploadIcon from '../../components/TFileUploadIcon/TFileUploadIcon';
import { UNITS, UNIT_ARR, CURRENCIES, CURRENCY_ARR, TIMEZONES } from '../../utils/constants';
import { alertError } from '../../utils/errorHandler';
import { messages } from '../../utils/messages';


const MAX_PRICE_CURVE_NAME_LENGTH = 50


const PriceCurveDetailModal = ({
    selectedPriceCurve,
    show,
    onCancel,
    contractCountries,
    onFileChanged,
    financialMarkFileNameToUpload,
    onRemoveFinancialMarkFile,
    onSavePriceCurve }) => {

    const [name, setName] = useState(selectedPriceCurve ? selectedPriceCurve.name : '');
    const [unit, setUnit] = useState(selectedPriceCurve ? UNITS[selectedPriceCurve.unit] : '');
    const [unitConversion, setUnitConversion] = useState(selectedPriceCurve ? (selectedPriceCurve.unit_conversion ? selectedPriceCurve.unit_conversion : '') : '');
    const [currency, setCurrency] = useState(selectedPriceCurve ? CURRENCIES[selectedPriceCurve.currency] : '');
    const [financialMarkCountryId, setFinancialMarkCountryId] = useState(selectedPriceCurve && contractCountries ? contractCountries.find(i => (i.label).includes(selectedPriceCurve.financial_mark_country_name)) : '');
    const [timezone, setTimezone] = useState(selectedPriceCurve ? TIMEZONES.find(i => i.label === selectedPriceCurve.timezone) : '');
    const [currentFinancialMarkFileName, setCurrentFinancialMarkFileName] = useState(selectedPriceCurve ? selectedPriceCurve.financial_mark_file_name : '');


    const validateInputs = () => {
        if (!name) {
            alertError(messages.PRICE_CURVE.EMPTY_PRICE_CURVE_NAME);
            return;
        }

        if (!unit) {
            alertError(messages.PRICE_CURVE.EMPTY_UNIT);
            return;
        }

        if (!currency) {
            alertError(messages.PRICE_CURVE.EMPTY_CURRENCY);
            return;
        }

        if (!financialMarkCountryId) {
            alertError(messages.PRICE_CURVE.EMPTY_FINANCIAL_MARKET_COUNTRY);
            return;
        }

        if (!timezone) {
            alertError(messages.PRICE_CURVE.EMPTY_TIMEZONE);
            return;
        }

        onSavePriceCurve(name, unit.value, currency.value, financialMarkCountryId.value, timezone.value, unitConversion);
    }

    return (
        <TBaseModal
            dialogClassName="t-modal-90w"
            show={show}
            onHide={onCancel}
            title="Price Curve Details"
            closeButton={false}
            body={
                <div className="container">
                    <div className="row">
                        <div className="col t-price-curve-label-container">
                            <label className="t-font f-font-size t-price-curve-labels">Price Curve Name</label>
                        </div>
                        <div className="col">
                            <input className="t-input" type="text" maxLength={MAX_PRICE_CURVE_NAME_LENGTH} value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="col t-price-curve-label-container">
                            <label className="t-font f-font-size t-price-curve-labels">Unit</label>
                        </div>
                        <div className="col">
                            <TDropdown
                                width="large"
                                options={UNIT_ARR}
                                defaultValue={unit}
                                onSelectedOptionChange={(unit) => setUnit(unit)}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col t-price-curve-label-container">
                            <label className="t-font f-font-size t-price-curve-labels">Currency</label>
                        </div>
                        <div className="col">
                            <TDropdown
                                width="large"
                                options={CURRENCY_ARR}
                                defaultValue={currency}
                                onSelectedOptionChange={(currency) => setCurrency(currency)}
                                isSearchable={false}
                            />
                        </div>
                        <div className="col t-price-curve-label-container">
                            <label className="t-font f-font-size t-price-curve-labels">Source Name</label>
                        </div>
                        <div className="col">
                            <TDropdown
                                width="large"
                                options={contractCountries}
                                defaultValue={financialMarkCountryId}
                                onSelectedOptionChange={(financialMarkCountryId) => setFinancialMarkCountryId(financialMarkCountryId)}
                                isSearchable={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col t-price-curve-label-container">
                            <label className="t-font f-font-size t-price-curve-labels">Timezone</label>
                        </div>
                        <div className="col">
                            <TDropdown
                                width="large"
                                options={TIMEZONES}
                                defaultValue={timezone}
                                onSelectedOptionChange={(timezone) => setTimezone(timezone)}
                                isSearchable={true}
                            />
                        </div>
                        <div className="col t-price-curve-label-container">
                            <label className="t-font f-font-size t-price-curve-labels">Unit Conversion</label>
                        </div>
                        <div className="col">
                            <input className="t-input"  type="number" value={unitConversion} onChange={(e) => setUnitConversion(e.target.value)} />
                        </div>
                        
                        <div className="col-6"></div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col t-price-curve-label-container">
                            <label className="t-font f-font-size t-price-curve-labels">Financial Mark File Name</label>
                        </div>
                        <div className="col">
                            <input className="t-input" type="text" value={currentFinancialMarkFileName ? currentFinancialMarkFileName : financialMarkFileNameToUpload} disabled={true} />
                        </div>
                        <div className="col-6">
                            {
                                financialMarkFileNameToUpload || currentFinancialMarkFileName ?
                                    <React.Fragment>
                                        <label className="t-upload-file-label t-red" onClick={onRemoveFinancialMarkFile}>
                                            <i aria-hidden="true"
                                                className={`fa fa-remove fa-fw`} />
                                            <span className="t-font t-font-size">Remove Uploaded File</span>
                                        </label>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <TFileUploadIcon
                                            accept={['.xlsx', '.xls', '.xlsm']}
                                            labeltext={`Import Financial Mark File`}
                                            key='importTableIcon'
                                            onFileChanged={onFileChanged}
                                        />
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            }
            actions={
                <React.Fragment>
                    <button
                        type="button"
                        className="btn t-gray-button"
                        onClick={onCancel}>
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn t-orange-button"
                        onClick={validateInputs}>
                        Save
                    </button>
                </React.Fragment >
            }
        />
    );
}

PriceCurveDetailModal.defaultProps = {
    selectedPriceCurve: '',
    financialMarkFileNameToUpload: ''
}

export default PriceCurveDetailModal;