import React from "react";
import { Tabs, Tab, Box, Paper } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import './TTabs.css';

const TTabs = (props) => {
  const [selectedTab, setSelectedTab] = React.useState(props.defaultSelectedTabId || props.children[0]?.props.id)

  // Handle Tab Button Click
  const handleTabChange = (event, newTabIndex) => {
    let tab = props.children[newTabIndex]
    if (tab) {
      setSelectedTab(tab.props.id)
    }
  };

  const findIndexOfTab = (id) => {
    let idx = props.children.findIndex(obj => obj.props.id === id)
    if (idx < 0) {
      return 0
    }
    return idx;
  }

  const deleteTab = (tab) => {
    let newTabList = props.children.filter(t => t.props.id !== tab.props.id).map(t => t.props.children.props.item);
    let newSelectedTab = null

    if (selectedTab !== tab.props.id) {
      newSelectedTab = selectedTab
    }
    props.setTabs(newTabList, newSelectedTab);

  }

  const renderTabs = (tabs) => {
    return tabs.map((tab) => {
      return (
        <Tab style={{ height: "10px", borderRadius: "3px 3px 0px 0px", backgroundColor: "rgb(236 240 243)" }} component="div" key={tab.props.id} label={
          <span style={{ marginLeft: "9px" }}>
            {tab.props.title.length < 11 ? tab.props.title : tab.props.title.slice(0, 10) + '...'}
            {!props.deleteButtonDisabled &&
              <IconButton onClick={(e) => { deleteTab(tab); e.stopPropagation() }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          </span>
        } />
      )
    })
  }

  const renderTabsContent = (tabs) => {
    let maxTabIndex = 0

    if (!tabs) {
      return null;
    }

    return tabs.map((tab) => {
      return (
        <Box
          p={1}
          value={maxTabIndex++}
          index={tab.props.id}
          hidden={tab.props.id !== selectedTab}
          key={`${tab.props.id}_box`}
        >
          {tab.props.children}
        </Box>
      )
    })
  }

  return (
    <Paper style={{ marginLeft: "15px" }}>
      <AppBar position="static" color="inherit">
        <Tabs
          value={findIndexOfTab(selectedTab)}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="on"
        >
          {renderTabs(props.children)}
        </Tabs>
      </AppBar>
      <Box >{renderTabsContent(props.children)}</Box>
    </Paper>
  );
}




export default TTabs;