import React from 'react';

const Link = ({ href, className, style, children }) => {
    const onClick = (event) => {

        if (event.metaKey || event.ctrlKey) {
            return;
        }
        event.preventDefault();
        window.history.pushState({}, '', href);

        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
    }
    return (
        <a onClick={onClick} href={href} className={className} style={style}>{children}</a>
    );
};

export default Link;