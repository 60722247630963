import "./VComponentSpinner.css";
import React from 'react';
import { Spinner } from 'react-bootstrap';

const VComponentSpinner = () => {
    return (
        <div className='v-component-spinner-container'>
            <div className='v-component-spinner'>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        </div>
    );
};

export default VComponentSpinner;