export const portalMessages = {
    // Date
    DATE_SELECTION: "Please select a valid date.",
    START_DATE_SELECTION: "Please select a valid start date.",
    END_DATE_SELECTION: "Please select a valid end date.",
    INVALIED_REQUESTED_DATE: 'Date is invalid. Please select a valid date.',

    // Common Errors
    UNEXPECTED_ERROR_OCCURED: "An unexpected error occurred while processing the request.",
    NETWORK_ERROR: "There was a network error, please try again. If you keep getting this error kindly request for support.",
    INVALID_GENERAL: 'Process could not be completed, there are invalid values.',
    NO_SAVED: "No values saved previously.",
    SEND_MAIL_ANYWAY: "Send mail anyway?",
    INCONSISTENT_DATA: 'Updated data is inconsistent, might be updated after listing. Please list the data again before further updates.',
    DELETE_ROW: "Are you sure want to delete row?",
    SELECT_CURRENCY: "Please select a valid currency.",

    // Border - Direction - Counter Party
    ADD_BORDER: "Please select at least one border.",
    ADD_DIRECTION: "Please select at least one direction.",
    ADD_COUNTER_PARTY: "Please select at least one counter party.",
    BORDERS_NOT_FOUND: "Could not get available borders, please refresh the page.",
    DIRECTIONS_NOT_FOUND: "Could not get available directions, please refresh the page.",
    BORDERS_AND_COUNTER_PARTIES_NOT_FOUND: "Could not get available borders and counter parties, please refresh the page.",
    SELECT_COUNTER_PARTY: "Please select a valid counter party.",
    SELECT_PERIOD: "Please select a valid period. ",
    SELECT_DIRECTION: "Please select a valid direction.",
    SELECT_EXCHANGE: "Please select a valid exchange.",
    SELECT_COMPANY: "Please select a valid company.",
    COULD_NOT_GET_EXCHANGES_C_PARTIES: "Could not get available exchanges and counter parties, please refresh the page.",
    COULD_NOT_GET_C_PARTIES: "Could not get available counter parties, please refresh the page.",
    COULD_NOT_GET_TSO: "Could not get available TSO data, please refresh the page.",
    COULD_NOT_GET_C_PARTIES_AND_DIRECTIONS: "Could not get available counter parties and directions, please refresh the page.",

    // Tab
    TAB_SELECTION: "Cannot switch tabs while editing.",

    // Mail
    MAIL_SENT_SUCCESSFULLY: "Mail sent successfully.",
    RESEND_SENT_MAIL: 'Mail sent previously. Resend mail?',

    // Multiple Selection
    ADD_DATE_AND_BORDER: "Please select a valid date and at least one border.",
    ADD_DATE_AND_DIRECTION: "Please select a valid date and at least one direction.",
    ADD_DATE_AND_COUNTER_PARTY: "Please select a valid date and counter party.",

    // Conjuctions
    AND: "and",

    MARGIN_ANALYSIS: {
        MARGIN_NOT_FOUND: "Data not found for this period and direction.",
        TURKEY_HOURS: "When realized price is used for TR, the last hour is filled with price forecasts due to the timezone difference.",
        FORECAST_GRAY: "Price forecasts are used in calculations for the countries marked with gray."
    },

    PARAMETERS: {
        DEFAULT_BIDS_NOT_GET: "Could not get available default bid values, please refresh the page.",
        SAFETY_MARGINS_NOT_GET: "Could not get available safety margin data, please refresh the page.",
        OFFER_PARAMETERS_NOT_GET: "Could not get available offer parameters, please refresh the page.",
        NO_DEFAULT_BIDS_FOUND: "No default bids found.",
        NO_SAFETY_MARGIN_FOUND: "No safety margins found.",
        NO_OFFER_PARAMETERS_FOUND: "No offer parameters found.",
        NO_FORACEST_FOUND: "No forecast found.",
        FORECASTS_UPDATED_SUCCESSFULLY: "Forecasts updated successfully.",
    },

    VTABLE: {
        UNSAVED_CHANGES: 'Unsaved changes will be lost.',
        XLS_MISMATCH_HEADER_COUNT: 'Import failed. Number of columns should match exactly when importing file.',
        XLS_MISMATCH_HEADER: 'Import failed. Headers should match exactly when importing file.'
    },

    AUCTION_BIDS: {
        BIDS_DELETED_SUCCESSFULLY: "Bids deleted successfully.",
        BIDS_CREATED_SUCCESSFULLY: "Bids created successfully.",
        BIDS_UPDATED_SUCCESSFULLY: "Bids updated successfully.",
        INVALID_BID_DELETE: "Delete operation failed. Only bid with the highest quote number can be deleted.",
        AUCTION_NO_NEGATIVE: 'Amount should be nonnegative.',
        INCONSISTENT_DATA: 'Inconsistent data. Bids might have been updated a few seconds ago. Please refresh the data before updating.',
        INCONSISTENT_AMOUNT: 'Amounts are inconsistent, might be updated after listing. Please list the data again before further updates.',
        INCONSISTENT_DATA_BID: 'Bids are inconsistent, might be updated after listing. Please list the data again before further updates.',
        INCONSISTENT_DATA_MAIL: 'Mail info is inconsistent, might be updated after listing. Please list the data again before further updates.',
        AMOUNT_TOO_BIG_OR_SMALL: 'Amounts must be between 0 and 200, please check the values.',
        BID_WITH_NO_AMOUNT: 'Amount can not be zero when bid is positive, please check the values.',
        BID_TOO_BIG_OR_SMALL: 'Bids must be between 0 and 300, please check the values.',
        AMOUNT_WITH_NO_BID: 'Bid can not be zero when amount is positive, please check the values.',
        ALL_AMOUNTS_ZERO: 'All amounts are zero and will not be saved, please check the values.',
        ALL_BIDS_ZERO: 'All bids are zero and will not be saved, please check the values.',
        INVALID_BID_NUMBER: 'Bids must be added by adjacent bid numbers for the same counter party - direction pairs (bid1 then bid2 then bid3 etc.).',
        INVALID_GREATER_THAN_PREVIOUS: 'Bids must be smaller than the previous ones, please check the values.',
        INVALID_LESS_THAN_NEXT: 'Bids can not be less than next bid, please check the values.',
        SEND_MAIL: 'Send bids to the counter party?',
        DELETE_BIDS: 'Delete bids permenantly?',
        SEND_ALL_BIDS: 'Send all bids to the [COUNTER_PARTY]?',
        BIDS_FRACTIONS: 'Bids can not have more than two digits on fractional part.',
        NO_MARGIN: 'Default values used because margins could not be calculated.',
        INVALID_REQUEST_DATE: 'Only day ahead bids can be added or edited.',
        INVALID_MAX_BID_NUMBER: 'Maximum 5 bids are allowed for a direction.',
    },

    AUCTION_ALLOCATION: {
        NUMERIC_INPUT: "Allocated capacity should be nonnegative and smaller than 200.",
        INCONSISTENT_DATA: 'Inconsistent data. Allocations might have been updated a few seconds ago. Please refresh the data before updating.',
        ALLOCATIONS_SAVED_SUCCESSFULLY: 'Allocations saved successfully.',
        ALLOCATIONS_DELETED_SUCCESSFULLY: "Allocations deleted successfully.",
        NO_ALLOCATION_TO_UPDATE: 'Please enter an allocation or capacity id to save.',
        ENERGOVIA_FILE_IMPORT_ERROR: 'Energovia file could not import. Please check your file and cell values.'
    },

    AUTHORIZATION: {
        GENERAL: 'Unauthorized',
        SEND_MAIL: 'Unauthorized to send mail.'
    },

    EXCHANGE_OFFERS: {
        OFFERS_UPDATED: "Exchange offers updated successfully.",
        OBLIGED_UPDATED: "Obliged amounts updated successfully.",
        TOTAL_UPDATED: "Total amounts updated successfully.",
        PROFILE_OR_PRICE_ZERO: "Profile and price offer must be specified together.",
        INCONSISTENT_DATA: 'Updated data is inconsistent, might be updated after listing. Please list the data again before further updates.',
        OFFER_NOT_FOUND: "Data not found on this date for this exchange and counter party.",
        NO_TABLE_TO_IMPORT: "At least 1 border must be listed to import data from file.",
        NO_TABLE_TO_EXPORT: "At least 1 border must be listed to export data to file.",
        BORDER_NOT_LISTED: "File format is wrong or [BORDER] border is not currently listed. Only listed borders can be imported.",
        NO_MARGIN: "Price offers could not be calculated.",
        SEND_ALL_OFFERS: 'Send all offers?',
        HOW_TO_DELETE: 'Reset obliged amounts permanently?',
        HOW_TO_DELETE_BORDER: 'Reset offers permanently?',
        SET_ZERO: 'Reset all values',
        SET_INITIAL: 'Set default values',
        NO_CURRENCY: 'Could not get currentcy rates.',
        PROFILE_NAN: 'Profiles must be numbers',
        INVALID_DATE: "Only day ahead offers can be added or edited.",
        INVALID_MAIL_DATE: "Mail can be sent for only day ahead offers.",
        INVALID_OBLIGED_DATE: "Only day ahead amounts can be added or edited.",
        INVALID_PRICE_OFF: '"[X]" is not valid for price offer.',
        PRICE_FRACTIONS: 'Price offers can not have more than two digits on fractional part.',
        PROFILE_INTEGER: ' profiles must be integers.',
        PROFILE_FRACTION: ' profiles can not have more than one digit on fractional part.',
        ALL_ZERO_SEND_MAIL: 'At least one of the values must be greater than zero to send email.',
        ALL_ZERO_SAVE: 'At least one of the values must be greater than zero to save values.',
        OBLIGED_NO_NEGATIVE: 'Obliged amounts must be nonnegative.',
        OBLIGED_NAN: 'Obliged amounts must be numbers',
        OFFER_TOO_BIG_OR_SMALL: 'Price offers for [EXCHANGE] must be between [MIN] and [MAX], please check the values.',
        INVALID_PROFILE: 'Profiles must be between 0 and 1000, please check the values.',
        DELETE_OFFERS: 'Delete offers permenantly?',
        OFFERS_DELETED_SUCCESSFULLY: "Offers deleted successfully.",
        INITIALS_LISTED: "Save initial values before adding new level?",
        DELETE_LEVELLED_OBLIGED: "Previously saved offers will be deleted before reseting total values. Reset anyway?",
        EDIT_LEVELLED_OBLIGED: "Previously saved offer levels will be deleted before saving total amounts, continue anyway?",
        MAX_LEVEL_COUNT: "Maximum level count is 4.",
        SELL_PRICE_ORDER: "Sell prices must increase on each level.",
        BUY_PRICE_ORDER: "Buy prices must decrease on each level.",
        BUY_SELL_PRICE_ORDER: "Sell prices must be greater than buy prices.",
        DEFAULT_LISTED: "No values saved previously.",
        PROFILES_DOES_NOT_MATCH: "For some hours, the total allocated amount does not match the total amount of levels. You may need to check the hours below.",
        SEND_MAIL_ANYWAY: "Send mail anyway?",
        PROFILES_POSITIVE: "Profiles can not be negative.",
        PROFILES_DOES_NOT_MATCH_RESULTS: "The total profiles are not equal to the expected values for balancing other exchange results. Please check the totals for the hours below.",
        NO_SERVICING: "There are not any saved servicing offers."
    },

    EXCHANGE_RESULTS: {
        INVALID_BUY_VALUES: 'Save operation failed. Buy values should be nonnegative number.',
        INVALID_SELL_VALUES: 'Save operation failed. Sell values should be nonnegative number.',
        NO_RESULT_TO_UPDATE: 'Please enter a result to save.',
        SPECIAL_FILE_IMPORT_ERROR: 'File could not import. Please check your file and cell values.',
        INVALID_INPUT: 'Exchange results should be nonnegative and less than or equal to 1000.',
        SUCCESSULL_SAVE_MESSAGE: 'Exchange results saved successfully.',
        SUCCESSULL_DELETE_MESSAGE: 'Exchange results deleted successfully.',
        INVALID_INPUT_FOR_GREEN: "Green's buy column values should be empty or zero.",
        TABLE_DELETE_MESSAGE: "Are you sure you want to delete [EXCHANGE] results?",
        INVALID_FRACTIONAL_INPUT: "Exchange results can not have more than one digit on fractional part."
    },

    PHYSICAL_FLOWS: {
        NOMINATIONS_UPDATED: "Nominations updated successfully.",
        HOW_TO_DELETE_NOM: 'Reset nominations permanently?',
        NOM_DELETED_SUCCESS: "Nominations deleted successfully.",
        INTRA_UPDATED: "Intraday profiles updated successfully.",
        HOW_TO_DELETE_INTRA: 'Reset intraday profiles permanently?',
        INTRA_DELETED_SUCCESS: "Intraday profiles deleted successfully.",
        IRREGULAR_MAIL: "Total of nominations and intraday results does not match with exchage results or some results are not specified. Please be aware that missing exchanges could not be validated and hours will not be listed as invalid.",
        NOM_NAN: 'Nominations must be numbers',
        NOM_NO_NEGATIVE: 'Nominations must be nonnegative.',
        INTRA_NAN: 'Intraday profiles must be numbers',
        INTRA_NO_NEGATIVE: 'Intraday profiles must be nonnegative.',
        INTRA_BUY_SELL_ONE: 'Buy and sell intraday profiles can not be specified together.',
        CAPACITY_FRACTIONS: 'Capacities can not have more than one digit on fractional part.',
    },

    LT_PHYSICAL_FLOWS: {
        NO_NOMINATIONS: "There are not any saved nominations.",
        NO_DISTRIBUTION: "There are not any saved distributions.",
        DELETE_NOM: 'Delete nomination permenantly?',
        NOMINATION_SAVED: "Nomination saved successfully.",
        NOM_DELETED_SUCCESS: "Nomination deleted successfully.",
        ALL_CAP_ZERO: 'All nominations are zero and will not be saved, please check the values.',
        NOM_NO_NEGATIVE: 'Nominations must be nonnegative.',
        NOM_NUMBER: 'Nominations must be numbers.',
        TOO_BIG_NOM: 'Nominations can not be greater than max allocation.',
        EXCEED_MAX: 'Total of some hours exceed maximum allocations:',
        NO_ALLOCATION: 'does not have any allocation to use.',
        NO_OP_MAIL: 'Nominations does not exist, do you want to send a notification about it?',
        CAP_INTEGER: 'Capacities must be integers.',
    },

    POWER_SCHEDULES: {
        NO_NOMINATIONS: "There are no nominations on [DATE].",
        SEND_NOMINATION_SUCCESSFUL: "Nomination pdf sent successfully.",
        SEND_NOMINATION_ERROR: "Nomination pdf cannot be sent successfully."
    },

    LT_AUCTION_ALLOCATIONS: {
        SELECT_PERIOD_TYPE: "Please select a valid period type.",
        SELECT_TSO: "Please select a valid TSO.",
        ENTER_CAPACITY_CODE: "Please enter a capacity code.",
        SELECT_START_DATE: "Please select a valid start date.",
        SELECT_END_DATE: "Please select a valid end date.",
        ENTER_CAPACITY: "Please enter a valid capacity.",
        INVALID_CAPACITY: "Capacity value should be nonnegative and less than 1000.",
        INVALID_PERIOD: "Start date should be earlier than end date.",
        EMPTY_CAPACITY_CODE: "Capacity code should not be empty.",
        ENTER_ORDER: "Please enter a valid order.",
        INVALID_ORDER: "Order value should be nonnegative number and less than 1000.",
        SUCCESSFUL_ADD_MESSAGE: "Allocation added successfully.",
        SUCCESSFUL_UPDATE_MESSAGE: "Allocations updated successfully.",
        SUCCESSFUL_DELETE_MESSAGE: "Allocation deleted successfully.",
        NO_LT_ALLOCATION_FOUND: "No LT Allocation found in this period.",
        INVALID_PERIOD_INPUT: "Period format should be 'dd/mm/yyyy - dd/mm/yyyy' and includes valid dates.",
        INVALID_PERIOD_ORDER: "Period start date should be earlier than period end date.",
        INVALID_PRICE: "Price should be positive number and less than [MAX_PRICE]",
        INVALID_FRACTIONAL_PRICE: "Price can not have more than two digit on fractional part.",
        ENTER_PRICE: "Please enter a price.",
        SELECT_COMPANY: "Please select a valid company."
    },

    PNL: {
        ALL_CP_LISTED: "All available counter parties will be listed if not filtered.",
        ALL_DIR_LISTED: "All available directions will be listed if not filtered.",
        LIGHT_COLUMNS: "When directions with 2+ countries are selected, their 2 country combinations will also be listed but they will be shown with lighter colors and will not be included to PnL calculations.",
        SUM_OF_BORDERS_HIDDEN: 'Previous and difference on Sum Of Borders are hidden if grouping is Hourly or Daily.',
        NO_LIQUIDATION: "There is not any saved liquidation."
    },

    CUSTOM_PNL: {
        INVALID_INPUT: 'Custom values should be number.',
        INVALID_FRACTIONAL_INPUT: 'Custom values can not have more than two digit on fractional part.',
        SUCCESSFULL_SAVE_MESSAGE: 'Custom values saved successfully.',
        NO_CUSTOM_PNL_FOUND: 'No custom PnL data found for selected date.',
        NO_CUSTOM_PNL_TO_SAVE: 'Please enter a valid value to save.',
        SUCCESSULL_DELETE_MESSAGE: 'Custom values deleted successfully.',
        DESCRIPTION_PLACEHOLDER: 'Open table edit mode to add description'
    },

    INTRADAY_RESULTS : {
        NO_RESULT_FOUND: 'No intraday results found for selected date.',
        COULD_NOT_GET_RESULTS: "Could not get available intraday results, please refresh the page.",
        INVALID_PRICE: 'Price values should be smaller than [PRICE].',
        INVALID_CAPACITY : 'Capacity values should be nonnegative number and smaller than [CAPACITY].',
        SUCCESSFUL_DELETE: 'Intraday results deleted successfully.',
        SUCCESSFULL_SAVE: 'Intraday results saved successfully.'
    },

    SERVICE_COSTS : {
        NO_RESULT_FOUND: 'No service cost found for selected date',
        INVALID_COST_VALUE: 'Cost values should be a nonnegative number.',
        EMPTY_START_DATE: 'Please select a valid start date.',
        EMPTY_COST_TYPE: 'Please select a valid cost type.',
        EMPTY_COST_TYPE_DESCRIPTION: 'Please select a valid cost type description.',
        EMPTY_UNIT: 'Please select a valid unit.',
        EMPTY_VALUE: 'Please enter a valid value',
        SUCCESSFULL_SAVE: 'Service costs saved successfully.',
        SUCCESSFUL_DELETE: 'Selected cost deleted successfully.',
        ASK_DELETE: 'Are you sure want to selected cost?'
    },

    DAILY_ENERGY_COSTS: {
        NO_DAILY_ENERGY_COSTS: "There are no daily energy costs on DAM Date: [DAM_DATE] and IDM Date: [IDM_DATE].",
        SEND_DAILY_ENERGY_COSTS_SUCCESSFUL: "Daily Energy Costs pdf sent successfully.",
        SEND_DAILY_ENERGY_COSTS_ERROR: "Daily Energy Costs pdf cannot be sent successfully."
    },

    TEIAS_DAILY_ENTRIES: {
        INVALID_DAILY_ENTRY: "TEIAS Daily Entries could not be fetched for [DATE].",
        INVALID_ADDITIONAL_CHARGE: "Additional charge could not be saved for TEIAS Invoice.",
    },

    CROSSBORDER_INVOICE: {
        WEEKLY_PERFORMA_SAVED: "Weekly Performa Saved Successfully.",
        WEEKLY_PAYMENT_DETAILS_SAVED: "Weekly Payment Details Saved Successfully.",
        HOURLY_RATES_SAVED: "Hourly Exchange Rates Saved Successfully.",
        NOT_FOUND: "Crossborder Invoice could not be calculated."
    }

};