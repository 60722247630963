import React from 'react';
import _ from 'lodash';
import store from "../store";

import { portalMessages } from './portalMessages';

export const generateUuid = () => {
    //source: https://stackoverflow.com/a/2117523

    return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, c =>
        (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
    );
};

export const isObject = (variable) => {
    if (typeof variable === 'object' && variable !== null)
        return true;
    return false;
};

export const isArray = (variable) => {
    return _.isArray(variable);
};

export const renderIfTrue = (condition, elementToRender) => {
    if (condition)
        return elementToRender;
    return;
};

// TODO: rename to proper function name, i.e dateToString etc. did not updated instantly to precent merge issues.
export const extractDate = dateTime => {
    return (new Date(dateTime)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
}

export const getDayAheadForGivenDate = dateTime => {
    return dateTime.setDate(dateTime.getDate() + 1);
}

export const getTwoDaysAheadForGivenDate = dateTime => {
    return dateTime.setDate(dateTime.getDate() + 2);
}

export const combineStringsForMessage = stringArr => {

    let combinedStr = stringArr.join(', ');

    if (stringArr.length > 1) {

        let lastCommaIndex = combinedStr.lastIndexOf(',');

        return `${combinedStr.substring(0, lastCommaIndex)} ${portalMessages.AND} ${combinedStr.substring(lastCommaIndex + 2, combinedStr.length)}`;
    }

    return combinedStr;
}

export const testingEnvironment = () => {
    if (process.env?.REACT_APP_VITUS_API?.includes('local') || process.env?.REACT_APP_VITUS_API?.includes('test'))
        return true;
    return false;
}

export const isDate = d => d instanceof Date && !isNaN(d);

export const getFirstMonthOfQuarter = quarter => {

    switch (`${quarter}`) {
        case '1':
            return 1;
        case '2':
            return 4;
        case '3':
            return 7;
        case '4':
            return 10;
        default:
            return 1;
    }
}

export const getQuarter = date => {

    switch (date.getMonth()) {
        case 0:
        case 1:
        case 2:
            return 1;

        case 3:
        case 4:
        case 5:
            return 2;

        case 6:
        case 7:
        case 8:
            return 3;

        case 9:
        case 10:
        case 11:
            return 4;

        default:
            return 0;
    }
}

export const getNumberClassBySign = (value) =>{
    value = Number(value);
    if (value > 0)
        return "v-positive";
    if (value < 0)
        return "v-negative";
}

export const getDate = (dayDifference, date) => {
    if (!date)
        date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + dayDifference);
    return date;
}

export const getCompanyOptions = () => {
    const companyOptions = store.getState().auth.companies.map(c => {
        return {
            value: c,
            label: <div>{c === "Vitus" ? "Vitus Commodities" : c}</div>,
            isDisabled: false
        };
    });

    return companyOptions;
}

export const getCompanyCheckboxOptions = () => {
    let companyOptions = {}
    store.getState().auth.companies.map((c, index) => {

        companyOptions[c] = { title: c === "Vitus" ? "Vitus Commodities" : c, checked: index === 0 }
    });

    return companyOptions;
}

export const isCompanySelectable = () => {
    return store.getState().auth.isCompanySelectable 
}