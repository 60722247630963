import './AuctionAllocations.css';

import React from 'react';
import _, { isEmpty, isNull } from 'lodash';

import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VTagInput from '../../components/VTagInput/VTagInput';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import VTable from '../../components/VTable/VTable';
import VDropdown from '../../components/VDropdown/VDropdown';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import { extractDate, getDayAheadForGivenDate, getCompanyOptions, isCompanySelectable } from '../../helpers/generalHelper';
import { handleApiError, alertError, alertSuccess, specifyErrorMessage } from '../../helpers/errorHelper';
import { portalMessages } from '../../helpers/portalMessages';
import { getBorders, getAuctionAllocations, saveAuctionAllocations, deleteAuctionAllocation } from '../../apis/vitusApi';
import { AddStyles } from '../../components/VTagInput/cusomized-react-tag-input/ReactTags';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorageHelper';
import { createExcelWithMultipleSheets } from '../../helpers/excelHelper';
import VFileUploadIcon from '../../components/VFileUploadIcon/VFileUploadIcon';
import { AuctionAllocationsExcelHelper } from './AuctionsAllocationExcelHelper';


class AuctionAllocations extends React.Component {

    hoursColumn = 'Hour';
    companyColumn = 'Company';
    hoursSubColumn = 'CET / Cap. Id';
    borders = [];
    directions = {};
    counterParties = {};
    spinner = new SpinnerManager(history.location.pathname);
    tableRef = React.createRef();
    EnergoviaExcelUnsupportedBorders = ['BG<>GR', 'TR<>GR'];
    TCAT_border = 'TR<>BG';
    TCAT_directions = {
        'TRBG:Energovia': {
            'originalDirection': 'TR-BG',
            'platform': 'Energovia',
        },
        'TRBG:Axpo': {
            'originalDirection': 'TR-BG',
            'platform': 'Axpo',
        },
        'BGTR:Energovia': {
            'originalDirection': 'BG-TR',
            'platform': 'Energovia',
        },
        'BGTR:Axpo': {
            'originalDirection': 'BG-TR',
            'platform': 'Axpo',
        }
    }

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED,
        InconsistentData: portalMessages.AUCTION_ALLOCATION.INCONSISTENT_DATA,
        MissingParameter: {
            "requested_date": portalMessages.DATE_SELECTION,
            "border": portalMessages.ADD_BORDER,
        },
        InvalidData: {
            Default: portalMessages.INVALID_GENERAL,
            "requested_date": portalMessages.INVALIED_REQUESTED_DATE
        }
    };

    state = {
        auctionDate: getDayAheadForGivenDate(new Date()),
        borderTags: [],
        bordersToDisplay: [],
        allTables: {},
        counterPartiesToDisplay: [],
        disableAllTabs: false,
        showNeedRefreshModal: false,
        inconsistentDataError: false,
        latestRequestedFilter: {},
        disableFilter: false,
        activeFilter: [],
        allTablesBeforeEnergoviaFileImport: {}
    }

    componentDidMount() {

        this.spinner.showSpinner('getBorders');

        getBorders()
            .then(response => {
                if (response.data.success) {
               
                    let localStorageBorders = getLocalStorage('auctionAllocations', 'tagInput', 'selectedBorders') || []
                
                    localStorageBorders = localStorageBorders.filter(f => response.data.success.borders.find(b => f.id === b.border));
                    if (!isEmpty(localStorageBorders)) {
                        this.setState({
                            borderTags: localStorageBorders
                        },() => {
                            this.setBorders(response.data.success.borders);
                            
                            let requestBody = this.createAuctionAllocationRequestBody();

                            let expectedAuctionAllocations = this.createExpectedAuctionAllocations(requestBody.filter.borders);

                            this.callGetAuctionAllocationsAsync(requestBody, expectedAuctionAllocations);
                        })

                    } else {
                        this.setBorders(response.data.success.borders);
                    }
                    
                } else {
                    this.setState({ showNeedRefreshModal: true });
                }

            }, error => {
                handleApiError(error);
                this.setState({ showNeedRefreshModal: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('getBorders');
            });
    }

    showErrorMessage(error) {
        const { message, errorType } = specifyErrorMessage(error, this.errorMessages);

        if (errorType === 'InconsistentData' && !this.state.inconsistentDataError)
            this.setState({ inconsistentDataError: true });

        if (message)
            alertError(message);
    }

    showSuccessMessage(message) {
        alertSuccess(message);
    }

    setBorders(borders) {

        borders.forEach(border => {

            if (border.border.split('<>').length === 2) {
                this.borders.push({
                    id: border.border,
                    text: border.border,
                    company: border.company,
                });

                this.directions[border.border] = [];

                border.directions.forEach(direction => {

                    let counterPartiesOfCurrentDirection = direction.counterParties.map(counterParty => { return counterParty });

                    this.directions[border.border].push(direction.direction);

                    this.counterParties[direction.direction] = [];

                    counterPartiesOfCurrentDirection.forEach(cParty => {
                        this.counterParties[direction.direction].push(cParty);
                    });
                });
            }
        });

        this.updateBordersToDisplay();

        this.setTCATBorders();
    }

    updateBordersToDisplay() {

        let orderedBorders = _.orderBy(this.borders, ['text'], ['asc']);

        let borderArr = orderedBorders.map(border => {
            return this.generateBorderTag(border.id);
        });

        if (isEmpty(this.state.borderTags)) {
            this.setState({ bordersToDisplay: borderArr });

        } else {

            let updatedBorderListToDisplay = borderArr.filter(s =>
                this.state.borderTags.filter(t => t.id === s.id).length === 0);

            this.setState({ bordersToDisplay: updatedBorderListToDisplay });
        }

    }

    setTCATBorders() {

        Object.keys(this.TCAT_directions).forEach(direction => {

            this.directions[this.TCAT_border].push(direction);

            this.counterParties[direction] = [];
            this.counterParties[direction].push('TCAT');
        });
    }

    createAuctionAllocationRequestBody() {
        let body = {
            filter: {
                date: extractDate(this.state.auctionDate),
                borders: []
            }
        };

        this.state.borderTags.forEach(tag => { body.filter.borders.push(tag.id) });

        return body;
    }

    formatTableData(tableDataToFormat) {
        let formattedArr = [];

        if (!isNull(tableDataToFormat)) {
            Object.keys(tableDataToFormat).forEach(hour => {
                if (tableDataToFormat[hour] !== "")
                    formattedArr.push({
                        hour: parseInt(hour),
                        allocation: parseInt(tableDataToFormat[hour])
                    });
            });
        }

        return formattedArr;
    }

    createDirectionObjToSaveAllocation(columnToUpdate, counterParty) {

        let currentColumn = null;

        if (!isEmpty(this.state.allTablesBeforeEnergoviaFileImport)) {

            currentColumn = this.state.allTablesBeforeEnergoviaFileImport[counterParty]
                .find(i => i.direction === columnToUpdate.direction);

        } else {
            currentColumn = this.state.allTables[counterParty]
                .find(i => i.direction === columnToUpdate.direction);
        }

        return {
            direction: currentColumn.originalDirection,
            previous_capacity_id: currentColumn.capacityId === '-' || currentColumn.capacityId === '' ? null : currentColumn.capacityId,
            updated_capacity_id: columnToUpdate.capacityId === '-' || columnToUpdate.capacityId === '' ? null : columnToUpdate.capacityId,
            allocation_id: currentColumn.allocationId,
            previous_data: this.formatTableData(currentColumn.data),
            updated_data: this.formatTableData(columnToUpdate.data),
            platform: currentColumn.platform
        }
    }

    createSaveAuctionAllocationsRequestBody(tableDataToUpdate, counterParty) {
        let body = {
            requested_date: extractDate(this.state.auctionDate),
            counterParty: counterParty,
            borders: []
        };

        tableDataToUpdate.forEach(columnToUpdate => {
            let found = false;

            if (!isEmpty(body.borders)) {

                for (let currentBorder of body.borders) {

                    if (currentBorder.border === columnToUpdate.border) {

                        currentBorder.directions.push(this.createDirectionObjToSaveAllocation(columnToUpdate, counterParty));

                        found = true;
                        break;
                    }
                }
            }

            if (!found) {

                let borderObj = {
                    border: columnToUpdate.border,
                    directions: []
                };

                borderObj.directions.push(this.createDirectionObjToSaveAllocation(columnToUpdate, counterParty));
                body.borders.push(borderObj);
            }

        });

        return body;
    }

    validateFilters() {
        if (isNull(this.state.auctionDate) && isEmpty(this.state.borderTags)) {
            this.showErrorMessage(portalMessages.ADD_DATE_AND_BORDER);
            return false;
        }

        if (isNull(this.state.auctionDate)) {
            this.showErrorMessage(portalMessages.DATE_SELECTION);
            return false;
        }

        if (isEmpty(this.state.borderTags)) {
            this.showErrorMessage(portalMessages.ADD_BORDER);
            return false;
        }

        return true;
    }

    createExpectedAuctionAllocations(bordersToMakeRequest) {
        let expectedArr = [];

        bordersToMakeRequest.forEach(border => {

            let directionsOfCurrentBorder = this.directions[border];

            directionsOfCurrentBorder.forEach(direction => {

                let counterPartiesOfCurrentDirection = this.counterParties[direction];

                counterPartiesOfCurrentDirection.forEach(counterParty => {

                    expectedArr.push({
                        border: border,
                        direction: direction,
                        counterParty: counterParty,
                        platform: counterParty === 'TCAT' ? this.TCAT_directions[direction].platform : '',
                        originalDirection: counterParty === 'TCAT' ? this.TCAT_directions[direction].originalDirection : direction
                    });
                });
            });
        });

        return _.groupBy(expectedArr, "counterParty");
    }

    createColumnInfo(border, direction, originalDirection, platform, header, data, capacityId, allocationId, company) {
        return {
            border: border,
            direction: direction,
            originalDirection: originalDirection,
            platform: platform,
            header: header,
            data: data,
            capacityId: capacityId,
            allocationId: allocationId,
            company: company
        };
    }

    setTableData(expectedAuctionAllocations, response) {

        let finalTables = {};
        let finalCounterParties = [];
        let expectedCounterParties = Object.keys(expectedAuctionAllocations);

        if (expectedCounterParties.includes('TCAT')) {
            let orderedCounterParties = expectedCounterParties.filter(cp => cp !== 'TCAT');
            orderedCounterParties.push('TCAT');
            expectedCounterParties = [...orderedCounterParties];
        }

        expectedCounterParties.forEach(counterParty => {
            let counterPartyData = [];

            if (isEmpty(response)) {
                expectedAuctionAllocations[counterParty].forEach(expected => {
                    counterPartyData.push(
                        this.createColumnInfo(
                            expected.border,
                            expected.direction,
                            expected.originalDirection,
                            expected.platform,
                            expected.direction,
                            null,
                            null,
                            null,
                            this.borders.find(b => b.id === expected.border)?.company)
                    );
                });

            } else {

                expectedAuctionAllocations[counterParty].forEach(expected => {

                    let found = false;

                    for (let result of response) {

                        if (expected.border === result.border) {

                            for (let fetched of result.directions) {

                                if (fetched["counter_party"] === counterParty
                                    && fetched["direction"] === expected.originalDirection
                                    && fetched["platform"] === expected.platform) {

                                    counterPartyData.push(
                                        this.createColumnInfo(
                                            expected.border,
                                            expected.direction,
                                            expected.originalDirection,
                                            expected.platform,
                                            expected.direction,
                                            fetched["data"],
                                            fetched["capacity_id"],
                                            fetched["allocation_id"],
                                            fetched["company"])
                                    );

                                    found = true;
                                    break;
                                }
                            }

                            if (found) break;
                        }
                    }

                    if (!found) {
                        counterPartyData.push(
                            this.createColumnInfo(
                                expected.border,
                                expected.direction,
                                expected.originalDirection,
                                expected.platform,
                                expected.direction,
                                null,
                                null,
                                null,
                                this.borders.find(b => b.id === expected.border)?.company)
                        );
                    }

                });
            }

            finalTables[counterParty] = counterPartyData;
            finalCounterParties.push(counterParty);

        });

        this.setState({
            allTables: finalTables,
            counterPartiesToDisplay: finalCounterParties,
            inconsistentDataError: false,
        });
    }

    callGetAuctionAllocationsAsync = async (requestBody, expectedAuctionAllocations) => {

        this.spinner.showSpinner('getAllocation');

        try {

            const response = await getAuctionAllocations(requestBody);

            if (response.data.success) {
                this.setState({
                    latestRequestedFilter: requestBody,
                    allTablesBeforeEnergoviaFileImport: {},
                    activeFilter: [
                        { label: "Date", value: requestBody.filter.date },
                        { label: "Border", value: [...requestBody.filter.borders] }
                    ]
                });

                this.setTableData(expectedAuctionAllocations, response.data.success.borders);
            }

            else if (response.data.error)
                this.showErrorMessage(response.data.error);

            else
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);

        } catch (error) {
            handleApiError(error);

        } finally {
            this.spinner.hideSpinner('getAllocation');
        }

    }

    callDeleteAuctionAllocations(allocationIdArr) {

        this.spinner.showSpinner('deleteAuctionAllocation');

        let requestBody = { allocation_id_list: allocationIdArr };

        deleteAuctionAllocation(requestBody)
            .then(response => {
                if (response.data.success) {

                    let expectedAuctionAllocations = this.createExpectedAuctionAllocations(this.state.latestRequestedFilter.filter.borders);

                    this.callGetAuctionAllocationsAsync(this.state.latestRequestedFilter, expectedAuctionAllocations);

                    this.showSuccessMessage(portalMessages.AUCTION_ALLOCATION.ALLOCATIONS_DELETED_SUCCESSFULLY);

                } else if (response.data.error) {
                    this.showErrorMessage(response.data.error);

                } else {
                    this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
                }

            }, error => {
                handleApiError(error);
            })
            .finally(() => {
                this.spinner.hideSpinner('deleteAuctionAllocation');
            });
    }

    callSaveAuctionAllocationsAsync = async (requestBody) => {

        this.spinner.showSpinner("saveAuctionAllocationsService");

        let result = false;

        try {
            let response;

            try {
                response = await saveAuctionAllocations(requestBody);

            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success)
                result = true;

            else if (response.data.error)
                this.showErrorMessage(response.data.error);

            else
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);


            return result;

        } finally {
            this.spinner.hideSpinner("saveAuctionAllocationsService");
        }

    }

    onShowButtonClick = () => {

        if (this.validateFilters()) {

            this.spinner.showSpinner('showButtonClick');

            let requestBody = this.createAuctionAllocationRequestBody();

            let expectedAuctionAllocations = this.createExpectedAuctionAllocations(requestBody.filter.borders);

            this.callGetAuctionAllocationsAsync(requestBody, expectedAuctionAllocations);

            this.spinner.hideSpinner('showButtonClick');
        }
    }

    onClearButtonClick = () => {
        let dayAhead = getDayAheadForGivenDate(new Date());

        this.setState({
            bordersToDisplay: _.orderBy([...this.borders], ['text'], ['asc']),
            borderTags: [],
            auctionDate: dayAhead
        });
    }

    onBorderTagsChanged = currentBorderTags => {
        this.setState({ borderTags: [...currentBorderTags] });
    }

    onBorderTagRemoved = currentBorderTags => {

        let removedTag = _.difference(this.state.borderTags, currentBorderTags)[0];

        this.setState({
            borderTags: [...currentBorderTags],
            bordersToDisplay: _.orderBy([...this.state.bordersToDisplay, removedTag], ['text'], ['asc'])
        }, () => {
            setLocalStorage('auctionAllocations', 'tagInput', 'selectedBorders', this.state.borderTags);
        });
    }

    generateBorderTag(value) {
        return { id: value, text: value };
    }

    addNewBorderTag = (newBorderTag) => {

        this.setState({
            borderTags: [...this.state.borderTags, newBorderTag],
            bordersToDisplay: this.state.bordersToDisplay.filter(i => i['id'] !== newBorderTag['id'])
        }, () => {
            setLocalStorage('auctionAllocations', 'tagInput', 'selectedBorders', this.state.borderTags);
        });

    }

    convertJsonToTable(data) {
        let headers = [[this.hoursColumn], [this.companyColumn]];
        let values = [];
        let directionData;

        values.push([this.hoursSubColumn]);

        for (let i = 1; i < 25; i++) values.push([i]);

        for (let i = 0; i < data.length; i++) {

            headers[0].push(data[i].header);
            headers[1].push(!data[i].company ? '-' : data[i].company);
            values[0].push(!data[i].capacityId ? '-' : data[i].capacityId);

            directionData = data[i].data;

            if (isNull(directionData)) {

                for (let i = 1; i < 25; i++) values[i].push('');

            } else {

                for (let j = 1; j < 25; j++) {
                    if (!directionData[j - 1] && directionData[j - 1] !== 0) {
                        values[j].push('');
                    }
                    else
                        values[j].push(directionData[j - 1]);
                }
            }
        }

        return {
            headers,
            values
        };
    }

    convertTableToJson(tableData, counterParty) {
        let directions = [];

        for (let i = 0; i < tableData.headers[0].length; i++) {

            if (tableData.headers[0] && tableData.headers[0][i] !== this.hoursColumn) {

                let currentHeader = tableData.headers[0][i];

                let columnInfo = this.state.allTables[counterParty].find(i => i.header === currentHeader);

                directions.push({
                    border: columnInfo.border,
                    allocationId: columnInfo.allocation_id,
                    direction: columnInfo.direction,
                    capacityId: tableData.values[0][i],
                    data: {
                        ...tableData.values.slice(1).map(value => {
                            return value[i];
                        })
                    }

                });

            }
        }

        return directions;
    }

    validateTableInputs(newTableData) {
        for (let values of newTableData.values.slice(1)) {
            for (let value of values) {
                if (value !== "") {
                    if (isNaN(value) || value < 0 || value > 200) return false;
                }
            }
        }

        return true;
    }

    convertEmptyValuesToZero(newTableData, counterParty) {

        // if a column has both numbers and empty fields, converts empty fields to zero.
        // if only capacity id updated and other all fields are empty, converts empty fields to zero for this column.

        let previousCapacityIdArr = this.state.allTables[counterParty].map(direction => {
            if (isNull(direction.capacityId))
                return '-';
            else
                return direction.capacityId;
        });

        let convertedTableData = {
            headers: [...newTableData.headers.map(i => { return [...i] })],
            values: [...newTableData.values.map(i => { return [...i] })]
        };

        let numberOfColumns = newTableData.values[0].length;
        let numberOfHours = convertedTableData.values.length;

        const isValueNotSet = (currentValue) => currentValue === "";

        for (let j = 1; j < numberOfColumns; j++) {
            let columnValues = [];

            // if capacity id set '', converts it '-'
            if (newTableData.values[0][j] === '')
                newTableData.values[0][j] = '-';

            for (let i = 1; i < numberOfHours; i++)
                columnValues.push(newTableData.values[i][j]);

            // checks if all columns are not empty
            if (!columnValues.every(isValueNotSet)) {
                for (let k = 1; k < numberOfHours; k++) {
                    if (convertedTableData.values[k][j] === "")
                        convertedTableData.values[k][j] = 0;
                }
                //  if all columns are empty and only capacity id updated, converts empty fields to zero.    
            } else if (newTableData.values[0][j] !== previousCapacityIdArr[j - 1]) {
                for (let k = 1; k < numberOfHours; k++)
                    convertedTableData.values[k][j] = 0;
            }

        }

        return convertedTableData;
    }

    saveTableChangesAsync = async (newTableData, counterParty) => {

        let updatedTableDataList = [];
        let result = false;

        try {

            if (this.validateTableInputs(newTableData)) {

                this.spinner.showSpinner('saveTableChanges');

                let convertedTableData = this.convertEmptyValuesToZero(newTableData, counterParty);

                Object.keys(this.state.allTables).forEach(currentCounterParty => {

                    if (counterParty === currentCounterParty) {
                        updatedTableDataList[counterParty] = this.convertTableToJson(convertedTableData, counterParty);

                    } else {
                        updatedTableDataList[currentCounterParty] = this.state.allTables[currentCounterParty];
                    }
                });

                let saveRequestBody = this.createSaveAuctionAllocationsRequestBody(updatedTableDataList[counterParty], counterParty);

                let updatedData = saveRequestBody.borders.find(border => {

                    let updatedDirection = border.directions.find(direction => {

                        if (!(isNull(direction.previous_capacity_id)
                            && isNull(direction.updated_capacity_id)
                            && isEmpty(direction.previous_data)
                            && isEmpty(direction.updated_data)))
                            return true;

                        return false;
                    });

                    return updatedDirection ? true : false;
                });

                if (!updatedData) {
                    alertError(portalMessages.AUCTION_ALLOCATION.NO_ALLOCATION_TO_UPDATE);
                    return false;
                }

                const serviceResult = await this.callSaveAuctionAllocationsAsync(saveRequestBody);

                if (serviceResult) {

                    let expectedAuctionAllocations = this.createExpectedAuctionAllocations(this.state.latestRequestedFilter.filter.borders);

                    await this.callGetAuctionAllocationsAsync(this.state.latestRequestedFilter, expectedAuctionAllocations);

                    this.showSuccessMessage(portalMessages.AUCTION_ALLOCATION.ALLOCATIONS_SAVED_SUCCESSFULLY);

                    result = true;
                }

            } else {
                this.showErrorMessage(portalMessages.AUCTION_ALLOCATION.NUMERIC_INPUT);
            }

            return result;

        } finally {
            this.spinner.hideSpinner('saveTableChanges');
        }

    }

    onTableDelete(counterParty) {
        this.spinner.showSpinner('tableDeleteClick');

        let directionsToDelete = this.state.allTables[counterParty].filter(i => !isNull(i.allocationId));
        let allocationIdArr = directionsToDelete.map(direction => { return direction.allocationId });

        this.callDeleteAuctionAllocations(allocationIdArr, counterParty);

        this.spinner.hideSpinner('tableDeleteClick');
    }

    checkTableHasAnAllocationId(counterParty) {

        for (let direction of this.state.allTables[counterParty])
            if (!isNull(direction.allocationId)) return true;

        return false;
    }

    setImportExcelErrorMessage(message) {
        this.showErrorMessage(message);
    }

    onOpenEditMode() {
        this.setState({ disableAllTabs: true, disableFilter: true });
    }

    onCloseEditMode() {
        this.setState({ disableAllTabs: false, disableFilter: false });

        if (!isEmpty(this.state.allTablesBeforeEnergoviaFileImport)) {
            this.setState({
                allTables: this.state.allTablesBeforeEnergoviaFileImport,
                allTablesBeforeEnergoviaFileImport: {}
            });
        }
    }

    updateIfInconsistentAsync = async () => {
        if (this.state.inconsistentDataError) {

            this.spinner.showSpinner('consistentData');

            let expectedAuctionAllocations = this.createExpectedAuctionAllocations(this.state.latestRequestedFilter.filter.borders);

            await this.callGetAuctionAllocationsAsync(this.state.latestRequestedFilter, expectedAuctionAllocations);

            this.spinner.hideSpinner('consistentData');
        }
    }

    onEnergoviaExcelImport(e) {

        this.spinner.showSpinner('onEnergoviaExcelImport');

        let directionsOfEnergovia = this.state.allTables['Energovia']
            .filter(i => !this.EnergoviaExcelUnsupportedBorders.includes(i.border))
            .map(j => j.direction);

        new AuctionAllocationsExcelHelper(e.target.files[0]).loadExcel(
            directionsOfEnergovia,
            (excelData) => this.updateTableAfterSpecialFileImport(excelData)
        );
    }

    updateTableAfterSpecialFileImport(excelData) {

        if (!isEmpty(excelData)) {

            let updatedTableData = {};

            Object.keys(this.state.allTables).forEach(cParty => {
                updatedTableData[cParty] = _.cloneDeep(this.state.allTables[cParty]);
            });

            Object.keys(excelData.values).forEach(direction => {

                let index = updatedTableData['Energovia'].findIndex(i => i.direction === direction);

                updatedTableData['Energovia'][index].data = excelData.values[direction].map(i => i.v);

                updatedTableData['Energovia'][index].capacityId = excelData.capacityIds[direction];
            });

            this.setState({
                allTablesBeforeEnergoviaFileImport: this.state.allTables,
                allTables: updatedTableData
            }, () => {
                if (!this.tableRef.current)
                    alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
                else
                    this.tableRef.current.onOpenEditMode();
            });

        } else {
            alertError(portalMessages.AUCTION_ALLOCATION.ENERGOVIA_FILE_IMPORT_ERROR);
        }

        this.spinner.hideSpinner('onEnergoviaExcelImport');
    }

    renderTableInATab(counterParty) {
        return (
            <div>
                <VTable
                    showTotal
                    staticHeaderButtons
                    ref={counterParty === 'Energovia' ? this.tableRef : null}
                    title=' '
                    simpleNumbers
                    customColumnClasses={{ 0: "v-column-narrow-bold" }}
                    onError={(message) => this.setImportExcelErrorMessage(message)}
                    exportFileName={`AuctionAllocations_${counterParty}_${extractDate(this.state.latestRequestedFilter.filter.date)}`}
                    inputType='text'
                    items={this.convertJsonToTable(this.state.allTables[counterParty])}
                    onSaveChangesAsync={(newTableData) => this.saveTableChangesAsync(newTableData, counterParty)}
                    readonlyColumns={[this.hoursColumn]}
                    onOpenEditMode={() => this.onOpenEditMode()}
                    onCloseEditMode={() => {
                        this.onCloseEditMode();
                        this.updateIfInconsistentAsync();
                    }}
                    onTableDelete={() => this.onTableDelete(counterParty)}
                    headerButtons={{
                        showExportExcelButton: true,
                        showImportExcelButton: true,
                        showEditButton: true,
                        showDeleteTableButton: this.checkTableHasAnAllocationId(counterParty)
                    }}
                />
            </div>
        );

    }

    renderTabContent() {
        let renderedArr = [];

        this.state.counterPartiesToDisplay.forEach(counterParty => {
            renderedArr.push(
                <VTab key={counterParty} eventKey={counterParty} title={counterParty} className="v-auction-allocation-tab">
                    <div className='v-auction-allocation-tab container'>
                        {
                            counterParty === "Energovia" &&
                            <div className="d-flex flex-row-reverse">
                                <div className="p-2">
                                    <VFileUploadIcon
                                        accept={['.xlsx', '.xls', '.xlsm']}
                                        labeltext={`Import Energovia File`}
                                        key='importTableIcon'
                                        onFileChanged={(e) => this.onEnergoviaExcelImport(e)}
                                    />
                                </div>
                            </div>
                        }
                        <div className='row'>
                            <div className='v-infinite-width'>
                                {this.renderTableInATab(counterParty)}
                            </div>
                        </div>
                    </div>
                </VTab>
            );
        });

        return renderedArr;
    }

    exportAll() {
        const counterParties = this.state.allTables;

        const sheets = Object.keys(counterParties).map(c => {
            return { name: c, data: this.convertJsonToTable(counterParties[c]) };
        });

        createExcelWithMultipleSheets('AuctionAllocations_' + this.state.latestRequestedFilter.filter.date, sheets);
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Auction Allocations">
                    <VFilterContainer showActiveFilter activeFilter={this.state.activeFilter}>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                            </div>
                            <div>
                                <VDatePicker
                                    selectedDate={this.state.auctionDate}
                                    onSelectedDateChange={(selectedDate) => this.setState({
                                        auctionDate: selectedDate
                                    })}
                                    maxDate={getDayAheadForGivenDate(new Date())}
                                    disabled={this.state.disableFilter}
                                />
                            </div>
                        </div>
                    
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Border
                            </div>
                            <div className="v-filter-taginput">
                                <VTagInput
                                    disabled={this.state.disableFilter}
                                    addStyle={AddStyles.Textbox}
                                    placeholder='Add Border'
                                    tags={this.state.borderTags}
                                    onOrderChanged={this.onBorderTagsChanged}
                                    onTagDeleted={this.onBorderTagRemoved}
                                    onInternalAddition={this.addNewBorderTag}
                                    suggestions={this.state.bordersToDisplay}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button className="btn v-cancel-button v-filter-button"
                                onClick={this.onClearButtonClick}
                                disabled={this.state.disableFilter}>
                                <i aria-hidden="true" className="fa fa-eraser fa-fw" />
                                    Clear
                            </button>
                            <button className="btn v-button v-filter-button"
                                onClick={this.onShowButtonClick}
                                disabled={this.state.disableFilter}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />
                                    Show
                            </button>
                        </div>
                    </VFilterContainer>
                    {
                        !isEmpty(Object.keys(this.state.allTables)) &&
                        <VMainContainer>
                            <div className="v-auction-allocation-export-all">
                                <div className="v-link-button" onClick={() => this.exportAll()}>
                                    <i key='exportTable' aria-hidden="true"
                                        className="fa fa-cloud-download fa-fw fa-lg" />
                                    <span className="v-font">Export All</span>
                                </div>
                            </div>
                            <VTabs
                                disableAllTabs={this.state.disableAllTabs}
                                disabledAllMessage={portalMessages.TAB_SELECTION}>
                                {this.renderTabContent()}
                            </VTabs >
                        </VMainContainer>
                    }
                </VContentContainer>
                <NeedRefreshModal
                    show={this.state.showNeedRefreshModal}
                    message={portalMessages.BORDERS_NOT_FOUND}
                />
            </React.Fragment>
        );
    }
}

export default AuctionAllocations;