
import React from 'react';
import TChip from './TChip';

class TIbanChip extends React.Component {

    isValid = (iban) => {
        let error = null;

        if (!this.isIban(iban)) {
            error = `${iban} is not a valid IBAN.`;
        }

        return error;
    }

    isIban = (iban) => {
        return /([A-Z]{2})\s*\t*(\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d)/g.test(iban);
    }

    render() {
        return (
            <TChip
                id={this.props.id}
                key={`${this.props.title}_iban`}
                isValid={this.isValid}
                items={this.props.items}
                title={this.props.title}
                readOnly={this.props.readOnly}
                placeholder="Type or paste IBANs and press `Enter`..."
                isIban={true}
                setList={this.props.setIbanList}
            >
            </TChip>
        )
    }
}
export default TIbanChip;