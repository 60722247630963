import './vtable.css'
import React from 'react';
import _, { isEmpty, isObject } from 'lodash';
import { generateUuid, isArray, renderIfTrue } from '../../helpers/generalHelper';
import { createExcel, loadExcel } from '../../helpers/excelHelper';
import VFileUploadIcon from '../VFileUploadIcon/VFileUploadIcon';
import ConfirmationModal from '../../modals/ConfirmationModal/ConfirmationModal';
import { portalMessages } from '../../helpers/portalMessages';
import ExportFileModal from '../../modals/ExportFileModal/ExportFileModal';

class VTable extends React.Component {
    escapeKeyStr = "Escape";
    selectedRowClass = 'selected-row';
    tableId = generateUuid();

    state = {
        NOTIMPLEMENTED: false,
        editedItems: [],
        openEditMode: false,
        tableIsDirty: false,
        showConfirmCancelModal: false,
        showConfirmDeleteModal: false,
        showConfirmSendMailModal: false,
        showExportOptions: false,
        selectedCells: []
    };

    componentDidMount() {
        if (this.props.editModeOn)
            this.onOpenEditMode()
    }

    componentWillUnmount() {
        // since the table usages got too complicated there are some scenarios which dont
        // worth complicated solutions where the table state is updated after it is unmounted
        // like updating state when edit mode is closed but actually the table is not used any more
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate() {
        if (this.props.editModeOn && !this.state.openEditMode)
            this.onOpenEditMode()
    }

    onError(message) {
        if (this.props.onError)
            this.props.onError(message);
        else
            console.log(message);
    }

    tableOperationButtons = [
        {
            isVisible: () => { return this.showDeleteTableButton() },
            elementBody: (
                <label key='deleteTable'
                    title='Delete'
                    onClick={() => this.onTableDelete()}
                    className="v-link-button">
                    <i aria-hidden="true"
                        className="fa fa-trash-o fa-fw fa-lg v-icon-button"
                        style={{ "lineHeight": "6px" }} />
                </label>
            )
        },
        {
            isVisible: () => { return this.showResetTableButton() },
            elementBody: (
                <label key='resetTable'
                    title='Reset'
                    onClick={() => this.onTableReset()}
                    className="v-link-button">
                    <i aria-hidden="true"
                        className="fa fa-undo fa-fw fa-lg v-icon-button"
                        style={{ "lineHeight": "6px" }} />
                </label>
            )
        },
        {
            isVisible: () => { return this.showEditButton() },
            elementBody: (
                <label key='editTable'
                    title='Edit'
                    onClick={() => this.onOpenEditMode()}
                    className="v-link-button">
                    <i aria-hidden="true"
                        className="fa fa-pencil-square-o fa-fw fa-lg v-icon-button"
                        style={{ "lineHeight": "15px" }} />
                </label>
            )
        },
        {
            isVisible: () => { return this.showSendMailButton() },
            elementBody: (
                <label key='sendMail'
                    title='Send'
                    onClick={() => this.onSendMail()}
                    className="v-link-button">
                    <i aria-hidden="true"
                        className='fa fa-envelope-o fa-fw fa-lg v-icon-button'
                        style={{ "lineHeight": "6px" }} />
                </label>
            )
        },
        {
            isVisible: () => { return this.showSendMailAgainButton() },
            elementBody: (
                <label key='sendMail'
                    title='Send'
                    onClick={() => this.onSendMail()}
                    className="v-link-button">
                    <i aria-hidden="true"
                        className='fa fa-envelope fa-fw fa-lg v-icon-button'
                        style={{ "lineHeight": "6px" }} />
                </label>
            )
        },
        {
            isVisible: () => { return this.showCancelEditButton() },
            elementBody: (
                <label key='cancelEdit'
                    title='Cancel Edit'
                    onClick={() => this.onCancelEditMode()}
                    className="v-link-button">
                    <i aria-hidden="true"
                        className="fa fa-ban fa-fw fa-lg v-icon-button" />
                </label>
            )
        },
        {
            isVisible: () => { return this.showSaveChangesButton() },
            elementBody: (
                <label key='saveChanges'
                    title='Save Changes'
                    onClick={() => this.onSaveChanges()}
                    className="v-link-button">
                    <i aria-hidden="true"
                        className="fa fa-check fa-fw fa-lg v-icon-button" />
                </label>
            )
        },
        {
            isVisible: () => { return this.showImportTableButton() },
            elementBody: (
                <VFileUploadIcon
                    accept={['.xlsx', '.xls']}
                    title='Upload File'
                    key='importTableIcon'
                    onFileChanged={(e) => this.onImportExcel(e)}
                />
            )
        },
        {
            isVisible: () => { return this.showExportTableButton() },
            elementBody: (
                <label key='exportTable'
                    title='Download File'
                    onClick={() => this.downloadExcel()}
                    className="v-link-button">
                    <i aria-hidden="true"
                        className="fa fa-cloud-download fa-fw fa-lg v-icon-button"
                        style={{ "lineHeight": "16px" }} />
                </label>
            )
        }
    ]

    onImportExcel(e) {
        loadExcel(e.target.files[0], (data) => this.handleUploadedExcel(data));
    }

    handleUploadedExcel(data) {
        if (!this.validateImportedData(data)) {
            return false;
        }

        let items = this.state.editedItems; // can not use filterIgnoredCells function here because we need to set ignored from saved and not ignored from file

        let newValues = data.slice(this.getHeaderRowCount());

        this.onOpenEditMode()

        let headerShift, inputHooksToCalculate;

        for (let i = 0; i < items.values.length; i++) {
            inputHooksToCalculate = [];

            headerShift = 0;

            for (let j = 0; j < items.values[i].length; j++) {
                if (this.props.ignoredColumnsForFiles.includes(j)) {
                    headerShift++;
                    continue;
                }

                const fileCellIndex = j - headerShift; //file and table indices differ because of ignored columns

                items.values[i][j] = (newValues[i][fileCellIndex] === null || newValues[i][fileCellIndex] === undefined) ? '' : newValues[i][fileCellIndex];

                const { columnIndex, calculator } = this.getInputHook(j);

                if (columnIndex > -1) {
                    inputHooksToCalculate.push({ i, j, columnIndex, calculator });
                }
            }

            inputHooksToCalculate.forEach(({ i, j, columnIndex, calculator }) => {
                items.values[i][columnIndex] = calculator(items.values[i][j]);
            });
        }

        this.setState({ editedItems: JSON.parse(JSON.stringify(items)), tableIsDirty: true });

        return true;
    }

    validateImportedData(data) {
        if (!this.state.editedItems || this.state.editedItems.length < 1)
            this.setState({ editedItems: JSON.parse(JSON.stringify(this.props.items)) });

        let newValues = data.slice(this.getHeaderRowCount());

        const headerColumnCount = this.getHeaderColumnCount();

        const fileHeaders = data[0];

        // file does not have the ignored columns, so file column count is (all - ignored)
        if (fileHeaders.length !== headerColumnCount - this.props.ignoredColumnsForFiles.filter(i => i < headerColumnCount).length) {
            const errorMessage = portalMessages.VTABLE.XLS_MISMATCH_HEADER_COUNT;
            this.onError(errorMessage);
            this.setState({ errorMessage: errorMessage });
            return false;
        }

        // headerShift is used to handle ignored columns and match the file and table
        let headerShift = 0;

        for (let i = 0; i < headerColumnCount; i++) {
            if (this.props.ignoredColumnsForFiles.includes(i)) {
                headerShift++;
                continue;
            }

            if (fileHeaders[i - headerShift] !== this.getMainHeader(i)) {
                const errorMessage = portalMessages.VTABLE.XLS_MISMATCH_HEADER;
                this.onError(errorMessage);
                this.setState({ errorMessage: errorMessage });
                return false;
            }
        }

        const headerRowCount = this.getHeaderRowCount();

        headerShift = 0;

        for (let i = 0; i < headerColumnCount; i++) {
            if (this.props.ignoredColumnsForFiles.includes(i)) {
                headerShift++;
                continue;
            }

            const fileCellIndex = i - headerShift;

            for (let j = 0; j < this.props.items.values.length; j++) {
                if (this.isColumnReadonly(this.getMainHeader(i), i) || this.isRowReadonly(j)) {
                    if (newValues[j][fileCellIndex]?.toString()?.trim() !== this.props.items.values[j][i]?.toString()?.trim()
                        && Number(newValues[j][fileCellIndex]) !== Number(this.props.items.values[j][i])) {

                        const errorMessage = `Readonly cells can not be updated. ` +
                            `"${this.getMainHeader(i)}" value at row ${j + headerRowCount + 1} ` +
                            `is updated from ${this.props.items.values[j][i]} to ${newValues[j][fileCellIndex] ?? 'empty'}. Import cancelled.`;

                        this.onError(errorMessage);
                        this.setState({ errorMessage: errorMessage });
                        return false;
                    }
                }
            }
        }

        return true;
    }

    isColumnReadonly(column, columnIndex) {
        if (this.props.readonlyColumns && this.props.readonlyColumns.includes(column))
            return true;

        if (this.props.readonlyColumnIndices && this.props.readonlyColumnIndices.includes(columnIndex))
            return true;

        return false;
    }

    editRow(rowId) {
        // TODO: implement edit row like onSaveChanges, params will be both edited row and final version of items
        // TODO: needs modal
        console.log('Editing: ' + rowId);
        this.setState({ NOTIMPLEMENTED: true })
    }

    /**
     * Calls props.onDeleteRow(rowIndex)
     * rowIndex: index of row in items.values
     */
    deleteRow(rowIndex) {
        if (this.props.onDeleteRow)
            this.props.onDeleteRow(rowIndex);
    }

    onSendMail() {
        this.setState({ showConfirmSendMailModal: true });
    }

    confirmSendMail() {
        this.setState({ showConfirmSendMailModal: false });
        this.props.onSendMail();
    }

    onTableDelete() {
        if (this.props.onSpecialDelete)
            this.props.onSpecialDelete();
        else
            this.setState({ showConfirmDeleteModal: true });
    }

    onTableReset() {
        if (this.props.onReset)
            this.props.onReset();
    }

    confirmDelete() {
        this.setState({ showConfirmDeleteModal: false });
        this.props.onTableDelete();
    }

    onExternalSaveChagnes() {
        this.setState({
            tableIsDirty: false,
            openEditMode: false
        });

        if (this.props.onCloseEditMode)
            this.props.onCloseEditMode();
    }

    onSaveChanges = async () => {
        let result;

        if (this.props.onSaveChanges)
            result = this.props.onSaveChanges(this.state.editedItems);
        else if (this.props.onSaveChangesAsync)
            result = await this.props.onSaveChangesAsync(this.state.editedItems);

        if (result === undefined)
            console.log("onSaveChanges must return a boolean value to check if it is failed or not.");

        if (result) {
            this.onExternalSaveChagnes();
        }
    }

    showDeleteTableButton() {
        return this.props.headerButtons.showDeleteTableButton && !this.state.openEditMode;
    }

    showResetTableButton() {
        return this.props.headerButtons.showResetTableButton && !this.state.openEditMode;
    }

    showEditButton() {
        return this.props.headerButtons.showEditButton && !this.state.openEditMode;
    }

    showSendMailButton() {
        return this.props.headerButtons.showEmailButton
            && !this.state.openEditMode
            && (!this.props.items || !this.props.items.mailAlreadySent);;
    }

    showSendMailAgainButton() {
        return this.props.headerButtons.showEmailButton
            && !this.state.openEditMode
            && (this.props.items && this.props.items.mailAlreadySent);
    }

    showExportTableButton() {
        return this.props.headerButtons.showExportExcelButton;
    }

    showImportTableButton() {
        return this.props.headerButtons.showImportExcelButton && !this.state.openEditMode;
    }

    isBeingEdited() {
        return this.state.openEditMode;
    }

    handleIgnoredItemsIfExist(items, filterIgnoredColumns) {
        if (filterIgnoredColumns)
            return {
                headers: this.filterIgnoredCells(items.headers),
                values: this.filterIgnoredCells(items.values)
            };

        return items;
    }

    getOriginalItems(filterIgnoredColumns = false) {
        return this.handleIgnoredItemsIfExist(this.props.items, filterIgnoredColumns);
    }

    getEditedItems(filterIgnoredColumns = false) {
        return this.handleIgnoredItemsIfExist(this.state.editedItems, filterIgnoredColumns);
    }

    showCancelEditButton() {
        return this.state.openEditMode;
    }

    showSaveChangesButton() {
        return this.state.openEditMode && !this.props.headerButtons.hideSaveButton;
    }

    getTitle() {
        if (this.props.items && this.props.items.title)
            return this.props.items.title;
        return this.props.title;
    }

    onOpenEditMode() {
        this.setState({
            editedItems: JSON.parse(JSON.stringify(this.props.items)),
            selectedCells: [],
            openEditMode: true,
            tableIsDirty: false,
        });

        if (this.props.onOpenEditMode)
            this.props.onOpenEditMode();
    }

    onCancelEditMode() {
        if (this.state.tableIsDirty)
            this.setState({ showConfirmCancelModal: true });
        else
            this.confirmEditCancelling();
    }

    confirmEditCancelling() {
        this.setState({
            editedItems: [],
            showConfirmCancelModal: false,
            tableIsDirty: false,
            openEditMode: false
        });

        if (this.props.onCloseEditMode)
            this.props.onCloseEditMode();
    }

    downloadExcel() {
        if (this.state.openEditMode)
            this.setState({ showExportOptions: true });
        else
            this.createExcelFile(this.props.items);
    }

    filterIgnoredCells(rowArray) {
        let ignoredIndices = this.props.ignoredColumnsForFiles;

        if (!ignoredIndices || ignoredIndices.length === 0)
            return rowArray;

        const copy = rowArray.map(r => [...r]); // deep copy not to affect original row array

        //indices must be numbers, sort will not work correctly for strings and will break the process
        ignoredIndices = ignoredIndices.map(i => Number(i));
        ignoredIndices.sort();

        // remove ignored columns on reverse order not to be affected by index changes
        for (let i = ignoredIndices.length - 1; i >= 0; i--)
            copy.forEach(r => r.splice(ignoredIndices[i], 1));

        return copy;
    }

    createExcelFile(items) {
        createExcel(
            this.filterIgnoredCells(this.getAllHeaders()),
            this.filterIgnoredCells(items.values),
            this.props.exportFileName || this.getTitle() || 'VitusTableExcel'
        );

        if (this.state.showExportOptions)
            this.setState({ showExportOptions: false });
    }

    renderOperationHeaders() {
        let operationButtons;

        if (!this.props.staticHeaderButtons)
            operationButtons = this.prepareOperationButtons()

        if (this.getTitle().length < 1 && (!operationButtons || operationButtons.length < 1))
            return;

        return (
            <tr className='v-main-header'>
                <th className='v-main-header'
                    colSpan={this.getHeaderColumnCount() + (this.renderRowButtons() ? 1 : 0)}>
                    {this.getTitle()}
                    <div style={{ float: 'right' }}>
                        {operationButtons}
                    </div>
                </th>
            </tr>
        );
    }

    prepareOperationButtons() {
        const visibleButtons = this.tableOperationButtons.filter(b => b.isVisible());

        if (visibleButtons.length < 1)
            return [];

        return visibleButtons.map(b => {
            if (b.isVisible())
                return b.elementBody;
            return "";
        });
    }

    rowOpsExist() {
        if (this.checkRowOpsExist === undefined)
            this.checkRowOpsExist = Object.values(this.props.rowButtons).some(c => c);

        return this.checkRowOpsExist;
    }

    getMainHeaderArray() {
        let headers;

        if (isArray(this.props.items.headers[0]))
            headers = this.props.items.headers[0];
        else
            headers = this.props.items.headers;

        return headers;
    }

    getMainHeader(cellNumber) {
        let headers = this.getMainHeaderArray();
        return this.getHeaderName(headers[cellNumber]);
    }

    getHeaderName(header) {
        if (isObject(header))
            return header.name;
        return header;
    }

    getHeaderColumnCount() {
        let headers = this.getMainHeaderArray();

        return headers.length;
    }

    getHeaderRowCount() {
        if (isArray(this.props.items.headers[0]))
            return this.props.items.headers.length;
        return 1;
    }

    getHeaderId(cellNumber) {
        return 'H' + cellNumber;
    }

    getMainHeaders() {
        if (isArray(this.props.items.headers[0]))
            return this.props.items.headers[0];
        return this.props.items.headers;
    }

    getAllHeaders() {
        if (isArray(this.props.items.headers[0]))
            return this.props.items.headers;
        else
            return [this.props.items.headers];
    }

    onGetHeaderClass(colIndex, columnCount) {
        if (this.props.onGetHeaderClass)
            return this.props.onGetHeaderClass(colIndex, columnCount);
        return '';
    }

    renderHeaders() {
        if (!this.props.items || _.isEmpty(this.props.items))
            return;

        const rowOpsExist = this.rowOpsExist();

        let cellNumber = 0;

        const headers = this.getAllHeaders();

        const headerColumnCount = this.getHeaderColumnCount();

        const textHeaders = headers.map(headerRow => {
            return (
                <tr key={'HR' + cellNumber} className='v-text-headers'>
                    {
                        headerRow.map(header => {
                            cellNumber++;
                            const id = this.getHeaderId(cellNumber);
                            return (
                                <th key={id} id={id}>
                                    <div className={`v-header-wrapper ${this.onGetHeaderClass(cellNumber, headerColumnCount)}`}>
                                        <div className={`v-header-content ${this.getCustomColumnClass((cellNumber - 1) % headerColumnCount)}`}>
                                            {
                                                isObject(header) ?
                                                    <span dangerouslySetInnerHTML={{ __html: header.html }} />
                                                    :
                                                    header
                                            }
                                        </div>
                                    </div>
                                </th>
                            )
                        })
                    }
                    {
                        /* Extra column for row operations */
                        (rowOpsExist && !this.state.openEditMode) &&
                        <th key={this.getHeaderId(cellNumber++)}></th>
                    }
                </tr>
            )
        });

        return (
            <thead>
                {this.renderOperationHeaders()}
                {textHeaders}
            </thead>
        );
    }

    renderBody() {
        if (!this.props.items || _.isEmpty(this.props.items))
            return;

        let rowNumber = 0;

        const rows = this.props.items.values.map(row => {
            return this.renderRow(row, rowNumber++);
        });

        if (this.props.showAverage) {
            const avgRow = this.getAvgOfPositives(this.props.items.values);
            rows.push(this.renderRow(avgRow, 'avg', 'v-footer-row'));
        }

        if (this.props.showTotal) {
            const totalRow = this.getTotalOfColumnValues(this.props.items.values);
            rows.push(this.renderRow(totalRow, 'total', 'v-footer-row'));
        }

        if (this.props.showSpecialAgg) {
            const aggregatedRow = this.getSpecialAggOfColumnValues(this.props.items);
            rows.push(this.renderRow(aggregatedRow, 'agg', 'v-footer-row'))
        }

        return (
            <tbody>
                {rows}
            </tbody>
        );
    }

    createSpecialRow({
        rowName,
        values,
        cellSelector = () => { return true; },
        reducer = (column) => column.reduce((a, b) => a + b)
    }) {
        const columns = [];

        for (let i = 0; i < values[0].length - 1; i++) columns.push([]);

        for (let i = 0; i < values.length; i++) {
            for (let j = 0; j < values[i].length - 1; j++) {
                if (isNaN(values[i][j + 1]))
                    continue;
                const currentNum = Number(values[i][j + 1]);
                if (cellSelector(currentNum))
                    columns[j].push(currentNum);
            }
        }

        const specialRow = [rowName];

        for (let i = 0; i < columns.length; i++) {
            if (columns[i].length > 0)
                specialRow.push(parseFloat(reducer(columns[i])).toFixed(2));
            else
                specialRow.push(parseFloat(0).toFixed(2));
        }

        return specialRow;
    }


    getAvgOfPositives(values) {
        return this.createSpecialRow(
            {
                rowName: 'Avg',
                values,
                cellSelector: (num) => num > 0,
                reducer: (column) => column.reduce((a, b) => a + b) / column.length
            }
        );
    }

    getTotalOfColumnValues(values) {
        return this.createSpecialRow(
            {
                rowName: 'Total',
                values,
                reducer: (column) => column.reduce((a, b) => a + b)
            }
        );
    }

    getSpecialAggOfColumnValues(items) {
        const values = items.values
        const headers = items.headers
        const columns = [];

        for (let i = 0; i < values[0].length - 1; i++) columns.push([]);

        for (let i = 0; i < values.length; i++) {
            for (let j = 0; j < values[i].length - 1; j++) {
                if (isNaN(values[i][j + 1]))
                    continue;
                const currentNum = Number(values[i][j + 1]);
                if (currentNum > 0)
                    columns[j].push(currentNum);
            }
        }

        const specialRow = [''];
        for (let i = 0; i < columns.length; i++) {
            
            if (columns[i].length > 0) {
                if (headers[0][i+1]?.agg?.reducer) {
                    specialRow.push(headers[0][i+1]?.agg.reducer(columns[i]).toFixed(2));
                } else {
                    specialRow.push(parseFloat(0).toFixed(2));
                }
            } else {
                specialRow.push(parseFloat(0).toFixed(2));
            }
                
        }

        return specialRow
    }

    getRowId(rowNumber) {
        return 'R' + rowNumber;
    }

    renderRowButtons(rowNumber, cellNumber) {
        if (this.state.openEditMode)
            return;

        const rowId = this.getRowId(rowNumber);

        return (
            <td key={this.getCellId(rowNumber, cellNumber)}>
                {
                    renderIfTrue(this.props.rowButtons.showEditButton,
                        <i key='editRow' aria-hidden="true"
                            onClick={() => this.editRow(rowId)}
                            style={{ color: 'orange' }}
                            className="fa fa-pencil-square-o fa-fw fa-lg v-icon-button" />)
                }
                {
                    renderIfTrue(this.props.rowButtons.showDeleteButton && !this.isRowReadonly(rowNumber),
                        <i key='deleteRow' aria-hidden="true"
                            title='Delete Row'
                            onClick={() => this.deleteRow(rowNumber)}
                            className="fa fa-times fa-fw fa-lg v-icon-button" />
                    )
                }
            </td>
        );
    }

    cleanSelectedRows() {
        document.getElementById(this.tableId)
            .querySelectorAll(`.${this.selectedRowClass}`)
            .forEach(i => {
                i.className = i.className.replace(this.selectedRowClass, "").trim();
            });
    }

    rowClicked(e) {
        if (e.currentTarget.className.includes(this.selectedRowClass)) {
            e.currentTarget.className = e.currentTarget.className.replace(this.selectedRowClass, "").trim();
        }
        else {
            this.cleanSelectedRows();
            e.currentTarget.className += ' ' + this.selectedRowClass;
        }
    }

    isRowReadonly(rowIndex) {
        if (this.props.readonlyRowIndices && this.props.readonlyRowIndices.includes(rowIndex))
            return true;
        return false;
    }

    onGetRowClass(rowIndex) {
        if (!isNaN(rowIndex) && this.props.onGetRowClass)
            return this.props.onGetRowClass(rowIndex);
        return "";
    }

    renderRow(row, rowNumber, rowClassName = '') {
        let cellNumber = 0;

        return (
            <tr
                key={this.getRowId(rowNumber)}
                className={`${rowClassName} ${this.onGetRowClass(rowNumber)}`}
                onClick={(e) => this.rowClicked(e)}
            >
                {
                    row.map(cell => {
                        return this.renderCell(cell, rowNumber, cellNumber++);
                    })
                }
                {
                    renderIfTrue(this.rowOpsExist() && !isNaN(rowNumber),
                        this.renderRowButtons(rowNumber, cellNumber++))
                }
            </tr>
        );
    }

    getCellId(rowNumber, cellNumber) {
        return 'C-' + rowNumber + '-' + cellNumber;
    }

    getInputId(rowNumber, cellNumber) {
        return this.tableId + '_I-' + rowNumber + '-' + cellNumber;
    }

    getContentForCell(cell, cellNumber) {
        let content;
        const scale = this.getScale(cellNumber, cell);
        if (scale > -1)
            content = parseFloat(cell).toFixed(scale);
        else
            content = cell;

        return content;
    }

    inputOnKeyDown(e) {
        if (e.key === this.escapeKeyStr) {
            this.clearSelection();
        }
    }

    renderCell(cell, rowNumber, cellNumber) {
        const isReadOnly = this.isColumnReadonly(this.getMainHeader(cellNumber), cellNumber) || this.isRowReadonly(rowNumber);

        const activateEditMode = this.state.openEditMode
            && this.props.items
            && !isNaN(rowNumber);

        let content;


        if (activateEditMode) {
            const newCellValue = this.state.editedItems.values[rowNumber][cellNumber];

            if (isReadOnly) {
                content = this.getContentForCell(newCellValue, cellNumber);
            }
            else {
                content = (
                    <input
                        id={this.getInputId(rowNumber, cellNumber)}
                        onPaste={(e) => this.onPasteInput(e)}
                        onMouseOver={(e) => this.inputHovered(e, rowNumber, cellNumber)}
                        onMouseDown={(e) => this.inputMouseDown(e, rowNumber, cellNumber)}
                        onKeyDown={(e) => this.inputOnKeyDown(e)}
                        className={this.props.inputClass ? this.props.inputClass : ""}
                        tabIndex={(this.props.tabIndex * 1000) + rowNumber + (cellNumber * this.props.items.values.length)}
                        type='text'
                        value={(newCellValue === null || newCellValue === undefined) ? '' : newCellValue}
                        onChange={e => this.inputChanged(e, rowNumber, cellNumber)}
                        onClick={(e) => { e.stopPropagation(); }}
                    />
                );
            }
        }
        else {
            content = this.getContentForCell(cell, cellNumber);
        }


        return (
            <td key={this.getCellId(rowNumber, cellNumber)}
                className={`${(activateEditMode && !isReadOnly) ? "" : this.decideTextClass(cell, isReadOnly)} ${this.getCustomColumnClass(cellNumber)}`}>
                {content}
            </td>
        );
    }

    getCustomColumnClass(cellNumber) {
        if (_.isEmpty(this.props.customColumnClasses))
            return "";

        if (this.props.customColumnClasses[cellNumber]) {
            return this.props.customColumnClasses[cellNumber];
        }

        if (this.props.customColumnClasses['*']) {
            return this.props.customColumnClasses['*'];
        }

        return "";
    }

    inputChanged(e, rowNumber, cellNumber) {
        e.target.value = isNaN(e.target.value.replace(',', '.')) ? e.target.value : e.target.value.replace(',', '.')

        const invalidNumber = this.props.inputType === 'number'
            && (isNaN(e.target.value) && e.target.value !== '-');

        const specialWords = this.getSpecialWords(e.target.value, cellNumber);

        let newValues = { ...this.state.editedItems };

        if (invalidNumber && (!specialWords || specialWords.length === 0)) {
            // number is invalid and there are no special words
            const prevSpecial = this.getSpecialWords(newValues.values[rowNumber][cellNumber], cellNumber);
            const wasSpecial = prevSpecial && prevSpecial.length > 0;

            if (wasSpecial)
                return false;

            const wasValid = !isNaN(newValues.values[rowNumber][cellNumber]);

            if (wasValid)
                return false;

            e.target.value = "";
        }
        else {
            //either number is valid or it might be a special word

            if (specialWords) {
                // cell may contain special words
                if (specialWords.length === 1 && e.target.value.toLowerCase() !== specialWords[0].toLowerCase()) {
                    //there is only 1 special word and cell is not it
                    if (newValues.values[rowNumber][cellNumber].length - 1 === e.target.value.length
                        && isNaN(newValues.values[rowNumber][cellNumber])) //if last op was delete, empty the cell
                        e.target.value = "";
                    else //else (last op was not delete) complete it to special word
                        e.target.value = specialWords[0];
                }
            }
            else if (invalidNumber) {
                // cell does not contain any special word and it is an invalid number
                e.target.value = "";
            }

            const { columnIndex, calculator } = this.getInputHook(cellNumber);

            if (columnIndex > -1) {
                newValues.values[rowNumber][columnIndex] = calculator(e.target.value);
            }
        }

        newValues.values[rowNumber][cellNumber] = e.target.value;

        if (this.state.selectedCells.length > 0) {
            this.state.selectedCells.forEach(c => {
                newValues.values[c.rowNumber][c.cellNumber] = e.target.value;

                const { columnIndex, calculator } = this.getInputHook(c.cellNumber);

                if (columnIndex > -1) {
                    newValues.values[c.rowNumber][columnIndex] = calculator(e.target.value);
                }
            });
        }

        this.setState({ editedItems: newValues, tableIsDirty: true });
    }

    // cell selection operations
    currentSelectionOperation = null;
    inputMouseDown(e, rowNumber, cellNumber) {
        this.currentSelectionOperation = null;
        this.inputHovered(e, rowNumber, cellNumber);
    }

    inputHovered(e, rowNumber, cellNumber) {
        if (e.buttons === 1 || e.buttons === 3 || e.which === 1 || e.which === 3) {
            this.inputClicked(e, rowNumber, cellNumber)
        }
    }

    getAllSelectedInputs() {
        const currentTable = document.getElementById(this.tableId);
        return currentTable.querySelectorAll('.v-selected-cell');
    }

    clearSelection(e, exceptRow, exceptCell) {
        this.getAllSelectedInputs().forEach(i => {
            i.className = this.clearSelectedClass(i.className);
        });

        if (e) {
            this.setState({ selectedCells: [{ rowNumber: exceptRow, cellNumber: exceptCell }] });
            e.target.className = this.setSelectedClass(e.target.className);
        }
        else {
            this.setState({ selectedCells: [] });
        }
    }

    setSelectedClass(className) {
        if (!className.includes('v-selected-cell'))
            return (className + ' v-selected-cell').trim();
        return className;
    }

    clearSelectedClass(className) {
        return className.replaceAll('v-selected-cell', '').trim();
    }

    inputClicked(e, rowNumber, cellNumber) {
        const isSelected = e.target.className.includes('v-selected-cell');

        if (!e.ctrlKey && !e.metaKey) {
            if (!isSelected)
                this.clearSelection();
            return;
        }

        let result = false;

        if (this.currentSelectionOperation !== null) {
            if (this.currentSelectionOperation)
                result = this.selectCurrentInput(e, rowNumber, cellNumber);
            else
                result = this.removeSelectionInput(e, rowNumber, cellNumber);
        }
        else if (!isSelected) {
            if (!this.currentSelectionOperation)
                this.currentSelectionOperation = 'v-selected-cell';
            result = this.selectCurrentInput(e, rowNumber, cellNumber);
        }
        else {
            if (!this.currentSelectionOperation)
                this.currentSelectionOperation = '';
            result = this.removeSelectionInput(e, rowNumber, cellNumber);
        }
        if (!result) {
            this.clearSelection(e, rowNumber, cellNumber);
        }
    }

    selectCurrentInput(e, rowNumber, cellNumber) {
        if (this.state.selectedCells.find(s => s.rowNumber === rowNumber && s.cellNumber === cellNumber))
            return true;

        let selectedCells = this.state.selectedCells;

        const uniqeCells = [...new Set(this.state.selectedCells.map(s => s.cellNumber))];
        if (uniqeCells.indexOf(cellNumber) < 0)
            uniqeCells.push(cellNumber)

        const isRectengle = this.state.selectedCells.length > 0
            && (this.state.selectedCells[0].cellNumber !== cellNumber
                || uniqeCells.length > 1);

        if (isRectengle) {
            //rectangle selection
            const uniqeRows = [...new Set(this.state.selectedCells.map(s => s.rowNumber))];
            if (uniqeRows.indexOf(rowNumber) < 0)
                uniqeRows.push(rowNumber)

            uniqeRows.forEach(r => {
                uniqeCells.forEach(c => {
                    if (!this.state.selectedCells.find(s => s.rowNumber === r && s.cellNumber === c)) {
                        selectedCells.push({ rowNumber: r, cellNumber: c });
                        const currentInput = document.getElementById(this.getInputId(r, c));
                        currentInput.className = this.setSelectedClass(currentInput.className)
                    }
                });
            })
        }
        else {
            //single column selection
            e.target.className = this.setSelectedClass(e.target.className);
            selectedCells.push({ rowNumber, cellNumber })
        }

        if (selectedCells.length === 1)
            this.setState({ selectedCells: selectedCells });
        else
            this.setState({ selectedCells: selectedCells });

        return true;
    }

    removeSelectionInput(e, rowNumber, cellNumber) {
        e.target.className = this.clearSelectedClass(e.target.className);
        let selectedCells = this.state.selectedCells.filter(c => (c.rowNumber !== rowNumber || c.cellNumber !== cellNumber));
        this.setState({ selectedCells: selectedCells });
        return true;
    }

    onPasteInput(e) {
        if (this.state.selectedCells.length === 0)
            return;

        let clipboardData, pastedData;

        clipboardData = e.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData('Text');

        const newLineSeperators = /\r\n|\n\r|\n|\r/;
        const newColumnSeperators = /\t/;

        if (pastedData.search(newLineSeperators) > 0) {
            e.stopPropagation();
            e.preventDefault();

            pastedData = pastedData.split(newLineSeperators).filter(l => l.length !== 0).map(p => p.split(newColumnSeperators));

            let newValues = this.state.editedItems;

            const selectedCells = this.state.selectedCells;
            selectedCells.sort((a, b) => { return (a.rowNumber > b.rowNumber) ? 1 : ((a.rowNumber < b.rowNumber) ? -1 : (a.cellNumber > b.cellNumber ? 1 : -1)) });

            const uniqeCellsArr = [...new Set(selectedCells.map(s => s.cellNumber))];

            const uniqeCellsDict = {};
            uniqeCellsArr.forEach((u, idx) => {
                uniqeCellsDict[u] = idx;
            });

            let currentPastedRow;
            for (let i = 0; i < selectedCells.length; i++) {
                const c = selectedCells[i];

                const verticalPasteIdx = (c.rowNumber - selectedCells[0].rowNumber) % pastedData.length;
                currentPastedRow = pastedData[verticalPasteIdx];

                const horizontalPasteIdx = uniqeCellsDict[c.cellNumber] % currentPastedRow.length;
                newValues.values[c.rowNumber][c.cellNumber] = currentPastedRow[horizontalPasteIdx];

                const { columnIndex, calculator } = this.getInputHook(c.cellNumber);

                if (columnIndex > -1) {
                    newValues.values[c.rowNumber][columnIndex] = calculator(newValues.values[c.rowNumber][c.cellNumber]);
                }
            }

            this.setState({ editedItems: newValues, tableIsDirty: true });
        }
    }

    // cell selection operations end

    getInputHook(cellNumber) {
        if (!this.props.inputHooks
            || isEmpty(this.props.inputHooks)
            || (!this.props.inputHooks[cellNumber] && this.props.inputHooks[cellNumber] !== 0))
            return -1;

        return this.props.inputHooks[cellNumber];
    }

    getSpecialWords(cellValue, cellNumber) {

        const header = this.getMainHeader(cellNumber);

        //Can be extended with table wide special words, not needed for now.

        let specialWords;


        if (cellValue) {
            if (this.props.specialWords
                && this.props.specialWords[header]
                && this.props.specialWords[header].length > 0) {
                specialWords = this.props.specialWords[header].filter(s => s.toLowerCase().startsWith(cellValue.toLowerCase()));
            }

            if ((!specialWords || specialWords.length === 0)
                && this.props.specialWordsByIndices
                && this.props.specialWordsByIndices[cellNumber]
                && this.props.specialWordsByIndices[cellNumber].length > 0) {
                specialWords = this.props.specialWordsByIndices[cellNumber].filter(s => s.toLowerCase().startsWith(cellValue.toLowerCase()));
            }
        }

        return specialWords;
    }

    getScale(cellNumber, cell) {
        const header = this.getMainHeader(cellNumber);

        if ((_.isEmpty(this.props.scales)
            && _.isEmpty(this.props.scalesByIndices))
            || (!cell && cell !== 0)
            || isNaN(cell))
            return -1;

        if (this.props.scales[header]) {
            return this.props.scales[header];
        }

        if (this.props.scalesByIndices[cellNumber]) {
            return this.props.scalesByIndices[cellNumber];
        }

        if (this.props.scales['*'] && !this.isColumnReadonly(header, cellNumber)) {
            return this.props.scales['*'];
        }

        if (this.props.scalesByIndices['*'] && !this.isColumnReadonly(header, cellNumber)) {
            return this.props.scalesByIndices['*'];
        }

        return -1;
    }

    decideTextClass(text, isReadOnly) {
        if (!isNaN(text?.toString()?.replace(',', ''))) {
            let textNum = Number(text?.toString()?.replace(',', ''));

            if (!isReadOnly && !this.props.simpleNumbers) {
                if (textNum > 0)
                    return 'v-number v-positive';
                else if (textNum < 0)
                    return 'v-number v-negative';
            }

            return 'v-number';
        }
        else if (!isNaN(text?.toString()?.replaceAll(',', '')) && this.props.bigNumber) {
            let textNum = Number(text?.toString()?.replaceAll(',', ''));

            if (!isReadOnly && !this.props.simpleNumbers) {
                if (textNum > 0)
                    return 'v-number v-positive';
                else if (textNum < 0)
                    return 'v-number v-negative';
            }

            return 'v-number';
        }
        return "v-text";
    }

    render() {
        return (
            <React.Fragment>
                {
                    renderIfTrue(this.state.NOTIMPLEMENTED,
                        <div style={{ fontWeight: '800', color: 'red' }}>
                            NOT IMPLEMENTED! This div will be deleted when the table is complete.
                        </div>)
                }
                {
                    this.props.staticHeaderButtons &&
                    <div className='v-margin-table-buttons-outer'>
                        <div className='v-margin-table-buttons-inner'>
                            {
                                this.prepareOperationButtons().map(icon => {
                                    if (icon.type === 'label') {
                                        return (
                                            <span className='v-link-button'
                                                key={icon.props.title}
                                                onClick={icon.props.onClick}>
                                                {icon}
                                                {icon.props.title}
                                            </span>
                                        );
                                    }
                                    else {
                                        return React.cloneElement(
                                            icon,
                                            { labeltext: icon.props.title }
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>
                }
                <div className='vTableWrapper'>
                    <table id={this.tableId}
                        className="vTable table table-striped table-borderless table-hover table-responsive v-table-fit">
                        {this.renderHeaders()}
                        {this.renderBody()}
                    </table>
                    {
                        this.props.endOfTableMark
                        && <div id={this.props.endOfTableMark}></div>
                    }
                    <ConfirmationModal
                        show={this.state.showConfirmCancelModal}
                        message={portalMessages.VTABLE.UNSAVED_CHANGES}
                        cancelText='Discard changes and cancel'
                        onCancel={() => this.confirmEditCancelling()}
                        confirmText='Stay on edit mode'
                        onConfirm={() => this.setState({ showConfirmCancelModal: false })}
                    />
                    {
                        !this.props.onSpecialDelete &&
                        <ConfirmationModal
                            show={this.state.showConfirmDeleteModal}
                            message={this.props.deleteMessage}
                            cancelText='Cancel'
                            onHide={() => this.setState({ showConfirmDeleteModal: false })}
                            onCancel={() => this.setState({ showConfirmDeleteModal: false })}
                            confirmText='Delete'
                            onConfirm={() => this.confirmDelete()}
                        />
                    }
                    <ConfirmationModal
                        show={this.state.showConfirmSendMailModal}
                        message={this.props.sendMailMessage}
                        cancelText='Cancel'
                        onHide={() => this.setState({ showConfirmSendMailModal: false })}
                        onCancel={() => this.setState({ showConfirmSendMailModal: false })}
                        confirmText='Send'
                        onConfirm={() => this.confirmSendMail()}
                    />
                    <ExportFileModal
                        show={this.state.showExportOptions}
                        onHide={() => this.setState({ showExportOptions: false })}
                        onExportEdited={() => this.createExcelFile(this.getEditedItems())}
                        onExportOriginal={() => this.createExcelFile(this.props.items)}
                    />
                </div>
            </React.Fragment>
        );
    }
};

VTable.defaultProps = {
    tabIndex: 1,
    inputType: 'text',
    scales: {},
    scalesByIndices: {},
    ignoredColumnsForFiles: [],
    customColumnClasses: {},
    showAverage: false,
    showTotal: false,
    showSpecialAgg: false,
    deleteMessage: 'Are you sure you want to delete the table?',
    sendMailMessage: 'Are you sure you want to send email for table?',
    headerButtons: {
        // WARNING: all default values in dictionary must be false!
        showEmailButton: false,
        showImportExcelButton: false,
        showExportExcelButton: false,
        showEditButton: false,
        showDeleteTableButton: false,
        showResetTableButton: false,
        hideSaveButton: false
    },
    rowButtons: {
        // WARNING: all default values in dictionary must be false!
        showEditButton: false,
        showDeleteButton: false
    },
    bigNumber: false
}

export default VTable;

export const AggType = {
    sum: {
        value: 'SUM',
        reducer: (column) => column.reduce((a, b) => a + b)
    },
    avg: {
        value: 'AVG',
        reducer: (column) => column.reduce((a, b) => a + b) / column.length
    }
};