import React from 'react';
import { Spinner } from 'react-bootstrap';
import { renderIfTrue } from '../../helpers/generalHelper';

const VButton = ({ isLoading, ...rest }) => {
    return (
        <button {...rest} disabled={isLoading}>
            {renderIfTrue(isLoading,
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />)}
            {rest.children}
        </button>
    );
};

export default VButton;