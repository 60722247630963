import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import './TChip.css';

const useStyles = (theme) => ({
    tagItems: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 5,
    },
    title: {
        fontSize: 12,
    }
});

class TChip extends React.Component {
    state = {
        //items: this.props.items || [],
        value: "",
        error: null,
        //readOnly: this.props.readOnly
    };

    isValid(item) {
        let error = null;

        if (this.props.isValid) {
            error = this.props.isValid(item)

            if (error) {
                this.setState({ error });
                return false;
            }
        }

        if (this.isInList(item)) {
            error = `${item} has already been added.`;
        }

        if (error) {
            this.setState({ error });

            return false;
        }

        return true;
    }

    isInList(item) {
        return this.props.items.includes(item);
    }

    handleDelete = item => {
        this.props.setList(this.props.items.filter(i => i !== item));
    };

    handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.value.trim();

            if (value && this.isValid(value)) {
                this.setState({value: ""})
                this.props.setList([...this.props.items, this.state.value]);
            }
        }
    };

    handleChange = evt => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handlePaste = evt => {
        evt.preventDefault();

        var paste = evt.clipboardData.getData("text");

        if (this.props.isEmail) {
            var emails = paste.match(/[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/g);

            if (emails) {
                let toBeAdded = emails.filter(email => !this.isInList(email));

                this.props.setList([...this.props.items, ...toBeAdded])
            }
        } else if (this.props.isIban) {

            var ibans = paste.match(/([A-Z]{2})\s*\t*(\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d)/gm);

            if (ibans) {
                let toBeAdded = ibans.filter(iban => !this.isInList(iban));

                this.props.setList([...this.props.items, ...toBeAdded])
            }
        } else {
            if (!this.isInList(paste)) {
                this.props.setList([...this.props.items, paste])
            }
        }

        evt.preventDefault();

    };

    render() {
        const { classes } = this.props;
        return (
            <Paper component="ul" className={classes.tagItems}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {this.props.title}
                </Typography>
                <div key={`${this.props.title}_${this.props.id}_div`}>
                    {this.props.items.map(item => {
                            return(<Chip
                                key={`${item}_${this.props.id}_chip`}
                                className="tag-item"
                                size="small"
                                label={item}
                                disabled={this.props.readOnly}
                                onDelete={() => !this.props.readOnly ? this.handleDelete(item) : null}
                                />);
                            }
                        )
                    }
                </div>
                <input
                    style={{ display: `${this.props.readOnly ? "none" : "block"}` }}
                    className={"input " + (this.state.error && " has-error")}
                    value={this.state.value}
                    placeholder={this.props.placeholder}
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                    onPaste={this.handlePaste}
                />
                {this.state.error && <p className="error">{this.state.error}</p>}
            </Paper>

        );
    }
}

export default withStyles(useStyles)(TChip);