import React from 'react';
import { portalMessages } from '../../helpers/portalMessages';
import VBaseModal from '../VBaseModal/VBaseModal';

const ResetTableModal = (
    { show, onCancel, exchange, counterParty, onSetZero, onSetInitial, title, border, message }
) => {
    return (
        <VBaseModal
            dialogClassName="v-modal-30w"
            show={show}
            onHide={onCancel}
            title={title}
            closeButton={true}
            body={
                <div className='row'>
                    <div className='col v-modal-error-icon-col'>
                        <div className='v-modal-error-icon'>
                            <i className={`fa fa-question fa-3x`} />
                        </div>
                    </div>
                    <div className='col-10'>
                        <div style={{ padding: "0", paddingRight: "15px" }}>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col'>
                                        {message}
                                    </div>
                                </div>
                                {exchange &&
                                    <div className='row'>
                                        <div className='col'>
                                            Exchange:
                                    </div>
                                        <div className='col-7'>
                                            <strong>{exchange}</strong>
                                        </div>
                                    </div>}
                                {counterParty &&
                                    <div className='row'>
                                        <div style={{ whiteSpace: 'nowrap' }} className='col'>
                                            Counter Party:
                                        </div>
                                        <div className='col-7'>
                                            <strong>{counterParty}</strong>
                                        </div>
                                    </div>}
                                {
                                    border
                                    &&
                                    <div className='row'>
                                        <div style={{ whiteSpace: 'nowrap' }} className='col'>
                                            Border:
                                        </div>
                                        <div className='col-7'>
                                            <strong>{border}</strong>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            actions={
                <React.Fragment>
                    <button
                        className="btn v-cancel-button"
                        onClick={onCancel}>
                        Cancel
                    </button>
                    <button
                        className="btn v-button"
                        onClick={onSetInitial}>
                        {portalMessages.EXCHANGE_OFFERS.SET_INITIAL}
                    </button>
                    <button
                        className="btn v-button"
                        onClick={onSetZero}>
                        {portalMessages.EXCHANGE_OFFERS.SET_ZERO}
                    </button>
                </React.Fragment >
            }
        />
    );
};

export default ResetTableModal