import React from 'react';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer';
import RightSideContainer from '../../components/TTPageContainers/RightSideContainer';
import TTabs from '../../components/TTabs/TTabs';
import TabPanel from '../../components/TTabs/TabPanel';
import ContractDetail from '../../pages/Contract/ContractDetail';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import { handleApiError } from '../../utils/errorHandler';
import { getContract } from '../../api/services';
import history from '../../../history';


import './TNotification.css';

class TNotification extends React.Component {
    state = {
        notificationItem: null,
        tabInfo: [],
        defaultSelectedTabId: null,
    }

    spinner = new SpinnerManager(history.location.pathname);

    setTabs = (newTabs, newTabId) => {
        this.setState({ tabInfo: newTabs, defaultSelectedTabId: newTabId })
    }

    addNewTab(row) {
        this.setState({ defaultSelectedTabId: row.id })

        let ids = this.state.tabInfo.map(t => t.id)

        row.key = `${row.id}_${row.readOnly}`

        if (!ids.includes(row.id)) {
            this.setState({ tabInfo: [...this.state.tabInfo, row] })
        } else {

            let newTabList = this.state.tabInfo.map(tab => {
                if (tab.id === row.id) {
                    return row
                } else {
                    return tab
                }
            })

            this.setState({ tabInfo: newTabList })
        }
    }

    getNotificationColorClass(dataType) {
        if (dataType === "Company") {
            return "notification-company"
        } else if (dataType === "Contract") {
            return "notification-contract"
        } else if (dataType === "Market") {
            return "notification-market"
        } else if (dataType === "Book") {
            return "notification-book"
        }
        return "notification-default"
    }

    onClickNotificationDetail(itemId, itemType) {
        if (itemType === "Contract") {
            this.spinner.showSpinner('getNotificationDetail');

            getContract({ contract_id: itemId })
                .then(response => {
                    if (response.data.success) {
                        let successData = response.data.success.contract_details

                        let costList = successData.cost_list.map(x => ({
                            id: x.id,
                            value: x.id,
                            name: x.cost_type,
                            label: `${x.cost_type} ${x.country_code} ${x.broker}`,
                            currency: x.currency,
                            broker: x.broker,
                            cost: x.cost,
                            calculation: x.cost_calculation,
                            countryCode: x.country_code
                        }))

                        let documentList = successData.document_list.map(d => ({
                            id: d.id,
                            documentName: d.name
                        }))

                        let collateralList = successData.collateral_list.map(c => ({
                            collateralId: c.collateral_id,
                            tradeId: c.trade_id,
                            issuerName: c.issuer_name,
                            issuerId: c.issuer,
                            receiverName: c.receiver_name,
                            receiverId: c.receiver,
                            bankGuaranteeValidityDate: new Date(c.validity_date),
                            collateralWithoutTax: c.collateral,
                            statusOfValidity: c.is_valid ? true : false,
                            remainingDays: c.remaining_days,
                            tradingVenue: c.trading_venue,
                            collateralType: c.collateral_type,
                            deliveryType: c.delivery_type,
                            collateralRate: c.collateral_rate
                        }))

                        let contract = {
                            id: successData.contract_id,
                            tradeId: successData.trade_id,
                            name: successData.contract_name,
                            companyName: successData.company_name,
                            companyId: successData.company_id,
                            tradingVenue: successData.trading_venue,
                            contractType: successData.contract_type,
                            deliveryType: successData.delivery_type,
                            product: successData.product,
                            trader: successData.trader,
                            marketId: successData.market_id,
                            marketName: successData.market_name,
                            currency: successData.currency,
                            counterPartyId: successData.counter_party_id,
                            counterPartyName: successData.counter_party_name,
                            periodId: successData.period_id,
                            periodName: successData.period_name,
                            bookId: successData.book_id,
                            bookName: successData.book_name,
                            contractDateType: successData.contract_date_type,
                            paymentPeriod: successData.payment_period,
                            paymentTimePeriod: successData.payment_time_period,
                            paymentDate: successData.payment_date ? new Date(successData.payment_date) : null,
                            year: successData.year,
                            startDate: new Date(successData.start_date),
                            endDate: new Date(successData.end_date),
                            term: successData.term,
                            contractDate: new Date(successData.contract_date),
                            position: successData.position,
                            price: successData.price,
                            amount: successData.amount,
                            costList: costList,
                            documentList: documentList,
                            collateralList: collateralList
                        }

                        this.addNewTab({ ...contract, readOnly: true })

                    }
                }, error => {
                    handleApiError(error);
                }).finally(() => {
                    this.spinner.hideSpinner('getNotificationDetail');
                });
        }
    }

    getLeftComponent() {
        return (

            <div className="notification-cell">
                {
                    this.props.items.map(item => {
                        let className = ""
                        let notificationMessage = ""
                        if (item.operationType === "CREATE") {
                            className = `notification-green ${this.getNotificationColorClass(item.dataType)}`
                            notificationMessage = `${item.userName} added a new ${item.dataType}`
                        } else if (item.operationType === "EDIT") {
                            className = `notification-yellow ${this.getNotificationColorClass(item.dataType)}`
                            notificationMessage = `${item.userName} made an update on ${item.dataType}`
                        } else {
                            className = `notification-red ${this.getNotificationColorClass(item.dataType)}`
                            notificationMessage = `${item.userName} deleted ${item.dataType}`
                        }
                        return (
                            <div key={`${item.id}_${item.dataType}`} className={`notification ${className}`}>
                                <div className="notification-icon">
                                    {item.userName[0]}
                                </div>

                                <div className="notification-content">
                                    <div className="notification-title">
                                        <p className="notification-type">{item.dataType}</p>
                                        <p className="notification-date">{item.activityDate}</p>
                                    </div>
                                    <p className="notification-message">
                                        {notificationMessage}:
                                        <a
                                            style={{ color: "#222426" }}
                                            href="" onClick={(e) => {
                                                e.preventDefault();
                                                this.onClickNotificationDetail(item.id, item.dataType);
                                            }}>{item.name}
                                        </a>
                                    </p>
                                </div>

                            </div>

                        );
                    })
                }
            </div>
        );
    }

    getDetailComponent() {
        return (
            <RightSideContainer>
                <TTabs
                    key={this.state.defaultSelectedTabId}
                    defaultSelectedTabId={this.state.defaultSelectedTabId}
                    setTabs={this.setTabs}
                >
                    {

                        this.state.tabInfo.map((t) => {
                            return (
                                <TabPanel key={`${t.key}_tabpanel`} title={t.name} id={t.id}>
                                    <ContractDetail
                                        key={`${t.key}_ContractDetail`}
                                        item={t}
                                        readOnly={true}
                                        editable={false}
                                    >
                                    </ContractDetail>
                                </TabPanel>
                            )
                        })
                    }
                </TTabs>
            </RightSideContainer>
        )
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getLeftComponent()}
                    rightSideComponent={this.getDetailComponent()}
                >
                </TContentDetailContainer>
            </TContentContainer>
        );
    }
}

export default TNotification;