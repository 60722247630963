import './VOverlay.css';
import React, { useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

// https://react-bootstrap.github.io/components/overlays/

const VOverlay = ({ parentRef, show, message, onHide, placement }) => {
    const [showMessage, setShowMessage] = useState(show);

    React.useEffect(() => {
        setShowMessage(show);
    }, [show])

    return (
        <Overlay
            placement={placement || 'top'}
            rootClose
            target={parentRef}
            onHide={onHide}
            show={showMessage && show}
            container={parentRef.current}>
            <Popover
                className='v-popever'
                onClick={onHide}>
                <Popover.Content>{message}</Popover.Content>
            </Popover>
        </Overlay>
    );
};

export default VOverlay;