import './PnlDetails.css';

import { isArray, isEmpty, isObject, sortBy } from 'lodash';
import React from 'react';
import VCheckboxGroup from '../../../components/VCheckboxGroup/VCheckboxGroup';
import { generateUuid, getNumberClassBySign } from '../../../helpers/generalHelper';
import VSimplePopup from '../../../components/VSimplePopup/VSimplePopup';

class PnlDetails extends React.Component {
    maxTitleDebth = 1;
    rowClasses = ['v-row-group-1', 'v-row-group-2'];
    pnlAllTotalColumn = 'Summary';
    intradayColumnType = 'intraday';
    totalColumn = 'Total';
    pnlCustomColumn = 'Custom';
    capacityColumn = 'Capacity';
    weakColumnClass = 'v-weak-column';
    dailyAuctionsKey = 'daily_auctions'; //daily auctions will be hidden by their total columns, rest are hidden if whole column is 0
    resaleKey = 'resales';
    autosettleKey= 'auto_settle';
    dataGroupClasses = ['v-data-group-1', 'v-data-group-2'];
    visibleGroupCounter = 0;
    selectedRowClass = 'v-pnl-selected-row';
    tableId = generateUuid();

    referenceData = {
        mainHeader: 'prices',
        key: 'dt',
        title: 'Date (CET)'
    }

    // new subtitles can just be added to required column groups and rest of the
    // groups are expected to adapt new subtitle count without change.
    // "rowSpan: n" can be used to widen main header vertically

    orderCounter = 0;

    columnsConfig = {
        prices: {
            order: this.orderCounter++,
            title: 'Price',
            getSubTitle: [
                (data) => { return `${data.type}`; },
                (data) => { return <span>{`${data.country_code}`} {this.renderUnit(data)}</span>; }
            ],
            sort: (subDataDict) => { return sortBy(subDataDict, ['type', 'country_code']) }
        },
        [this.resaleKey]: {
            order: this.orderCounter++,
            title: 'Resale',
            getSubTitle: [
                (data) => { return `${data.direction}  (${data.tso})`; },
                (data) => { return `${data.counter_party}`; },
                (data) => { return <span>{`${data.column_type}`} {this.renderUnit(data)}</span>; }
            ],
            sort: (data) => {
                return sortBy(data, ['direction', 'counter_party', 'tso', 'column_type'])
            }
        },
        [this.autosettleKey]: {
            order: this.orderCounter++,
            title: 'Auto Settle',
            getSubTitle: [
                (data) => { return `${data.direction}  (${data.tso})`; },
                (data) => { return `${data.counter_party}`; },
                (data) => { return <span>{`${data.column_type}`} {this.renderUnit(data)}</span>; }
            ],
            sort: (data) => {
                return sortBy(data, ['direction', 'counter_party', 'tso', 'column_type'])
            }
        },
        [this.dailyAuctionsKey]: {
            order: this.orderCounter++,
            title: 'Daily Auctions',
            getSubTitle: [
                (data) => { return `${data.direction}`; },
                (data) => { return <span>{data.counter_party} <span className='pnl-tso-label'>({data.tso})</span></span>; },
                (data) => { return <span>{`${data.column_type}`} {this.renderUnit(data)}</span>; }
            ],
            sort: (data) => {
                return sortBy(data, ['direction', 'counter_party', 'platform', 'tso', 'column_type'])
            }
        },
        auction_clearing_prices: {
            order: this.orderCounter++,
            title: 'Monthly Auction Clearing Price',
            getSubTitle: [
                (data) => { return `${data.counter_party}`; },
                (data) => { return `${data.period}`; },
                (data) => { return `${data.direction}`; },
                (data) => { return <span>{`${data.type}`} {this.renderUnit(data)}</span>; }
            ],
            sort: (data) => { return sortBy(data, ['counter_party', 'period', 'direction', 'type']) }
        },
        exchange_rate_monthly: {
            order: this.orderCounter++,
            title: 'Monthly Exchange Rates',
            getSubTitle: [
                (data) => { return `${data.currency}`; },
                (data) => { return `${data.type}`; }
            ],
            sort: (data) => { return sortBy(data, ['currency', 'type']) }
        },
        service_fees: {
            order: this.orderCounter++,
            title: 'Fees',
            getSubTitle: [
                (data) => { return `${data.period}`; },
                (data) => { return `${data.description}${data.detail ? ' (' + data.detail + ')' : ''}`; },
                (data) => { return <span>{`${data.counter_party}`} {this.renderUnit(data)}</span>; }
            ],
            sort: (data) => { return sortBy(data, ['period', 'description', 'detail', 'counter_party']) }
        },
        physical_flows: {
            order: this.orderCounter++,
            title: 'Physical Flows',
            getSubTitle: [
                (data) => { return `${data.counter_party}`; },
                (data) => { return `${data.period}`; },
                (data) => { return <span>{`${data.direction}`} <span className='pnl-unit-label'>(MW)</span></span>; }
            ],
            sort: (data) => { return sortBy(data, ['counter_party', 'period', 'direction']) }
        },
        servicing_physical_flows: {
            order: this.orderCounter++,
            title: 'Physical Flows For Servicing',
            getSubTitle: [
                (data) => { return `${data.counter_party}`; },
                (data) => { return <span>{`${data.direction}`} <span className='pnl-unit-label'>(MW)</span></span>; }
            ],
            sort: (data) => { return sortBy(data, ['counter_party', 'direction']) }
        },
       exchange_rate_daily: {
            order: this.orderCounter++,
            title: 'Daily Exchange Rates',
            getSubTitle: [
                (data) => { return `${data.currency}`; },
                (data) => { return `${data.type}`; }
            ],
            sort: (data) => { return sortBy(data, ['currency', 'type']) }
        },
        pnl: {
            order: this.orderCounter++,
            title: 'PNL',
            className: 'v-pnl-column',
            getSubTitle: [
                (data) => { return `${data.direction}`; },
                (data) => { return `${data.type}`; }
            ],
            renderValue: (row) => {
                return (
                    row.desc ?
                        <VSimplePopup
                            value={row.v}
                            popUpText={row.desc}
                        />
                        :
                        row.v.toLocaleString()
                );
            },
            sort: (data) => {
                return sortBy(data, this.getPnlColumnGroupOrder)
            },
            getValueClass: getNumberClassBySign
        },
        pnl_detail_summary: {
            order: this.orderCounter++,
            title: 'Summary',
            getSubTitle: [
                (data) => { return `${data.type}`; }
            ],
            sort: (data) => {
                return sortBy(data, this.getPnlColumnGroupOrder)
            },
            getValueClass: getNumberClassBySign
        }
    }

    renderUnit(data) {
        return <span className='pnl-unit-label'>{`(${data.unit})`}</span>;
    }

    state = {
        visibleGroups: {},
        hideZeroColumns: true,
        selectionExist: false
    }

    areAllValuesZero(data) {
        return data.reduce((a, b) => a + Math.abs(b.v), 0) === 0;
    }

    filterEmptyColumns(data) {
        if (!data || isEmpty(data))
            return null;

        const elementsWithData = {};
        Object.keys(data).forEach(d => {
            if (data[d].length)
                elementsWithData[d] = data[d];
        });

        if (!this.state.hideZeroColumns)
            return elementsWithData;

        const dailyAuctionHiddenColumns = [];

        //do not show prices for daily auction and resale if capacity is 0
        const hideByCapacityKeys = [this.dailyAuctionsKey, this.resaleKey];
        hideByCapacityKeys.forEach(key => {
            if (!elementsWithData[key])
                return;

            elementsWithData[key].forEach(d => {
                if (d.column_type === this.capacityColumn && this.areAllValuesZero(d.data))
                    dailyAuctionHiddenColumns.push({
                        counter_party: d.counter_party,
                        platform: d.platform,
                        direction: d.direction,
                        tso: d.tso,
                        auction_type: d.auction_type
                    });
            });
        });

        Object.keys(elementsWithData).forEach(groupKey => {
            if (!this.columnsConfig[groupKey])  //ignore unknown columns
                return;

            if (hideByCapacityKeys.includes(groupKey)) { //daily_auctions/resales should be hidden by total columns
                elementsWithData[groupKey].forEach(columnDict => {
                    if (dailyAuctionHiddenColumns.find(d => columnDict.counter_party === d.counter_party
                        && columnDict.platform === d.platform
                        && columnDict.direction === d.direction
                        && columnDict.tso === d.tso
                        && columnDict.auction_type === d.auction_type)) {
                        elementsWithData[groupKey] = elementsWithData[groupKey].filter(d => d !== columnDict);
                    }
                });

                return;
            }

            elementsWithData[groupKey].forEach(columnDict => {
                if (this.areAllValuesZero(columnDict.data)) {
                    elementsWithData[groupKey] = elementsWithData[groupKey].filter(d => d !== columnDict)
                }
            });
        });

        return elementsWithData;
    }

    /**
     * Returns a word to be used by sortBy, appends "z" to order custom and total pnl's properly (as last columns of group)
     */
    getPnlColumnGroupOrder = (column) => {
        if (column.direction === this.pnlAllTotalColumn)
            return "zzzzz"; // total will be last

        // others' order:
        // 1.sort directions alphabetically
        // 2.send total column to end of each direction
        // 3.send custom to end but before total for each direction

        if (column.type === this.totalColumn)
            return column.direction + "zzzz";
        else if (column.type === this.pnlCustomColumn)
            return column.direction + "zzz";

        return column.type + column.direction;
    }

    componentDidMount() {
        Object.keys(this.columnsConfig).forEach(key => {
            if (this.columnsConfig[key].getSubTitle.length > this.maxTitleDebth)
                this.maxTitleDebth = this.columnsConfig[key].getSubTitle.length;
        });

        const visibleGroups = { ...this.state.visibleGroups };

        if (isEmpty(visibleGroups) || !Object.keys(visibleGroups).find(k => visibleGroups[k].checked)) {
            this.setState({ visibleGroups: this.getVisibleGroups() });
        }
    }

    getVisibleGroups() {
        const visibleGroups = { ...this.state.visibleGroups };
        const mainColumnKeys = this.getMainColumnKeys();

        if (isEmpty(visibleGroups) || !Object.keys(visibleGroups).find(k => visibleGroups[k].checked)) {
            mainColumnKeys.forEach(c => {
                visibleGroups[c] = { title: this.columnsConfig[c].title, checked: true };
            });
        }

        return visibleGroups;
    }

    getMainColumnKeys() {
        return sortBy(Object.keys(this.columnsConfig), ['order']);
    }

    getClassByData(data) {
        if (this.props.directions
            && this.props.directions.length > 0
            && data.direction
            && data.direction !== this.pnlAllTotalColumn
            && data.type !== this.intradayColumnType
            && !this.props.directions.find(d => d === data.direction))
            return this.weakColumnClass;

        return '';
    }

    clearHeaderClassName() {
        this.visibleGroupCounter = 0;
    }

    getNextHeaderClassName() {
        this.visibleGroupCounter = (this.visibleGroupCounter + 1) % 2;
        return this.dataGroupClasses[this.visibleGroupCounter];
    }

    darkenTotalColumns(data) {
        if (data.column_type === this.totalColumn || data.type === this.totalColumn)
            return 'pnl-darker-column'

        return '';
    }

    headerToKey(header) {
        if (!!header?.props)
            return isArray(header.props.children) ?
                header.props.children.map(h => { return this.headerToKey(h) })?.join('_')
                : this.headerToKey(header.props.children);

        return header;
    }

    renderHeaders(data) {
        const subHeaderRows = [];

        const visibleGroups = this.getVisibleGroups();

        const visibleMainKeys = this.getMainColumnKeys().filter(c => visibleGroups[c].checked);

        for (let rowIdx = 0; rowIdx < this.maxTitleDebth; rowIdx++) {
            this.clearHeaderClassName();

            subHeaderRows.push({ key: `sub_header_row_${rowIdx}`, children: [] });

            for (let mainKeyIdx = 0; mainKeyIdx < visibleMainKeys.length; mainKeyIdx++) {
                const columnGroupKey = visibleMainKeys[mainKeyIdx];
                const currentGroup = this.columnsConfig[columnGroupKey];

                if (!data[columnGroupKey] || !data[columnGroupKey].length)
                    continue;

                const headerClass = this.getNextHeaderClassName();

                const sortedData = currentGroup.sort(data[columnGroupKey]);

                for (let dataIdx = 0; dataIdx < sortedData.length; dataIdx++) {
                    const subColumn = sortedData[dataIdx];
                    const currentColumnIdx = subHeaderRows[rowIdx].children.length;
                    let parent;

                    if (rowIdx === 0)
                        parent = columnGroupKey;
                    else {
                        const parentDict = subHeaderRows[rowIdx - 1].children[currentColumnIdx];
                        parent = parentDict.parent + this.headerToKey(parentDict?.title);
                    }

                    if (currentGroup.getSubTitle.length - 1 < rowIdx) {
                        subHeaderRows[rowIdx].children.push({
                            colSpan: 0,
                            key: `${columnGroupKey}_${rowIdx}_${currentColumnIdx}`,
                            parent,
                        });
                        continue;
                    }

                    let rowSpan = 1;

                    if (currentGroup.getSubTitle.length - 1 === rowIdx && rowIdx < this.maxTitleDebth - 1)
                        rowSpan += this.maxTitleDebth - rowIdx - 1;

                    const subTitle = currentGroup.getSubTitle[rowIdx](subColumn);
                    const subTitleStr = this.headerToKey(subTitle);

                    subHeaderRows[rowIdx].children.push({
                        colSpan: 1,
                        key: `${columnGroupKey}_${rowIdx}_${currentColumnIdx}`,
                        group: columnGroupKey,
                        rowSpan: rowSpan,
                        className: `${headerClass} ${this.getClassByData(subColumn)} ${currentGroup.className || ''}`,
                        title: subTitle,
                        parent,
                        subTitleStr
                    });
                }
            }
        }

        for (let i = 0; i < this.maxTitleDebth; i++) {
            let previousSuccessfulColumn = subHeaderRows[i].children[0];

            for (let j = 1; j < subHeaderRows[i].children.length; j++) {
                const current = subHeaderRows[i].children[j];

                if (!current || !current.colSpan)
                    continue;

                if (previousSuccessfulColumn
                    && previousSuccessfulColumn.group === current.group
                    && previousSuccessfulColumn.parent === current.parent
                    && previousSuccessfulColumn.subTitleStr === current.subTitleStr) {
                    previousSuccessfulColumn.colSpan++;
                    current.colSpan = 0;
                }
                else {
                    previousSuccessfulColumn = current;
                }
            }
        }

        this.clearHeaderClassName();

        const mainColumns = [];

        visibleMainKeys.forEach(columnGroupKey => {
            if (!data[columnGroupKey])
                return;

            const currentGroup = this.columnsConfig[columnGroupKey];
            const columnCount = data[columnGroupKey].length;

            if (!columnCount || !visibleGroups[columnGroupKey].checked)
                return;

            mainColumns.push({
                key: columnGroupKey,
                rowSpan: currentGroup.rowSpan ?? 1,
                className: `${this.getNextHeaderClassName()} ${currentGroup.className}`,
                colSpan: columnCount,
                title: currentGroup.title
            });
        });

        return (
            <React.Fragment>
                <tr>
                    <th rowSpan={this.maxTitleDebth + 1} className='v-pnl-date-header'>
                        {this.referenceData.title}
                    </th>
                    {
                        mainColumns.map(columnData => {
                            return (
                                <th key={columnData.key}
                                    rowSpan={columnData.rowSpan}
                                    className={columnData.className}
                                    colSpan={columnData.colSpan}
                                    title={columnData.title}>
                                    {columnData.title}
                                </th>
                            );
                        })
                    }
                </tr>
                {subHeaderRows.map(row => {
                    return (
                        <tr key={row.key}>
                            {row.children.filter(c => c?.colSpan).map(cell => {
                                return (
                                    <th key={cell.key}
                                        parent={cell.parent ?? "EMPTY"}
                                        subtitlestr={cell.subTitleStr}
                                        rowSpan={cell.rowSpan}
                                        colSpan={cell.colSpan}
                                        className={cell.className}
                                        title={isObject(cell.title) ? cell.title.props.children[0] : cell.title}>
                                        {cell.title}
                                    </th>
                                );
                            })}
                        </tr>
                    )
                })}
            </React.Fragment>
        );
    }

    rowClicked(e) {
        if (e.currentTarget.className.includes(this.selectedRowClass)) {
            e.currentTarget.className = e.currentTarget.className.replace(this.selectedRowClass, "").trim();

            const allSelected = this.getAllSelectedRows();

            if (!allSelected || isEmpty(allSelected))
                this.setState({ selectionExist: false });
        }
        else {
            e.currentTarget.className += ' ' + this.selectedRowClass;

            if (!this.state.selectionExist)
                this.setState({ selectionExist: true });
        }
    }

    getAllSelectedRows() {
        return document.getElementById(this.tableId).querySelectorAll(`.${this.selectedRowClass}`);
    }

    clearAllSelection() {
        const selectedRows = this.getAllSelectedRows();

        selectedRows.forEach(i => {
            i.className = i.className.replaceAll(this.selectedRowClass, '').trim();
        });

        this.setState({ selectionExist: false });
    }

    renderRows(data) {
        let rowClassOrder = 0;

        const referenceData = data[this.referenceData.mainHeader][0].data;

        const visibleGroups = this.getVisibleGroups()

        const visibleMainKeys = this.getMainColumnKeys().filter(c => visibleGroups[c].checked);

        return (
            referenceData.map((referenceRow, rowIdx) => {
                if (rowIdx && referenceRow[this.referenceData.key].split(' ')[0] !== referenceData[rowIdx - 1][this.referenceData.key].split(' ')[0])
                    rowClassOrder = (rowClassOrder + 1) % 2

                const rowKey = referenceRow[this.referenceData.key];

                return (
                    <tr key={rowKey} className={this.rowClasses[rowClassOrder]}
                        onClick={(e) => this.rowClicked(e)}>
                        <td className='v-pnl-date-row'>
                            {referenceRow[this.referenceData.key]}
                        </td>
                        {
                            visibleMainKeys.map(columnKey => {
                                const currentGroup = this.columnsConfig[columnKey];

                                return currentGroup.sort(data[columnKey]).map((d, columnIdx) => {
                                    return (
                                        <td key={`${rowKey}_${columnIdx}`}
                                            className={`${currentGroup.getValueClass ? currentGroup.getValueClass(d.data[rowIdx].v) : ''} ${this.getClassByData(d)} ${this.darkenTotalColumns(d)}`}>
                                            {currentGroup.renderValue ? currentGroup.renderValue(d.data[rowIdx]) : d.data[rowIdx].v.toLocaleString()}
                                        </td>
                                    );
                                })
                            })
                        }
                    </tr>
                )
            })
        );
    }

    filterIrregularColumns(data) {
        const expectedLength = data[this.referenceData.mainHeader][0].data.length;

        Object.keys(data).forEach(key => {
            const irregularColumns = []

            for (let columnIdx = 0; columnIdx < data[key].length; columnIdx++) {
                const columnData = data[key][columnIdx];

                if (columnData.data.length !== expectedLength) {
                    irregularColumns.push(columnIdx)
                }
            }

            for (let i = irregularColumns.length - 1; i >= 0; i--) {
                data[key].splice(irregularColumns[i], 1);
            }
        });

        return data;
    }

    render() {
        let data = this.filterEmptyColumns({ ...this.props.data });

        if (!data || isEmpty(data))
            return null;

        data = this.filterIrregularColumns(data);

        if (!data || isEmpty(data))
            return null;

        return (
            <div className='v-pnl-detail-table'>
                <div className='row'>
                    <div className='col-9' style={{ fontSize: '10px' }}>
                        <VCheckboxGroup
                            title='Show All'
                            headerOnTop
                            items={this.state.visibleGroups}
                            onCheckChanged={(k, updatedItems) => this.setState({ visibleGroups: { ...updatedItems } })}
                        />
                    </div>
                    <div className="v-checkBox checkbox pull-right col-3">
                        <label style={{ fontWeight: 600, margin: '5px 0 0' }}>
                            <input type="checkbox"
                                checked={this.state.hideZeroColumns}
                                onChange={() => { this.setState({ hideZeroColumns: !this.state.hideZeroColumns }) }} />
                                Hide empty columns
                            </label>
                    </div>
                </div>
                {
                    this.state.selectionExist &&
                    <div className='v-fixed-button-clear'>
                        <button className="btn v-button"
                            onClick={() => this.clearAllSelection()}>
                            <i aria-hidden="true" className="fa fa-times fa-fw" />
                                Unselect
                        </button>
                    </div>
                }
                <table id={this.tableId}
                    className="table table-hover table-borderless table-responsive">
                    <thead>
                        {this.renderHeaders(data)}
                    </thead>
                    <tbody>
                        {this.renderRows(data)}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PnlDetails;