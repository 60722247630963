import React from 'react';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer';
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import RightSideContainer from '../../components/TTPageContainers/RightSideContainer';
import TConfirmationModal from '../../components/TConfirmationModal/TConfirmationModal';
import { TfilterTypes } from '../../components/TTable/TTable';
import TTable from '../../components/TTable/TTable';
import TTabs from '../../components/TTabs/TTabs';
import TabPanel from '../../components/TTabs/TabPanel';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import {getMarketNames, getCompaniesDetail, getPeriodsDetail, getBookDetails, 
    getCostsDetail, getApiContracts, getApiContract, saveContract, deleteApiContract} from '../../api/services';
import { handleApiError, alertError, alertSuccess } from '../../utils/errorHandler';
import { messages } from '../../utils/messages';
import history from '../../../history';
import ContractDetail from './AutomatedRecordedContractsDetail';
import { DELIVERY_TYPES, POSITION, TRADING_VENUE, CONTRACT_TYPES, PRODUCT, TRADER, CONTRACT_DATE_TYPE, PAYMENT_PERIODS } from '../../utils/constants';
import { extractDate } from '../../utils/common';
import { generateUuid } from '../../../helpers/generalHelper';

const PositionFormatter = ({ row }) => {
    if (row) {
        return (<span className={`${row.position === POSITION.sell ? "t-table-sell" : "t-table-buy"}`}>{row.position}</span>);
    }
    return null;
};

const StartDateFormatter = ({ row }) => {
    if (row) {
        return (<span>{extractDate(row.startDate)}</span>);
    }
    return null;
}

const EndDateFormatter = ({ row }) => {
    if (row) {
        return (<span>{extractDate(row.endDate)}</span>);
    }
    return null;
}

const ContractDateFormatter = ({ row }) => {
    if (row) {
        return (<span>{extractDate(row.contractDate)}</span>);
    }
    return null;
}

const PaymentDateFormatter = ({ row }) => {
    if (row.paymentDate) {
        return (<span>{extractDate(row.paymentDate)}</span>);
    }
    return null;
}


class AutomatedRecordedContracts extends React.Component {
    title = "Auto Recorded Contracts"

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        openAddDialogBox: false,
        showDeleteConfirmationModal: false,
        selectedRow: null,
        defaultSelectedTabId: null,
        tabInfo: [],
        tableRows: [],
        tableColumns: [
            { 
                key: 'tradeId', 
                name: 'TradeId',
                sortable: true,
                resizable: true, 
                width: 120,
                frozen: true, 
                TfilterType: TfilterTypes.input,
                summaryFormatter() {
                    return <strong>Total</strong>;
                }
            },
            { 
                key: 'name', 
                name: 'Name', 
                sortable: true, 
                resizable: true, 
                width: 150,
                frozen: true, 
                TfilterType: TfilterTypes.input,
                summaryFormatter({ row }) {
                    return <strong>{row.totalCount} records</strong>;
                } 
            },
            { 
                key: 'companyName', 
                name: 'Company', 
                sortable: true, 
                resizable: true, 
                width: 150,
                frozen: true, 
                TfilterType: TfilterTypes.select
            },
            { key: "tradingVenueName", name: 'Trading Venue', sortable: true, resizable: true, frozen: true, TfilterType: TfilterTypes.select, width: 150 },
            { key: 'contractTypeName', name: 'Contract Type', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'deliveryTypeName', name: 'Delivery Type', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'productName', name: 'Product', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'bookName', name: 'Book', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'marketName', name: 'Market', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'counterPartyName', name: 'Counter Party', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'contractDate', name: 'Contract Date', sortable: true, resizable: true, formatter: ContractDateFormatter },
            { key: 'traderName', name: 'Trader', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'periodName', name: 'Period', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'contractDateTypeName', name: 'Contract Date Type', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'year', name: 'Year', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'position', name: 'Position', sortable: true, resizable: true, formatter: PositionFormatter },
            { key: 'startDate', name: 'Start Date', sortable: true, resizable: true, formatter: StartDateFormatter },
            { key: 'endDate', name: 'End Date', sortable: true, resizable: true, formatter: EndDateFormatter },
            { key: 'term', name: 'Term', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'price', name: 'Price', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'currency', name: 'Currency', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'amount', name: 'Amount', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'paymentPeriodName', name: 'Payment Period', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'paymentTimePeriod', name: 'Payment Time Period', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'paymentDate', name: 'Payment Date', sortable: true, resizable: true, formatter: PaymentDateFormatter },
        ],
        marketNames: [],
        bookNames: [],
        counterPartyNames: [],
        periodNames: [],
        costNames: [],
        tableRows: []
    }

    componentDidMount() {
        this.onShowButtonClick({});
        this.getCompaniesDetail();
        this.getPeriodsDetail();
        this.getMarketDetails()
        this.getBookDetails()
        this.getCostsDetail();
    }
    setTabs = (newTabs, newTabId) => {
        this.setState({ tabInfo: newTabs, defaultSelectedTabId: newTabId })
    }

    onDeleteRowClick = (row) => {
        this.setState({ showDeleteConfirmationModal: true, selectedRow: row });
    }
    
    onCancelButtonClick(contract) {
        this.closeAddDialogBox()
        if (contract) {
            this.addNewTab({ ...contract, readOnly: true })
        }
    }

    closeAddDialogBox() {
        this.setState({ openAddDialogBox: false })
    }

    hideDeleteConfirmationModal = () => {
        this.setState({ showDeleteConfirmationModal: false });
    }

    addNewTab(row) {
        this.setState({ defaultSelectedTabId: row.id })

        let ids = this.state.tabInfo.map(t => t.id)

        row.key = `${row.id}_${row.readOnly}`

        if (!ids.includes(row.id)) {
            this.setState({ tabInfo: [...this.state.tabInfo, row] })
            
        } else {

            let newTabList = this.state.tabInfo.map(tab => {
                if (tab.id === row.id) {
                    return row
                } else {
                    return tab
                }
            })

            this.setState({ tabInfo: newTabList })
        }
    }

    async getCompaniesDetail() {
        this.spinner.showSpinner('getCompanyNames');
        getCompaniesDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        counterPartyNames: successData.company_list.map(x => (
                            {
                                id: x.id,
                                name: x.name,
                                label: x.name,
                                type: x.type,
                                documentCount: x.documentCount
                            }
                        )),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getCompanyNames');
            });
    }

    async getPeriodsDetail() {
        this.spinner.showSpinner('getPeriodsDetail');
        getPeriodsDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        periodNames: successData.period_list.map(x => (
                            {
                                id: x.id,
                                name: x.name,
                                label: x.name,
                                hourCount: x.hour_count,
                                weekdayCount: x.weekday_count
                            })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getPeriodsDetail');
            });
    }

    async getMarketDetails() {
        this.spinner.showSpinner('getMarketNames');
        getMarketNames()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        marketNames: successData.market_name_list.map(x => ({ 
                            id: x.id, 
                            name: x.name, 
                            label: x.name, 
                            currency: x.currency,
                            book_id: x.book_id
                        })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getMarketNames');
            });
    }

    async getBookDetails() {
        this.spinner.showSpinner('getBookNames');
        getBookDetails()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        bookNames: successData.book_list.map(x => ({ id: x.id, name: x.name, label: x.name })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getBookNames');
            });
    }

    async getCostsDetail() {
        this.spinner.showSpinner('getCostsDetail');
        getCostsDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        costNames: successData.cost_list.map(x => (
                            {
                                id: x.id,
                                value: x.id,
                                name: x.cost_type,
                                label: `${x.cost_type} ${x.country_code} ${x.broker}`,
                                currency: x.currency,
                                broker: x.broker,
                                cost: x.cost,
                                calculation: x.cost_calculation,
                                countryCode: x.country_code,
                                startDate: x.start_date,
                                endDate: x.end_date
                            })),
                    });
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getCostsDetail');
            });
    }

    onEditRowClick = (row, readOnly) => {
        this.spinner.showSpinner('getApiContract');

        getApiContract({ contract_id: row.id })
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success.contract_details

                    let costList = successData.cost_list.map(x => ({
                        id: x.id,
                        value: x.id,
                        name: x.cost_type,
                        label: `${x.cost_type} ${x.country_code} ${x.broker}`,
                        currency: x.currency,
                        broker: x.broker,
                        cost: x.cost,
                        calculation: x.cost_calculation,
                        countryCode: x.country_code
                    }))

                    let documentList = successData.document_list.map(d => ({
                        id: d.id,
                        documentName: d.name
                    }))

                    let collateralList = successData.collateral_list.map(c => ({
                        collateralId: c.collateral_id,
                        tradeId: c.trade_id,
                        issuerName: c.issuer_name,
                        issuerId: c.issuer,
                        receiverName: c.receiver_name,
                        receiverId: c.receiver,
                        bankGuaranteeValidityDate: new Date(c.validity_date),
                        collateralWithoutTax: c.collateral,
                        statusOfValidity: c.is_valid ? true : false,
                        remainingDays: c.remaining_days,
                        tradingVenue: c.trading_venue,
                        collateralType: c.collateral_type,
                        deliveryType: c.delivery_type,
                        collateralRate: c.collateral_rate
                    }))

                    let contract = {
                        id: successData.contract_id,
                        api_contract_id: successData.contract_id,
                        tradeId: successData.trade_id,
                        name: successData.contract_name,
                        companyName: successData.company_name,
                        companyId: successData.company_id,
                        tradingVenue: successData.trading_venue,
                        contractType: successData.contract_type,
                        deliveryType: successData.delivery_type,
                        product: successData.product,
                        trader: successData.trader,
                        marketId: successData.market_id,
                        marketName: successData.market_name,
                        currency: successData.currency,
                        counterPartyId: successData.counter_party_id,
                        counterPartyName: successData.counter_party_name,
                        periodId: successData.period_id,
                        periodName: successData.period_name,
                        bookId: successData.book_id,
                        bookName: successData.book_name,
                        contractDateType: successData.contract_date_type,
                        paymentPeriod: successData.payment_period,
                        paymentTimePeriod: successData.payment_time_period,
                        paymentDate: successData.payment_date ? new Date(successData.payment_date): null,
                        year: successData.year,
                        startDate: new Date(successData.start_date),
                        endDate: new Date(successData.end_date),
                        term: successData.term,
                        contractDate: new Date(successData.contract_date),
                        position: successData.position,
                        price: successData.price,
                        amount: successData.amount,
                        costList: costList,
                        documentList: documentList,
                        collateralList: collateralList
                    }

                    this.addNewTab({ ...contract, readOnly: readOnly })

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getApiContract');
            });
    }

    onAddRowClick = (row, readOnly) => {
        this.spinner.showSpinner('addApiContract');
        
        getApiContract({ contract_id: row.id })
        .then(response => {
            if (response.data.success) {
                let successData = response.data.success.contract_details

                let costList = successData.cost_list.map(x => ({
                    id: x.id,
                    value: x.id,
                    name: x.cost_type,
                    label: `${x.cost_type} ${x.country_code} ${x.broker}`,
                    currency: x.currency,
                    broker: x.broker,
                    cost: x.cost,
                    calculation: x.cost_calculation,
                    countryCode: x.country_code
                }))

                let documentList = successData.document_list.map(d => ({
                    id: d.id,
                    documentName: d.name
                }))

                let collateralList = successData.collateral_list.map(c => ({
                    collateralId: c.collateral_id,
                    tradeId: c.trade_id,
                    issuerName: c.issuer_name,
                    issuerId: c.issuer,
                    receiverName: c.receiver_name,
                    receiverId: c.receiver,
                    bankGuaranteeValidityDate: new Date(c.validity_date),
                    collateralWithoutTax: c.collateral,
                    statusOfValidity: c.is_valid ? true : false,
                    remainingDays: c.remaining_days,
                    tradingVenue: c.trading_venue,
                    collateralType: c.collateral_type,
                    deliveryType: c.delivery_type,
                    collateralRate: c.collateral_rate
                }))

                let copied_contract_id = generateUuid()

                let contract = {
                    id: copied_contract_id,
                    api_contract_id: row.id,
                    tradeId: null,
                    name: successData.contract_name,
                    companyName: successData.company_name,
                    companyId: successData.company_id,
                    tradingVenue: successData.trading_venue,
                    contractType: successData.contract_type,
                    deliveryType: successData.delivery_type,
                    product: successData.product,
                    trader: successData.trader,
                    marketId: successData.market_id,
                    marketName: successData.market_name,
                    currency: successData.currency,
                    counterPartyId: successData.counter_party_id,
                    counterPartyName: successData.counter_party_name,
                    periodId: successData.period_id,
                    periodName: successData.period_name,
                    bookId: successData.book_id,
                    bookName: successData.book_name,
                    contractDateType: successData.contract_date_type,
                    paymentPeriod: successData.payment_period,
                    paymentTimePeriod: successData.payment_time_period,
                    paymentDate: successData.payment_date ? new Date(successData.payment_date): null,
                    year: successData.year,
                    startDate: new Date(successData.start_date),
                    endDate: new Date(successData.end_date),
                    term: successData.term,
                    contractDate: new Date(successData.contract_date),
                    position: successData.position,
                    price: successData.price,
                    amount: successData.amount,
                    costList: costList,
                    documentList: documentList,
                    collateralList: collateralList
                }

                this.addNewTab({ ...contract, readOnly: readOnly })

            }
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner('addApiContract');
        });

    }

    onShowButtonClick = async () => {
        this.spinner.showSpinner('getApiContracts');
        getApiContracts()
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        tableRows: this.getTableRows(response.data.success.contract_result)
                    });
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getApiContracts');
            });
    }

    getTableRows(data) {
        let rowList = []
        for (let i = 0; i < data.length; i++) {
            const currentRow = data[i]

            rowList.push({
                id: currentRow.contract_id,
                tradeId: currentRow.trade_id,
                name: currentRow.contract_name,
                companyName: currentRow.company_name,
                companyId: currentRow.company_id,
                tradingVenue: currentRow.trading_venue,
                tradingVenueName: TRADING_VENUE[currentRow.trading_venue]?.label,
                contractType: currentRow.contract_type,
                contractTypeName: CONTRACT_TYPES[currentRow.contract_type]?.label,
                deliveryType: currentRow.delivery_type,
                deliveryTypeName: DELIVERY_TYPES[currentRow.delivery_type]?.label,
                product: currentRow.product,
                productName: PRODUCT[currentRow.product]?.label,
                trader: currentRow.trader,
                traderName: TRADER[currentRow.trader]?.label,
                marketId: currentRow.market_id,
                marketName: currentRow.market_name,
                currency: currentRow.currency,
                counterPartyId: currentRow.counter_party_id,
                counterPartyName: currentRow.counter_party_name,
                periodId: currentRow.period_id,
                periodName: currentRow.period_name,
                bookId: currentRow.book_id,
                bookName: currentRow.book_name,
                contractDateType: currentRow.contract_date_type,
                contractDateTypeName: CONTRACT_DATE_TYPE[currentRow.contract_date_type]?.label,
                paymentPeriod: currentRow.payment_period,
                paymentPeriodName: PAYMENT_PERIODS[currentRow.payment_period]?.label,
                paymentTimePeriod: currentRow.payment_time_period,
                paymentDate: currentRow.payment_date ? new Date(currentRow.payment_date): null,
                year: currentRow.year,
                startDate: new Date(currentRow.start_date),
                endDate: new Date(currentRow.end_date),
                term: currentRow.term,
                contractDate: new Date(currentRow.contract_date),
                position: currentRow.position,
                price: currentRow.price,
                amount: currentRow.amount
            })
        }

        return rowList;
    }

    saveButtonClick(requestBody) {
        this.closeAddDialogBox()
        this.spinner.showSpinner('saveContract');
        

        let api_contract_id = JSON.parse(requestBody.get("contract_details"))["api_contract_id"]
        saveContract(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.CONTRACT.SUCCESSFULL_CONTRACT_SAVE);

                    let deleteRequestBody = {
                        contract_id: api_contract_id
                    }
                    deleteApiContract(deleteRequestBody)
                        .then(response => {
                        if (response.data.success) {
                            alertSuccess(messages.CONTRACT.SUCCESSFULL_CONTRACT_DELETE)
                            this.onShowButtonClick({});
                        } else {
                            alertError(messages.UNEXPECTED_ERROR_OCCURED);
                        }
                    }, error => {
                        handleApiError(error);
                    }).finally(() => {
                        console.log('Contract was made inactive');
                    });
                    this.onShowButtonClick({});

                    
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('saveContract');
            });
        
        this.spinner.hideSpinner('saveContract');
    }

    
    deleteButtonClick = () => {
        this.spinner.showSpinner('deleteApiContract');
        let requestBody = {
            contract_id: this.state.selectedRow.id
        }
        this.hideDeleteConfirmationModal();
        deleteApiContract(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.CONTRACT.SUCCESSFULL_CONTRACT_DELETE)
                    this.onShowButtonClick({});
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('deleteApiContract');
            });
    }
        
    

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        <TTable
                            
                            rows={this.state.tableRows}
                            columns={this.state.tableColumns}

                            showAddRow
                            showDeleteRow
                            showEditRow
                            
                            onAddRowClick={(row) => this.onAddRowClick(row)}
                            onDeleteRowClick={this.onDeleteRowClick}
                            onEditRowClick={(row) => this.onEditRowClick(row, false)}
                            onRowClick={(row) => this.onEditRowClick(row, false)} 
                                        
                            summaryRows={[{
                                id: 'contract_total_0',
                                totalCount: this.state.tableRows.length
                            }]}
                        ></TTable>

                    </div>
                }
            >
            </LeftSideContainer>
        )
    }

    getTableDetailComponent() {
        return (
            <RightSideContainer>
                <TTabs
                    key={this.state.defaultSelectedTabId}
                    defaultSelectedTabId={this.state.defaultSelectedTabId}
                    setTabs={this.setTabs}
                >
                    {

                        this.state.tabInfo.map((t) => {
                            return (
                                <TabPanel key={`${t.key}_tabpanel`} title={t.name} id={t.id}>
                                    <ContractDetail
                                        marketNames={this.state.marketNames}
                                        bookNames={this.state.bookNames}
                                        counterPartyNames={this.state.counterPartyNames}
                                        periodNames={this.state.periodNames}
                                        costNames={this.state.costNames}
                                        key={`${t.key}_ContractDetail`}
                                        item={t}
                                        onSaveButtonClick={(body) => this.saveButtonClick(body)}
                                        onEditButtonClick={(row) => this.addNewTab({ ...row, readOnly: false })}
                                        onCancelButtonClick={(contract) => this.onCancelButtonClick(contract)}
                                        readOnly={t.readOnly}
                                        editable={true}
                                    >
                                    </ContractDetail>
                                </TabPanel>
                            )
                        })
                    }
                </TTabs>
            </RightSideContainer>
        )
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                    rightSideComponent={this.getTableDetailComponent()}
                >
                </TContentDetailContainer>
                {
                    this.state.showDeleteConfirmationModal &&
                    <TConfirmationModal
                        show={this.state.showDeleteConfirmationModal}
                        message={messages.CONTRACT.CONFIRM_DELETE.replace("[CONTRACT]", this.state.selectedRow.name)}
                        cancelText='Cancel'
                        onHide={this.hideDeleteConfirmationModal}
                        onCancel={this.hideDeleteConfirmationModal}
                        confirmText='Delete'
                        onConfirm={this.deleteButtonClick}
                    />
                }
            </TContentContainer>
        );
    }
}

export default AutomatedRecordedContracts;



