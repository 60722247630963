import './PnlSummary.css';

import { isEmpty, sortBy } from 'lodash';
import React from 'react';
import { getNumberClassBySign } from '../../../helpers/generalHelper';

class PnlSummary extends React.Component {
    rowClasses = ['v-row-group-1', 'v-row-group-2'];
    columnClasses = ['v-data-group-1', 'v-data-group-2'];
    directionsMainKey = 'directions';

    specialColumns = {
        sumOfBorders: {
            key: 'sum_of_borders',
            title: 'Sum of Borders',
            className: 'v-sum-of-borders',
            subColumns: [
                { key: 'previous', title: 'Previous' },
                { key: 'today', title: 'Today' }
            ]
        }
    };

    referenceData = {
        mainHeader: this.specialColumns.sumOfBorders.key,
        key: 'dt',
        title: 'Date (CET)'
    }

    state = {
        hideZeroColumns: true
    }

    areAllValuesZero(data) {
        return data.reduce((a, b) => a + Math.abs(b.v), 0) === 0;
    }

    hideSumOfBordersDetails() {
        return ['Hourly', 'Daily'].includes(this.props.grouping);
    }

    renderHeaders(data) {
        const [prevColumn, todayColumn] = this.specialColumns.sumOfBorders.subColumns;

        return (
            <React.Fragment>
                <tr>
                    <th rowSpan={2}
                        className='v-pnl-date-header'>
                        {this.referenceData.title}
                    </th>
                    <th colSpan={this.hideSumOfBordersDetails() ? 1 : 3} className={this.specialColumns.sumOfBorders.className}>
                        {this.specialColumns.sumOfBorders.title}
                    </th>
                    {
                        sortBy(data[this.directionsMainKey], ['direction']).map((d, dIdx) => {
                            return (
                                <th key={`h_${d.direction}`}
                                    className={`${this.columnClasses[dIdx % 2]}`}
                                    rowSpan={2}>
                                    {d.direction}
                                </th>
                            );
                        })
                    }
                </tr>
                <tr>
                    {!this.hideSumOfBordersDetails() &&
                        <th key={`h_${prevColumn.key}`}
                            className={this.specialColumns.sumOfBorders.className}>
                            {prevColumn.title}
                        </th>
                    }
                    <th key={`h_${todayColumn.key}`}
                        className={this.specialColumns.sumOfBorders.className}>
                        {todayColumn.title}
                    </th>
                    {!this.hideSumOfBordersDetails() &&
                        <th key={`h_diff`}
                            className={this.specialColumns.sumOfBorders.className}>
                            Difference
                    </th>
                    }
                </tr>
            </React.Fragment>
        );
    }

    renderRows(data) {
        let rowClassOrder = 0;

        const referenceData = data[this.referenceData.mainHeader];

        const [prevColumn, todayColumn] = this.specialColumns.sumOfBorders.subColumns;

        let previousTotal = parseInt(referenceData.reduce((a, b) => a + b[prevColumn.key], 0));
        let todayTotal = parseInt(referenceData.reduce((a, b) => a + b[todayColumn.key], 0));
        const sortedData = sortBy(data[this.directionsMainKey], ['direction'])
        return (
            <React.Fragment>
                {
                    referenceData.map((referenceRow, rowIdx) => {
                        if (rowIdx && referenceRow[this.referenceData.key].split(' ')[0] !== referenceData[rowIdx - 1][this.referenceData.key].split(' ')[0])
                            rowClassOrder = (rowClassOrder + 1) % 2

                        return (
                            <tr key={referenceRow[this.referenceData.key]}
                                className={this.rowClasses[rowClassOrder]}>
                                <td className='v-pnl-date-row'>
                                    {referenceRow[this.referenceData.key]}
                                </td>
                                {!this.hideSumOfBordersDetails() &&
                                    <td key={`${prevColumn.key}_${rowIdx}`}
                                        className={this.specialColumns.sumOfBorders.className}>
                                        {parseInt(referenceRow[prevColumn.key]).toLocaleString()}
                                    </td>
                                }
                                <td key={`${todayColumn.key}_${rowIdx}`}
                                    className={this.specialColumns.sumOfBorders.className}>
                                    {parseInt(referenceRow[todayColumn.key]).toLocaleString()}
                                </td>
                                {!this.hideSumOfBordersDetails() &&
                                    <td key={`diff_${rowIdx}`}
                                        className={`${this.specialColumns.sumOfBorders.className} ${getNumberClassBySign(referenceRow[todayColumn.key] - referenceRow[prevColumn.key])}`}>
                                        {parseInt(referenceRow[todayColumn.key] - referenceRow[prevColumn.key]).toLocaleString()}
                                    </td>
                                }
                                {
                                    sortedData.map((d, dIdx) => {
                                        return (
                                            <td key={`v_${d.direction}`}
                                                className={`${this.columnClasses[dIdx % 2]}  ${getNumberClassBySign(d.data[rowIdx].v)}`}>
                                                {parseInt(d.data[rowIdx].v).toLocaleString()}
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                        )
                    })
                }
                <tr key={`total_row`}>
                    <td className='v-pnl-direction-col'>
                        Total
                    </td>
                    {!this.hideSumOfBordersDetails() &&
                        <td key={`total_${prevColumn.key}`}
                            style={{ fontWeight: 600 }}
                            className={`${this.specialColumns.sumOfBorders.className}`}>
                            {parseInt(previousTotal).toLocaleString()}
                        </td>
                    }
                    <td key={`total_${todayColumn.key}`}
                        style={{ fontWeight: 600 }}
                        className={`${this.specialColumns.sumOfBorders.className}`}>
                        {parseInt(todayTotal).toLocaleString()}
                    </td>
                    {!this.hideSumOfBordersDetails() &&
                        <td key={`diff_total`}
                            style={{ fontWeight: 600 }}
                            className={`${this.specialColumns.sumOfBorders.className} ${getNumberClassBySign(todayTotal - previousTotal)}`}>
                            {parseInt(todayTotal - previousTotal).toLocaleString()}
                        </td>
                    }
                    {
                        sortedData.map((totalCol, idx) => {
                            let rowTotal = parseInt(totalCol.data.reduce((a, b) => a + b.v, 0));
                            return (
                                <td style={{ fontWeight: 600 }} className={`${this.columnClasses[idx % 2]} ${getNumberClassBySign(rowTotal)}`} key={`total_${idx}`}>
                                    {rowTotal.toLocaleString()}
                                </td>
                            )
                        })
                    }
                </tr>
            </React.Fragment>
        );
    }

    filterEmptyColumns(data) {
        if (!data || isEmpty(data))
            return null;

        if (!this.state.hideZeroColumns)
            return data;

        data[this.directionsMainKey].forEach(columnDict => {
            if (this.areAllValuesZero(columnDict.data)) {
                data[this.directionsMainKey] = data[this.directionsMainKey].filter(d => d !== columnDict)
            }
        });

        return data;
    }

    render() {
        const data = this.filterEmptyColumns({ ...this.props.data });

        if (!data || isEmpty(data))
            return null;

        return (
            <div className='v-pnl-summary-table'>
                <div className='row'>
                    <div className="v-checkBox checkbox pull-right col-3 offset-9">
                        <label style={{ fontWeight: 600, margin: '5px 0 0' }}>
                            <input type="checkbox"
                                checked={this.state.hideZeroColumns}
                                onChange={() => { this.setState({ hideZeroColumns: !this.state.hideZeroColumns }) }} />
                                Hide empty columns
                            </label>
                    </div>
                </div>
                <table className="table table-borderless table-responsive">
                    <thead>
                        {this.renderHeaders(data)}
                    </thead>
                    <tbody>
                        {this.renderRows(data)}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PnlSummary;