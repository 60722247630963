import React from "react";
import TContentContainer from "../../components/TContentContainer/TContentContainer";
import TContentDetailContainer from "../../components/TContentDetailContainer/TContentDetailContainer";
import LeftSideContainer from "../../components/TTPageContainers/LeftSideContainer";
import PositionFilter from './PositionFilter';
import {
    getMarketNames,
    getBookDetails,
    getCompaniesDetail,
    getLTPosition,
    downloadPosition,
    sendPosition
} from "../../api/services";
import { handleApiError, alertError } from "../../utils/errorHandler";
import { SpinnerManager } from "../../components/TSpinner/SpinnerManager";
import history from "../../../history";
import { DELIVERY_TYPES } from '../../utils/constants';
import DataGrid from 'react-data-grid';
import { groupBy as rowGrouper } from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
import { alertSuccess } from '../../../helpers/errorHelper';
import { messages } from '../../utils/messages';

import './Position.css';


const month_names = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

function valueFormatter(value) {
    if (value)
        return value.toLocaleString(undefined, { maximumFractionDigits: 2 })
    return value
}

function CellFormatter(props) {
    console.log(props)
    return (
        <div style={{ backgroundColor: "#edda97" }}>
            {props.row[props.column.key]}
        </div>
    );
}

function pnlCountryGroupFormatter(groupKey, childRows, column) {
    let marketList = new Set(childRows.map(c => c.marketName))
    let sum = childRows.reduce((prev, row) => prev + (row[column] ? row[column] : 0), 0)
    if (marketList.has(groupKey)) {
        sum = Number.isInteger(sum / 2) ? sum / 2 : (sum / 2).toFixed(1)
        return (<div className="position-market">
            {valueFormatter(sum)}
        </div>)

    } else {
        sum = sum / childRows.length
        return (<div className="position-counter-party">
            {valueFormatter(sum)}
        </div>)
    }

}


class Position extends React.Component {
    title = "Position Reporting";

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        rows: [],
        columns: [],
        selectedRows: new Set(),
        counterPartyNames: [],
        marketNames: [],
        bookNames: [],
        companySummaryTables: {},
        filter: {},
        positionResponse: [],
        positionColumns: []
    };

    componentDidMount() {
        this.getCompaniesDetail();
        this.getMarketDetails();
        this.getBookDetails();
    }

    onShowButtonClick = async (filter) => {
        this.spinner.showSpinner("getLTPosition");
        this.setState({filter: filter});

        getLTPosition(filter)
            .then((res) => {

                const resultList = res.data.success.result_list;
                let month_columns = new Set()
                let years = new Set()
                let row_list = resultList.map(c => {
                    let row = {
                        id: c.id,
                        deliveryTypeName: DELIVERY_TYPES[c.delivery_type]?.label,
                        companyName: c.company_name,
                        bookName: c.book_name,
                        marketName: c.market_name,
                        counterPartyName: c.counter_party_name,
                        periodName: c.period_name,
                    }

                    let position = c.position

                    let current_row_month_columns = new Set()

                    Object.keys(position).map(b => {

                        let year_position = position[b]
                        let year = b.toString().substr(2)
                        years.add(year)

                        Object.keys(year_position).map(m => {
                            let month_name = `${m}${year}`.toString()
                            if (year_position[m])
                                month_columns.add(month_name)
                            current_row_month_columns.add(month_name)

                            row[month_name] = year_position[m]
                        })
                    })

                    if (current_row_month_columns.size > 0)
                        return row

                })

                row_list = row_list.filter(d => d)
                
            
                this.setState({
                    positionResponse: row_list,
                    positionColumns: month_columns
                })

                let month_table_column = []
                years.forEach(y => {

                    month_names.forEach(m => {

                        let month_name = `${m}${y}`.toString()

                        if (month_columns.has(month_name)) {
                            month_table_column = [...month_table_column,
                            {
                                key: month_name,
                                name: month_name.charAt(0).toUpperCase() + month_name.slice(1),
                                sortable: true,
                                resizable: true,
                                formatter({ row }) {
                                    return <>{valueFormatter(row[month_name])}</>;
                                },
                                groupFormatter({ groupKey, childRows }) {
                                    return pnlCountryGroupFormatter(groupKey, childRows, month_name)
                                }
                            }]
                        }
                    })
                })

                let bookSet = new Set(row_list.map(i => i.bookName))
                let bookList = Array.from(bookSet)

                let company_data_list = []
                bookList.map(book => {
                    let currentBookData = row_list.filter(d => d.bookName === book)

                    let companySet = new Set(currentBookData.map(i => i.companyName))
                    let companyList = Array.from(companySet)
                    companyList.map(company => {
                        let currentCompanyData = currentBookData.filter(d => d.companyName === company)
                        let book_column = {
                            key: 'marketName',
                            name: book,
                            frozen: true,
                            width: 150,
                            headerRenderer: ({ column }) => (
                                <div className="colSpanClassname">{column.name}</div>
                            )
                        }

                        let company_column = {
                            key: 'counterPartyName',
                            name: company,
                            frozen: true,
                            width: 170,
                            headerRenderer: ({ column }) => (
                                <div className="colSpanClassname">{column.name}</div>
                            )

                        }

                        let deliverySet = new Set(currentCompanyData.map(i => i.deliveryTypeName))
                        let deliveryList = Array.from(deliverySet)

                        deliveryList.map(delivery => {
                            let currentDeliveryData = currentCompanyData.filter(d => d.deliveryTypeName === delivery)
                            let period_column = {
                                key: 'periodName',
                                name: delivery,
                                frozen: true,
                                width: 170,
                                headerRenderer: ({ column }) => {
                                    return <div className="colSpanClassname">{column.name}</div>
                                }
                            }

                            let summaryColumns = [book_column, company_column, period_column, ...month_table_column]
                            company_data_list = [...company_data_list, {
                                bookName: book,
                                deliveryType: delivery,
                                companyName: company,
                                companyColumn: summaryColumns,
                                companyRows: currentDeliveryData,
                            }]

                        })




                    })
                })

                this.setState({
                    companySummaryTables: company_data_list
                });

            }, (error) => {
                    handleApiError(error);
                }
            ).finally(() => this.spinner.hideSpinner("getLTPosition"));
    };

    async getCompaniesDetail() {
        this.spinner.showSpinner("getCompanyNames");
        getCompaniesDetail()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;
                        this.setState({
                            counterPartyNames: successData.company_list.map((x) => ({
                                id: x.id,
                                value: x.name,
                                label: x.name,
                                type: x.type,
                            })),
                        });
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getCompanyNames");
            });
    }

    async getMarketDetails() {
        this.spinner.showSpinner("getMarketNames");
        getMarketNames()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;
                        this.setState({
                            marketNames: successData.market_name_list.map((x) => ({
                                id: x.id,
                                value: x.name,
                                label: x.name,
                                currency: x.currency,
                            })),
                        });
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getMarketNames");
            });
    }

    async getBookDetails() {
        this.spinner.showSpinner("getBookNames");
        getBookDetails()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;
                        this.setState({
                            bookNames: successData.book_list.map((x) => ({
                                id: x.id,
                                value: x.name,
                                label: x.name,
                            })),
                        });
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getBookNames");
            });
    }

    downloadPosition = async () => {
        const spinnerKey = this.spinner.showSpinner();

        let body = {
            data: { 
                result_list: this.state.positionResponse,
                column_list: Array.from(this.state.positionColumns),
                start_date: this.state.filter.start_date,
                end_date: this.state.filter.end_date
            }
        }


        downloadPosition(body).then(async response => {

            const filename = `Position_${moment(this.state.filter.start_date).format('YYYY-MM-DD')}_${moment(this.state.filter.end_date).format('YYYY-MM-DD')}.xlsx`;
            const contentType = response.headers["content-type"];

            const blob = new Blob([response.data], { type: contentType });

            if (contentType === 'application/json') {
                const error = blob.text();
                return;
            }

            FileSaver.saveAs(blob, filename);
            return;
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    sendPosition = async () => {
        const spinnerKey = this.spinner.showSpinner();

        const filename = `Position_${moment(this.state.filter.start_date).format('YYYY-MM-DD')}_${moment(this.state.filter.end_date).format('YYYY-MM-DD')}.xlsx`;
            
        let body = {
            data: { 
                result_list: this.state.positionResponse,
                column_list: Array.from(this.state.positionColumns),
                start_date: this.state.filter.start_date,
                end_date: this.state.filter.end_date,
                file_name: filename
            }
        }

        sendPosition(body).then(async response => {
            if (response.data.success){
                alertSuccess(messages.POSITION.SEND_POSITION_SUCCESS);
            }
            else{
                alertError(messages.POSITION.SEND_POSITION_ERROR);
            }
            
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }


    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        <div className="t-table-toolbar">
                            <button type="button" className="btn t-orange-button" onClick={() => { this.downloadPosition() }}>
                                Export to XLSX
                            </button>
                            <button type="button" className="btn t-orange-button" onClick={() => { this.sendPosition() }}>
                                Send to Slack
                            </button>
                        </div>
                        {
                            this.state.companySummaryTables.length ? (
                                this.state.companySummaryTables.map(table => {
                                    let expandedGroupIds = new Set([])
                                    if (this.state[`expandedCompany${table.companyName}_${table.bookName}_${table.deliveryType}`]) {
                                        expandedGroupIds = this.state[`expandedCompany${table.companyName}_${table.bookName}_${table.deliveryType}`]
                                    } else {
                                        let expandedGroupIdsMarket = new Set(table.companyRows.map(i => i.marketName))
                                        let expandedGroupIdsCP = new Set(table.companyRows.map(i => `${i.marketName}__${i.counterPartyName}`))
                                        expandedGroupIds = new Set([...expandedGroupIdsMarket, ...expandedGroupIdsCP])
                                    }

                                    return (
                                        <DataGrid
                                            key={`tableCompanySummary_${table.companyName}_${table.bookName}_${table.deliveryType}`}
                                            style={{ height: `${(expandedGroupIds.size + 3) * 115}px` }}
                                            columns={table.companyColumn}
                                            rows={table.companyRows}
                                            defaultColumnOptions={{ resizable: true }}
                                            groupBy={["marketName", "counterPartyName"]}
                                            rowGrouper={rowGrouper}
                                            expandedGroupIds={expandedGroupIds}
                                            onExpandedGroupIdsChange={ids => {
                                                this.setState({
                                                    [`expandedCompany${table.companyName}_${table.bookName}_${table.deliveryType}`]: ids
                                                })
                                            }}
                                        />
                                    )
                                })
                            ) : null

                        }
                    </div>
                }
                filterComponent={
                    this.state.counterPartyNames.length &&
                        this.state.marketNames.length &&
                        this.state.bookNames.length ? (
                            <PositionFilter
                                counterPartyNames={this.state.counterPartyNames}
                                marketNames={this.state.marketNames}
                                bookNames={this.state.bookNames}
                                onShowButtonClick={this.onShowButtonClick}
                            ></PositionFilter>
                        ) : null
                }
            ></LeftSideContainer>
        );
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                ></TContentDetailContainer>
            </TContentContainer>
        );
    }
}

export default Position;
