import React from 'react';
import DatePicker from 'react-datepicker';

import { getFirstMonthOfQuarter, isDate, getQuarter } from '../../utils/common';

import './TDatePicker.css';

/*
    react-datepicker: https://reactdatepicker.com/
*/

/*
    DatePicker custom string input formats:

    year    -> "yyyy"             ("2021")
    quarter -> "yyyy-QQ"          ("2017-Q3")
    month   -> "yyyy-mm"          ("2021-04")
    day     -> "yyyy-mm-dd"       ("2021-12-01")
    time    -> "yyyy-mm-dd hh:mm" ("2021-11-14 09:00")

    See Examples for detailed usage.
*/



const throwParseError = () => { throw new Error('Date string could not parse! Please check out the given formats on top of the component.'); }

const returnDateIfValid = date => {

    if (isDate(date))
        return date;
    else
        throwParseError();
}

const TDatePicker = ({
    label,
    year,
    quarter,
    month,
    day,
    hour,
    minute,
    selectedDate,
    selectedDateAsStr,
    onSelectedDateChange,
    onSelectedDateChangeAsStr,
    onDateChange,
    maxDate,
    minDate,
    disabled,
    type }) => {


    const renderContent = () => {

        switch (type) {

            case TDatePickerTypes.year:
                return (
                    <DatePicker
                        showYearPicker
                        dateFormat="yyyy"
                        yearItemNumber={8}
                        selected={setInput()}
                        onChange={date => setOutput(date)}
                        isClearable={disabled ? false : true}
                        placeholderText="&#xf073;  YYYY"
                        className="fa t-datetimepicker"
                        minDate={minDate ? minDate : null}
                        maxDate={maxDate ? maxDate : null}
                        disabled={disabled ? true : false}
                    />
                );

            case TDatePickerTypes.quarter:
                return (
                    <DatePicker
                        showQuarterYearPicker
                        selected={setInput()}
                        onChange={date => setOutput(date)}
                        dateFormat="yyyy, QQQ"
                        isClearable={disabled ? false : true}
                        placeholderText="&#xf073;  YYYY, QQ"
                        className="fa t-datetimepicker"
                        minDate={minDate ? minDate : null}
                        maxDate={maxDate ? maxDate : null}
                        disabled={disabled ? true : false}
                    />
                );

            case TDatePickerTypes.month:
                return (
                    <DatePicker
                        showMonthYearPicker
                        showFullMonthYearPicker
                        dateFormat="MM/yyyy"
                        selected={setInput()}
                        onChange={date => setOutput(date)}
                        isClearable={disabled ? false : true}
                        placeholderText="&#xf073;  MM/YYYY"
                        className="fa t-datetimepicker"
                        minDate={minDate ? minDate : null}
                        maxDate={maxDate ? maxDate : null}
                        disabled={disabled ? true : false}
                    />
                );

            case TDatePickerTypes.day:
                return (
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={setInput()}
                        onChange={date => setOutput(date)}
                        isClearable={disabled ? false : true}
                        placeholderText="&#xf073;  DD/MM/YYYY"
                        className="fa t-datepicker"
                        minDate={minDate ? minDate : null}
                        maxDate={maxDate ? maxDate : null}
                        disabled={disabled ? true : false}
                    />
                );

            case TDatePickerTypes.time:
                return (
                    <DatePicker
                        showTimeSelect
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        selected={setInput()}
                        onChange={date => setOutput(date)}
                        isClearable={disabled ? false : true}
                        placeholderText="&#xf073;  DD/MM/YYYY hh:mm"
                        className="fa t-datetimepicker"
                        minDate={minDate ? minDate : null}
                        maxDate={maxDate ? maxDate : null}
                        disabled={disabled ? true : false}
                    />
                );

            default:
                return (
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={selectedDate}
                        onChange={date => onSelectedDateChange(date)}
                        isClearable={disabled ? false : true}
                        placeholderText="&#xf073;  DD/MM/YYYY"
                        className="fa t-datepicker"
                        minDate={minDate ? minDate : null}
                        maxDate={maxDate ? maxDate : null}
                        disabled={disabled ? true : false}
                    />
                );
        }

    }

    const setInput = () => {

        if (selectedDate !== undefined)
            return selectedDate;
        else if (selectedDateAsStr !== undefined)
            return convertStringInputToDate();
        else
            return convertParametricInputToDate();
    }

    const convertParametricInputToDate = () => {

        try {

            switch (type) {

                case TDatePickerTypes.year:

                    return year ? returnDateIfValid(new Date(year, 1, 1)) : null;

                case TDatePickerTypes.quarter:

                    return (year && quarter) ? returnDateIfValid(new Date(year, getFirstMonthOfQuarter(quarter), 1)) : null;

                case TDatePickerTypes.month:

                    return (year && month) ? returnDateIfValid(new Date(year, month - 1, 1)) : null;

                case TDatePickerTypes.day:

                    return (year && month && day) ? returnDateIfValid(new Date(year, month - 1, day)) : null;

                case TDatePickerTypes.time:

                    return (year && month && day && (hour !== undefined && hour !== null) && (minute !== undefined && minute !== null)) ? returnDateIfValid(new Date(year, month - 1, day, hour, minute)) : null;

                default:
                    return new Date();
            }

        } catch (error) {
            throwParseError();
        }

    }

    const convertStringInputToDate = () => {

        if (selectedDateAsStr === null)
            return null;

        try {

            let day, month, year, dayMonthYear;

            switch (type) {

                case TDatePickerTypes.year:

                    return returnDateIfValid(new Date(selectedDateAsStr, 1, 1));

                case TDatePickerTypes.quarter:

                    let yearQuarter = selectedDateAsStr.split('-');
                    let quarter = yearQuarter[1].slice(-1);
                    year = yearQuarter[0];

                    return returnDateIfValid(new Date(year, getFirstMonthOfQuarter(quarter), 1));

                case TDatePickerTypes.month:

                    let yearMonth = selectedDateAsStr.split('-');
                    year = yearMonth[0];
                    month = yearMonth[1];

                    return returnDateIfValid(new Date(year, month - 1, 1));

                case TDatePickerTypes.day:

                    dayMonthYear = selectedDateAsStr.split('-');
                    year = dayMonthYear[0];
                    month = dayMonthYear[1];
                    day = dayMonthYear[2];

                    return returnDateIfValid(new Date(year, month - 1, day));

                case TDatePickerTypes.time:

                    let dateTime = selectedDateAsStr.split(' ');
                    dayMonthYear = dateTime[0].split('-');

                    year = dayMonthYear[0];
                    month = dayMonthYear[1];
                    day = dayMonthYear[2];

                    let hourMinute = dateTime[1].split(':');
                    let hour = hourMinute[0];
                    let minute = hourMinute[1];
                    return returnDateIfValid(new Date(year, month - 1, day, hour, minute));

                default:
                    return new Date();
            }

        } catch (error) {
            throwParseError();
        }

    }


    const setOutput = date => {

        if (onSelectedDateChange) {
            onSelectedDateChange(date);
            return;
        }

        switch (type) {

            case TDatePickerTypes.year:

                if (onDateChange) {
                    let result = date ? { year: date.getFullYear() } : { year: null };
                    onDateChange(result);

                } else {
                    let result = date ? `${date.getFullYear()}` : null;
                    onSelectedDateChangeAsStr(result);
                };

                break;

            case TDatePickerTypes.quarter:

                if (onDateChange) {
                    let result = date ? { year: date.getFullYear(), quarter: getQuarter(date) } : { year: null, quarter: null };
                    onDateChange(result);

                } else {
                    let result = date ? `${date.getFullYear()}-Q${getQuarter(date)}` : null;
                    onSelectedDateChangeAsStr(result);
                }

                break;

            case TDatePickerTypes.month:

                if (onDateChange) {
                    let result = date ? { year: date.getFullYear(), month: date.getMonth() + 1 } : { year: null, month: null };
                    onDateChange(result);

                } else {
                    let result = date ? `${date.getFullYear()}-${date.getMonth() + 1}` : null;
                    onSelectedDateChangeAsStr(result);
                }

                break;


            case TDatePickerTypes.day:

                if (onDateChange) {
                    let result = date ? { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } : { year: null, month: null, day: null };
                    onDateChange(result);

                } else {
                    let result = date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : null;
                    onSelectedDateChangeAsStr(result);
                }

                break;


            case TDatePickerTypes.time:

                if (onDateChange) {
                    let result = date ?
                        { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate(), hour: date.getHours(), minute: date.getMinutes() }
                        :
                        { year: null, month: null, day: null, hour: null, minute: null };

                    onDateChange(result);

                } else {
                    let result = date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}` : null;
                    onSelectedDateChangeAsStr(result);
                }

                break;

            default:
                onSelectedDateChange(date);
                break;
        }

    }

    return (
        <div className="t-datepicker-content">
            { label &&
                <div className="t-datepicker-label">
                    {label}
                </div>
            }
            {renderContent()}
        </div>
    );
};


export const TDatePickerTypes = { year: 0, quarter: 1, month: 2, day: 3, time: 4 };

export default TDatePicker;