import './RightSideContainer.css';

import React from 'react';

const RightSideContainer = props => {

    return (
        <div className="t-right-side-container">
            {props.children}
        </div>
    );
};

export default RightSideContainer;