export const messages = { 

    UNEXPECTED_ERROR_OCCURED: "An unexpected error occurred while processing the request.",
    NETWORK_ERROR: "There was a network error, please try again. If you keep getting this error kindly request for support.",


    BOOK : {
        EMPTY_BOOK_NAME: "Please enter a valid book name.",
        SUCCESSFULL_BOOK_SAVE: "Book saved successfully.",
        CONFIRM_DELETE: 'Are you sure want to delete "[BOOK]" book?',
        SUCCESSFULL_BOOK_DELETE: "Book deleted successfully."
    },

    MARKET : {
        EMPTY_MARKET_NAME: "Please enter a valid market name.",
        EMPTY_PRICE_CURVE: "Please select a valid price curve.",
        EMPTY_BOOK: "Please select a valid book.",
        EMPTY_COMPANY: "Please select a valid company.",
        SUCCESSFULL_MARKET_SAVE: "Market saved successfully.",
        CONFIRM_DELETE: 'Are you sure want to delete "[MARKET]" market?',
        SUCCESSFULL_MARKET_DELETE: "Market deleted successfully."
    },

    COMPANY : {
        EMPTY_COMPANY_NAME: "Please enter a valid company name.",
        EMPTY_COMPANY_TYPE: "Please select a company type.",
        SUCCESSFULL_COMPANY_SAVE: "Company saved successfully.",
        CONFIRM_DELETE: 'Are you sure want to delete "[COMPANY]" company?',
        SUCCESSFULL_COMPANY_DELETE: "Company deleted successfully."
    },

    CONTRACT_TYPE : {
        EMPTY_CONTRACT_TYPE_NAME: "Please enter a valid contract type name.",
        SUCCESSFULL_CONTRACT_TYPE_SAVE: "Contract type saved successfully.",
        CONFIRM_DELETE: 'Are you sure want to delete "[CONTRACT_TYPE]" contract type?',
        SUCCESSFULL_CONTRACT_TYPE_DELETE: "Contract type deleted successfully."
    },

    PERIOD: {
        EMPTY_PERIOD_NAME: "Please enter a valid period name.",
        SUCCESSFULL_PERIOD_SAVE: "Period saved successfully.",
        CONFIRM_DELETE: 'Are you sure want to delete "[PERIOD]" period?',
        SUCCESSFULL_PERIOD_DELETE: "Period deleted successfully."
    },

    PRICE_CURVE : {
        PRICE_CURVES_NOT_FOUND: "No price curve found.",
        EMPTY_PRICE_CURVE_NAME: "Please enter a valid price curve.",
        EMPTY_UNIT: "Please select a valid unit.",
        EMPTY_CURRENCY: "Please select a valid currency.",
        EMPTY_FINANCIAL_MARKET_COUNTRY: "Please select a valid financial market country.",
        EMPTY_TIMEZONE: "Please select a valid timezone.",
        SUCCESSFULL_PRICE_CURVE_SAVE: "Price curve saved successfully.",
        CONFIRM_PRICE_CURVE_DELETE: "Are you sure want to delete '[PRICE_CURVE_NAME]' price curve?",
        CONFIRM_FILE_DELETE: "Are you sure want to delete '[FILE_NAME]' file?",
        SUCCESSFULL_PRICE_CURVE_DELETE: "Price curve deleted successfully.",
        SUCCESSFULL_FINANCIAL_MARK_FILE_DELETE: "Mark file deleted successfully.",
        SUCCESSFULL_FILE_UPLOAD: 'File uploaded successfully.'
    },

    COST : {
        NO_COST_FOUND: "No cost found.",
        EMPTY_COST_TYPE: "Please select a valid cost type.",
        EMPTY_BROKER: "Please select a valid broker.",
        EMPTY_COUNTRY: "Please select a valid country.",
        EMPTY_COST: "Please select a valid cost.",
        EMPTY_CURRENCY: "Please select a valid currency.",
        EMPTY_COST_CALCULATION: "Please select a valid cost calculation type.",
        EMPTY_BILLING_PERIOD: "Please select a valid billing period.",
        EMPTY_COST_OPERATION_TYPE: "Please select a valid cost opearation type",
        EMPTY_COST_START_DATE: "Please select a valid cost start date.",
        EMPTY_COST_END_DATE: "Please select a valid cost end date.",
        INVALID_DATES: "Cost start date should be earlier than cost end date.",
        SUCCESSFULL_COST_SAVE: "Cost saved successfully.",
        CONFIRM_COST_DELETE: "Are you sure want to delete '[COST_NAME]' cost?",
        SUCCESSFULL_COST_DELETE: "Cost deleted successfully."
    },
    CONTRACT: {
        EMPTY_CONTRACT_NAME: "Please enter a valid contract name.",
        EMPTY_COMPANY: "Please select a valid company.",
        EMPTY_TRADING_VENUE: "Please select a valid trading venue.",
        EMPTY_CONTRACT_TYPE: "Please select a valid contract type.",
        EMPTY_DELIVERY_TYPE: "Please select a valid delivery type.",
        EMPTY_PRODUCT: "Please select a valid product.",
        EMPTY_TRADER: "Please select a valid trader.",
        EMPTY_MARKET: "Please select a valid market.",
        EMPTY_PERIOD: "Please select a valid period.",
        EMPTY_BOOK: "Please select a valid book.",
        EMPTY_CONTRACT_DATE_TYPE: "Please select a valid contract date type.",
        EMPTY_PAYMENT_PERIOD: "Please select a valid payment period.", 
        EMPTY_PAYMENT_TIME_PERIOD: "Please select a valid payment time period.", 
        EMPTY_PAYMENT_DAY: "Please enter a valid payment date.",
        EMPTY_YEAR: "Please select a valid year.",
        EMPTY_START_DATE: "Please select a valid start date.",
        EMPTY_END_DATE: "Please select a valid end date.",
        EMPTY_CONTRACT_DATE: "Please select a valid contract date.",
        EMPTY_POSITION: "Please select a valid position.",
        EMPTY_PRICE: "Please select a valid price.",
        EMPTY_AMOUNT: "Please select a valid amount.",
        SUCCESSFULL_CONTRACT_SAVE: "Contract saved successfully.",
        CONFIRM_DELETE: 'Are you sure want to delete "[CONTRACT]" contract?',
        SUCCESSFULL_CONTRACT_DELETE: "Contract deleted successfully.",
        CONFIRM_SAVE: 'Are you sure want to save "[CONTRACT]" contract?'
    },
    DOCUMENT: {
        CONFIRM_DELETE: 'Are you sure want to delete "[DOCUMENT]" document?',
        SUCCESSFULL_DOCUMENT_SAVE: "Document saved successfully.",
        SUCCESSFULL_DOCUMENT_DELETE: "Document deleted successfully."
    },
    COLLATERAL: {
        CONFIRM_DELETE: 'Are you sure want to delete "[COLLATERAL]" collateral?',
        SUCCESSFULL_COLLATERAL_SAVE: "Collateral saved successfully.",
        SUCCESSFULL_COLLATERAL_DELETE: "Collateral deleted successfully.",
        EMPTY_ISSUER: "Please select a valid issuer.",
        EMPTY_RECEIVER: "Please select a valid receiver.",
        EMPTY_TRADING_VENUE: "Please select a valid trading venue.",
        EMPTY_DELIVERY_TYPE: "Please select a valid delivery type.", 
        EMPTY_VALID_DATE: "Please select a validity date.",
        EMPTY_COLLATERAL_TYPE: "Please select a valid type.",
        EMPTY_COLLATERAL_VALUE: "Please enter a valid collateral value.",
        EMPTY_COLLATERAL_RATE: "Please enter a valid collateral rate.",
    },
    PNL: {
        CROSSBORDER_PNL: '"[COMPANY]"-"[BOOK]" crossborder pnl added.',
        CROSSBORDER_PNL_WITH_CURRENCY: '"[COMPANY]"-"[BOOK]"-"[CURRENCY]" pnl added.',
        SEND_PNL_SUCCESS: 'Pnl Summary report sent successfully.',
        SEND_PNL_ERROR: 'Pnl Summary report could not be sent successfully.'
    },
    DISTRIBUTION: {
        SUCCESSFULL_SAVE: "Distribution saved successfully.",
    },
    POS_AND_MARK: {
        SEND_POS_AND_MARK_SUCCESS: 'Pos and Mark report sent successfully.',
        SEND_POS_AND_MARK_ERROR: 'Pos and Mark report could not be sent successfully.'
    },
    POSITION: {
        SEND_POSITION_SUCCESS: 'Position report sent successfully.',
        SEND_POSITION_ERROR: 'Position report could not be sent successfully.'
    },
    LIQUIDATION: {
        SUCCESSFUL_LIQUIDATION: '"[COMPANY]" - "[BOOK]" daily liquidation added.',
        SEND_LIQUIDATION_SUCCESS: 'Daily liquidation report sent successfully.',
        SEND_LIQUIDATION_ERROR: 'Daily Liquidation report could not be sent successfully.'
    }
};