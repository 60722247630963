import React from 'react';

import VContentContainer from '../../components/VContentContainer/VContentContainer';

import TTable, { TfilterTypes } from '../../etrm/components/TTable/TTable';
import TDatePicker from '../../etrm/components//TDatePicker/TDatePicker';
import TDropdown from '../../etrm/components//TDropdown/TDropdown';


class TestPage1 extends React.Component {

    countryArray = [
        { value: 'BRA', label: 'Brazil' },
        { value: 'COL', label: 'Colombia' },
        { value: 'FJI', label: 'FIJI' },
        { value: 'COG', label: 'Republic of the Congoon' },
        { value: 'GB', label: 'United Kingdom' },
    ];


    columns = [
        { key: 'id', name: 'ID', width: 40 },
        { key: 'title', name: 'Title', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
        { key: 'location', name: 'Location', sortable: true, resizable: true, TfilterType: TfilterTypes.select }
    ];

    rows = [
        { id: 0, title: 'Example', location: 'Urla' },
        { id: 1, title: 'ttDemo', location: 'Konak' },
        { id: 2, title: 'ttSample', location: 'Konak' },
        { id: 3, title: 'Instance', location: 'Seferihisar' }
    ];

    setSelectedRows = selectedRows => console.log('Will be set to state. Selected Row Details -> ', selectedRows);

    render() {
        return (
            <VContentContainer title="Test React Data Grid">
                <div>
                    <input className="t-input" type="text" />
                </div>
                <div style={{"marginTop" : "10px"}}>
                    <TDropdown
                        width="large"
                        options={this.countryArray}
                        defaultValue={this.countryArray[1]}
                        onSelectedOptionChange={this.onCountryChange}
                        isSearchable={true}
                    />
                </div>
                <div style={{ "marginTop": "10px" }}>
                    <TDatePicker
                        selectedDate={new Date()}
                        onSelectedDateChange={(selectedDate) => console.log(selectedDate)}
                        disabled={false}
                    />
                </div>
                <TTable
                    showFilterButtons

                    showExportExcelButton
                    exportExcelFileName="FileForTheMasses"

                    showSelectionColumn
                    selectedRows={this.setSelectedRows}
                    selectedRowKey={'id'}

                    rows={this.rows}
                    columns={this.columns}

                    onRowClick={(rowId) => { console.log(rowId) }}
                />
            </VContentContainer>
        );
    }
}

export default TestPage1;