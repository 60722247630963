import './VPageNavigation.css';
import React from 'react';

class VPageNavigation extends React.Component {
    defaultrecordCountOptions = [50, 100, 150];

    state = {
        recordsPerPageCount: this.props.recordsPerPageCount || this.getOptionsArray()[0]
    }

    getOptionsArray() {
        return this.props.recordCountOptions || this.defaultrecordCountOptions;
    }

    renderOptions() {
        return this.getOptionsArray().map(o => {
            return <option key={o} value={o}>{o}</option>;
        });
    }

    onPageChanged(pageNum) {
        this.props.onPageChanged(this.state.recordsPerPageCount, pageNum);
    }

    renderPageItem(pageNum, pageText) {
        return (
            <li
                key={`option_${pageText || pageNum}`}
                className={`page-item ${(pageNum === this.props.currentPage && !pageText) ? "v-selected" : ""}`}>
                <button
                    disabled={this.props.currentPage === pageNum}
                    onClick={() => this.onPageChanged(pageNum)}
                    className='page-link'>
                    {pageText || pageNum}
                </button>
            </li>
        );
    }

    getPageCount() {
        const pageCount = Math.ceil(this.props.totalCount / this.state.recordsPerPageCount);

        if (!pageCount)
            return 1;

        return pageCount;
    }

    renderPageItems() {
        const pageCount = this.getPageCount();
        const pageOptions = [];

        for (let i = 0; i < pageCount; i++)
            pageOptions.push(this.renderPageItem(i + 1));

        return (
            <React.Fragment>
                {this.renderPageItem(1, '<<')}
                {this.renderPageItem(this.props.currentPage > 1 ? this.props.currentPage - 1 : 1, '<')}
                {pageOptions}
                {this.renderPageItem(this.props.currentPage < pageCount ? this.props.currentPage + 1 : pageCount, '>')}
                {this.renderPageItem(pageCount, '>>')}
            </React.Fragment>
        );
    }

    recordCountChanged(newCount) {
        this.setState({ recordsPerPageCount: newCount }, () => {
            this.onPageChanged(1)
        });
    }

    render() {
        return (
            <div className='v-paging'>
                <div className='v-paging-page-selector'>
                    <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-right">
                            <li className="page-item">
                                <label className='v-paging-record-count'>
                                    Show
                                    <select size="1"
                                        className="input-sm"
                                        onChange={(e) => this.recordCountChanged(e.target.value)}
                                        defaultValue={this.state.recordsPerPageCount}
                                    >
                                        {this.renderOptions()}
                                    </select>
                                    Rows
                                    </label>
                            </li>
                            {this.renderPageItems()}
                        </ul>
                    </nav>
                </div>
            </div >
        );
    }
}

export default VPageNavigation;