import React from 'react';

import history from '../../../history';
import { getPriceCurve, getContractCountry, uploadFinancialMarkFile, savePriceCurve, deletePriceCurve, deleteFinancialMarkFile } from '../../api/services';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import { messages } from '../../utils/messages';
import { handleApiError, alertError, alertSuccess, alertWarning } from '../../utils/errorHandler';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer'
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import PriceCurveList from '../PriceCurve/PriceCurveList';
import PriceCurveDetailModal from '../../modals/PriceCurve/PriceCurveDetailModal';
import TConfirmationModal from '../../components/TConfirmationModal/TConfirmationModal';

class PriceCurveMain extends React.Component {

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        priceCurves: [],
        contractCountries: [],
        showPriceCurveDetailModal: false,
        selectedPriceCurve: '',
        financialMarkFileNameToUpload: '',
        financialMarkFileToUploadAsBase64: null

    }

    componentDidMount() {
        this.callGetPriceCurve();
        this.callGetContractCountry();
    }

    callGetPriceCurve() {

        this.spinner.showSpinner('callGetPriceCurve');

        getPriceCurve()
            .then(response => {

                if (response.data.success) {
                    this.setState({ priceCurves: response.data.success });
                } else if (response.data.error.default.NotFound) {
                    alertWarning(messages.PRICE_CURVE.PRICE_CURVES_NOT_FOUND);
                    this.setState({ priceCurves: [] });
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }

            }, error => {
                handleApiError(error);
            })
            .finally(() => {
                this.spinner.hideSpinner('callGetPriceCurve');
            });
    }

    callGetContractCountry() {

        this.spinner.showSpinner('callGetContractCountry');

        getContractCountry()
            .then(response => {

                if (response.data.success) {
                    this.setState({
                        contractCountries: response.data.success.map(i => {
                            return {
                                value: i.contract_name,
                                label: i.description
                            }
                        })
                    });

                } else {
                    this.setState({ showPriceCurveDetailModal: false });
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }

            }, error => {
                handleApiError(error);
            })
            .finally(() => {
                this.spinner.hideSpinner('callGetContractCountry');
            });
    }

    createUploadFinancialMarkFileRequestBody(price_curve_id) {
        return {
            price_curve_id,
            file_name: this.state.financialMarkFileNameToUpload,
            data: this.state.financialMarkFileToUploadAsBase64
        }
    }

    callUploadFinancialMarkFile(saved_price_curve_id) {

        this.spinner.showSpinner('callUploadFinancialMarkFile');

        uploadFinancialMarkFile(this.createUploadFinancialMarkFileRequestBody(saved_price_curve_id))
            .then(response => {

                if (response.data.success) {
                    alertSuccess(messages.PRICE_CURVE.SUCCESSFULL_FILE_UPLOAD);
                    this.setState({
                        showPriceCurveDetailModal: false,
                        financialMarkFileNameToUpload: '',
                        financialMarkFileToUploadAsBase64: null
                    });
                    this.callGetPriceCurve();
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }

            }, error => {
                handleApiError(error);
            })
            .finally(() => {
                this.spinner.hideSpinner('callUploadFinancialMarkFile');
            });
    }

    onFileChanged = e => {

        this.spinner.showSpinner('financialMarkFileUpload');

        let val = e.target.value;

        // Remove file path
        let financialMarkFileNameToUpload = val.substring(val.lastIndexOf("\\") + 1);

        let reader = new FileReader();

        reader.readAsDataURL(new Blob([e.target.files[0]]));

        // Convert file byte codes to base64
        reader.onloadend = () => this.setState({
            financialMarkFileToUploadAsBase64: reader.result,
            financialMarkFileNameToUpload
        }, () => this.spinner.hideSpinner('financialMarkFileUpload'));
    }

    createSavePriceCurveRequestBody(name, unit, currency, financial_mark_country_id, timezone, unit_conversion) {
        return {
            data: [
                {
                    price_curve_id: this.state.selectedPriceCurve ? this.state.selectedPriceCurve.price_curve_id : null,
                    name,
                    unit,
                    currency,
                    financial_mark_country_id,
                    timezone,
                    unit_conversion
                }
            ]
        };
    }

    callSavePriceCurveServiceAsync = async (requestBody) => {

        this.spinner.showSpinner('callSavePriceCurveServiceAsync');

        try {

            let response = await savePriceCurve(requestBody);

            if (response.data.success) {

                alertSuccess(messages.PRICE_CURVE.SUCCESSFULL_PRICE_CURVE_SAVE);
                return response.data.success.price_curve_id;

            } else {
                alertError(messages.UNEXPECTED_ERROR_OCCURED);
            }

            return null;

        } catch (error) {
            alertError(messages.NETWORK_ERROR);
            return null;

        } finally {
            this.spinner.hideSpinner('callSavePriceCurveServiceAsync');
        }

    }

    callDeletePriceCurveServiceAsync = async () => {

        this.spinner.showSpinner('callDeletePriceCurveServiceAsync');

        let requestBody = { price_curve_id: this.state.selectedPriceCurve.price_curve_id };

        try {

            let response = await deletePriceCurve(requestBody);

            if (response.data.success) {

                alertSuccess(messages.PRICE_CURVE.SUCCESSFULL_PRICE_CURVE_DELETE);

                this.hideDeleteConfirmationModal();

                this.callGetPriceCurve();

            } else {
                alertError(messages.UNEXPECTED_ERROR_OCCURED);
            }

        } catch (error) {
            alertError(messages.NETWORK_ERROR);

        } finally {
            this.spinner.hideSpinner('callDeletePriceCurveServiceAsync');
        }
    }

    callDeleteFinancialMarkFileAsync = async () => {

        this.spinner.showSpinner('callDeleteFinancialMarkFileAsync');

        let requestBody = { price_curve_id: this.state.selectedPriceCurve.price_curve_id };

        try {

            let response = await deleteFinancialMarkFile(requestBody);

            if (response.data.success) {

                alertSuccess(messages.PRICE_CURVE.SUCCESSFULL_FINANCIAL_MARK_FILE_DELETE);

                this.hideDeleteConfirmationModal();

                this.callGetPriceCurve();

            } else {
                alertError(messages.UNEXPECTED_ERROR_OCCURED);
            }

        } catch (error) {
            alertError(messages.NETWORK_ERROR);

        } finally {
            this.spinner.hideSpinner('callDeleteFinancialMarkFileAsync');
        }
    }

    onSavePriceCurve = async (name, unit, currency, financialMarkCountryId, timezone, unitConversion) => {
        let saved_price_curve_id = await this.callSavePriceCurveServiceAsync(this.createSavePriceCurveRequestBody(name, unit, currency, financialMarkCountryId, timezone, unitConversion));

        if (saved_price_curve_id) {
            if (this.state.financialMarkFileNameToUpload && this.state.financialMarkFileToUploadAsBase64)
                this.callUploadFinancialMarkFile(saved_price_curve_id);
            else {
                this.setState({ showPriceCurveDetailModal: false });
                this.callGetPriceCurve();
            }
        }

    }

    callDeleteServiceAsync = () => {
        if (this.state.showPriceCurveDeleteConfirmationModal) {
            this.callDeletePriceCurveServiceAsync();
        } else if (this.state.showFileDeleteConfirmationModal) {
            this.callDeleteFinancialMarkFileAsync();
        }
    }

    setDeleteConfirmationMessage() {
        if (this.state.showPriceCurveDeleteConfirmationModal)
            return (messages.PRICE_CURVE.CONFIRM_PRICE_CURVE_DELETE).replace("[PRICE_CURVE_NAME]", this.state.selectedPriceCurve.name);
        else if (this.state.showFileDeleteConfirmationModal)
            return messages.PRICE_CURVE.CONFIRM_FILE_DELETE.replace("[FILE_NAME]", this.state.financialMarkFileNameToUpload || this.state.selectedPriceCurve.financial_mark_file_name);
    }

    onRemoveFinancialMarkFile = () => {
        if (this.state.financialMarkFileNameToUpload)
            this.setState({ financialMarkFileNameToUpload: '', financialMarkFileToUploadAsBase64: null });
        else
            this.setState({ showFileDeleteConfirmationModal: true });
    }

    hideDeleteConfirmationModal = () => {
        if (this.state.showPriceCurveDeleteConfirmationModal) {
            this.setState({ showPriceCurveDeleteConfirmationModal: false, showPriceCurveDetailModal: false, selectedPriceCurve: '' });
        } else if (this.state.showFileDeleteConfirmationModal) {
            this.setState({ showFileDeleteConfirmationModal: false, showPriceCurveDetailModal: false });
        }
    }

    onSelectedPriceCurveDelete = row => this.setState({ selectedPriceCurve: row, showPriceCurveDeleteConfirmationModal: true });

    onEditRowClick = row => this.setState({ selectedPriceCurve: row, showPriceCurveDetailModal: true });

    onAddNewPriceCurveButtonClick = () => this.setState({ showPriceCurveDetailModal: true, selectedPriceCurve: '' });

    hidePriceCurveDetailModal = () => this.setState({
        showPriceCurveDetailModal: false,
        selectedPriceCurve: '',
        financialMarkFileNameToUpload: '',
        financialMarkFileToUploadAsBase64: null
    });

    getLeftSide() {
        return (
            <LeftSideContainer
                contentDefaultExpanded
                contentLabel="Price Curves"
                contentComponent={
                    <div className="container">
                        <div className="row">
                            <div className="col-4">
                                <button
                                    type="button"
                                    className="btn t-orange-button"
                                    onClick={this.onAddNewPriceCurveButtonClick}>
                                    Add New Price Curve
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <PriceCurveList
                                    data={this.state.priceCurves}
                                    onEditRowClick={this.onEditRowClick}
                                    onDeleteRowClick={this.onSelectedPriceCurveDelete}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                <TContentContainer>
                    <TContentDetailContainer
                        leftSideComponent={this.getLeftSide()}
                    >
                    </TContentDetailContainer>
                </TContentContainer>
                {
                    this.state.showPriceCurveDetailModal &&
                    <PriceCurveDetailModal
                        show={this.state.showPriceCurveDetailModal}
                        onHide={this.hidePriceCurveDetailModal}
                        onCancel={this.hidePriceCurveDetailModal}
                        selectedPriceCurve={this.state.selectedPriceCurve}
                        contractCountries={this.state.contractCountries}
                        onFileChanged={this.onFileChanged}
                        onRemoveFinancialMarkFile={this.onRemoveFinancialMarkFile}
                        financialMarkFileNameToUpload={this.state.financialMarkFileNameToUpload}
                        onSavePriceCurve={this.onSavePriceCurve} />
                }
                {
                    (this.state.showPriceCurveDeleteConfirmationModal || this.state.showFileDeleteConfirmationModal) &&
                    <TConfirmationModal
                        show={true}
                        message={this.setDeleteConfirmationMessage()}
                        cancelText='Cancel'
                        onHide={this.hideDeleteConfirmationModal}
                        onCancel={this.hideDeleteConfirmationModal}
                        confirmText='Delete'
                        onConfirm={this.callDeleteServiceAsync}
                    />
                }
            </React.Fragment>
        );
    }

}

export default PriceCurveMain;