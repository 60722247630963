import './VMatrix.css';
import React from 'react';
import { isEmpty } from 'lodash';

class VMatrix extends React.Component {
    renderCell(cell, cellId, minValue, maxValue) {
        let blockClass;
        const blockStyle = {};

        if (cell < 0) {
            blockClass = 'v-matrix-base-color-red';
            blockStyle.opacity = cell / minValue;
        }
        else if (cell > 0) {
            blockClass = 'v-matrix-base-color-blue';
            blockStyle.opacity = cell / maxValue;
        }
        else {
            blockClass = '';
        }

        blockStyle.filter = `alpha(opacity=${blockStyle.opacity * 100})`;
        blockStyle.MozOpacity = blockStyle.opacity;

        return (
            <td key={cellId}>
                <div className="v-matrix-cell-container">
                    <div
                        className={`${blockClass} v-matrix-cell-block`}
                        style={blockStyle}>
                    </div>
                    <div className="v-matrix-cell-text">
                        <span>{cell}</span>
                    </div>
                </div>
            </td>
        );
    }

    render() {
        if (!this.props.items)
            return null;

        let { headers, values, minValue, maxValue } = this.props.items;

        if (!headers || isEmpty(headers) || !values || isEmpty(values))
            return null;

        if (!minValue)
            minValue = 0.01

        if (!maxValue)
            maxValue = 0.01

        return (
            <div className='v-matrix'>
                <table className="table table-borderless table-responsive">
                    <thead>
                        <tr>
                            <th className='v-period-column' key={`H${0}`}>{headers[0]}</th>
                            {headers.slice(1).map((h, idh) => {
                                return <th className={`v-header ${Number(h) > 0 ? 'v-header-positive' : 'v-header-negative'}`} key={`H${idh}`}><span>{h}</span></th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {values.map((r, idRow) => {
                            return (
                                <tr key={`R${idRow}`}>
                                    <td className='v-period-column'>{r[0]}</td>
                                    { r.slice(1).map((c, idCell) => {
                                        return this.renderCell(c, `R${idRow}C${idCell}`, minValue, maxValue);
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default VMatrix;