import './LeftSideContainer.css';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const LeftSideContainer = ({
  filterComponent,
  contentComponent,
  contentLabel,
  filterDefaultExpanded,
  contentDefaultExpanded }) => {

  const classes = useStyles();

  return (
    <div>
      {
        filterComponent &&
        <div>
          <Accordion
            defaultExpanded={filterDefaultExpanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#e7ecf3" }}
            >
              <Typography component={'span'} className={classes.heading}>Filter</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component={'span'}>
                {filterComponent}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      }
      <Accordion
          defaultExpanded={contentDefaultExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{ backgroundColor: "#e7ecf3" }}
          >
            <Typography component={'span'} className={classes.heading} >{contentLabel ? contentLabel : ''}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: "column" }}>
            <Typography component={'span'} style={{"margin": "10px"}} >
              {contentComponent}
            </Typography>
          </AccordionDetails>
        </Accordion>
    </div>
  );
}

LeftSideContainer.defaultProps = {
  contentLabel : '',
  filterDefaultExpanded : false,
  contentDefaultExpanded : false
};

export default LeftSideContainer;