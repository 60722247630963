import React from 'react';
import PropTypes from 'prop-types';

const crossStr = String.fromCharCode(215);
const RemoveComponent = (props) => {
  const { readOnly, removeComponent, onClick, className } = props;
  if (readOnly) {
    return <span />;
  }

  if (removeComponent) {
    const Component = removeComponent;
    return <Component {...props} />;
  }

  return (
    <i style={{ cursor: 'pointer' }} onClick={onClick} className={className} onKeyDown={onClick}>
      {crossStr}
    </i>
  );
};

RemoveComponent.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  removeComponent: PropTypes.func,
};

export default RemoveComponent;
