import React from 'react';

import TTable, { TfilterTypes } from '../../components/TTable/TTable';
import { UNITS } from '../../utils/constants';

const columns = [
    { key: 'name', name: 'Name', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
    { key: 'unit_name', name: 'Unit', width: 40, TfilterType: TfilterTypes.select },
    { key: 'currency', name: 'Currency', width: 40, TfilterType: TfilterTypes.select },
    { key: 'unit_conversion', name: 'Unit Conversion', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
    { key: 'financial_mark_country_name', name: 'Source Name', TfilterType: TfilterTypes.input },
    { key: 'financial_mark_file_name', name: 'Financial Mark File Name', TfilterType: TfilterTypes.input },
    { key: 'timezone', name: 'Timezone'}
];

const renderTable = (data, onEditRowClick, onDeleteRowClick) => {

    const rows = data.map(p => {
        return {
            price_curve_id: p.price_curve_id,
            name: p.name, 
            unit: p.unit, 
            unit_name: UNITS[p.unit]?.label,
            currency: p.currency, 
            unit_conversion: p.unit_conversion,
            financial_mark_country_name: p.financial_mark_country_name,
            financial_mark_file_name: p.financial_mark_file_name,
            timezone: p.timezone
        }
    });

    return (
        <TTable
            showEditRow
            onEditRowClick={onEditRowClick}
            showDeleteRow
            onDeleteRowClick={onDeleteRowClick}
            showFilterButtons
            rows={rows}
            columns={columns}
        />
    );
};

const PriceCurveList = ({ data, onEditRowClick, onDeleteRowClick }) => {

    return (
        <div>
            {renderTable(data, onEditRowClick, onDeleteRowClick)}
        </div>
    );
};

export default PriceCurveList;