import './ExchangeResults.css';

import React from 'react';
import _, { isEmpty, isNull } from 'lodash';

import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import { ExchangeResultsExcelHelper } from '../ExchangeResults/ExchangeResultsExcelHelper';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VDropdown from '../../components/VDropdown/VDropdown';
import VTable from '../../components/VTable/VTable';
import VFileUploadIcon from '../../components/VFileUploadIcon/VFileUploadIcon';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import { extractDate, getDayAheadForGivenDate, combineStringsForMessage, getCompanyOptions, isCompanySelectable } from '../../helpers/generalHelper';
import { handleApiError, alertError, alertSuccess, specifyErrorMessage } from '../../helpers/errorHelper';
import { portalMessages } from '../../helpers/portalMessages';
import { getOfferParameters, getExchangeResults, saveExchangeResults, deleteExchangeResult } from '../../apis/vitusApi';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorageHelper';

class ExchangeResults extends React.Component {

    headerClasses = ['v-colored-header-v1', 'v-colored-header-v2'];
    hoursColumn = 'Hour';
    subheadings = { 'cet': 'CET', 'buy': 'Buy (MWh)', 'sell': 'Sell (MWh)' };
    columnType = { 'buy': 'buy', 'sell': 'sell' };
    borderBasedCounterParties = ["Ensco", "Green"];
    valueIsEmpty = (value) => value === "";
    spinner = new SpinnerManager(history.location.pathname);
    tableRef = React.createRef();

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED,
        NotFound: portalMessages.PARAMETERS.NO_OFFER_PARAMETERS_FOUND,
        MissingParameter: {
            "exchange_date": portalMessages.DATE_SELECTION,
            "counter_party": portalMessages.SELECT_COUNTER_PARTY,
        },
        InvalidData: {
            "exchange_date": portalMessages.INVALIED_REQUESTED_DATE,
            "buy": portalMessages.EXCHANGE_RESULTS.INVALID_BUY_VALUES,
            "sell": portalMessages.EXCHANGE_RESULTS.INVALID_SELL_VALUES,
        }
    };

    state = {
        resultDate: getDayAheadForGivenDate(new Date()),
        showNeedRefreshModal: false,
        disableFilter: false,
        selectedCounterParty: getLocalStorage('exchangeResults', 'dropdown', 'selectedCounterParty') || null,
        showDeleteTableButton: false,
        tableData: {},
        tableDataBeforeSpecialFileImport: {},
        exchangeList: [],
        counterPartyList: [],
        latestRequestedFilter: {},
        offerParameters: [],
        columnDetails: [],
        activeFilter: [],
        dayAheadData: []
    }


    componentDidMount() {

        this.spinner.showSpinner('getOfferParameters');

        getOfferParameters()
            .then(response => {

                if (response.data.success) {
                    this.setCounterPartyList(response.data.success.offer_parameters);

                    if (!isNull(this.state.selectedCounterParty)) {
                        let expectedResults = this.createExpectedExchangeResult(this.state.selectedCounterParty.value);

                        this.callGetExchangeResultsAsync(this.createGetExchangeResultRequestBody(), expectedResults);
                    }
                }
                else {
                    this.setState({ showNeedRefreshModal: true });
                }

                if (response.data.error)
                    this.showErrorMessage(response.data.error);

            }, error => {
                handleApiError(error);
                this.setState({ showNeedRefreshModal: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('getOfferParameters');
            });
    }

    setCounterPartyList(fetchedOfferParameters) {

        let fetchedCounterPartyList = fetchedOfferParameters.map(param => {
            return {
                value: `${param.counter_party}`,
                label: `${param.counter_party}`
            }
        });

        this.setState({
            counterPartyList: _.orderBy(_.uniqBy(fetchedCounterPartyList, 'value'), ['label'], ['asc']),
            offerParameters: fetchedOfferParameters,
            showNeedRefreshModal: false
        });
    }

    showErrorMessage(error) {
        const { message } = specifyErrorMessage(error, this.errorMessages);

        if (message)
            alertError(message);
    }

    showSuccessMessage(message) {
        alertSuccess(message);
    }

    validateFilters() {

        if (isNull(this.state.resultDate) && isNull(this.state.selectedCounterParty)) {
            this.showErrorMessage(portalMessages.ADD_DATE_AND_COUNTER_PARTY);
            return false;
        }

        if (isNull(this.state.resultDate)) {
            this.showErrorMessage(portalMessages.DATE_SELECTION);
            return false;
        }

        if (isNull(this.state.selectedCounterParty)) {
            this.showErrorMessage(portalMessages.SELECT_COUNTER_PARTY);
            return false;
        }

        return true;
    }

    createExpectedExchangeObj(exchange, border, buy_directions, sell_directions) {
        return {
            exchange: exchange,
            border: border,
            buy_directions: buy_directions,
            sell_directions: sell_directions
        };
    }

    createExpectedExchangeResult(requestedCounterParty) {

        let expectedExhangeResults = [];
        let offerParameters = this.state.offerParameters;

        if (this.borderBasedCounterParties.includes(requestedCounterParty)) {

            offerParameters.forEach(offer => {

                if (offer.result_counter_party === requestedCounterParty) {

                    offer.borders.forEach(border => {
                        expectedExhangeResults.push(this.createExpectedExchangeObj(offer.exchange, border, offer.buy_direction_list, offer.sell_direction_list));
                    });
                }
            });

        } else {

            offerParameters.forEach(offer => {

                if (offer.result_counter_party === requestedCounterParty)
                    expectedExhangeResults.push(this.createExpectedExchangeObj(offer.exchange, null, {}, {}));
            });
        }

        return expectedExhangeResults;
    }

    createColumnInfo(exchange, type, data, columnIndex, exchangeId, border, sell_direction, buy_direction) {
        let obj = {
            type: type,
            data: data,
            index: columnIndex,
            exchangeId: exchangeId,
            border: border
        };


        if (type === this.columnType.buy) {

            if (isNull(border)) {
                obj['exchange'] = exchange;
                obj['heading'] = exchange;
                obj['subheading'] = `${this.subheadings.buy}`;

            } else {
                obj['exchange'] = `${exchange} ${border}`;
                obj['heading'] = `${exchange} ${border}`;
                obj['subheading'] = `${buy_direction} ${this.subheadings.buy}`;
                obj['originalExchange'] = exchange;
            }

        } else {

            if (isNull(border)) {
                obj['exchange'] = exchange;
                obj['heading'] = { name: exchange, html: '' };
                obj['subheading'] = this.subheadings.sell;

            } else {
                obj['exchange'] = `${exchange} ${border}`;
                obj['heading'] = { name: exchange, html: '' };
                obj['subheading'] = `${sell_direction} ${this.subheadings.sell}`;
                obj['originalExchange'] = exchange;
            }
        }


        return obj
    }

    setTableData(expectedExchangeResults, response) {

        let columnDetails = [];
        let columnIndex = 0;
        let showDeleteTableButton = false;

        if (isEmpty(response)) {

            expectedExchangeResults.forEach(expected => {
                const sell_direction = expected.sell_directions[expected.border]
                const buy_direction = expected.buy_directions[expected.border]

                columnDetails.push(this.createColumnInfo(
                    expected.exchange,
                    this.columnType.buy,
                    [],
                    ++columnIndex,
                    null,
                    expected.border,
                    sell_direction,
                    buy_direction
                ));

                columnDetails.push(this.createColumnInfo(
                    expected.exchange,
                    this.columnType.sell,
                    [],
                    ++columnIndex,
                    null,
                    expected.border,
                    sell_direction,
                    buy_direction
                ));
            });

        } else {

            expectedExchangeResults.forEach(expected => {
                const sell_direction = expected.sell_directions[expected.border]
                const buy_direction = expected.buy_directions[expected.border]

                let found = response.find(i => i.exchange === expected.exchange && i.border === expected.border);

                let buyValues = [];
                let sellValues = [];
                let exchangeId = null;

                if (found) {
                    found.data.forEach(hourlyValues => {
                        buyValues.push(hourlyValues.buy);
                        sellValues.push(hourlyValues.sell);
                    });

                    exchangeId = found.exchange_id;
                    showDeleteTableButton = true;
                }

                columnDetails.push(this.createColumnInfo(
                    expected.exchange,
                    this.columnType.buy,
                    buyValues,
                    ++columnIndex,
                    exchangeId,
                    expected.border,
                    sell_direction,
                    buy_direction
                ));

                columnDetails.push(this.createColumnInfo(
                    expected.exchange,
                    this.columnType.sell,
                    sellValues,
                    ++columnIndex,
                    exchangeId,
                    expected.border,
                    sell_direction,
                    buy_direction
                ));

            });
        }

        this.setState({
            tableData: this.convertJsonToTable(columnDetails),
            columnDetails: columnDetails,
            showDeleteTableButton: showDeleteTableButton
        });
    }

    createGetExchangeResultRequestBody() {
        return {
            filter: {
                exchange_date: extractDate(this.state.resultDate),
                counter_party: this.state.selectedCounterParty.value,
            }
        };
    }

    callGetExchangeResultsAsync = async (requestBody, expectedExchangeResults) => {

        this.spinner.showSpinner('getExchangeResultsService');

        try {

            let response;

            try {
                response = await getExchangeResults(requestBody);
            } catch (error) {
                handleApiError(error);
                return;
            }

            if (response.data.success) {
                this.setState({
                    latestRequestedFilter: requestBody.filter,
                    tableDataBeforeSpecialFileImport: {},
                    activeFilter: [
                        { label: "Date", value: requestBody.filter.exchange_date },
                        { label: "Counter Party", value: requestBody.filter.counter_party}
                    ]
                });

                this.setTableData(expectedExchangeResults, response.data.success.exchange_results);

            } else if (response.data.error) {
                this.showErrorMessage(response.data.error);

            } else {
                alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
            }

        } catch (error) {
            alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);

        } finally {
            this.spinner.hideSpinner('getExchangeResultsService');
        }
    }

    createExchangeObjToSave(exchange, border, exchangeId, updatedBuyValues, updatedSellValues) {

        let obj = {
            exchange: exchange,
            border: border,
            exchange_id: exchangeId,
            updated_data: []
        };

        // Buy and Sell columns both should be empty or non-empty.
        // If there is no different data, return null instead "obj".

        if (!updatedBuyValues.every(this.valueIsEmpty)) {

            for (let i = 0; i < updatedBuyValues.length; i++) {

                obj.updated_data.push({
                    hour: i,
                    buy: updatedBuyValues[i],
                    sell: updatedSellValues[i]
                });
            }

            return obj;
        }

        return null;
    }

    createDayAheadObjToSave(dayAheadData) {
        let obj = {
            updated_data: []
        };

        // Buy and Sell columns both should be empty or non-empty.
        // If there is no different data, return null instead "obj".

        if (!dayAheadData.every(this.valueIsEmpty)) {

            for (let i = 0; i < dayAheadData.length; i++) {

                obj.updated_data.push({
                    hour: i,
                    price: dayAheadData[i].v
                });
            }

            return obj.updated_data;
        }

        return null;
    }

    createSaveExchangeResultsRequestBody(updatedTableData, groupedColumnDetails) {

        let requestBody = {
            counter_party: this.state.latestRequestedFilter.counter_party,
            exchange_date: this.state.latestRequestedFilter.exchange_date,
            dayahead_results: this.createDayAheadObjToSave(this.state.dayAheadData),
            exchange_results: []
        };

        Object.keys(groupedColumnDetails).forEach(exchange => {

            let updatedBuyColumnValues = [];
            let updatedCellColumnValues = [];

            let border = null;
            let exchangeId = null;
            let exchangeToSend = exchange;


            groupedColumnDetails[exchange].forEach(columnDetail => {

                if (columnDetail.type === this.columnType.buy)
                    updatedBuyColumnValues = this.getColumValues(updatedTableData, columnDetail.index);


                if (columnDetail.type === this.columnType.sell)
                    updatedCellColumnValues = this.getColumValues(updatedTableData, columnDetail.index);

                if ('originalExchange' in columnDetail)
                    exchangeToSend = columnDetail.originalExchange;

                border = columnDetail.border;
                exchangeId = columnDetail.exchangeId;
            });


            requestBody.exchange_results.push(this.createExchangeObjToSave(
                exchangeToSend,
                border,
                exchangeId,
                updatedBuyColumnValues,
                updatedCellColumnValues
            ));


        });

        requestBody.exchange_results = requestBody.exchange_results.filter(i => !isNull(i));

        return requestBody
    }

    callSaveExchangeResultsAsync = async (requestBody) => {

        this.spinner.showSpinner("saveExchangeResultService");

        let result = false;

        try {
            let response;

            try {
                response = await saveExchangeResults(requestBody);
            } catch (error) {
                handleApiError(error);
                return false
            }

            if (response.data.success) {
                result = true;

            } else if (response.data.error) {
                this.showErrorMessage(response.data.error);

            } else {
                alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
            }

            return result

        } finally {
            this.spinner.hideSpinner("saveExchangeResultService");
        }

    }

    getColumValues(tableData, numberOfColumn) {
        let columnValues = [];

        for (let i = 0; i < tableData.values.length; i++)
            columnValues.push(tableData.values[i][numberOfColumn]);

        return columnValues;
    }

    convertEmptyValuesToZero(newTableData, groupedColumnDetails) {

        // 1. If a column has both numbers and empty rows, converts empty rows to zero.

        let convertedTableData = {
            headers: [...newTableData.headers.map(i => { return [...i] })],
            values: [...newTableData.values.map(i => { return [...i] })]
        };

        this.state.columnDetails.forEach(columnDetail => {

            let i = columnDetail.index;

            let updatedColumnValues = this.getColumValues(newTableData, i);

            if (!updatedColumnValues.every(this.valueIsEmpty)) {

                convertedTableData.values.forEach(rowData => {
                    if (rowData[i] === "")
                        rowData[i] = 0;
                });
            }

        })

        // 2. If a user enter data for only "buy" or "sell" column of an exchange, converts not entered column values to zero.

        Object.keys(groupedColumnDetails).forEach(exchange => {

            let buyColumnValues = [];
            let sellColumnValues = [];
            let buyColumnIndex;
            let sellColumnIndex;

            groupedColumnDetails[exchange].forEach(columnDetail => {

                if (columnDetail.type === this.columnType.buy) {

                    buyColumnIndex = columnDetail.index;
                    buyColumnValues = this.getColumValues(convertedTableData, buyColumnIndex);
                }


                if (columnDetail.type === this.columnType.sell) {

                    sellColumnIndex = columnDetail.index;
                    sellColumnValues = this.getColumValues(convertedTableData, sellColumnIndex);
                }

            });

            // Buy and Sell columns both should be empty or non-empty

            let allBuyColumnValuesAreEmpty = buyColumnValues.every(this.valueIsEmpty);
            let allSellColumnValuesAreEmpty = sellColumnValues.every(this.valueIsEmpty);

            if (!allBuyColumnValuesAreEmpty && allSellColumnValuesAreEmpty)
                convertedTableData.values.forEach(rowData => { rowData[sellColumnIndex] = 0; });

            else if (allBuyColumnValuesAreEmpty && !allSellColumnValuesAreEmpty)
                convertedTableData.values.forEach(rowData => { rowData[buyColumnIndex] = 0; });

        });


        return convertedTableData;
    }

    validateTableInputs(newTableData) {

        let invalidType = 'general';

        let invalidData = newTableData.values.find(rowData => {
            let found = false;

            rowData.forEach(i => {
                if (isNaN(i))
                    found = true;

                else if (i !== '') {

                    if (parseInt(i) < 0 || parseInt(i) > 1000)
                        found = true;

                    else if (i.toString().indexOf('.') > -1 && i.toString().split('.')[1].length > 1) {
                        found = true;
                        invalidType = 'fractional';
                    }
                }
            });

            return found;
        });

        if (invalidData) {

            if (invalidType === 'general')
                alertError(portalMessages.EXCHANGE_RESULTS.INVALID_INPUT);
            else
                alertError(portalMessages.EXCHANGE_RESULTS.INVALID_FRACTIONAL_INPUT);

            return false;
        }


        return true
    }

    saveTableChangesAsync = async (newTableData) => {

        this.spinner.showSpinner('saveTableChangesAsync');

        try {

            if (this.validateTableInputs(newTableData)) {

                let groupedColumnDetails = _.groupBy(this.state.columnDetails, "exchange");

                let convertedTableData = this.convertEmptyValuesToZero(newTableData, groupedColumnDetails);

                let saveRequestrequestBody = this.createSaveExchangeResultsRequestBody(convertedTableData, groupedColumnDetails);

                if (isEmpty(saveRequestrequestBody.exchange_results)) {
                    this.showErrorMessage(portalMessages.EXCHANGE_RESULTS.NO_RESULT_TO_UPDATE);
                    return false
                }

                let result = await this.callSaveExchangeResultsAsync(saveRequestrequestBody);

                if (!result)
                    return false;

                let expectedExchangeResults = this.createExpectedExchangeResult(this.state.latestRequestedFilter.counter_party);

                let getRequestBody = { filter: { ...this.state.latestRequestedFilter } };

                await this.callGetExchangeResultsAsync(getRequestBody, expectedExchangeResults);

                this.showSuccessMessage(portalMessages.EXCHANGE_RESULTS.SUCCESSULL_SAVE_MESSAGE);

                return true
            }

            return false

        } catch (error) {

            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
            return false

        } finally {
            this.spinner.hideSpinner('saveTableChangesAsync');
        }

    }

    onShowButtonClick = () => {
        if (this.validateFilters()) {
            this.spinner.showSpinner('showButtonClick');

            let requestBody = this.createGetExchangeResultRequestBody();

            let selectedCP = requestBody.filter.counter_party;

            let expectedExchangeResults = this.createExpectedExchangeResult(selectedCP);

            this.callGetExchangeResultsAsync(requestBody, expectedExchangeResults);

            setLocalStorage('exchangeResults', 'dropdown', 'selectedCounterParty', { value: selectedCP, label: selectedCP });

            this.spinner.hideSpinner('showButtonClick');
        }
    }

    convertJsonToTable(columnDetails) {
        let headers = [[this.hoursColumn], [this.subheadings.cet]];
        let values = [];

        for (let i = 1; i < 25; i++) values.push([i]);

        columnDetails.forEach(columnData => {

            headers[0].push(columnData.heading);
            headers[1].push(columnData.subheading);

            if (isEmpty(columnData.data)) {
                for (let j = 0; j < 24; j++)
                    values[j].push('');

            } else {
                for (let j = 0; j < columnData.data.length; j++)
                    values[j].push(columnData.data[j]);
            }

        });

        return {
            headers,
            values
        };
    }

    onOpenEditMode() {
        this.setState({ disableFilter: true });
    }

    onCloseEditMode() {

        if (!isEmpty(this.state.tableDataBeforeSpecialFileImport))
            this.setState({
                tableData: this.state.tableDataBeforeSpecialFileImport,
                tableDataBeforeSpecialFileImport: {}
            });

        this.setState({ disableFilter: false });
    }

    onTableDelete() {

        this.spinner.showSpinner('onTableDelete');

        let exchangeIdArr = (_.uniq(this.state.columnDetails.map(i => { return i.exchangeId }))).filter(i => !isNull(i));

        let deleteRequestBody = { exchange_ids: exchangeIdArr };

        deleteExchangeResult(deleteRequestBody)
            .then(response => {
                if (response.data.success) {

                    let expectedExchangeResults = this.createExpectedExchangeResult(this.state.latestRequestedFilter.counter_party);

                    let getRequestBody = { filter: { ...this.state.latestRequestedFilter } };

                    this.callGetExchangeResultsAsync(getRequestBody, expectedExchangeResults);

                    this.showSuccessMessage(portalMessages.EXCHANGE_RESULTS.SUCCESSULL_DELETE_MESSAGE);

                } else if (response.data.error) {
                    this.showErrorMessage(response.data.error);

                } else {
                    this.showErrorMessage(this.portalMessages.UNEXPECTED_ERROR_OCCURED);
                }

            }, error => {
                handleApiError(error);
            })
            .finally(() => {
                this.spinner.hideSpinner('onTableDelete');
            });

    }

    onImportExcel(e) {

        this.spinner.showSpinner('onImportSpecialExcel');

        let exchanges = this.state.offerParameters
            .filter(offer => offer.counter_party === this.state.latestRequestedFilter.counter_party)
            .map(found => { return found.exchange });

        new ExchangeResultsExcelHelper(e.target.files[0]).loadExcel(
            this.state.latestRequestedFilter.counter_party,
            exchanges,
            (excelData, dapData) => this.updateTableAfterSpecialFileImport(excelData, dapData)
        );
    }

    updateTableAfterSpecialFileImport(excelData, dapData) {

        if (!isEmpty(excelData)) {

            let updatedTableData = {
                headers: [...this.state.tableData.headers.map(i => { return [...i] })],
                values: [...this.state.tableData.values.map(i => { return [...i] })]
            };
            excelData.forEach(data => {

                let buyIndex = this.state.columnDetails.find(
                    i => i.exchange === data.exchange && i.type === this.columnType.buy).index;

                let sellIndex = this.state.columnDetails.find(
                    i => i.exchange === data.exchange && i.type === this.columnType.sell).index;

                updatedTableData.values.forEach(function (rowData, i) {
                    rowData[buyIndex] = data.buy[i].v;
                    rowData[sellIndex] = data.sell[i].v;
                });

            });

            this.setState({
                tableDataBeforeSpecialFileImport: this.state.tableData,
                tableData: updatedTableData,
                dayAheadData: dapData
            }, () => {
                if (!this.tableRef.current)
                    this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
                else
                    this.tableRef.current.onOpenEditMode();
            });

        } else {
            this.showErrorMessage(portalMessages.EXCHANGE_RESULTS.SPECIAL_FILE_IMPORT_ERROR);
        }

        this.spinner.hideSpinner('onImportSpecialExcel');
    }

    getTableDeleteMessage() {

        let exchangeList = (_.uniq(this.state.columnDetails.map(i => { return i.exchange })));

        return portalMessages.EXCHANGE_RESULTS.TABLE_DELETE_MESSAGE
            .replace('[EXCHANGE]', combineStringsForMessage(exchangeList));
    }

    downloadTemplate() {
        if (!this.tableRef.current)
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
        else
            this.tableRef.current.downloadExcel();
    }

    onGetHeaderClass(colId, colCount) {
        if ((colId - 1) % colCount > 0)
            return this.headerClasses[(Math.floor((((colId - 1) % colCount) - 1) / 2)) % 2];
        return "";
    }

    checkCounterPartyFileExists() {
        let counterParty = this.state.latestRequestedFilter.counter_party;

        if (counterParty)
            return counterParty === 'Energovia' || counterParty === 'Axpo';

        return false;
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Exchange Results">
                    <VFilterContainer showActiveFilter activeFilter={this.state.activeFilter}>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                            </div>
                            <div>
                                <VDatePicker
                                    selectedDate={this.state.resultDate}
                                    onSelectedDateChange={(selectedDate) => this.setState({ resultDate: selectedDate })}
                                    maxDate={getDayAheadForGivenDate(new Date())}
                                    disabled={this.state.disableFilter}
                                />
                            </div>
                        </div>
                       
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Counter Party
                            </div>
                            <div>
                                <VDropdown
                                    width="large"
                                    disabled={this.state.disableFilter}
                                    isSearchable={false}
                                    options={this.state.counterPartyList}
                                    value={this.state.selectedCounterParty}
                                    defaultValue={this.state.selectedCounterParty}
                                    onSelectedOptionChange={(option) => this.setState({ selectedCounterParty: option })}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                disabled={this.state.disableFilter}
                                className="btn v-cancel-button v-filter-button"
                                onClick={() => this.setState({
                                    resultDate: getDayAheadForGivenDate(new Date()),
                                    selectedCounterParty: null
                                })}>
                                <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                            </button>
                            <button
                                disabled={this.state.disableFilter}
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={this.onShowButtonClick}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                        </div>
                    </VFilterContainer>
                    {
                        !isEmpty(this.state.tableData) &&
                        <React.Fragment>
                            {
                                this.checkCounterPartyFileExists() &&
                                <React.Fragment>
                                    <div className="d-flex flex-row-reverse">
                                        <div className="p-2">
                                            <VFileUploadIcon
                                                accept={['.xlsx', '.xls', '.xlsm']}
                                                labeltext={`Upload ${this.state.latestRequestedFilter.counter_party} File`}
                                                key='importTableIcon'
                                                onFileChanged={(e) => this.onImportExcel(e)}
                                            />
                                        </div>
                                        {/* TODO: will be open after download template development.
                                        <div className="p-2">
                                            <div className="v-link-button" onClick={() => this.downloadTemplate()}>
                                                <i key='exportTable' aria-hidden="true"
                                                    className="fa fa-cloud-download fa-fw fa-lg" />
                                                <span className="v-font">{`Download ${this.state.latestRequestedFilter.counter_party} Template`}</span>
                                            </div>
                                        </div>
                                        */}
                                    </div>
                                </React.Fragment>
                            }
                            <VMainContainer flex>
                                <VTable
                                    ref={this.tableRef}
                                    onGetHeaderClass={(colNum, colCount) => this.onGetHeaderClass(colNum, colCount)}
                                    title='Exchange Results'
                                    simpleNumbers
                                    customColumnClasses={{ 0: "v-column-narrow-bold" }}
                                    onError={(message) => this.showErrorMessage(message)}
                                    exportFileName={`ExchangeResults_${this.state.latestRequestedFilter.counter_party}_${this.state.latestRequestedFilter.exchange_date}`}
                                    inputType='number'
                                    scales={{ '*': 1 }}
                                    deleteMessage={this.getTableDeleteMessage()}
                                    showTotal
                                    items={this.state.tableData}
                                    onSaveChangesAsync={(newTableData) => this.saveTableChangesAsync(newTableData)}
                                    readonlyColumns={[this.hoursColumn]}
                                    onOpenEditMode={() => this.onOpenEditMode()}
                                    onCloseEditMode={() => this.onCloseEditMode()}
                                    onTableDelete={() => this.onTableDelete()}
                                    headerButtons={{
                                        showExportExcelButton: true,
                                        showImportExcelButton: true,
                                        showEditButton: true,
                                        showDeleteTableButton: this.state.showDeleteTableButton
                                    }}
                                />
                            </VMainContainer>
                        </React.Fragment>
                    }
                </VContentContainer>
                <NeedRefreshModal
                    show={this.state.showNeedRefreshModal}
                    message={portalMessages.COULD_NOT_GET_C_PARTIES}
                />
            </React.Fragment>
        );
    }


}

export default ExchangeResults;