import './CustomPnl.css';

import React from 'react';
import { orderBy, isEmpty } from 'lodash';

import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import { getPnlBorders, saveCustomPnl, getCustomPnl, deleteCustomPnl } from '../../apis/vitusApi';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import AddDirectionModal from '../../modals/AddDirectionModal/AddDirectionModal';
import VVerticalTabs, { VVerticalTab } from '../../components/VVerticalTabs/VVerticalTabs';
import { AddStyles } from '../../components/VTagInput/cusomized-react-tag-input/ReactTags';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VDropdown from '../../components/VDropdown/VDropdown';
import VTagInput from '../../components/VTagInput/VTagInput';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import VMessageBox from '../../components/VMessageBox/VMessageBox';
import VTable from '../../components/VTable/VTable';
import { portalMessages } from '../../helpers/portalMessages';
import { handleApiError, alertError, alertSuccess, alertWarning, specifyErrorMessage } from '../../helpers/errorHelper';
import { extractDate, getDayAheadForGivenDate } from '../../helpers/generalHelper';

class CustomPnl extends React.Component {

    spinner = new SpinnerManager(history.location.pathname);
    maxDate = null; // disabled max date
    tableHourColumnClass = { 0: "v-column-narrow-bold" };
    tableColumns = ['Hour', 'Value'];
    tableReadOnlyColumn = ['Hour'];
    tableCustomValueIndex = 1;
    maxDescriptionLength = 200;
    enteredDescription = ''; // corresponds to new description added/updated by user
    currencyOptions = [
        { value: "EUR", label: "EUR" },
        { value: "TRY", label: "TRY" }
    ];

    companyLabels = { "Vitus": "Vitus Commodities", "Vitus Capital": "Vitus Capital" };
    companyRequestText = {'Vitus Commodities': 'Vitus'};

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED,
        MissingParameter: {
            "requested_date": portalMessages.DATE_SELECTION
        },
        InvalidData: {
            "requested_date": portalMessages.INVALIED_REQUESTED_DATE
        }
    };

    state = {
        selectedDate: new Date(),
        showNeedRefreshModal: false,
        showAddDirectionModal: false,
        activeFilter: [],
        latestRequestedFilter: {},
        disableFilter: false,
        selectedCounterParty: '',
        selectedVTabDirection: '',
        tables: {},
        newTable: {},
        cPartyTags: [],
        directionsOfCounterParties: {},
        cParties: [],
        selectedCurrency: null
    }

    componentDidMount() {

        this.spinner.showSpinner('getPnlBorders');

        getPnlBorders()
            .then(response => {

                if (response.data.success) {

                    this.setPnlBorders(response.data.success.result_list);

                    this.callGetCustomPnlAsync(this.createGetCustomPnlRequestBody(this.state.selectedDate));

                } else {
                    this.setState({ showNeedRefreshModal: true });
                }

                if (response.data.error)
                    this.showErrorMessage(response.data.error);

            }, error => {
                handleApiError(error);
                this.setState({ showNeedRefreshModal: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('getPnlBorders');
            });
    }

    showErrorMessage(error) {
        const { message } = specifyErrorMessage(error, this.errorMessages);

        if (message)
            alertError(message);
    }

    setPnlBorders(pnlBorders) {
        let directionsOfCounterParties = {};

        pnlBorders.forEach(i => {
            let currentCParty = i.counterParty;

            if (currentCParty in directionsOfCounterParties) {
                directionsOfCounterParties[currentCParty].add(i.direction);
            } else {
                directionsOfCounterParties[currentCParty] = new Set();
                directionsOfCounterParties[currentCParty].add(i.direction);
            }
        });

        let cParties = orderBy(Object.keys(directionsOfCounterParties));

        let cPartyTags = orderBy(cParties).map(cParty => this.generateTag(cParty));

        cParties.forEach(cParty => {
            directionsOfCounterParties[cParty] = Array.from(directionsOfCounterParties[cParty]);
        });

        this.setState({ selectedCounterParty: cParties[0], cPartyTags, directionsOfCounterParties, cParties });
    }

    createGetCustomPnlRequestBody(requestedDate) {
        return {
            filter: {
                requested_date: extractDate(requestedDate)
            }
        };
    }

    createEmptyTable() {

        let headers = this.tableColumns;
        let values = [];

        for (let i = 1; i < 25; i++)
            values.push([i, '']);

        return {
            headers,
            values,
            customPnlId: null,
            previousDescription: ''
        };
    }

    onAddDirection = (cParty, selectedDirection) => {

        this.spinner.showSpinner('onAddDirection');

        let newTable = {};
        newTable[cParty] = {};
        newTable[cParty][selectedDirection] = this.createEmptyTable();

        let selectedCurrency = cParty === 'Vitus' && selectedDirection === 'TR IDM' ? this.currencyOptions[1] : this.currencyOptions[0];

        this.setState({ newTable, selectedCurrency, showAddDirectionModal: false });

        this.spinner.hideSpinner('onAddDirection')
    }

    getAvailableDirections() {
        let directionsToRemove = this.state.tables[this.state.selectedCounterParty] ?
            Object.keys(this.state.tables[this.state.selectedCounterParty]) : [];

        return this.state.directionsOfCounterParties[this.state.selectedCounterParty]
            .filter(d => !directionsToRemove.includes(d))
            .map(d => { return { value: d, label: d } });
    }

    callGetCustomPnlAsync = async (requestBody) => {

        this.spinner.showSpinner('callGetCustomPnlAsync');

        try {
            let response;

            try {
                response = await getCustomPnl(requestBody);
            } catch (error) {
                handleApiError(error);
            }

            if (response.data.success) {

                this.setState({
                    latestRequestedFilter: { ...requestBody },
                    activeFilter: [
                        { label: "Date", value: requestBody.filter.requested_date },
                    ],
                });

                this.setTables(response.data.success);

            } else if (response.data.error.default.NotFound) {

                alertWarning(portalMessages.CUSTOM_PNL.NO_CUSTOM_PNL_FOUND);

                this.setState({
                    tables: {},
                    newTable: {},
                    latestRequestedFilter: { ...requestBody },
                    activeFilter: [
                        { label: "Date", value: requestBody.filter.requested_date }
                    ]
                });

            } else if (response.data.error) {
                this.showErrorMessage(response.data.error);

            } else {
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
            }


        } finally {
            this.spinner.hideSpinner('callGetCustomPnlAsync');
        }


    }

    callSaveCustomPnlAsync = async (requestBody) => {

        this.spinner.showSpinner('callSaveCustomPnlAsync');

        let result = false;

        try {
            let response;

            try {
                response = await saveCustomPnl(requestBody);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success)
                result = true;
            else if (response.data.error)
                this.showErrorMessage(response.data.error);
            else
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);

            return result;

        } finally {
            this.spinner.hideSpinner('callSaveCustomPnlAsync');
        }
    }

    setTables(allTables) {
        let tables = {};

        allTables.forEach(cParty => {

            tables[cParty.counter_party] = {};

            cParty.directions.forEach(direction => {
                tables[cParty.counter_party][direction.direction] = this.convertJsonToTable(direction);
            });

        });

        this.setState({ tables, newTable: {} });
    }

    convertJsonToTable(direction) {
        let headers = this.tableColumns;
        let values = [];

        direction.data.forEach(function (value, index) {
            values.push([++index, value]);
        });

        return {
            headers,
            values,
            customPnlId: direction.custom_pnl_id,
            previousDescription: direction.description,
            currency: direction.currency
        };
    }

    validateTableInputs(table) {

        let isAllValuesEmpty = true;

        for (let rowValues of table.values) {

            let value = rowValues[this.tableCustomValueIndex].toString().trim();

            if (value !== '') {

                isAllValuesEmpty = false;

                if (isNaN(value)) {
                    alertError(portalMessages.CUSTOM_PNL.INVALID_INPUT);
                    return false;

                } else if (value.includes('.') && value.toString().split('.')[1].length > 2) {
                    alertError(portalMessages.CUSTOM_PNL.INVALID_FRACTIONAL_INPUT);
                    return false;
                }
            }
        }

        if (isAllValuesEmpty) {
            alertError(portalMessages.CUSTOM_PNL.NO_CUSTOM_PNL_TO_SAVE);
            return false;
        }

        if (!isEmpty(this.state.newTable) && !this.state.selectedCurrency) {
            alertError(portalMessages.SELECT_CURRENCY);
            return false;
        }

        return true;
    }

    createSaveCustomPnlRequestBody(table, cParty, direction) {

        let customValueIndex = this.tableCustomValueIndex;

        let body = {
            requested_date: this.state.latestRequestedFilter.filter.requested_date,
            custom_pnl_id: table['customPnlId'] ? table['customPnlId'] : null,
            previous_description: table['previousDescription'],
            updated_description: this.enteredDescription,
            counter_party: cParty,
            direction: direction,
            currency: this.state.selectedCurrency ? this.state.selectedCurrency.value : this.state.tables[cParty][direction].currency,
            data: []
        };

        table.values.forEach(function (rowValues, index) {

            body.data.push({
                hour: index,
                value: rowValues[customValueIndex]
            });

        });

        return body;
    }

    convertEmptyValuesToZero(table) {

        table.values.forEach(rowValues => {

            if (rowValues[this.tableCustomValueIndex].toString().trim() === '')
                rowValues[this.tableCustomValueIndex] = 0;
        });
    }

    saveTableChangesAsync = async (tableToSave, cParty, direction) => {

        this.spinner.showSpinner('saveTableChangesAsync');

        try {

            if (this.validateTableInputs(tableToSave)) {

                this.convertEmptyValuesToZero(tableToSave);

                let result = await this.callSaveCustomPnlAsync(this.createSaveCustomPnlRequestBody(tableToSave, cParty, direction));

                if (result) {

                    alertSuccess(portalMessages.CUSTOM_PNL.SUCCESSFULL_SAVE_MESSAGE);

                    await this.callGetCustomPnlAsync(this.state.latestRequestedFilter);

                    return true;

                } else {
                    return false;
                }

            } else {
                return false;
            }

        } catch (error) {
            alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
            return false

        } finally {
            this.spinner.hideSpinner('saveTableChangesAsync');
        }

    }

    onTableDelete(cParty, direction) {

        this.spinner.showSpinner('onTableDelete');

        let deleteRequestBody = { custom_pnl_id: this.state.tables[cParty][direction].customPnlId };

        deleteCustomPnl(deleteRequestBody)
            .then(response => {
                if (response.data.success) {

                    alertSuccess(portalMessages.CUSTOM_PNL.SUCCESSULL_DELETE_MESSAGE);

                    this.callGetCustomPnlAsync(this.state.latestRequestedFilter);

                } else if (response.data.error) {
                    this.showErrorMessage(response.data.error);

                } else {
                    this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
                }

            }, error => {
                handleApiError(error);
            })
            .finally(() => {
                this.spinner.hideSpinner('onTableDelete');
            });

    }

    generateTag(value) {
        if (this.companyLabels[value])
            return { id: value, text: this.companyLabels[value] };
        return { id: value, text: value };
    }

    onClearButtonClick = () => {
        if (this.state.selectedDate)
            this.setState({ selectedDate: getDayAheadForGivenDate(new Date()) });
    }

    onShowButtonClick = () => {
        if (!this.state.selectedDate)
            alertError(portalMessages.DATE_SELECTION);
        else
            this.callGetCustomPnlAsync(this.createGetCustomPnlRequestBody(this.state.selectedDate));
    }

    setSelectedDate = selectedDate => this.setState({ selectedDate });

    onSelectedTabChange = key => this.setState({ selectedCounterParty: key });

    onAddDirectionButtonClick = () => this.setState({ showAddDirectionModal: true });

    onCancelAddDirection = () => this.setState({ showAddDirectionModal: false });

    onTableOpenEditMode = () => this.setState({ disableFilter: true });

    onTableCloseEditMode = () => this.setState({ disableFilter: false, newTable: {}, selectedCurrency: null });

    setSelectedCounterParty = key => this.setState({ selectedCounterParty: key });

    setDescription = d => this.enteredDescription = d;

    setSelectedCurrency = selectedCurrency => this.setState({ selectedCurrency });

    renderVerticalTabContent(cParty, direction, items) {

        const showExistingTable = isEmpty(this.state.newTable);

        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <VTable
                            title=""
                            showTotal
                            inputType='number'
                            editModeOn={!isEmpty(this.state.newTable)}
                            customColumnClasses={this.tableHourColumnClass}
                            readonlyColumns={this.tableReadOnlyColumn}
                            scales={{ '*': 2 }}
                            items={items}
                            onCloseEditMode={this.onTableCloseEditMode}
                            onOpenEditMode={this.onTableOpenEditMode}
                            onSaveChangesAsync={(tableToSave) => this.saveTableChangesAsync(tableToSave, cParty, direction)}
                            onTableDelete={() => this.onTableDelete(cParty, direction)}
                            exportFileName={`${cParty}_${direction}_${this.state.latestRequestedFilter.filter.requested_date}`}
                            headerButtons={{
                                showEditButton: true,
                                showExportExcelButton: true,
                                showImportExcelButton: true,
                                showDeleteTableButton: true
                            }}
                        />
                    </div>
                    <div className="col-8 v-custompnl-detail">
                        <div className="row">
                            <div className="v-filter-label v-label">
                                Currency
                            </div>
                            <div>
                                <VDropdown
                                    disabled={!this.state.disableFilter}
                                    width="large"
                                    options={this.currencyOptions}
                                    placeholder={showExistingTable ? this.state.tables[cParty][direction].currency : ''}
                                    value={this.state.selectedCurrency}
                                    onSelectedOptionChange={this.setSelectedCurrency}
                                    isSearchable={false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="v-filter-label v-label">
                                Description
                            </div>
                            <div className="v-custompnl-messagebox">
                                <VMessageBox
                                    showLimit
                                    defaultValue={showExistingTable ? this.state.tables[cParty][direction].previousDescription : ''}
                                    onChange={this.setDescription}
                                    maxLength={this.maxDescriptionLength}
                                    disabled={!this.state.disableFilter}
                                    placeholder={portalMessages.CUSTOM_PNL.DESCRIPTION_PLACEHOLDER}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderVerticalTabs(cParty, tablesOfACounterParty) {

        return Object.keys(tablesOfACounterParty).map(direction => {

            let key = `${cParty}-${direction}`;

            return (
                <VVerticalTab key={key} eventKey={key} title={direction}>
                    {this.renderVerticalTabContent(cParty, direction, tablesOfACounterParty[direction])}
                </VVerticalTab>
            );
        });
    }

    renderTabContent(cParty) {
        let tablesOfACounterParty = isEmpty(this.state.newTable) ? this.state.tables[cParty] : this.state.newTable[cParty];

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 v-custompnl-add-direction" >
                        <button className="btn v-button v-tab-button"
                            disabled={this.state.disableFilter || isEmpty(this.getAvailableDirections())}
                            onClick={this.onAddDirectionButtonClick}>
                            <i aria-hidden="true" className="fa fa-plus fa-fw" />
                                Add Direction
                        </button>
                    </div>
                </div>
                {
                    tablesOfACounterParty ?
                        <div className="row">
                            <div className="v-infinite-width">
                                <VVerticalTabs
                                    disableAllTabs={this.state.disableFilter}>
                                    {this.renderVerticalTabs(cParty, tablesOfACounterParty)}
                                </VVerticalTabs>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <span className="v-custompnl-no-direction">
                                No customized direction for {cParty}
                            </span>
                        </div>
                }
            </div>
        );
    }

    renderTabs() {
        return this.state.cParties.map(cParty => {
            
            return (
                <VTab key={cParty} eventKey={cParty} title={this.generateTag(cParty).text}>
                    {this.renderTabContent(cParty)}
                </VTab>
            );
        });
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Custom PnL">
                    <VFilterContainer showActiveFilter activeFilter={this.state.activeFilter}>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                            </div>
                            <div>
                                <VDatePicker
                                    disabled={this.state.disableFilter}
                                    selectedDate={this.state.selectedDate}
                                    onSelectedDateChange={this.setSelectedDate}
                                    maxDate={this.maxDate}
                                />
                            </div>
                        </div>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Counter Party
                            </div>
                            <div className="v-filter-taginput v-auction-bid">
                                <VTagInput
                                    disabled={true}
                                    addStyle={AddStyles.Textbox}
                                    tags={this.state.cPartyTags}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                disabled={this.state.disableFilter}
                                className="btn v-cancel-button v-filter-button"
                                onClick={this.onClearButtonClick}>
                                <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                            </button>
                            <button
                                disabled={this.state.disableFilter}
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={this.onShowButtonClick}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                        </div>
                    </VFilterContainer>
                    <VMainContainer>
                        <VTabs
                            onSelect={this.setSelectedCounterParty}
                            disableAllTabs={this.state.disableFilter}
                            disabledAllMessage={portalMessages.TAB_SELECTION}>
                            {this.renderTabs()}
                        </VTabs>
                    </VMainContainer>
                </VContentContainer>
                {
                    this.state.showNeedRefreshModal &&
                    <NeedRefreshModal
                        show={this.state.showNeedRefreshModal}
                        message={portalMessages.COULD_NOT_GET_C_PARTIES}
                    />
                }
                {
                    this.state.showAddDirectionModal &&
                    <AddDirectionModal
                        show={this.state.showAddDirectionModal}
                        directions={this.getAvailableDirections()}
                        counterParty={this.state.selectedCounterParty}
                        onAdd={this.onAddDirection}
                        onCancel={this.onCancelAddDirection}
                    />
                }
            </React.Fragment>
        );
    }


}

export default CustomPnl;