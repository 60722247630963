import * as XLSX from 'xlsx';

export class AuctionAllocationsExcelHelper {

    /*
        Abbreviations
        -------------
        s -> start cell
        e -> end cell
        c -> column
        r- > row    
    */

    constructor(file) {
        this.file = file;
    }

    excelValueAddresses = {
        "BG-RS": { "s": { "c": 7, "r": 3 }, "e": { "c": 7, "r": 27 } },
        "RS-BG": { "s": { "c": 14, "r": 3 }, "e": { "c": 14, "r": 27 } },
        "HU-RS": { "s": { "c": 7, "r": 3 }, "e": { "c": 7, "r": 27 } },
        "RS-HU": { "s": { "c": 14, "r": 3 }, "e": { "c": 14, "r": 27 } },
        "BG-RO": { "s": { "c": 7, "r": 3 }, "e": { "c": 7, "r": 27 } },
        "RO-BG": { "s": { "c": 14, "r": 3 }, "e": { "c": 14, "r": 27 } },
        "RO-RS": { "s": { "c": 7, "r": 3 }, "e": { "c": 7, "r": 27 } },
        "RS-RO": { "s": { "c": 14, "r": 3 }, "e": { "c": 14, "r": 27 } },
        "HR-HU": { "s": { "c": 7, "r": 3 }, "e": { "c": 7, "r": 27 } },
        "HU-HR": { "s": { "c": 14, "r": 3 }, "e": { "c": 14, "r": 27 } },
        "HR-RS": { "s": { "c": 7, "r": 3 }, "e": { "c": 7, "r": 27 } },
        "RS-HR": { "s": { "c": 14, "r": 3 }, "e": { "c": 14, "r": 27 } },
        "BG-TR": { "s": { "c": 7, "r": 3 }, "e": { "c": 7, "r": 27 } },
        "TR-BG": { "s": { "c": 14, "r": 3 }, "e": { "c": 14, "r": 27 } },
        "IT-ME": { "s": { "c": 7, "r": 3 }, "e": { "c": 7, "r": 27 } },
        "ME-IT": { "s": { "c": 14, "r": 3 }, "e": { "c": 14, "r": 27 } },
        "ME-RS": { "s": { "c": 7, "r": 3 }, "e": { "c": 7, "r": 27 } },
        "RS-ME": { "s": { "c": 14, "r": 3 }, "e": { "c": 14, "r": 27 } },
        "CH-IT": { "s": { "c": 7, "r": 3 }, "e": { "c": 7, "r": 27 } },
        "IT-CH": { "s": { "c": 14, "r": 3 }, "e": { "c": 14, "r": 27 } }
    }

    excelCapacityIdAdresses = {
        "BG-RS": { "c": 4, "r": 30 },
        "RS-BG": { "c": 11, "r": 30 },
        "HU-RS": { "c": 4, "r": 30 },
        "RS-HU": { "c": 11, "r": 30 },
        "BG-RO": { "c": 4, "r": 30 },
        "RO-BG": { "c": 11, "r": 30 },
        "RO-RS": { "c": 4, "r": 30 },
        "RS-RO": { "c": 11, "r": 30 },
        "HR-HU": { "c": 4, "r": 30 },
        "HU-HR": { "c": 11, "r": 30 },
        "HR-RS": { "c": 4, "r": 30 },
        "RS-HR": { "c": 11, "r": 30 },
        "BG-TR": { "c": 4, "r": 30 },
        "TR-BG": { "c": 11, "r": 30 },
        "IT-ME": { "c": 4, "r": 30},
        "ME-IT": { "c": 11, "r": 30},
        "ME-RS": { "c": 4, "r": 30},
        "RS-ME": { "c": 11, "r": 30},
        "CH-IT": { "c": 4, "r": 30},
        "IT-CH": { "c": 11, "r": 30}
    }

    loadExcel(expectedDirections, updateTableAfterSpecialFileImport) {

        try {

            let reader = new FileReader();
            let excelValues = {};
            let excelCapacityIds = {};

            reader.onload = (e) => {

                try {

                    let data = e.target.result;
                    let fetched = XLSX.read(data, { type: 'binary' });
                    const tempWsNames = fetched.SheetNames;
                    const wsNames =  tempWsNames.filter(function (sheetName) {
                        return sheetName !== 'RS<>ME';
                    });

                    expectedDirections.forEach(direction => {

                        let fromCountry = direction.split('-')[0];
                        let toCountry = direction.split('-')[1];

                        let wsName = wsNames.find(ws => ws.includes(fromCountry) && ws.includes(toCountry));
                        if (wsName) {
                            let ws = fetched.Sheets[wsName];


                            let range = this.excelValueAddresses[direction];
                            let values = [];

                            for (let R = range.s.r; R <= range.e.r; ++R) {
                                for (let C = range.s.c; C <= range.e.c; ++C) {
                                    let cell_address = { c: C, r: R };
                                    let data = XLSX.utils.encode_cell(cell_address);
                                    values.push(ws[data] ? ws[data] : { t: "n", v: 0, w: "0" });
                                }
                            }

                            excelValues[direction] = values;

                            let cap_id_cell_address = this.excelCapacityIdAdresses[direction];
                            let data = XLSX.utils.encode_cell(cap_id_cell_address);

                            excelCapacityIds[direction] = ws[data] ? ws[data].v : '-';

                        } else {
                            let range = this.excelValueAddresses[direction];
                            let values = [];

                            for (let R = range.s.r; R <= range.e.r; ++R) {
                                for (let C = range.s.c; C <= range.e.c; ++C) {
                                
                                    values.push({ t: "n", v: 0, w: "0" });
                                }
                            }

                            excelValues[direction] = values;
                            excelCapacityIds[direction] = '-';
                        }
                        

                    });

                    updateTableAfterSpecialFileImport({values: excelValues, capacityIds: excelCapacityIds});

                } catch (error) {
                    updateTableAfterSpecialFileImport({});
                }

            };

            reader.readAsBinaryString(this.file);

        } catch (error) {
            updateTableAfterSpecialFileImport({});
        }

    }


};