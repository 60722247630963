import './VFooter.css';

import React from 'react';


const VFooter = () => {

    return (
        <footer className="py-3">
            <hr />
            <div className="container text-center">
                <span className="v-footer-text">© 2020, Vitus Commodities. All rights reserved.</span>
            </div>
        </footer>
    );
};

export default VFooter;