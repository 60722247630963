import './VSpinner.css';

import React from 'react';
import { Modal } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

const VSpinner = props => {
    return (
        <div className='v-spinner'>
            <Modal show={props.show || props.loading.spinnerKeys.length !== 0}
                className='v-spinner-modal'
                dialogClassName='v-spinner-modal-dialog'
                onHide={() => { return; }}
                animation={false}
            >
                <div className='v-spinner-icon'>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.loading
    };
};

export default connect(mapStateToProps)(VSpinner);