import React from 'react';

import TTable, { TfilterTypes } from '../../components/TTable/TTable';
import { BILLING_PERIODS, COST_OPERATION_TYPES } from '../../utils/constants';
import { convertDateToString } from '../../utils/common';

const columns = [
    { key: 'cost_type', name: 'Cost Type', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
    { key: 'broker', name: 'Broker', resizable: true, TfilterType: TfilterTypes.input },
    { key: 'country', name: 'Country', width: 40, resizable: true, TfilterType: TfilterTypes.input },
    { key: 'cost', name: 'Cost', width: 40, resizable: true, },
    { key: 'currency', name: 'Currency', width: 40, resizable: true, },
    { key: 'cost_calculation', name: 'Cost Calculation', sortable: true, resizable: true, },
    { key: 'billing_period', name: 'Billing Period', sortable: true, resizable: true, },
    { key: 'cost_operation_type', name: 'Cost Operation Type', sortable: true, resizable: true, },
    { key: 'cost_start_date', name: 'Cost Start Date', sortable: true, resizable: true, },
    { key: 'cost_end_date', name: 'Cost End Date', sortable: true, resizable: true, }
];

const renderTable = (data, onEditRowClick, onDeleteRowClick) => {

    const rows = data.map(c => {
        return {
            cost_id: c.cost_id,
            cost_type: c.cost_type,
            broker: c.broker,
            country: c.country_code,
            country_id: c.country_id,
            cost: c.cost,
            currency: c.currency,
            cost_calculation: c.cost_calculation,
            billing_period: BILLING_PERIODS.find(i => i.value === `${c.billing_period}`).label,
            cost_operation_type: COST_OPERATION_TYPES.find(i => i.value === c.cost_operation_type).label,
            cost_start_date: convertDateToString(new Date(c.cost_start_date)),
            cost_end_date: convertDateToString(new Date(c.cost_end_date))
        }
    });

    return (
        <TTable
            showEditRow
            onEditRowClick={onEditRowClick}
            showDeleteRow
            onDeleteRowClick={onDeleteRowClick}
            showFilterButtons
            rows={rows}
            columns={columns}
        />
    );
};

const CostList = ({ data, onEditRowClick, onDeleteRowClick }) => {

    return (
        <div>
            {renderTable(data, onEditRowClick, onDeleteRowClick)}
        </div>
    );
};

export default CostList;