export const UNITS = {
    fix: { value: 'fix', label: 'Fix' },
    mw: { value: 'mw', label: 'MW' }
}

export const UNIT_ARR = Object.keys(UNITS).map(k => UNITS[k])

export const CURRENCIES = {
    EUR: { value: 'EUR', label: 'EUR' },
    TRY: { value: 'TRY', label: 'TRY' },
    USD: { value: 'USD', label: 'USD' },
    GBP: { value: 'GBP', label: 'GBP' },
}

export const CURRENCY_ARR = Object.keys(CURRENCIES).map(k => CURRENCIES[k])

export const PRICE_SOURCES = {
    vitus: { value: 'vitus', label: 'Vitus' },
    eex: { value: 'eex', label: 'EEX' },
    ice: { value: 'ice', label: 'ICE' },
    ice_last_trades: { value: 'ice_last_trades', label: 'ICE LT' },
    eex_last_trades: { value: 'eex_last_trades', label: 'EEX LT' },
    viop: {value: 'viop', label: 'VIOP'},
}

export const PRICE_SOURCE_ARR = Object.keys(PRICE_SOURCES).map(k => PRICE_SOURCES[k])

export const TIMEZONES = [
    { value: 'CET', label: 'CET' },
    { value: 'UTC', label: 'UTC' },
    { value: 'Europe/Amsterdam', label: 'Europe/Amsterdam' },
    { value: 'Europe/Andorra', label: 'Europe/Andorra' },
    { value: 'Europe/Astrakhan', label: 'Europe/Astrakhan' },
    { value: 'Europe/Athens', label: 'Europe/Athens' },
    { value: 'Europe/Belfast', label: 'Europe/Belfast' },
    { value: 'Europe/Belgrade', label: 'Europe/Belgrade' },
    { value: 'Europe/Berlin', label: 'Europe/Berlin' },
    { value: 'Europe/Bratislava', label: 'Europe/Bratislava' },
    { value: 'Europe/Brussels', label: 'Europe/Brussels' },
    { value: 'Europe/Bucharest', label: 'Europe/Bucharest' },
    { value: 'Europe/Budapest', label: 'Europe/Budapest' },
    { value: 'Europe/Busingen', label: 'Europe/Busingen' },
    { value: 'Europe/Chisinau', label: 'Europe/Chisinau' },
    { value: 'Europe/Copenhagen', label: 'Europe/Copenhagen' },
    { value: 'Europe/Dublin', label: 'Europe/Dublin' },
    { value: 'Europe/Gibraltar', label: 'Europe/Gibraltar' },
    { value: 'Europe/Guernsey', label: 'Europe/Guernsey' },
    { value: 'Europe/Helsinki', label: 'Europe/Helsinki' },
    { value: 'Europe/Isle_of_Man', label: 'Europe/Isle_of_Man' },
    { value: 'Europe/Istanbul', label: 'Europe/Istanbul' },
    { value: 'Europe/Jersey', label: 'Europe/Jersey' },
    { value: 'Europe/Kaliningrad', label: 'Europe/Kaliningrad' },
    { value: 'Europe/Kiev', label: 'Europe/Kiev' },
    { value: 'Europe/Kirov', label: 'Europe/Kirov' },
    { value: 'Europe/Lisbon', label: 'Europe/Lisbon' },
    { value: 'Europe/Ljubljana', label: 'Europe/Ljubljana' },
    { value: 'Europe/London', label: 'Europe/London' },
    { value: 'Europe/Luxembourg', label: 'Europe/Luxembourg' },
    { value: 'Europe/Madrid', label: 'Europe/Madrid' },
    { value: 'Europe/Malta', label: 'Europe/Malta' },
    { value: 'Europe/Mariehamn', label: 'Europe/Mariehamn' },
    { value: 'Europe/Minsk', label: 'Europe/Minsk' },
    { value: 'Europe/Monaco', label: 'Europe/Monaco' },
    { value: 'Europe/Moscow', label: 'Europe/Moscow' },
    { value: 'Europe/Nicosia', label: 'Europe/Nicosia' },
    { value: 'Europe/Oslo', label: 'Europe/Oslo' },
    { value: 'Europe/Paris', label: 'Europe/Paris' },
    { value: 'Europe/Podgorica', label: 'Europe/Podgorica' },
    { value: 'Europe/Prague', label: 'Europe/Prague' },
    { value: 'Europe/Riga', label: 'Europe/Riga' },
    { value: 'Europe/Rome', label: 'Europe/Rome' },
    { value: 'Europe/Samara', label: 'Europe/Samara' },
    { value: 'Europe/San_Marino', label: 'Europe/San_Marino' },
    { value: 'Europe/Sarajevo', label: 'Europe/Sarajevo' },
    { value: 'Europe/Saratov', label: 'Europe/Saratov' },
    { value: 'Europe/Simferopol', label: 'Europe/Simferopol' },
    { value: 'Europe/Skopje', label: 'Europe/Skopje' },
    { value: 'Europe/Sofia', label: 'Europe/Sofia' },
    { value: 'Europe/Stockholm', label: 'Europe/Stockholm' },
    { value: 'Europe/Tallinn', label: 'Europe/Tallinn' },
    { value: 'Europe/Tirane', label: 'Europe/Tirane' },
    { value: 'Europe/Tiraspol', label: 'Europe/Tiraspol' },
    { value: 'Europe/Ulyanovsk', label: 'Europe/Ulyanovsk' },
    { value: 'Europe/Uzhgorod', label: 'Europe/Uzhgorod' },
    { value: 'Europe/Vaduz', label: 'Europe/Vaduz' },
    { value: 'Europe/Vatican', label: 'Europe/Vatican' },
    { value: 'Europe/Vienna', label: 'Europe/Vienna' },
    { value: 'Europe/Vilnius', label: 'Europe/Vilnius' },
    { value: 'Europe/Volgograd', label: 'Europe/Volgograd' },
    { value: 'Europe/Warsaw', label: 'Europe/Warsaw' },
    { value: 'Europe/Zagreb', label: 'Europe/Zagreb' },
    { value: 'Europe/Zaporozhye', label: 'Europe/Zaporozhye' },
    { value: 'Europe/Zurich', label: 'Europe/Zurich' }
];

export const COST_TYPES = [
    { value: 'Broker Fee', label: 'Broker Fee' },
    { value: 'Market Access Fee', label: 'Market Access Fee' },
    { value: 'Broker Fee - Brent', label: 'Broker Fee - Brent' },
    { value: 'Broker Fee - EUA', label: 'Broker Fee - EUA' },
    { value: 'Finansman', label: 'Finansman' },
    { value: 'Financing Physical', label: 'Financing Physical' },
    { value: 'Broker Fee - JKM', label: 'Broker Fee - JKM' },
    { value: 'Broker Fee - TTF', label: 'Broker Fee - TTF' },
    { value: 'Broker Fee - HENRY HUB', label: 'Broker Fee - HENRY HUB' },
    { value: 'Broker Fee - CRUDE OIL', label: 'Broker Fee - CRUDE OIL' },
    { value: 'Broker Fee - COAL', label: 'Broker Fee - COAL' },
    { value: 'Clearing House Fee - EEX', label: 'Clearing House Fee - EEX' },
    { value: 'Clearing House Fee - EEX-Nordic', label: 'Clearing House Fee - EEX-Nordic' },
    { value: 'Clearing House Fee - EEX-PXE', label: 'Clearing House Fee - EEX-PXE' },
    { value: 'Clearing House Fee - EEX-Day/Weekend', label: 'Clearing House Fee - EEX-Day/Weekend' },
    { value: 'Clearing House Fee - EEX-TTF', label: 'Clearing House Fee - EEX-TTF' },
    { value: 'Clearing House Fee - TTF', label: 'Clearing House Fee - TTF' },
    { value: 'Clearing House Fee - TTF-Block', label: 'Clearing House Fee - TTF-Block' },
    { value: 'Clearing House Fee - EUA', label: 'Clearing House Fee - EUA' },
    { value: 'Clearing House Fee - ICE', label: 'Clearing House Fee - ICE' },
    { value: 'Exchange Fee - EEX', label: 'Exchange Fee - EEX' },
    { value: 'Exchange Fee - EEX-Nordic', label: 'Exchange Fee - EEX-Nordic' },
    { value: 'Exchange Fee - EEX-PXE', label: 'Exchange Fee - EEX-PXE' },
    { value: 'Exchange Fee - EEX-Day/Weekend', label: 'Exchange Fee - EEX-Day/Weekend' },
    { value: 'Exchange Fee - EEX-TTF', label: 'Exchange Fee - EEX-TTF' },
    { value: 'Exchange Fee - TTF', label: 'Exchange Fee - TTF' },
    { value: 'Exchange Fee - TTF-Block', label: 'Exchange Fee - TTF-Block' },
    { value: 'Exchange Fee - EUA', label: 'Exchange Fee - EUA' },
    { value: 'Exchange Fee - ICE', label: 'Exchange Fee - ICE' },
    { value: 'Commission - EEX', label: 'Commission - EEX' },
    { value: 'Commission - EEX-Nordic', label: 'Commission - EEX-Nordic' },
    { value: 'Commission - EEX-PXE', label: 'Commission - EEX-PXE' },
    { value: 'Commission - EEX-Day/Weekend', label: 'Commission - EEX-Day/Weekend' },
    { value: 'Commission - EEX-TTF', label: 'Commission - EEX-TTF' },
    { value: 'Commission - TTF', label: 'Commission - TTF' },
    { value: 'Commission - TTF-Block', label: 'Commission - TTF-Block' },
    { value: 'Commission - EUA', label: 'Commission - EUA' },
    { value: 'Commission - ICE', label: 'Commission - ICE' }
];

export const COST_CALCULATION_TYPES = [
    { value: 'Per Mwh etc', label: 'Per Mwh etc' },
    { value: 'Percent on Deal', label: 'Percent on Deal' }
];

export const BILLING_PERIODS = [
    { value: '2', label: 'Monthly' },
    { value: '3', label: 'Yearly' }
];

export const COST_OPERATION_TYPES = [
    { value: 'Addition', label: 'Addition' },
    { value: 'Multiplication', label: 'Multiplication' }
];

export const DELIVERY_TYPES = {
    physical: { value: "physical", label: "Physical"},
    financial: { value: "financial", label: "Financial"}
}

export const DELIVERY_TYPE_ARR = Object.keys(DELIVERY_TYPES).map(k => DELIVERY_TYPES[k])

export const CONTRACT_TYPES = {
    futures: { value: "futures", label: "Futures" },
    options: { value: "options", label: "Options" }
}

export const CONTRACT_TYPE_ARR = Object.keys(CONTRACT_TYPES).map(k => CONTRACT_TYPES[k])

export const TRADING_VENUE = {
    otc_physical: { value: "otc_physical", label: "OTC & Physical" },
    exchange: { value: "exchange", label: "Exchange" }
}

export const TRADING_VENUE_ARR = Object.keys(TRADING_VENUE).map(k => TRADING_VENUE[k])

export const PRODUCT = {
    power:  { value: "power", label: "Power" },
    equity: { value: "equity", label: "Equity"},
    foreignExchangeProducts: { value: "foreignExchangeProducts", label: "Foreign Exchange Products"},
    emission: { value: "emission", label: "Emission"},
    naturalGas: { value: "naturalGas", label: "Natural Gas"},
    crudeOilAndRefinedProducts: { value: "crudeOilAndRefinedProducts", label: "Crude Oil and Refined Products"},
    preciousMetals: { value: "preciousMetals", label: "Precious Metals"}
}

export const PRODUCT_ARR = Object.keys(PRODUCT).map(k => PRODUCT[k])

export const TRADER = {
    human : { value: "human", label: "Human"},
    human2 : { value: "human2", label: "Human 2"},
    human3 : {value: "human3", label: "Human 3"},
    human4 :  {value: "human4", label: "Human 4"},
    human5: {value: "human5", label: "Human 5"},
    human6: {value: "human6", label: "Human 6"},
    human7: {value: "human7", label: "Human 7"},
    api: { value: "api", label: "API" },
    algo_api: { value: "algo_api", label: "ALGO-API" },
    servicing: { value: "servicing", label: "Servicing"}
}

export const TRADER_ARR = Object.keys(TRADER).map(k => TRADER[k])

export const CONTRACT_DATE_TYPE = {
    year: { value: "year", label: "Year", hasTerm: false},
    half: { value: "half", label: "Half", hasTerm: false},
    half2: { value: "half2", label: "Half2", hasTerm: false},
    quarter: { value: "quarter", label: "Quarter", hasTerm: false},
    quarter2: { value: "quarter2", label: "Quarter2", hasTerm: false},
    quarter3: { value: "quarter3", label: "Quarter3", hasTerm: false},
    quarter4:{ value: "quarter4", label: "Quarter4", hasTerm: false},
    month: { value: "month", label: "Month", hasTerm: true},
    day: { value: "day", label: "Day", hasTerm: false, hasDateSelect: true},
    week: { value: "week", label: "Week", hasTerm: true},
    weekend: { value: "weekend", label: "Weekend", hasTerm: true},
    weekday: { value: "weekday", label: "Weekday", hasTerm: true},
    bom: { value: "bom", label: "BOM", hasTerm: false },
    boy: { value: "boy", label: "BOY", hasTerm: false },
    flexible: { value: "flexible", label: "Flexible"}
}

export const CONTRACT_DATE_TYPE_ARR = Object.keys(CONTRACT_DATE_TYPE).map(k => CONTRACT_DATE_TYPE[k])

export const POSITION = {
    sell: "sell",
    buy: "buy"
}

export const POSITION_ARR = Object.keys(POSITION).map(p => ({
    value: POSITION[p],
    label: POSITION[p]
}))

export const POSITION_PERIOD = {
    st: { value: "st", label: "Short term"},
    lt: { value: "lt", label: "Long term"}
}

export const POSITION_PERIOD_ARR = Object.keys(POSITION_PERIOD).map(k => POSITION_PERIOD[k])

export const DOCUMENT_TYPES = {
    protocol: { value: "protocol", label: "Protocol"},
    contract: { value: "contract", label: "Contract"},
    collateral: { value: "collateral", label: "Collateral"},
    companyDocument: { value: "companyDocument", label: "Company Document"},
    others: { value: "others", label: "Others"}
}

export const DOCUMENT_TYPE_ARR = Object.keys(DOCUMENT_TYPES).map(k => DOCUMENT_TYPES[k])

export const PAYMENT_PERIODS = {
    efet: { value: "efet", label: "EFET"},
    advenced: { value: "advenced", label: "Advanced"},
    markToMarket: { value: "markToMarket", label: "Mark to Market"}
}

export const PAYMENT_PERIOD_ARR = Object.keys(PAYMENT_PERIODS).map(k => PAYMENT_PERIODS[k])

export const PAYMENT_TIME_PERIODS = {
    weekly: { value: "weekly", label: "Weekly"},
    monthly: { value: "monthly", label: "Monthly"}
}

export const PAYMENT_TIME_PERIOD_ARR = Object.keys(PAYMENT_TIME_PERIODS).map(k => PAYMENT_TIME_PERIODS[k])

export const COLLATERAL_TYPES = {
    letter: { value: "letter", label: "Letter"},
    cash: { value: "cash", label: "Cash"},
    creditLine: { value: "creditLine", label: "Credit Line"},
}

export const COLLATERAL_TYPE_ARR = Object.keys(COLLATERAL_TYPES).map(k => COLLATERAL_TYPES[k])


export const COLLATERAL_RATES = {
    100: { value: 100, label: "100%"},
    25: { value: 25, label: "25%"},
    10: { value: 10, label: "10%"},
}

export const COLLATERAL_RATE_ARR = Object.keys(COLLATERAL_RATES).map(k => COLLATERAL_RATES[k])

export const COMPANY_TYPES = {
    myCompany: { value: "myCompany", label: "My Company" },
    exchange: { value: "exchange", label: "Exchange" },
    broker: { value: "broker", label: "Broker" },
    marketOperator: { value: "marketOperator", label: "Market Operator" },
    bank: { value: "bank", label: "Bank" },
    counterParty: { value: "counterParty", label: "Counter Party" }
} 

export const COMPANY_TYPE_ARR = Object.keys(COMPANY_TYPES).map(k => COMPANY_TYPES[k])
