import React from 'react';
import history from '../../../history';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer'
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import RightSideContainer from '../../components/TTPageContainers/RightSideContainer';
import TConfirmationModal from '../../components/TConfirmationModal/TConfirmationModal';
import TTabs from '../../components/TTabs/TTabs';
import TTable from '../../components/TTable/TTable';
import { TfilterTypes } from '../../components/TTable/TTable';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import TBaseModal from '../../components/TBaseModal/TBaseModal';
import TabPanel from '../../components/TTabs/TabPanel';
import AccordionActions from '@material-ui/core/AccordionActions'
import Divider from '@material-ui/core/Divider';
import { handleApiError, alertError, alertSuccess } from '../../utils/errorHandler';
import { messages } from '../../utils/messages';
import { getMarkets, getMarketNames, saveMarket, deleteMarket, getPriceCurvesDetail, getBookDetails } from '../../api/services';
import MarketDetail from '../Market/MarketDetail';

class Market extends React.Component {
    title = "Market"

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        openAddDialogBox: false,
        showDeleteConfirmationModal: false,
        selectedRow: null,
        defaultSelectedTabId: null,
        tabInfo: [],
        tableRows: [],
        tableColumns: [
            //{ key: 'id', name: 'Id', width: 40},
            { key: 'name', name: 'Name', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'shortName', name: 'Short Name', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'priceCurveName', name: 'Price Curve', sortable: true, resizable: true, TfilterType: TfilterTypes.input }
        ],
        marketNames: [],
        priceCurves: []
    }

    componentDidMount() {
        this.getPriceCurvesDetail();
        this.onShowButtonClick({});
    }

    setTabs = (newTabs, newTabId) => {
        this.setState({ tabInfo: newTabs, defaultSelectedTabId: newTabId })
    }

    onAddButtonClick() {
        this.setState({ openAddDialogBox: true })
    }

    onDeleteRowClick = (row) => {
        this.setState({ showDeleteConfirmationModal: true, selectedRow: row });
    }

    onEditRowClick = (row) => {
        this.addNewTab({ ...row, readOnly: false })
    }

    onCancelButtonClick(market) {
        this.closeAddDialogBox()
        if (market) {
            this.addNewTab({ ...market, readOnly: true })
        }
    }

    closeAddDialogBox() {
        this.setState({ openAddDialogBox: false })
    }

    hideDeleteConfirmationModal = () => {
        this.setState({ showDeleteConfirmationModal: false });
    }

    addNewTab(row) {
        this.setState({ defaultSelectedTabId: row.id })

        let ids = this.state.tabInfo.map(t => t.id)

        row.key = `${row.id}_${row.readOnly}`

        if (!ids.includes(row.id)) {
            this.setState({ tabInfo: [...this.state.tabInfo, row] })
        } else {

            let newTabList = this.state.tabInfo.map(tab => {
                if (tab.id === row.id) {
                    return row
                } else {
                    return tab
                }
            })

            this.setState({ tabInfo: newTabList })
        }
    }

    onShowButtonClick = async (filter) => {
        this.spinner.showSpinner('getMarkets');
        getMarkets(filter)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        tableRows: this.getTableRows(response.data.success.market_result)
                    });
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getMarkets');
            });
    }

    getTableRows(data) {
        let rowList = []
        for (let i = 0; i < data.length; i++) {
            const currentRow = data[i]

            rowList.push({
                id: currentRow.market_id,
                name: currentRow.market_name,
                shortName: currentRow.market_short_name,
                priceCurveId: currentRow.price_curve_id,
                priceCurveName: currentRow.price_curve_name
            })
        }

        return rowList;
    }

    async getPriceCurvesDetail() {
        this.spinner.showSpinner('getPriceCurve');
        getPriceCurvesDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        priceCurves: successData.price_list.map(x => ({ id: x.id, name: x.name, label: x.name })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getPriceCurve');
            });
    }

    async getBookDetails() {
        this.spinner.showSpinner('getBookNames');
        getBookDetails()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        bookNames: successData.book_list.map(x => ({ id: x.id, name: x.name, label: x.name })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getBookNames');
            });
    }

    getFilterData() {
        this.spinner.showSpinner('getMarketNames');
        getMarketNames()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        marketNames: successData.market_name_list.map(x => ({ name: x, label: x })),
                        priceCurves: successData.price_list.map(x => ({ name: x, label: x })),
                        companyNames: successData.company_list.map(x => ({ name: x, label: x })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getMarketNames');
            });
    }

    saveMarketButtonClick(requestBody) {
        this.closeAddDialogBox()
        this.spinner.showSpinner('saveMarkets');
        saveMarket(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.MARKET.SUCCESSFULL_MARKET_SAVE);
                    this.onShowButtonClick({});

                    let row = {
                        id: response.data.success.market_id,
                        name: requestBody.market_name,
                        shortName: requestBody.market_short_name,
                        priceCurveId: requestBody.price_curve_id,
                        priceCurveName: requestBody.price_curve_name
                    }

                    this.addNewTab({ ...row, readOnly: true })
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('saveMarkets');
            });
    }

    deleteMarket = () => {
        this.spinner.showSpinner('deleteMarkets');
        let requestBody = {
            market_id: this.state.selectedRow.id
        }
        this.hideDeleteConfirmationModal();
        deleteMarket(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.MARKET.SUCCESSFULL_MARKET_DELETE)
                    this.onShowButtonClick({});
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('deleteMarkets');
            });
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onAddButtonClick()}
                            >
                                Add
                            </button>
                        </AccordionActions>
                        <TBaseModal
                            dialogClassName="t-modal-50w"
                            show={this.state.openAddDialogBox}
                            onHide={() => this.closeAddDialogBox()}
                            closeButton={true}
                            title={"Add Market"}
                            body={
                                <TabPanel >
                                    <MarketDetail
                                        marketNames={this.state.marketNames}
                                        priceCurves={this.state.priceCurves}
                                        item={null}
                                        onSaveButtonClick={(body) => this.saveMarketButtonClick(body)}
                                        onCancelButtonClick={(market) => this.onCancelButtonClick(market)}
                                        readOnly={false}
                                    >
                                    </MarketDetail>
                                </TabPanel>
                            }
                        >

                        </TBaseModal>
                        <Divider />
                        <TTable
                            showFilterButtons

                            showExportExcelButton
                            exportExcelFileName={this.title}

                            rows={this.state.tableRows}
                            columns={this.state.tableColumns}

                            showDeleteRow
                            showEditRow

                            onDeleteRowClick={this.onDeleteRowClick}
                            onEditRowClick={this.onEditRowClick}
                        >
                        </TTable>
                    </div>
                }
            >
            </LeftSideContainer>
        )
    }

    getTableDetailComponent() {
        return (
            <RightSideContainer>
                <TTabs
                    key={this.state.defaultSelectedTabId}
                    defaultSelectedTabId={this.state.defaultSelectedTabId}
                    setTabs={this.setTabs}
                >
                    {

                        this.state.tabInfo.map((t) => {
                            return (
                                <TabPanel key={`${t.key}_tabpanel`} title={t.name} id={t.id}>
                                    <MarketDetail
                                        key={`${t.key}_MarketDetail`}
                                        marketNames={this.state.marketNames}
                                        priceCurves={this.state.priceCurves}
                                        item={t}
                                        onSaveButtonClick={(body) => this.saveMarketButtonClick(body)}
                                        onEditButtonClick={(row) => this.addNewTab({ ...row, readOnly: false })}
                                        onCancelButtonClick={(market) => this.onCancelButtonClick(market)}
                                        readOnly={t.readOnly}
                                    >
                                    </MarketDetail>
                                </TabPanel>
                            )
                        })
                    }
                </TTabs>
            </RightSideContainer>
        )
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                    rightSideComponent={this.getTableDetailComponent()}
                >
                </TContentDetailContainer>
                {
                    this.state.showDeleteConfirmationModal &&
                    <TConfirmationModal
                        show={this.state.showDeleteConfirmationModal}
                        message={messages.MARKET.CONFIRM_DELETE.replace("[MARKET]", this.state.selectedRow.name)}
                        cancelText='Cancel'
                        onHide={this.hideDeleteConfirmationModal}
                        onCancel={this.hideDeleteConfirmationModal}
                        confirmText='Delete'
                        onConfirm={this.deleteMarket}
                    />
                }
            </TContentContainer>
        );
    }
}

export default Market;