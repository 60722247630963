import './VVerticalTabs.css'

import React, { useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { isArray } from 'lodash';

const validateComponent = (childiren, isChildrenArr) => {

    if (isChildrenArr)
        childiren.forEach(child => {
            if (!child || !child.type || child.type !== VVerticalTab)
                throw new Error('All children of VVerticalTabs must be VVerticalTab!');
        });

    else
        if (!childiren || !childiren.type || childiren.type !== VVerticalTab)
            throw new Error('Child of VVerticalTabs must be VVerticalTab!');
}

const getVerticalTabHeaders = children => {
    return children.map(child => {
        return (
            <Nav.Item key={child.props.eventKey}>
                <Nav.Link eventKey={child.props.eventKey}>{child.props.title}</Nav.Link>
            </Nav.Item>
        );
    });
}

const getVerticalTabContent = children => {
    return children.map(child => {
        return (
            <Tab.Pane key={child.props.eventKey} eventKey={child.props.eventKey}>
                {child}
            </Tab.Pane>
        );
    });
}

const VVerticalTabs = ({ children, onSelect, disableAllTabs = false, activeKey = null }) => {
    const [key, setKey] = useState(activeKey);

    if (activeKey && key !== activeKey)
        setKey(activeKey);

    if (!children || children.length === 0)
        return null;

    let isChildrenArr = isArray(children);
    validateComponent(children, isChildrenArr);

    if (!key) {
        if (isArray(children)) {
            setKey(children[0].props.eventKey);
        }
        else {
            setKey(children.props.eventKey);
        }
    }
    else {
        if (isArray(children)) {
            if (children.filter(ch => (ch && ch.props.eventKey === key)).length === 0) {
                setKey(children[0].props.eventKey);
            }
        }
        else if (children.props.eventKey !== key) {
            setKey(children.props.eventKey);
        }
    }

    const onControlledSelect = (k) => {
        setKey(k);
        if (onSelect)
            return onSelect(k);
    };

    return (
        <div className="v-vertical-tab-container">
            <Tab.Container
                onSelect={onControlledSelect}
                activeKey={key}>
                <div className="row">
                    <div className={`col v-vertical-tab-header ${disableAllTabs ? 'v-disabled-vertical-tabs' : ''}`}>
                        <Nav variant="pills" className="flex-column v-font v-font-size">
                            {isChildrenArr ?
                                getVerticalTabHeaders(children)
                                :
                                <Nav.Item key={children.props.eventKey}>
                                    <Nav.Link
                                        eventKey={children.props.eventKey}>
                                        {children.props.title}
                                    </Nav.Link>
                                </Nav.Item>
                            }
                        </Nav>
                    </div>
                    <div className="col v-vertical-tab-content v-font v-font-size">
                        <Tab.Content>
                            {isChildrenArr ?
                                getVerticalTabContent(children)
                                :
                                <Tab.Pane key={children.props.eventKey} eventKey={children.props.eventKey}>
                                    {children}
                                </Tab.Pane>
                            }
                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>
        </div>
    );

};

export const VVerticalTab = ({ children, ...rest }) => {
    const { eventKey, ...restExceptKey } = rest;
    return (
        <div {...restExceptKey}
            key={eventKey}>
            {children}
        </div>
    );
};

export default VVerticalTabs;
