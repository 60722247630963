import React from "react";
import TContentContainer from "../../components/TContentContainer/TContentContainer";
import TContentDetailContainer from "../../components/TContentDetailContainer/TContentDetailContainer";
import LeftSideContainer from "../../components/TTPageContainers/LeftSideContainer";
import PosAndMarkFilter from './PosAndMarkFilter';
import {  PRICE_SOURCES } from '../../utils/constants';
import {
    getMarketNames,
    getBookDetails,
    getCompaniesDetail,
    getPosAndMark,
    downloadPosAndMark,
    sendPosAndMark
} from "../../api/services";
import { handleApiError, alertError } from "../../utils/errorHandler";
import { SpinnerManager } from "../../components/TSpinner/SpinnerManager";
import history from "../../../history";
import DataGrid from 'react-data-grid';
import FileSaver from 'file-saver';
import moment from 'moment';
import { alertSuccess } from '../../../helpers/errorHelper';
import { messages } from '../../utils/messages';


const shortTermMarks = "Short Term Marks"

class PosAndMark extends React.Component {
    title = "Position And Mark Reporting";

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        rows: [],
        columns: [],
        selectedRows: new Set(),
        counterPartyNames: [],
        marketNames: [],
        bookNames: [],
        companySummaryTables: {},
        filter: {},
        positionResponse: [],
        positionColumns: []
    };

    componentDidMount() {
        this.getCompaniesDetail();
        this.getMarketDetails();
        this.getBookDetails();
    }

    onShowButtonClick = async (filter) => {
        this.spinner.showSpinner("getPosAndMark");
        this.setState({ filter: filter });

        getPosAndMark(filter)
            .then((res) => {
                if (res.data.success) {
                    const resultList = res.data.success.result_list;

                    let finalResultList = Object.keys(resultList).map(book => {
                        let book_result = resultList[book]
                        let newHeaderList = []
                        let newSubHeaderDict = {}
                        book_result.header.map(h => {
                            let newSubHeaderList = book_result.sub_header[h].map(sub => {
                                if( h === shortTermMarks)  {
                                    return sub
                                }

                                let allZero = book_result.row_list.map(r => r[h][sub].peak === 0 && r[h][sub].offpeak === 0).every(r => r)
                            
                                if (!allZero) {
                                    return sub
                                }
                            }).filter(s => s)

                            if (newSubHeaderList.length) {
                                newSubHeaderDict[h] = newSubHeaderList
                                newHeaderList = [...newHeaderList, h]
                            }

                        })

                        book_result["header"] = newHeaderList
                        book_result["sub_header"] = newSubHeaderDict
                        book_result["price_source"] = filter.price_source
                        return book_result
                    })

                    let date_column = {
                        key: 'formatted_date',
                        name: 'Date',
                        width: 150,
                        frozen: true,
                        formatter({ row }) {
                            if (row.date) {
                                if (new Date(row.date).getDay() === 0) {
                                    return (<div className="end-of-week">
                                        {row.formatted_date}
                                    </div>
                                    )
                                }
                            } else {
                                return (<div className="table-summary">
                                        {row.formatted_date}
                                    </div>
                                    )
                            }

                            return <>{row.formatted_date}</>
                            
                        }
                    }

                    let book_data_list = Object.keys(finalResultList).map(book => {

                        let book_result = finalResultList[book]

                        let columnList = book_result.header.map(h => {
                            return {
                                key: h,
                                subHeader: book_result.sub_header[h],
                                name: h === shortTermMarks ? `${h} (${PRICE_SOURCES[book_result.price_source]?.label})` : h,
                                width: book_result.sub_header[h]?.length * 150,
                                height: 150,
                                formatter({ row }) {
                                    
                                    return (
                                        <div id={`${row.formatted_date}_${h}_header_name`} key={`${row.formatted_date}_${h}_header_name`} style={{ borderStyle: "groove", display: "flex" }}>
                                            {
                                                row.sub_header[h].map((s, index) => {
                                                    let pos_peak = row[h][s]?.peak
                                                    let pos_offpeak = row[h][s]?.offpeak
                                                    let mark_baseload = row[h][s]?.baseload
                                                    let market_count = row.sub_header[h].length

                                                    let backgroundColor = ""
                                                    if (row.date) {
                                                        backgroundColor = index % 2 === 0 ? "#f6d7be" : ""
                                                    } else {
                                                        backgroundColor = "#c1bdbd"
                                                    }

                                                    return h.startsWith(shortTermMarks) ? (
                                                        <div key={`${row.formatted_date}_${h}_${s}_sub_header_name`}
                                                            style={{ display: "flex", border: "outset", width: `${100 / market_count}%` }}>
                                                            <span key={`${row.formatted_date}_${h}_${s}_peak_header_name`}
                                                                style={{ width: "100%", backgroundColor: backgroundColor }}>
                                                                {mark_baseload ? mark_baseload : 0}
                                                            </span>

                                                        </div>
                                                    ) : (<div key={`${row.formatted_date}_${h}_${s}_sub_header_name`}
                                                        style={{ display: "flex", border: "outset", width: `${100 / market_count}%` }}>
                                                        <span key={`${row.formatted_date}_${h}_${s}_peak_header_name`}
                                                            style={{ width: "50%", backgroundColor: backgroundColor }}>
                                                            {pos_peak ? pos_peak : 0}
                                                        </span>
                                                        <span key={`${row.formatted_date}_${h}_${s}_offpeak_header_name`}
                                                            style={{ width: "50%", backgroundColor: backgroundColor }}>
                                                            {pos_offpeak ? pos_offpeak : 0}
                                                        </span>
                                                    </div>)

                                                })
                                            }
                                        </div>
                                    )

                                },
                                headerRenderer: ({ column }) => (
                                    <div className="headerClassname">
                                        <span style={{ width: "100%", lineHeight: "50px", borderStyle: "groove" }}>{column.name}</span>
                                        <div style={{ borderStyle: "groove", display: "flex" }}>
                                            {
                                            
                                            column.subHeader.map(sub => {
                                                return (

                                                    <div key={`${sub}_header`} className="headerClassname">

                                                        <span key={`${sub}_header_name`} style={{ width: column.name.startsWith(shortTermMarks) ?  "150px":  `100%`, borderStyle: "groove", lineHeight: "50px" }}>
                                                            {sub}
                                                        </span>
                                                        {
                                                            column.name.startsWith(shortTermMarks) ? (
                                                                <div key={`${sub}_sub_header_name`} style={{ borderStyle: "groove", display: "flex" }}>
                                                                    <span key={`${sub}_peak_header_name`} style={{ width: "100%", borderStyle: "groove", lineHeight: "50px" }}>
                                                                        {"Baseload"}
                                                                    </span>
                                                                </div>
                                                            )
                                                                :
                                                                (<div key={`${sub}_sub_header_name`} style={{ borderStyle: "groove", display: "flex" }}>
                                                                    <span key={`${sub}_peak_header_name`} style={{ width: "50%", borderStyle: "groove", lineHeight: "50px" }}>
                                                                        {"Peak"}
                                                                    </span>
                                                                    <span key={`${sub}_offpeak_header_name`} style={{ width: "50%", borderStyle: "groove", lineHeight: "50px" }}>
                                                                        {"Offpeak"}
                                                                    </span>
                                                                </div>)
                                                        }

                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </div>
                                )
                            }


                        })

                        let summaryColumns = [date_column, ...columnList]

                        return {
                            bookName: book_result.book_name,
                            bookColumn: summaryColumns,
                            bookRows: book_result.row_list.map(r => {
                                return { ...r, header: book_result.header, sub_header: book_result.sub_header }
                            }),
                        }


                    })

                    this.setState({
                        companySummaryTables: book_data_list,
                        positionResponse: finalResultList
                    })
                }


            },
            (error) => {
                handleApiError(error);
            })
            .finally(() => this.spinner.hideSpinner("getPosAndMark"));
    };

    async getCompaniesDetail() {
        this.spinner.showSpinner("getCompanyNames");
        getCompaniesDetail()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;
                        this.setState({
                            counterPartyNames: successData.company_list.map((x) => ({
                                id: x.id,
                                value: x.name,
                                label: x.name,
                                type: x.type,
                            })),
                        });
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getCompanyNames");
            });
    }

    async getMarketDetails() {
        this.spinner.showSpinner("getMarketNames");
        getMarketNames()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;
                        this.setState({
                            marketNames: successData.market_name_list.map((x) => ({
                                id: x.id,
                                value: x.name,
                                label: x.name,
                                currency: x.currency,
                            })),
                        });
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getMarketNames");
            });
    }

    async getBookDetails() {
        this.spinner.showSpinner("getBookNames");
        getBookDetails()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;
                        this.setState({
                            bookNames: successData.book_list.map((x) => ({
                                id: x.id,
                                value: x.name,
                                label: x.name,
                            })),
                        });
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getBookNames");
            });
    }

    downloadPosAndMark = async () => {
        const spinnerKey = this.spinner.showSpinner();

        let body = {
            data: {
                result_list: this.state.positionResponse
            }
        }


        downloadPosAndMark(body).then(async response => {
            const filename = `Daily Pos and Marks as of ${moment(new Date()).format('DD MMM YYYY')}.xlsx`;
            const contentType = response.headers["content-type"];

            const blob = new Blob([response.data], { type: contentType });

            if (contentType === 'application/json') {
                const error = blob.text();
                return;
            }

            FileSaver.saveAs(blob, filename);
            return;
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    sendPosAndMark = async () => {
        const spinnerKey = this.spinner.showSpinner();

        const filename = `Daily Pos and Marks as of ${moment(new Date()).format('DD MMM YYYY')}.xlsx`;

        let body = {
            data: {
                result_list: this.state.positionResponse,
                file_name: filename
            }
        }

        sendPosAndMark(body).then(async response => {
            
            if (response.data.success){
                alertSuccess(messages.POS_AND_MARK.SEND_POS_AND_MARK_SUCCESS);
            }
            else{
                alertError(messages.POS_AND_MARK.SEND_POS_AND_MARK_ERROR);
            }
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        <div className="t-table-toolbar">
                            <button type="button" className="btn t-orange-button" onClick={() => { this.downloadPosAndMark() }}>
                                Export to XLSX
                            </button>
                            <button type="button" className="btn t-orange-button" onClick={() => { this.sendPosAndMark() }}>
                                Send to Slack
                            </button>
                        </div>
                        {
                            this.state.companySummaryTables.length ? (
                                this.state.companySummaryTables.map(table => {

                                    return (
                                        <DataGrid
                                            key={`tableBookSummary_${table.bookName}`}
                                            columns={table.bookColumn}
                                            rows={table.bookRows}
                                            headerRowHeight={180}
                                            defaultColumnOptions={{ resizable: true }}

                                        />
                                    )
                                })
                            ) : null

                        }
                    </div>
                }
                filterComponent={
                    this.state.counterPartyNames.length &&
                        this.state.marketNames.length &&
                        this.state.bookNames.length ? (
                            <PosAndMarkFilter
                                counterPartyNames={this.state.counterPartyNames}
                                marketNames={this.state.marketNames}
                                bookNames={this.state.bookNames}
                                onShowButtonClick={this.onShowButtonClick}
                            ></PosAndMarkFilter>
                        ) : null
                }
            ></LeftSideContainer>
        );
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                ></TContentDetailContainer>
            </TContentContainer>
        );
    }
}

export default PosAndMark;
