import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';


export const exportToXlsx = (columns, rows, fileName) => {
    const ws = generateSheet(columns, rows);

    const wb = { Sheets: { 'Sheet1': ws }, SheetNames: ['Sheet1'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};                                                                                                                                             

     
const generateSheet = (columns, rows) => {

    let sheetColumns = columns.map(c => c.name);
    let sheetRows = [...rows.map(r => [...columns.map(c => r[c.key]?.value ?  r[c.key].value : r[c.key] ? r[c.key] : '')])];

    return XLSX.utils.json_to_sheet([sheetColumns, ...sheetRows], { skipHeader: true });
}                                                                                                                    