import React from 'react';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VDropdown from '../../components/VDropdown/VDropdown';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import { alertError, alertSuccess, alertWarning, handleApiError, specifyErrorMessage } from '../../helpers/errorHelper';
import { extractDate, getDayAheadForGivenDate, getCompanyOptions, isCompanySelectable } from '../../helpers/generalHelper';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorageHelper';
import { portalMessages } from '../../helpers/portalMessages';
import history from '../../history';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import {
    getNominations,
    saveNominations,
    deleteNominations,
    saveIntradayProfiles,
    deleteIntradayProfiles,
    sendNominations
} from '../../apis/vitusApi';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import VTable from '../../components/VTable/VTable';
import { isEmpty, isObject } from 'lodash';
import { testingEnvironment } from '../../helpers/generalHelper';
import ResetTableModal from '../../modals/ResetTableModal/ResetTableModal';
import { createExcelWithMultipleSheets } from '../../helpers/excelHelper';
import ConfirmationModal from '../../modals/ConfirmationModal/ConfirmationModal';
import { sortNominationColumns } from './flowUtils';

class PhysicalFlows extends React.Component {
    spinner = new SpinnerManager(history.location.pathname);
    storedActiveFilter = getLocalStorage('physicalFlows', 'filters', 'activeFilter') || {};
    headerClasses = ['v-colored-header-v1', 'v-colored-header-v2'];
    capacityScales = 1;

    columns = {
        hour: { key: "hour", title: "Hour" },
        cet: { key: "cet", title: "Cet" },
        st: { key: "st", title: "Short Term" },
        lt: { key: "lt", title: "Long Term" },
        total: { key: "total", title: "Total" },
        mwh: { key: "mwh", title: "MWh" },
        buy: { key: "buy", title: "Buy" },
        sell: { key: "sell", title: "Sell" }
    };

    summaryDirections = {
        energovia: {
            trbg: {
                title: 'TR-BG',
                search: (str) => str.startsWith("TR-BG")
            },
            bgtr: {
                title: 'BG-TR',
                search: (str) => str.endsWith("BG-TR")
            },
        },
        axpo: {
            trbg: {
                title: 'TR-BG',
                search: (str) => str.startsWith("TR-BG")
            },
            bgtr: {
                title: 'BG-TR',
                search: (str) => str.endsWith("BG-TR")
            },
        },
        ensco:{
            grtr: {
                title: 'GR-TR',
                search: (str) => str.endsWith("GR-TR")
            },
            trgr: {
                title: 'TR-GR',
                search: (str) => str.endsWith("TR-GR")
            }
        },
        green: {
            grtr: {
                title: 'GR-TR',
                search: (str) => str.endsWith("GR-TR")
            },
            trgr: {
                title: 'TR-GR',
                search: (str) => str.endsWith("TR-GR")
            }
        }        
    };

    summaryPlatforms = {
        energovia: ['ESO', 'TCAT'],
        axpo: ['ESO', 'TCAT'],
        ensco: ['SEECAO'],
        green: ['SEECAO']
    }

    counterParties = {
        energovia: {
            name: "Energovia",
            showSummary: true,
            showIntraday: true
        },
        axpo: {
            name: "Axpo",
            specialTable: true
        },
        ensco: {
            name: "Ensco",
            specialTable: true
        },
        green: {
            name: "Green",
            specialTable: true
        },
        monolith: {
            name: "Monolith Capital",
            showIntraday: true
        }
    }

    state = {
        activeFilterToDisplay: [],
        selectedDate: this.getEditableDate(),
        counterPartyOptions: [],
        showNeedsRefresModal: false,
        activeFilter: {
            requestedDate: null,
            counterParty: this.storedActiveFilter?.counterParty,
        },
        inconsistentDataCaught: false,
        editingNominations: false,
        showResetNominationsModal: false,
        editingIntraday: false,
        showResetIntradayModal: false,
        showIrregularMailModal: false,
        irregularMailConfirmed: false,
    };

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Default)',
        InconsistentData: portalMessages.INCONSISTENT_DATA,
    };

    getCounterPartyOptions() {
        return Object.keys(this.counterParties).map(c => { return { value: this.counterParties[c].name, label: this.counterParties[c].name } })
    }

    getActiveCounterPartyConfig() {
        if (!this.state.activeFilter || !this.state.activeFilter.counterParty)
            return {};
        return this.counterParties[this.state.activeFilter.counterParty === 'Monolith Capital' ? 'monolith' : this.state.activeFilter.counterParty.toLowerCase()];
    }

    componentDidMount() {
        let selectedCounterPartyOption;

        const counterPartyOptions = this.getCounterPartyOptions();

        if (this.state.activeFilter.counterParty) {
            selectedCounterPartyOption = counterPartyOptions.filter(e => e.value === this.state.activeFilter.counterParty);

            if (selectedCounterPartyOption.length !== 0)
                selectedCounterPartyOption = selectedCounterPartyOption[0];
            else
                selectedCounterPartyOption = "";
        }

        if (!selectedCounterPartyOption) {
            selectedCounterPartyOption = counterPartyOptions[0];
        }

        this.setState({ counterPartyOptions, selectedCounterPartyOption });

        const filter = {
            requested_date: extractDate(this.state.selectedDate),
            counter_party: selectedCounterPartyOption.value,
        };

        this.refreshNominationsAsync(filter);
    }

    showErrorMessage(error, operationName) {
        const { message, errorType } = specifyErrorMessage(error, this.errorMessages, operationName);

        if (errorType === 'InconsistentData' && !this.state.inconsistentDataCaught)
            this.setState({ inconsistentDataCaught: true });

        if (message)
            alertError(message);
    }

    refreshNominationsAsync = async (filter) => {
        if (!filter)
            filter = {
                requested_date: this.state.activeFilter.requestedDate,
                counter_party: this.state.activeFilter.counterParty,
            };

        return await this.getNominationsAsync(filter)
    }

    getNominationsAsync = async (filter) => {
        const spinnerKey = this.spinner.showSpinner();

        try {
            let response;

            try {
                response = await getNominations(filter);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                const intradayProfiles = response.data.success.intraday_profiles;
                const countryExchangePairs = {};

                intradayProfiles.forEach(p => {
                    countryExchangePairs[p.country] = p.exchange;
                });

                this.setState({
                    nominationList: response.data.success.nomination_list,
                    summaryList: response.data.success.summary_list,
                    intradayProfiles,
                    countryExchangePairs,
                    exchangeResultList: response.data.success.exchange_result_list,
                    mailSent: response.data.success.mail_sent,
                    activeFilter: {
                        requestedDate: filter.requested_date,
                        counterParty: filter.counter_party,
                    },
                    activeFilterToDisplay: [
                        { label: "Date", value: filter.requested_date },
                        { label: "Counter Party", value: filter.counter_party }
                    ]
                }, () => {
                    setLocalStorage('physicalFlows', 'filters', 'activeFilter',
                        { counterParty: filter.counter_party });
                });

            } else if (!response.data.error) {
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Nominations)');
            }

            if (response.data.error) {
                this.showErrorMessage(response.data.error);
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Nominations)');
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    getEditableDate() {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        return getDayAheadForGivenDate(today);
    }

    onClearButtonClick() {
        this.setState({ selectedDate: this.getEditableDate() });
    }

    onShowButtonClick() {
        if (!this.state.selectedDate) {
            this.showErrorMessage(portalMessages.DATE_SELECTION);
            return;
        }

        if (!this.state.selectedCounterPartyOption || isEmpty(this.state.selectedCounterPartyOption)) {
            this.showErrorMessage(portalMessages.SELECT_COUNTER_PARTY);
            return;
        }

        const filter = {
            requested_date: extractDate(this.state.selectedDate),
            counter_party: this.state.selectedCounterPartyOption.value,
        };

        this.refreshNominationsAsync(filter);
    }

    dateIsEditable() {
        return this.state
            && this.state.activeFilter
            && this.state.activeFilter.requestedDate
            && (testingEnvironment() || this.state.activeFilter.requestedDate > extractDate(new Date()));
    }

    editEnabled() {
        return this.dateIsEditable();
    }

    showButtonEnabled() {
        return !this.state.editingNominations && !this.state.editingIntraday;
    }

    sendMailEnabled() {
        return this.editEnabled() && this.showButtonEnabled();
    }

    validateMailData(is_test) {
        const nominations = this.state.nominationList;
        const intradays = this.state.intradayProfiles;
        const countryExchangePairs = this.state.countryExchangePairs;
        const exchangeResultList = this.state.exchangeResultList

        const nominationDict = {};

        nominations.forEach(nom => {
            const countries = nom.direction.split('-');

            if (!nominationDict[countries[0]])
                nominationDict[countries[0]] = { buys: [], sells: [] };
            if (!nominationDict[countries[countries.length - 1]])
                nominationDict[countries[countries.length - 1]] = { buys: [], sells: [] };

            nominationDict[countries[0]].buys.push(nom.data);
            nominationDict[countries[countries.length - 1]].sells.push(nom.data);
        });

        const irregularMailData = { missingExchangeResults: [], differentSellHours: [], differentBuyHours: [] };

        if (!exchangeResultList || exchangeResultList.length !== Object.keys(countryExchangePairs).length)
            irregularMailData.missingExchangeResults = (Object.values(countryExchangePairs).filter(v => !exchangeResultList?.find(e => e.exchange === v)))

        const intradayDict = {};

        intradays.forEach(intra => {
            intradayDict[intra.country] = intra.data;
        });

        const exchangeResultDict = {};

        exchangeResultList.forEach(res => {
            exchangeResultDict[res.country] = res.data;
        });

        const differentBuyHoursSet = new Set();
        const differentSellHoursSet = new Set();

        Object.keys(countryExchangePairs).forEach(country => {
            if (!irregularMailData.missingExchangeResults.includes(countryExchangePairs[country])) { // skip if exchange result is missing
                intradayDict[country].forEach(intra => {

                    // be aware of reverse intraday addition. validation is (i.e for OPCOM):

                    // if(OPCOM exchange result BUY > OPCOM exchange result SELL)
                    //  Sum(nominations starting with RO) + Ro intraday SELL === OPCOM exchange result BUY - OPCOM exchange result SELL
                    //  Sum(nominations ending with RO) + Ro intraday BUY === 0

                    // if(OPCOM exchange result SELL > OPCOM exchange result BUY)
                    //  Sum(nominations ending with RO) + Ro intraday BUY === OPCOM exchange result SELL - OPCOM exchange result BUY
                    //  Sum(nominations starting with RO) + Ro intraday SELL === 0

                    const currentBuyTotal = intra.sell
                        + nominationDict[country].buys.reduce((a, b) => a + b[intra.hour].value, 0);

                    const currentSellTotal = intra.buy
                        + nominationDict[country].sells.reduce((a, b) => a + b[intra.hour].value, 0);

                    const relatedExchangeResult = exchangeResultDict[country][intra.hour];

                    if (relatedExchangeResult.buy > relatedExchangeResult.sell) {
                        if (currentBuyTotal !== (relatedExchangeResult.buy - relatedExchangeResult.sell))
                            differentBuyHoursSet.add(intra.hour + 1);

                        if (currentSellTotal !== 0)
                            differentSellHoursSet.add(intra.hour + 1);
                    }
                    else {
                        if (currentSellTotal !== (relatedExchangeResult.sell - relatedExchangeResult.buy))
                            differentSellHoursSet.add(intra.hour + 1);

                        if (currentBuyTotal !== 0)
                            differentBuyHoursSet.add(intra.hour + 1);
                    }
                });
            }
        });

        irregularMailData.differentBuyHours = Array.from(differentBuyHoursSet);
        irregularMailData.differentSellHours = Array.from(differentSellHoursSet);

        if (!isEmpty(irregularMailData.missingExchangeResults)
            || !isEmpty(irregularMailData.differentSellHours)
            || !isEmpty(irregularMailData.differentBuyHours)) {
            this.setState({
                showIrregularMailModal: true,
                irregularMailBody: this.getIrregularMailMessage(irregularMailData),
                irregularMailAction: () => this.sendMail(is_test)
            });
            return false;
        }

        return true;
    }


    getIrregularMailMessage(irregularMailData) {
        return (
            <React.Fragment>
                <div style={{ padding: "0", paddingRight: "15px" }}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                {portalMessages.PHYSICAL_FLOWS.IRREGULAR_MAIL}
                                <br />
                                {portalMessages.SEND_MAIL_ANYWAY}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                Missing Results:
                            </div>
                            <div className='col-7'>
                                <strong>{irregularMailData?.missingExchangeResults?.join(', ') || "-"}</strong>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                Sell Hours:
                            </div>
                            <div className='col-7'>
                                <strong>{irregularMailData?.differentSellHours?.join(', ') || "-"}</strong>
                            </div>
                        </div>
                        <div className='row'>
                            <div style={{ whiteSpace: 'nowrap' }} className='col'>
                                Buy Hours:
                            </div>
                            <div className='col-7'>
                                <strong>{irregularMailData?.differentBuyHours?.join(', ') || "-"}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }


    onCloseIrregularMailConfirmationModal() {
        this.setState({
            irregularMailBody: null,
            showIrregularMailModal: false,
            irregularMailConfirmed: false
        });
    }

    async onConfirmIrregularMail() {
        this.setState({
            irregularMailBody: null,
            showIrregularMailModal: false,
            irregularMailConfirmed: true
        }, async () => await this.state.irregularMailAction());
    }

    sendTestMail = async () => {
        return this.sendMail(true);
    }

    sendCounterPartyMail = async () => {
        return this.sendMail(false);
    }

    sendMail = async (is_test) => {
        const spinnerKey = this.spinner.showSpinner();

        try {
            if (!this.state.irregularMailConfirmed && !this.validateMailData(is_test))
                return;

            let response;

            let requestData;

            if (this.getActiveCounterPartyConfig().specialTable) {
                requestData = {
                    nomination_list: this.state.summaryList.map(s => {
                        const { data, ...rest } = s;

                        let nominationObj = { ...rest };

                        if (rest.nomination_id)
                            nominationObj.previous_data = data;
                        else
                            nominationObj.updated_data = data;

                        return nominationObj;
                    })
                };
            } else {
                requestData = {
                    nomination_list: sortNominationColumns(this.state.nominationList).map(s => {
                        const { data, ...rest } = s;

                        let nominationObj = { ...rest };

                        if (rest.nomination_id)
                            nominationObj.previous_data = data;
                        else
                            nominationObj.updated_data = data;

                        return nominationObj;
                    }),
                    summary_list: this.state.summaryList.map(s => {
                        const { data, ...rest } = s;
                        return { previous_data: data, ...rest };
                    }),
                    intraday_profiles: this.state.intradayProfiles.map(s => {
                        const { data, ...rest } = s;

                        let intradayObj = { ...rest };

                        if (rest.intraday_id)
                            intradayObj.previous_data = data;
                        else
                            intradayObj.updated_data = data;

                        return intradayObj;
                    })
                };
            }

            let body = {
                requested_date: this.state.activeFilter.requestedDate,
                counter_party: this.state.activeFilter.counterParty,
                is_test,
                ...requestData
            };

            try {
                response = await sendNominations(body);
            } catch (error) {
                handleApiError(error);
                return;
            }

            if (response.data.success) {
                await this.refreshNominationsAsync();
                alertSuccess(portalMessages.MAIL_SENT_SUCCESSFULLY);
            }
            else {
                this.showErrorMessage(response.data.error || {}, "sendMail");
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Send Mail)');
            return false;
        }
        finally {
            this.setState({ irregularMailConfirmed: false });
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    mailAlreadySent() {
        return this.state.mailSent;
    }

    exportAllEnabled() {
        return this.showButtonEnabled()
            && ((this.getActiveCounterPartyConfig().specialTable && this.state.summaryList && !isEmpty(this.state.summaryList)) // special needs summary
                || (!this.getActiveCounterPartyConfig().specialTable // regular needs any
                    && (
                        (this.state.nominationList && !isEmpty(this.state.nominationList))
                        || (this.state.summaryList && !isEmpty(this.state.summaryList))
                        || (this.state.intradayProfiles && !isEmpty(this.state.intradayProfiles))
                    )));
    }

    prepareCumulativeSummaryTableForExport(tables) {
        const cummulatedData = {
            headers: [
                [], // table title
                [], // main headers of table
                [] // sub headers of table
            ],
            values: tables[0].items.values.map(r => [])
        };

        tables.forEach(stTable => {
            cummulatedData.headers[0].push(stTable.title);

            stTable.items.headers[0].forEach(headerCell => {
                cummulatedData.headers[0].push("");
                cummulatedData.headers[1].push(headerCell);
            });

            stTable.items.headers[1].forEach(headerCell => {
                cummulatedData.headers[2].push(headerCell);
            });

            cummulatedData.headers[1].push("");
            cummulatedData.headers[2].push("");

            stTable.items.values.forEach(valueRow => {
                cummulatedData.values[valueRow[0] - 1].push(...valueRow, "");
            });
        });

        return cummulatedData;
    }

    exportAll() {
        const fileName = `Phsical_Flows_${this.state.activeFilter.counterParty}_${this.state.activeFilter.requestedDate}`;

        const sheets = [];

        if (this.getActiveCounterPartyConfig().specialTable) {
            sheets.push({
                name: "Nominations",
                data: this.convertSpecialNominationJsonToTable(this.state.summaryList)
            });
        }
        else {
            if (this.state.nominationList && !isEmpty(this.state.nominationList)) {
                sheets.push({
                    name: "Nominations",
                    data: this.convertNominationJsonToTable(this.state.nominationList)
                });
            }

            const { shortTermTables, longTermTables } = this.prepareSummaryTables();

            const cummulatedSummaryData = { headers: [], values: [] };

            if (shortTermTables && !isEmpty(shortTermTables)) {
                const stCummulatedData = this.prepareCumulativeSummaryTableForExport(shortTermTables);

                if (isEmpty(cummulatedSummaryData.headers)) {
                    cummulatedSummaryData.headers = stCummulatedData.headers.map(h => []);
                    cummulatedSummaryData.values = stCummulatedData.values.map(h => []);
                }

                stCummulatedData.headers.forEach((headerRow, idx) => {
                    cummulatedSummaryData.headers[idx].push(...headerRow);
                });

                stCummulatedData.values.forEach((valueRow, idx) => {
                    cummulatedSummaryData.values[idx].push(...valueRow);
                });
            }

            if (longTermTables && !isEmpty(longTermTables)) {
                const ltCummulatedData = this.prepareCumulativeSummaryTableForExport(longTermTables);

                if (isEmpty(cummulatedSummaryData.headers)) {
                    cummulatedSummaryData.headers = ltCummulatedData.headers.map(h => []);
                    cummulatedSummaryData.values = ltCummulatedData.values.map(h => []);
                }

                ltCummulatedData.headers.forEach((headerRow, idx) => {
                    cummulatedSummaryData.headers[idx].push(...headerRow);
                });

                ltCummulatedData.values.forEach((valueRow, idx) => {
                    cummulatedSummaryData.values[idx].push(...valueRow);
                });
            }

            if (cummulatedSummaryData && !isEmpty(cummulatedSummaryData) && !isEmpty(cummulatedSummaryData.values)) {
                sheets.push({
                    name: "Summary",
                    data: cummulatedSummaryData
                });
            }

            if (this.state.intradayProfiles && !isEmpty(this.state.intradayProfiles)) {
                sheets.push({
                    name: "Intraday",
                    data: this.convertIntradayJsonToTable(this.state.intradayProfiles)
                });
            }
        }

        if (sheets && sheets.length > 0)
            createExcelWithMultipleSheets(fileName, sheets);
        else
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Export)');
    }

    onOpenIntradayEditMode() {
        this.setState({ editingIntraday: true });
    }

    onCloseIntradayEditMode() {
        this.setState({ editingIntraday: false });

        this.updateIfInconsistent();
    }

    onOpenNominationsEditMode() {
        this.setState({ editingNominations: true });
    }

    onCloseNominationsEditMode() {
        this.setState({ editingNominations: false });

        this.updateIfInconsistent();
    }

    updateIfInconsistent = async () => {
        if (!this.state.inconsistentDataCaught)
            return;

        const spinnerKey = this.spinner.showSpinner();

        try {
            await this.refreshNominationsAsync();

            this.setState({ inconsistentDataCaught: false });
        }
        finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    onSetZeroNominations() {
        let data = this.convertNominationJsonToTable(this.state.nominationList);

        data.values = data.values.map(row => [row[0], ...row.slice(1).map(c => 0)]);

        this.onSaveNominationsAsync(data, this.state.nominationList)

        this.onCancelDeleting();
    }

    onSetZeroIntraday() {
        let data = this.convertIntradayJsonToTable(this.state.intradayProfiles);

        data.values = data.values.map(row => [row[0], ...row.slice(1).map(c => 0)]);

        this.onSaveIntradayAsync(data, this.state.intradayProfiles)

        this.onCancelDeleting();
    }

    async onSetInitialIntradayAsync() {
        this.onCancelDeleting();

        const intradayIds = this.state.intradayProfiles.filter(n => n.intraday_id).map(n => n.intraday_id);

        if (!intradayIds || intradayIds.length === 0) {
            await this.refreshNominationsAsync();
            alertWarning(portalMessages.NO_SAVED);
            return;
        }

        let spinnerKey = this.spinner.showSpinner();

        try {
            let response;

            try {
                response = await deleteIntradayProfiles({
                    requested_date: this.state.activeFilter.requestedDate,
                    counter_party: this.state.activeFilter.counterParty,
                    intraday_ids: intradayIds
                });
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                await this.refreshNominationsAsync();
                alertSuccess(portalMessages.PHYSICAL_FLOWS.INTRA_DELETED_SUCCESS);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {});
                return false;
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Delete Intraday)');
            return;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    async onSetInitialNominationsAsync() {
        this.onCancelDeleting();

        const nomIds = this.state.nominationList.filter(n => n.nomination_id).map(n => n.nomination_id);

        if (!nomIds || nomIds.length === 0) {
            await this.refreshNominationsAsync();
            alertWarning(portalMessages.NO_SAVED);
            return;
        }

        let spinnerKey = this.spinner.showSpinner();

        try {

            let response;

            try {
                response = await deleteNominations({
                    requested_date: this.state.activeFilter.requestedDate,
                    counter_party: this.state.activeFilter.counterParty,
                    nomination_ids: nomIds
                });
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                await this.refreshNominationsAsync();
                alertSuccess(portalMessages.PHYSICAL_FLOWS.NOM_DELETED_SUCCESS);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {});
                return false;
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Delete Nominations)');
            return;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    convertSpecialNominationTableToJson(data, nominationList) {
        const nominationListDict = {};
        let error;

        nominationList.forEach(d => {
            if (!nominationListDict[d.platform])
                nominationListDict[d.platform] = {};

            nominationListDict[d.platform][d.direction] = d;
        });

        const resultList = [];

        const headersExceptHour = data.headers[0].slice(1);
        for (let idx = 0; idx < headersExceptHour.length; idx++) {
            let platform;
            const header = headersExceptHour[idx];

            if (isObject(header))
                platform = header.name;
            else
                platform = header;

            const direction = data.headers[1][idx + 1];

            const newDirectionJson = {
                direction,
                platform,
                nomination_id: nominationListDict[platform][direction].nomination_id,
                updated_data: []
            };

            for (let i = 0; i < data.values.length; i++) {
                const newDataRow = data.values[i];

                error = this.validateSpecialNominationRow(newDataRow);
                if (error)
                    break;

                newDirectionJson.updated_data.push({ hour: newDataRow[0] - 1, value: Number(newDataRow[idx + 1]) });
            }

            if (error)
                break;

            if (newDirectionJson.nomination_id)
                newDirectionJson.previous_data = nominationListDict[platform][direction].data;

            resultList.push(newDirectionJson);
        }

        return { resultList, error };
    }

    validateSpecialNominationRow(row) {
        return this.validateNominationRow(row);
    }

    convertNominationTableToJson(data, nominationList) {
        const nominationListDict = {};
        let error;

        nominationList.forEach(nom => {
            nominationListDict[nom.direction] = nom;
        });

        const resultList = [];

        const headersExceptHour = data.headers[0].slice(1);
        for (let idx = 0; idx < headersExceptHour.length; idx++) {
            const direction = headersExceptHour[idx];

            const newDirectionJson = {
                direction,
                nomination_id: nominationListDict[direction].nomination_id,
                updated_data: []
            };

            for (let i = 0; i < data.values.length; i++) {
                const newDataRow = data.values[i];

                error = this.validateNominationRow(newDataRow);
                if (error)
                    break;

                newDirectionJson.updated_data.push({ hour: newDataRow[0] - 1, value: Number(newDataRow[idx + 1]) });
            }

            if (error)
                break;

            if (nominationListDict[direction].nomination_id)
                newDirectionJson.previous_data = nominationListDict[direction].data;

            resultList.push(newDirectionJson);
        };

        return { resultList, error };
    }

    validateNominationRow(row) {
        for (let i = 1; i < row.length; i++) {
            let cell = row[i];

            if (isNaN(cell))
                return portalMessages.PHYSICAL_FLOWS.NOM_NAN;

            cell = Number(cell);

            if (cell < 0)
                return portalMessages.PHYSICAL_FLOWS.NOM_NO_NEGATIVE;

            let numParts = cell.toString().split('.');

            if (numParts.length > 1 && Number(numParts[1]) !== 0)
                return portalMessages.LT_PHYSICAL_FLOWS.CAP_INTEGER;
        }

        return "";
    }

    convertIntradayTableToJson(newData, intradayProfiles) {
        const resultList = [];

        const intradayProfileListDict = {};

        let error;

        intradayProfiles.forEach(intraday => {
            intradayProfileListDict[intraday.country] = intraday;
        });

        for (let i = 0; i < newData.headers[0].length - 1; i += 2) {
            const countryData = { updated_data: [] };

            const currentCountryColumnIndex = i + 1;

            countryData.country = newData.headers[0][currentCountryColumnIndex];

            for (let j = 0; j < newData.values.length; j++) {
                const row = newData.values[j];

                error = this.validateIntradayRow(row);
                if (error)
                    break;

                countryData.updated_data.push({
                    hour: row[0] - 1,
                    buy: Number(row[currentCountryColumnIndex]),
                    sell: Number(row[currentCountryColumnIndex + 1])
                });
            }

            if (error)
                break;

            countryData.intraday_id = intradayProfileListDict[countryData.country].intraday_id;
            countryData.exchange = intradayProfileListDict[countryData.country].exchange;

            if (countryData.intraday_id)
                countryData.previous_data = intradayProfileListDict[countryData.country].data;

            resultList.push(countryData);
        }

        return { resultList, error };
    }

    validateIntradayRow(row) {
        for (let i = 1; i < row.length; i++) {
            let cell = row[i];

            if (isNaN(cell))
                return portalMessages.PHYSICAL_FLOWS.INTRA_NAN;

            cell = Number(cell);

            if (cell < 0)
                return portalMessages.PHYSICAL_FLOWS.INTRA_NO_NEGATIVE;

            if (i % 2 === 0 && cell > 0 && Number(row[i - 1]) > 0)
                return portalMessages.PHYSICAL_FLOWS.INTRA_BUY_SELL_ONE;


            let numParts = cell.toString().split('.');

            if (numParts.length > 1 && numParts[1].length > this.capacityScales)
                return portalMessages.PHYSICAL_FLOWS.CAPACITY_FRACTIONS;
        }

        return "";
    }

    onSaveIntradayAsync = async (newData, intradayProfiles) => {
        let spinnerKey = this.spinner.showSpinner();

        try {

            const { resultList, error } = this.convertIntradayTableToJson(newData, intradayProfiles);

            if (error) {
                this.showErrorMessage(error);
                return false;
            }

            let body = {
                requested_date: this.state.activeFilter.requestedDate,
                counter_party: this.state.activeFilter.counterParty,
                intraday_profiles: resultList
            };

            let response;

            try {
                response = await saveIntradayProfiles(body);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                await this.refreshNominationsAsync();
                alertSuccess(portalMessages.PHYSICAL_FLOWS.INTRA_UPDATED);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {}, "intradayEdit");
                return false;
            }
        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (IntradayEdit)');
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    onSaveSpecialNominationsAsync(newData, nominationList) {
        const { resultList, error } = this.convertSpecialNominationTableToJson(newData, nominationList);

        if (error) {
            this.showErrorMessage(error);
            return false;
        }

        return this.onSaveNominationsAsync(newData, null, resultList);
    }

    onSaveNominationsAsync = async (newData, nominationList, convertedNominationList) => {
        let spinnerKey = this.spinner.showSpinner();

        try {
            if (!convertedNominationList) {
                const { resultList, error } = this.convertNominationTableToJson(newData, nominationList);

                if (error) {
                    this.showErrorMessage(error);
                    return false;
                }

                convertedNominationList = resultList;
            }

            let body = {
                requested_date: this.state.activeFilter.requestedDate,
                counter_party: this.state.activeFilter.counterParty,
                mail_sent: this.state.mailSent,
                nomination_list: convertedNominationList
            };

            let response;

            try {
                response = await saveNominations(body);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                await this.refreshNominationsAsync();
                alertSuccess(portalMessages.PHYSICAL_FLOWS.NOMINATIONS_UPDATED);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {}, "editValues");
                return false;
            }
        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Nominations)');
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    renderRegularNominationsTable(data) {
        if (!data || isEmpty(data))
            return null;

        return (
            <VTable
                title=" "
                key="nominationsTable"
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                inputType='number'
                showTotal
                staticHeaderButtons
                exportFileName={`Nominations_${this.state.activeFilter.counterParty}_${this.state.activeFilter.requestedDate}`}
                readonlyColumns={[this.columns.hour.title]}
                items={this.convertNominationJsonToTable(data)}
                onReset={() => this.setState({ showResetNominationsModal: true })}
                headerButtons={{
                    showImportExcelButton: this.editEnabled(),
                    showExportExcelButton: true,
                    showResetTableButton: this.editEnabled(),
                    showEditButton: this.editEnabled()
                }}
                onOpenEditMode={() => this.onOpenNominationsEditMode()}
                onCloseEditMode={() => this.onCloseNominationsEditMode()}
                onSaveChangesAsync={(newData) => this.onSaveNominationsAsync(newData, data)}
                onError={(message) => this.showErrorMessage(message)}
            />
        );
    }

    convertSpecialNominationJsonToTable(data) {
        const dataDict = {};

        data.forEach(d => {
            if (!dataDict[d.platform])
                dataDict[d.platform] = {};

            dataDict[d.platform][d.direction] = d;
        });

        const headers = [[this.columns.hour.title], [this.columns.cet.title], [""]];
        const values = data[0].data.map(d => [d.hour + 1]);
       
        this.summaryPlatforms[this.state.activeFilter.counterParty.toLowerCase()].forEach(platform => {
            headers[0].push(platform);
            headers[0].push({ name: platform, html: "" });

            Object.keys(this.summaryDirections[this.state.activeFilter.counterParty.toLowerCase()]).forEach(directionKey => {
                const direction = this.summaryDirections[this.state.activeFilter.counterParty.toLowerCase()][directionKey].title;

                headers[1].push(direction);
                headers[2].push(this.columns.mwh.title);

                dataDict[platform][direction].data.forEach(rowData => {
                    values[rowData.hour].push(rowData.value);
                });
            });
        });

        return { headers, values };
    }

    renderSpecialNominationsTable(data) {
        if (!data || isEmpty(data))
            return null;

        const items = this.convertSpecialNominationJsonToTable(data);

        return (
            <VTable
                key={"specialNominationsTable"}
                title=" "
                items={items}
                inputType='number'
                showTotal
                readonlyColumns={[this.columns.hour.title]}
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                onGetHeaderClass={(colNum, colCount) => this.onGetHeaderClass(colNum, colCount)}
                exportFileName={`Nominations_${this.state.activeFilter.counterParty}_${this.state.activeFilter.requestedDate}`}
                onReset={() => this.setState({ showResetNominationsModal: true })}
                headerButtons={{
                    showExportExcelButton: true,
                    showImportExcelButton: this.editEnabled(),
                    showResetTableButton: this.editEnabled(),
                    showEditButton: this.editEnabled()
                }}
                onOpenEditMode={() => this.onOpenNominationsEditMode()}
                onCloseEditMode={() => this.onCloseNominationsEditMode()}
                onSaveChangesAsync={(newData) => this.onSaveSpecialNominationsAsync(newData, data)}
                onError={(message) => this.showErrorMessage(message)}
            />
        );
    }

    renderNominatinsTab() {
        return (
            <VTab key='Nominations'
                eventKey='Nominations'
                title='Nominations'>
                {this.state.nominationList && !isEmpty(this.state.nominationList) &&
                    <div className='v-infinite-width v-table-list'>
                        {
                            this.getActiveCounterPartyConfig().specialTable ?
                                this.renderSpecialNominationsTable(this.state.summaryList)
                                :
                                this.renderRegularNominationsTable(this.state.nominationList)
                        }
                    </div>
                }
            </VTab>
        );
    }

    convertNominationJsonToTable(data) {
        const headers = [
            [this.columns.hour.title]
        ];

        const values = [];

        data[0].data.forEach(d => {
            values.push([d.hour + 1]);
        });

        const sortedData = sortNominationColumns(data);

        sortedData.forEach(d => {
            headers[0].push(d.direction);

            d.data.forEach(hourData => {
                values[hourData.hour].push(hourData.value);
            });
        });

        return { headers, values };
    }

    prepareSTSummaryTableItems(data) {
        const tableItems = {
            headers: [
                [this.columns.hour.title],
                [this.columns.cet.title]
            ],
            values: null
        }

        this.summaryPlatforms[this.state.activeFilter.counterParty.toLowerCase()].forEach(platform => {
            tableItems.headers[0].push(platform);
            tableItems.headers[1].push(data[platform].capacity_id ?? "-");

            if (!tableItems.values)
                tableItems.values = data[platform].data.map(d => [d.hour + 1]);

            data[platform].data.forEach((d, idx) => {
                tableItems.values[idx].push(d.value);
            });
        });

        tableItems.headers[0].push(this.columns.total.title);
        tableItems.headers[1].push(this.columns.mwh.title);

        tableItems.values.forEach(row => {
            row.push(row.slice(1).reduce((a, b) => a + b, 0))
        });

        return tableItems;
    }

    prepareLTSummaryTableItems(data) {
        const tableItems = {
            headers: [
                [this.columns.hour.title],
                [this.columns.cet.title]
            ],
            values: null
        }

        data.forEach(d => {
            tableItems.headers[0].push(d.direction);
            tableItems.headers[1].push(this.columns.mwh.title);

            if (!tableItems.values)
                tableItems.values = d.data.map(d => [d.hour + 1]);

            d.data.forEach((d, idx) => {
                tableItems.values[idx].push(d.value);
            });
        });

        tableItems.headers[0].push(this.columns.total.title);
        tableItems.headers[1].push(this.columns.mwh.title);

        tableItems.values.forEach(row => {
            row.push(row.slice(1).reduce((a, b) => a + b, 0))
        });

        return tableItems;
    }

    createSummaryTable(title, items) {
        return (
            <VTable
                key={title}
                title={title}
                items={items}
                readonlyColumns={[this.columns.hour.title]}
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                exportFileName={`Phsical_Flows_Summary_${title.replace(" ", "").replace(" ", "_")}_${this.state.activeFilter.counterParty}_${this.state.activeFilter.requestedDate}`}
                headerButtons={{
                    showExportExcelButton: true
                }}
            />
        );
    }

    prepareSummaryTables() {
        if (!this.state.summaryList || isEmpty(this.state.summaryList))
            return {};

        const stSummaryTables = {};
        const ltSummaryTables = {};
        const counterParty = this.state.activeFilter.counterParty.toLowerCase()

        Object.keys(this.summaryDirections[counterParty]).forEach(s => {
            ltSummaryTables[this.summaryDirections[counterParty][s].title] = [];
        });

        this.state.summaryList.forEach(s => {
            if (s.type === this.columns.st.key) {
                if (!stSummaryTables[s.direction])
                    stSummaryTables[s.direction] = {};

                stSummaryTables[s.direction][s.platform] = s;
            }
            else if (s.type === this.columns.lt.key) {
                Object.keys(this.summaryDirections[counterParty]).forEach(dir => {
                    if (this.summaryDirections[counterParty][dir].search(s.direction))
                        ltSummaryTables[this.summaryDirections[counterParty][dir].title].push(s);
                });
            }
        });

        const shortTermTables = [];
        const longTermTables = [];

        Object.keys(this.summaryDirections[counterParty]).forEach(s => {
            const summaryDirection = this.summaryDirections[counterParty][s];

            if (stSummaryTables[summaryDirection.title]) {
                const tableItems = this.prepareSTSummaryTableItems(stSummaryTables[summaryDirection.title]);
                shortTermTables.push({
                    title: `${this.columns.st.title} ${summaryDirection.title}`,
                    items: tableItems
                });
            }

            if (ltSummaryTables[summaryDirection.title] && !isEmpty(ltSummaryTables[summaryDirection.title])) {
                const tableItems = this.prepareLTSummaryTableItems(ltSummaryTables[summaryDirection.title]);
                longTermTables.push({
                    title: `${this.columns.lt.title} ${summaryDirection.title}`,
                    items: tableItems
                });
            }
        });

        return { shortTermTables, longTermTables };
    }

    renderSummaryTab() {
        const { shortTermTables, longTermTables } = this.prepareSummaryTables();

        return (
            <React.Fragment>
                <div className='v-infinite-width v-table-list'>
                    {shortTermTables && !isEmpty(shortTermTables) &&
                        shortTermTables.map(table => {
                            return this.createSummaryTable(table.title, table.items);
                        })
                    }
                    {
                        longTermTables && !isEmpty(longTermTables) && longTermTables.map(table => {
                            return this.createSummaryTable(table.title, table.items);
                        })
                    }
                </div>
            </React.Fragment>
        );
    }

    convertIntradayJsonToTable(intradayProfiles) {
        const items = {
            headers: [
                [this.columns.hour.title],
                [this.columns.cet.title],
                [" "],
            ],
            values: intradayProfiles[0].data.map(d => [d.hour + 1])
        }

        intradayProfiles.forEach(intraday => {
            items.headers[0].push(intraday.country);
            items.headers[0].push({ name: intraday.country, html: "" });

            items.headers[1].push(this.columns.buy.title);
            items.headers[1].push(this.columns.sell.title);

            items.headers[2].push(this.columns.mwh.title);
            items.headers[2].push(this.columns.mwh.title);

            intraday.data.forEach(countryRow => {
                items.values[countryRow.hour].push(countryRow.buy);
                items.values[countryRow.hour].push(countryRow.sell);
            });
        });

        return items;
    }

    onGetHeaderClass(colId, colCount) {
        const colorRepeatColCount = 2;

        if ((colId - 1) % colCount > 0)
            return this.headerClasses[(Math.floor((((colId - 1) % colCount) - 1) / colorRepeatColCount)) % colorRepeatColCount];
        return "";
    }

    renderIntradayTab() {
        if (!this.state.intradayProfiles || isEmpty(this.state.intradayProfiles))
            return null;

        const items = this.convertIntradayJsonToTable(this.state.intradayProfiles);
        const title = 'Intraday Profiles'

        return (
            <div className='v-infinite-width v-table-list'>
                <VTable
                    key={title}
                    title={title}
                    items={items}
                    inputType='number'
                    showTotal
                    staticHeaderButtons
                    readonlyColumns={[this.columns.hour.title]}
                    scales={{ '*': this.capacityScales }}
                    customColumnClasses={{ 0: "v-column-narrow-bold" }}
                    onGetHeaderClass={(colNum, colCount) => this.onGetHeaderClass(colNum, colCount)}
                    exportFileName={`${title.replaceAll(" ", "")}_${this.state.activeFilter.counterParty}_${this.state.activeFilter.requestedDate}`}
                    onReset={() => this.setState({ showResetIntradayModal: true })}
                    onOpenEditMode={() => this.onOpenIntradayEditMode()}
                    onCloseEditMode={() => this.onCloseIntradayEditMode()}
                    onSaveChangesAsync={(newData) => this.onSaveIntradayAsync(newData, this.state.intradayProfiles)}
                    onError={(message) => this.showErrorMessage(message)}
                    headerButtons={{
                        showImportExcelButton: this.editEnabled(),
                        showExportExcelButton: true,
                        showResetTableButton: this.editEnabled(),
                        showEditButton: this.editEnabled()
                    }}
                />
            </div>
        );
    }

    onCancelDeleting() {
        this.setState({ showResetNominationsModal: false, showResetIntradayModal: false });
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="ST Physical Flows">
                    <VFilterContainer
                        showActiveFilter
                        activeFilter={this.state.activeFilterToDisplay}
                    >
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                                </div>
                            <div>
                                <VDatePicker
                                    disabled={!this.showButtonEnabled()}
                                    selectedDate={this.state.selectedDate}
                                    onSelectedDateChange={(selectedDate) => this.setState({ selectedDate })}
                                    maxDate={this.getEditableDate()}
                                />
                            </div>
                        </div>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Counter Party
                                </div>
                            <div>
                                <VDropdown
                                    disabled={!this.showButtonEnabled()}
                                    width="large"
                                    options={this.state.counterPartyOptions}
                                    value={this.state.selectedCounterPartyOption}
                                    onSelectedOptionChange={(selectedCounterPartyOption) => {
                                        this.setState({
                                            selectedCounterParty: selectedCounterPartyOption.value,
                                            selectedCounterPartyOption: selectedCounterPartyOption
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                disabled={!this.showButtonEnabled()}
                                className="btn v-cancel-button v-filter-button"
                                onClick={() => this.onClearButtonClick()}>
                                <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                            </button>
                            <button
                                disabled={!this.showButtonEnabled()}
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={() => this.onShowButtonClick()}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                        </div>
                    </VFilterContainer>
                    <VMainContainer>
                        <div className='col-12' style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                            <button className="btn v-button v-tab-button"
                                disabled={!this.exportAllEnabled()}
                                onClick={() => this.exportAll()}>
                                <i aria-hidden="true" className="fa fa-cloud-download fa-fw" />
                                    Export All
                                </button>
                            <button className="btn v-button v-tab-button"
                                disabled={!this.sendMailEnabled()}
                                onClick={() => this.sendTestMail()}>
                                <i aria-hidden="true" className={`fa ${this.mailAlreadySent() ? "fa-envelope-o" : "fa-envelope"} fa-fw`} />
                                    Send Mail To Me
                                </button>
                            <button className="btn v-button v-tab-button"
                                disabled={!this.sendMailEnabled()}
                                onClick={() => this.sendCounterPartyMail()}>
                                <i aria-hidden="true" className={`fa ${this.mailAlreadySent() ? "fa-envelope-o" : "fa-envelope"} fa-fw`} />
                                    Send Mail To Counter Party
                                </button>
                        </div>
                        <VTabs disableAllTabs={!this.showButtonEnabled()}>
                            {this.renderNominatinsTab()}
                            {this.getActiveCounterPartyConfig().showSummary &&
                                <VTab key='Summary'
                                    eventKey='Summary'
                                    title='Summary'>
                                    {this.renderSummaryTab()}
                                </VTab>
                            }
                            {this.getActiveCounterPartyConfig().showIntraday &&
                                <VTab key='Intraday'
                                    eventKey='Intraday'
                                    title='Intraday'>
                                    {this.renderIntradayTab()}
                                </VTab>
                            }
                        </VTabs>
                    </VMainContainer>
                </VContentContainer>
                <NeedRefreshModal
                    show={this.state.showNeedsRefresModal}
                    message={portalMessages.COULD_NOT_GET_C_PARTIES}
                />
                <ResetTableModal
                    show={this.state.showResetNominationsModal}
                    title='Reset Nominations'
                    message={portalMessages.PHYSICAL_FLOWS.HOW_TO_DELETE_NOM}
                    counterParty={this.state.activeFilter.counterParty}
                    onCancel={() => { this.onCancelDeleting(); }}
                    onSetZero={() => { this.onSetZeroNominations(); }}
                    onSetInitial={() => { this.onSetInitialNominationsAsync(); }}
                />
                <ResetTableModal
                    show={this.state.showResetIntradayModal}
                    title='Reset Intraday Profiles'
                    message={portalMessages.PHYSICAL_FLOWS.HOW_TO_DELETE_INTRA}
                    counterParty={this.state.activeFilter.counterParty}
                    onCancel={() => { this.onCancelDeleting(); }}
                    onSetZero={() => { this.onSetZeroIntraday(); }}
                    onSetInitial={() => { this.onSetInitialIntradayAsync(); }}
                />
                <ConfirmationModal
                    show={this.state.showIrregularMailModal}
                    dialogClassName="v-modal-60w"
                    message={this.state.irregularMailBody}
                    cancelText='Cancel'
                    onCancel={() => this.onCloseIrregularMailConfirmationModal()}
                    onHide={() => this.onCloseIrregularMailConfirmationModal()}
                    confirmText='Send'
                    onConfirm={async () => await this.onConfirmIrregularMail()}
                />
            </React.Fragment>
        );
    }
}

export default PhysicalFlows;