import './VCheckboxGroup.css';
import React from 'react';
import { generateUuid } from '../../helpers/generalHelper';

/**
 * 
 * @param {string} title Main title of checkbox group, rendered if not empty
 * @param {dict} inputs Options dictionary with values as key: { title: "..", checked: true/false }
 * @param {function} onCheckChanged Calls (changedKey, updatedInputs) => onCheckChanged
 */
const VCheckboxGroup = ({ title, items, onCheckChanged, headerOnTop = false }) => {

    let mainChecked = true;

    const selectedCount = Object.keys(items).filter(i => items[i].checked).length;

    if (selectedCount === Object.keys(items).length)
        mainChecked = true;
    else
        mainChecked = false;

    const mainCheckId = generateUuid();

    const mainCheckChanged = (mainChecked) => {
        if (mainChecked)
            Object.keys(items).forEach(i => { items[i].checked = false });
        else
            Object.keys(items).forEach(i => { items[i].checked = true });

        onCheckChanged(null, items);
    }

    const childCheckChanged = () => {
        if (selectedCount === Object.keys(items).length)
            mainChecked = true;
        else
            mainChecked = false;
    }

    return (
        <div className="v-checkbox-group v-font v-font-size">
            {title &&
                <React.Fragment>
                    <div className="v-checkbox-title">
                        <label>
                            <input type="checkbox"
                                id={mainCheckId}
                                checked={mainChecked}
                                style={{ margin: "0 3px 0 15px" }}
                                onChange={() => {
                                    mainCheckChanged(mainChecked);
                                }} />
                            {title}
                        </label>
                    </div>
                    {headerOnTop && <br />}
                </React.Fragment>
            }
            <div className='v-checkbox-group-inputs'>
                {
                    Object.keys(items).map(checkKey => {
                        return (
                            <label key={checkKey}>
                                <input type="checkbox"
                                    checked={items[checkKey].checked}
                                    onChange={() => {
                                        items[checkKey].checked = !items[checkKey].checked;
                                        childCheckChanged()
                                        onCheckChanged(checkKey, items);
                                    }} />
                                {items[checkKey].title}
                            </label>
                        );
                    })
                }
            </div>
        </div >
    )
}

export default VCheckboxGroup;