import { getUser } from "../apis/vitusApi";
import store from "../store";

class Authenticator {
    auth = {};

    constructor() {
        this.auth = store.getState().auth;

        if (!this.auth || !this.auth.isLoggedIn) {
            getUser();
        }
    }

    initialize(){
        return;
    }
}

export default new Authenticator();