import React from 'react';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer';
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import RightSideContainer from '../../components/TTPageContainers/RightSideContainer';
import TConfirmationModal from '../../components/TConfirmationModal/TConfirmationModal';
import { TfilterTypes } from '../../components/TTable/TTable';
import TTable from '../../components/TTable/TTable';
import TTabs from '../../components/TTabs/TTabs';
import TabPanel from '../../components/TTabs/TabPanel';
import TDialogBox from '../../components/TDialogBox';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import { getCompanies, saveCompany, deleteCompany, importCompanies } from '../../api/services';
import { handleApiError, alertError, alertSuccess } from '../../utils/errorHandler';
import { messages } from '../../utils/messages';
import history from '../../../history';
import CompanyDetail from './CompanyDetail';
import TFileUploadIcon from '../../components/TFileUploadIcon/TFileUploadIcon';
import { loadExcel } from '../../../helpers/excelHelper';
import { COMPANY_TYPES } from '../../utils/constants';


const EmailFormatter = ({ row }) => {
    if (row) {
        return (<div
            style={{ fontStyle: "italic", fontSize: "10px" }}
        >
            {
                row.emailList.map(item => {
                    return <Chip key={`${row.id}_${item}_chip`} size="small" label={item}></Chip>
                })}
        </div>);
    }
    return null;
};

const IbanFormatter = ({ row }) => {
    if (row) {
        return (<div
            style={{ fontStyle: "italic", fontSize: "10px" }}
        >
            {
                row.ibanList.map(item => {
                    return <Chip key={`${row.id}_${item}_chip`} size="small" label={item}></Chip>
                })}
        </div>);
    }
    return null;
};

class Company extends React.Component {
    title = "Company"

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        openAddDialogBox: false,
        showDeleteConfirmationModal: false,
        selectedRow: null,
        defaultSelectedTabId: null,
        tabInfo: [],
        tableRows: [],
        tableColumns: [
            { key: 'name', name: 'Name', sortable: true, resizable: true, dragable: true, TfilterType: TfilterTypes.input },
            { key: "companyTypeName", name: 'Company Type', sortable: true, resizable: true, TfilterType: TfilterTypes.select, width: 150 },
            { key: 'eicCode', name: 'EIC Code', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'taxOffice', name: 'Tax Office', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'taxNumber', name: 'Tax Number', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'emailList', name: 'Company Mail', resizable: true, formatter: EmailFormatter },
            { key: 'companyBank', name: 'Company Bank', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'ibanList', name: 'Company IBAN', resizable: true, formatter: IbanFormatter },
            { key: 'address', name: 'Address', sortable: false, resizable: true, TfilterType: TfilterTypes.input }
        ]
    }

    componentDidMount() {
        this.onShowButtonClick({});
    }

    setTabs = (newTabs, newTabId) => {
        this.setState({ tabInfo: newTabs, defaultSelectedTabId: newTabId })
    }

    onAddButtonClick() {
        this.setState({ openAddDialogBox: true })
    }

    onDeleteRowClick = (row) => {
        this.setState({ showDeleteConfirmationModal: true, selectedRow: row });
    }

    onEditRowClick = (row) => {
        this.addNewTab({ ...row, readOnly: false })
    }

    onCancelButtonClick(company) {
        this.closeAddDialogBox()
        if (company) {
            this.addNewTab({ ...company, readOnly: true })
        }
    }

    closeAddDialogBox() {
        this.setState({ openAddDialogBox: false })
    }

    hideDeleteConfirmationModal = () => {
        this.setState({ showDeleteConfirmationModal: false });
    }

    addNewTab(row) {
        this.setState({ defaultSelectedTabId: row.id })

        let ids = this.state.tabInfo.map(t => t.id)

        row.key = `${row.id}_${row.readOnly}`

        if (!ids.includes(row.id)) {
            this.setState({ tabInfo: [...this.state.tabInfo, row] })
        } else {

            let newTabList = this.state.tabInfo.map(tab => {
                if (tab.id === row.id) {
                    return row
                } else {
                    return tab
                }
            })

            this.setState({ tabInfo: newTabList })
        }
    }

    onShowButtonClick = (filter) => {
        this.spinner.showSpinner('getCompanies');
        getCompanies(filter)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        tableRows: this.getTableRows(response.data.success.company_result)
                    });
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getCompanies');
            });
    }

    getTableRows(data) {
        let rowList = []
        for (let i = 0; i < data.length; i++) {
            const currentRow = data[i]

            rowList.push({
                id: currentRow.company_id,
                name: currentRow.company_name,
                companyType: currentRow.company_type,
                companyTypeName: COMPANY_TYPES[currentRow.company_type]?.label,
                eicCode: currentRow.eic_code,
                address: currentRow.address,
                taxOffice: currentRow.tax_office,
                taxNumber: currentRow.tax_number,
                companyBank: currentRow.company_bank,
                emailList: currentRow.email_list,
                ibanList: currentRow.iban_list
            })
        }

        return rowList;
    }

    saveButtonClick(requestBody) {
        this.closeAddDialogBox()
        this.spinner.showSpinner('saveCompany');

        saveCompany(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.COMPANY.SUCCESSFULL_COMPANY_SAVE);
                    this.onShowButtonClick({});

                    let row = {
                        id: response.data.success.company_id,
                        name: requestBody.company_name,
                        companyType: requestBody.company_type,
                        eicCode: requestBody.eic_code,
                        address: requestBody.address,
                        taxOffice: requestBody.tax_office,
                        taxNumber: requestBody.tax_number,
                        emailList: requestBody.company_mail_list,
                        companyBank: requestBody.company_bank,
                        ibanList: requestBody.company_iban_list
                    }

                    this.addNewTab({ ...row, readOnly: true })
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('saveCompany');
            });
    }

    deleteButtonClick = () => {
        this.spinner.showSpinner('deleteCompany');
        let requestBody = {
            company_id: this.state.selectedRow.id
        }
        this.hideDeleteConfirmationModal();
        deleteCompany(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.COMPANY.SUCCESSFULL_COMPANY_DELETE)
                    this.onShowButtonClick({});
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('deleteCompany');
            });
    }

    onCompanyExcelImport(e) {
        loadExcel(e.target.files[0], (data) => this.importCompanies(data));
    }

    importCompanies = (requestBody) => {
        this.spinner.showSpinner('importCompanies');
        importCompanies(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.COMPANY.SUCCESSFULL_COMPANY_SAVE);
                    this.onShowButtonClick({});
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('importCompanies');
            });
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        <AccordionActions>
                            <TFileUploadIcon
                                accept={['.xlsx', '.xls', '.xlsm']}
                                labeltext={`Import File`}
                                key='importTableIcon'
                                onFileChanged={(e) => this.onCompanyExcelImport(e)}
                            />
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onAddButtonClick()}
                            >
                                Add
                            </button>
                        </AccordionActions>
                        <TDialogBox
                            openAddDialogBox={this.state.openAddDialogBox}
                            handleClose={() => this.closeAddDialogBox()}
                            title={"Add Company"}
                        >
                            <TabPanel >
                                <CompanyDetail
                                    item={null}
                                    onSaveButtonClick={(body) => this.saveButtonClick(body)}
                                    onCancelButtonClick={(company) => this.onCancelButtonClick(company)}
                                    readOnly={false}
                                >
                                </CompanyDetail>
                            </TabPanel>
                        </TDialogBox>
                        <Divider />
                        <TTable
                            showFilterButtons

                            showExportExcelButton
                            exportExcelFileName={this.title}

                            rows={this.state.tableRows}
                            columns={this.state.tableColumns}

                            showDeleteRow
                            showEditRow

                            onDeleteRowClick={this.onDeleteRowClick}
                            onEditRowClick={this.onEditRowClick}
                        ></TTable>
                    </div>

                }
            >
            </LeftSideContainer>
        )
    }

    getTableDetailComponent() {
        return (
            <RightSideContainer>
                <TTabs
                    key={this.state.defaultSelectedTabId}
                    defaultSelectedTabId={this.state.defaultSelectedTabId}
                    setTabs={this.setTabs}
                >
                    {
                        this.state.tabInfo.map((t) => {
                            return (
                                <TabPanel key={`${t.key}_tabpanel`} title={t.name} id={t.id}>
                                    <CompanyDetail
                                        key={`${t.key}_CompanyDetail`}
                                        item={t}
                                        onSaveButtonClick={(body) => this.saveButtonClick(body)}
                                        onEditButtonClick={(row) => this.addNewTab({ ...row, readOnly: false })}
                                        onCancelButtonClick={(company) => this.onCancelButtonClick(company)}
                                        readOnly={t.readOnly}
                                    >
                                    </CompanyDetail>
                                </TabPanel>
                            )
                        })
                    }
                </TTabs>
            </RightSideContainer>
        )
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                    rightSideComponent={this.getTableDetailComponent()}
                >
                </TContentDetailContainer>
                {
                    this.state.showDeleteConfirmationModal &&
                    <TConfirmationModal
                        show={this.state.showDeleteConfirmationModal}
                        message={messages.COMPANY.CONFIRM_DELETE.replace("[COMPANY]", this.state.selectedRow.name)}
                        cancelText='Cancel'
                        onHide={this.hideDeleteConfirmationModal}
                        onCancel={this.hideDeleteConfirmationModal}
                        confirmText='Delete'
                        onConfirm={this.deleteButtonClick}
                    />
                }
            </TContentContainer>
        );
    }
}

export default Company;