import React from 'react';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer';
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import RightSideContainer from '../../components/TTPageContainers/RightSideContainer';
import TConfirmationModal from '../../components/TConfirmationModal/TConfirmationModal';
import { TfilterTypes } from '../../components/TTable/TTable';
import TTable from '../../components/TTable/TTable';
import TTabs from '../../components/TTabs/TTabs';
import TabPanel from '../../components/TTabs/TabPanel';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import AccordionActions from '@material-ui/core/AccordionActions'
import Divider from '@material-ui/core/Divider';
import { getContracts, saveContract, deleteContract, getMarketNames, getBookDetails, getCompaniesDetail, getPeriodsDetail, getCostsDetail, getContract, importContracts } from '../../api/services';
import { handleApiError, alertError, alertSuccess } from '../../utils/errorHandler';
import { messages } from '../../utils/messages';
import history from '../../../history';
import ContractDetail from './ContractDetail';
import TBaseModal from '../../components/TBaseModal/TBaseModal';
import { DELIVERY_TYPES, POSITION, TRADING_VENUE, CONTRACT_TYPES, PRODUCT, TRADER, CONTRACT_DATE_TYPE, PAYMENT_PERIODS } from '../../utils/constants';
import { extractDate } from '../../utils/common';
import TFileUploadIcon from '../../components/TFileUploadIcon/TFileUploadIcon';
import { loadExcel } from '../../../helpers/excelHelper';


import './Contract.css';


const PositionFormatter = ({ row }) => {
    if (row) {
        return (<span className={`${row.position === POSITION.sell ? "t-table-sell" : "t-table-buy"}`}>{row.position}</span>);
    }
    return null;
};

const StartDateFormatter = ({ row }) => {
    if (row) {
        return (<span>{extractDate(row.startDate)}</span>);
    }
    return null;
}

const EndDateFormatter = ({ row }) => {
    if (row) {
        return (<span>{extractDate(row.endDate)}</span>);
    }
    return null;
}

const ContractDateFormatter = ({ row }) => {
    if (row) {
        return (<span>{extractDate(row.contractDate)}</span>);
    }
    return null;
}

const PaymentDateFormatter = ({ row }) => {
    if (row.paymentDate) {
        return (<span>{extractDate(row.paymentDate)}</span>);
    }
    return null;
}


class Contract extends React.Component {
    title = "Contract"

    spinner = new SpinnerManager(history.location.pathname);

    columnsExcluded = ['companyName', 'tradingVenueName', 'contractTypeName', 'deliveryTypeName', 'productName', 'bookName', 'marketName', 
                       'counterPartyName', 'traderName', 'periodName', 'contractDateTypeName', 'year', 'term', 'currency', 'paymentPeriodName', 
                       'paymentTimePeriod', 'contractMonth', 'costType', 'broker']
    
    state = {
        openAddDialogBox: false,
        showDeleteConfirmationModal: false,
        selectedRow: null,
        defaultSelectedTabId: null,
        tabInfo: [],
        tableRows: [],
        tableColumns: [
            { 
                key: 'tradeId', 
                name: 'TradeId',
                sortable: true,
                resizable: true, 
                width: 120,
                frozen: true, 
                TfilterType: TfilterTypes.input,
                summaryFormatter() {
                    return <strong>Total</strong>;
                }
            },
            { 
                key: 'name', 
                name: 'Name', 
                sortable: true, 
                resizable: true, 
                width: 150,
                frozen: true, 
                TfilterType: TfilterTypes.input,
                summaryFormatter({ row }) {
                    return <strong>{row.totalCount} records</strong>;
                } 
            },
            { 
                key: 'companyName', 
                name: 'Company', 
                sortable: true, 
                resizable: true, 
                width: 150,
                frozen: true, 
                TfilterType: TfilterTypes.multiSelect
            },
            { key: "tradingVenueName", name: 'Trading Venue', sortable: true, resizable: true, frozen: true, TfilterType: TfilterTypes.multiSelect, width: 150 },
            { key: 'contractTypeName', name: 'Contract Type', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'deliveryTypeName', name: 'Delivery Type', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'productName', name: 'Product', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'bookName', name: 'Book', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'marketName', name: 'Market', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'counterPartyName', name: 'Counter Party', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'contractDate', name: 'Contract Date', sortable: true, resizable: true, formatter: ContractDateFormatter },
            { key: 'traderName', name: 'Trader', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'periodName', name: 'Period', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'contractDateTypeName', name: 'Contract Date Type', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'year', name: 'Year', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'position', name: 'Position', sortable: true, resizable: true, formatter: PositionFormatter },
            { key: 'startDate', name: 'Start Date', sortable: true, resizable: true, formatter: StartDateFormatter },
            { key: 'endDate', name: 'End Date', sortable: true, resizable: true, formatter: EndDateFormatter },
            { key: 'term', name: 'Term', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'price', name: 'Price', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'currency', name: 'Currency', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'amount', name: 'Amount', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'paymentPeriodName', name: 'Payment Period', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'paymentTimePeriod', name: 'Payment Time Period', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
            { key: 'paymentDate', name: 'Payment Date', sortable: true, resizable: true, formatter: PaymentDateFormatter },
            { key: 'contractMonth', name: 'Contract Month', sortable: true,resizable: true, TfilterType: TfilterTypes.multiSelectNotOrdered },
            { key: 'cost', name: 'Unit Cost', sortable: true, resizeable: true},
            { key: 'costType', name: 'Cost Type', sortable: true, resizeable: true, TfilterType: TfilterTypes.multiSelect},
            { key: 'broker', name: 'Broker', sortable: true, resizeable: true, TfilterType: TfilterTypes.multiSelect},
            { key: 'totalCost', name: 'Total Cost', sortable: true, resizeable: true}
        ],
        marketNames: [],
        bookNames: [],
        counterPartyNames: [],
        periodNames: [],
        costNames: []
    }

    componentDidMount() {
        this.onShowButtonClick({});
        this.getCompaniesDetail();
        this.getPeriodsDetail();
        this.getMarketDetails()
        this.getBookDetails()
        this.getCostsDetail();
    }

    setTabs = (newTabs, newTabId) => {
        this.setState({ tabInfo: newTabs, defaultSelectedTabId: newTabId })
    }

    onAddButtonClick() {
        this.setState({ openAddDialogBox: true })
    }

    onDeleteRowClick = (row) => {
        this.setState({ showDeleteConfirmationModal: true, selectedRow: row });
    }

    onEditRowClick = (row, readOnly) => {
        this.spinner.showSpinner('getContract');

        getContract({ contract_id: row.id })
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success.contract_details

                    let costList = successData.cost_list.map(x => ({
                        id: x.id,
                        value: x.id,
                        name: x.cost_type,
                        label: `${x.cost_type} ${x.country_code} ${x.broker}`,
                        currency: x.currency,
                        broker: x.broker,
                        cost: x.cost,
                        calculation: x.cost_calculation,
                        countryCode: x.country_code
                    }))

                    let documentList = successData.document_list.map(d => ({
                        id: d.id,
                        documentName: d.name
                    }))

                    let collateralList = successData.collateral_list.map(c => ({
                        collateralId: c.collateral_id,
                        tradeId: c.trade_id,
                        issuerName: c.issuer_name,
                        issuerId: c.issuer,
                        receiverName: c.receiver_name,
                        receiverId: c.receiver,
                        bankGuaranteeValidityDate: new Date(c.validity_date),
                        collateralWithoutTax: c.collateral,
                        statusOfValidity: c.is_valid ? true : false,
                        remainingDays: c.remaining_days,
                        tradingVenue: c.trading_venue,
                        collateralType: c.collateral_type,
                        deliveryType: c.delivery_type,
                        collateralRate: c.collateral_rate
                    }))

                    let contract = {
                        id: successData.contract_id,
                        tradeId: successData.trade_id,
                        name: successData.contract_name,
                        companyName: successData.company_name,
                        companyId: successData.company_id,
                        tradingVenue: successData.trading_venue,
                        contractType: successData.contract_type,
                        deliveryType: successData.delivery_type,
                        product: successData.product,
                        trader: successData.trader,
                        marketId: successData.market_id,
                        marketName: successData.market_name,
                        currency: successData.currency,
                        counterPartyId: successData.counter_party_id,
                        counterPartyName: successData.counter_party_name,
                        periodId: successData.period_id,
                        periodName: successData.period_name,
                        bookId: successData.book_id,
                        bookName: successData.book_name,
                        contractDateType: successData.contract_date_type,
                        paymentPeriod: successData.payment_period,
                        paymentTimePeriod: successData.payment_time_period,
                        paymentDate: successData.payment_date ? new Date(successData.payment_date): null,
                        year: successData.year,
                        startDate: new Date(successData.start_date),
                        endDate: new Date(successData.end_date),
                        term: successData.term,
                        contractDate: new Date(successData.contract_date),
                        position: successData.position,
                        price: successData.price,
                        amount: successData.amount,
                        costList: costList,
                        documentList: documentList,
                        collateralList: collateralList
                    }

                    this.addNewTab({ ...contract, readOnly: readOnly })

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getContract');
            });
    }

    onCancelButtonClick(contract) {
        this.closeAddDialogBox()
        if (contract) {
            this.addNewTab({ ...contract, readOnly: true })
        }
    }

    closeAddDialogBox() {
        this.setState({ openAddDialogBox: false })
    }

    hideDeleteConfirmationModal = () => {
        this.setState({ showDeleteConfirmationModal: false });
    }

    addNewTab(row) {
        this.setState({ defaultSelectedTabId: row.id })

        let ids = this.state.tabInfo.map(t => t.id)

        row.key = `${row.id}_${row.readOnly}`

        if (!ids.includes(row.id)) {
            this.setState({ tabInfo: [...this.state.tabInfo, row] })
        } else {

            let newTabList = this.state.tabInfo.map(tab => {
                if (tab.id === row.id) {
                    return row
                } else {
                    return tab
                }
            })

            this.setState({ tabInfo: newTabList })
        }
    }

    async getCompaniesDetail() {
        this.spinner.showSpinner('getCompanyNames');
        getCompaniesDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        counterPartyNames: successData.company_list.map(x => (
                            {
                                id: x.id,
                                name: x.name,
                                label: x.name,
                                type: x.type,
                                documentCount: x.documentCount
                            }
                        )),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getCompanyNames');
            });
    }

    async getPeriodsDetail() {
        this.spinner.showSpinner('getPeriodsDetail');
        getPeriodsDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        periodNames: successData.period_list.map(x => (
                            {
                                id: x.id,
                                name: x.name,
                                label: x.name,
                                hourCount: x.hour_count,
                                weekdayCount: x.weekday_count
                            })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getPeriodsDetail');
            });
    }

    async getMarketDetails() {
        this.spinner.showSpinner('getMarketNames');
        getMarketNames()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        marketNames: successData.market_name_list.map(x => ({ 
                            id: x.id, 
                            name: x.name, 
                            label: x.name, 
                            currency: x.currency,
                            book_id: x.book_id
                        })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getMarketNames');
            });
    }

    async getBookDetails() {
        this.spinner.showSpinner('getBookNames');
        getBookDetails()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        bookNames: successData.book_list.map(x => ({ id: x.id, name: x.name, label: x.name })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getBookNames');
            });
    }

    async getCostsDetail() {
        this.spinner.showSpinner('getCostsDetail');
        getCostsDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        costNames: successData.cost_list.map(x => (
                            {
                                id: x.id,
                                value: x.id,
                                name: x.cost_type,
                                label: `${x.cost_type} ${x.country_code} ${x.broker}`,
                                currency: x.currency,
                                broker: x.broker,
                                cost: x.cost,
                                calculation: x.cost_calculation,
                                countryCode: x.country_code,
                                startDate: x.start_date,
                                endDate: x.end_date
                            })),
                    });
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getCostsDetail');
            });
    }

    onShowButtonClick = async (filter) => {
        this.spinner.showSpinner('getContracts');
        getContracts(filter)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        tableRows: this.getTableRows(response.data.success.contract_result)
                    });
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getContracts');
            });
    }

    getTableRows(data) {
        let rowList = []
        for (let i = 0; i < data.length; i++) {
            const currentRow = data[i]

            rowList.push({
                id: currentRow.contract_id,
                tradeId: currentRow.trade_id,
                name: currentRow.contract_name,
                companyName: currentRow.company_name,
                companyId: currentRow.company_id,
                tradingVenue: currentRow.trading_venue,
                tradingVenueName: TRADING_VENUE[currentRow.trading_venue]?.label,
                contractType: currentRow.contract_type,
                contractTypeName: CONTRACT_TYPES[currentRow.contract_type]?.label,
                deliveryType: currentRow.delivery_type,
                deliveryTypeName: DELIVERY_TYPES[currentRow.delivery_type]?.label,
                product: currentRow.product,
                productName: PRODUCT[currentRow.product]?.label,
                trader: currentRow.trader,
                traderName: TRADER[currentRow.trader]?.label,
                marketId: currentRow.market_id,
                marketName: currentRow.market_name,
                currency: currentRow.currency,
                counterPartyId: currentRow.counter_party_id,
                counterPartyName: currentRow.counter_party_name,
                periodId: currentRow.period_id,
                periodName: currentRow.period_name,
                bookId: currentRow.book_id,
                bookName: currentRow.book_name,
                contractDateType: currentRow.contract_date_type,
                contractDateTypeName: CONTRACT_DATE_TYPE[currentRow.contract_date_type]?.label,
                paymentPeriod: currentRow.payment_period,
                paymentPeriodName: PAYMENT_PERIODS[currentRow.payment_period]?.label,
                paymentTimePeriod: currentRow.payment_time_period,
                paymentDate: currentRow.payment_date ? new Date(currentRow.payment_date): null,
                year: currentRow.year,
                startDate: new Date(currentRow.start_date),
                endDate: new Date(currentRow.end_date),
                term: currentRow.term,
                contractDate: new Date(currentRow.contract_date),
                position: currentRow.position,
                price: currentRow.price,
                amount: currentRow.amount,
                contractMonth: currentRow.contract_month,
                cost: currentRow.cost,
                costType: currentRow.cost_type,
                broker: currentRow.broker,
                totalCost: currentRow.total_cost
            })
        }

        return rowList;
    }

    saveButtonClick(requestBody) {
        this.closeAddDialogBox()
        this.spinner.showSpinner('saveContract');

        saveContract(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.CONTRACT.SUCCESSFULL_CONTRACT_SAVE);
                    this.onShowButtonClick({});

                    let row = {
                        id: response.data.success.contract_id
                    }

                    this.onEditRowClick(row, true)

                    //this.addNewTab({ ...row, readOnly: true })
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('saveContract');
            });
    }

    deleteButtonClick = () => {
        this.spinner.showSpinner('deleteContract');
        let requestBody = {
            contract_id: this.state.selectedRow.id
        }
        this.hideDeleteConfirmationModal();
        deleteContract(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.CONTRACT.SUCCESSFULL_CONTRACT_DELETE)
                    this.onShowButtonClick({});
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('deleteContract');
            });
    }

    onContractExcelImport(e) {
        loadExcel(e.target.files[0], (data) => this.importContracts(data));
    }

    importContracts = (requestBody) => {
        this.spinner.showSpinner('importContracts');
        importContracts(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.COMPANY.SUCCESSFULL_COMPANY_SAVE);
                    this.onShowButtonClick({});
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('importContracts');
            });
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        <AccordionActions>
                        <TFileUploadIcon
                                accept={['.xlsx', '.xls', '.xlsm']}
                                labeltext={`Import File`}
                                key='importTableIcon'
                                onFileChanged={(e) => this.onContractExcelImport(e)}
                            />
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onAddButtonClick()}
                            >
                                Add
                            </button>
                        </AccordionActions>
                        <TBaseModal
                            dialogClassName="t-modal-90w"
                            show={this.state.openAddDialogBox}
                            onHide={() => this.closeAddDialogBox()}
                            closeButton={true}
                            title={"Add Contract"}
                            body={
                                <TabPanel >
                                    <ContractDetail
                                        marketNames={this.state.marketNames}
                                        bookNames={this.state.bookNames}
                                        counterPartyNames={this.state.counterPartyNames}
                                        periodNames={this.state.periodNames}
                                        costNames={this.state.costNames}
                                        item={null}
                                        onSaveButtonClick={(body) => this.saveButtonClick(body)}
                                        onCancelButtonClick={(contract) => this.onCancelButtonClick(contract)}
                                        readOnly={false}
                                        editable={true}
                                    >
                                    </ContractDetail>
                                </TabPanel>
                            }
                        >

                        </TBaseModal>
                        <Divider />
                        <TTable
                            showFilterButtons

                            showExportExcelButton
                            exportExcelFileName={this.title}

                            rows={this.state.tableRows}
                            columns={this.state.tableColumns}

                            showDeleteRow
                            showEditRow

                            columnsExcluded={this.columnsExcluded}
                            onDeleteRowClick={this.onDeleteRowClick}
                            onEditRowClick={(row) => this.onEditRowClick(row, false)}

                            onRowClick={(row) => this.onEditRowClick(row, false)}

                            summaryRows={[{
                                id: 'contract_total_0',
                                totalCount: this.state.tableRows.length
                            }]}
                        ></TTable>
                    </div>
                }
            >
            </LeftSideContainer>
        )
    }

    getTableDetailComponent() {
        return (
            <RightSideContainer>
                <TTabs
                    key={this.state.defaultSelectedTabId}
                    defaultSelectedTabId={this.state.defaultSelectedTabId}
                    setTabs={this.setTabs}
                >
                    {

                        this.state.tabInfo.map((t) => {
                            return (
                                <TabPanel key={`${t.key}_tabpanel`} title={t.name} id={t.id}>
                                    <ContractDetail
                                        marketNames={this.state.marketNames}
                                        bookNames={this.state.bookNames}
                                        counterPartyNames={this.state.counterPartyNames}
                                        periodNames={this.state.periodNames}
                                        costNames={this.state.costNames}
                                        key={`${t.key}_ContractDetail`}
                                        item={t}
                                        onSaveButtonClick={(body) => this.saveButtonClick(body)}
                                        onEditButtonClick={(row) => this.addNewTab({ ...row, readOnly: false })}
                                        onCancelButtonClick={(contract) => this.onCancelButtonClick(contract)}
                                        readOnly={t.readOnly}
                                        editable={true}
                                    >
                                    </ContractDetail>
                                </TabPanel>
                            )
                        })
                    }
                </TTabs>
            </RightSideContainer>
        )
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                    rightSideComponent={this.getTableDetailComponent()}
                >
                </TContentDetailContainer>
                {
                    this.state.showDeleteConfirmationModal &&
                    <TConfirmationModal
                        show={this.state.showDeleteConfirmationModal}
                        message={messages.CONTRACT.CONFIRM_DELETE.replace("[CONTRACT]", this.state.selectedRow.name)}
                        cancelText='Cancel'
                        onHide={this.hideDeleteConfirmationModal}
                        onCancel={this.hideDeleteConfirmationModal}
                        confirmText='Delete'
                        onConfirm={this.deleteButtonClick}
                    />
                }
            </TContentContainer>
        );
    }
}

export default Contract;