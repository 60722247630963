import { SET_USER } from '../actions/types';
import { endpointNames } from '../apis/vitusApi';
import history from '../history';
import store from '../store';

export const redirectToLogin = () => {
    if (history.location.pathname !== endpointNames.login) {
        store.dispatch({ type: SET_USER, payload: { userChecked: true } });

        history.push(endpointNames.login, { from: history.location });
    }
}

