import React from 'react';
import DataGrid, { SelectColumn, TextEditor } from 'react-data-grid';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer';
import LeftSideContainer from "../../components/TTPageContainers/LeftSideContainer";
import DistributionFilter from './DistributionFilter';
import TextField from '@material-ui/core/TextField';
import {
    getMarketNames,
    getDistribution,
    saveDistribution
} from "../../api/services";
import { handleApiError, alertError, alertSuccess } from '../../utils/errorHandler';
import { SpinnerManager } from "../../components/TSpinner/SpinnerManager";
import history from "../../../history";
import { messages } from '../../utils/messages';
import VTable from '../../../components/VTable/VTable';
import { orderBy } from 'lodash';
import moment from 'moment';

function rowKeyGetter(row) {
    if (row) {
        return row.id;
    }
    return
}

class Distribution extends React.Component {
    title = "Distribution";
    dragStartRowId = -1
    dragStartColId = -1
    dragEndRowId = -1
    dragEndColId = -1


    spinner = new SpinnerManager(history.location.pathname);

    state = {
        marketNames: [],
        tableItems: {},
        rows: [],
        originalRows: [],
        columns: [],
        hourColumns: [],
        selectedRows: new Set(),
        readOnly: true,
        hourSet: new Set(),
        selectedFilter: {},
        isManuelUpdated: 0
    };

    componentDidMount() {
        this.getMarketDetails();
    }

    onShowButtonClick = async (filter) => {
        this.spinner.showSpinner('getDistribution');
        this.setState({ readOnly: true, selectedFilter: filter })
        getDistribution(filter)
            .then(response => {
                if (response.data.success) {

                    let distResult = response.data.success.dist_result
                    let isManuel = response.data.success.is_manuel
                    distResult = orderBy(distResult, ["dat_time"])

                    let values_result = {}
                    for (let oneDt in distResult) {
                        let date = moment(new Date(distResult[oneDt].date_time)).format('YYYY-MM-DD')
                        values_result[date] = {}
                    }

                    let hour_set = new Set()
                    for (let oneDt in distResult) {
                        let date_time = new Date(distResult[oneDt].date_time)
                        hour_set.add(date_time.getHours())
                        let hour = `H${date_time.getHours()}`;
                        let date = moment(date_time).format('YYYY-MM-DD')

                        values_result[date][hour] = distResult[oneDt].price
                        values_result[date]["price_id"] = distResult[oneDt].price_id
                    }
                    let row_list = []
                    let original_row_list = []
                    let idx = 0
                    for (let date in values_result) {

                        let d_value = {}
                        d_value["date"] = date
                        d_value["id"] = idx++;
                        d_value["price_id"] = values_result[date]["price_id"];
                        for (let hour in values_result[date]) {
                            d_value[hour] = values_result[date][hour]
                        }
                        d_value["editColList"] = []

                        row_list.push(d_value)
                        original_row_list.push({ ...d_value })
                    }

                    this.setState({
                        columns: this.getColumns(hour_set, false),
                        hourColumns: Array.from(hour_set),
                        hourSet: hour_set,
                        rows: row_list,
                        originalRows: original_row_list,
                        isManuelUpdated: isManuel
                    })
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getDistribution');
            });
    };

    dragStartHandler(e, rowId, columnId) {
        this.dragStartRowId = rowId
        this.dragStartColId = columnId
    }

    dragEndHandler(e, rowId, columnId) {
        let newRows = [...this.state.rows]
        const minRow = Math.min(this.dragStartRowId, this.dragEndRowId);
        const maxRow = Math.max(this.dragStartRowId, this.dragEndRowId);

        const minCol = Math.min(this.dragStartColId, this.dragEndColId);
        const maxCol = Math.max(this.dragStartColId, this.dragEndColId);


        let value = newRows[this.dragStartRowId][`H${this.dragStartColId}`]
        for (let rowIdx = minRow; rowIdx <= maxRow; rowIdx++) {
            for (let colIdx = minCol; colIdx <= maxCol; colIdx++) {
                newRows[rowIdx][`H${colIdx}`] = value
                newRows[rowIdx]["editColList"].push(colIdx)
            }
        }

        this.setState({ rows: newRows, columns: this.getColumns(this.state.hourSet, true) })

    }

    dragOverHandler(e, rowId, columnId) {
        this.dragEndRowId = rowId
        this.dragEndColId = columnId
    }

    onChangeCellValue = (value, rowId, columnId) => {
        let newRows = [...this.state.rows]
        newRows[rowId][`H${columnId}`] = value
        newRows[rowId]["editColList"].push(columnId)

        this.setState({ rows: newRows })
    }

    getColumns(hourSet, editable) {
        let columns = [{
            key: 'date',
            name: 'Date',
            frozen: true,
            resizable: true
        }]

        Array.from(hourSet).map(h => {
            columns.push({
                key: `H${h}`,
                name: `H${h}`,
                formatter: ({ row }) => {
                    let backgroundColor = ""
                    if (row.editColList.some(v => v === h)) {
                        backgroundColor = "#f6d7be"
                    } else {
                        backgroundColor = "#c1bdbd"
                    }
                    return (

                        <div key={`${row.date}_${h}_header`}
                            draggable={!this.state.readOnly}
                            onDragStart={e => editable ? this.dragStartHandler(e, row.id, h) : null}
                            onDragEnd={e => editable ? this.dragEndHandler(e, row.id, h) : null}
                            onDragOver={e => editable ? this.dragOverHandler(e, row.id, h) : null}
                            style={{ display: "flex", border: "outset", width: "100%" }}>
                            {
                                editable ? (
                                    <input
                                        style={{ width: "100%", backgroundColor: backgroundColor }}
                                        id={`${row.date}_${h}_cell`}
                                        type='number'
                                        value={row[`H${h}`]}
                                        onChange={(e) => this.onChangeCellValue(e.target.value, row.id, h)}
                                    >
                                    </input>
                                ) : (
                                        <span key={`${row.date}_${h}_cell`}
                                            style={{ width: "100%", backgroundColor: backgroundColor }}>
                                            {row[`H${h}`]}
                                        </span>
                                    )
                            }

                        </div>
                    )

                },
            })

        })

        return columns

    }

    async getMarketDetails() {
        this.spinner.showSpinner("getMarketNames");
        getMarketNames()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;

                        this.setState({
                            marketNames: successData.market_name_list.map((x) => ({
                                id: x.id,
                                value: x.name,
                                label: x.name,
                                currency: x.currency,
                            })),
                        });
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getMarketNames");
            });
    }


    onEditButtonClick = () => {
        this.setState({ readOnly: false, columns: this.getColumns(this.state.hourSet, true) })
    }

    onCancelButtonClick = () => {
        this.setState({
            readOnly: true,
            columns: this.getColumns(this.state.hourSet, false),
            rows: this.state.originalRows.map(r => ({ ...r, editColList: [] }))
        })
    }

    onSaveButtonClick = () => {
        console.log("SAVE CLICK")
        this.setState({ readOnly: true, columns: this.getColumns(this.state.hourSet, false) })
        this.spinner.showSpinner('saveDistribution');
        let requestBody = {
            data: this.state.rows,
            ...this.state.selectedFilter
        }

        saveDistribution(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.DISTRIBUTION.SUCCESSFULL_SAVE);
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('saveDistribution');
            });
    }


    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                filterDefaultExpanded={true}
                contentComponent={
                    <div>
                        <div style={{ "margin": "12px", display: `${this.state.isManuelUpdated ? "block" : "none"}` }}>
                            <span style={{ marginLeft: "9px", fontSize: "14px", fontStyle: "italic" }}>
                                {"** Prices updated manually."}
                            </span>
                            <Divider />
                        </div>
                        <div style={{ "margin": "12px", display: `${this.state.readOnly ? "block" : "none"}` }}>
                            <AccordionActions>
                                <button
                                    size="small"
                                    className="btn t-orange-button"
                                    onClick={() => this.onEditButtonClick()}
                                    disabled={!(this.state.readOnly)}
                                >
                                    Edit
                        </button>
                            </AccordionActions>
                            <Divider />
                        </div>
                        <div style={{ "margin": "12px", display: `${this.state.readOnly ? "none" : "block"}` }}>
                            <Divider />
                            <AccordionActions>
                                <button
                                    size="small"
                                    className="btn t-gray-button"
                                    onClick={() => this.onCancelButtonClick()}
                                >
                                    Cancel
                            </button>
                                <button
                                    size="small"
                                    className="btn t-orange-button"
                                    onClick={() => this.onSaveButtonClick()}
                                    disabled={this.state.readOnly}
                                >
                                    Save
                            </button>
                            </AccordionActions>
                        </div>


                        <DataGrid
                            columns={this.state.columns}
                            rows={this.state.rows}
                            rowKeyGetter={rowKeyGetter}
                        />


                    </div>
                }
                filterComponent={
                    this.state.marketNames.length ? (
                        <DistributionFilter
                            marketNames={this.state.marketNames}
                            onShowButtonClick={this.onShowButtonClick}
                        ></DistributionFilter>
                    ) : null
                }
            ></LeftSideContainer>
        );
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                ></TContentDetailContainer>
            </TContentContainer>
        );
    }
}

export default Distribution;