import React from 'react';
import TContentContainer from '../TContentContainer/TContentContainer';

class TestEtrmPage2 extends React.Component {
    state = {selected: null}
    onSelectedChanged = (item) => {
        this.setState({ selected: item });
    }

    getTitle = () => {
        return this.state.selected ? this.state.selected.label : ""
    }
    
    
    render() {
        return (
            <TContentContainer title = {"Add Trader"}>
                   
                    
                </TContentContainer>
        );
    }
}

export default TestEtrmPage2;