import {
    CREATE_ALERT,
    REMOVE_ALERT
} from '../actions/types';

const INITIAL_STATE = {
    alerts: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_ALERT:
            return {
                ...state,
                alerts: [...state.alerts,
                {
                    id: new Date().getTime(),
                    type: action.payload.type,
                    message: action.payload.message
                }]
            };
        case REMOVE_ALERT:
            const alerts = state.alerts;
            const idx = alerts.indexOf(action.payload.alert);
            if (idx < 0) return alerts;

            return {
                ...state,
                alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
            };
        default:
            return state;
    }
};
