import React from 'react';

import VBaseModal from '../VBaseModal/VBaseModal';
import VDropdown from '../../components/VDropdown/VDropdown';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import { extractDate } from '../../helpers/generalHelper';

class AddServiceCostModal extends React.Component {

    unitOptions = [
        { value: 'EUR/MWh', label: 'EUR/MWh' },
        { value: 'TL/MWh', label: 'TL/MWh' },
        { value: 'EUR/TRY', label: 'EUR/TRY' }
    ];

    state = {
        startDate: null,
        endDate: null,
        selectedCostType: null,
        selectedCostTypeDescription: null,
        selectedUnit: null,
        value: ""
    };

    componentDidMount() {
        if (this.props.startDate && this.props.endDate) {
            this.setState({ startDate: this.props.startDate, endDate: this.props.endDate });
        }
        else if (this.props.startDate) {
            this.setState({ startDate: this.props.startDate });
        }
    }

    createModalInputs() {
        return {
            startDate: this.state.startDate ? extractDate(this.state.startDate) : null,
            endDate: this.state.endDate ? extractDate(this.state.endDate) : null,
            costType: this.state.selectedCostType ? this.state.selectedCostType.value : null,
            costTypeDescription: this.state.selectedCostTypeDescription ? this.state.selectedCostTypeDescription.value : null,
            unit: this.state.selectedUnit ? this.state.selectedUnit.value : null,
            value: this.state.value
        };
    }

    clearStates() {
        this.setState({
            startDate: null,
            endDate: null,
            selectedCostType: null,
            selectedCostTypeDescription: null,
            selectedUnit: null,
            value: ""
        });
    }

    onModalAddClick = () => this.props.onAdd(this.createModalInputs());

    onModalCancelClick = () => {
        this.props.onCancel();
        this.clearStates();
    }

    checkIfTableHasAdate() {
        return this.props.startDate !== null;
    }

    render() {
        return (
            <VBaseModal
                dialogClassName="v-modal-30w"
                show={this.props.show}
                onHide={this.props.onCancel}
                title="Add Service Cost"
                closeButton={false}
                body={
                    <div className="container">
                        <React.Fragment>
                            <div className="row">
                                <div className="col">
                                    <label className="v-font v-font-size v-lt-add-modal-label">Start Date</label>
                                </div>
                                <div className="col-8">
                                    <VDatePicker
                                        disabled={this.checkIfTableHasAdate()}
                                        selectedDate={this.state.startDate}
                                        onSelectedDateChange={(startDate) => this.setState({ startDate })}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label className="v-font v-font-size v-lt-add-modal-label">End Date</label>
                                </div>
                                <div className="col-8">
                                    <VDatePicker
                                        disabled={this.checkIfTableHasAdate()}
                                        selectedDate={this.state.endDate}
                                        onSelectedDateChange={(endDate) => this.setState({ endDate })}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">Cost Type</label>
                            </div>
                            <div className="col-8">
                                <VDropdown
                                    width="large"
                                    value={this.state.selectedCostType}
                                    options={this.props.serviceCostTypes}
                                    onSelectedOptionChange={(selectedCostType) => this.setState({ selectedCostType, selectedCostTypeDescription: null })}
                                    isSearchable={false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">Cost Description</label>
                            </div>
                            <div className="col-8">
                                <VDropdown
                                    width="large"
                                    value={this.state.selectedCostTypeDescription}
                                    options={this.state.selectedCostType ? this.props.serviceCostTypeDescriptions[this.state.selectedCostType.value] : []}
                                    onSelectedOptionChange={(selectedCostTypeDescription) => this.setState({ selectedCostTypeDescription })}
                                    isSearchable={false}
                                    disabled={!this.state.selectedCostType}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">Unit</label>
                            </div>
                            <div className="col-8">
                                <VDropdown
                                    width="large"
                                    value={this.state.selectedUnit}
                                    options={this.unitOptions}
                                    isSearchable={false}
                                    onSelectedOptionChange={(selectedUnit) => this.setState({ selectedUnit })}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">Value</label>
                            </div>
                            <div className="col-8">
                                <input type="number" value={this.state.value} onChange={(e) => this.setState({ value: e.target.value })} className="v-input" />
                            </div>
                        </div>
                    </div>
                }
                actions={
                    <React.Fragment>
                        <button
                            className="btn v-cancel-button"
                            onClick={this.onModalCancelClick}>
                            Cancel
                        </button>
                        <button
                            className="btn v-button"
                            onClick={this.onModalAddClick}>
                            Add
                        </button>
                    </React.Fragment >
                }
            />
        );
    }


}
export default AddServiceCostModal;
