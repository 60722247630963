import './VFilterContainer.css';

import React from 'react';
import { isArray, isEmpty } from 'lodash';


const VFilterContainer = props => {

    /*
        Active Filter Array Format

        let activeFilter = [
         { label: "Date", value: "22/11/2020" },
         { label: "Counter Party", value: ["Energovia", "Axpo", "Ensco", "Green"] }
        ];
    */

    let text = "Selected ";
    let filterFound = props.showActiveFilter && !isEmpty(props.activeFilter);

    if (filterFound)
        props.activeFilter.forEach(filter => { text = text + `${filter.label}: ${isArray(filter.value) ? filter.value.join(", ") : filter.value} | ` });

    return (
        <div className="v-filter-container">
            {props.children}
            <hr />
            { filterFound &&
                <React.Fragment>
                    <div className="v-gray">
                        <i className="fa fa-filter" aria-hidden="true"> <span className="v-font v-font-size">{text.slice(0, -3)}</span></i>
                    </div>
                </React.Fragment>
            }
        </div>
    );
};

export default VFilterContainer;