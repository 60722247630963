import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../actions';
import TSpinner from '../TSpinner/TSpinner';
import Divider from '@material-ui/core/Divider';
import { endpointNames } from '../../../apis/vitusApi';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Box from '@material-ui/core/Box';
import { getNotifications, markAsSeen } from "../../api/services";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import './TUserInfo.css';


class TUserInfo extends React.Component {
    state = {
        showSpinner: false,
        showNotificationDetail: false,
        target: null,
        notificationCount: 0
    };

    ref = React.createRef();

    componentDidMount() {
        this.getNotifications()
        setInterval(this.getNotifications, 100000);
    }

    getNotifications = async () => {
        getNotifications()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    let notificationCount = successData.notification_result.filter(n => !n.is_seen)
                    
                    this.setState({
                        notificationCount: notificationCount.length,
                        notificationList: successData.notification_result
                    });
                }
            }, error => {
                console.log(error);
            }).finally(() => {

            });
    }

    markAsSeen = async () => {
        markAsSeen({})
    }

    create_logout_action = (event) => {
        this.setState({ showSpinner: true });
        event.preventDefault();
        this.props.logout();
    }

    componentWillUnmount() {
        this.setState({ showSpinner: false });
    }

    handleNotificationClick = (event) => {
        this.markAsSeen();
        this.setState({
            notificationCount: 0,
            showNotificationDetail: !this.state.showNotificationDetail,
            target: event.target
        });
    }

    onHide = () => {
        this.setState({
            showNotificationDetail: false
        });
    }

    render() {
        if (this.state.showSpinner) {
            return <TSpinner show={true} />;
        }

        return (
            <div className="t-user-info-content v-gray">
                <div className="t-external-link" onClick={() => window.open(endpointNames.main) }>
                    <i aria-hidden="true">
                        <span className="t-font">
                            {"Cross Border Operations"}
                        </span>
                    </i>
                </div>
                <Divider style={{ margin: "5px" }} orientation="vertical" flexItem />
                <div className="t-user-detail">
                    <i className="fa fa-user-circle-o" aria-hidden="true">
                        <span className="t-font">
                            {this.props.userName}
                        </span>
                    </i>
                </div>
                <Divider style={{ margin: "5px" }} orientation="vertical" flexItem />


                <Box sx={{ flexGrow: 0 }} onClick={this.handleNotificationClick}>
                    <Badge
                        badgeContent={this.state.notificationCount}
                        max={99}
                        color="error"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <NotificationsIcon color="action" />
                    </Badge>
                </Box>
                {

                    <div ref={this.ref}>
                        <Overlay
                            show={this.state.showNotificationDetail}
                            target={this.state.target}
                            placement="bottom"
                            container={this.ref.current}
                            containerPadding={20}
                            rootClose={true}
                            onHide={this.onHide}
                        >
                            <Popover id="popover-contained">
                                <Popover.Title as="h3">{"Notifications"}</Popover.Title>
                                <Popover.Content style={{ padding: '5px 5px', overflow: "auto", maxWidth: "420px" }}>
                                    {
                                        this.state.notificationList?.length ? (
                                            <List style={{ width: "100%", maxHeight: "720px" }}>
                                                {
                                                    this.state.notificationList.map(notification => {
                                                        return (
                                                            <ListItem key={`${notification.market}_${notification.insert_date}`} style={{backgroundColor: notification.is_seen ? "" : "#d4e7f7", margin:"1px"}}>
                                                                <ListItemIcon style={{minWidth: "20px"}}>
                                                                    <FiberManualRecordIcon fontSize="small" style={{width: "10px"}}/>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={`${notification.market} pnl updated with ${notification.price_date} prices on ${notification.insert_date}`}
                                                                />
                                                            </ListItem>
                                                           
                                                        )
                                                    })
                                                }

                                            </List>

                                        ) : (
                                                <ListItem>
                                                    <ListItemText primary="No Notification Found" />
                                                </ListItem>
                                            )

                                    }
                                </Popover.Content>
                            </Popover>

                        </Overlay>
                    </div>

                }


                <Divider style={{ margin: "5px" }} orientation="vertical" flexItem />
                <div className="t-user-log-out">
                    <button onClick={this.create_logout_action}>
                        <i className="fa fa-sign-out v-gray t-user-log-out-icon" aria-hidden="true">
                            &nbsp;Log out
                        </i>
                    </button>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        userName: state.auth.userName
    };
};

export default connect(mapStateToProps, { logout })(TUserInfo);