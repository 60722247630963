import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing() / 4,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  indeterminateColor: {
    color: "#3f51b5"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class MultipleSelect extends React.Component {
  state = {
    selectedItems: this.props.selectedItems
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedItems !== prevProps.selectedItems) {
      this.setState({
        selectedItems: this.props.selectedItems
      });
    }
  }

  handleChange = event => {
    const value = event.target.value;
    let newSelectedItems = []
    if (value[value.length - 1] === "all") {
      newSelectedItems = this.state.selectedItems.length === this.props.items.length ? [] : this.props.items
    } else {
      newSelectedItems = event.target.value
    }


    this.setState({
      selectedItems: newSelectedItems
    })

  };

  handleClose = () => {
    this.props.onChange(this.state.selectedItems);
    return;
  }

  isAllSelected() {
    return this.props.items.length > 0 && this.state.selectedItems.length === this.props.items.length
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          {
            (this.props.readOnly) ? (
              <TextField
                label={this.props.title}
                value={this.state.selectedItems && this.state.selectedItems.length ? this.state.selectedItems.map(d => d.label).join(', ') : ""}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true
                }}
              />
            ) : (
                <div>
                  <InputLabel>{this.props.title}</InputLabel>
                  <Select
                    style={{ display: "flex" }}
                    multiple
                    value={this.props.items.filter(item => this.state.selectedItems.find(d => d.value === item.value))}
                    onChange={this.handleChange}
                    onClose={this.handleClose}
                    input={<Input />}
                    renderValue={selected => `(${selected.length}) ${selected.map(d => d.label).join(", ")}`}
                    MenuProps={MenuProps}
                    disabled={this.props.disabled}
                  >

                    <MenuItem
                      value="all"
                      classes={{
                        root: this.isAllSelected() ? classes.selectedAll : ""
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          color="primary"
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={this.isAllSelected()}
                          indeterminate={
                            this.state.selectedItems.length > 0 && this.state.selectedItems.length < this.props.items.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {this.props.items.map(item => (
                      <MenuItem key={`${item.value}_${item.id}`} value={item}>
                        <Checkbox color="primary" checked={this.state.selectedItems.find(d => d.value === item.value) ? true : false} />
                        <ListItemText primary={item.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>

              )
          }

        </FormControl>
      </div>
    );
  }
}

MultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MultipleSelect);
