import './PnlFtr.css';

import { isEmpty, orderBy } from 'lodash';
import React from 'react';
import { getNumberClassBySign } from '../../../helpers/generalHelper';

class PnlFtr extends React.Component {

    pnlColumnKey = 'pnl';
    totalPnlHeader = 'Total PnL';
    rowClasses = ['v-row-group-1', 'v-row-group-2'];
    companyLabels = { 
        "Vitus": "Vitus Commodities",
         "Vitus Capital": "Vitus Capital",
          "Monolith Capital": "Monolith Capital"
    };

    dateColumn = {
        key: 'dt',
        title: 'Date (CET)'
    }

    periodColumns = {
        'monthly': 'Monthly',
        'yearly': 'Yearly'
    }

    directionInfoColumns = {
        'general': 'General Info',
        'current': 'Current Info'
    }

    // -> 'Json response param' : 'Column title'
    ftrDirectionSubColumns = {
        'capacity': 'Capacity MW',
        'price': 'Capacity Cost',
        'monthly_rate': 'Capacity Exchange Rate',
        'usage_amount': 'Capacity Usage Amount',
        'daily_auction_price': 'Capacity Resale Auction Price',
        'daily_rate': 'Realized Exchange Rate',
        'pnl': 'Realized PNL'
    }

    /*
    -> 'Json response param' : 'Column title'
    -> sell_price = parse direction by '-' then get second country
    -> buy_price = parse direction by '-' then get first country
    */
    autoSettleDirectionSubColumns = {
        'sell_price': '',
        'buy_price': '',
        'capacity': 'Capacity MW',
        'daily_rate': 'Realized Exchange Rate',
        'auction_price': 'Capacity Cost',
        'monthly_rate': 'Capacity Exchange Rate',
        'pnl': 'Realized PNL'
    }

    // Both mainDataTypes and mainDataDetails has same object id
    mainDataDetails = {
        'ftr': {
            'topColumnName': 'Resale',
            'dataGroupColor': 'v-data-group-2',
            'directionSubColumnNumber': Object.keys(this.ftrDirectionSubColumns).length
        },
        'autoSettle': {
            'topColumnName': 'Auto Settle',
            'dataGroupColor': 'v-data-group-1',
            'directionSubColumnNumber': Object.keys(this.autoSettleDirectionSubColumns).length
        }
    }

    mainDataTypes = this.prepareMainDataTypes();

    prepareMainDataTypes() {
        let obj = {};

        Object.keys(this.mainDataDetails).forEach(type => {
            obj[type] = type;
        });

        return obj;
    }

    setPeriodColumns(direcions, type) {

        const monthlyDirections = direcions.filter(d => d.period === this.periodColumns.monthly);
        const yearlyDirections = direcions.filter(d => d.period === this.periodColumns.yearly);

        return (
            <React.Fragment>
                {
                    !isEmpty(monthlyDirections) &&
                    <th key={`m_${type}`}
                        colSpan={monthlyDirections.length * this.mainDataDetails[type].directionSubColumnNumber}
                        className={this.mainDataDetails[type].dataGroupColor}>
                        {this.periodColumns.monthly}
                    </th>
                }
                {
                    !isEmpty(yearlyDirections) &&
                    <th key={`y_${type}`}
                        colSpan={yearlyDirections.length * this.mainDataDetails[type].directionSubColumnNumber}
                        className={this.mainDataDetails[type].dataGroupColor}>
                        {this.periodColumns.yearly}
                    </th>
                }
            </React.Fragment>
        );
    }

    setDirectionColumns(directions, type) {
        return directions.map((d, dIdx) => {
            return (
                <th key={`${d.direction}_${d.period}_${dIdx}`}
                    colSpan={this.mainDataDetails[type].directionSubColumnNumber}
                    className={this.mainDataDetails[type].dataGroupColor}>
                    <span>{d.direction} <span className='pnl_ftr_light_font'>{`(${this.companyLabels[d.company]})`}</span></span>
                </th>
            );
        });
    }

    setInfoColumns(directions, type) {
        return directions.map((d, dIdx) => {
            return (
                <React.Fragment>
                    <th key={`i_${d.direction}_${dIdx}`}
                        colSpan={3}
                        className={this.mainDataDetails[type].dataGroupColor}>
                        {this.directionInfoColumns.general}
                    </th>
                    <th key={`c_${d.direction}_${dIdx}`}
                        colSpan={4}
                        className={this.mainDataDetails[type].dataGroupColor}>
                        {this.directionInfoColumns.current}
                    </th>
                </React.Fragment>
            );
        });
    }

    setDirectionSubColumns(directions, type) {
        let columns = [];

        if (type === this.mainDataTypes.ftr) {

            directions.forEach((d, idx) => {
                Object.keys(this.ftrDirectionSubColumns).forEach(key => {
                    columns.push(
                        <th key={`${d.period}_${idx}_${key}`}
                            className={this.mainDataDetails[type].dataGroupColor}>
                            {this.ftrDirectionSubColumns[key]}
                        </th>
                    );
                });
            });

        } else {
            directions.forEach((d, idx) => {

                let sellCountry = d.direction.split('-')[1];
                let buyCountry = d.direction.split('-')[0];

                Object.keys(this.autoSettleDirectionSubColumns).forEach(key => {

                    let v = this.autoSettleDirectionSubColumns[key];

                    if (key === 'sell_price')
                        v = sellCountry;
                    else if (key === 'buy_price')
                        v = buyCountry;

                    columns.push(
                        <th key={`${d.period}_${idx}_${key}`}
                            className={this.mainDataDetails[type].dataGroupColor}>
                            {v}
                        </th>
                    );
                });
            });
        }

        return columns;
    }

    setTopColumn(directions, type) {
        return (
            <th colSpan={(directions.length * this.mainDataDetails[type].directionSubColumnNumber)}
                className={this.mainDataDetails[type].dataGroupColor}>
                {this.mainDataDetails[type].topColumnName}
            </th>
        );
    }

    renderHeaders(ftrDirections, autoSettleDirections, ftr, autoSettle) {

        const totalColumnColor = ftr ?
            this.mainDataDetails[this.mainDataTypes.ftr].dataGroupColor : this.mainDataDetails[this.mainDataTypes.autoSettle].dataGroupColor;

        return (
            <React.Fragment>
                <tr>
                    <th rowSpan={5}
                        className='v-pnl-date-header'>
                        {this.dateColumn.title}
                    </th>
                    <th className={totalColumnColor}>
                    </th>
                    {
                        ftr &&
                        this.setTopColumn(ftrDirections, this.mainDataTypes.ftr)
                    }
                    {
                        autoSettle &&
                        this.setTopColumn(autoSettleDirections, this.mainDataTypes.autoSettle)
                    }
                </tr>
                <tr>
                    <th key={"period_empty"}
                        className={totalColumnColor}>
                    </th>
                    {
                        ftr &&
                        this.setPeriodColumns(ftrDirections, this.mainDataTypes.ftr)
                    }
                    {
                        autoSettle &&
                        this.setPeriodColumns(autoSettleDirections, this.mainDataTypes.autoSettle)
                    }
                </tr>
                <tr>
                    <th key={"direction_empty"}
                        className={totalColumnColor}>
                    </th>
                    {
                        ftr &&
                        this.setDirectionColumns(ftrDirections, this.mainDataTypes.ftr)
                    }
                    {
                        autoSettle &&
                        this.setDirectionColumns(autoSettleDirections, this.mainDataTypes.autoSettle)
                    }
                </tr>
                <tr>
                    <th key={`info_empty`}
                        className={totalColumnColor}>
                    </th>
                    {
                        ftr &&
                        this.setInfoColumns(ftrDirections, this.mainDataTypes.ftr)
                    }
                    {
                        autoSettle &&
                        this.setInfoColumns(autoSettleDirections, this.mainDataTypes.autoSettle)
                    }
                </tr>
                <tr>
                    <th key={'total'}
                        className={totalColumnColor}>
                        {this.totalPnlHeader}
                    </th>
                    {
                        ftr &&
                        this.setDirectionSubColumns(ftrDirections, this.mainDataTypes.ftr)
                    }
                    {
                        autoSettle &&
                        this.setDirectionSubColumns(autoSettleDirections, this.mainDataTypes.autoSettle)
                    }
                </tr>
            </React.Fragment>
        );
    }

    renderEachRow(ftrDirections, autoSettleDirections, ftr, autoSettle, rowDate, totalPnl) {
        let rowValues = [];

        if (ftr) {
            ftrDirections.forEach(d => {
                let data = d.data.find(i => i.dt === rowDate);

                Object.keys(this.ftrDirectionSubColumns).forEach(key => {
                    rowValues.push(
                        <td key={`f_${key}_${d.direction}_${d.period}_${rowDate}`}
                            className={key === this.pnlColumnKey ? `${getNumberClassBySign(data[key])}` : ''}>
                            {data[key].toLocaleString()}
                        </td>
                    );
                });
            });
        }

        if (autoSettle) {
            autoSettleDirections.forEach(d => {
                let data = d.data.find(i => i.dt === rowDate);

                Object.keys(this.autoSettleDirectionSubColumns).forEach(key => {
                    rowValues.push(
                        <td key={`a_${key}_${d.direction}_${d.period}`}
                            className={key === this.pnlColumnKey ? `${getNumberClassBySign(data[key])}` : ''}>
                            {data[key].toLocaleString()}
                        </td>
                    );
                });
            });
        }

        return rowValues;
    }

    renderRows(ftrDirections, autoSettleDirections, ftr, autoSettle) {

        const referenceData = ftr ? ftrDirections[0].data : autoSettleDirections[0].data;
        let rowClassOrder = 0;

        return (
            referenceData.map((referenceRow, rowIdx) => {
                if (rowIdx && referenceRow[this.dateColumn.key].split(' ')[0] !== referenceData[rowIdx - 1][this.dateColumn.key].split(' ')[0])
                    rowClassOrder = (rowClassOrder + 1) % 2;

                const ftrPnL = ftr ? ftrDirections.reduce((a, b) => a + Number(b.data[rowIdx][this.pnlColumnKey]), 0).toFixed(2) : 0;
                const autoSettlePnL = autoSettle ? autoSettleDirections.reduce((a, b) => a + Number(b.data[rowIdx][this.pnlColumnKey]), 0).toFixed(2) : 0;
                const totalPnl = parseFloat(ftrPnL) + parseFloat(autoSettlePnL);

                const referenceDate = referenceRow[this.dateColumn.key];

                return (
                    <tr key={referenceDate}
                        className={this.rowClasses[rowClassOrder]}>
                        <td className='v-pnl-date-row'>
                            {referenceDate}
                        </td>
                        <td className={`v-pnl-total ${getNumberClassBySign(totalPnl)}`}>
                            {totalPnl.toLocaleString()}
                        </td>
                        {
                            this.renderEachRow(ftrDirections, autoSettleDirections, ftr, autoSettle, referenceDate, totalPnl)
                        }
                    </tr>
                );
            })

        );

    }

    render() {
        /*
            All FTR and Auto Settle directions ordered by 'period' and 'directions'.
        */
        const ftrDirections = this.props.ftrData && !isEmpty(this.props.ftrData.directions) ?
            orderBy(this.props.ftrData.directions, ['period', 'direction', 'company']) : null;

        const autoSettleDirections = this.props.autoSettleData && !isEmpty(this.props.autoSettleData.directions) ?
            orderBy(this.props.autoSettleData.directions, ['period', 'direction', 'company']) : null;

        if (ftrDirections === null && autoSettleDirections === null)
            return (
                <div className="row">
                    <span className="pnl_ftr_no_value ">
                        No FTR or Auto Settle for selected dates
                    </span>
                </div>
            );

        const isFtrFound = ftrDirections === null ? false : true;
        const isAutoSettleFound = autoSettleDirections === null ? false : true;

        return (
            <div className='v-pnl-summary-table'>
                <table className="table table-borderless table-responsive">
                    <thead>
                        {this.renderHeaders(ftrDirections, autoSettleDirections, isFtrFound, isAutoSettleFound)}
                    </thead>
                    <tbody>
                        {this.renderRows(ftrDirections, autoSettleDirections, isFtrFound, isAutoSettleFound)}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PnlFtr;