import React from 'react';
import TEmailChip from '../../components/TChip/TEmailChip';
import TIbanChip from '../../components/TChip/TIbanChip';
import TextField from '@material-ui/core/TextField';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import TRadioButton from '../../components/TRadioButton/TRadioButton';
import { alertError } from '../../../helpers/errorHelper';
import { messages } from '../../utils/messages';
import { COMPANY_TYPES, COMPANY_TYPE_ARR } from '../../utils/constants';


class CompanyDetail extends React.Component {
    state = {
        originalCompany: null,
        companyId: null,
        companyName: '',
        companyType: null,
        eicCode: '',
        address: '',
        taxOffice: '',
        taxNumber: '',
        companyBank: '',
        emailList: [],
        ibanList: [],
        readOnly: true
    }  

    componentDidMount() {
        if (this.props.item) {
            
            this.setState({
                originalCompany: this.props.item,
                companyId: this.props.item.id,
                companyName: this.props.item.name,
                companyType: COMPANY_TYPES[this.props.item.companyType],
                eicCode: this.props.item.eicCode,
                address: this.props.item.address,
                taxOffice: this.props.item.taxOffice,
                taxNumber: this.props.item.taxNumber,
                companyBank: this.props.item.companyBank,
                emailList: this.props.item.emailList,
                ibanList: this.props.item.ibanList,
            })
        }

        this.setState({
            readOnly: this.props.readOnly ? this.props.readOnly : false
        })
    }

    setEmailList = (emailList) => {
        this.setState({ emailList: emailList })
    }

    setIbanList = (ibanList) => {
        this.setState({ ibanList: ibanList })
    }

    onChangeCompanyName = companyName => {
        this.setState({ companyName: companyName })
    }

    onChangeEicCode = eicCode => {
        this.setState({ eicCode: eicCode })
    }

    onChangeAddress = address => {
        this.setState({ address: address })
    }

    onChangeTaxOffice = taxOffice => {
        this.setState({ taxOffice: taxOffice })
    }

    onChangeTaxNumber = taxNumber => {
        this.setState({ taxNumber: taxNumber })
    }

    onChangeCompanyBank = companyBank => {
        this.setState({ companyBank: companyBank })
    }

    onChangeCompanyType = companyType => {
        this.setState({ companyType: COMPANY_TYPES[companyType] })
    }

    onEditButtonClick() {
        this.setState({ readOnly: false })
        this.props.onEditButtonClick(this.state.originalCompany)
    }

    validateInputs() {
        if (!this.state.companyName) {
            alertError(messages.COMPANY.EMPTY_COMPANY_NAME);
            return false;
        }

        if (!this.state.companyType) {
            alertError(messages.COMPANY.EMPTY_COMPANY_TYPE);
            return false;
        }

        return true;
    }

    onSaveButtonClick() {
        if (!this.validateInputs()) {
            return;
        }

        let requestBody = {
            company_id: this.state.companyId,
            company_name: this.state.companyName,
            company_type: this.state.companyType.value ,
            eic_code: this.state.eicCode,
            address: this.state.address,
            tax_office: this.state.taxOffice,
            tax_number: this.state.taxNumber,
            company_mail_list: this.state.emailList,
            company_bank: this.state.companyBank,
            company_iban_list: this.state.ibanList
        }
        this.setState({ readOnly: true })
        this.props.onSaveButtonClick(requestBody)
    }

    onCancelButtonClick() {
        if (this.props.item) {
            this.setState({
                companyId: this.state.originalCompany.id,
                companyName: this.state.originalCompany.name,
                companyType: COMPANY_TYPES[this.state.originalCompany.companyType],
                eicCode: this.state.originalCompany.eicCode,
                address: this.state.originalCompany.address,
                taxOffice: this.state.originalCompany.taxOffice,
                taxNumber: this.state.originalCompany.taxNumber,
                companyBank: this.state.originalCompany.companyBank,
                emailList: this.state.originalCompany.emailList,
                ibanList: this.state.originalCompany.ibanList

            })
        }
        if (this.props.onCancelButtonClick) {
            this.props.onCancelButtonClick(this.state.originalCompany)
        } else {
            this.setState({ readOnly: true })
        }
    }

    render() {

        return (
            <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ "margin": "12px", display: `${this.state.readOnly ? "block" : "none"}` }}>
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onEditButtonClick()}
                                disabled={!this.state.readOnly}
                            >
                                Edit
                        </button>
                        </AccordionActions>
                        <Divider />
                    </div>
                    <TextField
                        style={{ width: "50%", margin: "12px", fontSize: "small" }}
                        label="Company Name"
                        value={this.state.companyName}
                        onChange={(e) => this.onChangeCompanyName(e.target.value)}
                        InputProps={{
                            readOnly: this.state.readOnly,
                            style: { fontSize: 12 },
                            disableUnderline: this.state.readOnly
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                    />
                    <TextField
                        style={{ width: "50%", margin: "12px", fontSize: "small" }}
                        label="EIC Code"
                        value={!this.state.eicCode && this.state.readOnly ? "-" : this.state.eicCode}
                        onChange={(e) => this.onChangeEicCode(e.target.value)}
                        InputProps={{
                            readOnly: this.state.readOnly,
                            style: { fontSize: 12 },
                            disableUnderline: this.state.readOnly
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                    />
                    <TextField
                        style={{ width: "50%", margin: "12px", fontSize: "small" }}
                        label="Address"
                        value={!this.state.address && this.state.readOnly ? "-" : this.state.address}
                        onChange={(e) => this.onChangeAddress(e.target.value)}
                        InputProps={{
                            readOnly: this.state.readOnly,
                            style: { fontSize: 12 },
                            disableUnderline: this.state.readOnly
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                    />
                    <TextField
                        style={{ width: "50%", margin: "12px", fontSize: "small" }}
                        label="Tax Office"
                        value={!this.state.taxOffice && this.state.readOnly ? "-" : this.state.taxOffice}
                        onChange={(e) => this.onChangeTaxOffice(e.target.value)}
                        InputProps={{
                            readOnly: this.state.readOnly,
                            style: { fontSize: 12 },
                            disableUnderline: this.state.readOnly
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                    />
                    <TextField
                        style={{ width: "50%", margin: "12px", fontSize: "small" }}
                        label="Tax Number"
                        value={!this.state.taxNumber && this.state.readOnly ? "-" : this.state.taxNumber}
                        onChange={(e) => this.onChangeTaxNumber(e.target.value)}
                        InputProps={{
                            readOnly: this.state.readOnly,
                            style: { fontSize: 12 },
                            disableUnderline: this.state.readOnly
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                    />
                    <TextField
                        style={{ width: "50%", margin: "12px", fontSize: "small" }}
                        label="Company Bank"
                        value={!this.state.companyBank && this.state.readOnly ? "-" : this.state.companyBank}
                        onChange={(e) => this.onChangeCompanyBank(e.target.value)}
                        InputProps={{
                            readOnly: this.state.readOnly,
                            style: { fontSize: 12 },
                            disableUnderline: this.state.readOnly
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}

                    />
                    <TRadioButton
                        title="Company Type"
                        items={COMPANY_TYPE_ARR}
                        readOnly={this.state.readOnly}
                        selected={this.state.companyType ? this.state.companyType.value : ''}
                        onChange={this.onChangeCompanyType}
                    >
                    </TRadioButton>

                    <TEmailChip
                        id={this.state.companyId}
                        items={this.state.emailList}
                        title="Company Mails"
                        readOnly={this.state.readOnly}
                        setEmailList={this.setEmailList}
                       
                    >
                    </TEmailChip>
                    <TIbanChip
                        id={this.state.companyId}
                        items={this.state.ibanList}
                        title="Company IBAN"
                        readOnly={this.state.readOnly}
                        setIbanList={this.setIbanList}
                    >
                    </TIbanChip>
                    <div style={{ "margin": "12px", display: `${this.state.readOnly ? "none" : "block"}` }}>
                        <Divider />
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-gray-button"
                                onClick={() => this.onCancelButtonClick()}
                            >
                                Cancel
                                </button>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onSaveButtonClick()}
                                disabled={this.state.readOnly}
                            >
                                Save
                             </button>
                        </AccordionActions>
                    </div>
                </div>
            </div>
        )
    };
}

export default CompanyDetail;