import './ControlLevels.css';

import React from 'react';
import VTable from '../../../components/VTable/VTable';
import { isEmpty } from 'lodash';
import { portalMessages } from '../../../helpers/portalMessages';
import { generateUuid } from '../../../helpers/generalHelper';

class ControlLevels extends React.Component {
    maxLevel = 4;

    state = {
        invalidLevel: false
    }

    jsonToTableConverter = this.props.jsonToTableConverter;
    showErrorMessage = this.props.showErrorMessage;
    exchange = this.props.exchange;
    counterParty = this.props.counterParty;
    date = this.props.date;
    defaultCurrency = 'EUR';
    defaultOperationType = 'All';
    defaultServicingCompany = 'Vitus';
    endOfControlLevelsId = generateUuid();

    componentDidUpdate() {    
        if (this.props.addNewLevel) {
            if (this.props.controlList.length === this.maxLevel) {
                this.showErrorMessage(portalMessages.EXCHANGE_OFFERS.MAX_LEVEL_COUNT);
                this.props.onEditModeClosed();
                return;
            }

            const scrollTo = document.getElementById(this.endOfControlLevelsId);
            if (scrollTo)
                scrollTo.scrollIntoView(false);
        }
    }

    renderTable(tableDetails) {
        return this.createTable(tableDetails.level, this.jsonToTableConverter(tableDetails.data, tableDetails.border), false, tableDetails);
    }

    createNewLevelTable() {
        if (this.props.createEmptyTable) {
            return this.props.createEmptyTable();
        }
        const emptyTable = this.props.jsonToTableConverter([], this.props.border);
        
        if (this.props.controlLevelFileData) {
            emptyTable.values = this.props.controlLevelFileData
        } else {
            const emptyRow = emptyTable.headers[0].slice(1).map(h => 0);
            for (let i = 0; i < 24; i++) {
                emptyTable.values[i] = [i + 1, ...emptyRow];
            }
        }

        return emptyTable;
    }

    editEnabled() {
        return this.props.editEnabled;
    }

    tableDeleteEnabled() {
        return this.editEnabled();
    }

    createTable(level, items, editModeOn, borderDetail) {
        const isLastTable = !level
            || (!this.props.addNewLevel && this.props.controlList.length === level)

        if (!level)
            level = this.props.controlList.length + 1;

        const columnCount = items.headers[0].length;

        if (!borderDetail) {
            borderDetail = {
                border: this.props.border,
                currency: this.defaultCurrency,
                level,
                offer_id: null,
                operation_type: this.defaultOperationType,
                other_currency_data: [],
                servicing_company: this.props.servicingCompany || this.props.company,
                offer_type: this.props.offerType
            }
        }

        const deleteEnabled = this.tableDeleteEnabled() && level === this.props.controlList.length && !this.props.addNewLevel;

        const ref = React.createRef();
        this.props.borderTableRefs.push(ref);

        const ids = [borderDetail.offer_id];
        if (borderDetail.other_currency_data && borderDetail.other_currency_data.length > 0 && borderDetail.other_currency_data[0].offer_id)
            ids.push(borderDetail.other_currency_data[0].offer_id)

        return (
            <VTable
                ref={ref}
                relatedInfo={borderDetail}
                key={`Intra_${level}`}
                title={borderDetail.border === this.props.totalDataTag ? `Level ${level}` : borderDetail.border}
                items={items}
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                editModeOn={editModeOn}
                onError={(message) => this.showErrorMessage(message)}
                simpleNumbers
                inputType='number'
                inputClass='v-input-4'
                exportFileName={`ExchangeOffers_ControlLevels_${this.exchange}_${this.counterParty}_Level_${level}_${this.date}`}
                endOfTableMark={isLastTable ? this.endOfControlLevelsId : ''}
                onOpenEditMode={() => this.props.onEditModeOpened()}
                onCloseEditMode={() => this.props.onEditModeClosed()}
                readonlyColumnIndices={this.props.readonlyColumnIndices(columnCount)}
                scalesByIndices={this.props.scalesByIndices(columnCount)}
                specialWordsByIndices={this.props.specialWordsByIndices(columnCount)}
                ignoredColumnsForFiles={this.props.ignoredColumnsForFiles(columnCount)}
                inputHooks={this.props.inputHooks(columnCount)}
                onSaveChangesAsync={(newData) => this.props.onSaveAsync([{ newData, borderDetail }])}
                onTableDelete={() => this.props.onDeleteAsync(ids)}
                headerButtons={{
                    showEditButton: this.editEnabled(),
                    showExportExcelButton: true,
                    showImportExcelButton: this.editEnabled(),
                    showDeleteTableButton: deleteEnabled,
                    hideSaveButton: this.props.hideSaveButton()
                }}
                deleteMessage={this.props.getDeleteLevelConfirmationMessage(level)}
                showSpecialAgg
            />
        )
    }

    render() {
        if (!this.props.addNewLevel && (!this.props.controlList || isEmpty(this.props.controlList)))
            return null;

        return (
            <React.Fragment>
                <div className={`v-control-levels${this.props.hideWrapperBorder ? '' : ' v-control-levels-bordered'}`}>
                    {
                        !this.props.hideWrapperBorder &&
                        <div className='v-control-header-wrapper'>
                            <span className='v-control-header'>Control Levels</span>
                        </div>
                    }
                    <React.Fragment>
                        {
                            this.props.controlList.map(b => {
                                return this.renderTable(b);
                            })
                        }
                        {
                            this.props.addNewLevel &&
                            this.createTable(0, this.createNewLevelTable(), true)
                        }
                    </React.Fragment>
                </div>
            </React.Fragment>
        );
    }
}

export default ControlLevels;