import './MarginAnalysis.css';

import React from 'react';
import _ from 'lodash';

import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VTagInput from '../../components/VTagInput/VTagInput';
import VBaseModal from '../../modals/VBaseModal/VBaseModal';
import VDropdown from '../../components/VDropdown/VDropdown';
import VTable from '../../components/VTable/VTable';
import { extractDate, getDayAheadForGivenDate } from '../../helpers/generalHelper';
import { alertError, handleApiError } from '../../helpers/errorHelper';
import { getBorders, getMarginAnalysis } from '../../apis/vitusApi';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorageHelper';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import { portalMessages } from '../../helpers/portalMessages';


class MarginAnalysis extends React.Component {
    hoursColumn = 'Hour';
    hoursSubColumn = 'CET'
    tableValueFormat = 'EUR/MWh';
    forecastHeaderColor = 'lightslategray';
    spinner = new SpinnerManager(history.location.pathname);

    state = {
        marginDate: getDayAheadForGivenDate(new Date()),
        modalShow: false,
        counterParties: {},
        directions: [],
        selectableCounterParties: {},
        selectableDirections: [],
        selectedCounterParty: "",
        selectedDirection: "",
        tableData: {},
        directionTags: getLocalStorage('marginAnalysis', 'tagInput', 'selectedDirections') || [],
        showNeedsRefresModal: false,
        turkeyListed: false,
        eurTryValue: '',
        forecastsExist: false,
        activeFilter: []
    };

    componentDidMount() {

        this.spinner.showSpinner('getBorders');

        getBorders()
            .then(response => {
                if (response.data.success) {
                    this.setDirectionsAndCounterParties(response.data.success.borders);

                    if (this.state.directionTags
                        && this.state.directionTags.length > 0
                        && !_.isNull(this.state.marginDate)) {
                        this.onShowButtonClick();
                    }
                } else {
                    this.setState({ showNeedsRefresModal: true });
                }

            }, error => {
                handleApiError(error);
                this.setState({ showNeedsRefresModal: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('getBorders');
            });
    }

    setDirectionsAndCounterParties(borders) {
        var directionsAndCounterParties = borders.map(border => border.directions);
        var iterator = directionsAndCounterParties.values();
        var fetchedDirections = [];
        var fetchedCounterParties = {};

        for (let directionAndCounterPartyArray of iterator) {

            directionAndCounterPartyArray.forEach(eachDirection => {

                let directionObj = {
                    value: `${eachDirection.direction}`,
                    label: `${eachDirection.direction}`
                }
                fetchedDirections.push(directionObj);

                fetchedCounterParties[directionObj.value] = eachDirection.counterParties.map(counterParty => {
                    return {
                        value: `${counterParty}`,
                        label: `${counterParty}`
                    };
                });
            });
        }

        const directions = _.orderBy(fetchedDirections, ['label'], ['asc']);

        if (!this.state.directionTags || this.state.directionTags.length < 1) {
            this.setState({
                directions: directions,
                counterParties: fetchedCounterParties,
                selectableDirections: directions,
                selectableCounterParties: fetchedCounterParties
            });
        }
        else {
            this.setState({
                directions: directions,
                counterParties: fetchedCounterParties
            });
            this.updateSelectableValuesFullCheck(directions, fetchedCounterParties)
        }

    }

    validateFields() {
        if (_.isNull(this.state.marginDate) && this.state.directionTags.length === 0) {
            alertError(portalMessages.ADD_DATE_AND_DIRECTION);
            return false;
        }

        if (_.isNull(this.state.marginDate)) {
            alertError(portalMessages.DATE_SELECTION);
            return false;
        }

        if (this.state.directionTags.length === 0) {
            alertError(portalMessages.ADD_DIRECTION);
            return false;
        }

        return true;
    }

    createMarginAnalysisRequestBody = () => {
        var body = {
            filter: {
                date: extractDate(this.state.marginDate),
                directions: []
            }
        };

        this.state.directionTags.forEach(tag => {
            body.filter.directions.push({
                direction: tag.text.split(":")[0],
                counterParty: tag.text.split(":")[1],

            });
        });

        return body;
    }

    setEurTrValueIfExists(data) {
        return 'EUR/TRY' in data ? data['EUR/TRY'].value : '';
    }

    // Show Button
    onShowButtonClick = () => {
        if (this.validateFields()) {

            this.spinner.showSpinner('showButtonClick');

            let requestBody = this.createMarginAnalysisRequestBody();

            getMarginAnalysis(requestBody)
                .then(response => {
                    if (response.data.success) {
                        this.setState({
                            tableData: this.convertJsonToTable(
                                response.data.success.counterPartyList,
                                response.data.success.realized),
                            activaDate: this.state.marginDate,
                            eurTryValue: this.setEurTrValueIfExists(response.data.success),
                            activeFilter: [
                                { label: "Date", value: extractDate(this.state.marginDate) },
                                { label: "Direction", value: [...this.state.directionTags.map(i => i.text)] }
                            ]
                        });

                        if (response.data.error) {
                            let notFoundDirections = [];
                            for (let key in response.data.error.multi) notFoundDirections.push(" " + key);
                            alertError(`${notFoundDirections} data not found.`)
                        }

                    } else {
                        this.setState({ tableData: {} });
                        alertError(portalMessages.MARGIN_ANALYSIS.MARGIN_NOT_FOUND);
                    }

                }, error => {
                    handleApiError(error);
                    this.setState({ tableData: {} });
                })
                .finally(() => {
                    this.spinner.hideSpinner('showButtonClick');
                });
        }
    }

    // TagInput
    onHideModal() {
        this.setState({
            selectedDirection: "",
            selectedCounterParty: "",
            modalShow: false
        })
    }

    generateDirectionTagId(direction, counterParty) {
        return direction.value + ":" + counterParty.value;
    }

    generateDirectionTag(value) {
        return { id: value, text: value };
    }

    addNewDirectionTag() {
        const newDirectionTag = this.generateDirectionTagId(this.state.selectedDirection, this.state.selectedCounterParty);
        const existingDirectionTags = this.state.directionTags.filter(t => t.id === newDirectionTag);

        if (existingDirectionTags.length > 0) {
            alertError(`${this.state.selectedDirection.value}:${this.state.selectedCounterParty.value} is already added.`);
            return;
        }

        this.updateDireactionsState([...this.state.directionTags, this.generateDirectionTag(newDirectionTag)])

        this.updateSelectableValuesForAddition(this.state.selectedDirection.value, this.state.selectedCounterParty.value);

        this.onHideModal();
    }

    updateSelectableValuesForAddition(selectedDirection, selectedCounterParty) {
        let counterParties = this.state.selectableCounterParties;

        const counterPartyArray = counterParties[selectedDirection];

        counterParties[selectedDirection] = counterPartyArray.filter(c => c.value !== selectedCounterParty)

        this.prepareSelectableValues(this.state.selectableDirections, counterParties);
    }

    updateSelectableValuesFullCheck(originalDirections, originalCounterParties, tags) {
        if (!this.state.directionTags || this.state.directionTags.length < 1) {
            this.setState({ selectableDirections: originalDirections, selectableCounterParties: originalCounterParties });
        }

        if (!tags)
            tags = this.state.directionTags;

        let counterParties = { ...originalCounterParties };
        let tagParts;

        tags.forEach(tag => {
            tagParts = tag.text.split(':');
            const counterPartyArray = counterParties[tagParts[0]];
            counterParties[tagParts[0]] = counterPartyArray.filter(c => c.value !== tagParts[1])
        });

        this.prepareSelectableValues(originalDirections, counterParties);
    }

    prepareSelectableValues(previousSelectableDirections, currentSelectableCounterPsrties) {
        const updatedDirections = previousSelectableDirections.filter(dir => currentSelectableCounterPsrties[dir.value].length > 0);
        this.setState({ selectableCounterParties: { ...currentSelectableCounterPsrties }, selectableDirections: [...updatedDirections] });
    }

    updateDireactionsState(newDirectionTags) {
        this.setState({ directionTags: newDirectionTags }, () => {
            setLocalStorage('marginAnalysis', 'tagInput', 'selectedDirections', this.state.directionTags);
        });
    }

    onDirectionTagsChanged(newTags) {
        this.updateDireactionsState([...newTags]);
    }

    getMarkedCountry(country, realizedCountries) {
        if (realizedCountries.filter(c => c === country).length > 0) {
            return `<span>${country}</span>`;
        }
        else {
            if (!this.state.forecastsExist)
                this.setState({ forecastsExist: true });

            return `<span style="color:${this.forecastHeaderColor};" title="Forecasts used for ${country}">${country}</span>`;
        }
    }

    prepareMarkedHeader(direction, realizedCountries) {
        const directionParts = direction.split('-');

        direction = direction.replace(
            directionParts[0],
            this.getMarkedCountry(directionParts[0], realizedCountries));

        direction = direction.replace(
            directionParts[directionParts.length - 1],
            this.getMarkedCountry(directionParts[directionParts.length - 1], realizedCountries));

        return direction;
    }

    // Table
    convertJsonToTable(data, realizedCountries) {
        let headers = [[this.hoursColumn], [this.hoursSubColumn], ['']];
        let values = [];
        let directionData;

        if (this.state.forecastsExist)
            this.setState({ forecastsExist: false });

        let turkeyListed = false;

        for (let i = 1; i < 25; i++) values.push([i]);

        for (let i = 0; i < data.length; i++) {
            if (!turkeyListed && data[i].direction.includes('TR'))
                turkeyListed = true;

            headers[0].push({ name: data[i].direction, html: this.prepareMarkedHeader(data[i].direction, realizedCountries) });
            headers[1].push(data[i].counterParty);
            headers[2].push(this.tableValueFormat);

            directionData = data[i].data;

            for (let j = 0; j < 24; j++) {
                if (!directionData[j])
                    values[j].push('0.0');
                else
                    values[j].push(directionData[j]);
            }
        }

        this.setState({ turkeyListed });

        return {
            headers,
            values
        };
    }

    // Dropdowns
    onDirectionChange = selectedDirection => {
        this.setState({
            selectedDirection: selectedDirection
        });

        if (_.size(this.state.selectableCounterParties[selectedDirection.value]) === 1) {
            this.setState({ selectedCounterParty: this.state.selectableCounterParties[selectedDirection.value][0] });
        } else {
            this.setState({ selectedCounterParty: null });
        }
    }

    onCounterPartyChange = selectedCounterParty => {
        this.setState({
            selectedCounterParty: selectedCounterParty
        });
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Margin Analysis">
                    <VFilterContainer showActiveFilter activeFilter={this.state.activeFilter}>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                            </div>
                            <div>
                                <VDatePicker
                                    maxDate={getDayAheadForGivenDate(new Date())}
                                    selectedDate={this.state.marginDate}
                                    onSelectedDateChange={(selectedDate) => {
                                        this.setState({
                                            marginDate: selectedDate
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Direction
                            </div>
                            <div className="v-filter-taginput">
                                <VTagInput
                                    addButtonText='Add Direction'
                                    onAddButtonClick={() => {
                                        this.setState({
                                            modalShow: true
                                        })
                                    }}
                                    onTagDeleted={(tags) => {
                                        this.onDirectionTagsChanged(tags);
                                        this.updateSelectableValuesFullCheck(this.state.directions, this.state.counterParties, tags);
                                    }}
                                    onOrderChanged={(tags) => this.onDirectionTagsChanged(tags)}
                                    tags={this.state.directionTags}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button className="btn v-button" onClick={this.onShowButtonClick}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                        </div>
                    </VFilterContainer>
                    {!_.isEmpty(this.state.tableData) &&
                        <React.Fragment>
                            <VMainContainer flex>
                                <VTable
                                    staticHeaderButtons={true}
                                    title='Margin'
                                    customColumnClasses={{ 0: "v-column-narrow-bold" }}
                                    exportFileName={`MarginAnalysis_${extractDate(this.state.activaDate)}`}
                                    inputType='number'
                                    items={this.state.tableData}
                                    readonlyColumns={[this.hoursColumn]}
                                    scales={{ '*': 2 }}
                                    headerButtons={{
                                        showExportExcelButton: true
                                    }}
                                />
                            </VMainContainer>
                            {(this.state.forecastsExist || this.state.turkeyListed) &&
                                < div >
                                    <ul className='v-bullet-list' style={{ "paddingLeft": "16px" }}>
                                        {this.state.turkeyListed &&
                                            <li>{`EUR/TRY: ${this.state.eurTryValue}`}</li>
                                        }
                                        {this.state.forecastsExist &&
                                            <li style={{ color: this.forecastHeaderColor }}>{portalMessages.MARGIN_ANALYSIS.FORECAST_GRAY}</li>
                                        }
                                        {this.state.turkeyListed &&
                                            <li>{portalMessages.MARGIN_ANALYSIS.TURKEY_HOURS}</li>
                                        }
                                    </ul>
                                </div>}
                        </React.Fragment>
                    }
                    <NeedRefreshModal
                        show={this.state.showNeedsRefresModal}
                        message={portalMessages.DIRECTIONS_NOT_FOUND}
                    />
                    <VBaseModal
                        dialogClassName="v-modal-30w"
                        show={this.state.modalShow}
                        onHide={() => this.onHideModal()}
                        title='Add Direction'
                        body={(
                            <div className="container">
                                <div className='row'>
                                    <div className='col'>
                                        <label>Direction</label>
                                    </div>
                                    <div className='col-8'>
                                        <VDropdown
                                            width="large"
                                            options={this.state.selectableDirections}
                                            defaultValue={this.state.selectedDirection}
                                            onSelectedOptionChange={this.onDirectionChange}
                                            isSearchable={true}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <label>Counter Party</label>
                                    </div>
                                    <div className='col-8'>
                                        <VDropdown
                                            width="large"
                                            options={_.orderBy(this.state.selectableCounterParties[this.state.selectedDirection.value], ['label'], ['asc'])}
                                            onSelectedOptionChange={this.onCounterPartyChange}
                                            isSearchable={true}
                                            value={this.state.selectedCounterParty}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        actions={(
                            <React.Fragment>
                                <button
                                    type="button"
                                    className="btn v-cancel-button"
                                    onClick={() => { this.onHideModal() }}>
                                    Cancel
                            </button>
                                <button
                                    type="button"
                                    className="btn v-button"
                                    disabled={!this.state.selectedDirection || !this.state.selectedCounterParty}
                                    onClick={() => { this.addNewDirectionTag() }}>
                                    <i aria-hidden="true"
                                        className="fa fa-plus fa-fw" />
                            Add
                            </button>
                            </React.Fragment>
                        )}
                    />
                </VContentContainer>
            </React.Fragment >
        );
    }

}

export default MarginAnalysis;