import React from 'react';
import VFilterContainer from '../../../components/VFilterContainer/VFilterContainer';
import VDatePicker from '../../../components/VDatePicker/VDatePicker';
import VTagInput from '../../../components/VTagInput/VTagInput';
import { AddStyles } from '../../../components/VTagInput/cusomized-react-tag-input/ReactTags';
import VTable from '../../../components/VTable/VTable';
import VDropdown from '../../../components/VDropdown/VDropdown';
import _ from 'lodash';

class TestPnlDetails extends React.Component {
    dataTypesToList = ['Price', 'Monthly Auction Clearing Price', 'Daily Auctions', 'Fees', 'Exchange Rates', 'PnL'];

    state = {
        selectedStartDate: this.getDefaultDate(),
        selectedEndDate: this.getDefaultDate(),
        selectedDirections: [
            { id: 'Test1', text: 'Test1' },
            { id: 'Test2', text: 'Test2' }
        ],
        directionSuggestions: [
            { id: 'Test3', text: 'Test3' },
            { id: 'Test4', text: 'Test4' },
            { id: 'Test5', text: 'Test5' },
            { id: 'Test6', text: 'Test6' },
            { id: 'Test7', text: 'Test7' },
            { id: 'Test8', text: 'Test8' },
            { id: 'Test9', text: 'Test9' },
            { id: 'Test10', text: 'Test10' },
            { id: 'Test11', text: 'Test11' },
            { id: 'Test12', text: 'Test12' }
        ],
        selectedDataTypes: {},
        selectedGrouping: { value: 'Daily', label: 'Daily' },
        selectedCurrency: { value: 'TRY', label: 'TRY' }
    }

    getRandom() {
        return Math.floor(Math.random() * 10000) * [1, -1][Math.floor(Math.random() * 2)] + 1;
    }

    getDefaultDate() {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        today.setDate(today.getDate() + 1);
        return today;
    }

    onDirectionTagsChanged(newTags) {
        this.setState({ directionTags: [...newTags] });
    }

    onselectedDirectionsChanged = currentselectedDirections => {
        this.setState({ selectedDirections: [...currentselectedDirections] });
    }

    addselectedDirectionTag = (newselectedDirections) => {
        this.setState({
            selectedDirections: [...this.state.selectedDirections, newselectedDirections],
            directionSuggestions: this.state.directionSuggestions.filter(i => i['id'] !== newselectedDirections['id'])
        });

    }

    onselectedDirectionRemoved = currentselectedDirections => {

        let removedTag = _.difference(this.state.selectedDirections, currentselectedDirections)[0];

        this.setState({
            selectedDirections: [...currentselectedDirections],
            directionSuggestions: _.orderBy([...this.state.directionSuggestions, removedTag], ['text'], ['asc'])
        });
    }

    getFilters() {
        return (
            <VFilterContainer>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Start Date
                                                </div>
                                        <div>
                                            <VDatePicker
                                                selectedDate={this.state.selectedStartDate}
                                                onSelectedDateChange={(selectedStartDate) => this.setState({ selectedStartDate })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            End Date
                                                </div>
                                        <div>
                                            <VDatePicker
                                                selectedDate={this.state.selectedEndDate}
                                                onSelectedDateChange={(selectedEndDate) => this.setState({ selectedEndDate })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Grouping
                                                </div>
                                        <div>
                                            <VDropdown
                                                width='large'
                                                options={[
                                                    { value: 'Daily', label: 'Daily' },
                                                    { value: 'Weekly', label: 'Weekly' },
                                                    { value: 'Monthly', label: 'Monthly' }
                                                ]}
                                                value={this.state.selectedGrouping}
                                                onSelectedOptionChange={(selectedGrouping) => {
                                                    this.setState({ selectedGrouping })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Currency
                                                </div>
                                        <div>
                                            <VDropdown
                                                width='large'
                                                options={[
                                                    { value: 'TRY', label: 'TRY' },
                                                    { value: 'EUR', label: 'EUR' }
                                                ]}
                                                value={this.state.selectedCurrency}
                                                onSelectedOptionChange={(selectedCurrency) => {
                                                    this.setState({ selectedCurrency })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Border
                                                </div>
                                        <div className="v-filter-taginput">
                                            <VTagInput
                                                addStyle={AddStyles.Textbox}
                                                placeholder='Add Border'
                                                tags={this.state.selectedDirections}
                                                onOrderChanged={this.onselectedDirectionsChanged}
                                                onTagDeleted={this.onselectedDirectionRemoved}
                                                onInternalAddition={this.addselectedDirectionTag}
                                                suggestions={this.state.directionSuggestions}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Counter Party
                                                </div>
                                        <div className="v-filter-taginput">
                                            <VTagInput
                                                addStyle={AddStyles.Textbox}
                                                placeholder='Add Border'
                                                tags={this.state.selectedDirections}
                                                onOrderChanged={this.onselectedDirectionsChanged}
                                                onTagDeleted={this.onselectedDirectionRemoved}
                                                onInternalAddition={this.addselectedDirectionTag}
                                                suggestions={this.state.directionSuggestions}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='col'>
                                <div className="v-filter-group">
                                    <div className="v-filter-label v-label">
                                        Display
                                                </div>
                                </div>
                            </div>
                            {
                                this.dataTypesToList.map(d => {
                                    return (
                                        <div key={d} className="col v-checkBox checkbox">
                                            <label>
                                                <input type="checkbox"
                                                    checked={this.state.selectedDataTypes[d]}
                                                    onChange={() => {
                                                        if (this.state.selectedDataTypes[d])
                                                            this.setState({ selectedDataTypes: { ...this.state.selectedDataTypes, d: !this.state.selectedDataTypes[d] } });
                                                        else
                                                            this.setState({ selectedDataTypes: { ...this.state.selectedDataTypes, d: true } });

                                                    }} />
                                                {d}
                                            </label>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="v-filter-buttons">
                    <button
                        className="btn v-cancel-button v-filter-button">
                        <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                            </button>
                    <button
                        tabIndex={0}
                        className="btn v-button v-filter-button">
                        <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                </div>
            </VFilterContainer>
        );
    }


    getContent() {
        const items = {
            headers: [
                ['Date (CET)', ...this.dataTypesToList.flatMap(d => { return [d, ''] })],
                ['', 'Price 1', 'Price 2', 'Clearing 1', 'Clearing 2', 'Auction 1', 'Auction 2', 'Fee 1', 'Fee 2', 'Rate 1', 'Rate 2', 'Pnl 1', 'Pnl 2'] //13
            ],
            values: []
        };

        for (let i = 0; i < 12; i++) {
            const row = [];
            row.push(i + 1);
            this.dataTypesToList.forEach(() => {
                row.push(this.getRandom());
                row.push(this.getRandom());
            });

            items.values.push(row);
        }

        return (
            <div className='v-infinite-width v-table-list'>
                <VTable
                    title='Summary'
                    items={items}
                    showTotal
                    readonlyColumnIndices={[0]}
                    customColumnClasses={{ 0: "v-column-narrow-bold" }}
                />
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.getFilters()}
                {this.getContent()}
            </React.Fragment>
        )
    }
}

export default TestPnlDetails;