export const generateUuid = () => {
    //source: https://stackoverflow.com/a/2117523

    return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, c =>
        (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
    );
};

export const getFirstMonthOfQuarter = quarter => {

    switch (`${quarter}`) {
        case '1':
            return 1;
        case '2':
            return 4;
        case '3':
            return 7;
        case '4':
            return 10;
        default:
            return 1;
    }
};

export const isDate = d => d instanceof Date && !isNaN(d);

export const getQuarter = date => {

    switch (date.getMonth()) {
        case 0:
        case 1:
        case 2:
            return 1;

        case 3:
        case 4:
        case 5:
            return 2;

        case 6:
        case 7:
        case 8:
            return 3;

        case 9:
        case 10:
        case 11:
            return 4;

        default:
            return 0;
    }
};

export const dayNames = [
    {id: 0, value:0, label: 'Mon'},
    {id: 1, value: 1, label: 'Tue'},
    {id: 2, value: 2, label: 'Wed'},
    {id: 3, value: 3, label: 'Thu'},
    {id: 4, value: 4, label: 'Fri'},
    {id: 5, value: 5, label: 'Sat'},
    {id: 6, value: 6, label: 'Sun'},
];


export const generateArrayOfYears = (yearCount) => {
    let currentYear = new Date().getFullYear()
    let maxYear = currentYear + yearCount
    let years = []
  
    for (var i = currentYear - 1; i <= maxYear; i++) {
      years.push({
          value: i,
          label: i
      })
    }
    
    return years
  }

export const contractTypes = [
    {
        value: "futures",
        label: "Futures"
    },
    {
        value: "options",
        label: "Options"
    }
]

export const convertDateToString = dateTime => {
    return (new Date(dateTime)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
}

export const extractDate = dateTime => {
    return (new Date(dateTime)).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'  });

}