import React from 'react';
import TDropdown from '../../components/TDropdown/TDropdown';
import TextField from '@material-ui/core/TextField';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import TTabs from '../../components/TTabs/TTabs';
import TabPanel from '../../components/TTabs/TabPanel';
import TTable from '../../components/TTable/TTable';
import MultipleSelect from '../../components/MultipleSelect/MultipleSelect';
import FileUploader from '../../components/FileUploader/FileUploader';
import {
    DELIVERY_TYPES, DELIVERY_TYPE_ARR, CONTRACT_TYPES, CONTRACT_TYPE_ARR, TRADING_VENUE, TRADING_VENUE_ARR, PRODUCT, PRODUCT_ARR, TRADER, TRADER_ARR, CONTRACT_DATE_TYPE, CONTRACT_DATE_TYPE_ARR, POSITION,
    PAYMENT_PERIODS, PAYMENT_PERIOD_ARR, PAYMENT_TIME_PERIODS, PAYMENT_TIME_PERIOD_ARR, COLLATERAL_TYPES, COLLATERAL_RATES
} from '../../utils/constants';
import { generateArrayOfYears } from '../../utils/common';
import TDatePicker from '../../components/TDatePicker/TDatePicker';
import { TDatePickerTypes } from '../../components/TDatePicker/TDatePicker';
import { alertError } from '../../../helpers/errorHelper';
import { messages } from '../../utils/messages';
import { differenceInDays } from 'date-fns';
import { getDate } from '../../../helpers/generalHelper';
import CollateralDetail from '../Collateral/CollateralDetail';
import moment from 'moment';
import TConfirmationModal from '../../components/TConfirmationModal/TConfirmationModal';


import DoneIcon from '@material-ui/icons/Done';
import AutomatedRecordedContracts from './AutomatedRecordedContracts';


const MAX_YEAR_COUNT = 10;
const BANK_GUARANTEE_VALID_DAYS = 40

class AutomatedRecordedContractsDetail extends React.Component {
    state = {
        updatedCosts: {},
        originalContract: null,
        api_contract_id: this.props.item.api_contract_id,
        contractId: null,
        tradeId: null,
        contractName: CONTRACT_DATE_TYPE.year.label + ' ' + new Date().getFullYear(),
        contractNameTerm: CONTRACT_DATE_TYPE.year.label,
        selectedCompany: null,
        selectedTradingVenue: null,
        selectedContractType: null,
        selectedDeliveryType: null,
        selectedProduct: null,
        selectedTrader: null,
        selectedBook: null,
        selectedMarket: null,
        selectedCounterParty: null,
        selectedPeriod: null,
        selectedContractDateType: CONTRACT_DATE_TYPE.year,
        selectedPaymentPeriod: PAYMENT_PERIODS.markToMarket,
        selectedPaymentTimePeriod: null,
        selectedPaymentDate: null,
        selectedYear: {
            value: new Date().getFullYear(),
            label: new Date().getFullYear(),
        },
        selectedStartDate: new Date(new Date().getFullYear(), 0, 1),
        selectedEndDate: new Date(new Date().getFullYear() + 1, 0, 1),
        selectedTerm: null,
        selectedContractDate: new Date(),
        selectedContractTypeDay: new Date(),
        position: POSITION.buy,
        price: '',
        amount: '',
        currency: this.props.item?.currency,
        selectedCostItems: this.props.item ? this.props.item.costList : [],
        marketNames: this.props.marketNames,
        bookNames: this.props.bookNames,
        counterPartyNames: this.props.counterPartyNames,
        vitusCompanies: this.props.counterPartyNames?.filter(c => c.type === "Vitus"),
        counterParties: this.props.counterPartyNames?.filter(c => c.type !== "Vitus"),
        periodNames: this.props.periodNames,
        costNames: this.props.costNames,
        documentNames: this.props.documentNames,
        readOnly: true,
        costTabInfo: {
            id: "costs",
            name: "Costs",
            tableColumns: [
                { key: 'name', name: 'Cost', sortable: true, resizable: true },
                { key: 'cost', 
                  name: 'Value', 
                  sortable: true,
                  resizable: true,
                  editor : ({ row }) => {
                     
                      return (
                        <div >
                            <input 
                                type="number" 
                                className="cost-input"
                                onChange={(e) => { this.onChangeCost(e.target.value)} } />
                                <button
                                    size="small"
                                    className="btn cost-save-button"
                                    onClick={() => this.onCostSaved(row)}
                                    disabled={this.state.readOnly}
                                >
                                    <DoneIcon></DoneIcon>
                                </button>
                        </div>
                      )
                  }
                 },
                { key: "currency", name: 'Currency', sortable: true, resizable: true },
            ],
            tableRows: this.props.items ? this.props.item.costList : []
        },
        documentTabInfo: {
            id: "documents",
            name: "Documents",
            tableColumns: [
                { key: 'documentName', name: 'Document', sortable: true, resizable: true }
            ],
            tableRows: this.props.item ? this.props.item.documentList : []
        },
        collateralTabInfo: {
            id: "collateral",
            name: "Collateral",
            tableColumns: [
                { key: 'tradeId', name: 'Trade Id', sortable: true, resizable: true },
                { key: "issuer", name: 'Issuer', sortable: true, resizable: true, width: 150 },
                { key: 'receiver', name: 'Receiver', sortable: true, resizable: true },
                { key: 'bankGuaranteeValidityDate', name: 'Bank Guarantee Validity Date', sortable: true, resizable: true },
                { key: 'collateralWithoutTax', name: 'Collateral Without Tax', sortable: true, resizable: true },
                { key: 'statusOfValidity', name: 'Status of Validity', sortable: true, resizable: true },
                { key: 'remainingDays', name: 'Remaining days', sortable: false, resizable: true },
                { key: "tradingVenue", name: 'Trading Venue', sortable: true, resizable: true, width: 150 },
                { key: "collateralType", name: 'Type', sortable: true, resizable: true },
                { key: 'deliveryType', name: 'Delivery Type', sortable: true, resizable: true },
                { key: 'collateralRate', name: 'Collateral Rate', sortable: true, resizable: true },
            ],
            tableRows: []
        },
        documentFiles: [],
        defaultCollateral: {
            collateralId: null,
            tradeId: '',
            issuer: null,
            receiver: null,
            tradingVenue: null,
            deliveryType: null,
            collateralType: null,
            statusOfValidity: true,
            bankGuaranteeValidityDate: null,
            remainingDays: 0,
            collateralRate: 100,
            collateralWithoutTax: 0
        },
        showSaveConfirmationModal: false
    };

    onChangeCost(cost_value) {
        this.setState({newCostValue: cost_value})
    }

    onCostSaved(row) {
        let cost_value = this.state.newCostValue
        if (cost_value) {
            let existing_costs = {...this.state.updatedCosts}
            existing_costs[row.id] = cost_value

            let tableRows = this.props.item.costList.map(c => {
                if (c.id === row.id) {
                    c["cost"] = cost_value ? cost_value : c.cost
                }
                return c
            })

            this.setState({ 
                updatedCosts: {...existing_costs},
                costTabInfo: { ...this.state.costTabInfo, tableRows: tableRows }
            })
        }
    }

    componentDidMount() {
        if (this.props.item) {
            let collateral = null
            if (this.props.item.collateralList.length > 0){
                let firstCollateral = this.props.item.collateralList[0]
                collateral = {
                    collateralId: firstCollateral.collateralId,
                    tradeId: firstCollateral.tradeId,
                    issuer: {
                        id: firstCollateral.issuerId,
                        name: firstCollateral.issuerName,
                        label: firstCollateral.issuerName
                    },
                    receiver: {
                        id: firstCollateral.receiverId,
                        name: firstCollateral.receiverName,
                        label: firstCollateral.receiverName
                    },
                    selectedTradingVenue: TRADING_VENUE[firstCollateral.tradingVenue],
                    selectedDeliveryType: DELIVERY_TYPES[firstCollateral.deliveryType],
                    selectedCollateralType: COLLATERAL_TYPES[firstCollateral.collateralType],
                    statusOfValidity: firstCollateral.statusOfValidity,
                    bankGuaranteeValidityDate: firstCollateral.bankGuaranteeValidityDate,
                    remainingDays: firstCollateral.remainingDays,
                    collateralRate: firstCollateral.collateralRate,
                    collateralWithoutTax: firstCollateral.collateralWithoutTax
                }
            } 
            this.setState({
                originalContract: this.props.item,
                contractId: this.props.item.id,
                tradeId: this.props.item.tradeId,
                contractName: this.props.item.name,
                selectedCompany: {
                    id: this.props.item.companyId,
                    name: this.props.item.companyName,
                    label: this.props.item.companyName
                },
                selectedContractType: CONTRACT_TYPES[this.props.item.contractType],
                selectedDeliveryType: DELIVERY_TYPES[this.props.item.deliveryType],
                selectedTradingVenue: TRADING_VENUE[this.props.item.tradingVenue],
                selectedMarket: {
                    id: this.props.item.marketId,
                    name: this.props.item.marketName,
                    label: this.props.item.marketName,
                    currency: this.props.item.currency,
                },
                currency: this.props.item.currency,
                selectedCounterParty: {
                    id: this.props.item.counterPartyId,
                    name: this.props.item.counterPartyName,
                    label: this.props.item.counterPartyName,
                    documentCount: this.props.counterPartyNames?.find(x => x.id === this.props.item.counterPartyId)?.documentCount
                },
                selectedPeriod: {
                    id: this.props.item.periodId,
                    name: this.props.item.periodName,
                    label: this.props.item.periodName,
                    hourCount: this.props.item.hourCount,
                    weekdayCount: this.props.item.weekdayCount,
                },
                selectedProduct: PRODUCT[this.props.item.product],
                selectedTrader: TRADER[this.props.item.trader],
                selectedBook: {
                    id: this.props.item.bookId,
                    name: this.props.item.bookName,
                    label: this.props.item.bookName
                },
                selectedContractDateType: CONTRACT_DATE_TYPE[this.props.item.contractDateType],
                selectedPaymentPeriod: this.props.item.paymentPeriod ? PAYMENT_PERIODS[this.props.item.paymentPeriod] : PAYMENT_PERIODS.markToMarket,
                selectedPaymentTimePeriod: PAYMENT_TIME_PERIODS[this.props.item.paymentTimePeriod],
                selectedPaymentDate: this.props.item.paymentDate,
                selectedYear: {
                    value: this.props.item.year,
                    label: this.props.item.year,
                },
                selectedStartDate: this.props.item.startDate,
                selectedEndDate: this.props.item.endDate,
                selectedTerm: this.props.item.term ? { value: this.props.item.term, label: this.props.item.term } : null,
                selectedContractDate: this.props.item.contractDate,
                position: POSITION[this.props.item.position],
                price: this.props.item.price,
                amount: this.props.item.amount,
                defaultCollateral: collateral,
                marketNames: this.props.marketNames,
            })

            this.onChangeCosts(this.props.item.costList)
        }

        this.setState({
            readOnly: this.props.readOnly ? this.props.readOnly : false
        })
    }

    componentDidUpdate() {    
        if (this.state.selectedTradingVenue?.value !== TRADING_VENUE.exchange.value && this.state.selectedPaymentPeriod?.value !== PAYMENT_PERIODS.advenced.value) {
            let newCollateral = {
                ...this.state.defaultCollateral,
                tradingVenue: this.state.selectedTradingVenue ? this.state.selectedTradingVenue.value : null,
                deliveryType: this.state.selectedDeliveryType ? this.state.selectedDeliveryType.value : null,
                collateralRate: this.state.defaultCollateral?.collateralRate ? this.state.defaultCollateral.collateralRate : 100
            }
    
            if (this.state.selectedTradingVenue) {
                if (this.state.selectedTradingVenue === TRADING_VENUE.otc_physical) {
                    newCollateral = {
                        ...newCollateral,
                        collateralType: COLLATERAL_TYPES.letter.value,
                    }
                } else {
                    newCollateral = {
                        ...newCollateral,
                        collateralType: COLLATERAL_TYPES.cash.value,
                    }
                }
    
            }
    
            if (this.state.position === POSITION.buy) {
                newCollateral = {
                    ...newCollateral,
                    issuer: this.state.selectedCompany ? this.state.selectedCompany : null,
                    issuerList: this.state.vitusCompanies,
                    receiver: this.state.selectedCounterParty ? this.state.selectedCounterParty : null,
                    receiverList: this.state.counterParties
                }
            } else {
                newCollateral = {
                    ...newCollateral,
                    issuer: this.state.selectedCounterParty ? this.state.selectedCounterParty : null,
                    issuerList: this.state.counterParties,
                    receiver: this.state.selectedCompany ? this.state.selectedCompany : null,
                    receiverList: this.state.vitusCompanies,
                }
            }
    
            if (this.state.selectedStartDate && this.state.selectedEndDate) {
                let collateralRate = this.state.defaultCollateral?.collateralRate ? this.state.defaultCollateral.collateralRate : 100
                let collateralWithoutTax = this.calculateCollateralWithoutTax(collateralRate)
                let diffInDays = 0
                if (this.state.selectedEndDate > new Date()) {
                    diffInDays = differenceInDays(this.state.selectedEndDate, new Date());
                }
    
                newCollateral = {
                    ...newCollateral,
                    remainingDays: diffInDays,
                    collateralWithoutTax: collateralWithoutTax
                }
            }
    
            if (this.state.selectedEndDate) {
                newCollateral = {
                    ...newCollateral,
                    bankGuaranteeValidityDate: getDate(BANK_GUARANTEE_VALID_DAYS, new Date(this.state.selectedEndDate))
                }
            }
    
            if (JSON.stringify(this.state.defaultCollateral) !== JSON.stringify(newCollateral)) {
                const newCollateralTabInfo = { ...this.state.collateralTabInfo, tableRows: [newCollateral] };
                this.setState({ collateralTabInfo: newCollateralTabInfo, defaultCollateral: newCollateral })
            }
        }
    }

    calculateCollateralWithoutTax(collateralRate) {
        let collateralWithoutTax = 0
        if (this.state.price && this.state.amount && this.state.selectedPeriod) {
            const daysBetweenStartAndEndDate = differenceInDays(this.state.selectedEndDate, this.state.selectedStartDate);
            let hourCount = this.state.periodNames?.find(x => x.id === this.state.selectedPeriod.id)?.hourCount || 0
            if (daysBetweenStartAndEndDate >= 60) {
                collateralWithoutTax = 60 * this.state.amount * hourCount * this.state.price
            } else {
                collateralWithoutTax = (daysBetweenStartAndEndDate + 1) * this.state.amount * hourCount * this.state.price
            }
    
            collateralWithoutTax = collateralWithoutTax * collateralRate / 100
    
        }

        return collateralWithoutTax
    }

    onChangeContractName = contractName => {
        this.setState({ contractName: contractName })
    }

    onChangeTradeId = tradeId => {
        if (this.state.defaultCollateral) {
            this.state.defaultCollateral.tradeId = tradeId
        }
        
        this.setState({tradeId: tradeId, defaultCollateral: this.state.defaultCollateral})
    }

    onChangeCompany = company => {
        this.setState({ selectedCompany: company });
    }

    onChangeDeliveryType = deliveryType => {
        let newCollateral = this.state.defaultCollateral

        if (this.state.defaultCollateral) {
            if (deliveryType.value === DELIVERY_TYPES.financial.value) {
                let collateralWithoutTax = this.calculateCollateralWithoutTax(COLLATERAL_RATES[25].value)
                newCollateral = {
                    ...this.state.defaultCollateral,
                    collateralRate: COLLATERAL_RATES[25].value,
                    collateralWithoutTax: collateralWithoutTax
                }
            }
    
            if (deliveryType.value === DELIVERY_TYPES.physical.value) {
                let collateralWithoutTax = this.calculateCollateralWithoutTax(COLLATERAL_RATES[100].value)
                newCollateral = {
                    ...this.state.defaultCollateral,
                    collateralRate: COLLATERAL_RATES[100].value,
                    collateralWithoutTax: collateralWithoutTax
                }
            }
        }
        
        
        this.setState({ selectedDeliveryType: deliveryType, defaultCollateral: newCollateral })
    }

    onChangeContractType = contractType => {
        this.setState({ selectedContractType: contractType })
    }

    onChangeTradingVenue = tradingVenue => {
        let newState = { selectedTradingVenue: tradingVenue }
        if (tradingVenue.value === TRADING_VENUE.otc_physical.value && !this.state.selectedProduct) {
            newState = { ...newState, selectedProduct: PRODUCT.power }
        }

        if (tradingVenue.value === TRADING_VENUE.exchange.value) {
            //get exchange companies
            newState = {
                ...newState,
                counterParties: this.props.counterPartyNames.filter(x => x.type === TRADING_VENUE.exchange.value),
                selectedPaymentPeriod: PAYMENT_PERIODS.markToMarket,
                defaultCollateral: null
            }
        } else {
            //get not exchange companies
            newState = { ...newState, counterParties: this.props.counterPartyNames.filter(x => x.type !== TRADING_VENUE.exchange.value) }
        }

        if (tradingVenue.value === TRADING_VENUE.exchange.value && !this.state.selectedDeliveryType) {
            newState = { ...newState, selectedDeliveryType: DELIVERY_TYPES.Physical }
        }
        this.setState({ ...newState })
    }

    onChangeMarket = market => {
        this.setState({ selectedMarket: market, currency: market.currency })
    }

    onChangeCounterParty = counterParty => {
        this.setState({
            selectedCounterParty: counterParty,
        });
    }

    onChangeProduct = product => {
        this.setState({ selectedProduct: product })
    }

    onChangeTrader = trader => {
        this.setState({ selectedTrader: trader })
    }

    onChangePeriod = period => {
        this.setState({ selectedPeriod: period })
    }

    onChangeBook = book => {
        this.setState({
            selectedBook: book
        })
    }

    onChangeContractDateType = contractDateType => {
        let newState = { selectedContractDateType: contractDateType }
        let year = this.state.selectedYear.value
        if (contractDateType.value === CONTRACT_DATE_TYPE.year.value) {
            newState = {
                ...newState,
                selectedStartDate: new Date(year, 0, 1),
                selectedEndDate: new Date(year + 1, 0, 1),
                contractNameTerm: contractDateType.label,
                contractName: contractDateType.label + ' ' + year,
                selectedTerm: null
            }
        }else if (contractDateType.value === CONTRACT_DATE_TYPE.half.value) {
            newState = {
                ...newState,
                selectedStartDate: new Date(year, 0, 1),
                selectedEndDate: new Date(year, 6, 1),
                contractNameTerm: contractDateType.label,
                contractName: contractDateType.label + ' ' + year,
                selectedTerm: null
            }

        } else if (contractDateType.value === CONTRACT_DATE_TYPE.half2.value) {
            newState = {
                ...newState,
                selectedStartDate: new Date(year, 6, 1),
                selectedEndDate: new Date(year + 1, 0, 1),
                contractNameTerm: contractDateType.label,
                contractName: contractDateType.label + ' ' + year,
                selectedTerm: null
            }

        } else if (contractDateType.value === CONTRACT_DATE_TYPE.quarter.value) {
            newState = {
                ...newState,
                selectedStartDate: new Date(year, 0, 1),
                selectedEndDate: new Date(year, 3, 1),
                contractNameTerm: contractDateType.label,
                contractName: contractDateType.label + ' ' + year,
                selectedTerm: null
            }

        } else if (contractDateType.value === CONTRACT_DATE_TYPE.quarter2.value) {
            newState = {
                ...newState,
                selectedStartDate: new Date(year, 3, 1),
                selectedEndDate: new Date(year, 6, 1),
                contractNameTerm: contractDateType.label,
                contractName: contractDateType.label + ' ' + year,
                selectedTerm: null
            }

        } else if (contractDateType.value === CONTRACT_DATE_TYPE.quarter3.value) {
            newState = {
                ...newState,
                selectedStartDate: new Date(year, 6, 1),
                selectedEndDate: new Date(year, 9, 1),
                contractNameTerm: contractDateType.label,
                contractName: contractDateType.label + ' ' + year,
                selectedTerm: null
            }

        } else if (contractDateType.value === CONTRACT_DATE_TYPE.quarter4.value) {
            newState = {
                ...newState,
                selectedStartDate: new Date(year, 9, 1),
                selectedEndDate: new Date(year + 1, 0, 1),
                contractNameTerm: contractDateType.label,
                contractName: contractDateType.label + ' ' + year,
                selectedTerm: null
            }

        } else if (contractDateType.value === CONTRACT_DATE_TYPE.month.value) {
            let currentMonth = new Date().getMonth()
            newState = {
                ...newState,
                selectedStartDate: new Date(year, currentMonth, 1),
                selectedEndDate: new Date(year, currentMonth + 1, 1),
                selectedTerm: { value: currentMonth + 1, label: currentMonth + 1 },
                contractNameTerm: new Date(this.state.selectedYear.value, currentMonth, 1).toLocaleString('en-us', { month: 'short' }),
                contractName: new Date(this.state.selectedYear.value, currentMonth, 1).toLocaleString('en-us', { month: 'short' }) + ' ' + year

            }

        } else if (contractDateType.value === CONTRACT_DATE_TYPE.day.value) {
            let currentMonth = new Date().getMonth()
            let currentDay = new Date().getDate() + 1
            newState = {
                ...newState,
                selectedContractTypeDay: new Date(year, currentMonth, currentDay),
                selectedStartDate: new Date(year, currentMonth, currentDay),
                selectedEndDate: new Date(year, currentMonth, currentDay + 1),
                selectedTerm: { value: currentMonth, label: currentMonth },
                contractNameTerm: new Date(this.state.selectedYear.value, currentMonth, currentDay).toLocaleString('en-us', { weekday: 'short', month: 'numeric', day: 'numeric', year: 'numeric' }),
                contractName: new Date(this.state.selectedYear.value, currentMonth, currentDay).toLocaleString('en-us', { weekday: 'short', month: 'numeric', day: 'numeric', year: 'numeric' })

            }
        } else if (contractDateType.value === CONTRACT_DATE_TYPE.bom.value) {
            let bomDate = new Date()
            bomDate.setDate(bomDate.getDate() + 2)
            let bomMonth = bomDate.getMonth()
            newState = {
                ...newState,
                selectedStartDate: new Date(year, bomMonth, bomDate.getDate()),
                selectedEndDate: new Date(year, bomMonth + 1, 1),
                contractNameTerm:  contractDateType.label + ' ' + new Date(year, bomMonth, 1).toLocaleString('en-us', { month: 'short' }),
                contractName: contractDateType.label + ' ' + new Date(year, bomMonth , 1).toLocaleString('en-us', { month: 'short' }) + ' ' + year
            }
        } else if (contractDateType.value === CONTRACT_DATE_TYPE.boy.value) {
            let boyDate = new Date()
            boyDate.setDate(boyDate.getDate() + 2)
            let boyMonth = boyDate.getMonth()
            newState = {
                ...newState,
                selectedStartDate: new Date(year, boyMonth, boyDate.getDate()),
                selectedEndDate: new Date(year + 1, 0, 1),
                contractNameTerm:  contractDateType.label,
                contractName: contractDateType.label + ' ' + year
            }
        } else if (contractDateType.value === CONTRACT_DATE_TYPE.flexible.value) {
            let start = this.state.selectedStartDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
            let end = this.state.selectedEndDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
            newState = {
                ...newState,
                contractNameTerm:  contractDateType.label,
                contractName: contractDateType.label + ' ' + start + ' ' + end
            }
        } else {
            let week = (this.state.selectedTerm) ? this.state.selectedTerm.value : moment(new Date()).week() - 1
            let startDate = new Date(this.getFirstMondayOfWeek(year, week))
            let endDate = new Date(this.getFirstMondayOfWeek(year, week))

            if (contractDateType.value === CONTRACT_DATE_TYPE.week.value) {
                endDate.setDate(startDate.getDate() + 7)
            } else if (contractDateType.value === CONTRACT_DATE_TYPE.weekend.value) {
                startDate.setDate(startDate.getDate() + 5)
                endDate.setDate(startDate.getDate() + 2)
            } else {
                endDate.setDate(startDate.getDate() + 5)
            }

            newState = {
                ...newState,
                selectedStartDate: startDate,
                selectedEndDate: endDate,
                selectedTerm: { value: week, label: week },
                contractNameTerm: contractDateType.label + ' ' + week + ' ',
                contractName: contractDateType.label + ' ' + week + ' ' + year
            }
        }
        this.setState({ ...newState })
    }

    getFirstMondayOfWeek(year, weekNo) {
        var firstMonday = new Date(year, 0, 4, 0, 0, 0, 0);

        while (firstMonday.getDay() !== 1) {
            firstMonday.setDate(firstMonday.getDate() - 1);
        }

        if (1 <= weekNo && weekNo <= 52)
            return firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1));

        firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1));
        if (weekNo === 53 && firstMonday.getDate() >= 22 && firstMonday.getDate() <= 28)
            return firstMonday;
        return null;
    }

    onChangeSelectedContractTypeDay = day => {
        if (day) {
            this.setState({
                selectedContractTypeDay: day,
                selectedStartDate: day,
                selectedEndDate: new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1),
                contractName: day.toLocaleString('en-us', { weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric' })
            })
        } else {
            this.setState({
                selectedContractTypeDay: day
            })
        }

    }

    onChangeTerm = term => {
        let newState = { selectedTerm: term }
        let year = this.state.selectedYear.value

        if (this.state.selectedContractDateType.value === CONTRACT_DATE_TYPE.month.value) {
            let currentMonth = term.value
            newState = {
                ...newState,
                selectedStartDate: new Date(year, currentMonth - 1, 1),
                selectedEndDate: new Date(year, currentMonth, 1),
                contractNameTerm: new Date(this.state.selectedYear.value, currentMonth - 1, 1).toLocaleString('en-us', { month: 'short' }),
                contractName: new Date(this.state.selectedYear.value, currentMonth - 1, 1).toLocaleString('en-us', { month: 'short' }) + '-' + year

            }

        } else {
            let week = (term) ? term.value : moment(new Date()).week()
            let startDate = new Date(this.getFirstMondayOfWeek(year, week))
            let endDate = new Date(this.getFirstMondayOfWeek(year, week))


            if (this.state.selectedContractDateType.value === CONTRACT_DATE_TYPE.week.value) {
                endDate.setDate(startDate.getDate() + 7)
            } else if (this.state.selectedContractDateType.value === CONTRACT_DATE_TYPE.weekend.value ) {
                startDate.setDate(startDate.getDate() + 5)
                endDate.setDate(startDate.getDate() + 2)
            } else {
                endDate.setDate(startDate.getDate() + 5)
            }

            newState = {
                ...newState,
                selectedStartDate: startDate,
                selectedEndDate: endDate,
                contractNameTerm: this.state.selectedContractDateType.label + ' ' + week + ' ',
                contractName: this.state.selectedContractDateType.label + ' ' + week + ' ' + year
            }
        }

        this.setState({ ...newState })
    }

    onChangePaymentPeriod = paymentPeriod => {
        let advancedPaymentState = {}
        if (!paymentPeriod || paymentPeriod.value !== PAYMENT_PERIODS.advenced.value) {
            advancedPaymentState = {
                selectedPaymentTimePeriod: null,
                selectedPaymentDate: null
            }
        }

        if (paymentPeriod && paymentPeriod.value === PAYMENT_PERIODS.advenced.value) {
            advancedPaymentState = {
                ...advancedPaymentState,
                defaultCollateral: null
            }
        }
        this.setState({ ...advancedPaymentState, selectedPaymentPeriod: paymentPeriod })
    }

    onChangePaymentTimePeriod = paymentTimePeriod => {
        this.setState({ selectedPaymentTimePeriod: paymentTimePeriod })
    }

    onChangeYear = year => {
        let newStart = new Date(year.value, 0, 1)
        let newEnd = new Date(year.value + 1, 0, 1)
        
        this.setState({
            selectedYear: year,
            selectedStartDate: newStart,
            selectedEndDate: newEnd,
            contractName: this.state.contractNameTerm + ' ' + year.value
        }, () => {
            if (this.state.selectedTerm) {
                this.onChangeTerm(this.state.selectedTerm);
            }
        });
    }

    onChangeStartDate = startDate => {
        let start = startDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
        let end = this.state.selectedEndDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
            
        this.setState({ selectedStartDate: startDate,  contractName: this.state.selectedContractDateType.label + ' ' + start + ' ' + end })
    }

    onChangeEndDate = endDate => {
        let start = this.state.selectedStartDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
        let end = endDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
 
        this.setState({ selectedEndDate: endDate, contractName: this.state.selectedContractDateType.label + ' ' + start + ' ' + end   })
    }

    onChangeContractDate = contractDate => {
        this.setState({ selectedContractDate: contractDate })
    }

    onChangePrice = price => {
        this.setState({ price: price })
    }

    onChangePaymentDate = date => {
        this.setState({ selectedPaymentDate: date })
    }

    onChangeAmount = amount => {
        this.setState({ amount: amount })
    }

    onChangeCosts = costs => {
        const newCostTabInfo = { ...this.state.costTabInfo, tableRows: costs };
        this.setState({ selectedCostItems: costs, costTabInfo: newCostTabInfo });
    }

    onSellButtonClick() {
        this.setState({ position: POSITION.sell })
    }

    onBuyButtonClick() {
        this.setState({ position: POSITION.buy })
    }

    setFiles = (files) => {
        const newDocumentTabInfo = { ...this.state.documentTabInfo, tableRows: files.map(f => ({ "documentName": f.name })) };
        this.setState({ documentFiles: files, documentTabInfo: newDocumentTabInfo })
    }

    addFiles = (files) => {
        let newFileList = [...this.state.documentFiles, ...files]
        this.setFiles(newFileList)
    }

    onEditButtonClick() {
        this.setState({ readOnly: false })
        this.props.onEditButtonClick(this.state.originalContract)
    }

    onSaveClick(){
        this.setState({showSaveConfirmationModal: true});
    }

    hideSaveConfirmationModal = () => {
        this.setState({ showSaveConfirmationModal: false });
    }

    validateInputs() {
        if (!this.state.contractName) {
            alertError(messages.CONTRACT.EMPTY_CONTRACT_NAME);
            return false;
        }

        if (!this.state.selectedCompany) {
            alertError(messages.CONTRACT.EMPTY_COMPANY);
            return false;
        }

        if (!this.state.selectedTradingVenue) {
            alertError(messages.CONTRACT.EMPTY_TRADING_VENUE);
            return false;
        }

        if (!this.state.selectedContractType) {
            alertError(messages.CONTRACT.EMPTY_CONTRACT_TYPE);
            return false;
        }

        if (!this.state.selectedDeliveryType) {
            alertError(messages.CONTRACT.EMPTY_DELIVERY_TYPE);
            return false;
        }

        if (!this.state.selectedProduct) {
            alertError(messages.CONTRACT.EMPTY_PRODUCT);
            return false;
        }

        if (!this.state.selectedTrader) {
            alertError(messages.CONTRACT.EMPTY_TRADER);
            return false;
        }

        if (!this.state.selectedMarket) {
            alertError(messages.CONTRACT.EMPTY_MARKET);
            return false;
        }

        if (!this.state.selectedBook) {
            alertError(messages.CONTRACT.EMPTY_BOOK);
            return false;
        }

        if (!this.state.selectedContractDateType) {
            alertError(messages.CONTRACT.EMPTY_CONTRACT_DATE_TYPE);
            return false;
        }

        if (this.state.selectedPaymentPeriod && this.state.selectedPaymentPeriod.value === PAYMENT_PERIODS.advenced.value) {
            if (!this.state.selectedPaymentTimePeriod) {
                alertError(messages.CONTRACT.EMPTY_PAYMENT_TIME_PERIOD);
                return false;
            }

            if (!this.state.selectedPaymentDate) {
                alertError(messages.CONTRACT.EMPTY_PAYMENT_DAY);
                return false;
            }
        }

        if (!this.state.selectedYear) {
            alertError(messages.CONTRACT.EMPTY_YEAR);
            return false;
        }

        if (!this.state.selectedStartDate) {
            alertError(messages.CONTRACT.EMPTY_START_DATE);
            return false;
        }

        if (!this.state.selectedEndDate) {
            alertError(messages.CONTRACT.EMPTY_END_DATE);
            return false;
        }

        if (!this.state.selectedContractDate) {
            alertError(messages.CONTRACT.EMPTY_CONTRACT_DATE)
            return false
        }

        if (!this.state.position) {
            alertError(messages.CONTRACT.EMPTY_POSITION);
            return false;
        }

        if (!this.state.price) {
            alertError(messages.CONTRACT.EMPTY_PRICE);
            return false;
        }

        if (!this.state.amount) {
            alertError(messages.CONTRACT.EMPTY_AMOUNT);
            return false;
        }

        if (!this.state.selectedPeriod) {
            alertError(messages.CONTRACT.EMPTY_PERIOD);
            return false;
        }

        return true;
    }

    onSaveButtonClick() {

        this.setState({showSaveConfirmationModal: false})

        if (!this.validateInputs()) {
            return;
        }

        let requestBody = {
            api_contract_id: this.props.item.api_contract_id,
            contract_id: null,
            trade_id: null,
            contract_name: this.state.contractName,
            company_name: this.state.selectedCompany.label,
            company_id: this.state.selectedCompany.id,
            trading_venue: this.state.selectedTradingVenue.value,
            contract_type: this.state.selectedContractType.value,
            delivery_type: this.state.selectedDeliveryType.value,
            product: this.state.selectedProduct.value,
            trader: this.state.selectedTrader.value,
            market_id: this.state.selectedMarket.id,
            market_name: this.state.selectedMarket.name,
            currency: this.state.selectedMarket.currency,
            counter_party_id: this.state.selectedCounterParty.id,
            counter_party_name: this.state.selectedCounterParty.name,
            period_id: this.state.selectedPeriod.id,
            period_name: this.state.selectedPeriod.name,
            book_id: this.state.selectedBook.id,
            book_name: this.state.selectedBook.name,
            contract_date_type: this.state.selectedContractDateType.value,
            payment_period: this.state.selectedPaymentPeriod?.value,
            payment_time_period: this.state.selectedPaymentTimePeriod?.value,
            payment_date: this.state.selectedPaymentDate ? moment(this.state.selectedPaymentDate).utcOffset(0, true).format() : null,
            year: this.state.selectedYear.value,
            start_date: moment(this.state.selectedStartDate).utcOffset(0, true).format(),
            end_date: moment(this.state.selectedEndDate).utcOffset(0, true).format(),
            term: this.state.selectedTerm ? this.state.selectedTerm.value : null,
            contract_date: moment(this.state.selectedContractDate).utcOffset(0, true).format(),
            position: POSITION[this.state.position],
            price: this.state.price,
            amount: this.state.amount,
            cost_ids: this.state.selectedCostItems.map(c => {
                let updatedCost = this.state.updatedCosts[c.id]
                let costDict = {}
                costDict[c.id] = updatedCost ? updatedCost : null
                return costDict
            }),
            cost_list: this.state.selectedCostItems,
            collateral: this.state.defaultCollateral ? {
                collateral_id: null,
                trade_id: null,
                issuer: this.state.defaultCollateral.issuer.id,
                receiver: this.state.defaultCollateral.receiver.id,
                trading_venue: this.state.defaultCollateral.tradingVenue,
                delivery_type: this.state.defaultCollateral.deliveryType,
                collateral_type: this.state.defaultCollateral.collateralType,
                validity_date: moment(this.state.defaultCollateral.bankGuaranteeValidityDate).utcOffset(0, true).format() ,
                remaining_days: this.state.defaultCollateral.remainingDays,
                collateral_without_tax: this.state.defaultCollateral.collateralWithoutTax,
                is_valid: this.state.defaultCollateral.statusOfValidity,
                collateral_rate: this.state.defaultCollateral.collateralRate
            } : null
        }

        const formData = new FormData();
        formData.append('contract_details', JSON.stringify(requestBody))
        formData.append('file_count', this.state.documentFiles.length)

        this.state.documentFiles.forEach((file, index) => {
            formData.append(`file${index}`, file);
        });

        this.setState({ readOnly: true })
        this.props.onSaveButtonClick(formData)
    }

    onCancelButtonClick() {
        if (this.props.item) {
            this.setState({
                contractId: this.state.originalContract.id,
                tradeId: this.state.originalContract.tradeId,
                contractName: this.state.originalContract.name,
                selectedCompany: {
                    id: this.state.originalContract.companyId,
                    name: this.state.originalContract.companyName,
                    label: this.state.originalContract.companyName
                },
                selectedContractType: CONTRACT_TYPES[this.state.originalContract.contractType],
                selectedDeliveryType: DELIVERY_TYPES[this.state.originalContract.deliveryType],
                selectedTradingVenue: TRADING_VENUE[this.state.originalContract.tradingVenue],
                selectedMarket: {
                    id: this.state.originalContract.marketId,
                    name: this.state.originalContract.marketName,
                    label: this.state.originalContract.marketName,
                    currency: this.state.originalContract.currency
                },
                selectedCounterParty: {
                    id: this.state.originalContract.counterPartyId,
                    name: this.state.originalContract.counterPartyName,
                    label: this.state.originalContract.counterPartyName,
                    documentCount: this.props.counterPartyNames.find(x => x.id === this.state.originalContract.counterPartyId)?.documentCount
                },
                selectedPeriod: {
                    id: this.state.originalContract.periodId,
                    name: this.state.originalContract.periodName,
                    label: this.state.originalContract.periodName
                },
                selectedProduct: PRODUCT[this.state.originalContract.product],
                selectedTrader: TRADER[this.state.originalContract.trader],
                selectedBook: {
                    id: this.state.originalContract.bookId,
                    name: this.state.originalContract.bookName,
                    label: this.state.originalContract.bookName
                },
                selectedContractDateType: CONTRACT_DATE_TYPE[this.state.originalContract.contractDateType],
                selectedPaymentPeriod: PAYMENT_PERIODS[this.state.originalContract.paymentPeriod],
                selectedPaymentTimePeriod: PAYMENT_TIME_PERIODS[this.state.originalContract.paymentTimePeriod],
                selectedPaymentDate: this.state.originalContract.paymentDate,
                selectedYear: {
                    value: this.state.originalContract.year,
                    label: this.state.originalContract.year
                },
                selectedStartDate: this.state.originalContract.startDate,
                selectedEndDate: this.state.originalContract.endDate,
                selectedContractDate: this.state.originalContract.contractDate,
                position: POSITION[this.state.originalContract.position],
                price: this.state.originalContract.price,
                amount: this.state.originalContract.amount

            })
        }
        if (this.props.onCancelButtonClick) {
            this.props.onCancelButtonClick(this.state.originalContract)
        } else {
            this.setState({ readOnly: true })
        }
    }

    onDeleteCostClick = (row) => {
        const newCosts = this.state.selectedCostItems.filter(c => c.id !== row.id);
        this.onChangeCosts(newCosts);
    }

    onDeleteDocumentClick = (row) => {
        let newList = this.state.documentFiles.filter(f => f.name !== row.documentName)
        this.setFiles(newList);
    }

    getTermOptions() {
        let termOptions = []
        if (this.state.selectedContractDateType.value === CONTRACT_DATE_TYPE.month.value) {

            for (let i = 1; i <= 12; i++) {
                termOptions.concat()
                termOptions = [...termOptions, { value: i, label: i }]
            }

        } else {
            for (let i = 1; i <= moment(new Date(this.state.selectedYear.value, 11, 25)).week(); i++) {
                termOptions.concat()
                termOptions = [...termOptions, { value: i, label: i }]
            }
        }

        return termOptions
    }

    getCostOptions(){
        let costOptions = []

        let selectedContractDate = this.state.selectedContractDate;
        const contractDate = new Date(selectedContractDate.getFullYear(), selectedContractDate.getMonth(), selectedContractDate.getDate());
        
        if (this.state.costNames?.length > 0){
            this.state.costNames.map(h => {
                if (new Date(h.startDate) <= contractDate && contractDate <= new Date(h.endDate)){
                    costOptions.push(h);
                }
            })
        }
        return costOptions;
    }

    render() {
        return (
            <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ "margin": "12px", display: `${this.state.readOnly && this.props.editable ? "block" : "none"}` }}>
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onEditButtonClick()}
                                disabled={!(this.state.readOnly && this.props.editable)}
                            >
                                Edit
                        </button>
                        </AccordionActions>
                        <Divider />
                    </div>
                    {
                        this.state.tradeId ? (
                            <div style={{ "margin": "12px" }}>
                                <TextField
                                    style={{ width: "50%", margin: "25px" }}
                                    label="Trade Id"
                                    value={this.state.tradeId}
                                    onChange={(e) => this.onChangeTradeId(e.target.value)}
                                    InputProps={{
                                        readOnly: this.state.readOnly,
                                        disableUnderline: this.state.readOnly
                                    }}
                                />
                                <Divider />
                            </div>
                        ) : null
                    }

                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <TextField
                                style={{ width: "50%", margin: "25px" }}
                                label="Contract Name"
                                value={this.state.contractName}
                                onChange={(e) => this.onChangeContractName(e.target.value)}
                                InputProps={{
                                    readOnly: this.state.readOnly,
                                    disableUnderline: this.state.readOnly
                                }}
                            />
                            <TDropdown
                                label='Company'
                                options={this.state.vitusCompanies}
                                width="large"
                                value={this.state.selectedCompany}
                                defaultValue={this.state.selectedCompany}
                                onSelectedOptionChange={this.onChangeCompany}
                                disabled={this.state.readOnly}
                            />
                            <TDropdown
                                label='Trading Venue'
                                options={TRADING_VENUE_ARR}
                                width="large"
                                value={this.state.selectedTradingVenue}
                                defaultValue={this.state.selectedTradingVenue}
                                onSelectedOptionChange={this.onChangeTradingVenue}
                                disabled={this.state.readOnly}
                            />
                            <TDropdown
                                label='Contract Type'
                                options={CONTRACT_TYPE_ARR}
                                width="large"
                                value={this.state.selectedContractType}
                                defaultValue={this.state.selectedContractType}
                                onSelectedOptionChange={this.onChangeContractType}
                                disabled={this.state.readOnly}
                            />
                            <TDropdown
                                label='Delivery Type'
                                options={DELIVERY_TYPE_ARR}
                                width="large"
                                value={this.state.selectedDeliveryType}
                                defaultValue={this.state.selectedDeliveryType}
                                onSelectedOptionChange={this.onChangeDeliveryType}
                                disabled={this.state.readOnly}
                            />

                            <TDropdown
                                label='Product'
                                options={PRODUCT_ARR}
                                width="large"
                                value={this.state.selectedProduct}
                                defaultValue={this.state.selectedProduct}
                                onSelectedOptionChange={this.onChangeProduct}
                                disabled={this.state.readOnly}
                            />

                            <TDropdown
                                label='Book'
                                options={this.state.bookNames}
                                width="large"
                                value={this.state.selectedBook}
                                defaultValue={this.state.selectedBook}
                                onSelectedOptionChange={this.onChangeBook}
                                disabled={this.state.readOnly}
                            />

                            <TDropdown
                                label='Market'
                                options={this.state.marketNames}
                                width="large"
                                value={this.state.selectedMarket}
                                defaultValue={this.state.selectedMarket}
                                onSelectedOptionChange={this.onChangeMarket}
                                disabled={this.state.readOnly}
                            />

                            <TDropdown
                                label='Counter Party'
                                options={this.state.counterParties}
                                width="large"
                                value={this.state.selectedCounterParty}
                                defaultValue={this.state.selectedCounterParty}
                                onSelectedOptionChange={this.onChangeCounterParty}
                                disabled={this.state.readOnly}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <TDatePicker
                                type={TDatePickerTypes.time}
                                label={"Contract Date"}
                                selectedDate={this.state.selectedContractDate}
                                onSelectedDateChange={this.onChangeContractDate}
                                disabled={this.state.readOnly}
                            />

                            <TDropdown
                                label='Trader'
                                options={TRADER_ARR}
                                width="small"
                                value={this.state.selectedTrader}
                                defaultValue={this.state.selectedTrader}
                                onSelectedOptionChange={this.onChangeTrader}
                                disabled={this.state.readOnly}
                            />

                            <TDropdown
                                label='Period'
                                options={this.state.periodNames}
                                width="small"
                                value={this.state.selectedPeriod}
                                defaultValue={this.state.selectedPeriod}
                                onSelectedOptionChange={this.onChangePeriod}
                                disabled={this.state.readOnly}
                            />

                            <TDropdown
                                label='Contract Date Type'
                                options={CONTRACT_DATE_TYPE_ARR}
                                width="small"
                                value={this.state.selectedContractDateType}
                                defaultValue={this.state.selectedContractDateType}
                                onSelectedOptionChange={this.onChangeContractDateType}
                                disabled={this.state.readOnly}
                            />
                            {
                                (this.state.selectedContractDateType?.hasTerm) ?
                                    (
                                        <TDropdown
                                            label='Term'
                                            options={this.getTermOptions()}
                                            width="small"
                                            value={this.state.selectedTerm}
                                            defaultValue={this.state.selectedTerm}
                                            onSelectedOptionChange={this.onChangeTerm}
                                            disabled={this.state.readOnly}
                                        />
                                    ) : null

                            }
                            {
                                (this.state.selectedContractDateType?.hasDateSelect) ?
                                    (
                                        <TDatePicker
                                            type={TDatePickerTypes.time}
                                            label={"Day"}
                                            selectedDate={this.state.selectedContractTypeDay}
                                            onSelectedDateChange={this.onChangeSelectedContractTypeDay}
                                            disabled={this.state.readOnly}
                                        />
                                    ) : null

                            }

                            <TDropdown
                                label='Year'
                                options={generateArrayOfYears(MAX_YEAR_COUNT)}
                                width="small"
                                value={this.state.selectedYear}
                                defaultValue={this.state.selectedYear}
                                onSelectedOptionChange={this.onChangeYear}
                                disabled={this.state.readOnly}
                            />
                            <TDatePicker
                                type={TDatePickerTypes.time}
                                label={"Start Date"}
                                selectedDate={this.state.selectedStartDate}
                                onSelectedDateChange={this.onChangeStartDate}
                                disabled={this.state.readOnly}
                            />

                            <TDatePicker
                                type={TDatePickerTypes.time}
                                label={"End Date"}
                                selectedDate={this.state.selectedEndDate}
                                onSelectedDateChange={this.onChangeEndDate}
                                disabled={this.state.readOnly}
                            />
                            <div className="t-position-div">
                                <div className="t-position-label">Position</div>
                                <div>
                                    <div
                                        className={`btn t-buy-button ${this.state.position !== POSITION.buy ? "unselect-buy" : ""}`}
                                        onClick={() => !this.state.readOnly ? this.onBuyButtonClick() : undefined}
                                        disabled={this.state.readOnly}
                                    >
                                        Buy
                                    </div>
                                    <div
                                        className={`btn t-sell-button ${this.state.position !== POSITION.sell ? "unselect-sell" : ""}`}
                                        onClick={() => !this.state.readOnly ? this.onSellButtonClick() : undefined}
                                        disabled={this.state.readOnly}
                                    >
                                        Sell
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <TextField
                                    style={{ width: "50%", margin: "20px 0px 0px 25px" }}
                                    label={this.state.selectedMarket ? `Price (${this.state.selectedMarket?.currency})` : "Price"}
                                    value={this.state.price}
                                    onChange={(e) => this.onChangePrice(e.target.value)}
                                    InputProps={{
                                        readOnly: this.state.readOnly,
                                        disableUnderline: this.state.readOnly
                                    }}
                                    type="number"
                                />

                                <TextField
                                    style={{ width: "50%", margin: "20px 0px 0px 25px" }}
                                    label="Amount"
                                    value={this.state.amount}
                                    onChange={(e) => this.onChangeAmount(e.target.value)}
                                    InputProps={{
                                        readOnly: this.state.readOnly,
                                        disableUnderline: this.state.readOnly
                                    }}
                                    type="number"
                                />
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "row", margin: "20px 0px 0px 0px" }}>
                        <TDropdown
                            label='Payment Period'
                            options={PAYMENT_PERIOD_ARR}
                            width="large"
                            value={this.state.selectedPaymentPeriod}
                            defaultValue={this.state.selectedPaymentPeriod}
                            onSelectedOptionChange={this.onChangePaymentPeriod}
                            disabled={this.state.readOnly}
                            isClearable={true}
                            
                        />

                        <div style={{ display: `${!this.state.selectedPaymentPeriod || this.state.selectedPaymentPeriod?.value === PAYMENT_PERIODS.efet.value || this.state.selectedPaymentPeriod?.value === PAYMENT_PERIODS.markToMarket.value ? "none" : "flex"}`, flexDirection: "row" }}>
                            <TDropdown
                                label='Time Period'
                                options={PAYMENT_TIME_PERIOD_ARR}
                                width="small"
                                value={this.state.selectedPaymentTimePeriod}
                                defaultValue={this.state.selectedPaymentTimePeriod}
                                onSelectedOptionChange={this.onChangePaymentTimePeriod}
                                disabled={this.state.readOnly}
                            />
                            <TDatePicker
                                type={TDatePickerTypes.time}
                                label={"Payment Date"}
                                selectedDate={this.state.selectedPaymentDate}
                                onSelectedDateChange={this.onChangePaymentDate}
                                disabled={this.state.readOnly}
                            />
                        </div>
                    </div>
                    <div style={{ margin: "35px 10px 12px 0px" }}>
                        <TTabs
                            key={`${this.state.contractId}_ttabs_contract_detail_key"`}
                            defaultSelectedTabId={this.state.costTabInfo.id}
                            deleteButtonDisabled={true}
                        >
                            {
                                [this.state.costTabInfo, this.state.documentTabInfo, this.state.collateralTabInfo].map((t) => {
                                    return (
                                        t.id === "costs" ?
                                            <TabPanel key={`${t.id}_tabpanel`} title={t.name} id={t.id}>
                                                <MultipleSelect
                                                    readOnly={this.state.readOnly}
                                                    title="Select Cost"
                                                    items={this.getCostOptions()}
                                                    onChange={this.onChangeCosts}
                                                    selectedItems={this.state.selectedCostItems}
                                                >
                                                </MultipleSelect>
                                                <TTable
                                                    height="200px"
                                                    rows={t.tableRows}
                                                    columns={t.tableColumns}
                                                    showDeleteRow={!this.state.readOnly}
                                                    onDeleteRowClick={this.onDeleteCostClick}
                                                ></TTable>
                                            </TabPanel>
                                            :
                                            t.id === "documents" ?
                                                <TabPanel key={`${t.id}_tabpanel`} title={t.name} id={t.id}>
                                                    {this.state.selectedCounterParty && this.state.selectedCounterParty.documentCount ? <label>{`Company have ${this.state.selectedCounterParty.documentCount} documents.`}</label> : null}
                                                    {
                                                        !this.state.readOnly ? (
                                                            <FileUploader
                                                                addFiles={this.addFiles}
                                                            >
                                                            </FileUploader>
                                                        ) : null
                                                    }
                                                    <TTable
                                                        height="200px"
                                                        rows={t.tableRows}
                                                        columns={t.tableColumns}
                                                        showDeleteRow={!this.state.readOnly}
                                                        onDeleteRowClick={this.onDeleteDocumentClick}
                                                    ></TTable>
                                                </TabPanel>
                                                :
                                                <TabPanel key={`${t.id}_tabpanel`} title={t.name} id={t.id}>

                                                    {
                                                        this.state.selectedTradingVenue?.value !== TRADING_VENUE.exchange.value && this.state.selectedPaymentPeriod?.value !== PAYMENT_PERIODS.advenced.value ? (
                                                            <CollateralDetail
                                                                key={`${t.key}_CollateralDetail`}
                                                                item={this.state.defaultCollateral}
                                                                displayAddEditButtons={false}
                                                                readOnly={this.state.readOnly}
                                                                counterPartyNames={this.state.counterPartyNames}
                                                                displayRemainingDays={false}
                                                            >
                                                            </CollateralDetail>
                                                        ) : null
                                                    }

                                                </TabPanel>
                                    )
                                })
                            }
                        </TTabs>
                    </div>
                    <div style={{ "margin": "12px", display: `${this.state.readOnly ? "none" : "block"}` }}>
                        <Divider />
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-gray-button"
                                onClick={() => this.onCancelButtonClick()}
                            >
                                Cancel
                            </button>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onSaveClick()}
                                disabled={this.state.readOnly}
                            >
                                Save
                            </button>
                        </AccordionActions>
                        {
                        this.state.showSaveConfirmationModal &&
                        <TConfirmationModal
                            show={this.state.showSaveConfirmationModal}
                            message={messages.CONTRACT.CONFIRM_SAVE.replace("[CONTRACT]", this.state.contractName)}
                            cancelText='Cancel'
                            onHide={this.hideSaveConfirmationModal}
                            onCancel={this.hideSaveConfirmationModal}
                            confirmText='Confirm'
                            onConfirm={() => this.onSaveButtonClick()}
                        />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default AutomatedRecordedContractsDetail;