import './CostDetailModal.css';

import React, { useState } from 'react';

import TBaseModal from '../../components/TBaseModal/TBaseModal';
import TDropdown from '../../components/TDropdown/TDropdown';
import TDatePicker from '../../components/TDatePicker/TDatePicker';
import { CURRENCIES, CURRENCY_ARR, COST_TYPES, COST_CALCULATION_TYPES, BILLING_PERIODS, COST_OPERATION_TYPES } from '../../utils/constants';
import { alertError } from '../../utils/errorHandler';
import { messages } from '../../utils/messages';
import { convertDateToString } from '../../utils/common';


const MAX_COST = 3
const MIN_COST = 3


const CostDetailModal = ({
    selectedCost,
    show,
    onCancel,
    onHide,
    brokerList,
    countryList,
    onSaveCost }) => {
    const [costType, setCostType] = useState(selectedCost ? COST_TYPES.find(i => i.label === selectedCost.cost_type) : '');
    const [broker, setBroker] = useState(selectedCost ? brokerList.find(i => i.label === selectedCost.broker) : '');
    const [country, setCountry] = useState(selectedCost ?  countryList.find(i => i.value === selectedCost.country_id) : '');
    const [cost, setCost] = useState(selectedCost ? selectedCost.cost : 0);
    const [currency, setCurrency] = useState(selectedCost ? CURRENCIES[selectedCost.currency] : '');
    const [costCalculation, setCostCalculation] = useState(selectedCost ? COST_CALCULATION_TYPES.find(i => i.label === selectedCost.cost_calculation) : '');
    const [billingPeriod, setBillingPeriod] = useState(selectedCost ? BILLING_PERIODS.find(i => i.label === selectedCost.billing_period) : '');
    const [costOpertionType, setCostOperationType] = useState(selectedCost ? COST_OPERATION_TYPES.find(i => i.label === selectedCost.cost_operation_type) : '');
    const [costStartDate, setCostStartDate] = useState(selectedCost ? new Date(selectedCost.cost_start_date) : '');
    const [costEndDate, setCostEndDate] = useState(selectedCost ? new Date(selectedCost.cost_end_date) : '');


    const validateInputs = () => {

        if (!costType) {
            alertError(messages.COST.EMPTY_COST_TYPE);
            return;
        }

        if (!broker) {
            alertError(messages.COST.EMPTY_BROKER);
            return;
        }

        if (!country) {
            alertError(messages.COST.EMPTY_COUNTRY);
            return;
        }

        if (!cost) {
            alertError(messages.COST.EMPTY_COST);
            return;
        }

        if (!currency) {
            alertError(messages.COST.EMPTY_CURRENCY);
            return;
        }

        if (!costCalculation) {
            alertError(messages.COST.EMPTY_COST_CALCULATION);
            return;
        }

        if (!billingPeriod) {
            alertError(messages.COST.EMPTY_BILLING_PERIOD);
            return;
        }

        if (!costOpertionType) {
            alertError(messages.COST.EMPTY_COST_OPERATION_TYPE);
            return;
        }

        if (!costStartDate) {
            alertError(messages.COST.EMPTY_COST_START_DATE);
            return;
        }

        if (!costEndDate) {
            alertError(messages.COST.EMPTY_COST_END_DATE);
            return;
        }

        if (costStartDate > costEndDate) {
            alertError(messages.COST.INVALID_DATES);
            return;
        }

        onSaveCost(
            selectedCost ? selectedCost.cost_id : null,
            costType.value,
            broker.id,
            country.value,
            cost,
            currency.value,
            costCalculation.value,
            billingPeriod.value,
            costOpertionType.value,
            convertDateToString(costStartDate),
            convertDateToString(costEndDate)
            );

        return;
    }

    return (
        <TBaseModal
            dialogClassName="t-modal-90w"
            show={show}
            onHide={onHide}
            title="Cost Details"
            closeButton={false}
            body={
                <div className="container">
                    <div className="row">
                        <div className="col t-cost-detail-label-container">
                            <label className="t-font f-font-size t-cost-detail-labels">Cost Type</label>
                        </div>
                        <div className="col">
                            <TDropdown
                                width="large"
                                options={COST_TYPES}
                                defaultValue={costType}
                                onSelectedOptionChange={(costType) => setCostType(costType)}
                                isSearchable={false}
                            />
                        </div>
                        <div className="col t-cost-detail-label-container">
                            <label className="t-font f-font-size t-cost-detail-labels">Broker</label>
                        </div>
                        <div className="col">
                            <TDropdown
                                width="large"
                                options={brokerList}
                                defaultValue={broker}
                                onSelectedOptionChange={(broker) => setBroker(broker)}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col t-cost-detail-label-container">
                            <label className="t-font f-font-size t-cost-detail-labels">Country</label>
                        </div>
                        <div className="col">
                            <TDropdown
                                width="large"
                                options={countryList}
                                defaultValue={country}
                                onSelectedOptionChange={(country) => setCountry(country)}
                                isSearchable={true}
                            />
                        </div>
                        <div className="col t-cost-detail-label-container">
                            <label className="t-font f-font-size t-cost-detail-labels">Cost Calculation</label>
                        </div>
                        <div className="col">
                            <TDropdown
                                width="large"
                                options={COST_CALCULATION_TYPES}
                                defaultValue={costCalculation}
                                onSelectedOptionChange={(costCalculation) => setCostCalculation(costCalculation)}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col t-cost-detail-label-container">
                            <label className="t-font f-font-size t-cost-detail-labels">Cost</label>
                        </div>
                        <div className="col">
                            <input className="t-input" min={MIN_COST} max={MAX_COST} type="number" value={cost} onChange={(e) => setCost(e.target.value)} />
                        </div>
                        <div className="col t-cost-detail-label-container">
                            <label className="t-font f-font-size t-cost-detail-labels">Currency</label>
                        </div>
                        <div className="col">
                            <TDropdown
                                width="large"
                                options={CURRENCY_ARR}
                                defaultValue={currency}
                                onSelectedOptionChange={(currency) => setCurrency(currency)}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col t-cost-detail-label-container">
                            <label className="t-font f-font-size t-cost-detail-labels">Cost Start Date</label>
                        </div>
                        <div className="col">
                            <TDatePicker
                                selectedDate={costStartDate}
                                onSelectedDateChange={(costStartDate) => setCostStartDate(costStartDate)}
                                disabled={false}
                            />
                        </div>
                        <div className="col t-cost-detail-label-container">
                            <label className="t-font f-font-size t-cost-detail-labels">Cost End Date</label>
                        </div>
                        <div className="col">
                            <TDatePicker
                                selectedDate={costEndDate}
                                onSelectedDateChange={(costEndDate) => setCostEndDate(costEndDate)}
                                disabled={false}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col t-price-curve-label-container">
                            <label className="t-font f-font-size t-price-curve-labels">Cost Operation Type</label>
                        </div>
                        <div className="col">
                            <TDropdown
                                width="large"
                                options={COST_OPERATION_TYPES}
                                defaultValue={costOpertionType}
                                onSelectedOptionChange={(costOpertionType) => setCostOperationType(costOpertionType)}
                                isSearchable={false}
                            />
                        </div>
                        <div className="col t-price-curve-label-container">
                            <label className="t-font f-font-size t-price-curve-labels">Billing Period</label>
                        </div>
                        <div className="col">
                            <TDropdown
                                width="large"
                                options={BILLING_PERIODS}
                                defaultValue={billingPeriod}
                                onSelectedOptionChange={(billingPeriod) => setBillingPeriod(billingPeriod)}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                </div>
            }
            actions={
                <React.Fragment>
                    <button
                        type="button"
                        className="btn t-gray-button"
                        onClick={onCancel}>
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn t-orange-button"
                        onClick={validateInputs}>
                        Save
                    </button>
                </React.Fragment >
            }
        />
    );
}

CostDetailModal.defaultProps = {
    selectedCost: null
};

export default CostDetailModal;