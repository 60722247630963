import React from 'react';
import TDropdown from '../../components/TDropdown/TDropdown';
import TextField from '@material-ui/core/TextField';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import { alertError } from '../../../helpers/errorHelper';
import { messages } from '../../utils/messages';
import { DELIVERY_TYPE_ARR, COLLATERAL_TYPES, COLLATERAL_TYPE_ARR, TRADING_VENUE, TRADING_VENUE_ARR, DELIVERY_TYPES, COLLATERAL_RATES, COLLATERAL_RATE_ARR } from '../../utils/constants';
import TDatePicker from '../../components/TDatePicker/TDatePicker';
import { TDatePickerTypes } from '../../components/TDatePicker/TDatePicker';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { differenceInDays } from 'date-fns';
import moment from 'moment';




class CollateralDetail extends React.Component {

    state = {
        originalCollateral: null,
        id: null,
        tradeId: '',
        selectedIssuer: this.props.item ? this.props.item.issuer : null,
        selectedReceiver: this.props.item ? this.props.item.receiver : null,
        selectedTradingVenue: null,
        selectedDeliveryType: null,
        selectedCollateralType: null,
        selectedValidityDate: null,
        collateralWithoutTax: 0,
        isValid: true,
        remainingDays: 0,
        selectedCollateralRate: COLLATERAL_RATES[100],
        readOnly: true,
        displayAddEditButtons: false,
        issuerList: this.props.counterPartyNames ? this.props.counterPartyNames : [],
        receiverList: this.props.counterPartyNames ? this.props.counterPartyNames : []
    }

    componentDidMount() {
        if (this.props.item) {

            this.setState({
                originalCollateral: this.props.item,
                id: this.props.item.id,
                tradeId: this.props.item.tradeId,
                selectedIssuer: this.props.item.issuer,
                selectedReceiver: this.props.item.receiver,
                selectedDeliveryType: DELIVERY_TYPES[this.props.item.deliveryType],
                selectedTradingVenue: TRADING_VENUE[this.props.item.tradingVenue],
                selectedCollateralType: COLLATERAL_TYPES[this.props.item.collateralType],
                selectedValidityDate: this.props.item.bankGuaranteeValidityDate,
                collateralWithoutTax: this.props.item.collateralWithoutTax ? this.props.item.collateralWithoutTax : 0,
                isValid: !this.props.item.statusOfValidity ? false : true,
                remainingDays: this.props.item.remainingDays ? this.props.item.remainingDays : 0,
                selectedCollateralRate: this.props.item.collateralRate ? COLLATERAL_RATES[this.props.item.collateralRate] : COLLATERAL_RATES[100],
                issuerList: this.props.item.issuerList ? this.props.item.issuerList : this.state.issuerList,
                receiverList: this.props.item.receiverList ? this.props.item.receiverList : this.state.receiverList
            })
        }

        this.setState({
            readOnly: this.props.readOnly ? this.props.readOnly : false,
            displayAddEditButtons: this.props.displayAddEditButtons ? this.props.displayAddEditButtons : false
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.item !== prevProps.item) {
            this.componentDidMount();
        }
    }

    onChangeTradeId = tradeId => {
        this.setState({ tradeId: tradeId })
    }

    onChangeIssuer = issuer => {
        this.setState({ selectedIssuer: issuer })
    }

    onChangeReceiver = receiver => {
        this.setState({ selectedReceiver: receiver })
    }

    onChangeTradingVenue = tradingVenue => {
        this.setState({ selectedTradingVenue: tradingVenue })
    }

    onChangeDeliveryType = deliveryType => {
        this.setState({ selectedDeliveryType: deliveryType })
    }

    onChangeValidityDate = validityDate => {
        const diffInDays = differenceInDays(validityDate, new Date());
        this.setState({ selectedValidityDate: validityDate, remainingDays: diffInDays + 1 })
    }

    onChangeCollateralType = collateralType => {
        this.setState({ selectedCollateralType: collateralType })
    }

    onChangeCollateralRate = collateralRate => {
        let newcollateralWithoutTax = this.state.collateralWithoutTax
        if (collateralRate) {
            newcollateralWithoutTax = this.state.collateralWithoutTax * 100 / this.state.selectedCollateralRate.value * collateralRate.value / 100
            this.setState({ selectedCollateralRate: collateralRate, collateralWithoutTax: newcollateralWithoutTax })
        } else {
            newcollateralWithoutTax = this.state.collateralWithoutTax * 100 / this.state.selectedCollateralRate.value * 100 / 100
            this.setState({ collateralWithoutTax: newcollateralWithoutTax })
        }
    }

    onChangeCollateral = collateral => {
        this.setState({ collateralWithoutTax: collateral })
    }

    validateInputs() {
        if (!this.state.selectedIssuer) {
            alertError(messages.COLLATERAL.EMPTY_ISSUER);
            return false;
        }

        if (!this.state.selectedReceiver) {
            alertError(messages.COLLATERAL.EMPTY_RECEIVER);
            return false;
        }

        if (!this.state.selectedTradingVenue) {
            alertError(messages.COLLATERAL.EMPTY_TRADING_VENUE);
            return false;
        }

        if (!this.state.selectedDeliveryType) {
            alertError(messages.COLLATERAL.EMPTY_DELIVERY_TYPE);
            return false;
        }

        if (!this.state.selectedCollateralType) {
            alertError(messages.COLLATERAL.EMPTY_COLLATERAL_TYPE);
            return false;
        }

        if (!this.state.selectedValidityDate) {
            alertError(messages.COLLATERAL.EMPTY_VALID_DATE);
            return false;
        }

        if (!this.state.collateralRate) {
            alertError(messages.COLLATERAL.EMPTY_COLLATERAL_RATE);
            return false;
        }

        if (!this.state.collateralWithoutTax) {
            alertError(messages.COLLATERAL.EMPTY_COLLATERAL_VALUE);
            return false;
        }

        return true;
    }

    onSaveButtonClick() {
        if (!this.validateInputs()) {
            return;
        }

        let requestBody = {
            collateral_id: this.state.id,
            trade_id: this.state.tradeId ? this.state.tradeId : null,
            issuer: this.state.selectedIssuer.id,
            issuer_name: this.state.selectedIssuer.label,
            receiver: this.state.selectedReceiver.id,
            receiver_name: this.state.selectedReceiver.label,
            trading_venue: this.state.selectedTradingVenue.value,
            delivery_type: this.state.selectedDeliveryType.value,
            collateral_type: this.state.selectedCollateralType.value,
            validity_date: moment(this.state.selectedValidityDate).utcOffset(0, true).format(),
            validity_date_withtz: this.state.selectedValidityDate,
            remaining_days: this.state.remainingDays,
            contract_date: this.state.selectedContractDate,
            collateral_without_tax: this.state.collateralWithoutTax,
            is_valid: this.state.isValid,
            collateral_rate: this.state.selectedCollateralRate.value
        }

        this.setState({ readOnly: true })
        this.props.onSaveButtonClick(requestBody)
    }

    onCancelButtonClick() {
        if (this.props.item) {
            this.setState({
                id: this.state.originalCollateral.id,
                tradeId: this.state.originalCollateral.tradeId,
                selectedIssuer: this.state.originalCollateral.issuer,
                selectedReceiver: this.state.originalCollateral.receiver,
                selectedDeliveryType: DELIVERY_TYPES[this.state.originalCollateral.deliveryType],
                selectedTradingVenue: TRADING_VENUE[this.state.originalCollateral.tradingVenue],
                selectedValidityDate: this.state.originalCollateral.validDate,
                collateralWithoutTax: this.state.originalCollateral.collateralWithoutTax,
                isValid: this.state.originalCollateral.statusOfValidity,
                remainingDays: this.state.originalCollateral.remainingDays,
                selectedCollateralRate: COLLATERAL_RATES[this.state.originalCollateral.collateralRate]
            })
        }
        if (this.props.onCancelButtonClick) {
            this.props.onCancelButtonClick(this.state.originalCollateral)
        } else {
            this.setState({ readOnly: true })
        }
    }

    onEditButtonClick() {
        this.setState({ readOnly: false })
        this.props.onEditButtonClick(this.state.originalCollateral)
    }

    render() {
        return (
            <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {
                        this.state.displayAddEditButtons ? (
                            <div style={{ "margin": "12px", display: `${this.state.readOnly ? "block" : "none"}` }}>
                                <AccordionActions>
                                    <button
                                        size="small"
                                        className="btn t-orange-button"
                                        onClick={() => this.onEditButtonClick()}
                                        disabled={!this.state.readOnly}
                                    >
                                        Edit
                                    </button>
                                </AccordionActions>
                                <Divider />
                            </div>
                        ) : null
                    }
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <TextField
                                style={{ width: "50%", margin: "25px" }}
                                label="Trade Id"
                                value={this.state.tradeId ? this.state.tradeId : ''}
                                onChange={(e) => this.onChangeTradeId(e.target.value)}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                            <TDropdown
                                label='Issuer'
                                options={this.state.issuerList}
                                width="large"
                                value={this.state.selectedIssuer}
                                defaultValue={this.state.selectedIssuer}
                                onSelectedOptionChange={this.onChangeIssuer}
                                disabled={this.state.readOnly}
                            />
                            <TDropdown
                                label='Receiver'
                                options={this.state.receiverList}
                                width="large"
                                value={this.state.selectedReceiver}
                                defaultValue={this.state.selectedReceiver}
                                onSelectedOptionChange={this.onChangeReceiver}
                                disabled={this.state.readOnly}
                            />
                            <TDropdown
                                label='Trading Venue'
                                options={TRADING_VENUE_ARR}
                                width="large"
                                value={this.state.selectedTradingVenue}
                                defaultValue={this.state.selectedTradingVenue}
                                onSelectedOptionChange={this.onChangeTradingVenue}
                                disabled={this.state.readOnly}
                            />
                            <TDropdown
                                label='Delivery Type'
                                options={DELIVERY_TYPE_ARR}
                                width="large"
                                value={this.state.selectedDeliveryType}
                                defaultValue={this.state.selectedDeliveryType}
                                onSelectedOptionChange={this.onChangeDeliveryType}
                                disabled={this.state.readOnly}
                            />
                            <TDropdown
                                label='Type'
                                options={COLLATERAL_TYPE_ARR}
                                width="large"
                                value={this.state.selectedCollateralType}
                                defaultValue={this.state.selectedCollateralType}
                                onSelectedOptionChange={this.onChangeCollateralType}
                                disabled={this.state.readOnly}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <FormControlLabel
                                style={{ justifyContent: "flex-end" }}
                                disabled={this.state.readOnly}
                                control={
                                    <Switch
                                        checked={this.state.isValid}
                                        onChange={() => this.setState({ isValid: !this.state.isValid })}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={this.state.isValid ? "Valid" : "Invalid"}
                            />
                            <TDatePicker
                                type={TDatePickerTypes.time}
                                label={"Bank Guarantee Validity Date"}
                                selectedDate={this.state.selectedValidityDate}
                                onSelectedDateChange={this.onChangeValidityDate}
                                disabled={this.state.readOnly}
                            />
                            
                            <TDropdown
                                label='Collateral Rate (%)'
                                options={COLLATERAL_RATE_ARR}
                                width="large"
                                value={this.state.selectedCollateralRate}
                                defaultValue={this.state.selectedCollateralRate}
                                onSelectedOptionChange={this.onChangeCollateralRate}
                                disabled={this.state.readOnly}
                            />

                            {
                                this.props.displayRemainingDays ? (
                                    <TextField
                                        style={{ width: "50%", marginLeft: "25px", marginTop: "15px" }}
                                        label="Remaing Days"
                                        value={this.state.remainingDays}
                                        InputProps={{
                                            readOnly: true,
                                            disableUnderline: true
                                        }}
                                        type="number"
                                    />
                                ) : null
                            }

                            <TextField
                                style={{ width: "50%", marginLeft: "25px", marginTop: "15px" }}
                                label="Collateral Without Tax"
                                value={this.state.collateralWithoutTax}
                                onChange={(e) => this.onChangeCollateral(e.target.value)}
                                InputProps={{
                                    readOnly: this.state.readOnly,
                                    disableUnderline: this.state.readOnly
                                }}
                                type="number"
                            />
                        </div>
                    </div>
                    {
                        this.state.displayAddEditButtons ? (
                            <div style={{ "margin": "12px", display: `${this.state.readOnly ? "none" : "block"}` }}>
                                <Divider />
                                <AccordionActions>
                                    <button
                                        size="small"
                                        className="btn t-gray-button"
                                        onClick={() => this.onCancelButtonClick()}
                                    >
                                        Cancel
                            </button>
                                    <button
                                        size="small"
                                        className="btn t-orange-button"
                                        onClick={() => this.onSaveButtonClick()}
                                        disabled={this.state.readOnly}
                                    >
                                        Save
                            </button>
                                </AccordionActions>
                            </div>
                        ) : null
                    }

                </div>
            </div>
        );
    }
}

export default CollateralDetail;