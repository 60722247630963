import React from 'react';
import { isEmpty, isObject } from 'lodash';

import { redirectToLogin } from './../../helpers/routeHelper';
import store from '../../store';
import { CREATE_ALERT } from '../../actions/types';
import { messages } from '../utils/messages';


export const handleApiError = (error) => {
    if (error.response && error.response.status === 401) {
        redirectToLogin();
    }
    else {
        //source:
        //https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/

        if (error.response) {
            //backend returned error
            alertError(messages.UNEXPECTED_ERROR_OCCURED);
        } else if (error.request) {
            //could not reach backend
            alertError(messages.NETWORK_ERROR);
        } else {
            //real unexpexted error
            alertError(messages.UNEXPECTED_ERROR_OCCURED);
        }
    }
}

export const showErrorMessage = message => {
    return (
        <div className="v-red v-font-size">
            <i className="fa fa-exclamation-circle v-red" aria-hidden="true">
                <span className="v-font"> {message}</span>
            </i>
        </div>
    );
}

export const showSuccessMessage = message => {
    return (
        <div className="v-green v-font-size">
            <i className="fa fa-check v-green" aria-hidden="true">
                <span className="v-font"> {message}</span>
            </i>
        </div>
    );
}

const types = { info: 'info', success: 'success', warning: 'warning', danger: 'danger' };

export const alertError = message => {
    store.dispatch({ type: CREATE_ALERT, payload: { type: types.danger, message } });
}

export const alertSuccess = message => {
    store.dispatch({ type: CREATE_ALERT, payload: { type: types.success, message } });
}

export const alertWarning = message => {
    store.dispatch({ type: CREATE_ALERT, payload: { type: types.warning, message } });
}

export const alertInfo = message => {
    store.dispatch({ type: CREATE_ALERT, payload: { type: types.info, message } });
}

export const rearrangeErrors = error => {
    let allErrors = {};

    if (error.multi)
        allErrors = { ...error.multi };

    if (error.default)
        allErrors.default = error.default;

    return allErrors;
}

export const specifyErrorMessage = (error, messagesDict, operationName) => {
    let message, errorType;

    if (!error)
        return null;

    if (!messagesDict || isEmpty(messagesDict))
        return { message: messages.UNEXPECTED_ERROR_OCCURED, errorType: "" };

    if (!isObject(error))
        return { message: error, errorType: "" };

    let allErrors = rearrangeErrors(error);

    if (!isEmpty(allErrors)) {
        Object.keys(allErrors).forEach(errorKey => {
            const errorDetail = allErrors[errorKey];

            Object.keys(errorDetail).forEach(currentErrorType => {
                message = messagesDict[currentErrorType];
                errorType = currentErrorType;

                if (isObject(message)) {
                    message = messagesDict[currentErrorType][errorDetail[currentErrorType]];

                    if (isObject(message)) {
                        message = message[operationName] ?? message["Default"];
                    }

                    if (!message)
                        message = messagesDict[currentErrorType]["Default"];
                }
            });
        });
    }

    if (!message)
        return { message: messagesDict["Default"], errorType: "Default" };

    if (!message)
        return { message: messages.UNEXPECTED_ERROR_OCCURED, errorType: "" };

    return { message, errorType };
}