import React from 'react';
import { Box } from "@material-ui/core";

const TabPanel = ({ children, tabId, maxTabIndex, currentTabId }) => {

  return (
    <Box
      p={1}
      value={maxTabIndex}
      index={maxTabIndex}
      hidden={false}
      key={maxTabIndex}
    >
      {children}
    </Box>
  );
}

export default TabPanel;