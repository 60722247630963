import React from 'react';
import Select from 'react-select';

import './VDropdown.css';

/*
    * 'width' prop options: small, medium, large and x-large
    * 'height' prop options: small, medium, large and x-large
    * If you don't use multiselect dropdown, no need to set 'dropdownHeight' prop.
    * This sizes are set for all portal by considering consistency. If you need to add a new one, please
    check  out 'VDropdown.css' and append.

    * Some css classes in 'VDropdown.css' inspected by opening Chrome DevTools debug mode.

    react-select: https://react-select.com/home
*/

const VDropdown = ({ label, width, placeholder, options,
    defaultValue, onSelectedOptionChange, isSearchable, value, disabled }) => {

    const finalDropdownWidthClass = `v-dropdown-width-${width}`

    const renderContent = () => {

        return (
            <Select
                className='v-select'
                classNamePrefix="v-select"
                placeholder={placeholder !== undefined ? placeholder : ''}
                options={options}
                defaultValue={defaultValue}
                onChange={onSelectedOptionChange}
                isSearchable={isSearchable}
                maxMenuHeight={220}
                value={value}
                isDisabled={disabled}
            />
        );
    };

    return (
        <div className="v-dropdown-content">
            { label !== undefined &&
                <div className="v-dropdown-label">
                    {label}
                </div>
            }
            <div className={finalDropdownWidthClass}>
                {renderContent()}
            </div>
        </div>
    );


};

export default VDropdown;