import React from 'react';
import './CrossborderInvoice.css';

import _, { isEmpty, isNull, isObject, sortBy, isArray } from 'lodash';

import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VCheckboxGroup from '../../components/VCheckboxGroup/VCheckboxGroup';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VDatePicker, { VDatePickerTypes } from '../../components/VDatePicker/VDatePicker';
import VPageNavigation from '../../components/VPageNavigation/VPageNavigation';
import VDropdown from '../../components/VDropdown/VDropdown';
import VTable from '../../components/VTable/VTable';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import { extractDate, getDate } from '../../helpers/generalHelper';
import { handleApiError, alertError, alertSuccess, specifyErrorMessage } from '../../helpers/errorHelper';
import { portalMessages } from '../../helpers/portalMessages';
import { getCrossborderInvoice, savePaymentDetails, saveCustomInvoice, saveExchangeRates, getCrossborderInvoiceFile, saveHourlyExchangeRates } from '../../apis/vitusApi';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorageHelper';
import moment from 'moment';
import FileSaver from 'file-saver';

const weeklyColumnNames = {
    PerimeterCost: 'Perimeter Fee', ServiceCost: 'Service Fee', Profile: 'Total Amount (MWh)( LT+ ST)',
    InvoiceAmount: 'Invoice Amount', CustomAmount: 'Custom Amount', TotalInvoiceAmount: 'Total Invoice Amount',
    DailyCapacityCost: 'Daily Auction Costs',
    ExportCost: 'TEIAS Export Fee', ImportCost: 'TEIAS Import Fee', InvoiceAmount_ST: 'Invoice Amount-ST',
    InvoiceAmount_LT: 'Invoice Amount-LT', 'InvoiceAmountST_LT': 'Invoice Amount ST & LT',
    IntradayCost: 'Intraday Energy Cost', FinancingCost: 'VAT Financing Fee', VitusServiceCost: 'Service Fee',
    ExchangeCost: 'IBEX Exchange Fee'
}

const damCostIdentifiers = [{ performaType: 'Serviced By Energovia', direction: 'BG-TR', DAMCost: 'Costs from Purchases in BG IBEX DAM' },
{ performaType: 'Serviced By Energovia', direction: 'TR-BG', DAMCost: 'Revenue from Sales in BG IBEX DAM' },
{ performaType: 'Serviced By Vitus', direction: 'BG-TR', DAMCost: 'Revenue from Sales in TR EPIAS DAM' },
{ performaType: 'Serviced By Vitus', direction: 'TR-BG', DAMCost: 'Costs from Purchases in TR EPIAS DAM' },
{ performaType: 'Servicing', direction: 'GR-TR', DAMCost: 'Revenue from Sales in TR EPIAS DAM' },
{ performaType: 'Servicing', direction: 'TR-GR', DAMCost: 'Costs from Purchases in TR EPIAS DAM' },
{ performaType: 'Vitus', direction: 'GR-TR', DAMCost: 'Costs from Purchases in GR HENEX DAM' },
{ performaType: 'Vitus', direction: 'TR-GR', DAMCost: 'Revenue from Sales in GR HENEX DAM' }]

const auctionIdentifiers = [{
    performaType: 'Serviced By Vitus', DailyCapacityCost: 'Daily Auction Costs TCAT',
    MonthlyCapacityCost: 'Monthly Auction Costs TCAT'
}]

const amountInvoiceColumnNames = { Profile: 'Energy Amount (MWh)', TotalInvoiceAmount: 'Invoice Amount' }

const SERVICING = 'Servicing'
const SERVICED_BY_VITUS = 'Serviced By Vitus'
const INVOICE_AMOUNT = 'Invoice Amount'
const DAM_COST = 'DAMCost'
const DAILY_CAPACITY_COST = 'DailyCapacityCost'
const MONTHLY_CAPACITY_COST = 'MonthlyCapacityCost'
const CUSTOM_AMOUNT = 'CustomAmount'
const ENERGY_AMOUNT = 'Energy Amount (MWh)'
const BORDER = 'border'
const PAYMENT_DETAILS = 'Payment Details'
const EUR = 'EUR'

class CrossborderInvoice extends React.Component {

    maxTitleDebth = 1;
    orderCounter = 1
    weakColumnClass = 'v-weak-column';
    headerClasses = ['v-colored-header-v1', 'v-colored-header-v2'];
    pageRecordCountOptions = [12, 24, 48, 120, 240];
    spinner = new SpinnerManager(history.location.pathname);
    storedActiveFilter = getLocalStorage(this.props.pageName || 'CrossborderInvoice', 'filters', 'activeFilter') || {};
    tableRef = React.createRef();

    counterParties = {
        ensco: {
            name: "Ensco"
        },
        energovia: {
            name: "Energovia"
        }
    }

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED,
        NotFound: portalMessages.CROSSBORDER_INVOICE.NOT_FOUND
    };

    paymentOptionTypes = ['Vitus']

    counterPartyConfigs = {
        Default: { hourlyCurrency: false },
        Energovia: {
            paymentWithCurrency: false,
            hourlyCurrency: true
        },
        Ensco: {
            paymentWithCurrency: true,
            hourlyCurrency: false
        }
    }

    energoviaColumnsConfig = {
        price_details: {
            order: this.orderCounter++,
            title: 'Price',
            getSubTitle: [
                (data) => { return `${data.type}`; }
            ],
            sort: (subDataDict) => { return sortBy(subDataDict, ['type']) }
        },
        daily_auctions_details: {
            order: this.orderCounter++,
            title: 'Daily Auctions',
            getSubTitle: [
                (data) => { return `${data.tso}`; },
                (data) => { return `${data.direction}`; },
                (data) => { return <span>{`${data.data_type}`}</span>; }
            ],
            sort: (data) => {
                return sortBy(data, ['tso', 'direction', 'data_type'])
            }
        },
        lt_allocations_details: {
            order: this.orderCounter++,
            title: 'Monthly Auctions',
            getSubTitle: [
                (data) => { return `${data.tso}`; },
                (data) => { return `${data.direction}`; },
                (data) => { return <span>{`${data.data_type}`}</span>; }
            ],
            sort: (data) => {
                return sortBy(data, ['tso', 'direction'])
            }
        },
        physical_flows: {
            order: this.orderCounter++,
            title: 'Physical Flows',
            getSubTitle: [
                (data) => { return <span>{`${data.period}`}</span>; },
                (data) => { return `${data.direction}`; }
            ],
            sort: (data) => {
                return sortBy(data, ['period', 'direction'])
            }
        },
        exchange_rate_details: {
            order: this.orderCounter++,
            title: 'Exchange Rates',
            getSubTitle: [
                (data) => { return <span>{`${data.currency}`}</span>; }
            ],
            sort: (data) => {
                return sortBy(data, ['currency'])
            }
        },
        cost_details: {
            order: this.orderCounter++,
            title: 'Fees',
            getSubTitle: [
                (data) => { return `${data.type}`; }
            ],
            sort: (data) => { return data }
        },
        invoice_details: {
            order: this.orderCounter++,
            title: 'Invoice Amount',
            getSubTitle: [
                (data) => { return `${data.type}`; },
                (data) => { return `${data.direction}`; }
            ],
            sort: (data) => {
                return sortBy(data, ['type', 'direction'])
            }
        },
        pnl_details: {
            order: this.orderCounter++,
            title: 'PNL',
            getSubTitle: [
                (data) => { return `${data.type}`; },
                (data) => { return `${data.direction}`; }
            ],
            sort: (data) => {
                return sortBy(data, ['type', 'direction'])
            }
        },
    }

    enscoColumnsConfig = {
        price_details: {
            order: this.orderCounter++,
            title: 'Price',
            getSubTitle: [
                (data) => { return `${data.type}`; }
            ],
            sort: (data) => { return sortBy(data, ['type']) }
        },
        daily_auctions_details: {
            order: this.orderCounter++,
            title: 'Daily Auctions',
            getSubTitle: [
                (data) => { return `${data.direction}`; },
                (data) => { return <span>{`${data.data_type}`}</span>; }
            ],
            sort: (data) => {
                return sortBy(data, ['direction', 'data_type'])
            }
        },
        vitus_flows: {
            order: this.orderCounter++,
            title: 'Vitus',
            getSubTitle: [
                (data) => { return <span>{`${data.type}`}</span>; },
                (data) => { return `${data.direction}`; }
            ],
            sort: (data) => {
                return sortBy(data, ['type', 'direction'])
            }
        },
        servicing_flows: {
            order: this.orderCounter++,
            title: 'Servicing',
            getSubTitle: [
                (data) => { return <span>{`${data.type}`}</span>; },
                (data) => { return `${data.direction}`; }
            ],
            sort: (data) => {
                return sortBy(data, ['type', 'direction'])
            }
        },
        cost_details: {
            order: this.orderCounter++,
            title: 'Fees',
            getSubTitle: [
                (data) => { return `${data.type}`; }
            ],
            sort: (data) => { return data }
        }
    }

    columnsConfig = {}

    dataGroupClasses = ['v-data-group-1', 'v-data-group-2'];
    rowClasses = ['v-row-group-1', 'v-row-group-2'];

    state = {
        activeFilterToDisplay: {},
        selectedStartDate: this.getDefaultStartDate(),
        disableFilter: false,
        selectedCounterParty: null,
        selectedCounterPartyOption: null,
        selectedCounterPartyConfig: this.counterPartyConfigs.Default,
        paymentOptions: this.getPaymentCheckboxOptions(),
        counterPartyList: [],
        counterPartyOptions: [],
        activeFilter: {
            start_date: null,
            counter_party: this.storedActiveFilter?.counter_party,
            payment_option_list: this.storedActiveFilter?.payment_option_list
        },
        hourly_details: {},
        hourly_excel_details: {},
        weekly_details: [],
        weekly_exchange_details: [],
        weekly_amount_invoice_details: [],
        weekly_payment_details: [],
        monthly_invoice_details: [],
        weekly_pnl_analyses: [],
        exchange_rates_details: [],
        editingDetails: false,
        editingWeeklyCustom: {},
        currentDetailsPage: 1,
        detailRecordsPerPageCount: 120
    }

    getPaymentCheckboxOptions() {
        let paymentOptions = {}
        this.paymentOptionTypes.map((c, index) => {
            paymentOptions[c] = { title: c, checked: index === 0 }
        });

        return paymentOptions;
    }

    getCounterPartyOptions() {
        return Object.keys(this.counterParties).map(c => { return { value: this.counterParties[c].name, label: this.counterParties[c].name } })
    }

    componentDidMount() {

        let selectedCounterPartyOption;
        const counterPartyOptions = this.getCounterPartyOptions();

        if (this.state.activeFilter.counter_party) {
            selectedCounterPartyOption = counterPartyOptions.find(e => e.label === this.state.activeFilter.counter_party);

            if (!selectedCounterPartyOption)
                selectedCounterPartyOption = "";
        }

        if (!selectedCounterPartyOption) {
            selectedCounterPartyOption = counterPartyOptions[0];
        }

        this.setState({ counterPartyOptions, selectedCounterPartyOption });

        const selectedCounterParty = selectedCounterPartyOption.value;

        if (selectedCounterParty) {

            const filter = {
                start_date: extractDate(this.state.selectedStartDate),
                counter_party: selectedCounterPartyOption.value,
                payment_option_list: Object.keys(this.state.paymentOptions).filter(p => this.state.paymentOptions[p].checked),
            };

            if (filter.counter_party === 'Energovia') {
                this.columnsConfig = this.energoviaColumnsConfig;
            }
            else {
                this.columnsConfig = this.enscoColumnsConfig;
            }

            Object.keys(this.columnsConfig).forEach(key => {
                if (this.columnsConfig[key].getSubTitle.length > this.maxTitleDebth)
                    this.maxTitleDebth = this.columnsConfig[key].getSubTitle.length;
            });

            this.refreshCrossborderInvoiceAsync(filter, null, 1);
        }

    }

    getDefaultStartDate() {
        return this.getConvertedDatesForGroup().start_date;
    }

    getConvertedDatesForGroup(defaultStart) {

        const now = new Date();

        if (!defaultStart)
            defaultStart = new Date(now.getFullYear(), now.getMonth(), 1);

        return { start_date: defaultStart }
    }

    showErrorMessage(error) {
        const { message } = specifyErrorMessage(error, this.errorMessages);

        if (message)
            alertError(message);
    }

    refreshCrossborderInvoiceAsync = async (filter, rowCount, pageNum) => {

        if (!filter)
            filter = {
                start_date: this.state.activeFilter.start_date,
                counter_party: this.state.activeFilter.counter_party,
                payment_option_list: Object.keys(this.state.paymentOptions).filter(p => this.state.paymentOptions[p].checked),
            };

        return await this.getCrossborderInvoiceAsync(filter, rowCount, pageNum)
    }

    getCrossborderInvoiceAsync = async (filter, rowCount, pageNum) => {

        this.spinner.showSpinner('getCrossborderInvoiceService');

        try {

            if (!rowCount && rowCount !== 0)
                rowCount = this.state.detailRecordsPerPageCount;

            filter.row_count = rowCount;

            if (!pageNum)
                pageNum = this.state.currentDetailsPage;

            filter.start_row = filter.row_count * (pageNum - 1);

            let response;

            try {
                response = await getCrossborderInvoice(filter);
            } catch (error) {
                handleApiError(error);
                return;
            }
            if (response.data.success) {

                const selectedCounterPartyConfig = this.counterPartyConfigs[filter.counter_party]

                this.setState({
                    hourly_details: response.data.success.hourly_details,
                    hourly_excel_details: response.data.success.hourly_excel_details,
                    weekly_details: response.data.success.weekly_details,
                    weekly_exchange_details: response.data.success.weekly_exchange_details,
                    weekly_amount_invoice_details: response.data.success.weekly_amount_invoice_details,
                    weekly_payment_details: response.data.success.weekly_payment_details,
                    monthly_invoice_details: response.data.success.monthly_invoice_details,
                    weekly_pnl_analyses: response.data.success.weekly_pnl_analyses,
                    exchange_rates_details: response.data.success.exchange_rates_details,
                    selectedCounterPartyConfig,
                    currentDetailsPage: pageNum,
                    detailRecordsPerPageCount: rowCount,
                    activeFilter: {
                        start_date: filter.start_date,
                        counter_party: filter.counter_party,
                        payment_option_list: filter.payment_option_list
                    },
                    activeFilterToDisplay: [
                        { label: "Start Date", value: filter.start_date },
                        { label: "Counter Party", value: filter.counter_party },
                        { label: "Payment Option", value: filter.payment_option_list?.length ? filter.payment_option_list.map(p => this.state.paymentOptions[p].title) : "undefined" },

                    ]
                }, () => {
                    setLocalStorage(this.props.pageName || 'CrossborderInvoice', 'filters', 'activeFilter',
                        { start_date: filter.start_date, counter_party: filter.counter_party, payment_option_list: filter.payment_option_list });
                });

            } else if (response.data.error) {
                this.showErrorMessage(response.data.error);

            } else {
                alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
            }

        } catch (error) {
            alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);

        } finally {
            this.spinner.hideSpinner('getCrossborderInvoiceService');
        }
    }

    async onSaveWeeklyPerformaDetailsChange(newData, directionCustomDict, currencyIndex, performaType) {

        let spinnerKey = this.spinner.showSpinner();

        try {

            let rows = newData.values;
            let rateDetailsToSave = []
            if (performaType === SERVICING) {
                rows.forEach(row => {
                    let rateDetail = {};
                    rateDetail['from'] = row[0];
                    rateDetail['to'] = row[1];
                    rateDetail['rate'] = Number(String(row[currencyIndex]).replaceAll(',', ''));
                    rateDetailsToSave.push(rateDetail);
                })
            }

            let performaDetailsToSave = []
            Object.keys(directionCustomDict).forEach(direction => {
                let directionCustomIndex = directionCustomDict[direction];
                rows.forEach(row => {
                    let customDetail = {};
                    customDetail['direction'] = direction;
                    customDetail['customType'] = performaType;
                    customDetail['counter_party'] = this.state.selectedCounterPartyOption.value;
                    customDetail['from'] = row[0];
                    customDetail['to'] = row[1];
                    customDetail['invoice'] = Number(String(row[directionCustomIndex]).replaceAll(',', ''));
                    performaDetailsToSave.push(customDetail);
                })
            })
            let performa_body = {
                'performa_details': performaDetailsToSave,
                'start_date': extractDate(this.state.selectedStartDate)
            };

            let rate_body = {
                'exchange_rates_details': rateDetailsToSave,
                'start_date': extractDate(this.state.selectedStartDate)
            };

            let response;
            try {
                response = await saveCustomInvoice(performa_body);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (performaType === SERVICING) {
                try {
                    response = await saveExchangeRates(rate_body);
                } catch (error) {
                    handleApiError(error);
                    return false;
                }
            }

            if (response.data.success) {
                await this.refreshCrossborderInvoiceAsync(null, null, 1);
                alertSuccess(portalMessages.CROSSBORDER_INVOICE.WEEKLY_PERFORMA_SAVED);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {}, "editValues");
                return false;
            }
        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Save Weekly Performa)');
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    createWeeklyPerformaColumns(performaType, direction, column) {
        let displayedColumn = weeklyColumnNames[column];

        if (column === DAM_COST) {
            let damCostIdentifier = damCostIdentifiers.find(d => d.performaType === performaType && d.direction === direction);
            if (damCostIdentifier) {
                displayedColumn = damCostIdentifier[column];
            }
        }

        else if (column === DAILY_CAPACITY_COST || column === MONTHLY_CAPACITY_COST) {
            let auctionIdentifier = auctionIdentifiers.find(d => d.performaType === performaType);
            if (auctionIdentifier) {
                displayedColumn = auctionIdentifier[column];
            }
        }

        return displayedColumn;
    }

    onGetHeaderClass = (colId, colCount, headerIndexList) => {

        let colIdx;
        
        if (colId > colCount){
            colIdx = colId -colCount;
        }
        else{
            colIdx = colId;
        }
        colIdx -= 1
        let headerClass = "";
        headerIndexList.forEach((headerIndexDict, headerIndexDictNum) => {
            if ((headerIndexDict["startCol"] <= colIdx) && (headerIndexDict["endCol"] >= colIdx)){
                headerClass = this.headerClasses[headerIndexDictNum];
            }
        })

        return headerClass;

    }

    createWeeklyPerformaDetails() {

        if (this.state.weekly_details.length <= 0) {
            return null;
        }

        return (
            this.state.weekly_details.map(weeklyDetail => {

                let readOnlyColumnIndices = [0, 1];
                let directionCustomDict = {};
                let directionHeaderIndexDictList = [];
                let headers = [[{ name: 'From', html: 'From' }, { name: 'To', html: 'To' }], ['', '']]
                let values = []
                let readedColumns = [];
                let exchangeReaded = false;
                let counter = 2;
                let currencyIndex;
                if (weeklyDetail.dataType === SERVICING || weeklyDetail.dataType === SERVICED_BY_VITUS) {
                    headers[0].push({ name: 'Exchange Rate', html: 'Exchange Rate' })
                    headers[1].push('')
                    currencyIndex = counter;
                    if (weeklyDetail.dataType === SERVICED_BY_VITUS) {
                        readOnlyColumnIndices.push(counter);
                    }
                    counter++;
                }

                weeklyDetail.data.forEach((directionWeeklyDetail, directionWeeklyDetailIndex) => {
                    let directionHeaderIndexDict = {};
                    
                    readedColumns = [];
                    headers[0].push({ name: directionWeeklyDetail.direction, html: directionWeeklyDetail.direction })
                    directionHeaderIndexDict["direction"] = directionWeeklyDetail.direction;
                    directionHeaderIndexDict["startCol"] = headers[0].length - 1;

                    directionWeeklyDetail.data.map((weekDetail, weekDetailIndex) => {
                        let data_list = []
                        if (directionWeeklyDetailIndex === 0) {
                            data_list.push(weekDetail['From'])
                            data_list.push(weekDetail['To'])

                            if ((!exchangeReaded && weeklyDetail.dataType === SERVICING) || ((!exchangeReaded && weeklyDetail.dataType === SERVICED_BY_VITUS))) {
                                data_list.push(this.state.weekly_exchange_details[weekDetailIndex]['Exchange Rate']);
                            }
                            values.push(data_list);
                        }

                        Object.keys(weekDetail).forEach(column => {

                            let displayedColumn = this.createWeeklyPerformaColumns(weeklyDetail.dataType, directionWeeklyDetail.direction, column);

                            if ((!readedColumns.includes(column)) && (column !== 'From') && (column !== 'To')) {
                                readedColumns.push(column);
                                headers[1].push(displayedColumn);

                                if (column !== CUSTOM_AMOUNT) {
                                    readOnlyColumnIndices.push(counter)
                                }
                                else {
                                    directionCustomDict[directionWeeklyDetail.direction] = counter;
                                }
                                counter++;
                                values[weekDetailIndex].push(weekDetail[column].toLocaleString("en-us", { maximumFractionDigits: 2 }));
                            }
                            else if ((column !== 'From') && (column !== 'To')) {
                                values[weekDetailIndex].push(weekDetail[column].toLocaleString("en-us", { maximumFractionDigits: 2 }));
                            }
                        })

                    })

                    if (weeklyDetail.dataType === SERVICING) {
                        exchangeReaded = true;
                    }
                    for (let i = 0; i < readedColumns.length - 1; i++) {
                        headers[0].push({ name: '', html: '' });
                    }
                    directionHeaderIndexDict["endCol"] = headers[0].length - 1;
                    directionHeaderIndexDictList.push(directionHeaderIndexDict);
                })

                return (
                    <div className='v-infinite-width v-table-list'>
                        <VTable
                            key={weeklyDetail.dataType}
                            title={weeklyDetail.dataType}
                            onGetHeaderClass={(colId, colCount) => this.onGetHeaderClass(colId, colCount, directionHeaderIndexDictList)}
                            items={{ headers, values }}
                            readonlyColumnIndices={readOnlyColumnIndices}
                            onOpenEditMode={() => this.onOpenCustomEditMode(weeklyDetail.dataType)}
                            onCloseEditMode={() => this.onCloseCustomEditMode(weeklyDetail.dataType)}
                            onSaveChangesAsync={(newData) => this.onSaveWeeklyPerformaDetailsChange(newData, directionCustomDict, currencyIndex, weeklyDetail.dataType)}
                            headerButtons={{
                                showEditButton: true
                            }}
                            bigNumber={true}
                        />
                    </div>
                );


            })
        )

    }

    createWeeklyMonthlyInvoiceColumns(performaType, column) {
        let columnDisplay = amountInvoiceColumnNames[column];
        let currency = '';

        if (performaType === SERVICING) {
            currency = ' (TRY)'
        }
        else {
            currency = ' (EUR)'
        }

        if (columnDisplay === INVOICE_AMOUNT) {
            columnDisplay = columnDisplay + currency
        }

        return columnDisplay;
    }


    createWeeklyInvoices() {

        if (this.state.weekly_amount_invoice_details.length <= 0) {
            return null;
        }

        return (
            this.state.weekly_amount_invoice_details.map(weeklyInvoiceDetail => {

                let dataType = weeklyInvoiceDetail.dataType;
                let weeklyInvoiceData = weeklyInvoiceDetail.data;

                let headers = [[{ name: 'From', html: 'From' }, { name: 'To', html: 'To' }], ['', '']]
                let values = []
                let readedColumns = [];
                weeklyInvoiceData.forEach((directionWeeklyInvoiceDetail, directionWeeklyInvoiceDetailIndex) => {

                    let direction = directionWeeklyInvoiceDetail.direction;
                    let directionInvoiceData = directionWeeklyInvoiceDetail.data;

                    readedColumns = [];
                    headers[0].push(direction);
                    directionInvoiceData.forEach((weekDetail, weekDetailIndex) => {

                        let data_list = [];
                        if (directionWeeklyInvoiceDetailIndex === 0) {
                            data_list.push(weekDetail['From']);
                            data_list.push(weekDetail['To']);

                            values.push(data_list);
                        }
                        Object.keys(weekDetail).forEach(column => {

                            let displayedColumn = this.createWeeklyMonthlyInvoiceColumns(dataType, column);

                            if ((!readedColumns.includes(column)) && (column !== 'From') && (column !== 'To')) {
                                readedColumns.push(column);
                                headers[1].push(displayedColumn);
                                values[weekDetailIndex].push(weekDetail[column].toLocaleString("en-us", { maximumFractionDigits: 2 }));
                            }
                            else if ((column !== 'From') && (column !== 'To')) {
                                values[weekDetailIndex].push(weekDetail[column].toLocaleString("en-us", { maximumFractionDigits: 2 }));
                            }

                        })

                    })

                    for (let i = 0; i < readedColumns.length - 1; i++) {
                        headers[0].push({ name: '', html: '' });
                    }
                })

                return (
                    <div className='v-infinite-width v-table-list'>
                        <VTable
                            key={dataType}
                            title={dataType}
                            items={{ headers, values }}
                            readonlyColumns={['BG-TR', 'TR-BG', 'TR-GR', 'GR-TR']}
                            bigNumber={true}
                        />
                    </div>
                );
            })
        )
    }

    onOpenDetailsEditMode() {
        this.setState({ editingDetails: true });
    }

    onCloseDetailsEditMode() {
        this.setState({ editingDetails: false });

    }

    onOpenCustomEditMode(performaType) {

        let editMode = this.state.editingWeeklyCustom;
        editMode[performaType] = true;

        this.setState({ editingWeeklyCustom: { ...editMode } });

    }

    onCloseCustomEditMode(performaType) {
        let editMode = this.state.editingWeeklyCustom;
        editMode[performaType] = false;

        this.setState({ editingWeeklyCustom: { ...editMode } });

    }

    async onSaveEnergoviaWeeklyPaymentDetailsChange(newData) {

        let spinnerKey = this.spinner.showSpinner();

        try {

            let rows = newData.values;
            let paymentDetailToSave = []
            rows.forEach(row => {
                let paymentDetail = {};
                paymentDetail['currency'] = EUR;
                paymentDetail['counter_party'] = this.state.selectedCounterPartyOption.value;
                paymentDetail['from'] = row[0];
                paymentDetail['to'] = row[1];
                paymentDetail['payment'] = Number(row[3].replaceAll(',', ''));
                paymentDetailToSave.push(paymentDetail);
            })

            let body = {
                'payment_data': paymentDetailToSave,
                'start_date': extractDate(this.state.selectedStartDate)
            }
            let response;

            try {
                response = await savePaymentDetails(body);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                await this.refreshCrossborderInvoiceAsync(null, null, 1);
                alertSuccess(portalMessages.CROSSBORDER_INVOICE.WEEKLY_PAYMENT_DETAILS_SAVED);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {}, "editValues");
                return false;
            }


        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Save Weekly Payment Details)');
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    async onSaveWeeklyPaymentDetailsChange(newData, paymentCustomDict) {


        let spinnerKey = this.spinner.showSpinner();

        try {

            let rows = newData.values;
            let paymentDetailToSave = []
            Object.keys(paymentCustomDict).forEach(paymentCurrency => {
                let paymentCurrencyIndex = paymentCustomDict[paymentCurrency];
                rows.forEach(row => {
                    let paymentDetail = {};
                    paymentDetail['currency'] = paymentCurrency;
                    paymentDetail['counter_party'] = this.state.selectedCounterPartyOption.value;
                    paymentDetail['from'] = row[0];
                    paymentDetail['to'] = row[1];
                    paymentDetail['payment'] = Number(row[paymentCurrencyIndex].replaceAll(',', ''));
                    paymentDetailToSave.push(paymentDetail);
                })
            })

            let body = {
                'payment_data': paymentDetailToSave,
                'start_date': extractDate(this.state.selectedStartDate)
            }


            let response;
            try {
                response = await savePaymentDetails(body);
            } catch (error) {
                handleApiError(error);
                return false;
            }


            if (response.data.success) {
                await this.refreshCrossborderInvoiceAsync(null, null, 1);
                alertSuccess(portalMessages.CROSSBORDER_INVOICE.WEEKLY_PAYMENT_DETAILS_SAVED);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {}, "editValues");
                return false;
            }


        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Save Weekly Payment Details)');
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }

    }

    createWeeklyPnlAnaylses() {

        let headers = [[{ name: 'From', html: 'From' }, { name: 'To', html: 'To' }], ['', '']]
        let values = [];
        let readOnlyColumnIndices = [0, 1];
        let counter = 2;

        if (this.state.weekly_pnl_analyses.length <= 0) {
            return null;
        }

        this.state.weekly_pnl_analyses.forEach((weeklyPnlDetail, weeklyPnlDetailIndex) => {

            let dataType = weeklyPnlDetail.dataType;
            let weeklyPnlData = weeklyPnlDetail.data;

            headers[0].push(dataType);

            weeklyPnlData.forEach((directionWeeklyPnlDetail, directionWeeklyPnlDetailIndex) => {

                let direction = directionWeeklyPnlDetail.direction;
                let directionWeeklyPnlData = directionWeeklyPnlDetail.data;

                headers[1].push(direction);

                readOnlyColumnIndices.push(counter);
                counter++;

                directionWeeklyPnlData.forEach((weekDetail, weekDetailIndex) => {
                    let data_list = [];
                    if ((weeklyPnlDetailIndex === 0) && (directionWeeklyPnlDetailIndex === 0)) {
                        data_list.push(weekDetail['From']);
                        data_list.push(weekDetail['To']);
                        values.push(data_list);
                    }

                    Object.keys(weekDetail).forEach(column => {
                        if ((column !== 'From') && (column !== 'To')) {
                            values[weekDetailIndex].push(weekDetail[column].toLocaleString("en-us", { maximumFractionDigits: 2 }));
                        }
                    })

                })

            })

            for (let i = 0; i < weeklyPnlData.length - 1; i++) {
                headers[0].push({ name: '', html: '' });
            }

        })

        return (
            <div className='v-infinite-width v-table-list'>
                <VTable
                    key={'Weekly Pnl Analyses'}
                    title={'Weekly Pnl Analyses'}
                    items={{ headers, values }}
                    readonlyColumnIndices={readOnlyColumnIndices}
                    bigNumber={true}
                />
            </div>
        );

    }

    createEnergoviaWeeklyPaymentDetails() {

        let headers = [[{ name: 'From', html: 'From' }, { name: 'To', html: 'To' }]]
        let values = [];
        let readOnlyColumnIndices = [0, 1];
        let counter = 2;

        if (this.state.weekly_payment_details.length <= 0) {
            return null;
        }

        this.state.weekly_payment_details.forEach((weeklyPaymentDetail, weeklyPaymentDetailIndex) => {

            let dataType = weeklyPaymentDetail.dataType;
            let weeklyPaymentData = weeklyPaymentDetail.data;

            headers[0].push(dataType);

            if (dataType !== PAYMENT_DETAILS) {
                readOnlyColumnIndices.push(counter);
            }

            counter++;

            weeklyPaymentData.forEach((weekDetail, weekDetailIndex) => {
                let data_list = [];
                if ((weeklyPaymentDetailIndex === 0)) {
                    data_list.push(weekDetail['From']);
                    data_list.push(weekDetail['To']);

                    values.push(data_list);
                }

                Object.keys(weekDetail).forEach(column => {
                    if ((column !== 'From') && (column !== 'To')) {
                        values[weekDetailIndex].push(weekDetail[column].toLocaleString("en-us", { maximumFractionDigits: 2 }));
                    }
                })
            })

        })

        return (
            <div className='v-infinite-width v-table-list'>
                <VTable
                    key={'Weekly Payment Details'}
                    title={'Weekly Payment Details'}
                    items={{ headers, values }}
                    readonlyColumnIndices={readOnlyColumnIndices}
                    onSaveChangesAsync={(newData) => this.onSaveEnergoviaWeeklyPaymentDetailsChange(newData)}
                    onOpenEditMode={() => this.onOpenDetailsEditMode()}
                    onCloseEditMode={() => this.onCloseDetailsEditMode()}
                    headerButtons={{
                        showEditButton: true
                    }}
                    bigNumber={true}
                />
            </div>
        );
    }

    createEnscoWeeklyPaymentDetails() {
        let headers = [[{ name: 'From', html: 'From' }, { name: 'To', html: 'To' }], ['', '']]
        let values = [];
        let readOnlyColumnIndices = [0, 1];
        let counter = 2;
        let paymentCustomDict = {};

        if (this.state.weekly_payment_details.length <= 0) {
            return null;
        }

        this.state.weekly_payment_details.forEach((weeklyPaymentDetail, weeklyPaymentDetailIndex) => {

            let dataType = weeklyPaymentDetail.dataType;
            let weeklyPaymentData = weeklyPaymentDetail.data;

            headers[0].push(dataType);

            weeklyPaymentData.map((currencyWeeklyDetail, currencyWeeklyDetailIndex) => {

                let currency = currencyWeeklyDetail.currency;
                let currencyWeeklyData = currencyWeeklyDetail.data;

                let readedColumns = [];
                readedColumns = [];
                headers[1].push(currency);

                if (dataType !== PAYMENT_DETAILS) {
                    readOnlyColumnIndices.push(counter);
                }
                else {
                    paymentCustomDict[currency] = counter;
                }

                counter++;

                currencyWeeklyData.forEach((weekDetail, weekDetailIndex) => {
                    let data_list = [];
                    if ((weeklyPaymentDetailIndex === 0) && (currencyWeeklyDetailIndex === 0)) {
                        data_list.push(weekDetail['From']);
                        data_list.push(weekDetail['To']);

                        values.push(data_list);
                    }

                    Object.keys(weekDetail).map(column => {
                        if ((column !== 'From') && (column !== 'To')) {
                            values[weekDetailIndex].push(weekDetail[column].toLocaleString("en-us", { maximumFractionDigits: 2 }));
                        }
                    })

                })

            })

            for (let i = 0; i < weeklyPaymentData.length - 1; i++) {
                headers[0].push({ name: '', html: '' });
            }

        })

        return (
            <div className='v-infinite-width v-table-list'>
                <VTable
                    key={'Weekly Payment Details'}
                    title={'Weekly Payment Details'}
                    items={{ headers, values }}
                    readonlyColumnIndices={readOnlyColumnIndices}
                    onOpenEditMode={() => this.onOpenDetailsEditMode()}
                    onCloseEditMode={() => this.onCloseDetailsEditMode()}
                    onSaveChangesAsync={(newData) => this.onSaveWeeklyPaymentDetailsChange(newData, paymentCustomDict)}
                    headerButtons={{
                        showEditButton: true
                    }}
                    bigNumber={true}
                />
            </div>
        );
    }

    createWeeklyPaymentDetails() {

        if (this.state.weekly_payment_details.length <= 0) {
            return null;
        }

        if (!this.state.selectedCounterPartyConfig.paymentWithCurrency) {
            return this.createEnergoviaWeeklyPaymentDetails()
        }
        else {
            return this.createEnscoWeeklyPaymentDetails()
        }

    }

    createMonthlyInvoiceDetails() {
        let headers = [[{ name: 'Border', html: 'Border' }], ['']]
        let values = [];

        if (this.state.monthly_invoice_details <= 0) {
            return null;
        }

        let readOnlyColumnIndices = [0];
        let counter = 1;
        this.state.monthly_invoice_details.forEach((monthlyInvoiceDetail, monthlyInvoiceDetailIndex) => {

            let dataType = monthlyInvoiceDetail.dataType;
            let monthlyInvoiceData = monthlyInvoiceDetail.data;

            headers[0].push(dataType);
            let readedColumns = []
            monthlyInvoiceData.forEach((borderDetail, borderDetailIndex) => {

                let border = borderDetail.border;

                let data_list = [];
                if (monthlyInvoiceDetailIndex === 0) {
                    data_list.push(border);
                    values.push(data_list);
                }

                Object.keys(borderDetail).forEach(column => {

                    let displayedColumn = this.createWeeklyMonthlyInvoiceColumns(dataType, column);

                    if ((!readedColumns.includes(column)) && (column !== 'border')) {
                        readedColumns.push(column);
                        headers[1].push(displayedColumn);
                        values[borderDetailIndex].push(borderDetail[column].toLocaleString("en-us", { maximumFractionDigits: 2 }));
                    }
                    else if (column !== BORDER) {
                        values[borderDetailIndex].push(borderDetail[column].toLocaleString("en-us", { maximumFractionDigits: 2 }));
                        if (displayedColumn === ENERGY_AMOUNT) {
                            readOnlyColumnIndices.push(counter);
                        }
                        counter++;
                    }

                })
            })

            for (let i = 0; i < readedColumns.length - 1; i++) {
                headers[0].push({ name: '', html: '' });
            }

        })

        let title = 'Monthly Payment Details'
        return (
            <div className='v-infinite-width v-table-list'>
                <VTable
                    key={title}
                    title={title}
                    items={{ headers, values }}
                    staticHeaderButtons
                    readonlyColumnIndices={readOnlyColumnIndices}
                    bigNumber={true}
                />
            </div>
        );
    }

    async onSaveHourlyExchangeRates(newData, currencyDict, date_values) {

        let spinnerKey = this.spinner.showSpinner();

        try {

            let rows = newData.values;
            let rate_list = []
            Object.keys(currencyDict).forEach(currency => {
                let currencyIndex = currencyDict[currency];
                rows.forEach((row, rowIndex) => {
                    let rate_dict = {}
                    rate_dict['DateTime'] = date_values[rowIndex];
                    rate_dict['Rate'] = row[currencyIndex];
                    rate_dict['Currency'] = currency;
                    rate_list.push(rate_dict);
                })
            })

            let rate_body = {
                'exchange_rates_details': rate_list,
                'start_date': extractDate(this.state.selectedStartDate)
            };
            let response;

            try {
                response = await saveHourlyExchangeRates(rate_body);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                await this.refreshCrossborderInvoiceAsync(null, null, 1);
                alertSuccess(portalMessages.CROSSBORDER_INVOICE.HOURLY_RATES_SAVED);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {}, "editValues");
                return false;
            }
        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Save Hourly Exchange Rates)');
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }

    }

    renderHourlyExchangeRates() {
        return (
            <VTab key='HourlyExchangeRates'
                eventKey='HourlyExchangeRates'
                title='Hourly Exchange Rates'>
                <div className='v-cb-invoice-summary-table'>
                    {this.createHourlyExchangeRates()}
                </div>
            </VTab>)
    }

    onOpenExchangeRatesEditMode() {
        this.setState({ editingExchangeRates: true });
    }

    onCloseExchangeRatesEditMode() {
        this.setState({ editingExchangeRates: false });
    }

    createHourlyExchangeRates() {

        if (this.state.exchange_rates_details <= 0) {
            return null;
        }
        let headers = [[{ name: 'Date', html: 'Date' }]]
        let values = [];
        let date_values = [];
        let exchangeCounter = 1;
        let currencyDict = [];

        this.state.exchange_rates_details.forEach((exchangeRateDetail, exchangeRateDetailIndex) => {

            let currency = exchangeRateDetail.currency;
            let currencyData = exchangeRateDetail.data;

            headers[0].push({ name: currency, html: currency });
            currencyDict[currency] = exchangeCounter;

            currencyData.forEach(currencyHourData => {
                let dt = currencyHourData.dt;
                let v = currencyHourData.v;
                let date = currencyHourData.dt_real;
                values.push([dt, v]);
                if (exchangeRateDetailIndex === 0) {
                    date_values.push(date);
                }
            })
            exchangeCounter++;
        })

        let title = 'Hourly Exchange Rates'
        return (
            <div className='v-infinite-width v-table-list'>
                <VTable
                    key={title}
                    title={title}
                    items={{ headers, values }}
                    staticHeaderButtons
                    readonlyColumns={['Date']}
                    onOpenEditMode={() => this.onOpenExchangeRatesEditMode()}
                    onCloseEditMode={() => this.onCloseExchangeRatesEditMode()}
                    onSaveChangesAsync={(newData) => this.onSaveHourlyExchangeRates(newData, currencyDict, date_values)}
                    headerButtons={{
                        showEditButton: true
                    }}
                    bigNumber={true}
                />
            </div>
        );

    }

    onShowButtonClick = () => {

        if (!this.state.selectedStartDate) {
            this.showErrorMessage(portalMessages.DATE_SELECTION);
            return;
        }

        if (!this.state.selectedCounterPartyOption || isEmpty(this.state.selectedCounterPartyOption)) {
            this.showErrorMessage(portalMessages.SELECT_COUNTER_PARTY);
            return;
        }

        const filter = {
            start_date: extractDate(this.state.selectedStartDate),
            counter_party: this.state.selectedCounterPartyOption.value,
            payment_option_list: Object.keys(this.state.paymentOptions).filter(p => this.state.paymentOptions[p].checked)
        };

        if (filter.counter_party === 'Energovia') {
            this.columnsConfig = this.energoviaColumnsConfig;
        }
        else {
            this.columnsConfig = this.enscoColumnsConfig;
        }

        this.maxTitleDebth = 1;
        this.orderCounter = 1;

        Object.keys(this.columnsConfig).forEach(key => {
            if (this.columnsConfig[key].getSubTitle.length > this.maxTitleDebth)
                this.maxTitleDebth = this.columnsConfig[key].getSubTitle.length;
        });

        this.refreshCrossborderInvoiceAsync(filter, null, 1);

    }

    getMainColumnKeys() {
        return sortBy(Object.keys(this.columnsConfig), ['order']);
    }

    clearHeaderClassName() {
        this.visibleGroupCounter = 0;
    }

    getNextHeaderClassName() {
        this.visibleGroupCounter = (this.visibleGroupCounter + 1) % 2;
        return this.dataGroupClasses[this.visibleGroupCounter];
    }

    headerToKey(header) {
        if (!!header?.props)
            return isArray(header.props.children) ?
                header.props.children.map(h => { return this.headerToKey(h) })?.join('_')
                : this.headerToKey(header.props.children);

        return header;
    }

    renderHeaders() {

        let data = this.state.hourly_details;

        if (!data['price_details']) {
            return null;
        }

        const subHeaderRows = [];

        const visibleMainKeys = this.getMainColumnKeys();

        for (let rowIdx = 0; rowIdx < this.maxTitleDebth; rowIdx++) {
            this.clearHeaderClassName();

            subHeaderRows.push({ key: `sub_header_row_${rowIdx}`, children: [] });

            for (let mainKeyIdx = 0; mainKeyIdx < visibleMainKeys.length; mainKeyIdx++) {
                const columnGroupKey = visibleMainKeys[mainKeyIdx];
                const currentGroup = this.columnsConfig[columnGroupKey];

                if (!data[columnGroupKey] || !data[columnGroupKey].length)
                    continue;

                const headerClass = this.getNextHeaderClassName();

                const sortedData = currentGroup.sort(data[columnGroupKey]);

                for (let dataIdx = 0; dataIdx < sortedData.length; dataIdx++) {
                    const subColumn = sortedData[dataIdx];
                    const currentColumnIdx = subHeaderRows[rowIdx].children.length;
                    let parent;

                    if (rowIdx === 0)
                        parent = columnGroupKey;
                    else {
                        const parentDict = subHeaderRows[rowIdx - 1].children[currentColumnIdx];
                        parent = parentDict.parent + this.headerToKey(parentDict?.title);
                    }

                    if (currentGroup.getSubTitle.length - 1 < rowIdx) {
                        subHeaderRows[rowIdx].children.push({
                            colSpan: 0,
                            key: `${columnGroupKey}_${rowIdx}_${currentColumnIdx}`,
                            parent,
                        });
                        continue;
                    }

                    let rowSpan = 1;

                    if (currentGroup.getSubTitle.length - 1 === rowIdx && rowIdx < this.maxTitleDebth - 1)
                        rowSpan += this.maxTitleDebth - rowIdx - 1;

                    const subTitle = currentGroup.getSubTitle[rowIdx](subColumn);
                    const subTitleStr = this.headerToKey(subTitle);

                    subHeaderRows[rowIdx].children.push({
                        colSpan: 1,
                        key: `${columnGroupKey}_${rowIdx}_${currentColumnIdx}`,
                        group: columnGroupKey,
                        rowSpan: rowSpan,
                        className: `${headerClass} ${currentGroup.className || ''}`,
                        title: subTitle,
                        parent,
                        subTitleStr
                    });
                }
            }
        }

        for (let i = 0; i < this.maxTitleDebth; i++) {
            let previousSuccessfulColumn = subHeaderRows[i].children[0];

            for (let j = 1; j < subHeaderRows[i].children.length; j++) {
                const current = subHeaderRows[i].children[j];

                if (!current || !current.colSpan)
                    continue;

                if (previousSuccessfulColumn
                    && previousSuccessfulColumn.group === current.group
                    && previousSuccessfulColumn.parent === current.parent
                    && previousSuccessfulColumn.subTitleStr === current.subTitleStr) {
                    previousSuccessfulColumn.colSpan++;
                    current.colSpan = 0;
                }
                else {
                    previousSuccessfulColumn = current;
                }
            }
        }

        this.clearHeaderClassName();

        const mainColumns = [];

        visibleMainKeys.forEach(columnGroupKey => {
            if (!data[columnGroupKey])
                return;

            const currentGroup = this.columnsConfig[columnGroupKey];
            const columnCount = data[columnGroupKey].length;

            if (!columnCount)
                return;

            mainColumns.push({
                key: columnGroupKey,
                rowSpan: currentGroup.rowSpan ?? 1,
                className: `${this.getNextHeaderClassName()} ${currentGroup.className}`,
                colSpan: columnCount,
                title: currentGroup.title
            });
        });

        return (
            <React.Fragment>
                <tr>
                    <th rowSpan={this.maxTitleDebth + 1} className='v-cb-invoice-date-header'>
                        {'Date(CET)'}
                    </th>
                    {
                        mainColumns.map(columnData => {
                            return (
                                <th key={columnData.key}
                                    rowSpan={columnData.rowSpan}
                                    className={columnData.className}
                                    colSpan={columnData.colSpan}
                                    title={columnData.title}>
                                    {columnData.title}
                                </th>
                            );
                        })
                    }
                </tr>
                {subHeaderRows.map(row => {
                    return (
                        <tr key={row.key}>
                            {row.children.filter(c => c?.colSpan).map(cell => {
                                return (
                                    <th key={cell.key}
                                        parent={cell.parent ?? "EMPTY"}
                                        subtitlestr={cell.subTitleStr}
                                        rowSpan={cell.rowSpan}
                                        colSpan={cell.colSpan}
                                        className={cell.className}
                                        title={isObject(cell.title) ? cell.title.props.children[0] : cell.title}>
                                        {cell.title}
                                    </th>
                                );
                            })}
                        </tr>
                    )
                })}
            </React.Fragment>
        );
    }

    getClassByData(data) {
        if (this.props.directions
            && this.props.directions.length > 0
            && data.direction
            && data.direction !== this.pnlAllTotalColumn
            && data.type !== this.intradayColumnType
            && !this.props.directions.find(d => d === data.direction))
            return this.weakColumnClass;

        return '';
    }

    renderRows() {

        let rowClassOrder = 0;

        let data = this.state.hourly_details;

        if (!data['price_details']) {
            return;
        }

        const referenceData = data['price_details'][0].data;
        const visibleMainKeys = this.getMainColumnKeys();

        return (
            referenceData.map((referenceRow, rowIdx) => {
                if (rowIdx && referenceRow['dt'].split(' ')[0] !== referenceData[rowIdx - 1]['dt'].split(' ')[0])
                    rowClassOrder = (rowClassOrder + 1) % 2

                const rowKey = referenceRow['dt'];
                let row_style;

                if (referenceRow['last_hour'] === "true") {
                    row_style = { borderRight: 'solid 3px #979797', padding: '2px 8px', backgroundColor: '#b2b2b2' }
                }
                else {
                    row_style = { borderRight: 'solid 3px #979797', padding: '2px 8px' }
                }

                return (
                    <tr key={rowKey} className={this.rowClasses[rowClassOrder]}>
                        <td style={row_style}>
                            {referenceRow['dt']}
                        </td>
                        {
                            visibleMainKeys.map(columnKey => {
                                const currentGroup = this.columnsConfig[columnKey];

                                return currentGroup.sort(data[columnKey]).map((d, columnIdx) => {
                                    return (
                                        <td key={`${rowKey}_${columnIdx}`}
                                            className={`${currentGroup.getValueClass ? currentGroup.getValueClass(d.data[rowIdx].v) : ''} ${this.getClassByData(d)}`}>
                                            {columnKey === 'exchange_rate_details' ? d.data[rowIdx].v.toLocaleString("en-us", { maximumFractionDigits: 4 }) : d.data[rowIdx].v.toLocaleString("en-us", { maximumFractionDigits: 2 })}
                                        </td>
                                    )
                                })
                            })
                        }
                    </tr>
                )
            })
        );
    }

    exportInvoice = async () => {

        const spinnerKey = this.spinner.showSpinner();
        let body = {
            start_date: extractDate(this.state.selectedStartDate),
            counter_party: this.state.selectedCounterPartyOption.value,
            payment_option_list: Object.keys(this.state.paymentOptions).filter(p => this.state.paymentOptions[p].checked)
        }

        let response;
        try {
            response = await getCrossborderInvoiceFile(body);
            let reportDate = moment(this.state.activeFilter.start_date);
            let reportMonth = (reportDate.month() + 1) < 10 ? `0${reportDate.month() + 1}` : `${reportDate.month() + 1}`;
            const filename = `${this.state.activeFilter.counter_party} Crossborder Invoice - ${reportMonth}/${reportDate.year()}.xlsx`;
            const contentType = response.headers["content-type"];

            const blob = new Blob([response.data], { type: contentType });

            if (contentType === 'application/json') {
                //const error = blob.text();
                return;
            }

            FileSaver.saveAs(blob, filename);
            return;
        } catch (error) {
            handleApiError(error);
            return false;
        }
        finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    onDetailsPageChanged(recordCount, pageNum) {
        this.refreshCrossborderInvoiceAsync(null, recordCount, pageNum);
    }

    getTotalRowCount() {

        const selectedStartDate = this.state.selectedStartDate;
        const selectedEndDate = new Date(selectedStartDate.getFullYear(), selectedStartDate.getMonth() + 1, 0);
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setDate(today.getDate() + 1);
        if (today > selectedEndDate || today === selectedEndDate) {
            return (selectedEndDate.getDate() - selectedStartDate.getDate() + 1) * 24
        }
        else {
            return (today.getDate() - selectedStartDate.getDate() + 1) * 24
        }
    }

    getPaging(pagerId) {
        const currentPage = this.state.currentDetailsPage;
        const recordsPerPageCount = this.props.recordsPerPageCount;
        const totalCount = this.getTotalRowCount();

        return <VPageNavigation
            key={`${currentPage}${recordsPerPageCount}${totalCount}${pagerId}`}
            onPageChanged={(recordCount, pageNum) => this.onDetailsPageChanged(recordCount, pageNum)}
            currentPage={currentPage}
            recordsPerPageCount={this.state.detailRecordsPerPageCount}
            recordCountOptions={this.pageRecordCountOptions}
            totalCount={totalCount} />;
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Crossborder Invoice">
                    <VFilterContainer
                        showActiveFilter
                        activeFilter={this.state.activeFilterToDisplay}
                    >
                        {<div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                            </div>
                            <div>
                                <VDatePicker
                                    selectedDate={this.state.selectedStartDate}
                                    onSelectedDateChange={(selectedDate) => this.setState({ selectedStartDate: selectedDate })}
                                    maxDate={getDate(1)}
                                    disabled={this.state.disableFilter}
                                    type={VDatePickerTypes.month}
                                />
                            </div>
                        </div>}

                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Counter Party
                            </div>
                            <div>
                                <VDropdown
                                    width="large"
                                    options={this.state.counterPartyOptions}
                                    value={this.state.selectedCounterPartyOption}
                                    onSelectedOptionChange={(selectedCounterPartyOption) => {
                                        this.setState({
                                            selectedCounterParty: selectedCounterPartyOption.value,
                                            selectedCounterPartyOption: selectedCounterPartyOption
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Payment Option
                            </div>
                            <div>
                                <VCheckboxGroup
                                    items={this.state.paymentOptions}
                                    onCheckChanged={(k, updatedItems) => this.setState({ paymentOptions: { ...updatedItems } })}
                                />
                            </div>
                        </div>

                        <div className="v-filter-buttons">
                            <button
                                disabled={this.state.disableFilter}
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={this.onShowButtonClick}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                            </button>
                            <button
                                disabled={this.state.disableFilter}
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={() => this.exportInvoice()}>
                                <i aria-hidden="true" className="fa fa-cloud-download fa-fw" />Download
                            </button>
                        </div>
                    </VFilterContainer>
                    <VMainContainer>
                        <React.Fragment>
                            <VTabs>
                                <VTab key='HourlyDetails'
                                    eventKey='HourlyDetails'
                                    title='Hourly Details'>
                                    <div className='v-cb-invoice-summary-table'>
                                        <table className="table table-borderless table-responsive">
                                            <thead>
                                                {this.renderHeaders()}
                                            </thead>
                                            <tbody>
                                                {this.renderRows()}
                                            </tbody>
                                        </table>
                                    </div>
                                    {this.getPaging(1)}
                                </VTab>
                                <VTab key='WeeklyDetails'
                                    eventKey='WeeklyDetails'
                                    title='Weekly Details'>
                                    <div className='v-cb-invoice-summary-table'>
                                        {this.createWeeklyPerformaDetails()}
                                    </div>
                                </VTab>
                                <VTab key='WeeklyMonthlyInvoices'
                                    eventKey='WeeklyMonthlyInvoices'
                                    title='Weekly & Monthly Invoices'>
                                    <div className='v-cb-invoice-summary-table'>
                                        <div className='v-infinite-width v-table-list'>
                                            {this.createWeeklyInvoices()}
                                        </div>
                                        {this.createWeeklyPnlAnaylses()}
                                        {this.createWeeklyPaymentDetails()}
                                        {this.createMonthlyInvoiceDetails()}
                                    </div>
                                </VTab>
                                {this.state.selectedCounterPartyConfig.hourlyCurrency &&
                                    this.renderHourlyExchangeRates()
                                }
                            </VTabs>


                        </React.Fragment>

                    </VMainContainer>
                </VContentContainer>

            </React.Fragment>
        );
    }


}

export default CrossborderInvoice;