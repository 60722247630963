import React from 'react';
import './FileUploader.css';

class FileUploader extends React.Component {

    handleChange = (event) => {
        
        if (this.props.addFiles) {
            this.props.addFiles(event.target.files)
        }
    }

    render() {
        return (
            <form>
                <label style={{ padding: "20px" }} htmlFor="fileUpload">Upload files</label>
                <input type="file" id="file" multiple name="file" onChange={this.handleChange} />
            </form>
        )
    }
}

export default FileUploader;