import {
    LOG_OUT,
    SET_USER
} from '../actions/types';

const INITIAL_STATE = {
    userId: "",
    userName: "",
    roles: [],
    isLoggedIn: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOG_OUT:
            return {
                ...state,
                ...INITIAL_STATE
            };
        case SET_USER:
            return {
                ...state,
                ...INITIAL_STATE,
                ...action.payload,
                isLoggedIn: action.payload && action.payload.userName && action.payload.userName.length > 0
            };
        default:
            return state;
    }
};