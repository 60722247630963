import React from 'react';
import DataGrid from 'react-data-grid';
import { groupBy as rowGrouper } from 'lodash';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer';
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import PnlFilter from './PnlFilter';
import { getMarketNames, getBookDetails, getCompaniesDetail, getEtrmPnl } from '../../api/services';
import { handleApiError, alertError } from '../../utils/errorHandler';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import history from '../../../history';
import { messages } from '../../utils/messages';
import { exportToXlsx } from '../../components/TTable/TTableExportUtils';
import { getPnl } from '../../../apis/vitusApi';
import Radio from '@material-ui/core/Radio';
import InfoIcon from '@material-ui/icons/Info';
import TDialogBox from '../../components/TDialogBox';

const month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const columns = [
    {
        key: 'country',
        name: 'Country',
    },
    {
        key: 'period',
        name: 'Data',
        width: 100,
    },
    {
        key: 'type',
        name: 'Type'
    },
    {
        key: 'jan',
        name: 'Jan',
        formatter({ row }) {
            return <>{valueFormatter(row.jan)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "jan")
        }
    },
    {
        key: 'feb',
        name: 'Feb',
        formatter({ row }) {
            return <>{valueFormatter(row.feb)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "feb")
        }
    },
    {
        key: 'mar',
        name: 'Mar',
        formatter({ row }) {
            return <>{valueFormatter(row.mar)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "mar")
        }
    },
    {
        key: 'apr',
        name: 'Apr',
        formatter({ row }) {
            return <>{valueFormatter(row.apr)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "apr")
        }
    },
    {
        key: 'may',
        name: 'May',
        formatter({ row }) {
            return <>{valueFormatter(row.may)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "may")
        }
    },
    {
        key: 'jun',
        name: 'Jun',
        formatter({ row }) {
            return <>{valueFormatter(row.jun)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "jun")
        }
    },
    {
        key: 'jul',
        name: 'Jul',
        formatter({ row }) {
            return <>{valueFormatter(row.jul)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "jul")
        }
    },
    {
        key: 'aug',
        name: 'Aug',
        formatter({ row }) {
            return <>{valueFormatter(row.aug)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "aug")
        }
    },
    {
        key: 'sep',
        name: 'Sep',
        formatter({ row }) {
            return <>{valueFormatter(row.sep)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "sep")
        }
    },
    {
        key: 'oct',
        name: 'Oct',
        formatter({ row }) {
            return <>{valueFormatter(row.oct)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "oct")
        }
    },
    {
        key: 'nov',
        name: 'Nov',
        formatter({ row }) {
            return <>{valueFormatter(row.nov)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "nov")
        }
    },
    {
        key: 'dec',
        name: 'Dec',
        formatter({ row }) {
            return <>{valueFormatter(row.dec)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "dec")
        }
    },
    {
        key: 'total',
        name: 'Total',
        formatter({ row }) {
            if (row.period === "Mark" || row.period === "Peak" || row.period === "Offpeak") {
                const sum = row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec
                return <>{(sum/12).toFixed(2)}</>;
            }
            const sum = row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec
            return <>{valueFormatter(sum)}</>;
        },
        groupFormatter({ childRows }) {
            let period_list = new Set(childRows.map(r =>  r.period))

            if (period_list.size === 1) {
                if (period_list.has("Mark") || period_list.has("Peak") || period_list.has("Offpeak")) {
                    childRows = childRows.filter(r => r.type === 'Last')
                    const sum = childRows.reduce((prev, row) => prev + row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec, 0)
                    return <>{(sum/12).toFixed(2)}</>;
                }
        
                if (period_list.has("Positions")) {
                    childRows = childRows.filter(r => r.type === 'Last Peak' || r.type === 'Last Offpeak')
                    const sum = childRows.reduce((prev, row) => prev + row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec, 0)
                    return <>{(sum / childRows.length).toFixed(2)}</>;
                }

                if (period_list.has("PNL")) {
                    childRows = childRows.filter(r => r.period === "PNL" && r.type === "Last")
                }
                const sum = childRows.reduce((prev, row) => prev + row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec, 0)
                return <>{valueFormatter(sum)}</>;
            }
           
            childRows = childRows.filter(r => r.period === "PNL" && r.type === "Last")
            const sum = childRows.reduce((prev, row) => prev + row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec, 0)
            return <>{valueFormatter(sum)}</>;
        }
    }
];

const curr_columns = [
    {
        key: 'currency',
        name: 'Currency',
    },
    {
        key: 'type',
        name: 'Type'
    },
    {
        key: 'jan',
        name: 'Jan',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { jan }) => prev + jan, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'feb',
        name: 'Feb',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { feb }) => prev + feb, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'mar',
        name: 'Mar',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { mar }) => prev + mar, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'apr',
        name: 'Apr',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { apr }) => prev + apr, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'may',
        name: 'May',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { may }) => prev + may, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'jun',
        name: 'Jun',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { jun }) => prev + jun, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'jul',
        name: 'Jul',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { jul }) => prev + jul, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'aug',
        name: 'Aug',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { aug }) => prev + aug, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'sep',
        name: 'Sep',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { sep }) => prev + sep, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'oct',
        name: 'Oct',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { oct }) => prev + oct, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'nov',
        name: 'Nov',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { nov }) => prev + nov, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'dec',
        name: 'Dec',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { dec }) => prev + dec, 0);
            const avg = (sum / childRows.length).toFixed(5) || 0;
            return <>{avg}</>;
        }
    },
    {
        key: 'average',
        name: 'Average',
        formatter({ row }) {
            return <>{((row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec)/12).toFixed(4)}</>;
        },
        groupFormatter({ childRows }) {
            childRows = childRows.filter(r => r.type === "Last")
            const sum = childRows.reduce((prev, row) => prev + row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec, 0)
            const avg = (sum / 12) || 0;
            return <>{avg.toFixed(4)}</>;
        }
        
    }
];

const total_columns = [
    {
        key: 'country',
        name: 'Country',
    },
    {
        key: 'period',
        name: 'Data',
        width: 100,
    },
    {
        key: 'type',
        name: 'Type'
    },
    {
        key: 'jan',
        name: 'Jan',
        formatter({ row }) {
            return <>{valueFormatter(row.jan)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "jan")
        }
    },
    {
        key: 'feb',
        name: 'Feb',
        formatter({ row }) {
            return <>{valueFormatter(row.feb)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "feb")
        }
    },
    {
        key: 'mar',
        name: 'Mar',
        formatter({ row }) {
            return <>{valueFormatter(row.mar)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "mar")
        }
    },
    {
        key: 'apr',
        name: 'Apr',
        formatter({ row }) {
            return <>{valueFormatter(row.apr)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "apr")
        }
    },
    {
        key: 'may',
        name: 'May',
        formatter({ row }) {
            return <>{valueFormatter(row.may)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "may")
        }
    },
    {
        key: 'jun',
        name: 'Jun',
        formatter({ row }) {
            return <>{valueFormatter(row.jun)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "jun")
        }
    },
    {
        key: 'jul',
        name: 'Jul',
        formatter({ row }) {
            return <>{valueFormatter(row.jul)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "jul")
        }
    },
    {
        key: 'aug',
        name: 'Aug',
        formatter({ row }) {
            return <>{valueFormatter(row.aug)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "aug")
        }
    },
    {
        key: 'sep',
        name: 'Sep',
        formatter({ row }) {
            return <>{valueFormatter(row.sep)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "sep")
        }
    },
    {
        key: 'oct',
        name: 'Oct',
        formatter({ row }) {
            return <>{valueFormatter(row.oct)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "oct")
        }
    },
    {
        key: 'nov',
        name: 'Nov',
        formatter({ row }) {
            return <>{valueFormatter(row.nov)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "nov")
        }
    },
    {
        key: 'dec',
        name: 'Dec',
        formatter({ row }) {
            return <>{valueFormatter(row.dec)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "dec")
        }
    },
    {
        key: 'total',
        name: 'Total',
        groupFormatter({ childRows }) {
            let type_list = new Set(childRows.map(r => r.type))
            if (type_list.size === 1) {
                const sum = childRows.reduce((prev, row) => prev + row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec, 0)
                return <>{valueFormatter(sum)}</>;
                
            }
            childRows = childRows.filter(r => r.period === "PNL" && r.type === "Last")
            const sum = childRows.reduce((prev, row) => prev + row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec, 0)
            return <>{valueFormatter(sum)}</>;
        }
    }
];

const source_columns = [
    {
        key: 'country',
        name: 'Market',
       
    },
    {
        key: 'vitus',
        name: 'Vitus',
        formatter: ({ row }) => {
            return (<Radio checked={row.vitus} color='primary'/>)
        }
    },
    {
        key: 'ice',
        name: 'ICE',
        formatter: ({ row }) => {
            return (<Radio checked={row.ice} color='primary'/>)
        }
    },
    {
        key: 'ice_last_trades',
        name: 'ICE LT',
        formatter: ({ row }) => {
            return (<Radio checked={row.ice_last_trades} color='primary'/>)
        }
    },
    {
        key: 'eex',
        name: 'EEX',
        formatter: ({ row }) => {
            return (<Radio checked={row.eex} color='primary'/>)
        }
    },
    {
        key: 'viop',
        name: 'VIOP',
        formatter: ({ row }) => {
            return (<Radio checked={row.viop} color='primary'/>)
        }
    },
]

function pnlCountryGroupFormatter(childRows, column) {
    let period_list = new Set(childRows.map(r =>  r.period))
           
    if (period_list.size === 1) {
        if (period_list.has("Mark") || period_list.has("Peak") || period_list.has("Offpeak") || period_list.has("PNL")) {
            childRows = childRows.filter(r => r.type === 'Last')
            const sum = childRows.reduce((prev, row) => prev + row[column], 0)
            return <>{valueFormatter(sum)}</>;
        }

        if (period_list.has("Positions")) {
            childRows = childRows.filter(r => r.type === 'Last Peak' || r.type === 'Last Offpeak')
            const sum = childRows.reduce((prev, row) => prev + row[column], 0)
            return <>{valueFormatter(sum / childRows.length)}</>;
        }
        const sum = childRows.reduce((prev, row) => prev + row[column], 0)
        return <>{valueFormatter(sum)}</>;
    }
    childRows = childRows.filter(r => r.period === "PNL" && r.type === 'Last')
    const sum = childRows.reduce((prev, row) => prev + row[column], 0)
        return <>{valueFormatter(sum)}</>;
}

function totalPnlGroupFormatter(childRows, column) {
    let type_list = new Set(childRows.map(r => r.type))

    if (type_list.size === 1) {
        const sum = childRows.reduce((prev, row) => prev + row[column], 0)
        return <>{valueFormatter(sum)}</>;
    }
            
    childRows = childRows.filter(r => r.period === "PNL" && r.type === "Last")
    const sum = childRows.reduce((prev, row) => prev + row[column], 0)
    return <>{valueFormatter(sum)}</>;
}

function valueFormatter(value) {
    return value.toLocaleString(undefined, {maximumFractionDigits:2})
}


function rowKeyGetter(row) {
    return row.id;
}

const options = ['country', 'period'];

class Pnl extends React.Component {
    title = "PNL"

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        rows: [],
        currencyRows: [],
        sourceRows: [],
        selectedRows: new Set(),
        selectedCurrencyRows: new Set(),
        selectedTotalRows: new Set(),
        selectedOptions: [
            options[0],
            options[1],
        ],
        expandedGroupIds: new Set([]),
        expandedCurrencyGroupIds: new Set([]),
        expandedTotalGroupIds: new Set([]),
        counterPartyNames: [],
        marketNames: [],
        bookNames: [],
        openInfoDialogBox: false

    }

    componentDidMount() {        
        this.getCompaniesDetail();
        this.getMarketDetails()
        this.getBookDetails()
    }

    onShowButtonClick = async (filter) => {
        this.spinner.showSpinner('getPnl');
        getEtrmPnl(filter)
            .then(response => {
                if (response.data.success) {
                    this.setState({ 
                        rows: response.data.success.result_list,
                        currencyRows:  response.data.success.currency_list,
                        sourceRows: response.data.success.price_sources
                    })
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(async () => {
                this.spinner.hideSpinner('getPnl');

                if (filter.fetch_cb_pnl) {
                    if (!filter.company_list.length || filter.company_list.length === 2 ) {
                        await this.getCBPnl(filter, 'Vitus')
                        this.getCBPnl(filter, 'Vitus Capital')
                    } else {
                        if (filter.has_vitus_capital) {
                            this.getCBPnl(filter, 'Vitus Capital')
                        } else {
                            this.getCBPnl(filter, 'Vitus')
                        }
                    }
                    
                }
                
            });

    }

    getCBPnl = async (filter, company) => {
        let year = filter.year.toString().substr(2)
        let request_filter = {
            start_date: filter.start_date,
            end_date: filter.end_date,
            currency: filter.currency,
            grouping: "Monthly",
            company_list: [company],
        }

        
        let month_map = {}
        month_names.forEach(m => month_map[`${m}-${year}`] = m.toLowerCase())

        let cb_pre_row = {
            "id": `${company}_pre`,
            "period": "PNL",
            "type": "Previous",
            "country": `CB ${company}`,
            "jan": 0,
            "feb": 0,
            "mar": 0,
            "apr": 0,
            "may": 0,
            "jun": 0,
            "jul": 0,
            "aug": 0,
            "sep": 0,
            "oct": 0,
            "nov": 0,
            "dec": 0,
        }

        let cb_last_row = {
            "id": `${company}_last`,
            "period": "PNL",
            "type": "Last",
            "country": `CB ${company}`,
            "jan": 0,
            "feb": 0,
            "mar": 0,
            "apr": 0,
            "may": 0,
            "jun": 0,
            "jul": 0,
            "aug": 0,
            "sep": 0,
            "oct": 0,
            "nov": 0,
            "dec": 0,
        }

        let cb_change_row = {
            "id": `${company}_change`,
            "period": "PNL",
            "type": "Change",
            "country": `CB ${company}`,
            "jan": 0,
            "feb": 0,
            "mar": 0,
            "apr": 0,
            "may": 0,
            "jun": 0,
            "jul": 0,
            "aug": 0,
            "sep": 0,
            "oct": 0,
            "nov": 0,
            "dec": 0,
        }

        return getPnl(request_filter)
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    let sumOfBorders = successData.pnl_summary?.sum_of_borders
                    if (sumOfBorders) {
                        sumOfBorders.map(cb => {
                            cb_pre_row[month_map[cb.dt]] = cb.previous
                            cb_last_row[month_map[cb.dt]] = cb.today
                            cb_change_row[month_map[cb.dt]] = cb.today - cb.previous
                        })

                        this.setState({rows: [...this.state.rows, cb_pre_row, cb_last_row, cb_change_row]})

                    }
                }
            }, error => {
                handleApiError(error);
            })

    }

    async getCompaniesDetail() {
        this.spinner.showSpinner('getCompanyNames');
        getCompaniesDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        counterPartyNames: successData.company_list.map(x => (
                            {
                                id: x.id,
                                value: x.name,
                                label: x.name,
                                type: x.type,
                            }
                        )),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getCompanyNames');
            });
    }

    async getMarketDetails() {
        this.spinner.showSpinner('getMarketNames');
        getMarketNames()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        marketNames: successData.market_name_list.map(x => ({ 
                            id: x.id, 
                            value: x.name,
                            label: x.name, 
                            currency: x.currency })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getMarketNames');
            });
    }

    async getBookDetails() {
        this.spinner.showSpinner('getBookNames');
        getBookDetails()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        bookNames: successData.book_list.map(x => ({ 
                            id: x.id, 
                            value: x.name, 
                            label: x.name })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getBookNames');
            });
    }

    onInfoButtonClick() {
        this.setState({ openInfoDialogBox: true})
    }

    closeInfoDialogBox() {
        this.setState({ openInfoDialogBox: false })
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        { this.state.sourceRows.length ? (
                            <InfoIcon style={{margin: "5px"}} onClick={() => this.onInfoButtonClick()}></InfoIcon>
                            ) : null
                        }
                        {
                            this.state.currencyRows?.length ? (
                                <DataGrid
                                    columns={curr_columns}
                                    rows={this.state.currencyRows}
                                    defaultColumnOptions={{ resizable: true }}
                                    groupBy={["currency"]}
                                    rowGrouper={rowGrouper}
                                    rowKeyGetter={rowKeyGetter}
                                    selectedRows={this.state.selectedCurrencyRows}
                                    onSelectedRowsChange={rows => this.setState({ selectedCurrencyRows: rows })}
                                    expandedGroupIds={this.state.expandedCurrencyGroupIds}
                                    onExpandedGroupIdsChange={ids => this.setState({ expandedCurrencyGroupIds: ids })}
                                    style={{height: "300px"}}
                                />
                            ): null
                        }
                        <DataGrid
                            columns={total_columns}
                            rows={this.state.rows.filter(r => r.period === "PNL")}
                            rowKeyGetter={rowKeyGetter}
                            selectedRows={this.state.selectedTotalRows}
                            onSelectedRowsChange={rows => this.setState({ selectedTotalRows: rows })}
                            groupBy={["period", "type"]}
                            rowGrouper={rowGrouper}
                            expandedGroupIds={this.state.expandedTotalGroupIds}
                            onExpandedGroupIdsChange={ids => this.setState({ expandedTotalGroupIds: ids })}
                            defaultColumnOptions={{ resizable: true }}
                            style={{height: "300px"}}
                        />
                        <button type="button" className="btn t-orange-button" onClick={() => { exportToXlsx(columns, this.state.rows, "PNL"); }}>
                            Export to XLSX
                        </button>
                        <DataGrid
                            columns={columns}
                            rows={this.state.rows}
                            rowKeyGetter={rowKeyGetter}
                            selectedRows={this.state.selectedRows}
                            onSelectedRowsChange={rows => this.setState({ selectedRows: rows })}
                            groupBy={this.state.selectedOptions}
                            rowGrouper={rowGrouper}
                            expandedGroupIds={this.state.expandedGroupIds}
                            onExpandedGroupIdsChange={ids => this.setState({ expandedGroupIds: ids })}
                            defaultColumnOptions={{ resizable: true }}
                        />
                        

                        <TDialogBox
                            openAddDialogBox={this.state.openInfoDialogBox}
                            handleClose={() => this.closeInfoDialogBox()}
                            title={"Price Sources"}
                        >
                            <DataGrid
                                    columns={source_columns}
                                    rows={this.state.sourceRows}
                                    defaultColumnOptions={{ resizable: true }}
                                    style={{height: "500px"}}
                                />
                        </TDialogBox>
                    </div>
                }
                filterComponent={
                    this.state.counterPartyNames.length  &&
                     this.state.marketNames.length &&
                      this.state.bookNames.length ? (
                    <PnlFilter
                        counterPartyNames={this.state.counterPartyNames}
                        marketNames={this.state.marketNames}
                        bookNames={this.state.bookNames}
                        onShowButtonClick={this.onShowButtonClick}
                    ></PnlFilter>
                    ) : null
                    
                }
            >
            </LeftSideContainer>
        )
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                >
                </TContentDetailContainer>
            </TContentContainer>
        );
    }
}

export default Pnl;