export const categories = {
    test: {
        title: "Test",
        order: 10,
        pages: [
            {
                id: "testPage1",
                order: 2,
                title: "Test Daily Liquidation",
                icon: "fa fa-cogs",
                route: "/testPage1",
                pageJsClass: "TestPage1"
            },
            {
                id: "testPage2",
                order: 1,
                title: "Test Pnl",
                icon: "fa fa-cogs",
                route: "/testPage2",
                pageJsClass: "TestPage2"
            },
            {
                id: "testPage3",
                order: 3,
                title: "Test CB Breakdown",
                icon: "fa fa-cogs",
                route: "/testPage3",
                pageJsClass: "TestPage3"
            }
        ]
    },
    powerData: {
        title: "Power Data",
        order: 3,
        pages: [
            {
                id: "dayAheadPriceForecasts",
                order: 1,
                icon: "fa fa-diamond",
                title: "Day Ahead Price Forecasts",
                route: ""
            },
            {
                id: "dayAheadAuctionResults",
                order: 2,
                icon: "fa fa-calendar-check-o",
                title: "Day Ahead Auction Results",
                route: ""
            },
            {
                id: "dayAheadRealizedPrices",
                order: 3,
                icon: "fa fa-bolt",
                title: "Day Ahead Realized Prices",
                route: ""
            }
        ]
    },

    crossBorderOperations: {
        title: "Cross Border Operations",
        order: 1,
        pages: [
            {
                id: "parameters",
                order: 1,
                icon: "fa fa-sliders",
                title: "Parameters",
                route: "/parameters",
                pageJsClass: "Parameters"
            },
            {
                id: "serviceCosts",
                order: 2,
                icon: "fa fa-clipboard",
                title: "Service Costs",
                route: ""
            },
            {
                id: "marginAnalysis",
                order: 3,
                icon: "fa fa-calculator",
                title: "Margin Analysis",
                route: "/marginAnalysis",
                pageJsClass: "MarginAnalysis"
            },
            {
                id: "bids",
                order: 4,
                icon: "fa fa-tasks",
                title: "Auction Bids",
                route: "/bids",
                pageJsClass: "AuctionBids"
            },
            {
                id: "auctionAllocations",
                order: 5,
                icon: "fa fa-handshake-o",
                title: "Auction Allocations",
                route: "/auctionAllocations",
                pageJsClass: "AuctionAllocations"
            },
            {
                id: "exchangeOffers",
                order: 6,
                icon: "fa fa-exchange",
                title: "Exchange Offers",
                route: "/exchangeOffers",
                pageJsClass: "ExchangeOffers"
            },
            {
                id: "exchangeResults",
                order: 7,
                icon: "fa fa-calendar-check-o",
                title: "Exchange Results",
                route: "/exchangeResults",
                pageJsClass: "ExchangeResults"
            },
            {
                id: "physicalFlows",
                order: 8,
                icon: "fa fa-random",
                title: "ST Physical Flows",
                route: "/physicalFlows",
                pageJsClass: "PhysicalFlows"
            },
            {
                id: "ltAuctionAllocations",
                order: 9,
                icon: "fa fa-arrow-right",
                title: "LT Auction Allocations",
                route: "/ltAuctionAllocations",
                pageJsClass: "LTAuctionAllocations"
            },
            {
                id: "ltPhysicalFlows",
                order: 10,
                icon: "fa fa-random",
                title: "LT Physical Flows",
                route: "/ltPhysicalFlows",
                pageJsClass: "LTPhysicalFlows"
            },
            {
                id: "intradayResults",
                order: 11,
                icon: "fa fa-circle-o-notch",
                title: "Intraday Results",
                route: "/intradayResults",
                pageJsClass: "IntradayResults"
            },
            {
                id: "serviceCosts",
                order: 12,
                icon: "fa fa-envelope-open-o",
                title: "Service Costs",
                route: "/serviceCosts",
                pageJsClass: "ServiceCosts"
            },
            {
                id: "powerSchedules",
                order: 13,
                icon: "fa fa-random",
                title: "Power Schedules",
                route: "/powerSchedules",
                pageJsClass: "PowerSchedules"
            },
            {
                id: "dailyEnergyCosts",
                order: 14,
                icon: "fa fa-calculator",
                title: "Daily Energy Costs / Revenues based on Exchanges",
                route: "/dailyEnergyCosts",
                pageJsClass: "DailyEnergyCosts"
            },
            {
                id: "teiasDailyEntries",
                order: 15,
                icon: "fa fa-random",
                title: "Teias Daily Entries",
                route: "/teiasDailyEntries",
                pageJsClass: "TeiasDailyEntries"
            },
            {
                id: "crossborderInvoice",
                order: 16,
                icon: "fa fa-line-chart",
                title: "Crossborder Invoice",
                route: "/crossborderInvoice",
                pageJsClass: "CrossborderInvoice"
            }
        ]
    },

    pnl: {
        title: "PnL",
        order: 2,
        pages: [
            {
                id: "pnl",
                order: 1,
                icon: "fa fa-line-chart",
                title: "PnL Report",
                route: "/pnl",
                pageJsClass: "PnLReport"
            },
            {
                id: "dailyLiquidation",
                order: 2,
                icon: "fa fa-bar-chart",
                title: "Daily Liquidation",
                route: "/dailyLiquidation",
                pageJsClass: "DailyLiquidation"
            },
            {
                id: "customPnl",
                order: 3,
                icon: "fa fa-wrench",
                title: "Custom PnL",
                route: "/customPnl",
                pageJsClass: "CustomPnl"
            }
        ]
    }
};

export const pages = (() => {
    let pagesObj = {};
    let currentPages = [];

    for (let category in categories) {
        currentPages = categories[category].pages;
        for (let i = 0; i < currentPages.length; i++) {
            pagesObj[currentPages[i].id] = {
                ...currentPages[i],
                category: category
            }
        }
    }

    return pagesObj;
})();