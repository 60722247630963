import React from 'react';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer';
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import TConfirmationModal from '../../components/TConfirmationModal/TConfirmationModal';
import { TfilterTypes } from '../../components/TTable/TTable';
import TTable from '../../components/TTable/TTable';
import TabPanel from '../../components/TTabs/TabPanel';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import AccordionActions from '@material-ui/core/AccordionActions'
import Divider from '@material-ui/core/Divider';
import { handleApiError, alertError, alertSuccess } from '../../utils/errorHandler';
import { messages } from '../../utils/messages';
import history from '../../../history';
import DocumentDetail from './DocumentDetail';
import TBaseModal from '../../components/TBaseModal/TBaseModal';
import { saveDocument, deleteDocument, getDocuments, getCompaniesDetail } from '../../api/services';

class Document extends React.Component {
    title = "Document";

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        openAddDialogBox: false,
        openEditDialogBox: false,
        showDeleteConfirmationModal: false,
        selectedRow: null,
        defaultSelectedTabId: null,
        tableRows: [],
        tableColumns: [
            { key: 'documentName', name: 'File Name', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'companyName', name: 'Company', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'documentType', name: 'Document Type', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
        ],
        companyNames: [],
        files: []
    }

    componentDidMount() {
        this.getCompaniesDetail();
        this.onShowButtonClick({});
    }

    onAddButtonClick() {
        this.setState({ openAddDialogBox: true })
    }

    onDeleteRowClick = (row) => {
        this.setState({ showDeleteConfirmationModal: true, selectedRow: row });
    }

    onEditRowClick = (row) => {
        this.setState({selectedRow: row})
        this.setState({ openEditDialogBox: true})
    }

    onCancelButtonClick(document) {
        this.closeAddDialogBox()
        this.closeEditDialogBox()
    }

    closeAddDialogBox() {
        this.setState({ openAddDialogBox: false })
    }

    closeEditDialogBox() {
        this.setState({ openEditDialogBox: false})
    }

    hideDeleteConfirmationModal = () => {
        this.setState({ showDeleteConfirmationModal: false });
    }

    async getCompaniesDetail() {
        this.spinner.showSpinner('getCompanyNames');
        getCompaniesDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        companyNames: successData.company_list.map(x => ({ id: x.id, name: x.name, label: x.name, type: x.type })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getCompanyNames');
            });
    }

    onShowButtonClick = async (filter) => {
        this.spinner.showSpinner('getDocuments');
        getDocuments(filter)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        tableRows: this.getTableRows(response.data.success.document_result)
                    });
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getDocuments');
            });
    }

    getTableRows(data) {
        let rowList = []
        for (let i = 0; i < data.length; i++) {
            const currentRow = data[i]

            rowList.push({
                id: currentRow.document_id,
                documentName: currentRow.document_name,
                companyId: currentRow.company_id,
                companyName: currentRow.company_name,
                documentType: currentRow.document_type,
                
            })
        }

        return rowList;
    }

    saveDocumentButtonClick(requestBody) {
        this.closeAddDialogBox()
        this.closeEditDialogBox()
        this.spinner.showSpinner('saveDocument');

        saveDocument(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.DOCUMENT.SUCCESSFULL_DOCUMENT_SAVE);
                    this.onShowButtonClick({});
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('saveDocument');
            });
    }

    deleteDocument = () => {
        this.spinner.showSpinner('deleteDocument');
        let requestBody = {
            document_id: this.state.selectedRow.id
        }
        this.hideDeleteConfirmationModal();
        deleteDocument(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.DOCUMENT.SUCCESSFULL_DOCUMENT_DELETE)
                    this.onShowButtonClick({});
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('deleteDocument');
            });
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onAddButtonClick()}
                            >
                                Add
                            </button>
                        </AccordionActions>
                        <TBaseModal
                            dialogClassName="t-modal-50w"
                            show={this.state.openAddDialogBox}
                            onHide={() => this.closeAddDialogBox()}
                            closeButton={true}
                            title={"Add Document"}
                            body={
                                <TabPanel>
                                    <DocumentDetail
                                        companyNames={this.state.companyNames}
                                        item={null}
                                        onSaveButtonClick={(body) => this.saveDocumentButtonClick(body)}
                                        onCancelButtonClick={(market) => this.onCancelButtonClick(market)}
                                        readOnly={false}
                                    >
                                    </DocumentDetail>
                                </TabPanel>
                            }
                        >

                        </TBaseModal>
                        <TBaseModal
                            dialogClassName="t-modal-50w"
                            show={this.state.openEditDialogBox}
                            onHide={() => this.closeEditDialogBox()}
                            closeButton={true}
                            title={"Edit Document"}
                            body={
                                <TabPanel>
                                    <DocumentDetail
                                        companyNames={this.state.companyNames}
                                        item={this.state.selectedRow}
                                        onSaveButtonClick={(body) => this.saveDocumentButtonClick(body)}
                                        onCancelButtonClick={(document) => this.onCancelButtonClick(document)}
                                        readOnly={false}
                                    >
                                    </DocumentDetail>
                                </TabPanel>
                            }
                        >

                        </TBaseModal>
                        <Divider />
                        <TTable
                            showFilterButtons

                            showExportExcelButton
                            exportExcelFileName={this.title}

                            rows={this.state.tableRows}
                            columns={this.state.tableColumns}

                            showDeleteRow
                            showEditRow

                            onDeleteRowClick={this.onDeleteRowClick}
                            onEditRowClick={this.onEditRowClick}
                        >
                        </TTable>
                    </div>
                }
            >
            </LeftSideContainer>
        )
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                >
                </TContentDetailContainer>
                {
                    this.state.showDeleteConfirmationModal &&
                    <TConfirmationModal
                        show={this.state.showDeleteConfirmationModal}
                        message={messages.DOCUMENT.CONFIRM_DELETE.replace("[DOCUMENT]", this.state.selectedRow.name)}
                        cancelText='Cancel'
                        onHide={this.hideDeleteConfirmationModal}
                        onCancel={this.hideDeleteConfirmationModal}
                        confirmText='Delete'
                        onConfirm={this.deleteDocument}
                    />
                }
            </TContentContainer>
        );
    }
}

export default Document;