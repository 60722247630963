import './Main.css';
import '../../components/VContentContainer/VContentContainer.css';

import React from 'react';

import '../../components/VContentContainer/VContentContainer';
import VCategoryItem from '../../components/VCategoryItem/VCategoryItem';
import VCategory from '../../components/VCategory/VCategory';
import { connect } from 'react-redux';
import { prepareUserPages } from '../../helpers/pageHelper';


class Main extends React.Component {
    renderCategoryItems(category) {
        return category.pages.map(item => {
            return (
                <VCategoryItem
                    key={item.id}
                    title={item.title}
                    icon={item.icon}
                    route={item.route}
                />
            );
        });
    }

    renderCategories() {
        let renderedArr = [];

        const categories = prepareUserPages(this.props.authPages);

        let sortedCategoryKeys = Object.keys(categories).sort((a, b) => categories[a].order - categories[b].order);

        let category;

        for (let i = 0; i < sortedCategoryKeys.length; i++) {
            category = categories[sortedCategoryKeys[i]];
            renderedArr.push(
                <div className="container" key={category.order}>
                    <div className="v-main-page-content">
                        <div className="v-content-container">
                            <div className="card">
                                <div className="card-header">
                                    {category.title}
                                </div>
                                <div className="card-body">
                                    <VCategory key={category.order} title={category.title}>
                                        {this.renderCategoryItems(category)}
                                    </VCategory>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return renderedArr;
    }

    render() {
        if (!this.props.authPages)
            return null;

        return (
            <React.Fragment>
                <div className="v-main-page-border">
                    {this.renderCategories()}
                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authPages: state.auth.pages
    }
}

export default connect(mapStateToProps)(Main);