import React from 'react';
import './VMainContainer.css';


const VMainContainer = props => {

    const finalClassName = `v-main-container${!props.flex ? '' : '-flex'}`

    return (
        <div className={finalClassName}>
            {props.children}
        </div>
    );
};

export default VMainContainer;