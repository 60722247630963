import './VNavbar.css';

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import VUserInfo from '../VUserInfo/VUserInfo';
import { prepareUserPages } from '../../helpers/pageHelper';
import { endpointNames } from '../../apis/vitusApi';

class VNavbar extends React.Component {

    state = { expanded: false };

    renderCategoryItems(category) {
        return category.pages.map(item => {
            return (
                <Link
                    key={item.id}
                    onClick={() => this.setState({ expanded: false })}
                    to={item.route}
                >
                    <NavDropdown.Item
                        as="button"
                    >
                        {item.title}
                    </NavDropdown.Item>
                </Link>
            );
        });
    }

    renderMenu() {
        let renderedArr = []

        const categories = prepareUserPages(this.props.auth.pages);

        let sortedCategoryKeys = Object.keys(categories).sort((a, b) => categories[a].order - categories[b].order);

        let category;

        for (let i = 0; i < sortedCategoryKeys.length; i++) {
            category = categories[sortedCategoryKeys[i]];
            renderedArr.push(
                <NavDropdown className="v-navdropdown" key={category.order} title={category.title} as={ButtonGroup} id="basic-nav-dropdown">
                    {this.renderCategoryItems(category)}
                </NavDropdown>
            );
        }

        return renderedArr;
    }

    render() {
        return (
            <div className="v-navbar">
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top" expanded={this.state.expanded}>
                    {this.props.auth.isLoggedIn &&
                        <React.Fragment>
                            <Link to={endpointNames.main}>
                                <img src="./logo2.png" width="30" height="30" alt="" />
                            </Link>
                            <Navbar.Toggle
                                aria-controls="responsive-navbar-nav"
                                onClick={() => this.setState({ expanded: this.state.expanded ? false : 'expanded' })} />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="mr-auto">
                                    <div className="v-navbar-home-link">
                                        <Link
                                            onClick={() => this.setState({ expanded: false })}
                                            to={endpointNames.main}
                                        >
                                            Home
                                        </Link>
                                    </div>
                                    <div className="v-navbar-dropdowns">
                                        <ButtonGroup>
                                            {this.renderMenu()}
                                        </ButtonGroup>
                                    </div>
                                </Nav>
                                <Nav>
                                    <NavDropdown.Divider className="user-info-divider" />
                                    <VUserInfo />
                                </Nav>
                            </Navbar.Collapse>
                        </React.Fragment>
                    }
                    {!this.props.auth.isLoggedIn &&
                        <img src="./logo2.png" width="30" height="30" alt="" />
                    }
                </Navbar>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};


export default connect(mapStateToProps)(VNavbar);