import { logout as vitusApiLogout } from '../apis/vitusApi';
import { handleApiError } from '../helpers/errorHelper';
import { redirectToLogin } from '../helpers/routeHelper';
import {
    LOG_OUT,
    SET_USER,
    CREATE_ALERT,
    REMOVE_ALERT,
    SHOW_SPINNER,
    HIDE_SPINNER,
    CLEAR_SPINNERS
} from './types';

export const createAlert = (type, message) => {
    return {
        type: CREATE_ALERT,
        payload: { type, message }
    };
};

export const removeAlert = (alert) => {
    return {
        type: REMOVE_ALERT,
        payload: { alert }
    };
};

export const logout = () => {
    return async (dispatch) => {
        try {
            await vitusApiLogout();

            dispatch({ type: LOG_OUT });

            redirectToLogin();

        } catch (error) {
            handleApiError(error);
        }
    };
};

export const setUser = (user) => {
    return {
        type: SET_USER,
        payload: user
    };
};

export const showSpinner = (path, key) => {
    return {
        type: SHOW_SPINNER,
        payload:  { "path": path, "id": path + key }
    };
};

export const hideSpinner = (path, key) => {
    return {
        type: HIDE_SPINNER,
        payload: { "path": path, "id": path + key }
    };
};

export const clearSpinners = () => {
    return {
        type: CLEAR_SPINNERS
    };
};
