import React from 'react';
import VBaseModal from '../VBaseModal/VBaseModal';

const NeedRefreshModal = ({ show, message }) => {
    return (
        <VBaseModal
            dialogClassName="v-modal-30w"
            show={show}
            onHide={() => { return; }}
            title='Error'
            closeButton={false}
            body={
                <div className='row'>
                    <div className='col v-modal-error-icon-col'>
                        <div className='v-modal-error-icon'>
                            <i className="fa fa-exclamation fa-3x" />
                        </div>
                    </div>
                    <div className='col-10'>
                        {message}
                    </div>
                </div>
            }
            actions={
                <React.Fragment>
                    <button className="btn v-button"
                        onClick={() => {
                            window.location.reload();
                        }}>
                        Refresh
                    </button>
                </React.Fragment >
            }
        />
    );
};

export default NeedRefreshModal;
