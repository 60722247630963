import React from 'react';

import TBaseModal from '../TBaseModal/TBaseModal';

const TConfirmationModal = ({
    show,
    message,
    onHide,
    onCancel,
    cancelText = 'Cancel',
    showCancelButton = true,
    onConfirm,
    confirmText = 'Confirm',
    showConfirmButton = true,
    closeButton = false,
    dialogClassName = "t-modal-30w" }) => {

    return (
        <TBaseModal
            dialogClassName={dialogClassName}
            show={show}
            onHide={onHide}
            title='Confirm'
            closeButton={closeButton}
            body={
                <div className='row'>
                    <div className='col-10'>
                        {message}
                    </div>
                </div>
            }
            actions={
                <React.Fragment>
                    {
                        showCancelButton &&
                        <button
                            type="button"
                            className="btn t-gray-button"
                            onClick={onCancel}>
                            {cancelText}
                        </button>
                    }
                    {
                        showConfirmButton &&
                        <button
                            type="button"
                            className="btn t-orange-button"
                            onClick={onConfirm}>
                            {confirmText}
                        </button>
                    }
                </React.Fragment >
            }
        />
    );
};

TConfirmationModal.defaultProps = {
    onHide: () => { return; }
};

export default TConfirmationModal