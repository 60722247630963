import React from 'react';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer'
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import RightSideContainer from '../../components/TTPageContainers/RightSideContainer';
import TConfirmationModal from '../../components/TConfirmationModal/TConfirmationModal';
import { TfilterTypes } from '../../components/TTable/TTable';
import TTable from '../../components/TTable/TTable';
import TTabs from '../../components/TTabs/TTabs';
import TabPanel from '../../components/TTabs/TabPanel';
import TDialogBox from '../../components/TDialogBox'
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import AccordionActions from '@material-ui/core/AccordionActions'
import Divider from '@material-ui/core/Divider';
import { getPeriods, savePeriod, deletePeriod } from '../../api/services';
import { handleApiError, alertError, alertSuccess } from '../../utils/errorHandler';
import { messages } from '../../utils/messages';
import history from '../../../history';
import PeriodDetail from './PeriodDetail';
import { dayNames } from '../../utils/common'


import './Period.css';

const DayFormatter = ({ row }) => {
    if (row) {
        return <div style={{ fontStyle: "italic", fontSize: "12px" }}>{row.weekdayList.map(item => dayNames[item]?.label).join(',')}</div>;
    }
    return null;

};

const HourFormatter = ({ row }) => {
    if (row) {
        return <div style={{ fontStyle: "italic", fontSize: "12px" }}>{row.hourList.map(item => `H${item}`).join('/')}</div>;
    }
    return null;

};

class Period extends React.Component {
    title = "Period"

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        openAddDialogBox: false,
        showDeleteConfirmationModal: false,
        selectedRow: null,
        defaultSelectedTabId: null,
        tabInfo: [],
        tableRows: [],
        tableColumns: [
            { key: 'name', name: 'Name', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'weekdayList', name: 'Days', resizable: true, formatter: DayFormatter },
            { key: 'hourList', name: 'Hours', resizable: true, formatter: HourFormatter },
        ]
    }

    componentDidMount() {
        this.onShowButtonClick({});
    }

    setTabs = (newTabs, newTabId) => {
        this.setState({ tabInfo: newTabs, defaultSelectedTabId: newTabId })
    }

    onAddButtonClick() {
        this.setState({ openAddDialogBox: true })
    }

    onDeleteRowClick = (row) => {
        this.setState({ showDeleteConfirmationModal: true, selectedRow: row });
    }

    onEditRowClick = (row) => {
        this.addNewTab({ ...row, readOnly: false })
    }

    onCancelButtonClick(period) {
        this.closeAddDialogBox()
        if (period) {
            this.addNewTab({ ...period, readOnly: true })
        }
    }

    closeAddDialogBox() {
        this.setState({ openAddDialogBox: false })
    }

    hideDeleteConfirmationModal = () => {
        this.setState({ showDeleteConfirmationModal: false });
    }


    addNewTab(row) {
        this.setState({ defaultSelectedTabId: row.id })

        let ids = this.state.tabInfo.map(t => t.id)

        row.key = `${row.id}_${row.readOnly}`

        if (!ids.includes(row.id)) {
            this.setState({ tabInfo: [...this.state.tabInfo, row] })
        } else {

            let newTabList = this.state.tabInfo.map(tab => {
                if (tab.id === row.id) {
                    return row
                } else {
                    return tab
                }
            })

            this.setState({ tabInfo: newTabList })
        }
    }

    onShowButtonClick = (filter) => {
        this.spinner.showSpinner('getPeriods');
        getPeriods(filter)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        tableRows: this.getTableRows(response.data.success.period_result)
                    });
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getPeriods');
            });
    }

    getTableRows(data) {
        let rowList = []
        for (let i = 0; i < data.length; i++) {
            const currentRow = data[i]

            rowList.push({
                id: currentRow.period_id,
                name: currentRow.period_name,
                weekdayList: currentRow.weekdays || [],
                hourList: currentRow.hours || []
            })
        }

        return rowList;
    }

    savePeriodButtonClick(requestBody) {
        this.closeAddDialogBox()
        this.spinner.showSpinner('savePeriod');

        savePeriod(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.PERIOD.SUCCESSFULL_PERIOD_SAVE);
                    this.onShowButtonClick({});

                    let row = {
                        id: response.data.success.period_id,
                        name: requestBody.period_name,
                        weekdayList: requestBody.weekday_list,
                        hourList: requestBody.hour_list
                    }

                    this.addNewTab({ ...row, readOnly: true })
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('savePeriod');
            });
    }

    deletePeriod = () => {
        this.spinner.showSpinner('deletePeriod');
        let requestBody = {
            period_id: this.state.selectedRow.id
        }
        this.hideDeleteConfirmationModal();
        deletePeriod(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.PERIOD.SUCCESSFULL_PERIOD_DELETE)
                    this.onShowButtonClick({});
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('deletePeriod');
            });
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onAddButtonClick()}
                            >
                                Add
                            </button>
                        </AccordionActions>
                        <TDialogBox
                            openAddDialogBox={this.state.openAddDialogBox}
                            handleClose={() => this.closeAddDialogBox()}
                            title={"Add Period"}
                        >
                            <TabPanel >
                                <PeriodDetail
                                    item={null}
                                    onSaveButtonClick={(body) => this.savePeriodButtonClick(body)}
                                    onCancelButtonClick={(period) => this.onCancelButtonClick(period)}
                                    readOnly={false}
                                >
                                </PeriodDetail>
                            </TabPanel>
                        </TDialogBox>
                        <Divider />
                        <TTable
                            showFilterButtons

                            showExportExcelButton
                            exportExcelFileName={this.title}

                            rows={this.state.tableRows}
                            columns={this.state.tableColumns}

                            showDeleteRow
                            showEditRow

                            onDeleteRowClick={this.onDeleteRowClick}
                            onEditRowClick={this.onEditRowClick}
                        ></TTable>
                    </div>

                }
            >
            </LeftSideContainer>
        )
    }

    getTableDetailComponent() {
        return (
            <RightSideContainer>
                <TTabs
                    key={this.state.defaultSelectedTabId}
                    defaultSelectedTabId={this.state.defaultSelectedTabId}
                    setTabs={this.setTabs}
                >
                    {
                        this.state.tabInfo.map((t) => {
                            return (
                                <TabPanel key={`${t.key}_tabpanel`} title={t.name} id={t.id}>
                                    <PeriodDetail
                                        key={`${t.key}_PeriodDetail`}
                                        item={t}
                                        onSaveButtonClick={(body) => this.savePeriodButtonClick(body)}
                                        onEditButtonClick={(row) => this.addNewTab({ ...row, readOnly: false })}
                                        onCancelButtonClick={(period) => this.onCancelButtonClick(period)}
                                        readOnly={t.readOnly}
                                    >
                                    </PeriodDetail>
                                </TabPanel>
                            )
                        })
                    }
                </TTabs>
            </RightSideContainer>
        )
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                    rightSideComponent={this.getTableDetailComponent()}
                >
                </TContentDetailContainer>
                {
                    this.state.showDeleteConfirmationModal &&
                    <TConfirmationModal
                        show={this.state.showDeleteConfirmationModal}
                        message={messages.PERIOD.CONFIRM_DELETE.replace("[PERIOD]", this.state.selectedRow.name)}
                        cancelText='Cancel'
                        onHide={this.hideDeleteConfirmationModal}
                        onCancel={this.hideDeleteConfirmationModal}
                        confirmText='Delete'
                        onConfirm={this.deletePeriod}
                    />
                }
            </TContentContainer>
        );
    }

}

export default Period;
