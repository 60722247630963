import React from 'react';
import Select from 'react-select';

import './TDropdown.css';

/*
    * 'width' prop options: small, medium, large and x-large
    * 'height' prop options: small, medium, large and x-large
    * This sizes are set for all portal by considering consistency. If you need to add a new one, please
    check out 'TDropdown.css' and append.

    * Some css classes in 'TDropdown.css' inspected by opening Chrome DevTools debug mode.

    react-select: https://react-select.com/home
*/

const TDropdown = ({ label, width, placeholder, options,
    defaultValue, onSelectedOptionChange, isSearchable, value, disabled, isClearable }) => {

    const finalDropdownWidthClass = `t-dropdown-width-${width}`

    const renderContent = () => {

        return (
            <Select
                className='v-select'
                classNamePrefix="v-select"
                placeholder={placeholder ? placeholder : ''}
                options={options}
                defaultValue={defaultValue}
                onChange={onSelectedOptionChange}
                isSearchable={isSearchable}
                maxMenuHeight={220}
                value={value}
                isDisabled={disabled}
                isClearable={isClearable}
            />
        );
    };

    return (
        <div className="t-dropdown-content">
            {
                label &&
                <div className="t-dropdown-label">
                    {label}
                </div>
            }
            <div className={finalDropdownWidthClass}>
                {renderContent()}
            </div>
        </div>
    );


};

export default TDropdown;