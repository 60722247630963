import './VHorizontalTable.css';
import React from 'react';

class VHorizontalTable extends React.Component {
    quantityPricePairHeader = 'Quantity - Price Pair No.';
    messageIdentifierHeader = 'Message Identifier';
    messageVersionHeader = 'Message Version';
    offerTypeHeader = 'Offer Type (buy/sell)';
    transactionZoneHeader = 'Transaction Zone';
    participantCodeHeader = 'Participant Code';
    quantityHeader = 'Quantity';
    priceHeader = 'Price';
    priceScale = 2;
    quantityScale = 1;

    render() {
        let maxOrder = 9;

        this.props.data.forEach(h => {
            h.offers.forEach(o => {
                if (o.order > maxOrder)
                    maxOrder = o.order;
            })
        });

        return (
            <div className='v-horizontal-table'>
                <table className="table table-borderless table-responsive">
                    <tbody>
                        <tr style={{ textAlign: 'center', top: '50%' }}>
                            <th className='v-filled-header v-filled-header-first'
                                rowSpan={7}
                                style={{ whiteSpace: 'normal' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyJontent: 'center',
                                        alignItems: 'center',
                                        height: '200px'
                                    }}>
                                    {this.quantityPricePairHeader}
                                </div>
                            </th>
                            {
                                this.props.data.map(d => {
                                    return (
                                        <th key={`TH_H_${d.hour}`}
                                            className='v-filled-header'
                                            colSpan={2}>
                                            {d.hour + 1}
                                        </th>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            {
                                this.props.data.map((d) => {
                                    return (
                                        <React.Fragment key={`TH_MI_${d.hour}`} >
                                            <th className='v-text'>
                                                {this.messageIdentifierHeader}
                                            </th>
                                            <th className='v-info-end v-number'>
                                                {this.props.messageIdentifier}
                                            </th>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            {
                                this.props.data.map((d) => {
                                    return (
                                        <React.Fragment key={`TH_MV_${d.hour}`} >
                                            <th className='v-text'>
                                                {this.messageVersionHeader}
                                            </th>
                                            <th className='v-info-end v-number'>
                                                {this.props.messageVersion}
                                            </th>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            {
                                this.props.data.map((d) => {
                                    return (
                                        <React.Fragment key={`TH_OT_${d.hour}`} >
                                            <th className='v-text'>
                                                {this.offerTypeHeader}
                                            </th>
                                            <th className={`v-info-end v-text ${this.props.offerType?.toLowerCase() === 'sell' ? 'v-offer-type-sell' : 'v-offer-type-buy'}`}>
                                                {this.props.offerType?.toUpperCase()}
                                            </th>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            {
                                this.props.data.map((d) => {
                                    return (
                                        <React.Fragment key={`TH_TZ_${d.hour}`} >
                                            <th className='v-text'>
                                                {this.transactionZoneHeader}
                                            </th>
                                            <th className='v-info-end v-text'>
                                                {this.props.transactionZone}
                                            </th>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            {
                                this.props.data.map((d) => {
                                    return (
                                        <React.Fragment key={`TH_TZ_${d.hour}`} >
                                            <th className='v-text'>
                                                {this.participantCodeHeader}
                                            </th>
                                            <th className='v-info-end v-text'>
                                                {this.props.participantCode}
                                            </th>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            {
                                this.props.data.map((d) => {
                                    return (
                                        <React.Fragment key={`TH_QP_${d.hour}`} >
                                            <th className='v-filled-header v-sub-filled-header'>
                                                {this.quantityHeader}
                                            </th>
                                            <th className='v-filled-header v-sub-filled-header'>
                                                {this.priceHeader}
                                            </th>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tr>
                        {
                            [...Array(maxOrder + 1).keys()].map((o) => {
                                return (
                                    <tr key={`TR_OFFERS_${o}`}>
                                        <td className='v-info-order'>{o + 1}</td>
                                        {
                                            this.props.data.map((d) => {
                                                if (d.offers.length <= o)
                                                    return (
                                                        <React.Fragment key={`TD_OFFERS_${o}_${d.hour}`}>
                                                            <td className={`v-number ${o === maxOrder ? 'v-last-row' : ''}`}></td>
                                                            <td className={`v-info-end v-number ${o === maxOrder ? 'v-last-row' : ''}`}></td>
                                                        </React.Fragment>
                                                    );
                                                return (
                                                    <React.Fragment key={`TD_OFFERS_${o}_${d.hour}`}>
                                                        <td className={`v-number ${o === maxOrder && o === d.offers.length - 1 ? 'v-last-row' : ''}`}>
                                                            {Number(d.offers[o].quantity).toFixed(this.quantityScale)}
                                                        </td>
                                                        <td className={`v-info-end v-number ${o === maxOrder && o === d.offers.length - 1 ? 'v-last-row' : ''}`}>
                                                            {Number(d.offers[o].price).toFixed(this.priceScale)}
                                                        </td>
                                                    </React.Fragment>
                                                );
                                            })

                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

VHorizontalTable.defaultProps = {
    messageIdentifier: '',
    messageVersion: '',
    offerType: '',
    transactionZone: '',
    participantCode: '',
    data: []
}

export default VHorizontalTable;