import React from 'react';
import VBaseModal from '../VBaseModal/VBaseModal';

const ExportFileModal = ({ show, onHide, onExportEdited, onExportOriginal }) => {
    return (
        <VBaseModal
            dialogClassName="v-modal-60w"
            show={show}
            onHide={onHide}
            title='Export Offers'
            body={
                <div className='row'>
                    <div className='col v-modal-error-icon-col'>
                        <div className='v-modal-error-icon'>
                            <i className='fa fa-question fa-3x' />
                        </div>
                    </div>
                    <div className='col-10'>
                        Which values would you like to export?
                            </div>
                </div>
            }
            actions={
                <React.Fragment>
                    <button
                        className="btn v-cancel-button"
                        onClick={onHide}>
                        Cancel
                            </button>
                    <button
                        className="btn v-button"
                        onClick={onExportEdited}>
                        Export edited values
                            </button>
                    <button
                        className="btn v-button"
                        onClick={onExportOriginal}>
                        Export original values
                            </button>
                </React.Fragment >
            }
        />
    );
}

export default ExportFileModal;