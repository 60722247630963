import './ServiceCosts.css';

import React from 'react';
import { orderBy, uniqBy, isEmpty } from 'lodash';

import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import { getBorders, getServiceCostTypes, getSelectedServiceCosts, saveSelectedServiceCosts } from '../../apis/vitusApi';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VDropdown from '../../components/VDropdown/VDropdown';
import VTable from '../../components/VTable/VTable';
import { portalMessages } from '../../helpers/portalMessages';
import { handleApiError, alertError, alertSuccess, alertWarning, specifyErrorMessage } from '../../helpers/errorHelper';
import { extractDate, getDayAheadForGivenDate } from '../../helpers/generalHelper';
import AddServiceCostModal from '../../modals/AddServiceCostModal/AddServiceCostModal';
import ConfirmationModal from '../../modals/ConfirmationModal/ConfirmationModal';

class ServiceCosts extends React.Component {

    spinner = new SpinnerManager(history.location.pathname);
    maxDate = getDayAheadForGivenDate(new Date());
    addServiceCostModalRef = React.createRef();

    periodTypeOptions = [
        { value: 'ST', label: 'ST (Short Term)' },
        { value: 'LT', label: 'LT (Long Term)' }
    ];

    tableColumns = [
        { label: 'Cost Type', index: 0, id: 'costType' },
        { label: 'Cost Type Description', index: 1, id: 'costTypeDescription' },
        { label: 'Unit', index: 2, id: 'unit' },
        { label: 'Value', index: 3, id: 'value' }
    ];

    tableIndexes = {
        "costType": this.tableColumns.find(i => i.id === 'costType').index,
        "costTypeDescription": this.tableColumns.find(i => i.id === 'costTypeDescription').index,
        "unit": this.tableColumns.find(i => i.id === 'unit').index,
        "value": this.tableColumns.find(i => i.id === 'value').index
    };

    tableReadOnlyColumns = this.tableColumns
        .filter(i => i.id === 'costType' || i.id === 'costTypeDescription' || i.id === 'unit').map(i => i.label);

    state = {
        selectedDate: getDayAheadForGivenDate(new Date()),
        showNeedRefreshModal: false,
        showAddServiceCostModal: false,
        showAddCostButton: false,
        showRowDeleteModal: false,
        rowIndexToDelete: null,
        activeFilter: [],
        latestRequestedFilter: {},
        disableFilter: false,
        table: {},
        startDate: null,
        endDate: null,
        startDateBeforeTableEditMode: null,
        endDateBeforeTableEditMode: null,
        directionsOfCounterParties: [],
        counterParties: [],
        selectedCounterParty: '',
        selectedDirection: '',
        selectedPeriod: '',
        serviceCostTypes: [],
        unSelectedServiceCostTypes: [],
        serviceCostTypeDescriptions: {}
    }

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED,
        MissingParameter: {
            "requested_date": portalMessages.DATE_SELECTION
        }
    };

    showErrorMessage(error) {
        const { message } = specifyErrorMessage(error, this.errorMessages);

        if (message)
            alertError(message);
    }

    componentDidMount() {
        this.spinner.showSpinner('getBordersServiceCosts');

        getBorders()
            .then(response => {
                if (response.data.success) {
                    this.callGetServiceCostTypes();
                    this.setCounterPartiesAndDirections(response.data.success.borders);
                } else {
                    this.setState({ showNeedsRefresModal: true });
                }

            }, error => {
                handleApiError(error);
                this.setState({ showNeedsRefresModal: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('getBordersServiceCosts');
            });
    }

    callGetServiceCostTypes = () => {

        this.spinner.hideSpinner('callGetServiceCostTypes');

        getServiceCostTypes()
            .then(response => {
                if (response.data.success)
                    this.setServiceCostTypes(response.data.success);
                else
                    this.setState({ showNeedsRefresModal: true });

            }, error => {
                handleApiError(error);
                this.setState({ showNeedsRefresModal: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('callGetServiceCostTypes');
            });
    }

    setServiceCostTypes(fetchedTypes) {
        let serviceCostTypes = [];
        let serviceCostTypeDescriptions = {};

        fetchedTypes.forEach(eachType => {
            serviceCostTypes.push({ value: eachType.type, label: eachType.type });
            serviceCostTypeDescriptions[eachType.type] = eachType.descriptions.map(d => { return { value: d, label: d } });
        });

        this.setState({ serviceCostTypes, serviceCostTypeDescriptions });
    }

    setCounterPartiesAndDirections(borders) {

        let directionsOfCounterParties = {};
        let counterParties = [];

        borders.forEach(border => {
            border.directions.forEach(direction => {
                direction.counterParties.forEach(cParty => {

                    if (cParty in directionsOfCounterParties) {
                        directionsOfCounterParties[cParty].push({ value: direction.direction, label: direction.direction });
                    } else {
                        directionsOfCounterParties[cParty] = [];
                        directionsOfCounterParties[cParty].push({ value: direction.direction, label: direction.direction });
                    }

                    counterParties.push({ value: cParty, label: cParty });
                });
            });
        });

        Object.keys(directionsOfCounterParties).forEach(cParty => {
            directionsOfCounterParties[cParty] = orderBy(uniqBy(directionsOfCounterParties[cParty], 'value'), ['label']);
        });

        counterParties = orderBy(uniqBy(counterParties, 'value'), ['label']);

        this.setState({ directionsOfCounterParties, counterParties });
    }

    callGetSelectedServiceCostsAsync = async (requestBody) => {

        this.spinner.showSpinner('callGetSelectedServiceCostsAsync');

        try {
            let response;

            try {
                response = await getSelectedServiceCosts(requestBody);
            } catch (error) {
                handleApiError(error);
            }

            if (response.data.success) {

                this.setState({
                    latestRequestedFilter: { ...requestBody },
                    activeFilter: [
                        { label: "Date", value: requestBody.filter.requested_date },
                        { label: "Period", value: requestBody.filter.period },
                        { label: "Counter Party", value: requestBody.filter.counter_party },
                        { label: "Direction", value: requestBody.filter.direction }
                    ]
                });

                this.setTable(response.data.success);

            } else if (response.data.error.default.NotFound) {

                alertWarning(portalMessages.SERVICE_COSTS.NO_RESULT_FOUND);

                this.setState({
                    showAddCostButton: true,
                    startDate: null,
                    endDate: null,
                    rowIndexToDelete: null,
                    table: {},
                    unSelectedServiceCostTypes: [],
                    latestRequestedFilter: { ...requestBody },
                    activeFilter: [
                        { label: "Date", value: requestBody.filter.requested_date },
                        { label: "Period", value: requestBody.filter.period },
                        { label: "Counter Party", value: requestBody.filter.counter_party },
                        { label: "Direction", value: requestBody.filter.direction }
                    ]
                });

            } else if (response.data.error) {
                this.showErrorMessage(response.data.error);
                this.setState({ showAddCostButton: false, startDate: null, endDate: null });

            } else {
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
                this.setState({ showNeedRefreshModal: true, showAddCostButton: true, startDate: null, endDate: null });
            }

        } finally {
            this.spinner.hideSpinner('callGetSelectedServiceCostsAsync');
        }

    }

    createGetSelectedServiceCostsRequestBody(requestedDate = null, cParty = null, direction = null, period = null) {
        return {
            filter: {
                requested_date: requestedDate ? requestedDate : extractDate(this.state.selectedDate),
                counter_party: cParty ? cParty : this.state.selectedCounterParty.value,
                direction: direction ? direction : this.state.selectedDirection.value,
                period: period ? period : this.state.selectedPeriod.value
            }
        };
    }

    setTable(result) {
        let table = this.convertJsonTable(result['service_costs']);
        let startDate = result['start_date'] ? new Date(result['start_date']) : null;
        let endDate = result['end_date'] ? new Date(result['end_date']) : null;

        let unSelectedServiceCostTypes = [];

        this.state.serviceCostTypes.forEach(cost_type => {
            let found = result['service_costs'].find(i => i.type_name === cost_type.value);

            if (!found)
                unSelectedServiceCostTypes.push(cost_type);

        });

        this.setState({
            table,
            startDate,
            endDate,
            startDateBeforeTableEditMode: null,
            endDateBeforeTableEditMode: null,
            rowIndexToDelete: null,
            showAddCostButton: true,
            unSelectedServiceCostTypes: unSelectedServiceCostTypes
        });
    }

    convertJsonTable(result) {
        let headers = this.tableColumns.map(i => i.label);
        let values = [];

        result.forEach(cost => {
            values.push([cost.type_name, cost.description, cost.unit, cost.value]);
        });


        return { headers, values };
    }

    createCostObjToSave(rowValues = null, costType = null, costTypeDescription = null, unit = null, value = null) {

        if (rowValues === null) {
            return {
                cost_type: costType,
                cost_type_description: costTypeDescription,
                unit: unit,
                value: value
            };

        } else {

            return {
                cost_type: rowValues[this.tableIndexes.costType],
                cost_type_description: rowValues[this.tableIndexes.costTypeDescription],
                unit: rowValues[this.tableIndexes.unit],
                value: rowValues[this.tableIndexes.value]
            };
        }
    }

    createSaveServiceCostsRequestBody(tableData, modalInputs = null) {

        let body = {
            counter_party: this.state.latestRequestedFilter.filter.counter_party,
            direction: this.state.latestRequestedFilter.filter.direction,
            period: this.state.latestRequestedFilter.filter.period,
            start_date: modalInputs ? extractDate(modalInputs.startDate) : extractDate(this.state.startDate),
            end_date: modalInputs ? (modalInputs.endDate ? extractDate(modalInputs.endDate) : null) :
                (this.state.endDate ? extractDate(this.state.endDate) : null),

            costs: []
        };

        if (!isEmpty(tableData) && this.state.rowIndexToDelete === null)
            tableData.values.forEach(rowValues => body.costs.push(this.createCostObjToSave(rowValues)));


        if (!isEmpty(tableData) && this.state.rowIndexToDelete !== null)
            tableData.values.forEach((rowValues, idx) => {
                if (this.state.rowIndexToDelete !== idx)
                    body.costs.push(this.createCostObjToSave(rowValues));
            });

        if (modalInputs)
            body.costs.push(this.createCostObjToSave(null, modalInputs.costType, modalInputs.costTypeDescription, modalInputs.unit, modalInputs.value));

        return { filter: body };
    }

    callSaveSelectedServiceCostsAsync = async (requestBody) => {

        this.spinner.showSpinner('callSaveSelectedServiceCostsAsync');

        let result = false;

        try {
            let response;

            try {
                response = await saveSelectedServiceCosts(requestBody);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success)
                result = true;
            else if (response.data.error)
                this.showErrorMessage(response.data.error);
            else
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);

            return result;

        } finally {
            this.spinner.hideSpinner('callSaveSelectedServiceCostsAsync');
        }
    }

    validateTableInputs(tableToSave) {

        if (!this.state.startDate) {
            this.showErrorMessage(portalMessages.SERVICE_COSTS.EMPTY_START_DATE);
            return false;
        }

        for (let rowValues of tableToSave.values) {

            let value = rowValues[this.tableIndexes.value];

            if (isNaN(value)) {
                this.showErrorMessage(portalMessages.SERVICE_COSTS.INVALID_COST_VALUE);
                return false;
            }

            let v = parseFloat(value);

            if (v < 0) {
                this.showErrorMessage(portalMessages.SERVICE_COSTS.INVALID_COST_VALUE);
                return false;
            }
        }

        return true;
    }

    saveTableChangesAsync = async (tableToSave) => {

        this.spinner.showSpinner('saveTableChangesAsync');

        try {

            if (this.validateTableInputs(tableToSave)) {

                let result = await this.callSaveSelectedServiceCostsAsync(this.createSaveServiceCostsRequestBody(tableToSave));

                if (result) {

                    alertSuccess(portalMessages.SERVICE_COSTS.SUCCESSFULL_SAVE);

                    await this.callGetSelectedServiceCostsAsync(this.state.latestRequestedFilter)

                    return true;

                } else {
                    return false;
                }

            } else {
                return false;
            }

        } catch (error) {
            alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
            return false;

        } finally {
            this.spinner.hideSpinner('saveTableChangesAsync');
        }
    }

    onShowButtonClick = () => {
        if (!this.state.selectedDate)
            alertError(portalMessages.DATE_SELECTION);

        else if (!this.state.selectedCounterParty)
            alertError(portalMessages.SELECT_COUNTER_PARTY);


        else if (!this.state.selectedDirection)
            alertError(portalMessages.SELECT_DIRECTION);


        else if (!this.state.selectedCounterParty)
            alertError(portalMessages.SELECT_COUNTER_PARTY);

        else if (!this.state.selectedPeriod)
            alertError(portalMessages.LT_AUCTION_ALLOCATIONS.SELECT_PERIOD_TYPE);

        else
            this.callGetSelectedServiceCostsAsync(this.createGetSelectedServiceCostsRequestBody());
    }

    validateModalInputs(modalInputs) {
        if (!this.state.startDate && !modalInputs.startDate) {
            this.showErrorMessage(portalMessages.SERVICE_COSTS.EMPTY_START_DATE);
            return false;
        }

        if (!modalInputs.costType) {
            this.showErrorMessage(portalMessages.SERVICE_COSTS.EMPTY_COST_TYPE);
            return false;
        }

        if (!modalInputs.costTypeDescription) {
            this.showErrorMessage(portalMessages.SERVICE_COSTS.EMPTY_COST_TYPE_DESCRIPTION);
            return false;
        }

        if (!modalInputs.value) {
            this.showErrorMessage(portalMessages.SERVICE_COSTS.EMPTY_VALUE);
            return false;
        }

        return true;
    }

    onAddServiceCost = async (modalInputs) => {
        if (this.validateModalInputs(modalInputs)) {
            let result = await this.callSaveSelectedServiceCostsAsync(this.createSaveServiceCostsRequestBody(this.state.table, modalInputs));

            if (result) {
                alertSuccess(portalMessages.SERVICE_COSTS.SUCCESSFULL_SAVE);

                this.callGetSelectedServiceCostsAsync(this.state.latestRequestedFilter);

                this.setState({ showAddServiceCostModal: false });

                if (this.addServiceCostModalRef.current)
                    this.addServiceCostModalRef.current.clearStates();
            }
        }
    }

    confirmRowDelete = async () => {

        let result = await this.callSaveSelectedServiceCostsAsync(this.createSaveServiceCostsRequestBody(this.state.table));

        if (result) {
            alertSuccess(portalMessages.SERVICE_COSTS.SUCCESSFUL_DELETE);
            this.callGetSelectedServiceCostsAsync(this.state.latestRequestedFilter);
        }

        this.setState({ showRowDeleteModal: false });
    }

    setSelectedCounterParty = selectedCounterParty => {
        if (this.state.selectedDirection)
            this.setState({ selectedDirection: '', selectedCounterParty });
        else
            this.setState({ selectedCounterParty });
    }

    getFileExportName() {

        let name = 'ServiceCosts'
            .concat(`_${this.state.latestRequestedFilter.filter.counter_party}`)
            .concat(`_${this.state.latestRequestedFilter.filter.direction}`)
            .concat(`_${this.state.latestRequestedFilter.filter.period}`)
            .concat(`_${extractDate(this.state.startDate)}`)
            .concat(`_${this.state.endDate ? extractDate(this.state.endDate) : '*'}`)

        return name;
    }

    onOpenEditMode = () => {
        this.setState({
            disableFilter: true,
            startDateBeforeTableEditMode: this.state.startDate,
            endDateBeforeTableEditMode: this.state.endDate
        });
    };

    onCloseEditMode = () => {

        if (this.state.startDateBeforeTableEditMode && this.state.startDateBeforeTableEditMode) {
            this.setState({
                disableFilter: false,
                startDate: this.state.startDateBeforeTableEditMode,
                endDate: this.state.endDateBeforeTableEditMode
            });

        } else if (this.state.startDateBeforeTableEditMode) {
            this.setState({
                disableFilter: false,
                startDate: this.state.startDateBeforeTableEditMode,
            });

        } else {
            this.setState({ disableFilter: false });
        }

    }

    onClearButtonClick = () => this.setState({ selectedDate: null, selectedCounterParty: null, selectedPeriod: null, selectedDirection: null });

    setSelectedDate = selectedDate => this.setState({ selectedDate });

    setSelectedDirection = selectedDirection => this.setState({ selectedDirection });

    onAddCostButtonClick = () => this.setState({ showAddServiceCostModal: true });

    hideAddServiceCostModal = () => this.setState({ showAddServiceCostModal: false });

    setSelectedPeriod = selectedPeriod => this.setState({ selectedPeriod });

    setStartDate = startDate => this.setState({ startDate });

    setEndDate = endDate => this.setState({ endDate });

    hideRowDeleteModal = () => this.setState({ showRowDeleteModal: false });

    showRowDeleteModal = rowIndexToDelete => this.setState({ showRowDeleteModal: true, rowIndexToDelete });

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Service Costs">
                    <VFilterContainer showActiveFilter activeFilter={this.state.activeFilter}>
                        <div className='container'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col'>
                                        <div className="v-filter-group">
                                            <div className="v-filter-label v-label">
                                                Date
                                            </div>
                                            <div>
                                                <VDatePicker
                                                    disabled={this.state.disableFilter}
                                                    selectedDate={this.state.selectedDate}
                                                    onSelectedDateChange={this.setSelectedDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="v-filter-group">
                                            <div className="v-filter-label v-label">
                                                Counter Party
                                            </div>
                                            <div>
                                                <VDropdown
                                                    width="large"
                                                    disabled={this.state.disableFilter}
                                                    isSearchable={false}
                                                    options={this.state.counterParties}
                                                    value={this.state.selectedCounterParty}
                                                    defaultValue={this.state.selectedCounterParty}
                                                    onSelectedOptionChange={this.setSelectedCounterParty}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col'>
                                        <div className="v-filter-group">
                                            <div className="v-filter-label v-label">
                                                Period
                                            </div>
                                            <div>
                                                <VDropdown
                                                    width="large"
                                                    disabled={this.state.disableFilter}
                                                    isSearchable={false}
                                                    options={this.periodTypeOptions}
                                                    value={this.state.selectedPeriod}
                                                    defaultValue={this.state.selectedPeriod}
                                                    onSelectedOptionChange={this.setSelectedPeriod}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="v-filter-group">
                                            <div className="v-filter-label v-label">
                                                Direction
                                            </div>
                                            <div>
                                                <VDropdown
                                                    width="large"
                                                    disabled={this.state.disableFilter}
                                                    isSearchable={true}
                                                    options={this.state.selectedCounterParty ? this.state.directionsOfCounterParties[this.state.selectedCounterParty.value] : []}
                                                    value={this.state.selectedDirection}
                                                    defaultValue={this.state.selectedDirection}
                                                    onSelectedOptionChange={this.setSelectedDirection}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                disabled={this.state.disableFilter}
                                className="btn v-cancel-button v-filter-button"
                                onClick={this.onClearButtonClick}>
                                <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                            </button>
                            <button
                                disabled={this.state.disableFilter}
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={this.onShowButtonClick}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                            </button>
                        </div>
                    </VFilterContainer>
                    <VMainContainer flex>
                        <div className="container v-service-cost-container">
                            {
                                this.state.showAddCostButton &&
                                <div className="row">
                                    <div className="col-12 v-lt-allocation-add-button">
                                        <button className="btn v-button v-tab-button"
                                            disabled={this.state.disableFilter}
                                            onClick={this.onAddCostButtonClick}>
                                            <i aria-hidden="true" className="fa fa-plus fa-fw" />
                                                Add Cost
                                        </button>
                                    </div>
                                </div>
                            }
                            {
                                !isEmpty(this.state.table) &&
                                <React.Fragment>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Start Date
                                        </div>
                                        <div>
                                            <VDatePicker
                                                disabled={!this.state.disableFilter}
                                                selectedDate={this.state.startDate}
                                                onSelectedDateChange={this.setStartDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            End Date
                                         </div>
                                        <div>
                                            <VDatePicker
                                                disabled={!this.state.disableFilter}
                                                selectedDate={this.state.endDate}
                                                onSelectedDateChange={this.setEndDate}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <VTable
                                            title=""
                                            simpleNumbers
                                            inputType='number'
                                            items={this.state.table}
                                            onOpenEditMode={this.onOpenEditMode}
                                            onCloseEditMode={this.onCloseEditMode}
                                            onDeleteRow={this.showRowDeleteModal}
                                            exportFileName={this.getFileExportName()}
                                            readonlyColumns={this.tableReadOnlyColumns}
                                            onSaveChangesAsync={this.saveTableChangesAsync}
                                            headerButtons={{
                                                showExportExcelButton: true,
                                                showEditButton: true
                                            }}
                                            rowButtons={{
                                                showDeleteButton: this.state.table.values.length > 1
                                            }}
                                        />
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </VMainContainer>
                </VContentContainer>
                {
                    this.state.showAddServiceCostModal &&
                    <AddServiceCostModal
                        ref={this.addServiceCostModalRef}
                        show={this.state.showAddServiceCostModal}
                        onCancel={this.hideAddServiceCostModal}
                        onAdd={this.onAddServiceCost}
                        serviceCostTypes={isEmpty(this.state.unSelectedServiceCostTypes) ? this.state.serviceCostTypes : this.state.unSelectedServiceCostTypes}
                        serviceCostTypeDescriptions={this.state.serviceCostTypeDescriptions}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                    />
                }
                {
                    this.state.showNeedRefreshModal &&
                    <NeedRefreshModal
                        show={this.state.showNeedRefreshModal}
                        message={portalMessages.COULD_NOT_GET_C_PARTIES_AND_DIRECTIONS}

                    />
                }
                {
                    this.state.showRowDeleteModal &&
                    <ConfirmationModal
                        show={this.state.showRowDeleteModal}
                        message={portalMessages.SERVICE_COSTS.ASK_DELETE}
                        cancelText='Cancel'
                        onHide={this.hideRowDeleteModal}
                        onCancel={this.hideRowDeleteModal}
                        confirmText='Delete'
                        onConfirm={this.confirmRowDelete}
                    />
                }
            </React.Fragment>
        );
    }
}


export default ServiceCosts;