
import React from 'react';
import TChip from './TChip';

class TEmailChip extends React.Component {
    isValid = (email) => {
        let error = null;

        if (!this.isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        return error;
    }

    isEmail = (email) => {
        return /[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/.test(email);
    }

    render() {
        return (
            <TChip
                id={this.props.id}
                key={`${this.props.title}_email`}
                isValid={this.isValid}
                items={this.props.items}
                title={this.props.title}
                readOnly={this.props.readOnly}
                placeholder="Type or paste email addresses and press `Enter`..."
                isEmail={true}
                setList={this.props.setEmailList}
            >
            </TChip>
        )
    }
}
export default TEmailChip;