import React from 'react';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TNotification from '../../components/TNotification/TNotification';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import { getBackOfficeNotificatons } from '../../api/services';
import { handleApiError } from '../../utils/errorHandler';
import history from '../../../history';


class BackOffice extends React.Component {
    spinner = new SpinnerManager(history.location.pathname);

    state = {
        items: []
    }

    componentDidMount() {
        this.getBackOfficeNotificatons();
    }

    async getBackOfficeNotificatons() {
        this.spinner.showSpinner('getBackOfficeNotificatons');
        getBackOfficeNotificatons()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        items: successData.notification_list.map(item => ({
                            id: item.id,
                            operationType: item.operation_type,
                            dataType: item.data_type,
                            name: item.name,
                            userName: item.user_name,
                            userId: item.userId,
                            activityDate: item.activity_date
                        })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getBackOfficeNotificatons');
            });

    }

    render() {
        return (
            <TContentContainer>
                <div>
                    Latest Activities
                </div>
                <TNotification
                    items={this.state.items}
                ></TNotification>
            </TContentContainer>

        );
    }
}

export default BackOffice;