import { pages, categories as allCategories } from './constants';

export const prepareUserPages = authPages => {
    if (!authPages)
        return {};

    let categories = {};
    let pageDetails;

    for (let i = 0; i < authPages.length; i++) {
        pageDetails = pages[authPages[i]];

        if (!pageDetails || !pageDetails.route)
            continue;

        if (!categories[pageDetails.category])
            categories[pageDetails.category] = {
                order: allCategories[pageDetails.category].order,
                title: allCategories[pageDetails.category].title,
                pages: []
            };

        categories[pageDetails.category].pages.push(pageDetails);
    }

    for (let category in categories) {
        categories[category].pages.sort((a, b) => a.order - b.order);
    }

    return categories;
}

export default prepareUserPages;