import React from 'react';

import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer';
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import RightSideContainer from '../../components/TTPageContainers/RightSideContainer';
import TConfirmationModal from '../../components/TConfirmationModal/TConfirmationModal';
import { TfilterTypes } from '../../components/TTable/TTable';
import TTable from '../../components/TTable/TTable';
import TTabs from '../../components/TTabs/TTabs';
import TabPanel from '../../components/TTabs/TabPanel';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import AccordionActions from '@material-ui/core/AccordionActions'
import Divider from '@material-ui/core/Divider';
import { messages } from '../../utils/messages';
import history from '../../../history';
import { handleApiError, alertError, alertSuccess } from '../../utils/errorHandler';
import { getCollaterals, saveCollateral, deleteCollateral, getCompaniesDetail } from '../../api/services';
import TBaseModal from '../../components/TBaseModal/TBaseModal';
import CollateralDetail from './CollateralDetail';
import { extractDate } from '../../utils/common';
import { COLLATERAL_TYPES, DELIVERY_TYPES, TRADING_VENUE } from '../../utils/constants';

const ValidityDateFormatter = ({ row }) => {
    if (row) {
        return (<span>{extractDate(row.bankGuaranteeValidityDate)}</span>);
    }
    return null;
}

class Collateral extends React.Component {
    title = "Collateral";

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        openAddDialogBox: false,
        showDeleteConfirmationModal: false,
        selectedRow: null,
        defaultSelectedTabId: null,
        tabInfo: [],
        tableRows: [],
        tableColumns: [
            { key: 'tradeId', name: 'Trade Id', sortable: true, resizable: true, frozen: true, TfilterType: TfilterTypes.input },
            { key: "issuer_name", name: 'Issuer', sortable: true, resizable: true, frozen: true, TfilterType: TfilterTypes.select, width: 150 },
            { key: 'receiver_name', name: 'Receiver', sortable: true, resizable: true, frozen: true, TfilterType: TfilterTypes.select, width: 150 },
            { key: 'bankGuaranteeValidityDate', name: 'Validity Date', sortable: true, resizable: true, formatter: ValidityDateFormatter },
            { key: 'collateralWithoutTax', name: 'Collateral Without Tax', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'statusOfValidity', name: 'Status of Validity', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
            { key: 'remainingDays', name: 'Remaining days', sortable: false, resizable: true, TfilterType: TfilterTypes.input },
            { key: "tradingVenueName", name: 'Trading Venue', sortable: true, resizable: true, TfilterType: TfilterTypes.select, width: 150 },
            { key: "collateralTypeName", name: 'Type', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'deliveryTypeName', name: 'Delivery Type', sortable: true, resizable: true, TfilterType: TfilterTypes.select },
            { key: 'collateralRate', name: 'Collateral Rate', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
        ]
    }

    componentDidMount() {
        this.onShowButtonClick({});
        this.getCompaniesDetail();
    }

    setTabs = (newTabs, newTabId) => {
        this.setState({ tabInfo: newTabs, defaultSelectedTabId: newTabId })
    }

    onAddButtonClick() {
        this.setState({ openAddDialogBox: true })
    }

    onDeleteRowClick = (row) => {
        this.setState({ showDeleteConfirmationModal: true, selectedRow: row });
    }

    onEditRowClick = (row) => {
        this.addNewTab({ ...row, readOnly: false })
    }

    onCancelButtonClick(row) {
        this.closeAddDialogBox()
        if (row) {
            this.addNewTab({ ...row, readOnly: true })
        }
    }

    closeAddDialogBox() {
        this.setState({ openAddDialogBox: false })
    }

    hideDeleteConfirmationModal = () => {
        this.setState({ showDeleteConfirmationModal: false });
    }

    addNewTab(row) {
        this.setState({ defaultSelectedTabId: row.id })

        let ids = this.state.tabInfo.map(t => t.id)

        row.key = `${row.id}_${row.readOnly}`

        if (!ids.includes(row.id)) {
            this.setState({ tabInfo: [...this.state.tabInfo, row] })
        } else {

            let newTabList = this.state.tabInfo.map(tab => {
                if (tab.id === row.id) {
                    return row
                } else {
                    return tab
                }
            })

            this.setState({ tabInfo: newTabList })
        }
    }

    onShowButtonClick = (filter) => {
        this.spinner.showSpinner('getCollaterals');
        getCollaterals(filter)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        tableRows: this.getTableRows(response.data.success.collateral_result)
                    });
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getCollaterals');
            });
    }

    getTableRows(data) {
        let rowList = []
        for (let i = 0; i < data.length; i++) {
            const currentRow = data[i]

            rowList.push({
                id: currentRow.collateral_id,
                tradeId: currentRow.trade_id,
                issuer: {
                    id: currentRow.issuer,
                    name: currentRow.issuer_name,
                    label: currentRow.issuer_name
                },
                issuer_name: currentRow.issuer_name,
                receiver: {
                    id: currentRow.receiver,
                    name: currentRow.receiver_name,
                    label: currentRow.receiver_name
                },
                receiver_name: currentRow.receiver_name,
                bankGuaranteeValidityDate: new Date(currentRow.validity_date),
                collateralWithoutTax: currentRow.collateral,
                statusOfValidity: currentRow.is_valid,
                remainingDays: currentRow.remaining_days,
                tradingVenue: currentRow.trading_venue,
                tradingVenueName: TRADING_VENUE[currentRow.trading_venue]?.label,
                collateralType: currentRow.collateral_type,
                collateralTypeName: COLLATERAL_TYPES[currentRow.collateral_type]?.label,
                deliveryType: currentRow.delivery_type,
                deliveryTypeName: DELIVERY_TYPES[currentRow.delivery_type]?.label,
                collateralRate: currentRow.collateral_rate
            })
        }

        return rowList;
    }

    async getCompaniesDetail() {
        this.spinner.showSpinner('getCompanyNames');
        getCompaniesDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        counterPartyNames: successData.company_list.map(x => (
                            {
                                id: x.id,
                                name: x.name,
                                label: x.name,
                                type: x.type,
                                documentCount: x.documentCount
                            }
                        )),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getCompanyNames');
            });
    }

    saveButtonClick(requestBody) {
        this.closeAddDialogBox()
        this.spinner.showSpinner('saveCollateral');

        saveCollateral(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.COLLATERAL.SUCCESSFULL_COLLATERAL_SAVE);
                    this.onShowButtonClick({});

                    let row = {
                        id: response.data.success.collateral_id,
                        tradeId: response.data.success.trade_id,
                        issuer: {
                            id: requestBody.issuer,
                            name: requestBody.issuer_name,
                            label: requestBody.issuer_name
                        },
                        issuer_name: requestBody.issuer_name,
                        receiver: {
                            id: requestBody.receiver,
                            name: requestBody.receiver_name,
                            label: requestBody.receiver_name
                        },
                        receiver_name: requestBody.receiver_name,
                        bankGuaranteeValidityDate: requestBody.validity_date_withtz,
                        collateralWithoutTax: requestBody.collateral_without_tax,
                        statusOfValidity: requestBody.is_valid,
                        remainingDays: requestBody.remaining_days,
                        tradingVenue: requestBody.trading_venue,
                        collateralType: requestBody.collateral_type,
                        deliveryType: requestBody.delivery_type,
                        selectedDeliveryType: requestBody.delivery_type,
                        collateralRate: requestBody.collateral_rate
                    }

                    this.addNewTab({ ...row, readOnly: true })
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('saveCollateral');
            });
    }

    deleteButtonClick = () => {
        this.spinner.showSpinner('deleteCollateral');
        let requestBody = {
            collateral_id: this.state.selectedRow.id
        }
        this.hideDeleteConfirmationModal();
        deleteCollateral(requestBody)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(messages.COLLATERAL.SUCCESSFULL_COLLATERAL_DELETE)
                    this.onShowButtonClick({});
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('deleteCollateral');
            });
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onAddButtonClick()}
                            >
                                Add
                            </button>
                        </AccordionActions>

                        <TBaseModal
                            dialogClassName="t-modal-90w"
                            show={this.state.openAddDialogBox}
                            onHide={() => this.closeAddDialogBox()}
                            closeButton={true}
                            title={"Add Collateral"}
                            body={
                                <TabPanel >
                                    <CollateralDetail
                                        item={null}
                                        onSaveButtonClick={(body) => this.saveButtonClick(body)}
                                        onCancelButtonClick={(row) => this.onCancelButtonClick(row)}
                                        readOnly={false}
                                        displayAddEditButtons={true}
                                        counterPartyNames={this.state.counterPartyNames}
                                        displayRemainingDays={true}
                                    >
                                    </CollateralDetail>
                                </TabPanel>
                            }
                        >

                        </TBaseModal>


                        <Divider />
                        <TTable
                            showFilterButtons

                            showExportExcelButton
                            exportExcelFileName={this.title}

                            rows={this.state.tableRows}
                            columns={this.state.tableColumns}

                            showDeleteRow
                            showEditRow

                            onDeleteRowClick={this.onDeleteRowClick}
                            onEditRowClick={this.onEditRowClick}
                        ></TTable>
                    </div>

                }
            >
            </LeftSideContainer>
        )
    }

    getTableDetailComponent() {
        return (
            <RightSideContainer>
                <TTabs
                    key={this.state.defaultSelectedTabId}
                    defaultSelectedTabId={this.state.defaultSelectedTabId}
                    setTabs={this.setTabs}
                >
                    {
                        this.state.tabInfo.map((t) => {
                            return (
                                <TabPanel key={`${t.key}_tabpanel`} title={t.tradeId} id={t.id}>
                                    <CollateralDetail
                                        key={`${t.key}_CollateralDetail`}
                                        item={t}
                                        onSaveButtonClick={(body) => this.saveButtonClick(body)}
                                        onEditButtonClick={(row) => this.addNewTab({ ...row, readOnly: false })}
                                        onCancelButtonClick={(row) => this.onCancelButtonClick(row)}
                                        readOnly={t.readOnly}
                                        counterPartyNames={this.state.counterPartyNames}
                                        displayAddEditButtons={true}
                                        displayRemainingDays={true}
                                    >
                                    </CollateralDetail>
                                </TabPanel>
                            )
                        })
                    }
                </TTabs>
            </RightSideContainer>
        )
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                    rightSideComponent={this.getTableDetailComponent()}
                >
                </TContentDetailContainer>
                {
                    this.state.showDeleteConfirmationModal &&
                    <TConfirmationModal
                        show={this.state.showDeleteConfirmationModal}
                        message={messages.COLLATERAL.CONFIRM_DELETE.replace("[COLLATERAL]", this.state.selectedRow.name)}
                        cancelText='Cancel'
                        onHide={this.hideDeleteConfirmationModal}
                        onCancel={this.hideDeleteConfirmationModal}
                        confirmText='Delete'
                        onConfirm={this.deleteButtonClick}
                    />
                }
            </TContentContainer>
        );
    }

}

export default Collateral;