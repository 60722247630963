import './Parameters.css';

import React from 'react';
import _, { isObject } from 'lodash';

import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import VTable from '../../components/VTable/VTable';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VDropdown from '../../components/VDropdown/VDropdown';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import { getBorders, getBidParameter, getSafetyMargin, getOfferParameters,
         getForecastCountry, getPriceForecasts, savePriceForecasts } from '../../apis/vitusApi';
import { handleApiError, showErrorMessage as textError, alertError, alertSuccess, rearrangeErrors } from '../../helpers/errorHelper';
import { portalMessages } from '../../helpers/portalMessages';
import { getDate, extractDate } from '../../helpers/generalHelper';


class Parameters extends React.Component {

    stBordersAndCounterPartiesTableHeaders = ['Border', 'Counter Party'];
    defaultBidValueTableHeaders = ['Direction', 'Bid Amount (MWh)', 'Min Bid Price (EUR/MWh)'];
    safetyMarginTableHeaders = ['Direction', 'Safety Margin (EUR/MWh)', 'Type'];
    offerParametersTableHeaders = ['Country', 'Exchange', 'Counter Party', 'Borders', 'Currency',
        'Min Price', 'Max Price', 'Limit Min Price', 'Limit Max Price', 'Bidding Step', 'Format'];
        
    dateTimeColumnName = "DateTime"

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        borders: [],
        directions: [],
        counterParties: [],
        stBordersAndCounterPartiesTableData: [],
        defaultBidValuesTableData: [],
        safetyMarginTableData: [],
        offerParametersTableData: [],
        forecastsTableData: [],
        showRefreshMessageForBorder: false,
        showRefreshMessageForBidParameter: false,
        showRefreshMessageForSafetyMargin: false,
        showRefreshMessageForOfferParameter: false,
        showRefreshMessageForForecasts: false,
        selectedDate: getDate(1),
        countryList: []
    }

    componentDidMount() {
        this.callGetBorders();
    }

    showErrorMessage(error) {
        const errorMessage = this.setErrorMessage(error);
        if (errorMessage)
            alertError(errorMessage);
    }

    showSuccessMessage(message) {
        alertSuccess(message);
    }

    setErrorMessage(error) {
        let message;

        if (!isObject(error)) {
            message = error;
        }
        else {
            let allErrors = rearrangeErrors(error);

            if (!_.isEmpty(allErrors)) {
                Object.keys(allErrors).forEach(errorKey => {
                    const borderError = allErrors[errorKey];

                    Object.keys(borderError).forEach(errorType => {
                        switch (errorType) {
                            case 'NotFound':
                                switch (borderError[errorType]) {
                                    case 'bid_parameter':
                                        message = portalMessages.PARAMETERS.NO_DEFAULT_BIDS_FOUND;
                                        break;

                                    case 'safety_margin':
                                        message = portalMessages.PARAMETERS.NO_SAFETY_MARGIN_FOUND;
                                        break;

                                    case 'offer_parameters':
                                        message = portalMessages.PARAMETERS.NO_OFFER_PARAMETERS_FOUND;
                                        break;

                                    default:
                                        message = portalMessages.UNEXPECTED_ERROR_OCCURED;
                                        break;
                                }
                                break;

                            case 'DB':
                                switch (borderError[errorType]) {
                                    case 'borders':
                                        message = portalMessages.BORDERS_AND_COUNTER_PARTIES_NOT_FOUND;
                                        this.setState({ showRefreshMessageForBorder: true });
                                        break;

                                    case 'get_bid_parameters':
                                        message = portalMessages.PARAMETERS.DEFAULT_BIDS_NOT_GET;
                                        this.setState({ showRefreshMessageForBidParameter: true });
                                        break;

                                    case 'get_safety_margins':
                                        message = portalMessages.PARAMETERS.SAFETY_MARGINS_NOT_GET;
                                        this.setState({ showRefreshMessageForSafetyMargin: true });
                                        break;

                                    case 'get_offer_parameters':
                                        message = portalMessages.PARAMETERS.OFFER_PARAMETERS_NOT_GET;
                                        this.setState({ showRefreshMessageForOfferParameter: true });
                                        break;

                                    default:
                                        message = portalMessages.UNEXPECTED_ERROR_OCCURED;
                                        break;
                                }
                                break;

                            default:
                                message = portalMessages.UNEXPECTED_ERROR_OCCURED;
                                break;
                        }
                    });
                });
            }
        }

        return message;
    }

    callGetBorders() {

        this.spinner.showSpinner('getBorders');

        getBorders()
            .then(response => {
                if (response.data.success) {
                    this.setBorders(response.data.success.borders);
                } else {
                    this.showErrorMessage(response.data.error);
                }

            }, error => {
                handleApiError(error);
                this.setState({ showRefreshMessageForBorder: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('getBorders');
            });
    }

    setBorders(borders) {
        var fetchedBorders = [];
        var fetchedDirections = [];
        var fetchedCounterParties = {};

        borders.forEach(eachBorder => {

            fetchedBorders.push({
                value: eachBorder.border,
                label: eachBorder.border
            });

            fetchedDirections[eachBorder.border] = [];

            eachBorder.directions.forEach(eachDirection => {
                let counterPartiesOfCurrentDirection = eachDirection.counterParties.map(counterParty => {
                    return {
                        value: `${counterParty}`,
                        label: `${counterParty}`
                    }
                });

                // Border-Direction Pairs
                fetchedDirections[eachBorder.border].push(eachDirection.direction);

                // Direction-CounterParty Pairs
                fetchedCounterParties[eachDirection.direction] = [];

                counterPartiesOfCurrentDirection.forEach(currentCounterParty => {
                    fetchedCounterParties[eachDirection.direction].push(currentCounterParty);
                });
            });
        });

        let orderedBorders = _.orderBy(fetchedBorders, ['label'], ['asc']);

        this.setState({
            borders: orderedBorders,
            directions: fetchedDirections,
            counterParties: fetchedCounterParties,
            showRefreshMessageForBorder: false,
            stBordersAndCounterPartiesTableData: this.convertJsonToStBordersAndCounterPartiesTable(orderedBorders, fetchedDirections, fetchedCounterParties)
        });

    }

    callGetBidParameter() {

        this.spinner.showSpinner('bidParameter');

        getBidParameter()
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        showRefreshMessageForBidParameter: false,
                        defaultBidValuesTableData: this.convertJsonToDefaultBidParametersTable(response.data.success.bid_parameters)
                    });
                } else {
                    this.showErrorMessage(response.data.error);
                }

            }, error => {
                handleApiError(error);
                this.setState({ showRefreshMessageForBidParameter: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('bidParameter');
            });
    }

    callGetSafetyMargin() {

        this.spinner.showSpinner('safetyMargin');

        getSafetyMargin()
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        showRefreshMessageForSafetyMargin: false,
                        safetyMarginTableData: this.convertJsonToSafetyMarginTable(response.data.success.safety_margins)
                    });
                }
                else {
                    this.showErrorMessage(response.data.error);
                }

            }, error => {
                handleApiError(error);
                this.setState({ showRefreshMessageForSafetyMargin: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('safetyMargin');
            });
    }

    callGetOfferParameter() {

        this.spinner.showSpinner('offerParameter');

        getOfferParameters()
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        showRefreshMessageForOfferParameter: false,
                        offerParametersTableData: this.convertJsonToOfferParametersTable(response.data.success.offer_parameters)
                    });
                }
                else {
                    this.showErrorMessage(response.data.error);
                }

            }, error => {
                handleApiError(error);
                this.setState({ showRefreshMessageForOfferParameter: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('offerParameter');
            });
    }

    callGetForecasts() {
        this.spinner.showSpinner('forecasts');
        let filter = {
            requested_date: extractDate(this.state.selectedDate)
        }

        getPriceForecasts(filter)
            .then(response => {
                if (response.data.success) {
                    let result_list = response.data.success.result_list
                    let forecastsTableDataList = Object.keys(result_list).map(key => {
                        let country_data = result_list[key]
                        return {
                            country_code: country_data["country_code"],
                            country_id: country_data["country_id"],
                            source: country_data["source"],
                            version: country_data["version"],
                            data: this.convertJsonToForecastsTable(country_data["country_id"], country_data["data"])
                        }
                    })
                    this.setState({
                        showRefreshMessageForForecasts: false,
                        forecastsTableData: forecastsTableDataList
                    });
                }
                else {
                    this.showErrorMessage(response.data.error);
                }

            }, error => {
                handleApiError(error);
                this.setState({ showRefreshMessageForForecasts: true });
            })
            .finally(() => {
                this.spinner.hideSpinner('forecasts');
            });
    }

    callGetCountryList() {
        this.spinner.showSpinner('countryList');

        getForecastCountry()
            .then(response => {
                if (response.data.success) {
                    let country_list = response.data.success.country_list

                    this.setState({
                        countryList: country_list
                    });
                }
                else {
                    this.showErrorMessage(response.data.error);
                }

            }, error => {
                handleApiError(error);
            })
            .finally(() => {
                this.spinner.hideSpinner('countryList');
            });
    }


    // Tab
    onTabSelect = key => {
        switch (key) {
            case "BordersAndcounterParties":
                if (_.isEmpty(this.state.stBordersAndCounterPartiesTableData))
                    this.callGetBorders();
                break;

            case "BidParameters":
                if (_.isEmpty(this.state.defaultBidValuesTableData))
                    this.callGetBidParameter();

                if (_.isEmpty(this.state.safetyMarginTableData))
                    this.callGetSafetyMargin();
                break;

            case "OfferParameters":
                if (_.isEmpty(this.state.offerParametersTableData))
                    this.callGetOfferParameter();
                break;
            case "Prices":
                if (_.isEmpty(this.state.forecastsTableData)) {
                    this.callGetCountryList();
                    this.callGetForecasts();
                }
                    
                break;

            default:
                throw new Error("Unexpected tab selection!");
        }
    }

    // Refresh Button
    onRefreshButtonClick = serviceToReCall => {
        switch (serviceToReCall) {
            case "borders":
                this.callGetBorders();
                break;

            case "bidParameters":
                this.callGetBidParameter();
                break;

            case "safetyMargin":
                this.callGetSafetyMargin();
                break;

            case "offers":
                this.callGetOfferParameter();
                break;

            default:
                throw new Error("Unexpected service recall!");
        }
    }


    // Table
    convertJsonToStBordersAndCounterPartiesTable(borders, directions, counterParties) {
        let headers = this.stBordersAndCounterPartiesTableHeaders;
        let values = [];

        borders.forEach(border => {
            let counterPartiesOfCurrentBorder = [];

            directions[border.value].forEach(direction => {
                counterParties[direction].forEach(counterParty => {

                    if (!counterPartiesOfCurrentBorder.includes(counterParty.value))
                        counterPartiesOfCurrentBorder.push(counterParty.value);
                });
            });

            values.push([border.value, counterPartiesOfCurrentBorder.join(', ')]);
        });

        return {
            headers,
            values
        };
    }

    convertJsonToDefaultBidParametersTable(bidParameters) {
        let headers = this.defaultBidValueTableHeaders;
        let values = [];

        bidParameters.forEach(value => {
            values.push([value.Direction, value.BidAmount, value.MinBidPrice])
        });

        return {
            headers,
            values
        };
    }

    convertJsonToSafetyMarginTable(safetyMargins) {
        let headers = this.safetyMarginTableHeaders;
        let values = [];

        safetyMargins.forEach(value => {
            values.push([value.Direction, value.Margin, value.Type])
        });

        return {
            headers,
            values
        };
    }

    convertJsonToOfferParametersTable(offerParameters) {
        let headers = this.offerParametersTableHeaders;
        let values = [];
        let counterPartyToShow;

        offerParameters.forEach(offer => {

            counterPartyToShow = offer.counter_party === offer.result_counter_party ? offer.counter_party :
                `${offer.counter_party} > ${offer.result_counter_party}`;

            values.push([
                offer.country,
                offer.exchange,
                counterPartyToShow,
                _.join(offer.borders, ', '),
                offer.currency,
                offer.min_price,
                offer.max_price,
                offer.limit_min_price,
                offer.limit_max_price,
                offer.bidding_step,
                offer.format
            ]);
        });

        return {
            headers,
            values
        };
    }

    convertJsonToForecastsTable(country_id, forecasts) {
        let countryInfo = this.state.countryList.filter(c => c.country_id === country_id)
        let curr = countryInfo.length ? `${countryInfo[0].currency}` : ""
      
        let headers = [
            [this.dateTimeColumnName, "Forecast", "Realized"],
            ["CET", `${curr}`, `${curr}`]
        ];
        let values = [];

        forecasts.forEach(f => {

            values.push([
                f.date_time,
                f.price,
                f.dap
            ])
        });

        return {
            headers,
            values
        };
    }

    onSaveTable = async (country_forecast, newItemsTableData) => {
        this.spinner.showSpinner('savePriceForecast');

        let updatedData = newItemsTableData.values.map(value => {
            return {
                "date_time": value[0],
                "value": Number(value[1]) 
            }
        });

        let body = {
            requested_date: extractDate(this.state.selectedDate),
            country_id: country_forecast["country_id"],
            updated_data: updatedData,
            source: country_forecast["source"],
            version: country_forecast["version"]
        }

        savePriceForecasts(body)
            .then(response => {
                if (response.data.success) {
                    alertSuccess(portalMessages.PARAMETERS.FORECASTS_UPDATED_SUCCESSFULLY);
                    this.callGetForecasts({});
                    return true;

                } else {
                    alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
                    return false;
                }
            }, error => {
                handleApiError(error);
                return false;
            }).finally(() => {
                this.spinner.hideSpinner('savePriceForecast');
                return true;
            });
        
        return true;
        
    }

    renderRefreshMessage(serviceToReCall, errorMessage) {
        return (
            <div className="v-parameters-error-content">
                <div className="v-parameters-error-message">
                    {textError(errorMessage)}
                </div>
                <div className="v-parameters-refresh-button">
                    <button className="btn v-cancel-button"
                        onClick={() => this.onRefreshButtonClick(serviceToReCall)}>
                        <i aria-hidden="true" className="fa fa-refresh fa-fw" /> Refresh
                </button>
                </div>
            </div>
        );
    }

    createSavedDataTable(country_forecast) {

        return (
            <VTable
                key={`${country_forecast["country_id"]}_KEY`}
                title={`${country_forecast["country_code"]} Prices`}
                inputType='text'
                simpleNumbers
                items={country_forecast["data"]}
                showAverage
                inputType='number'
                readonlyColumns={[this.dateTimeColumnName, "Realized"]}
                headerButtons={{
                    showEditButton: true,
                    showExportExcelButton: true
                }}
                onSaveChangesAsync={(newData) => this.onSaveTable(country_forecast, newData)}
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Parameters">
                    <VMainContainer>
                        <VTabs onSelect={this.onTabSelect}>
                            <VTab eventKey="BordersAndcounterParties" title="Borders And Counter Parties">
                                {!_.isEmpty(this.state.stBordersAndCounterPartiesTableData) &&
                                    <div className="container">
                                        <div className='row'>
                                            <div className="v-infinite-width v-parameters-table">
                                                <VTable
                                                    title='ST Borders and Counter Parties'
                                                    inputType='text'
                                                    items={this.state.stBordersAndCounterPartiesTableData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.showRefreshMessageForBorder &&
                                    this.renderRefreshMessage("borders", portalMessages.BORDERS_AND_COUNTER_PARTIES_NOT_FOUND)
                                }
                            </VTab>

                            <VTab eventKey="BidParameters" title="Bid Parameters">
                                <div className="container">
                                    <div className='row'>
                                        {!_.isEmpty(this.state.defaultBidValuesTableData) &&
                                            <div className='v-infinite-width v-parameters-table v-parameters-bid-parameters'>
                                                <VTable
                                                    title='Default Bid Values'
                                                    inputType='text'
                                                    items={this.state.defaultBidValuesTableData}
                                                    readonlyColumns={['Bid Amount (MWh)', 'Min Bid Price (EUR/MWh)']}
                                                />
                                            </div>
                                        }
                                        {
                                            this.state.showRefreshMessageForBidParameter &&
                                            this.renderRefreshMessage("bidParameters", portalMessages.PARAMETERS.DEFAULT_BIDS_NOT_GET)
                                        }
                                        {!_.isEmpty(this.state.safetyMarginTableData) &&
                                            <div className='v-infinite-width v-parameters-table v-parameters-bid-parameters'>
                                                <VTable
                                                    title='Safety Margin'
                                                    inputType='text'
                                                    items={this.state.safetyMarginTableData}
                                                    scales={{ '*': 2 }}
                                                    readonlyColumns={['Safety Margin (EUR/MWh)']}
                                                />
                                            </div>
                                        }
                                        {
                                            this.state.showRefreshMessageForSafetyMargin &&
                                            this.renderRefreshMessage("safetyMargin", portalMessages.PARAMETERS.SAFETY_MARGINS_NOT_GET)
                                        }
                                    </div>
                                </div>
                            </VTab>

                            <VTab eventKey="OfferParameters" title="Offer Parameters">
                                {!_.isEmpty(this.state.offerParametersTableData) &&
                                    <div className="container">
                                        <div className='row v-parameters-table'>
                                            <div className='v-infinite-width'>
                                                <VTable
                                                    title='Offer Parameters'
                                                    inputType='text'
                                                    simpleNumbers
                                                    items={this.state.offerParametersTableData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.showRefreshMessageForOfferParameter &&
                                    this.renderRefreshMessage("offers", portalMessages.PARAMETERS.OFFER_PARAMETERS_NOT_GET)
                                }
                            </VTab>

                            <VTab eventKey="Prices" title="Prices">
                                <VFilterContainer>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Date
                                    </div>
                                        <div>
                                            <VDatePicker
                                                selectedDate={this.state.selectedDate}
                                                onSelectedDateChange={(selectedDate) => this.setState({ selectedDate })}

                                            />
                                        </div>
                                    </div>
                                   
                                    <div className="v-filter-buttons">

                                        <button
                                            tabIndex={0}
                                            className="btn v-button v-filter-button"
                                            onClick={() => this.callGetForecasts()}>
                                            <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                                        </button>
                                    </div>

                                </VFilterContainer>

                                {!_.isEmpty(this.state.forecastsTableData) &&
                                    <div className="container">
                                        <div className='row'>
                                            <div className='v-infinite-width v-table-list'>
                                                {
                                                    this.state.forecastsTableData.map(country_forecast => {
                                                        return this.createSavedDataTable(country_forecast);
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.showRefreshMessageForForecasts &&
                                    this.renderRefreshMessage("offers", portalMessages.PARAMETERS.NO_FORACEST_FOUND)
                                }
                            </VTab>

                        </VTabs>
                    </VMainContainer>
                </VContentContainer>
            </React.Fragment>
        );
    }

}

export default Parameters;