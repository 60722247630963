import React from 'react';
import { Link } from 'react-router-dom';

import './VCategoryItem.css';

const VCategoryItem = ({ title, icon, route }) => {

    const finalIcon = `${icon} fa-3x v-light-blue`

    return (
        <Link to={route}>
            <div className="v-category-item">
                <div className="v-category-item-icon">
                    <i className={finalIcon} aria-hidden="true"></i>
                </div>
                <div className="v-category-item-title">
                    <p className="v-font v-light-blue">{title}</p>
                </div>
            </div>
        </Link>
    );
};

export default VCategoryItem;