import React from "react";
import TContentContainer from "../../components/TContentContainer/TContentContainer";
import TContentDetailContainer from "../../components/TContentDetailContainer/TContentDetailContainer";
import LeftSideContainer from "../../components/TTPageContainers/LeftSideContainer";
import ContractPnlFilter from './ContractPnlFilter';
import TTable from '../../components/TTable/TTable';
import { TfilterTypes } from '../../components/TTable/TTable';
import {
  getMarketNames,
  getBookDetails,
  getCompaniesDetail,
  getContractPnl,
} from "../../api/services";
import { handleApiError } from "../../utils/errorHandler";
import { SpinnerManager } from "../../components/TSpinner/SpinnerManager";
import history from "../../../history";
import { DELIVERY_TYPES, POSITION, TRADING_VENUE, CONTRACT_TYPES, PRODUCT } from '../../utils/constants';

const month_names = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

function valueFormatter(value) {
   
    if (value) {
        return value.toLocaleString(undefined, {maximumFractionDigits:2})
    }
        
    return value
}

const PositionFormatter = ({ row }) => {
    if (row) {
        return (<span className={`${row.position === POSITION.sell ? "t-table-sell" : "t-table-buy"}`}>{row.position}</span>);
    }
    return null;
};

const table_columns = [
    { key: 'tradeId', name: 'TradeId', sortable: true, resizable: true, frozen: true, TfilterType: TfilterTypes.input },
    { key: 'name', name: 'Name', sortable: true, resizable: true, frozen: true, TfilterType: TfilterTypes.input },
    { key: 'companyName', name: 'Company', sortable: true, resizable: true, frozen: true, TfilterType: TfilterTypes.multiSelect },
    { key: "tradingVenueName", name: 'Trading Venue', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect, width: 120 },
    { key: 'contractTypeName', name: 'Contract Type', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
    { key: 'deliveryTypeName', name: 'Delivery Type', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
    { key: 'productName', name: 'Product', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
    { key: 'bookName', name: 'Book', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
    { key: 'marketName', name: 'Market', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
    { key: 'counterPartyName', name: 'Counter Party', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
    { key: 'periodName', name: 'Period', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
    { key: 'position', name: 'Position', sortable: true, resizable: true, formatter: PositionFormatter },
    { key: 'price', name: 'Price', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
    { key: 'amount', name: 'Amount', sortable: true, resizable: true, TfilterType: TfilterTypes.input },
    { key: 'currency', name: 'Currency', sortable: true, resizable: true, TfilterType: TfilterTypes.multiSelect },
];

////----------------------------------------------- CLASS COMPONENT -----------------------------------------------////

class ContractPnl extends React.Component {
  title = "Contract Pnl";

  spinner = new SpinnerManager(history.location.pathname);

  state = {
    rows: [],
    columns: [],
    selectedRows: new Set(),
    counterPartyNames: [],
    marketNames: [],
    bookNames: [],
    openInfoDialogBox: false,
  };

  componentDidMount() {
    this.getCompaniesDetail();
    this.getMarketDetails();
    this.getBookDetails();
  }

  onShowButtonClick = async (filter) => {
    this.spinner.showSpinner("getContractPnl");
    getContractPnl(filter)
      .then((res) => {
        
        const resultList = res.data.success.result_list;

        let month_columns = new Set()
        let years = new Set()
       
        let row_list = resultList.map(c => {
          let row = {
            id: c.id,
            tradeId: c.trade_id,
            name: c.contract_name,
            deliveryTypeName: DELIVERY_TYPES[c.delivery_type]?.label,
            tradingVenueName: TRADING_VENUE[c.trading_venue]?.label,
            contractTypeName: CONTRACT_TYPES[c.contract_type]?.label,
            companyName: c.company_name,
            productName: PRODUCT[c.product]?.label,
            bookName: c.book_name,
            marketName: c.market_name,
            counterPartyName: c.counter_party_name,
            periodName: c.period_name,
            position: c.position,
            currency: c.currency,
            price: c.price,
            amount: c.amount,
            total: 0
          }
          let pnl = c.pnl

          Object.keys(pnl).map(b => {
  
            let year_pnl = pnl[b]
            let year = b.toString().substr(2)
            years.add(year)

            Object.keys(year_pnl).map(m => {
              let month_name = `${m}${year}`.toString()
              if (year_pnl[m])
                month_columns.add(month_name)
              row[month_name] = year_pnl[m]
              row["total"] += year_pnl[m]
            })
          })

          return row
        })
   
        let month_table_column = []
          years.forEach(y => {
  
            month_names.forEach(m => {
  
              let month_name = `${m}${y}`.toString()
  
              if (month_columns.has(month_name)) {
                month_table_column = [...month_table_column,
                {
                  key: month_name,
                  name: month_name.charAt(0).toUpperCase() + month_name.slice(1),
                  sortable: true,
                  resizable: true,
                  formatter({ row }) {
                    return <>{valueFormatter(row[month_name])}</>;
                  }
                }]
              }
            })
          })

          month_table_column = [...month_table_column,
            {
              key: "total",
              name: "Total",
              sortable: true,
              resizable: true,
              formatter({ row }) {
                return <>{valueFormatter(row["total"])}</>;
              }
            }]
  
          this.setState({
            columns: [...table_columns, ...month_table_column],
            month_table_column: month_table_column,
            rows: row_list
          });

      })
      .finally(() => this.spinner.hideSpinner("getContractPnl"));
  };

  async getCompaniesDetail() {
    this.spinner.showSpinner("getCompanyNames");
    getCompaniesDetail()
      .then(
        (response) => {
          if (response.data.success) {
            let successData = response.data.success;
            this.setState({
              counterPartyNames: successData.company_list.map((x) => ({
                id: x.id,
                value: x.name,
                label: x.name,
                type: x.type,
              })),
            });
          }
        },
        (error) => {
          handleApiError(error);
        }
      )
      .finally(() => {
        this.spinner.hideSpinner("getCompanyNames");
      });
  }

  async getMarketDetails() {
    this.spinner.showSpinner("getMarketNames");
    getMarketNames()
      .then(
        (response) => {
          if (response.data.success) {
            let successData = response.data.success;
            this.setState({
              marketNames: successData.market_name_list.map((x) => ({
                id: x.id,
                value: x.name,
                label: x.name,
                currency: x.currency,
              })),
            });
          }
        },
        (error) => {
          handleApiError(error);
        }
      )
      .finally(() => {
        this.spinner.hideSpinner("getMarketNames");
      });
  }

  async getBookDetails() {
    this.spinner.showSpinner("getBookNames");
    getBookDetails()
      .then(
        (response) => {
          if (response.data.success) {
            let successData = response.data.success;
            this.setState({
              bookNames: successData.book_list.map((x) => ({
                id: x.id,
                value: x.name,
                label: x.name,
              })),
            });
          }
        },
        (error) => {
          handleApiError(error);
        }
      )
      .finally(() => {
        this.spinner.hideSpinner("getBookNames");
      });
  }

  onInfoButtonClick() {
    this.setState({ openInfoDialogBox: true });
  }

  closeInfoDialogBox() {
    this.setState({ openInfoDialogBox: false });
  }

  getTableSubTotal = rows => {
    let currency_list = new Set(rows.map(r =>  r.currency))
    if (rows.length) {
     
      return [...currency_list].map(curr => {
            
            let currency_rows = rows.filter(r => r.currency === curr)
            
            const sum = currency_rows.reduce((prev, row) => prev + row.pnl, 0)
            const sumWithCost = currency_rows.reduce((prev, row) => prev + row.pnlWithCost, 0)
           
            let row = {
              id: `${curr}_sub_total`,
              currency: curr,
              pnl: sum,
              pnlWithCost: sumWithCost
            }

            this.state.month_table_column.map(m => {
              let month_name = m.key

              let sum = currency_rows.reduce((prev, row) => prev + row[month_name], 0)
              
              row[month_name] = sum
            })

            return row
      }) 
    }

    return []
  }

  getTableComponent() {
    return (
      <LeftSideContainer
        contentLabel={this.title}
        contentDefaultExpanded={true}
        contentComponent={
          <div>
            <TTable
                showFilterButtons

                showExportExcelButton
                exportExcelFileName={this.title}

                rows={this.state.rows}
                columns={this.state.columns}
                subTotalAgg={this.getTableSubTotal}

            ></TTable>
          </div>
        }
        filterComponent={
          this.state.counterPartyNames.length &&
          this.state.marketNames.length &&
          this.state.bookNames.length ? (
            <ContractPnlFilter
              counterPartyNames={this.state.counterPartyNames}
              marketNames={this.state.marketNames}
              bookNames={this.state.bookNames}
              onShowButtonClick={this.onShowButtonClick}
            ></ContractPnlFilter>
          ) : null
        }
      ></LeftSideContainer>
    );
  }

  render() {
    return (
      <TContentContainer>
        <TContentDetailContainer
          leftSideComponent={this.getTableComponent()}
        ></TContentDetailContainer>
      </TContentContainer>
    );
  }
}

export default ContractPnl;
