import FileSaver from 'file-saver';
import { isObject } from 'lodash';
import * as XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const generateSheet = (headers, values) => {
    let dataArr = headers.map(row => {
        return row.map(h => {
            if (isObject(h))
                return h.name;
            return h;
        });
    });

    dataArr = dataArr.concat(values.map(row => {
        return row.map(cell => {
            if (!isNaN(cell) && cell !== '')
                return Number(cell);
            return cell;
        });
    }));

    const ws = XLSX.utils.json_to_sheet(dataArr, { skipHeader: true });

    return ws;
}

export const createExcel = (headers, values, fileName) => {
    const ws = generateSheet(headers, values);

    const wb = { Sheets: { 'Sheet1': ws }, SheetNames: ['Sheet1'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

/**
 * 
 * @param {*} fileName
 * @param {*} sheets:
 * [
 *  {
 *      name: sheetName1,
 *      data: {
 *              headers: [h1, h2, h3]
 *              values: [[v11,v21,v31],[v21,v22,v23]]
 *      }
 *  },
 *  {
 *      name: sheetName2,
 *      data: {
 *          headers: [h1, h2, h3]
 *          values: [[v11,v21,v31],[v21,v22,v23]]
 *      }
 *  },
 *  ...
 * ]
 */

export const createExcelWithMultipleSheets = (fileName, sheets) => {
    const wb = { Sheets: {}, SheetNames: [] };

    sheets.forEach(s => {
        wb.Sheets[s.name] = generateSheet(s.data.headers, s.data.values);
        wb.SheetNames.push(s.name);
    });

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
}

export const loadExcel = (file, onExcelLoaded) => {
    var reader = new FileReader();

    reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: 'binary', cellDates: true });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const parsedData = XLSX.utils.sheet_to_json(ws, { header: 1 });
     
        onExcelLoaded(parsedData);
    };

    reader.readAsBinaryString(file);
};