import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'flag-icon-css/css/flag-icon.css';

import React from 'react';
import './App.css';
import './TApp.css';
import PageAuth from './auth/PageAuth';
import VFooter from './components/VFooter/VFooter';
import VAlertList from './components/VAlertList/VAlertList';
import VSpinner from './components/VSpinner/VSpinner';

class App extends React.Component {
    render() {
        return (
            <React.Fragment>
                <VAlertList />
                <PageAuth />
                <VSpinner />
                <VFooter />
            </React.Fragment>
        );
    };
}

export default App;