import React from 'react';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VDropdown from '../../components/VDropdown/VDropdown';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import { alertError, alertSuccess, alertWarning, handleApiError, specifyErrorMessage } from '../../helpers/errorHelper';
import { extractDate, getDayAheadForGivenDate, getCompanyOptions, isCompanySelectable } from '../../helpers/generalHelper';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorageHelper';
import { portalMessages } from '../../helpers/portalMessages';
import history from '../../history';
import {
    getCompanyNominations,
    getCompanyNominationsFile,
    sendCompanyNominationsFile
} from '../../apis/vitusApi';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import { isEmpty, isObject } from 'lodash';
import {isArray, sortBy} from 'lodash';
import FileSaver from 'file-saver';

import './PowerSchedules.css';

class PowerSchedules extends React.Component {
    spinner = new SpinnerManager(history.location.pathname);
    storedActiveFilter = getLocalStorage('powerSchedules', 'filters', 'activeFilter') || {};

    companies = {
        vitusCommodities: {label: "Vitus Commodities", name: "Vitus"},
        monolithCapital: {label: "Monolith Capital", name: "Monolith Capital"},
        vitusCapital: {label: "Vitus Capital", name: "Vitus Capital"},
    }

    rowClasses = ['v-row-group-1', 'v-row-group-2'];
    dataGroupClasses = ['v-data-group-1', 'v-data-group-2'];
    dataGroupDividerClasses = {'v-data-group-1': 'v-data-group-1-divider', 'v-data-group-2': 'v-data-group-2-divider'}
    periods = {       
        ST_LT: {label: ['ST', 'LT'], name: 'Short Term + Long Term'},
        LT: {label: ['LT'], name: 'Long Term'},
        ST: {label: ['ST'], name: 'Short Term'},
        
    }
    counterPartyNames = ['Alpiq', 'Axpo', 'Energovia', 'Ensco', 'Green', 'Ritam']

    state = {
        activeFilterToDisplay: [],
        selectedDate: this.getDefaultDate(),
        counterPartyOptions: [],
        companyOptions: [],
        periodOptions: [],
        activeFilter: {
            requestedDate: null,
            company: this.storedActiveFilter?.company,
            period: this.storedActiveFilter?.period,
        },
        editingNominations: false,
        showResetNominationsModal: false,
        editingIntraday: false,
        showResetIntradayModal: false,
        showIrregularMailModal: false,
        irregularMailConfirmed: false
    };

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Default)'
    };

    getPeriodOptions() {
        return Object.keys(this.periods).map(c => { return { value: this.periods[c].label, label: this.periods[c].name } })
    }

    getCompanyOptions() {
        return Object.keys(this.companies).map(c => { return { value: this.companies[c].name, label: this.companies[c].label } })
    }

    componentDidMount() {
        let selectedCompanyOption;
        let selectedPeriodOption;

        const companyOptions = this.getCompanyOptions();
        const periodOptions = this.getPeriodOptions();

        if (this.state.activeFilter.company) {
            selectedCompanyOption = companyOptions.filter(e => e.label === this.state.activeFilter.company);

            if (selectedCompanyOption.length !== 0)
                selectedCompanyOption = selectedCompanyOption[0];
            else
                selectedCompanyOption = "";
        }

        if (this.state.activeFilter.period) {
            selectedPeriodOption = periodOptions.filter(e => e.label === this.state.activeFilter.period);

            if (selectedPeriodOption.length !== 0)
                selectedPeriodOption = selectedPeriodOption[0];
            else
                selectedPeriodOption = "";
        }

        if (!selectedCompanyOption) {
            selectedCompanyOption = companyOptions[0];
        }

        if (!selectedPeriodOption) {
            selectedPeriodOption = periodOptions[0];
        }

        this.setState({ companyOptions, selectedCompanyOption, periodOptions, selectedPeriodOption });
        
        const filter = {
                requested_date: extractDate(this.state.selectedDate),
                company: selectedCompanyOption.value,
                period: selectedPeriodOption.value,
            };
        
        this.refreshNominationsAsync(filter);
    }

    showErrorMessage(error, operationName) {
        const { message, errorType } = specifyErrorMessage(error, this.errorMessages, operationName);

        if (message)
            alertError(message);
    }

    exportNominations = async () => {
        const spinnerKey = this.spinner.showSpinner();

        let response;
        let body = {'company': this.state.selectedCompanyOption.value, 'requested_date': extractDate(this.state.selectedDate), 'period': this.state.selectedPeriodOption.value, 'nominations_list': this.state.nominationList}; 
        try {
            response = await getCompanyNominationsFile(body);
            
            const filename = `${this.state.activeFilter.company} Total Physical Flows  ${this.state.activeFilter.requestedDate}.xlsx`;
            const contentType = response.headers["content-type"];

            const blob = new Blob([response.data], { type: contentType });

            if (contentType === 'application/json') {
                //const error = blob.text();
                return;
            }

            FileSaver.saveAs(blob, filename);
            return;
        } catch (error) {
            handleApiError(error);
            return false;
        }
        finally{
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    sendNominations = async () => {
        const spinnerKey = this.spinner.showSpinner();

        let response;
        const filename = `${this.state.activeFilter.company} Total Physical Flows ${this.state.activeFilter.requestedDate}.xlsx`;
        let body = {'company': this.state.selectedCompanyOption.value, 'requested_date': extractDate(this.state.selectedDate), 'period': this.state.selectedPeriodOption.value, 'nominations_list': this.state.nominationList,
                    'flows_file_name': filename}; 
        try {
            response = await sendCompanyNominationsFile(body);
            
            if (response.data.success){
                alertSuccess(portalMessages.POWER_SCHEDULES.SEND_NOMINATION_SUCCESSFUL);
            }
            else{
                alertError(portalMessages.POWER_SCHEDULES.SEND_NOMINATION_ERROR);
            }
            return;
        } catch (error) {
            handleApiError(error);
            return false;
        }
        finally{
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    refreshNominationsAsync = async (filter) => {
        if (!filter)
            filter = {
                requested_date: this.state.activeFilter.requestedDate,
                company: this.state.activeFilter.company,
                period: this.state.activeFilter.period
            };

        return await this.getNominationsAsync(filter)
    }

    getNominationsAsync = async (filter) => {
        const spinnerKey = this.spinner.showSpinner();

        try {
            let response;

            try {
                response = await getCompanyNominations(filter);
            } catch (error) {
                handleApiError(error);
                return false;
            }
            
            if (response.data.success) {

                if (response.data.success.nomination_list.length === 0){
                    alertSuccess(portalMessages.POWER_SCHEDULES.NO_NOMINATIONS.replace('[DATE]', filter.requested_date));
                }
                this.setState({
                    nominationList: response.data.success.nomination_list,
                    activeFilter: {
                        requestedDate: filter.requested_date,
                        company: filter.company,
                        period: filter.period
                    },
                    activeFilterToDisplay: [
                        { label: "Date", value: filter.requested_date },
                        { label: "Company", value: filter.company },
                        { label: "Period", value: filter.period },
                    ]
                }, () => {
                    setLocalStorage('powerSchedules', 'filters', 'activeFilter',
                        { requestedDate: filter.requested_date, company: filter.company, period: filter.period });
                });

            } else if (!response.data.error) {
                this.setState({
                    nominationList: [],
                    activeFilter: {
                        requestedDate: filter.requested_date,
                        company: filter.company,
                        period: filter.period
                    },
                    activeFilterToDisplay: [
                        { label: "Date", value: filter.requested_date },
                        { label: "Company", value: filter.company },
                        { label: "Period", value: filter.period },
                    ]
                }, () => {
                    setLocalStorage('powerSchedules', 'filters', 'activeFilter',
                        { requestedDate: filter.requested_date, company: filter.company, period: filter.period });
                });
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Nominations)');
            }

            if (response.data.error) {
                this.showErrorMessage(response.data.error);
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Nominations)');
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    getMaxEditableDate() {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        return today.setDate(today.getDate() + 2);
    }

    getDefaultDate(){
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        return today.setDate(today.getDate() + 1);
    }

    onShowButtonClick() {
        if (!this.state.selectedDate) {
            this.showErrorMessage(portalMessages.DATE_SELECTION);
            return;
        }

        if (!this.state.selectedCompanyOption || isEmpty(this.state.selectedCompanyOption)) {
            this.showErrorMessage(portalMessages.SELECT_COMPANY);
            return;
        }

        if (!this.state.selectedPeriodOption || isEmpty(this.state.selectedPeriodOption)) {
            this.showErrorMessage(portalMessages.SELECT_PERIOD);
            return;
        }

        const filter = {
            requested_date: extractDate(this.state.selectedDate),
            company: this.state.selectedCompanyOption.value,
            period: this.state.selectedPeriodOption.value,
        };

        this.refreshNominationsAsync(filter);
    }

    headerToKey(header) {
        if (!!header?.props)
            return isArray(header.props.children) ?
                header.props.children.map(h => { return this.headerToKey(h) })?.join('_')
                : this.headerToKey(header.props.children);

        return header;
    }

    renderHeaders(data, t){
        const subHeaderRows = [];

        data = data.filter(d => d.type === t);
        let data_with_total = data.filter(d => d.direction === 'Export' || d.direction === 'Import' || d.direction === 'Net' || d.direction === 'Empty');
        let data_without_total = data.filter(d => d.direction !== 'Export' && d.direction !== 'Import' && d.direction !== 'Net' && d.direction !== 'Empty');
        let sortedData;
        
        if (data_with_total){
            data_with_total = sortBy(data_with_total, ['counter_party', 'direction', 'period']);
            sortedData = [...data_without_total, ...data_with_total]
        }
        else{
            sortedData = [...data_without_total]
        }
        
        let sub_title = [
            (sortedData) => { return `${sortedData.counter_party}`; },
            (sortedData) => { return `${sortedData.direction}`; }
        ]
        
        for (let rowIdx = 0; rowIdx < 2; rowIdx++) {
            
            subHeaderRows.push({ key: `sub_header_row_${rowIdx}`, children: [] });
            let columGroupKey = "PhysicalFlows"
                if (!data || !data.length)
                    continue;

                const headerClassCounter = (rowIdx + 1) % 2;
                const headerClass = this.dataGroupClasses[headerClassCounter];

                for (let dataIdx = 0; dataIdx < sortedData.length; dataIdx++) {
                    const subGroup = sortedData[dataIdx];
                    
                    const currentColumnIdx = subHeaderRows[rowIdx].children.length;
                    let parent;
                    
                    if (rowIdx === 0)
                        parent = columGroupKey;
                    else {
                        const parentDict = subHeaderRows[rowIdx - 1].children[currentColumnIdx];
                        parent = parentDict.parent + this.headerToKey(parentDict?.title);
                    }

                    if (sub_title.length - 1 < rowIdx) {
                        subHeaderRows[rowIdx].children.push({
                            colSpan: 0,
                            key: `${columGroupKey}_${rowIdx}_${currentColumnIdx}`,
                            parent,
                        });
                        continue;
                    }

                    let rowSpan = 1;

                    if (sub_title.length - 1 === rowIdx && rowIdx < 2 - 1)
                        rowSpan += 2 - rowIdx - 1;

                    const subTitle = sub_title[rowIdx](subGroup);
                    const subTitleStr = this.headerToKey(subTitle);

                    subHeaderRows[rowIdx].children.push({
                        colSpan: 1,
                        key: `${columGroupKey}_${rowIdx}_${currentColumnIdx}`,
                        group: columGroupKey,
                        rowSpan: rowSpan,
                        className: `${headerClass}`,
                        title: subTitle,
                        parent,
                        subTitleStr
                    });

                }
            
        }

        for (let i = 0; i < 2; i++) {
            let previousSuccessfulColumn = subHeaderRows[i].children[0];

            for (let j = 1; j < subHeaderRows[i].children.length; j++) {
                const current = subHeaderRows[i].children[j];

                if (!current || !current.colSpan)
                    continue;

                if (previousSuccessfulColumn
                    && previousSuccessfulColumn.group === current.group
                    && previousSuccessfulColumn.parent === current.parent
                    && previousSuccessfulColumn.subTitleStr === current.subTitleStr) {
                    previousSuccessfulColumn.colSpan++;
                    current.colSpan = 0;
                }
                else {
                    previousSuccessfulColumn = current;
                }
            }
        }

        let name = `${this.state.activeFilter.company} - ${t}`;
        return (
            <React.Fragment>
                <tr>
                    <th rowSpan={3} className ='v-flows-date-header' style={{padding: '2px 8px', borderBottom: 'solid 3px #979797', borderRight: 'solid 3px #979797'}}>
                        {name}<br></br>
                        {'DateTimeCET'}
                    </th>                    
                </tr>
                {subHeaderRows.map(row => {
                    return (
                        <tr key={row.key}>
                            {row.children.filter(c => c?.colSpan).map((cell, cellIdx) => {
                                
                                    if (cell.title === 'Empty'){
                                        
                                        return (
                                            <th style={{padding: '2px 8px', borderRight: '2px solid #141414'}}
                                                key={cell.key}
                                                parent={cell.parent ?? "EMPTY"}
                                                subtitlestr={cell.subTitleStr}
                                                rowSpan={cell.rowSpan}
                                                colSpan={cell.colSpan}
                                                className={cell.className}
                                                title={isObject(cell.title) ? cell.title.props.children[0] : cell.title}>
                                                {cell.title = cell.title === 'Empty' ? "" : cell.title}
                                            </th>
                                        );
                                    }
                                    else{
                                        return (
                                            <th style={{padding: '2px 8px', borderRight: '2px solid #141414'}}
                                                key={cell.key}
                                                parent={cell.parent ?? "EMPTY"}
                                                subtitlestr={cell.subTitleStr}
                                                rowSpan={cell.rowSpan}
                                                colSpan={cell.colSpan}
                                                className={cell.className}
                                                title={isObject(cell.title) ? cell.title.props.children[0] : cell.title}>
                                                {cell.title = cell.title === 'Empty' ? "" : cell.title}
                                            </th>
                                        );
                                    }
                                    
                                
                            })}
                        </tr>
                    )
                })}
            </React.Fragment>
        );
    }

    renderRows(data, t){
        let rowClassOrder = 0;
        let i = 0;
        let values = [];
        let date_values = [];
        let date_value;

        let reference_data = data.filter(d => d.type === t);
        let data_with_total = reference_data.filter(d => d.direction === 'Export' || d.direction === 'Import' || d.direction === 'Net' || d.direction === 'Empty');
        let data_without_total = reference_data.filter(d => d.direction !== 'Export' && d.direction !== 'Import' && d.direction !== 'Net' && d.direction !== 'Empty');
        let sortedData;

        if (data_with_total){
            data_with_total = sortBy(data_with_total, ['counter_party', 'direction', 'period']);
            reference_data = [...data_without_total, ...data_with_total]
        }
        else{
            reference_data = [...data_without_total]
        }
        reference_data[0].data.forEach(d => {
            values.push(d.hour);
        });
        reference_data[0].data.forEach(d => {
            date_values.push(d.date_time);
        });
        
        let font_weight;
        let empty_background_color = '#F8F9FA'
        return (
            values.map((v, idx) => {
                if (idx && values[idx] !== values[idx + 1])
                    rowClassOrder = (rowClassOrder + 1) % 2;
                    date_value = date_values[idx]
                    return (
                        <tr key={v} style={{borderBottom: 'solid 3px #979797'}}>
                            <td style={{borderRight: 'solid 3px #979797', padding: '2px 8px'}}>
                                {date_value}
                            </td>
                            {
                                reference_data.map((d, columnIdx) => {
                                    if (d.data[v].date_time === 'Total'){
                                        font_weight = 'bold'
                                    }
                                    else{
                                        font_weight = 'normal'
                                    }
                                    if (d.data[v].value !== ""){
                                        if (d.direction === 'Net' && d.data[v].value < 0){
                                            return (
                                                <td key={`${v}_${columnIdx}`} style={{textAlign: 'center', borderRight: 'solid 3px #979797', fontWeight: font_weight, backgroundColor:'#FFC7CE', color: '#9C0006'}}>
                                                    {d.data[v].value.toLocaleString()}
                                                </td>
                                            );
                                        }
                                        else if (d.direction === 'Net' && d.data[v].value > 0){
                                            return (
                                                <td key={`${v}_${columnIdx}`} style={{textAlign: 'center', borderRight: 'solid 3px #979797', fontWeight: font_weight, backgroundColor:'#C6EFCE', color: '#006100'}}>
                                                    {d.data[v].value.toLocaleString()}
                                                </td>
                                            );
                                        }
                                        else if (d.direction === 'Net' && d.data[v].value === 0){
                                            return (
                                                <td key={`${v}_${columnIdx}`} style={{textAlign: 'center', borderRight: 'solid 3px #979797', fontWeight: font_weight, backgroundColor:'#FFFFFF'}}>
                                                    {d.data[v].value.toLocaleString()}
                                                </td>
                                            );
                                        }
                                        return (
                                            <td key={`${v}_${columnIdx}`} style={{textAlign: 'center', borderRight: 'solid 3px #979797', fontWeight: font_weight}}>
                                                {d.data[v].value.toLocaleString()}
                                            </td>
                                        );    
                                    }
                                    else{
                                        return (
                                            <td style={{width : '3rem', textAlign: 'center', borderRight: 'solid 3px #979797', color: '#E7ECEF', backgroundColor: empty_background_color}}>

                                            </td>
                                        );  
                                    }
                                    
                                                     
                                })
                            }
                        </tr>
                    )
            })
        )
        
    }

    renderRegularNominationsTable(data) {
        if (!data || isEmpty(data))
            return null;

        let types = Object.keys(data.reduce((r, {type}) => (r[type]='', r), {}))
        types.sort();
        return (types.map(t => {
            if (t === 'Total'){
                return(
                
                    <div className = 'v-margin'>
                        <table className='table-striped' style={{border: 'solid 1px #dadada'}}>
                            <thead>
                                {this.renderHeaders(data, t)}
                            </thead>
                            <tbody>
                                {this.renderRows(data, t)}
                            </tbody>
                        </table>
                        <div style={{fontWeight: 'bold'}}> 
                            {
                            '*Only Vitus schedules included'}
                        </div>
                    </div>
                    
                )
            }
            else{
                return(
                
                    <div className = 'v-margin'>
                        <table className='table-striped' style={{border: 'solid 1px #dadada'}}>
                            <thead>
                                {this.renderHeaders(data, t)}
                            </thead>
                            <tbody>
                                {this.renderRows(data, t)}
                            </tbody>
                        </table>
                    </div>
                    
                )
            }
            
        }))
    }


    renderNominationsTab() {
        return (
            <VTab key='Nominations'
                eventKey='Nominations'
                title='Nominations'>
                {this.state.nominationList && !isEmpty(this.state.nominationList) &&
                    <div className='v-infinite-width v-table-list'>
                       {this.renderRegularNominationsTable(this.state.nominationList)}
                    </div>
                }
            </VTab>
        );
    }

    onGetHeaderClass(colId, colCount) {
        const colorRepeatColCount = 2;

        if ((colId - 1) % colCount > 0)
            return this.headerClasses[(Math.floor((((colId - 1) % colCount) - 1) / colorRepeatColCount)) % colorRepeatColCount];
        return "";
    }

    onCancelDeleting() {
        this.setState({ showResetNominationsModal: false, showResetIntradayModal: false });
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Power Schedules">
                    <VFilterContainer
                        showActiveFilter
                        activeFilter={this.state.activeFilterToDisplay}
                    >
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                                </div>
                            <div>
                                <VDatePicker
                                    selectedDate={this.state.selectedDate}
                                    onSelectedDateChange={(selectedDate) => this.setState({ selectedDate })}
                                    maxDate={this.getMaxEditableDate()}
                                />
                            </div>
                        </div>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Company
                                </div>
                            <div>
                                <VDropdown
                                    width="large"
                                    options={this.state.companyOptions}
                                    value={this.state.selectedCompanyOption}
                                    onSelectedOptionChange={(selectedCompanyOption) => {
                                        this.setState({
                                            selectedCompany: selectedCompanyOption.value,
                                            selectedCompanyOption: selectedCompanyOption
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Period
                                </div>
                            <div>
                                <VDropdown
                                    width="large"
                                    options={this.state.periodOptions}
                                    value={this.state.selectedPeriodOption}
                                    onSelectedOptionChange={(selectedPeriodOption) => {
                                        this.setState({
                                            selectedPeriod: selectedPeriodOption.value,
                                            selectedPeriodOption: selectedPeriodOption
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={() => this.onShowButtonClick()}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                        </div>
                    </VFilterContainer>
                    <VMainContainer>
                    <div className='col-12' style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                            <button className="btn v-button v-tab-button"
                                onClick={() => this.exportNominations()}>
                                <i aria-hidden="true"/>
                                    Export XLSX
                            </button>
                            <button className="btn v-button v-tab-button"
                                onClick={() => this.sendNominations()}>
                                <i aria-hidden="true"/>
                                    Send PDF to Slack
                            </button>
                        </div>
                        <VTabs>
                            {this.renderNominationsTab()}
                        </VTabs>
                    </VMainContainer>
                </VContentContainer>
            </React.Fragment>
        );
    }
}

export default PowerSchedules;