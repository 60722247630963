import React from 'react';
import { isEmpty } from 'lodash';

import history from '../../../history';
import { getCost, saveCost, getCountry, getCompanies, deleteCost } from '../../api/services';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import { messages } from '../../utils/messages';
import { handleApiError, alertError, alertSuccess, alertWarning } from '../../utils/errorHandler';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer'
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import CostList from '../Cost/CostList';
import CostDetailModal from '../../modals/Cost/CostDetailModal';
import TConfirmationModal from '../../components/TConfirmationModal/TConfirmationModal';

class CostMain extends React.Component {

    spinner = new SpinnerManager(history.location.pathname);
    countryList = [];
    brokerList = [];

    state = {
        data: [],
        selectedCost: '',
        showCostDetailModal: false,
        showCostDeleteConfirmationModal: false
    }

    componentDidMount() {
        this.callGetCostService();
    }


    callGetCostService() {

        this.spinner.showSpinner('callGetCostService');

        getCost()
            .then(response => {

                if (response.data.success) {
                    this.setState({ data: response.data.success });
                } else if (response.data.error.default.NotFound) {
                    alertWarning(messages.COST.NO_COST_FOUND);
                    this.setState({ data: [] });
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }

            }, error => {
                handleApiError(error);
            })
            .finally(() => {
                this.spinner.hideSpinner('callGetCostService');
            });
    }


    createSaveCostRequestBody(cost_id, cost_type, broker, country_id,
        cost, currency, cost_calculation, billing_period,
        cost_operation_type, cost_start_date, cost_end_date) {

        return {
            data: [
                {
                    cost_id,
                    cost_type,
                    broker,
                    country_id,
                    cost,
                    currency,
                    cost_calculation,
                    billing_period,
                    cost_operation_type,
                    cost_start_date,
                    cost_end_date
                }
            ]
        };
    }

    callSaveCostServiceAsync = async (requestBody) => {

        this.spinner.showSpinner('callSaveCostServiceAsync');

        try {

            let response = await saveCost(requestBody);

            if (response.data.success) {

                alertSuccess(messages.COST.SUCCESSFULL_COST_SAVE);

                this.hideCostDetailModal();

                this.callGetCostService();

            } else {
                alertError(messages.UNEXPECTED_ERROR_OCCURED);
            }

        } catch (error) {
            alertError(messages.NETWORK_ERROR);

        } finally {
            this.spinner.hideSpinner('callSaveCostServiceAsync');
        }

    }

    callGetCountryServiceAsync = async () => {

        this.spinner.showSpinner('callGetCountryServiceAsync');

        try {

            let response = await getCountry();

            if (response.data.success) {

                this.countryList = (response.data.success)
                    .map(i => {
                        return { value: i.country_id, label: `${i.country_code}-${i.country_name}` }
                    });

                return true;

            } else {
                alertError(messages.UNEXPECTED_ERROR_OCCURED);
                return false;
            }

        } catch (error) {
            alertError(messages.NETWORK_ERROR);
            return false;
        } finally {
            this.spinner.hideSpinner('callGetCountryServiceAsync');
        }

    }

    callGetCompanyServiceAsync = async () => {

        this.spinner.showSpinner('callGetCompanyServiceAsync');

        try {

            let response = await getCompanies();

            if (response.data.success) {

                this.brokerList = (response.data.success.company_result)
                    .filter(i => i.company_type === 'broker')
                    .map(j => {
                        return {
                            id: j.company_id,
                            label: j.company_name,
                            value: j.company_name
                        }
                    });

                return true;

            } else {
                alertError(messages.UNEXPECTED_ERROR_OCCURED);
                return false;
            }

        } catch (error) {
            alertError(messages.NETWORK_ERROR);
            return false;
        } finally {
            this.spinner.hideSpinner('callGetCompanyServiceAsync');
        }

    }

    onAddNewCostButtonClick = async () => {

        let result = await this.setBookDetailOptions();

        if (result)
            this.setState({ selectedCost: '', showCostDetailModal: true });

    }

    async setBookDetailOptions() {
        let serviceResults = true;

        if (isEmpty(this.countryList))
            serviceResults = await this.callGetCountryServiceAsync();

        if (isEmpty(this.brokerList) && !isEmpty(this.countryList))
            serviceResults = await this.callGetCompanyServiceAsync();

        return serviceResults;
    }

    onSaveCost = (costId, costType, broker, countryId,
        cost, currency, costCalculation,
        billingPeriod, costOpertionType, costStartDate, costEndDate) => {

        this.callSaveCostServiceAsync(
            this.createSaveCostRequestBody(costId, costType, broker, countryId,
                cost, currency, costCalculation, billingPeriod,
                costOpertionType, costStartDate, costEndDate)
        )
    }

    callDeleteCostService = async () => {

        this.spinner.showSpinner('callDeleteCostService');

        let requestBody = { cost_id: this.state.selectedCost.cost_id };

        try {

            let response = await deleteCost(requestBody);

            if (response.data.success) {

                alertSuccess(messages.COST.SUCCESSFULL_COST_DELETE);

                this.hideDeleteCostConfirmationModal();

                this.callGetCostService();

            } else {
                alertError(messages.UNEXPECTED_ERROR_OCCURED);
            }

        } catch (error) {
            alertError(messages.NETWORK_ERROR);

        } finally {
            this.spinner.hideSpinner('callDeleteCostService');
        }
    }

    onEditRowClick = async (row) => {

        let result = await this.setBookDetailOptions();

        if (result)
            this.setState({ selectedCost: row, showCostDetailModal: true });
    }

    hideCostDetailModal = () => this.setState({ showCostDetailModal: false, selectedCost: '' });

    hideDeleteCostConfirmationModal = () => this.setState({ showCostDeleteConfirmationModal: false, selectedCost: '' });

    onDeleteRowClick = (row) => this.setState({ selectedCost: row, showCostDeleteConfirmationModal: true });

    getLeftSide() {
        return (
            <LeftSideContainer
                contentDefaultExpanded
                contentLabel="Costs"
                contentComponent={
                    <div className="container">
                        <div className="row">
                            <div className="col-4">
                                <button
                                    type="button"
                                    className="btn t-orange-button"
                                    onClick={this.onAddNewCostButtonClick}>
                                    Add New Cost
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <CostList
                                    data={this.state.data}
                                    onEditRowClick={this.onEditRowClick}
                                    onDeleteRowClick={this.onDeleteRowClick}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                <TContentContainer>
                    <TContentDetailContainer
                        leftSideComponent={this.getLeftSide()}
                    >
                    </TContentDetailContainer>
                </TContentContainer>
                {
                    this.state.showCostDetailModal &&
                    <CostDetailModal
                        show={this.state.showCostDetailModal}
                        onHide={this.hideCostDetailModal}
                        onCancel={this.hideCostDetailModal}
                        onSaveCost={this.onSaveCost}
                        selectedCost={this.state.selectedCost}
                        brokerList={this.brokerList}
                        countryList={this.countryList}
                    />
                }
                {
                    this.state.showCostDeleteConfirmationModal &&
                    <TConfirmationModal
                        show={this.state.showCostDeleteConfirmationModal}
                        message={messages.COST.CONFIRM_COST_DELETE.replace("[COST_NAME]", this.state.selectedCost.cost_type)}
                        cancelText='Cancel'
                        onHide={this.hideDeleteCostConfirmationModal}
                        onCancel={this.hideDeleteCostConfirmationModal}
                        confirmText='Delete'
                        onConfirm={this.callDeleteCostService}
                    />

                }
            </React.Fragment>
        );
    }

}

export default CostMain;