import './VAccordion.css';

import React, { useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { isArray } from 'lodash';

const renderContent = (children, selectedItemKey) => {

    return children.map(child => {

        let eKey = child.props.eventkey;

        return (
            <div key={child.props.title}>
                <Accordion.Toggle as={Button} variant="link" eventKey={eKey}>
                    <i className={`fa fa-angle-${eKey === selectedItemKey ? 'up' : 'down'} v-accordion-icon`} aria-hidden="true" > {child.props.title}</i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={eKey}>
                    <div className="v-accordion-collapse">{child}</div>
                </Accordion.Collapse>
            </div>
        );
    });
}

const VAccordion = ({ children }) => {

    const [selectedItemKey, setSelectedItemKey] = useState();

    return (
        <div className="v-accordion">
            <Accordion onSelect={(selectedItemKey) => setSelectedItemKey(selectedItemKey)}>
                {
                    isArray(children) ?
                        renderContent(children, selectedItemKey)
                        :
                        <div key={children.props.title}>
                            <Accordion.Toggle as={Button} variant="link" eventKey={children.props.eventKey}>
                                <i className={`fa fa-angle-${children.props.eventKey === selectedItemKey ? 'up' : 'down'} v-accordion-icon`} aria-hidden="true" > {children.props.title}</i>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={children.props.eventKey}>
                                <div className="v-accordion-collapse">{children}</div>
                            </Accordion.Collapse>
                        </div>
                }
            </Accordion>
        </div>
    );

};

export const VAccordionItem = ({ children, ...rest }) => {
    return (
        <div {...rest}>
            {children}
        </div>
    );
};

export default VAccordion;