import axios from 'axios';

const vitusETRMapi = axios.create({
    baseURL: process.env.REACT_APP_VITUS_ETRM_API,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true, //TODO: research again after CORS
    validateStatus: (status) => { return status === 200; }
});

export default vitusETRMapi;