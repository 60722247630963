import React from 'react';
import TDropdown from '../../components/TDropdown/TDropdown';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import TDatePicker from '../../components/TDatePicker/TDatePicker';
import { TDatePickerTypes } from '../../components/TDatePicker/TDatePicker';
import { PRICE_SOURCES, PRICE_SOURCE_ARR } from '../../utils/constants';
import moment from 'moment';
import { getLocalStorage, setLocalStorage } from '../../../helpers/localStorageHelper';


const localStorageConfig = {
    market: {
        ownerPage: "distribution",
        ownerElement: "filters",
        key: "selectedMarket",
        matchKey: "id"
    },
    priceSource: {
        ownerPage: "distribution",
        ownerElement: "filters",
        key: "selectedPriceSource",
    }
}

class DistributionFilter extends React.Component {
    state = {
        selectedMarket: this.getOrElseLocalStorage(localStorageConfig.market, this.props.marketNames[0]),
        selectedPriceSource: this.getOrElseLocalStorage(localStorageConfig.priceSource, PRICE_SOURCES.vitus),
        selectedDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        marketNames: this.props.marketNames
    };

    getOrElseLocalStorage(config, defaultValue) {
        let localStorageValue = getLocalStorage(config.ownerPage, config.ownerElement, config.key)
        if (localStorageValue) {
            return localStorageValue
        }

        return defaultValue
    }

    getOrElseLocalStorageAsList(config, currentData) {
        let localStorageList = getLocalStorage(config.ownerPage, config.ownerElement, config.key)

        if (!localStorageList || !localStorageList.length) {
            return currentData
        } else {
            let existingList = currentData.filter(x => localStorageList.includes(x[config.matchKey]))

            if (existingList.length) {
                return existingList
            } else {
                return currentData
            }
        }
    }

    setPnlLocalStorageAsList(config, data) {
        let dataList = data.map(x => x[config.matchKey])
        setLocalStorage(config.ownerPage, config.ownerElement, config.key, dataList)
    }

    setPnlLocalStorage(config, data) {
        setLocalStorage(config.ownerPage, config.ownerElement, config.key, data)
    }

    componentDidMount() {
        this.onShowButtonClick();
    }

    onShowButtonClick() {
        let filter = {
            market_id: this.state.selectedMarket.id,
            price_source: this.state.selectedPriceSource.value,
            requested_date: moment(this.state.selectedDate).utcOffset(0, true).format(),
        }

        this.props.onShowButtonClick(filter)
    }

    onMarketChange = market => {
        this.setState({ selectedMarket: market }, () => {
            this.setPnlLocalStorage(localStorageConfig.market, market);
        });
    }

    onChangePriceSource = source => {
        this.setState({ selectedPriceSource: source }, () => {
            this.setPnlLocalStorage(localStorageConfig.priceSource, source);
        })
    }

    onChangeDate = date => {
        this.setState({ selectedDate: date })
    }

    render() {
        return (
            <div style={{ "marginTop": "10px" }}>
                <div style={{ "marginTop": "10px", display: "flex", flexDirection: "row" }}>
                    <div style={{ "margin": "5px" }}>
                        <TDropdown
                            label='Market'
                            options={this.props.marketNames}
                            width="x-large"
                            value={this.state.selectedMarket}
                            defaultValue={this.state.selectedMarket}
                            onSelectedOptionChange={this.onMarketChange}
                            disabled={this.state.readOnly}
                        />
                    </div>

                    <div style={{ "margin": "5px", "width": "30%" }}>
                        <TDropdown
                            label='Price Source'
                            options={PRICE_SOURCE_ARR}
                            width="small"
                            value={this.state.selectedPriceSource}
                            defaultValue={this.state.selectedPriceSource}
                            onSelectedOptionChange={this.onChangePriceSource}
                            disabled={this.state.readOnly}
                        />
                    </div>
                    <div style={{ "margin": "5px", "width": "30%" }}>
                    <TDatePicker
                            type={TDatePickerTypes.month}
                            label={"Month"}
                            selectedDate={this.state.selectedDate}
                            onSelectedDateChange={this.onChangeDate}
                        />
                    </div>

                </div>



                <div style={{ "margin": "12px" }}>
                    <Divider />
                    <AccordionActions>
                        <button
                            size="small"
                            className="btn t-orange-button"
                            onClick={() => this.onShowButtonClick()}
                        >
                            Apply
                        </button>
                    </AccordionActions>
                </div>

            </div>
        );
    }
}

export default DistributionFilter;