const generateKey = (ownerPage, ownerElement, key) => {
    return `${ownerPage}_${ownerElement}_${key}`;
}

export const setLocalStorage = (ownerPage, ownerElement, key, value) => {
    const storage_key = generateKey(ownerPage, ownerElement, key);
    localStorage.setItem(storage_key, JSON.stringify(value));
    return key;
}

export const getLocalStorage = (ownerPage, ownerElement, key) => {
    const storage_key = generateKey(ownerPage, ownerElement, key);
    const value = localStorage.getItem(storage_key);
    return JSON.parse(value);
}