import history from '../history';

import {
    SHOW_SPINNER,
    HIDE_SPINNER,
    CLEAR_SPINNERS
} from '../actions/types';

const INITIAL_STATE = {
    spinnerKeys: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_SPINNER:
            return {
                spinnerKeys: history.location.pathname === action.payload.path ? [...state.spinnerKeys, action.payload] : []
            };
        case HIDE_SPINNER:
            return {
                spinnerKeys: state.spinnerKeys.filter(i => i.id !== action.payload.id)
            };
        case CLEAR_SPINNERS:
            return {
                spinnerKeys: []
            };
        default:
            return state;
    }
};