import './PnLReport.css';

import React from 'react';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import { VDatePickerTypes } from '../../components/VDatePicker/VDatePicker';
import VDropdown from '../../components/VDropdown/VDropdown';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import { AddStyles } from '../../components/VTagInput/cusomized-react-tag-input/ReactTags';
import VTagInput from '../../components/VTagInput/VTagInput';
import _ from 'lodash';
import VCheckboxGroup from '../../components/VCheckboxGroup/VCheckboxGroup';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import PnlSummary from './PnlSummary/PnlSummary';
import PnlDetails from './PnlDetails/PnlDetails';
import { getPnlBorders, getPnl, downloadPnlFile } from '../../apis/vitusApi';
import { alertError, handleApiError, specifyErrorMessage } from '../../helpers/errorHelper';
import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import { portalMessages } from '../../helpers/portalMessages';
import { extractDate, generateUuid, getCompanyCheckboxOptions } from '../../helpers/generalHelper';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorageHelper';
import VPageNavigation from '../../components/VPageNavigation/VPageNavigation';
import FileSaver from 'file-saver';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import PnlFtr from './PnlFtr/PnlFtr';

export const dateTypes = {
    Hourly: 'Hourly',
    Daily: 'Daily',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    Yearly: 'Yearly',
}


class PnLReport extends React.Component {
    spinner = new SpinnerManager(history.location.pathname);
    storedActiveFilter = getLocalStorage(this.props.pageName || 'pnlReport', 'filters', 'activeFilter') || {};
    pageRecordCountOptions = [12, 24, 48, 120, 240];
    minStartDate = new Date(2021, 0, 1); // Hard min date because system is started to be used on 2021

    localDateTypes = dateTypes;

    hourToMillisecond = 60 * 60 * 1000;

    groupingOptions = Object.keys(this.localDateTypes).map(k => { return { value: this.localDateTypes[k], label: this.localDateTypes[k] } });

    currencyOptions = [
        { value: "TRY", label: "TRY" },
        { value: "EUR", label: "EUR" },
        { value: "USD", label: "USD" }
    ]

    state = {
        selectedGrouping: this.props.grouping ? null : this.groupingOptions[2],
        selectedStartDate: this.getDefaultStartDate(),
        selectedEndDate: this.getDefaultEndDate(),
        selectedCurrency: this.props.grouping ? null : this.currencyOptions[0],
        selectedDirections: [],
        selectedCounterParties: [],
        availableCounterParties: [],
        availableDirections: [],
        periodOptions: {
            st: { title: "Short Term", checked: true },
            lt: { title: "Long Term", checked: true }
        },
        companyOptions: getCompanyCheckboxOptions(),
        pnlDetails: {},
        activeFilter: this.storedActiveFilter,
        currentDetailsPage: 1,
        detailRecordsPerPageCount: 120
    }

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Default)',
    }

    showErrorMessage(error, operationName) {
        const { message } = specifyErrorMessage(error, this.errorMessages, operationName);

        if (message)
            alertError(message);
    }

    componentDidMount() {
        const spinnerKey = this.spinner.showSpinner();

        if (this.props.setInitialState)
            this.props.setInitialState(this.state);

        getPnlBorders().then(response => {
            if (response.data.success) {

                const counterParties = [];
                const directions = [];

                response.data.success.result_list.forEach(b => {
                    counterParties.push(b.counterParty);
                    directions.push(b.direction);
                });

                let availableDirections = [...new Set(directions)].sort().map(k => { return { id: k, text: k } });
                let availableCounterParties = [...new Set(counterParties)].sort().map(k => { return { id: k, text: k } });

                let selectedGrouping = null;
                if (this.state.activeFilter.grouping)
                    selectedGrouping = this.groupingOptions.find(g => g.value === this.state.activeFilter.grouping);
                else
                    selectedGrouping = this.props.grouping ? this.props.grouping : this.groupingOptions[2];

                let selectedCurrency = null;
                if (this.state.activeFilter.currency)
                    selectedCurrency = this.currencyOptions.find(c => c.value === this.state.activeFilter.currency);
                else
                    selectedCurrency = this.currencyOptions[0];

                let selectedDirections = [];
                if (this.state.activeFilter.direction_list && this.state.activeFilter.direction_list.length > 0) {
                    for (const direction of this.state.activeFilter.direction_list) {
                        if (availableDirections.find(d => d.id === direction)) {
                            selectedDirections.push(direction);
                        }
                    }

                    selectedDirections = selectedDirections.sort().map(k => { return { id: k, text: k } });
                    availableDirections = availableDirections.filter(d => !selectedDirections.find(s => s.id === d.id));
                }

                let selectedCounterParties = [];
                if (this.state.activeFilter.counter_party_list && this.state.activeFilter.counter_party_list.length > 0) {
                    for (const counterParty of this.state.activeFilter.counter_party_list) {
                        if (availableCounterParties.find(d => d.id === counterParty)) {
                            selectedCounterParties.push(counterParty);
                        }
                    }

                    selectedCounterParties = selectedCounterParties.sort().map(k => { return { id: k, text: k } });
                    availableCounterParties = availableCounterParties.filter(d => !selectedCounterParties.find(s => s.id === d.id));
                }

                let periodOptions = this.state.periodOptions;

                if (this.state.activeFilter.period_list && this.state.activeFilter.period_list.length > 0) {
                    Object.keys(periodOptions).forEach(k => {
                        periodOptions[k].checked = false;
                    });

                    this.state.activeFilter.period_list.forEach(k => {
                        periodOptions[k].checked = true;
                    });
                }

                let companyOptions = this.state.companyOptions;

                if (this.state.activeFilter.checked && this.state.activeFilter.checked.length > 0) {
                    Object.keys(companyOptions).forEach(k => {
                        companyOptions[k].checked = false;
                    });

                    this.state.activeFilter.company_list.forEach(k => {
                        companyOptions[k].checked = true;
                    });
                }

                this.setState({
                    availableCounterParties,
                    availableDirections,
                    selectedGrouping,
                    selectedCurrency,
                    selectedDirections,
                    selectedCounterParties,
                    periodOptions,
                    companyOptions
                }, () => {
                    this.onShowButtonClick();
                });
            } else {
                this.setState({ showNeedsRefresModal: true });
            }

            if (response.data.error)
                this.showErrorMessage(response.data.error);
        }, error => {
            handleApiError(error);
            this.setState({ showNeedsRefresModal: true });
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    showButtonEnabled() {
        // There are no scenarios to disable show button for now
        // function written to keep project convention
        return true;
    }

    onClearButtonClick() {
        const periodOptions = this.state.periodOptions;

        Object.keys(periodOptions).forEach(k => {
            periodOptions[k].checked = true;
        });

        this.setState({
            selectedStartDate: this.getDefaultStartDate(),
            selectedEndDate: this.getDefaultEndDate(),
            periodOptions,
            availableDirections: [
                ...this.state.availableDirections,
                ...this.state.selectedDirections
            ],
            selectedDirections: [],
            availableCounterParties: [
                ...this.state.availableCounterParties,
                ...this.state.selectedCounterParties
            ],
            selectedCounterParties: []
        });
    }

    createNewFilterObject() {
        let { start_date, end_date } = this.getConvertedDatesForGroup(this.state.selectedStartDate, this.state.selectedEndDate);

        return {
            start_date: extractDate(start_date),
            end_date: extractDate(end_date),
            direction_list: this.state.selectedDirections.map(s => s.id),
            counter_party_list: this.state.selectedCounterParties.map(s => s.id),
            currency: this.state.selectedCurrency.value,
            period_list: Object.keys(this.state.periodOptions).filter(p => this.state.periodOptions[p].checked),
            grouping: this.props.grouping || this.state.selectedGrouping.value,
            company_list: Object.keys(this.state.companyOptions).filter(p => this.state.companyOptions[p].checked),
        };
    }

    onShowButtonClick(callBack) {
        if (!this.state.selectedStartDate) {
            this.showErrorMessage(portalMessages.START_DATE_SELECTION);
            return false;
        }

        if (!this.state.selectedEndDate) {
            this.showErrorMessage(portalMessages.END_DATE_SELECTION);
            return false;
        }

        const filter = this.createNewFilterObject();

        this.refreshPnlAsync(filter, null, 1).then(() => {
            if (callBack)
                callBack(this.state.pnlSummary, filter);
        });

        return true;
    }

    onDetailsPageChanged(recordCount, pageNum) {
        this.refreshPnlAsync(null, recordCount, pageNum);
    }

    async refreshPnlAsync(filter, rowCount, pageNum) {
        const spinnerKey = this.spinner.showSpinner();

        try {
            if (!filter)
                filter = this.state.activeFilter;

            if (!rowCount && rowCount !== 0)
                rowCount = this.state.detailRecordsPerPageCount;

            filter.row_count = rowCount;

            if (!pageNum)
                pageNum = this.state.currentDetailsPage;

            filter.start_row = filter.row_count * (pageNum - 1);

            let response;

            try {
                response = await getPnl(filter);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                const activeFilterToDisplay = [
                    { label: `Start Date`, value: filter.start_date },
                    { label: `End Date`, value: filter.end_date },
                    { label: "Currency", value: filter.currency },
                    { label: "Companies", value: filter.company_list.map(p => this.state.companyOptions[p].title) },
                    { label: "Periods", value: filter.period_list.map(p => p.toUpperCase()) },
                    { label: "Counter Parties", value: filter.counter_party_list.length > 0 ? filter.counter_party_list : 'All' },
                    { label: "Directions", value: filter.direction_list.length > 0 ? filter.direction_list : 'All' },
                    { label: "Timezone", value: "CET" }
                ];

                if (!this.props.hideGrouping)
                    activeFilterToDisplay.unshift({ label: "Grouping", value: filter.grouping });

                this.setState({
                    pnlDetails: response.data.success.pnl_details,
                    pnlSummary: response.data.success.pnl_summary,
                    pnlFtr: response.data.success.pnl_details.pnl_ftr,
                    pnlAutoSettle: response.data.success.pnl_details.pnl_auto_settle,
                    activeFilter: filter,
                    currentDetailsPage: pageNum,
                    detailRecordsPerPageCount: rowCount,
                    activeFilterToDisplay
                }, () => {
                    const {
                        start_date,
                        end_date,
                        ...rest
                    } = filter;

                    setLocalStorage(this.props.pageName || 'pnlReport', 'filters', 'activeFilter', { ...rest })
                });
            }
            else if (!response.data.error) {
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Get Pnl 1)');
            }

            if (response.data.error) {
                this.showErrorMessage(response.data.error);
            }
        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Get Pnl 2)');
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    getConvertedDatesForGroup(defaultStart, defaultEnd) {
        if (!defaultStart)
            defaultStart = this.props.grouping? this.getDate(-7) : new Date((new Date()).getFullYear(), 0, 1)
        if (!defaultEnd)
            defaultEnd = this.getDate(1);

        let start_date, end_date;

        switch (this.state?.selectedGrouping?.value) {
            case this.localDateTypes.Monthly:
                start_date = new Date(defaultStart.getFullYear(), defaultStart.getMonth(), 1)
                end_date = this.getDate(-1, new Date(defaultEnd.getFullYear(), defaultEnd.getMonth() + 1, 1));
                break;
            case this.localDateTypes.Quarterly:
                start_date = new Date(defaultStart.getFullYear(), (parseInt(defaultStart.getMonth() / 3) * 3), 1)
                end_date = this.getDate(-1, new Date(defaultEnd.getFullYear(), (parseInt(defaultEnd.getMonth() / 3) * 3) + 3, 1));
                break;
            case this.localDateTypes.Yearly:
                start_date = new Date(defaultStart.getFullYear(), 0, 1)
                end_date = this.getDate(-1, new Date(defaultEnd.getFullYear() + 1, 0, 1));
                break;
            default:
                start_date = defaultStart;
                end_date = defaultEnd;
                break;
        }

        if (end_date > this.getDate(1)) {
            end_date = this.getDate(1);
        }

        return { start_date, end_date }
    }

    getDefaultStartDate() {
        return this.getConvertedDatesForGroup().start_date;
    }

    getDefaultEndDate() {
        return this.getConvertedDatesForGroup().end_date;
    }

    getDate(dayDifference, date) {
        if (!date)
            date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + dayDifference);
        return date;
    }

    getDatePicker(currentDate, minDate, maxDate, onChange) {
        if (!this.state.selectedGrouping)
            return null;

        let datePickerType;

        switch (this.state.selectedGrouping.value) {
            case this.localDateTypes.Monthly:
                datePickerType = VDatePickerTypes.month;
                break;
            case this.localDateTypes.Quarterly:
                datePickerType = VDatePickerTypes.quarter;
                break;
            case this.localDateTypes.Yearly:
                datePickerType = VDatePickerTypes.year;
                break;
            default:
                datePickerType = VDatePickerTypes.day;
                break;
        }

        return (
            <VDatePicker
                selectedDate={currentDate}
                onSelectedDateChange={onChange}
                minDate={minDate}
                maxDate={maxDate}
                type={datePickerType}
            />
        );
    }

    onSelectedDirectionsChanged = (currentSelectedDirections) => {
        this.setState({ selectedDirections: [...currentSelectedDirections] });
    }

    addSelectedDirectionTag = (newSelectedDirections) => {
        this.setState({
            selectedDirections: [...this.state.selectedDirections, newSelectedDirections],
            availableDirections: this.state.availableDirections.filter(i => i['id'] !== newSelectedDirections['id'])
        });
    }

    onSelectedDirectionRemoved = (currentSelectedDirections) => {
        let removedTag = _.difference(this.state.selectedDirections, currentSelectedDirections)[0];

        this.setState({
            selectedDirections: [...currentSelectedDirections],
            availableDirections: _.orderBy([...this.state.availableDirections, removedTag], ['text'], ['asc'])
        });
    }

    onSelectedCounterPartiesChanged = (currentSelectedCounterParties) => {
        this.setState({
            selectedCounterParties: [...currentSelectedCounterParties]
        });
    }

    addSelectedCounterPartyTag = (newSelectedCounterParties) => {
        const selectedCounterParties = [...this.state.selectedCounterParties, newSelectedCounterParties];

        this.setState({
            selectedCounterParties,
            availableCounterParties: this.state.availableCounterParties.filter(i => i['id'] !== newSelectedCounterParties['id'])
        });

    }

    onSelectedCounterPartyRemoved = (currentSelectedCounterParties) => {
        let removedTag = _.difference(this.state.selectedCounterParties, currentSelectedCounterParties)[0];

        this.setState({
            selectedCounterParties: [...currentSelectedCounterParties],
            availableCounterParties: _.orderBy([...this.state.availableCounterParties, removedTag], ['text'], ['asc'])
        });
    }

    getFilter() {
        return (
            <VFilterContainer
                showActiveFilter
                activeFilter={this.state.activeFilterToDisplay}
            >
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                {!this.props.hideGrouping &&
                                    <div className='col-6'>
                                        <div className="v-filter-group">
                                            <div className="v-filter-label v-label">
                                                Grouping
                                        </div>
                                            <div>
                                                <VDropdown
                                                    width='large'
                                                    options={this.groupingOptions}
                                                    value={this.state.selectedGrouping}
                                                    onSelectedOptionChange={(selectedGrouping) => {
                                                        this.setState({ selectedGrouping })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='col-6'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Company
                                        </div>
                                        <div>
                                            <VCheckboxGroup
                                                items={this.state.companyOptions}
                                                onCheckChanged={(k, updatedItems) => this.setState({ companyOptions: { ...updatedItems } })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Start Date
                                        </div>
                                        <div>
                                            {this.getDatePicker(
                                                this.state.selectedStartDate,
                                                this.minStartDate,
                                                this.state.selectedEndDate ? this.getDate(0, new Date(this.state.selectedEndDate)) : this.getDate(1),
                                                (selectedStartDate) => this.setState({ selectedStartDate })
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            End Date
                                        </div>
                                        <div>
                                            {this.getDatePicker(
                                                this.state.selectedEndDate,
                                                this.state.selectedStartDate ? this.getDate(0, new Date(this.state.selectedStartDate)) : null,
                                                this.getDate(1),
                                                (selectedEndDate) => this.setState({ selectedEndDate }))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Report Currency
                                        </div>
                                        <div>
                                            <VDropdown
                                                width='large'
                                                options={this.currencyOptions}
                                                value={this.state.selectedCurrency}
                                                onSelectedOptionChange={(selectedCurrency) => {
                                                    this.setState({ selectedCurrency })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Periods
                                        </div>
                                        <div>
                                            <VCheckboxGroup
                                                items={this.state.periodOptions}
                                                onCheckChanged={(k, updatedItems) => this.setState({ periodOptions: { ...updatedItems } })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Counter Parties
                                        </div>
                                        <div className="v-filter-taginput">
                                            <VTagInput
                                                dragDisabled
                                                addStyle={AddStyles.Textbox}
                                                placeholder='Add Counter Party'
                                                tags={this.state.selectedCounterParties}
                                                onOrderChanged={this.onSelectedCounterPartiesChanged}
                                                onTagDeleted={this.onSelectedCounterPartyRemoved}
                                                onInternalAddition={this.addSelectedCounterPartyTag}
                                                suggestions={this.state.availableCounterParties}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Directions
                                        </div>
                                        <div className="v-filter-taginput">
                                            <VTagInput
                                                dragDisabled
                                                addStyle={AddStyles.Textbox}
                                                placeholder='Add Direction'
                                                tags={this.state.selectedDirections}
                                                onOrderChanged={this.onSelectedDirectionsChanged}
                                                onTagDeleted={this.onSelectedDirectionRemoved}
                                                onInternalAddition={this.addSelectedDirectionTag}
                                                suggestions={this.state.availableDirections}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="v-filter-buttons">
                    <button
                        disabled={!this.showButtonEnabled()}
                        className="btn v-cancel-button v-filter-button"
                        onClick={() => this.onClearButtonClick()}>
                        <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                    </button>
                    <button
                        disabled={!this.showButtonEnabled()}
                        tabIndex={0}
                        className="btn v-button v-filter-button"
                        onClick={() => this.onShowButtonClick()}>
                        <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                    </button>
                    <button
                        disabled={!this.downloadPnlEnabled()}
                        className="btn v-button v-filter-button"
                        onClick={() => this.showAndDownloadPnl()}>
                        <i aria-hidden="true" className="fa fa-cloud-download fa-fw" />
                        Download
                    </button>
                </div>
            </VFilterContainer>
        );
    }

    getQuarter(date) {
        return Math.floor((date.getMonth() + 3) / 3);
    }

    getTotalRowCount() {
        if (!this.state.activeFilter.start_date || !this.state.activeFilter.end_date || !this.state.selectedGrouping)
            return 0;

        const selectedGrouping = this.state.activeFilter.grouping;
        const selectedEndDate = new Date(this.state.activeFilter.end_date);
        const selectedStartDate = new Date(this.state.activeFilter.start_date);

        switch (selectedGrouping) {
            case this.localDateTypes.Hourly:
                return Math.ceil((selectedEndDate - selectedStartDate) / this.hourToMillisecond);
            case this.localDateTypes.Daily:
                return Math.ceil((selectedEndDate - selectedStartDate) / (24 * this.hourToMillisecond));
            case this.localDateTypes.Monthly:
                return selectedEndDate.getMonth() - selectedStartDate.getMonth() + (12 * (selectedEndDate.getFullYear() - selectedStartDate.getFullYear()));
            case this.localDateTypes.Quarterly:
                return this.getQuarter(selectedEndDate) - this.getQuarter(selectedStartDate) + (4 * (selectedEndDate.getFullYear() - selectedStartDate.getFullYear()));
            case this.localDateTypes.Yearly:
                return selectedEndDate.getFullYear() - selectedStartDate.getFullYear();
            default:
                throw Error("Unknown date type");
        }
    }

    downloadPnlEnabled() {
        // There are no scenarios to disable show button for now
        // function written to keep project convention
        return true;
    }

    showAndDownloadPnl() {
        if (this.onShowButtonClick(this.props.download)) {
            if (!this.props.download) {
                const filter = this.createNewFilterObject();
                this.downloadPnl(filter);
            }
        }
    }

    downloadPnl(filter) {
        const spinnerKey = this.spinner.showSpinner();

        if (!filter)
            filter = this.state.activeFilter;

        downloadPnlFile({
            uniqe_key: generateUuid(),
            ...filter
        })
            .then(async response => {
                if (!(response.data instanceof ArrayBuffer)) {
                    this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Download File 1)');
                    return;
                }

                const filename = `Pnl_Report_${filter.grouping}_${filter.currency}_${filter.company_list.map(p => p.toUpperCase()).join('-')}_${filter
                    .period_list.map(p => p.toUpperCase()).join('-')}_${filter.start_date}_${filter.end_date}.xlsx`;
                const contentType = response.headers["content-type"];

                const blob = new Blob([response.data], { type: contentType });

                if (contentType === 'application/json') {
                    const error = await blob.text();
                    this.showErrorMessage(JSON.parse(error));
                    return;
                }

                FileSaver.saveAs(blob, filename);
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner(spinnerKey);
            });
    }

    getPaging(pagerId) {
        const currentPage = this.state.currentDetailsPage;
        const recordsPerPageCount = this.props.recordsPerPageCount;
        const totalCount = this.getTotalRowCount();

        return <VPageNavigation
            key={`${currentPage}${recordsPerPageCount}${totalCount}${pagerId}`}
            onPageChanged={(recordCount, pageNum) => this.onDetailsPageChanged(recordCount, pageNum)}
            currentPage={currentPage}
            recordsPerPageCount={this.state.detailRecordsPerPageCount}
            recordCountOptions={this.pageRecordCountOptions}
            totalCount={totalCount} />;
    }

    scrollToTop() {
        //https://www.w3schools.com/howto/howto_js_scroll_to_top.asp

        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }


    scrollToBottom() {
        document.body.scrollTop = document.body.scrollHeight;
        document.documentElement.scrollTop = document.body.scrollHeight;
    }

    getInfo() {
        return (
            <div className='col-12 v-font v-font-size'>
                <ul>
                    <li>
                        {portalMessages.PNL.ALL_CP_LISTED}
                    </li>
                    <li>
                        {portalMessages.PNL.ALL_DIR_LISTED}
                    </li>
                    <li>
                        {portalMessages.PNL.LIGHT_COLUMNS}
                    </li>
                    <li>
                        {portalMessages.PNL.SUM_OF_BORDERS_HIDDEN}
                    </li>
                </ul>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer
                    title={this.props.title || "Pnl Report"}
                    info={!this.props.hideInfo && this.getInfo()}>
                    {this.getFilter()}
                    <VMainContainer>
                        <div className='v-fixed-button-up'>
                            <button className="btn v-button"
                                title='Scroll to top'
                                onClick={() => this.scrollToTop()}>
                                <i aria-hidden="true" className="fa fa-arrow-up fa-fw" />
                                Top
                            </button>
                        </div>
                        <div className='v-fixed-button-down'>
                            <button className="btn v-button"
                                title='Scroll to bottom'
                                onClick={() => this.scrollToBottom()}>
                                <i aria-hidden="true" className="fa fa-arrow-down fa-fw" />
                                Bottom
                            </button>
                        </div>
                        {
                            this.props.customOutput !== undefined ?
                                this.props.customOutput(this.state.pnlSummary)
                                :
                                <React.Fragment>
                                    <VTabs>
                                        <VTab key='PnlSummary'
                                            eventKey='PnlSummary'
                                            title='Pnl Summary'>
                                            <PnlSummary
                                                data={this.state.pnlSummary}
                                                grouping={this.state.activeFilter.grouping}
                                            />
                                        </VTab>
                                        <VTab key='PnlDetails'
                                            eventKey='PnlDetails'
                                            title='Pnl Details'>
                                            <PnlDetails
                                                data={this.state.pnlDetails}
                                                directions={this.state.activeFilter.direction_list}
                                            />
                                        </VTab>
                                        <VTab key='PnlFtr'
                                            eventKey='PnlFtr'
                                            title='FTR'>
                                            <PnlFtr
                                                ftrData={this.state.pnlFtr}
                                                autoSettleData={this.state.pnlAutoSettle}
                                            />
                                        </VTab>
                                    </VTabs>
                                    {this.getPaging(1)}
                                </React.Fragment>
                        }
                    </VMainContainer>
                </VContentContainer>
                <NeedRefreshModal
                    show={this.state.showNeedsRefresModal}
                    message={portalMessages.BORDERS_AND_COUNTER_PARTIES_NOT_FOUND}
                />
            </React.Fragment >
        );
    }
}

export default PnLReport;