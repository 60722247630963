import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { endpointNames } from "../apis/vitusApi";

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
    return (
        <Route {...rest} render={(props) => (
            isLoggedIn === true
                ? <Component {...props} />
                : <Redirect to={{
                    pathname: endpointNames.login,
                    state: { from: props.location }
                }} />
        )} />
    )
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn
    };
};

export default connect(mapStateToProps)(PrivateRoute);