import React from 'react';
import VDropdown from '../../components/VDropdown/VDropdown';
import VBaseModal from '../VBaseModal/VBaseModal';

const AddAuctionBidModal = ({ show, onCancel, onAdd, availableDirections, counterParty }) => {
    const options = availableDirections.map(dir => {
        return { value: dir, label: dir };
    });

    let initialOption;

    if (!options || options.length === 0)
        initialOption = { value: null, label: null };
    else
        initialOption = options[0];

    let { value, label } = initialOption;

    const [selectedDirection, setSelectedDirection] = React.useState({ value, label });

    React.useEffect(() => {
        setSelectedDirection({ value, label });
    }, [value, label, show]);

    const onDirectionChange = (newDir) => {
        setSelectedDirection(newDir);
    };

    if (!initialOption || !initialOption.value)
        return null;

    return (
        <VBaseModal
            dialogClassName="v-modal-30w"
            show={show}
            onHide={onCancel}
            title='Add Auction Bid'
            closeButton={false}
            body={
                <div className="container">
                    <div className='row'>
                        <div className='col'>
                            <label className="v-font v-font-size">Counter Party</label>
                        </div>
                        <div className='col-8'>
                            <label className="v-label">{counterParty}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="v-font v-font-size">Direction</label>
                        </div>
                        <div className='col-8'>
                            <VDropdown
                                width="large"
                                options={options}
                                value={selectedDirection}
                                isSearchable={true}
                                onSelectedOptionChange={onDirectionChange}
                            />
                        </div>
                    </div>
                </div>
            }
            actions={
                <React.Fragment>
                    <button
                        className="btn v-cancel-button"
                        onClick={onCancel}>
                        Cancel
                    </button>
                    <button
                        className="btn v-button"
                        onClick={() => onAdd(counterParty, selectedDirection.value)}>
                        Add
                    </button>
                </React.Fragment >
            }
        />
    );
};

export default AddAuctionBidModal