import './AddLTAllocationModal.css';

import React from 'react';

import VBaseModal from '../VBaseModal/VBaseModal';
import VDropdown from '../../components/VDropdown/VDropdown';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import { getCompanyOptions } from '../../helpers/generalHelper';


class AddLTAllocationModal extends React.Component {

    periodTypes = { 'Monthly': 2, 'Yearly': 3 };
    defaultCurrency = 'EUR';
    FTRcParty = 'Vitus';
    TCATcParty = 'TCAT';
    companyOptions = getCompanyOptions();

    settlementOptions = [
        { value: "", label: "None" },
        { value: "Resale", label: "Resale" },
        { value: "AutoSettle", label: "AutoSettle" }
    ];

    state = {
        selectedDirection: null,
        selectedPeriodType: null,
        selectedTSO: null,
        selectedCompany: null,
        capacityId: "",
        startDate: null,
        endDate: null,
        capacity: "",
        order: "",
        selectableTSOList: {},
        price: "",
        currency: this.defaultCurrency,
        selectedSettlement: null
    }

    createModalInputs() {
        return {
            direction: this.state.selectedDirection ? this.state.selectedDirection.value : null,
            periodType: this.state.selectedPeriodType ? this.state.selectedPeriodType.value : null,
            tso: this.state.selectedTSO ? this.state.selectedTSO.value : null,
            company: this.state.selectedCompany ? this.state.selectedCompany.value : null,
            capacity: this.state.capacity ? parseInt(this.state.capacity) : null,
            capacityId: this.state.capacityId ? (this.state.capacityId.trim() ? this.state.capacityId.trim() : "") : "",
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            order: this.state.order ? parseInt(this.state.order) : this.state.order,
            price: this.state.price,
            currency: this.state.currency,
            settlement: this.state.selectedSettlement ? this.state.selectedSettlement.value : null
        };
    }

    setTSOOptions(selectedPeriodType) {

        if (this.state.selectedDirection) {

            if (selectedPeriodType.value === this.periodTypes.Monthly) {
                this.setState({ selectableTSOList: this.props.counterPartyInfo[this.props.counterParty].monthly_tso_list[this.state.selectedDirection.value] });
            } else {
                this.setState({ selectableTSOList: this.props.counterPartyInfo[this.props.counterParty].yearly_tso_list[this.state.selectedDirection.value] });
            }
        }
    }

    clearStates() {
        this.setState({
            selectedDirection: null,
            selectedPeriodType: null,
            selectedTSO: null,
            selectedCompany : null,
            capacityId: "",
            startDate: null,
            endDate: null,
            capacity: "",
            order: "",
            selectableTSOList: {},
            price: "",
            selectedSettlement: null
        });
    }

    onModalAddClick = () => this.props.onAdd(this.createModalInputs());

    onModalCancelClick = () => {
        this.props.onCancel();
        this.clearStates();
    }

    render() {
        return (
            <VBaseModal
                dialogClassName="v-modal-30w"
                show={this.props.show}
                onHide={this.props.onCancel}
                title="Add LT Allocation"
                closeButton={false}
                body={
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size">Counter Party</label>
                            </div>
                            <div className="col-8">
                                <label className="v-label">{this.props.counterParty}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">Direction</label>
                            </div>
                            <div className="col-8">
                                <VDropdown
                                    width="large"
                                    value={this.state.selectedDirection}
                                    options={this.props.counterPartyInfo[this.props.counterParty].directions}
                                    onSelectedOptionChange={(selectedDirection) => {
                                        const defaultSettlement = this.props.counterPartyInfo[this.props.counterParty].settlement_list[selectedDirection.value]
                                        this.setState({ selectedDirection, selectableTSOList: {}, selectedPeriodType: null, selectedTSO: null,
                                            selectedSettlement: {
                                                value: defaultSettlement,
                                                label: defaultSettlement  === '' ? 'None' : defaultSettlement
                                            }
                                        })
                                    }}
                                    isSearchable={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">Period Type</label>
                            </div>
                            <div className="col-8">
                                <VDropdown
                                    width="large"
                                    value={this.state.selectedPeriodType}
                                    options={this.props.counterPartyInfo[this.props.counterParty].periods}
                                    onSelectedOptionChange={(selectedPeriodType) => {
                                        this.setState({ selectedPeriodType, selectedTSO: null, selectableTSOList: {} });
                                        this.setTSOOptions(selectedPeriodType);
                                    }}
                                    isSearchable={false}
                                    disabled={!this.state.selectedDirection}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">Settlement</label>
                            </div>
                            <div className="col-8">
                                <VDropdown
                                    width="large"
                                    value={this.state.selectedSettlement}
                                    options={this.settlementOptions}
                                    onSelectedOptionChange={(selectedSettlement) => {
                                        this.setState({ selectedSettlement })
                                    }}
                                    isSearchable={false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">TSO</label>
                            </div>
                            <div className="col-8">
                                <VDropdown
                                    width="large"
                                    value={this.state.selectedTSO}
                                    options={this.state.selectableTSOList}
                                    onSelectedOptionChange={(selectedTSO) => this.setState({ selectedTSO })}
                                    isSearchable={false}
                                    disabled={!this.state.selectedPeriodType}
                                />
                            </div>
                        </div>
                        {
                            this.props.counterParty !== this.FTRcParty &&
                            <div className="row">
                                <div className="col">
                                    <label className="v-font v-font-size v-lt-add-modal-label">Capacity ID</label>
                                </div>
                                <div className="col-8">
                                    <input type="text" value={this.state.capacityId} onChange={(e) => this.setState({ capacityId: e.target.value })} className="v-input" />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">Start Date</label>
                            </div>
                            <div className="col-8">
                                <VDatePicker
                                    selectedDate={this.state.startDate}
                                    onSelectedDateChange={(startDate) => this.setState({ startDate })}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">End Date</label>
                            </div>
                            <div className="col-8">
                                <VDatePicker
                                    selectedDate={this.state.endDate}
                                    onSelectedDateChange={(endDate) => this.setState({ endDate })}
                                />
                            </div>
                        </div>
                        {
                            <div className="row">
                                <div className="col">
                                    <label className="v-font v-font-size v-lt-add-modal-label">Company</label>
                                </div>
                                <div className="col-8">
                                    <VDropdown
                                        width="large"
                                        value={this.state.selectedCompany}
                                        options={this.companyOptions}
                                        onSelectedOptionChange={(selectedCompany) => this.setState({ selectedCompany })}
                                        isSearchable={true}
                                    />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">Capacity (MWh)</label>
                            </div>
                            <div className="col-8">
                                <input type="number" value={this.state.capacity} onChange={(e) => this.setState({ capacity: e.target.value })} className="v-input" />
                            </div>
                        </div>
                        {!(this.props.counterParty === this.TCATcParty || this.props.counterParty === this.FTRcParty) &&
                            <div className="row">
                                <div className="col">
                                    <label className="v-font v-font-size v-lt-add-modal-label">Order</label>
                                </div>
                                <div className="col-8">
                                    <input type="number" value={this.state.order} onChange={(e) => this.setState({ order: e.target.value })} className="v-input" />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">Price</label>
                            </div>
                            <div className="col-8">
                                <input type="number" value={this.state.price} onChange={(e) => this.setState({ price: e.target.value })} className="v-input" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="v-font v-font-size v-lt-add-modal-label">Currency</label>
                            </div>
                            <div className="col-8">
                                <input type="text" value={this.state.currency} disabled={true} className="v-input" />
                            </div>
                        </div>
                    </div>
                }
                actions={
                    <React.Fragment>
                        <button
                            className="btn v-cancel-button"
                            onClick={this.onModalCancelClick}>
                            Cancel
                        </button>
                        <button
                            className="btn v-button"
                            onClick={this.onModalAddClick}>
                            Add
                        </button>
                    </React.Fragment >
                }
            />
        );
    }


}
export default AddLTAllocationModal;