import './NotFound.css';
import React from 'react';
import VContentContainer from '../../components/VContentContainer/VContentContainer';

const NotFound = () => {
    return (
        <React.Fragment>
            <VContentContainer>
                <div className='v-not-found'>
                    <h1>Error</h1>
                    <h3>Page not found</h3>
                </div>
            </VContentContainer>
        </React.Fragment>
    );
};

export default NotFound;