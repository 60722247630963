import './VSimplePopup.css';
import React from 'react';

//https://www.w3schools.com/howto/howto_js_popup.asp

const VSimplePopup = ({ value, popUpText }) => {
    const popUpRef = React.useRef();

    const hide = () => {
        if (popUpRef.current.className.includes('show'))
            popUpRef.current.classList.toggle("show");
        document.removeEventListener('click', hide);
    }

    const toggleShow = () => {
        popUpRef.current.classList.toggle("show");
        document.addEventListener("click", hide);
    }

    return (
        <div
            className="popup"
            onClick={toggleShow}>
            {value}
            <span ref={popUpRef}
                className="popuptext">
                {popUpText}
            </span>
        </div>
    );
};

export default VSimplePopup;