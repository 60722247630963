import React from 'react';
import VBaseModal from '../VBaseModal/VBaseModal';

const ConfirmationModal = ({
    show, message, onHide, icon = 'fa-question',
    onCancel, cancelText = 'Cancel', showCancelButton = true,
    onConfirm, confirmText = 'Confirm', showConfirmButton = true,
    closeButton = false, dialogClassName="v-modal-30w" }) => {
    return (
        <VBaseModal
            dialogClassName={dialogClassName}
            show={show}
            onHide={onHide}
            title='Confirm'
            closeButton={closeButton}
            body={
                <div className='row'>
                    {icon &&
                        <div className='col v-modal-error-icon-col'>
                            <div className='v-modal-error-icon'>
                                <i className={`fa ${icon} fa-3x`} />
                            </div>
                        </div>}
                    <div className='col-10'>
                        {message}
                    </div>
                </div>
            }
            actions={
                <React.Fragment>
                    {showCancelButton &&
                        <button
                            className="btn v-cancel-button"
                            onClick={onCancel}>
                            {cancelText}
                        </button>}
                    {showConfirmButton &&
                        <button
                            className="btn v-button"
                            onClick={onConfirm}>
                            {confirmText}
                        </button>}
                </React.Fragment >
            }
        />
    );
};

ConfirmationModal.defaultProps = {
    onHide: () => { return; }
};

export default ConfirmationModal