import './VTagInput.css'
import React from 'react';
import ReactTags, { AddStyles as BaseAddStyles } from './cusomized-react-tag-input/ReactTags';

//WARNING: Below package is not directly used, it is customized and used from ./cusomized-react-tag-input.
//https://github.com/prakhar1989/react-tags

const KeyCodes = { comma: 188, enter: 13, tab: 9 };

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab];

class VTagInput extends React.Component {
    constructor() {
        super();

        this.handleDelete = this.handleDelete.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
    }

    handleDelete(i) {
        const newTags = this.props.tags.filter((tag, index) => index !== i);
        this.props.onTagDeleted(newTags);
    }

    handleDrag(tag, currPos, newPos) {
        const tags = [...this.props.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        this.props.onOrderChanged(newTags)
    }

    handleAddition(newTag) {
        //This is preventing adding the same tag again as default, can ve extended if multiple addition is needed
        if (this.props.suggestions.filter(s => s.id === newTag.id).length === 0)
            return;

        this.props.onInternalAddition(newTag);
    }


    render() {
        return (
            <div
                className='v-tag-input'
                onClick={this.props.disabled ?
                    () => { return false; }
                    :
                    () => this.reactTags.resetAndFocusInput()}
            >
                <ReactTags
                    allowDragDrop={!this.props.dragDisabled}
                    readOnly={this.props.disabled}
                    tags={this.props.tags}
                    resetInputOnDelete={false}
                    suggestions={this.props.suggestions}
                    placeholder={this.props.placeholder}
                    delimiters={delimiters}
                    ref={(reactTags) => this.reactTags = reactTags}
                    addStyle={this.props.addStyle}
                    handleDelete={this.handleDelete}
                    handleDrag={this.handleDrag}
                    addButtonText={this.props.addButtonText}
                    onAddButtonClick={this.props.onAddButtonClick}
                    handleAddition={this.handleAddition}
                    minQueryLength={this.props.minQueryLength}
                />
            </div>
        );
    }
}

export const AddStyles = BaseAddStyles;

VTagInput.defaultProps = {
    addStyle: AddStyles.Button,
    minQueryLength: 0
};

export default VTagInput;