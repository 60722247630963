import React from 'react';
import VDropdown from '../../components/VDropdown/VDropdown';
import VBaseModal from '../VBaseModal/VBaseModal';
import _ from 'lodash';

const AddDirectionModal = ({ show, onCancel, onAdd, counterParty, directions }) => {
    const orderedDirections = _.sortBy(
        _.orderBy(directions, ['value']),
        [function (o) { return o.isDisabled; }]);

    let { value, label } = orderedDirections.find(d => !d.isDisabled) || orderedDirections[0];

    const [selectedDirection, setSelectedDirection] = React.useState({ value, label });

    React.useEffect(() => {
        setSelectedDirection({ value, label });
    }, [value, label, show]);

    const onDirectionChange = (newDir) => {
        setSelectedDirection(newDir);
    };

    return (
        <VBaseModal
            dialogClassName="v-modal-30w"
            show={show}
            onHide={onCancel}
            title='Add Direction'
            closeButton={false}
            body={
                <div className="container">
                    <div className='row'>
                        <div className='col'>
                            <label className="v-font v-font-size">Counter Party</label>
                        </div>
                        <div className='col-8'>
                            <label className="v-label">{counterParty}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="v-font v-font-size">Direction</label>
                        </div>
                        <div className='col-8'>
                            <VDropdown
                                disabled={!orderedDirections.find(d => !d.isDisabled)}
                                width="large"
                                options={orderedDirections}
                                value={selectedDirection}
                                isSearchable={true}
                                onSelectedOptionChange={onDirectionChange}
                            />
                        </div>
                    </div>
                </div>
            }
            actions={
                <React.Fragment>
                    <button
                        className="btn v-cancel-button"
                        onClick={onCancel}>
                        Cancel
                    </button>
                    <button
                        className="btn v-button"
                        onClick={() => onAdd(counterParty, selectedDirection.value)}>
                        Add
                    </button>
                </React.Fragment >
            }
        />
    );
};

export default AddDirectionModal;
