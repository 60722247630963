import React from 'react';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import TestPnlDetails from './TestPnlDetails/TestPnlDetails';
import TestPnlSummary from './TestPnlSummary/TestPnlSummary';
import TestCBBreakdown from './TestCBBreakdown/TestCBBreakdown';

class TestPage3 extends React.Component {
    renderPnlSummaryTab() {
        return (
            <VTab key='PnlSummary'
                eventKey='PnlSummary'
                title='Pnl Summary'>
                <TestPnlSummary />
            </VTab>
        );
    }

    renderPnlDetailsTab() {
        return (
            <VTab key='PnlDetails'
                eventKey='PnlDetails'
                title='Pnl Details'>
                <TestPnlDetails />
            </VTab>
        );
    }

    renderCBBreakdownTab() {
        return (
            <VTab key='CBBreakdown'
                eventKey='CBBreakdown'
                title='CB Breakdown'>
                <TestCBBreakdown />
            </VTab>
        );
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Test CB Breakdown">
                    <VMainContainer>
                        <VTabs >
                            {/* {this.renderPnlSummaryTab()}
                            {this.renderPnlDetailsTab()} */}
                            {this.renderCBBreakdownTab()}
                        </VTabs>
                    </VMainContainer>
                </VContentContainer>
            </React.Fragment>
        );
    }
}

export default TestPage3;
