import React from 'react';
import TDropdown from '../../components/TDropdown/TDropdown';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import { DOCUMENT_TYPES, DOCUMENT_TYPE_ARR } from '../../utils/constants';
import FileUploader from '../../components/FileUploader/FileUploader';
import TTable from '../../components/TTable/TTable';


class DocumentDetail extends React.Component {
    state = {
        originalDocument: null,
        documentId: null,
        documentName: '',
        selectedDocumentType: null,
        selectedCompany: null,
        readOnly: true,
        files: [],
        fileNameTableColumns: [
            { key: 'documentName', name: 'Document', sortable: true, resizable: true }
        ],
        fileNameTableRows: []
    };

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                originalDocument: this.props.item,
                documentId: this.props.item.id,
                documentName: this.props.item.documentName,
                selectedDocumentType: DOCUMENT_TYPES[this.props.item.documentType],
                selectedCompany: {
                    id: this.props.item.companyId,
                    name: this.props.item.companyName,
                    label: this.props.item.companyName
                },
            })
        }

        this.setState({
            readOnly: this.props.readOnly ? this.props.readOnly : false
        })
    }

    onCompanyChange = company => {
        this.setState({ selectedCompany: company });
    }

    onChangeDocumentType = documentType => {
        this.setState({ selectedDocumentType: documentType });
    }

    setFiles = (files) => {      
        this.setState({ files: files, fileNameTableRows: files.map(f => ({ "documentName": f.name})) })
    }

    addFiles = (files)  => {
        let newFileList = [...this.state.files, ...files]
        this.setFiles(newFileList)
    }

    onEditButtonClick() {
        this.setState({ readOnly: false })
        this.props.onEditButtonClick(this.state.originalDocument)
    }

    onDeleteDocumentClick = (row) => {
        this.setFiles(this.state.files.filter(f => f.name !== row.documentName));
    }

    validateInputs() {
        return true;
    }

    onSaveButtonClick() {
        if (!this.validateInputs()) {
            return;
        }
        const formData = new FormData();

        if (this.state.documentId) {
            formData.append('document_id', this.state.documentId)
        }
        
        formData.append('document_name', this.state.documentName)
        formData.append('company_id', this.state.selectedCompany.id)
        formData.append('company_name', this.state.selectedCompany.name)
        formData.append('document_type', this.state.selectedDocumentType.value)
        formData.append('file_count', this.state.files.length)
       

        this.state.files.forEach((file, index) => {
            formData.append(`file${index}`, file);
        });

        this.setState({ readOnly: true })

        this.props.onSaveButtonClick(formData)
    }

    onCancelButtonClick() {
        if (this.props.item) {
            this.setState({
                documentId: this.state.originalDocument.id,
                documentName: this.state.originalDocument.documentName,
                selectedDocumentType: DOCUMENT_TYPES[this.state.originalDocument.documentType],
                selectedCompany: {
                    id: this.state.originalDocument.companyId,
                    name: this.state.originalDocument.companyName,
                    label: this.state.originalDocument.companyName
                }
            })
        }
        if (this.props.onCancelButtonClick) {
            this.props.onCancelButtonClick(this.state.originalMarket)
        } else {
            this.setState({ readOnly: true })
        }
    }

    render() {
        return (
            <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ "margin": "12px", display: `${this.state.readOnly ? "block" : "none"}` }}>
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onEditButtonClick()}
                                disabled={!this.state.readOnly}
                            >
                                Edit
                        </button>
                        </AccordionActions>
                        <Divider />
                    </div>

                    <TDropdown
                        label='Company'
                        options={this.props.companyNames}
                        width="large"
                        value={this.state.selectedCompany}
                        defaultValue={this.state.selectedCompany}
                        onSelectedOptionChange={this.onCompanyChange}
                        disabled={this.state.readOnly}
                    />

                    <TDropdown
                        label='Document Type'
                        options={DOCUMENT_TYPE_ARR}
                        width="large"
                        value={this.state.selectedDocumentType}
                        defaultValue={this.state.selectedDocumentType}
                        onSelectedOptionChange={this.onChangeDocumentType}
                        disabled={this.state.readOnly}
                    />

                    <FileUploader
                        addFiles={this.addFiles}
                    >
                    </FileUploader>

                    <TTable
                        height="200px"
                        rows={this.state.fileNameTableRows}
                        columns={this.state.fileNameTableColumns}
                        showDeleteRow={!this.state.readOnly}
                        onDeleteRowClick={this.onDeleteDocumentClick}
                    ></TTable>

                    <div style={{ "margin": "12px", display: `${this.state.readOnly ? "none" : "block"}` }}>
                        <Divider />
                        <AccordionActions>
                            <button
                                size="small"
                                className="btn t-gray-button"
                                onClick={() => this.onCancelButtonClick()}
                            >
                                Cancel
                                </button>
                            <button
                                size="small"
                                className="btn t-orange-button"
                                onClick={() => this.onSaveButtonClick()}
                                disabled={this.state.readOnly}
                            >
                                Save
                             </button>
                        </AccordionActions>
                    </div>
                </div>


            </div>
        );
    }
}

export default DocumentDetail;