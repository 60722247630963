import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { setUser, clearSpinners } from '../actions';
import history from '../history';
import Login from '../pages/Login/Login';
import Main from '../pages/Main/Main';
import PageFactory from './PageFactory';
import { pages } from '../helpers/constants';
import NotFound from '../pages/NotFound/NotFound';
import PrivateRoute from './PrivateRoute';
import VSpinner from '../components/VSpinner/VSpinner';
import Authenticator from './Authenticator';
import { endpointNames } from '../apis/vitusApi';
import VNavbar from '../components/VNavbar/VNavbar';
import { etrmSubRoute, etrmRoutes } from '../etrm/utils/Routes';
import TSideNav from '../etrm/components/TSideNav';

class PageAuth extends React.Component {
    state = { showNavbar: true }

    componentDidMount() {
        Authenticator.initialize();

        // Listen history changes if needed
        history.listen(() => {
            this.props.clearSpinners();

        });
        this.setShowNavbar()
    }

    setShowNavbar = () => {
        if (history.location.pathname !== endpointNames.main && !this.state.showNavbar) {
            this.setState({ showNavbar: true })
        }
        else if (history.location.pathname.startsWith(etrmSubRoute) && this.state.showNavbar) {
            this.setState({ showNavbar: false })
        }
    }

    renderRoutes() {
        if (!this.props.pages)
            return "";

        let pageInfo;

        const routes = this.props.pages.map(pageKey => {
            pageInfo = pages[pageKey]

            if (!pageInfo) {
                console.log('Unknown page:', pageKey);
                return null;
            }

            if (pageInfo.route === "")
                return null;

            return <PrivateRoute key={pageInfo.route}
                path={pageInfo.route}
                exact
                component={PageFactory.getPageClass(pageInfo.pageJsClass)} />;
        });

        return routes;
    }

    renderNestedRoutes = (nestedRoutes, items) => {
        nestedRoutes.forEach(route => {
            if (route.path === "")
                items.push(null);

            if (route.routes) {
                this.renderNestedRoutes(route.routes, items)
            }

            items.push(
                <PrivateRoute 
                    key={route.path}
                    path={route.path}
                    exact
                    component={route.component} />
            );
        });

        return items;
    }

    renderNavbar() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path={endpointNames.main} exact component={Main} />
                    <Route path={endpointNames.login} exact component={Login} />
                    {this.renderRoutes()}
                    <Route path="" exact component={NotFound} />
                </Switch>
                <VNavbar />
            </Router>
        )
    }

    renderSideBar() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path={endpointNames.login} exact component={Login} />
                    <TSideNav>
                        {this.renderNestedRoutes(etrmRoutes, [])}
                        
                    </TSideNav>
                </Switch>
            </Router>
        )
    }

    render() {
        if (!this.props.auth.userChecked)
            return (
                <VSpinner show={true} />
            );

        return (
            this.state.showNavbar ? this.renderNavbar() : this.renderSideBar()
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pages: state.auth.pages
    };
};

export default connect(mapStateToProps, { setUser, clearSpinners })(PageAuth);