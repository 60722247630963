import './VBaseModal.css';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { renderIfTrue } from '../../helpers/generalHelper';

const VBaseModal = ({ show, onHide, small, title, body, actions, dialogClassName, closeButton = true }) => {
    const size = small ? 'sm' : 'lg';

    return (
        <Modal
            dialogClassName={`v-modal ${dialogClassName ? dialogClassName : ""}`}
            show={show}
            onHide={onHide}
            size={size}>
            <Modal.Header closeButton={closeButton}>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            {renderIfTrue(body,
                <Modal.Body className="v-modal-body">
                    {body}
                </Modal.Body>
            )}
            {renderIfTrue(actions,
                <Modal.Footer>
                    {actions}
                </Modal.Footer>
            )}
        </Modal>
    );
}

export default VBaseModal;