import React from 'react';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VTagInput from '../../components/VTagInput/VTagInput';
import { AddStyles } from '../../components/VTagInput/cusomized-react-tag-input/ReactTags';
import _ from 'lodash';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VDropdown from '../../components/VDropdown/VDropdown';
import TestPnlDetails from './TestPnlDetails/TestPnlDetails';
import TestPnlSummary from './TestPnlSummary/TestPnlSummary';
import TestCBBreakdown from './TestCBBreakdown/TestCBBreakdown';

class TestPage2 extends React.Component {
    dataTypesToList = ['Price', 'Monthly Auction Clearing Price', 'Daily Auctions', 'Fees', 'Exchange Rates', 'PnL'];
    countries = ['TR', 'BG', 'GR']
    directions = ['TR-BG', 'TR-GR', 'TR-GR-BG', 'GR-BG', 'BG-GR'];
    summaryTypes = ['LT', 'Daily']

    state = {
        selectedStartDate: this.getDefaultDate(),
        selectedEndDate: this.getDefaultDate(),
        selectedDirections: [
            { id: 'Test1', text: 'Test1' },
            { id: 'Test2', text: 'Test2' }
        ],
        directionSuggestions: [
            { id: 'Test3', text: 'Test3' },
            { id: 'Test4', text: 'Test4' },
            { id: 'Test5', text: 'Test5' },
            { id: 'Test6', text: 'Test6' },
            { id: 'Test7', text: 'Test7' },
            { id: 'Test8', text: 'Test8' },
            { id: 'Test9', text: 'Test9' },
            { id: 'Test10', text: 'Test10' },
            { id: 'Test11', text: 'Test11' },
            { id: 'Test12', text: 'Test12' }
        ],
        selectedDataTypes: {},
        selectedGrouping: { value: 'Daily', label: 'Daily' },
        selectedCurrency: { value: 'TRY', label: 'TRY' }
    }

    getDefaultDate() {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        today.setDate(today.getDate() + 1);
        return today;
    }

    onDirectionTagsChanged(newTags) {
        this.setState({ directionTags: [...newTags] });
    }

    onselectedDirectionsChanged = currentselectedDirections => {
        this.setState({ selectedDirections: [...currentselectedDirections] });
    }

    addselectedDirectionTag = (newselectedDirections) => {
        this.setState({
            selectedDirections: [...this.state.selectedDirections, newselectedDirections],
            directionSuggestions: this.state.directionSuggestions.filter(i => i['id'] !== newselectedDirections['id'])
        });

    }

    onselectedDirectionRemoved = currentselectedDirections => {

        let removedTag = _.difference(this.state.selectedDirections, currentselectedDirections)[0];

        this.setState({
            selectedDirections: [...currentselectedDirections],
            directionSuggestions: _.orderBy([...this.state.directionSuggestions, removedTag], ['text'], ['asc'])
        });
    }

    renderPnlSummaryTab() {
        return (
            <VTab key='PnlSummary'
                eventKey='PnlSummary'
                title='Pnl Summary'>
                <TestPnlSummary />
            </VTab>
        );
    }

    renderPnlDetailsTab() {
        return (
            <VTab key='PnlDetails'
                eventKey='PnlDetails'
                title='Pnl Details'>
                <TestPnlDetails />
            </VTab>
        );
    }

    renderCBBreakdownTab() {
        return (
            <VTab key='CBBreakdown'
                eventKey='CBBreakdown'
                title='CB Breakdown'>
                <TestCBBreakdown />
            </VTab>
        );
    }

    getDatePicker(currentDate, onChange) {
        return (
            <VDatePicker
                selectedDate={currentDate}
                onSelectedDateChange={onChange}
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Test Pnl">
                    <VFilterContainer>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-4'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="v-filter-group">
                                                <div className="v-filter-label v-label">
                                                    Grouping
                                                </div>
                                                <div>
                                                    <VDropdown
                                                        width='large'
                                                        options={[
                                                            { value: 'Daily', label: 'Daily' },
                                                            { value: 'Weekly', label: 'Weekly' },
                                                            { value: 'Monthly', label: 'Monthly' }
                                                        ]}
                                                        value={this.state.selectedGrouping}
                                                        onSelectedOptionChange={(selectedGrouping) => {
                                                            this.setState({ selectedGrouping })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="v-filter-group">
                                                <div className="v-filter-label v-label">
                                                    Start Date
                                                </div>
                                                <div>
                                                    {this.getDatePicker(this.state.selectedStartDate, (selectedStartDate) => this.setState({ selectedStartDate }))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="v-filter-group">
                                                <div className="v-filter-label v-label">
                                                    Currency
                                                </div>
                                                <div>
                                                    <VDropdown
                                                        width='large'
                                                        options={[
                                                            { value: 'TRY', label: 'TRY' },
                                                            { value: 'EUR', label: 'EUR' }
                                                        ]}
                                                        value={this.state.selectedCurrency}
                                                        onSelectedOptionChange={(selectedCurrency) => {
                                                            this.setState({ selectedCurrency })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="v-filter-group">
                                                <div className="v-filter-label v-label">
                                                    End Date
                                                </div>
                                                <div>
                                                    {this.getDatePicker(this.state.selectedEndDate, (selectedEndDate) => this.setState({ selectedEndDate }))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="v-filter-group">
                                                <div className="v-filter-label v-label">
                                                    Border
                                                </div>
                                                <div className="v-filter-taginput">
                                                    <VTagInput
                                                        addStyle={AddStyles.Textbox}
                                                        placeholder='Add Border'
                                                        tags={this.state.selectedDirections}
                                                        onOrderChanged={this.onselectedDirectionsChanged}
                                                        onTagDeleted={this.onselectedDirectionRemoved}
                                                        onInternalAddition={this.addselectedDirectionTag}
                                                        suggestions={this.state.directionSuggestions}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="v-filter-group">
                                                <div className="v-filter-label v-label">
                                                    Counter Party
                                                </div>
                                                <div className="v-filter-taginput">
                                                    <VTagInput
                                                        addStyle={AddStyles.Textbox}
                                                        placeholder='Add Border'
                                                        tags={this.state.selectedDirections}
                                                        onOrderChanged={this.onselectedDirectionsChanged}
                                                        onTagDeleted={this.onselectedDirectionRemoved}
                                                        onInternalAddition={this.addselectedDirectionTag}
                                                        suggestions={this.state.directionSuggestions}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                className="btn v-cancel-button v-filter-button">
                                <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                            </button>
                            <button
                                tabIndex={0}
                                className="btn v-button v-filter-button">
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                        </div>
                    </VFilterContainer>
                    <VMainContainer>
                        <VTabs >
                            {this.renderPnlSummaryTab()}
                            {this.renderPnlDetailsTab()}
                            {/* {this.renderCBBreakdownTab()} */}
                        </VTabs>
                    </VMainContainer>
                </VContentContainer>
            </React.Fragment>
        );
    }
}

export default TestPage2;
