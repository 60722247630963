import React from 'react';
import VDropdown from '../../components/VDropdown/VDropdown';
import VBaseModal from '../VBaseModal/VBaseModal';

const defaultCompanyWithBorders = 'Ensco';

const AddServicingCompanyModal = ({
    show, onCancel, onAdd, exchange, counterParty, companies,
    companyFilter, defaultCompany, dialogClassName = "v-modal-30w",
    showBorderOption = false, companyBorderPairs = {} }) => {

    const companyOptions = companies.map(comp => {
        return { value: comp, label: comp };
    });

    let initialCompanyOption;

    if (!companyOptions || companyOptions.length === 0) {
        initialCompanyOption = { value: null, label: null };
    }
    else {
        const defaultCompanyOption = companyOptions.find(c => c.value === defaultCompany);

        if (defaultCompanyOption)
            initialCompanyOption = defaultCompanyOption;
        else {
            if (showBorderOption && companyOptions.find(c => c.value === defaultCompanyWithBorders))
                initialCompanyOption = companyOptions.find(c => c.value === defaultCompanyWithBorders);
            else
                initialCompanyOption = companyOptions[0];
        }
    }

    let companyValue = initialCompanyOption.value;
    let companyLabel = initialCompanyOption.label;

    const [selectedCompany, setSelectedCompany] = React.useState({ value: companyValue, label: companyLabel });

    React.useEffect(() => {
        setSelectedCompany({ value: companyValue, label: companyLabel });
    }, [companyValue, companyLabel, show]);

    const [borderOptions, setBorderOptions] = React.useState(companyBorderPairs[companyValue]);

    const borderOption = borderOptions ? borderOptions[0] : '';

    const [selectedBorder, setSelectedBorder] = React.useState({ value: borderOption, label: borderOption });

    const onCompanyChange = (newDir) => {
        setSelectedCompany(newDir);
        setBorderOptions(companyBorderPairs[newDir.value]);

        const newBorderOption = { value: companyBorderPairs[newDir.value][0], label: companyBorderPairs[newDir.value][0] };
        setSelectedBorder(newBorderOption);
    };

    if (!initialCompanyOption || !initialCompanyOption.value)
        return null;

    return (
        <VBaseModal
            dialogClassName={dialogClassName}
            show={show}
            onHide={onCancel}
            title='Add Servicing Company'
            closeButton={false}
            body={
                <div className="container">
                    <div className='row'>
                        <div className='col'>
                            <label className="v-font v-font-size">Exchange</label>
                        </div>
                        <div className='col-8'>
                            <label className="v-label">{exchange}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="v-font v-font-size">Counter Party</label>
                        </div>
                        <div className='col-8'>
                            <label className="v-label">{counterParty}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="v-font v-font-size">Servicing Company</label>
                        </div>
                        <div className='col-8'>
                            {
                                companyFilter ?
                                    <label className="v-font v-font-size">{companyFilter}</label>
                                    :
                                    <VDropdown
                                        width="large"
                                        options={companyOptions}
                                        value={selectedCompany}
                                        isSearchable={true}
                                        onSelectedOptionChange={onCompanyChange}
                                    />
                            }
                        </div>
                    </div>
                    {
                        showBorderOption &&
                        <div className='row'>
                            <div className='col'>
                                <label className="v-font v-font-size">Border</label>
                            </div>
                            <div className='col-8'>
                                <VDropdown
                                    width="large"
                                    options={borderOptions.map(b => { return { value: b, label: b }; })}
                                    value={selectedBorder}
                                    isSearchable={true}
                                    onSelectedOptionChange={setSelectedBorder}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
            actions={
                <React.Fragment>
                    <button
                        className="btn v-cancel-button"
                        onClick={onCancel}>
                        Cancel
                    </button>
                    <button
                        className="btn v-button"
                        onClick={() => onAdd(selectedCompany.value, selectedBorder?.value)}>
                        Add
                    </button>
                </React.Fragment >
            }
        />
    );
};

export default AddServicingCompanyModal;