import React from 'react';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import { VDatePickerTypes } from '../../components/VDatePicker/VDatePicker';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import _ from 'lodash';
import VCheckboxGroup from '../../components/VCheckboxGroup/VCheckboxGroup';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import { getCompanyInvoice, getCompanyInvoiceFile } from '../../apis/vitusApi';
import { alertError, handleApiError, specifyErrorMessage } from '../../helpers/errorHelper';
import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import { portalMessages } from '../../helpers/portalMessages';
import { extractDate, getCompanyCheckboxOptions } from '../../helpers/generalHelper';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorageHelper';
import FileSaver from 'file-saver';
import { isEmpty, isObject } from 'lodash';
import {isArray} from 'lodash';
import moment from 'moment';
import VTable from '../../components/VTable/VTable';

import './TeiasDailyEntries.css';

class TeiasDailyEntries extends React.Component {
    spinner = new SpinnerManager(history.location.pathname);
    storedActiveFilter = getLocalStorage(this.props.pageName || 'teiasDailyEnteries', 'filters', 'activeFilter') || {};
    minStartDate = new Date(2021, 0, 1); // Hard min date because system is started to be used on 2021

    dataGroupClasses = ['v-data-group-1', 'v-data-group-2'];
    
    state = {
        selectedStartDate: this.getDefaultStartDate(),
        invoiceDetails: {},
        flowsDetails: [],
        eetCetDetails: [],
        companyOptions: getCompanyCheckboxOptions(),
        activeFilter: this.storedActiveFilter,
        currentDetailsPage: 1,
        detailRecordsPerPageCount: 120
    }

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Default)',
    }

    showErrorMessage(error, operationName) {
        const { message } = specifyErrorMessage(error, this.errorMessages, operationName);

        if (message)
            alertError(message);
    }

    componentDidMount() {
        const spinnerKey = this.spinner.showSpinner();

        if (this.props.setInitialState)
            this.props.setInitialState(this.state);

        this.onShowButtonClick();
        
        this.spinner.hideSpinner(spinnerKey);
        
    }

    showButtonEnabled() {
        // There are no scenarios to disable show button for now
        // function written to keep project convention
        return true;
    }

    downloadButtonEnabled() {
        // There are no scenarios to disable show button for now
        // function written to keep project convention
        return true;
    }

    createNewFilterObject() {
        let { start_date} = this.getConvertedDatesForGroup(this.state.selectedStartDate);
        return {
            start_date: extractDate(start_date),
            company_list: Object.keys(this.state.companyOptions).filter(p => this.state.companyOptions[p].checked),
        };
    }

    onShowButtonClick() {
        if (!this.state.selectedStartDate) {
            this.showErrorMessage(portalMessages.DATE_SELECTION);
            return false;
        }

        const filter = this.createNewFilterObject();
        this.refreshInvoiceAsync(filter, null, 1);
        
        return true;
    }

    async refreshInvoiceAsync(filter, rowCount, pageNum) {
        const spinnerKey = this.spinner.showSpinner();

        try {
            if (!filter)
                filter = this.state.activeFilter;

            let response;

            try {
                response = await getCompanyInvoice(filter);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                const activeFilterToDisplay = [
                    { label: `Month/Year`, value: filter.start_date },
                    { label: "Companies", value: filter.company_list.map(p => this.state.companyOptions[p].title) },
                    { label: "Timezone", value: "CET" }
                ];

                this.setState({
                    flowsDetails: response.data.success.flows_details,
                    invoiceDetails: response.data.success.invoice_details,
                    eetCetDetails: response.data.success.eet_cet_details,
                    activeFilter: filter,
                    currentDetailsPage: pageNum,
                    detailRecordsPerPageCount: rowCount,
                    activeFilterToDisplay
                }, () => {
                    const {
                        start_date,
                        ...rest
                    } = filter;

                    setLocalStorage(this.props.pageName || 'teiasDailyEntries', 'filters', 'activeFilter', { ...rest })
                });
            }
            else if (!response.data.error) {
                this.showErrorMessage(portalMessages.TEIAS_DAILY_ENTRIES.INVALID_DAILY_ENTRY.replace('[DATE]', filter.start_date));
            }

            if (response.data.error) {
                this.showErrorMessage(response.data.error);
            }
        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED);
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    getConvertedDatesForGroup(defaultStart) {
        if (!defaultStart)
            defaultStart = new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1);
        
        let start_date = defaultStart;
        
        return { start_date}
    }

    getDefaultStartDate() {
        return this.getConvertedDatesForGroup().start_date;
    }

    getDate(dayDifference, date) {
        if (!date)
            date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + dayDifference);
        return date;
    }

    getDatePicker(currentDate, minDate, maxDate, onChange) {
        let datePickerType = VDatePickerTypes.month;
        
        return (
            <VDatePicker
                selectedDate={currentDate}
                onSelectedDateChange={onChange}
                minDate={minDate}
                maxDate={maxDate}
                type={datePickerType}
            />
        );
    }

    exportInvoice = async () => {
        const spinnerKey = this.spinner.showSpinner();

        let response;
        let body = {'flows_details': this.state.flowsDetails, 'invoice_details': this.state.invoiceDetails, 'timezone_details': this.state.eetCetDetails};
        try {
            response = await getCompanyInvoiceFile(body);
            
            let reportDate = moment(this.state.activeFilter.start_date);
            let reportMonth = (reportDate.month() + 1) < 10 ? `0${reportDate.month()+1}` : `${reportDate.month()+1}`;
            const filename = `TEİAŞ Daily Entries - ${reportMonth}/${reportDate.year()}.xlsx`;
            const contentType = response.headers["content-type"];

            const blob = new Blob([response.data], { type: contentType });

            if (contentType === 'application/json') {
                //const error = blob.text();
                return;
            }

            FileSaver.saveAs(blob, filename);
            return;
        } catch (error) {
            handleApiError(error);
            return false;
        }
        finally{
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    getFilter() {
        return (
            <VFilterContainer
                showActiveFilter
                activeFilter={this.state.activeFilterToDisplay}
            >
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                {<div className='col-6'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="v-filter-group">
                                                <div className="v-filter-label v-label">
                                                    Month/Year
                                                </div>
                                                <div>
                                                    {this.getDatePicker(
                                                        this.state.selectedStartDate,
                                                        this.minStartDate,
                                                        this.getDate(1),
                                                        (selectedStartDate) => this.setState({ selectedStartDate })
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className='col-6'>
                                    <div className="v-filter-group">
                                        <div className="v-filter-label v-label">
                                            Company
                                        </div>
                                        <div>
                                            <VCheckboxGroup
                                                items={this.state.companyOptions}
                                                onCheckChanged={(k, updatedItems) => this.setState({ companyOptions: { ...updatedItems } })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="v-filter-buttons">
                    <button
                        disabled={!this.showButtonEnabled()}
                        tabIndex={0}
                        className="btn v-button v-filter-button"
                        onClick={() => this.onShowButtonClick()}>
                        <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                    </button>
                    <button
                        disabled={!this.downloadButtonEnabled()}
                        tabIndex={0}
                        className="btn v-button v-filter-button"
                        onClick={() => this.exportInvoice()}>
                        <i aria-hidden="true" className="fa fa-search fa-fw" />Download
                    </button>
                </div>
            </VFilterContainer>
        );
    }

    headerToKey(header) {
        if (!!header?.props)
            return isArray(header.props.children) ?
                header.props.children.map(h => { return this.headerToKey(h) })?.join('_')
                : this.headerToKey(header.props.children);

        return header;
    }

    renderHeaders(data, t){
        const subHeaderRows = [];

        data = data.filter(d => d.flow_type === t);
    
        let sortedData = data;
        
        let sub_title = [
            (sortedData) => { return `${sortedData.counter_party}`; },
            (sortedData) => { return `${sortedData.direction}`; }
        ]
        
        for (let rowIdx = 0; rowIdx < 2; rowIdx++) {
            
            subHeaderRows.push({ key: `sub_header_row_${rowIdx}`, children: [] });
            let columGroupKey = "TeiasDailyEntries"
                if (!data || !data.length)
                    continue;

                const headerClassCounter = (rowIdx + 1) % 2;
                const headerClass = this.dataGroupClasses[headerClassCounter];

                for (let dataIdx = 0; dataIdx < sortedData.length; dataIdx++) {
                    const subGroup = sortedData[dataIdx];
                    
                    const currentColumnIdx = subHeaderRows[rowIdx].children.length;
                    let parent;
                    
                    if (rowIdx === 0)
                        parent = columGroupKey;
                    else {
                        const parentDict = subHeaderRows[rowIdx - 1].children[currentColumnIdx];
                        parent = parentDict.parent + this.headerToKey(parentDict?.title);
                    }

                    if (sub_title.length - 1 < rowIdx) {
                        subHeaderRows[rowIdx].children.push({
                            colSpan: 0,
                            key: `${columGroupKey}_${rowIdx}_${currentColumnIdx}`,
                            parent,
                        });
                        continue;
                    }

                    let rowSpan = 1;

                    if (sub_title.length - 1 === rowIdx && rowIdx < 2 - 1)
                        rowSpan += 2 - rowIdx - 1;

                    const subTitle = sub_title[rowIdx](subGroup);
                    const subTitleStr = this.headerToKey(subTitle);

                    subHeaderRows[rowIdx].children.push({
                        colSpan: 1,
                        key: `${columGroupKey}_${rowIdx}_${currentColumnIdx}`,
                        group: columGroupKey,
                        rowSpan: rowSpan,
                        className: `${headerClass}`,
                        title: subTitle,
                        parent,
                        subTitleStr
                    });

                }
            
        }

        for (let i = 0; i < 2; i++) {
            let previousSuccessfulColumn = subHeaderRows[i].children[0];

            for (let j = 1; j < subHeaderRows[i].children.length; j++) {
                const current = subHeaderRows[i].children[j];

                if (!current || !current.colSpan)
                    continue;

                if (previousSuccessfulColumn
                    && previousSuccessfulColumn.group === current.group
                    && previousSuccessfulColumn.parent === current.parent
                    && previousSuccessfulColumn.subTitleStr === current.subTitleStr) {
                    previousSuccessfulColumn.colSpan++;
                    current.colSpan = 0;
                }
                else {
                    previousSuccessfulColumn = current;
                }
            }
        }

        let name = `${t}`;
        return (
            <React.Fragment>
                <tr>
                    <th rowSpan={3} className ='v-flows-date-header' style={{padding: '2px 8px', borderBottom: 'solid 3px #979797', borderRight: 'solid 3px #979797'}}>
                        {name}<br></br>
                        {'DateTimeCET'}
                    </th>                    
                </tr>
                {subHeaderRows.map(row => {
                    return (
                        <tr key={row.key}>
                            {row.children.filter(c => c?.colSpan).map((cell, cellIdx) => {
                                    
                                return (
                                    <th style={{padding: '2px 8px', borderRight: '2px solid #141414'}}
                                        key={cell.key}
                                        parent={cell.parent ?? "EMPTY"}
                                        subtitlestr={cell.subTitleStr}
                                        rowSpan={cell.rowSpan}
                                        colSpan={cell.colSpan}
                                        className={cell.className}
                                        title={isObject(cell.title) ? cell.title.props.children[0] : cell.title}>
                                        {cell.title = cell.title === 'Empty' ? "" : cell.title}
                                    </th>
                                );
                                
                            })}
                        </tr>
                    )
                })}
            </React.Fragment>
        );
    }

    renderRows(data, t){
        let rowClassOrder = 0;
        let values = [];
        let date_values = [];
        let date_last_hour_values = [];
        let date_value;

        let reference_data = data.filter(d => d.flow_type === t);
        
        reference_data[0].data.forEach(d => {
            values.push(d.date_time);
            date_values.push(d.date_time);
            date_last_hour_values.push(d.last_hour);
        });
        
        let font_weight;
        let row_style;
        return (
            values.map((v, idx) => {
                    if (date_last_hour_values[idx] === "true"){
                        row_style={borderRight: 'solid 3px #979797', padding: '2px 8px', backgroundColor: '#b2b2b2'}
                    }
                    else{
                        row_style={borderRight: 'solid 3px #979797', padding: '2px 8px'}
                    }
                    rowClassOrder = (rowClassOrder + 1) % 2;
                    date_value = date_values[idx]
                    return (
                        <tr key={v} style={{borderBottom: 'solid 3px #979797'}}>
                            <td style={row_style}>
                                {date_value}
                            </td>
                            {
                                reference_data.map((d, columnIdx) => {
                                    if (d.data[idx].date_time === 'Total'){
                                        font_weight = 'bold'
                                    }
                                    else{
                                        font_weight = 'normal'
                                    }
                                    return (
                                        <td key={`${v}_${columnIdx}`} style={{textAlign: 'center', borderRight: 'solid 3px #979797', fontWeight: font_weight}}>
                                            {d.data[idx].profile.toLocaleString()}
                                        </td>
                                    );                
                                })
                            }
                        </tr>
                    )
            })
        )
        
    }

    renderHourlyDetails(data) {
        if (!data || isEmpty(data))
            return null;

        
        let types = Object.keys(data.reduce((r, {flow_type}) => (r[flow_type]='', r), {}))
        return (types.map(t => {
            
            return(
            
                <div className = 'v-margin'>
                    <table className='table-striped' style={{border: 'solid 1px #dadada'}}>
                        <thead>
                            {this.renderHeaders(data, t)}
                        </thead>
                        <tbody>
                            {this.renderRows(data, t)}
                        </tbody>
                        
                    </table>
                </div>
                
            ) 
            
        }))
    }

    onOpenDetailsEditMode() {
        this.setState({ editingDetails: true });
    }

    onCloseDetailsEditMode() {
        this.setState({ editingDetails: false });

    }

    onSaveTotalDetailsAsync = async (newData) => {
        let spinnerKey = this.spinner.showSpinner();


        try {

            let tableTotalIndex;
            let tableTotalInvoice = 0
            let tableTotalInvoiceVat = 0;
            let tableTotalInvoiceIndex, tableTotalInvoiceVatIndex;
            newData.values.map((v, vidx) => {
                let totalCost, additionalCharge, additionalChargeIndex;
                let totalInvoiceIndex, totalInvoiceVatIndex;
                newData.headers.map((h, idx) => {
                    if (h === 'Total Cost'){
                        totalCost = Number(v[idx].replaceAll(',', ''));
                    }
                    if (h === 'Additional Charge'){
                        additionalCharge = Number(v[idx]);
                        additionalChargeIndex = idx;
                    }
                    if (h === 'Total Invoice Amount'){
                        totalInvoiceIndex = idx;
                        tableTotalInvoiceIndex = idx;
                    }
                    if (h === 'Total Invoice Amount (w/VAT)'){
                        totalInvoiceVatIndex = idx;
                        tableTotalInvoiceVatIndex = idx;
                    }
                    if (v[idx] === 'Total'){
                        tableTotalIndex = vidx;
                    }
                })
                
                if (!tableTotalIndex){
                    let totalInvoice = totalCost + Number(additionalCharge);
                    let totalInvoiceVat = totalInvoice * Number(1.2)
                    tableTotalInvoice = tableTotalInvoice + totalInvoice
                    tableTotalInvoiceVat = tableTotalInvoiceVat + totalInvoiceVat
                    
                    v[additionalChargeIndex] = Number(additionalCharge);
                    v[totalInvoiceIndex] = Number(totalInvoice);
                    v[totalInvoiceVatIndex] = Number(totalInvoiceVat);    
                }
            })

            newData.values[tableTotalIndex][tableTotalInvoiceIndex] = Number(tableTotalInvoice);
            newData.values[tableTotalIndex][tableTotalInvoiceVatIndex] = Number(tableTotalInvoiceVat);
            

            let editedInvoiceDetails = {invoice_details: newData.values, invoice_columns: newData.headers}
            
            this.setState({invoiceDetails: editedInvoiceDetails});
            return true;
        } catch (error) {
            this.showErrorMessage(portalMessages.TEIAS_DAILY_ENTRIES.INVALID_ADDITIONAL_CHARGE);
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    renderTimezoneDetails(){
        if ((!this.state.eetCetDetails || isEmpty(this.state.eetCetDetails)))
            return null;

        return this.state.eetCetDetails.map(h => {
            let title = h.timezone
            let timezone_details = h.timezone_details
            let timezone_columns = h.timezone_columns
            let items = {headers: timezone_columns,
                         values: []}
            timezone_details.map(d => {
                let timezone_values = []
                timezone_columns.map((c, idx) => {
                    timezone_values.push(d[idx]);
                })
                items.values.push(timezone_values);
            })
            
            return (
                <div className='v-infinite-width v-table-list'>
                                        
                <VTable
                    key={title}
                    title={title}
                    items={items}
                    inputType='number'
                    staticHeaderButtons
                    readonlyColumns={[]}
                    scales={{ '*': this.capacityScales }}
                    customColumnClasses={{ 0: "v-column-narrow-bold" }}
                />
                </div>
            );
            
        })
        
    }


    renderTotalDetails(){
        let invoice_details = this.state.invoiceDetails.invoice_details;
        let invoice_columns = this.state.invoiceDetails.invoice_columns;
        
        if ((!invoice_details || isEmpty(invoice_details)) && (!invoice_columns || isEmpty(invoice_columns)))
            return null;

        const items = {
            headers: invoice_columns,
            values: []
        }
        
        invoice_details.map(h => {
            let invoiceList = []
            invoice_columns.map((d, idx) => { 
                invoiceList.push(h[idx].toLocaleString("en-us", { maximumFractionDigits: 2 }));
            })
            items.values.push(invoiceList);
        })
        
        const title = 'Total Details'

        return (
            <div className='v-infinite-width v-table-list'>
                <VTable
                    key={title}
                    title={title}
                    items={items}
                    inputType='number'
                    staticHeaderButtons
                    readonlyColumns={['TEIAS', 'Border', 'Unit Rate', 'Amount (MWh)', 'Total Cost', 'Total Invoice Amount', 'Total Invoice Amount (w/VAT)']}
                    scales={{ '*': this.capacityScales }}
                    customColumnClasses={{ 0: "v-column-narrow-bold" }}
                    onOpenEditMode={() => this.onOpenDetailsEditMode()}
                    onCloseEditMode={() => this.onCloseDetailsEditMode()}
                    onSaveChangesAsync={(newData) => this.onSaveTotalDetailsAsync(newData)}
                    headerButtons={{
                        showEditButton: true
                    }}
                    bigNumber={true}
                    
                />
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer
                    title={this.props.title || "Teias Report"}>
                    {this.getFilter()}
                    <VMainContainer>
                        <React.Fragment>
                            <VTabs>
                                
                                <VTab key='TotalDetails'
                                      eventKey='TotalDetails'
                                      title='Total Details'>
                                    {this.renderTotalDetails()}
                                    {this.renderTimezoneDetails()}
                                </VTab>
                                <VTab key='TeiasDailyEntries'
                                      eventKey='TeiasDailyEntries'
                                      title='Teias Hourly Entries'>
                                    {this.state.flowsDetails && !isEmpty(this.state.flowsDetails) &&
                                        <div className='v-infinite-width v-table-list'>
                                        {this.renderHourlyDetails(this.state.flowsDetails)}
                                        </div>
                                    }
                                </VTab>
                            </VTabs>
                        </React.Fragment>
                    </VMainContainer>
                </VContentContainer>
            </React.Fragment >
        );
    }
}

export default TeiasDailyEntries;