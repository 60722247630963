import './Login.css';

import React from 'react';
import CryptoJS from 'crypto-js';
import { login as vitusApiLogin, getUser, endpointNames } from '../../apis/vitusApi';
import { handleApiError, showErrorMessage } from '../../helpers/errorHelper';
import { connect } from 'react-redux';
import { setUser } from '../../actions'
import { Redirect } from 'react-router-dom';
import VButton from '../../components/VButton/VButton';

class Login extends React.Component {
    userName = React.createRef();
    password = React.createRef();
    state = { showError: false, isLoading: false }

    hashPass = (pass) => {
        let hash = CryptoJS.HmacSHA256(pass, process.env.REACT_APP_MILH);
        return CryptoJS.enc.Base64.stringify(hash);
    };

    onSubmit = event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        this.login(this.userName.current.value, this.hashPass(this.password.current.value));
    }

    showError() {
        this.setState({ showError: true, isLoading: false });
        this.password.current.value = "";
    }

    login(username, password) {
        this.setState({ showError: false });

        vitusApiLogin(JSON.stringify(
            {
                username: username,
                password: password
            })).then(response => {
                if (response.data.data.toLowerCase() === 'true') {
                    getUser().then(gotUser => {
                        if (!gotUser) {
                            this.showError();
                        }
                    });
                }
                else {
                    this.showError();
                }
            }, error => {
                handleApiError(error);
                this.showError();
            });
    }

    clearErrorMessage = () => {
        if (this.state.showError)
            this.setState({ showError: false });
    }

    render() {
        if (this.props.auth.isLoggedIn) {
            const { from } = this.props.location.state || { from: { pathname: endpointNames.main } };
            return (
                <Redirect to={from} />
            );
        }

        return (
            <div className="container">
                <div className="v-login-border">
                    <div className="v-login-content">
                        <div className="v-login-logo">
                            <img src="/logo.png" alt="Vitus Commodities"></img>
                        </div>
                        <div className="v-login-form">
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        ref={this.userName}
                                        onChange={this.clearErrorMessage}
                                        className="form-control fa v-login-placeholder"
                                        placeholder="&#xf2c0;  Username"
                                        minLength="3"
                                        required />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        ref={this.password}
                                        onChange={this.clearErrorMessage}
                                        className="form-control fa v-login-placeholder"
                                        placeholder="&#xf023;   Password"
                                        minLength="5"
                                        required />
                                </div>
                                <VButton
                                    isLoading={this.state.isLoading}
                                    type="submit"
                                    className="btn btn-outline-dark pmd-ripple-effect v-login-btn">
                                    Login
                                        </VButton>
                                {
                                    this.state.showError &&
                                    showErrorMessage("Login failed. Please check your credentials.")

                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

export default connect(mapStateToProps, { setUser })(Login);