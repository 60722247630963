import './VTabs.css';
import React, { useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import VOverlay from '../VOverlay/VOverlay';
import { isArray } from '../../helpers/generalHelper';
import _ from 'lodash';

const prepareChild = (child, disableAllTabs) => {
    if (!child || !child.type || child.type !== VTab)
        throw new Error('All children of VTabs must be VTab!');

    return <child
        key={child.props.eventKey}
        {...child.props}
        disabled={child.props.disabled || disableAllTabs} />;
}

const VTabs = ({ disableAllTabs, children, disabledAllMessage, onSelect, activeKey = null }) => {
    const [showMessage, setShowMessage] = useState(false);
    const ref = useRef(null);
    const [key, setKey] = useState(activeKey);

    if (!children || children.length === 0)
        return null;

    if (!key) {
        if (_.isArray(children)) {
            setKey(children[0].props.eventKey);
        }
        else {
            setKey(children.props.eventKey);
        }
    }
    else {
        if (_.isArray(children)) {
            if (!children.find(ch => (ch && ch.props.eventKey === key && !ch.props.disabled))) {
                setKey(children.find(ch => (ch && !ch.props.disabled)).props.eventKey);
            }
        }
        else if (children.props.eventKey !== key) {
            setKey(children.props.eventKey);
        }
    }

    const onControlledSelect = (k) => {
        setKey(k);
        if (onSelect)
            return onSelect(k);
    };

    return (
        <div ref={ref} className='vtabs-container' >
            <Tabs className='vtabs'
                onSelect={onControlledSelect}
                activeKey={key}
                onClick={() => setShowMessage(disableAllTabs && !showMessage)}>
                {isArray(children) ? children.map(child => {
                    return child && prepareChild(child, disableAllTabs);
                })
                    :
                    prepareChild(children, disableAllTabs)
                }
            </Tabs>
            <VOverlay
                parentRef={ref}
                show={showMessage && disableAllTabs && disabledAllMessage !== undefined && disabledAllMessage.length > 0}
                message={disabledAllMessage}
                onHide={() => setShowMessage(false)} />
        </div>
    );
};

export const VTab = ({ children, ...rest }) => {
    return (
        <Tab {...rest}>
            {children}
        </Tab>
    );
};

export default VTabs;