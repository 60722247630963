import React from 'react';
import _, { isEmpty } from 'lodash';

import history from '../../history';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VDropdown from '../../components/VDropdown/VDropdown';
import { extractDate, getDayAheadForGivenDate} from '../../helpers/generalHelper';
import { handleApiError, alertError, alertSuccess, specifyErrorMessage } from '../../helpers/errorHelper';
import { portalMessages } from '../../helpers/portalMessages';
import { getCounterPartyDailyEnergyCosts, getCounterPartyDailyEnergyCostsFile, sendCounterPartyDailyEnergyCostsFile } from '../../apis/vitusApi';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorageHelper';
import FileSaver from 'file-saver';

class DailyEnergyCosts extends React.Component {

    spinner = new SpinnerManager(history.location.pathname);
    
    dailyEnergyCostHeaderList = ['Exchange', 'Bank Info', 'Daily Total Energy Amounts (Mwhs)',
                                 'Total Energy Cost / Revenue (€)', 'VAT Inc. Total Energy Cost / Revenue (€)']                             
    localDailyEnergyCostHeaderList = ['Exchange', 'Bank Info', 'Daily Total Energy Amounts (Mwhs)',
    'Total Energy Cost / Revenue Local Currency',
    'Total Energy Cost / Revenue Local Currency w/VAT',
    'Turnover and Settlement Fee w/VAT', 'Total Energy Cost w/VAT']
    
    counterParties = {
        Energovia: {label: "Energovia", name: "Energovia"}
    }

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED,
        NotFound: portalMessages.PARAMETERS.NO_OFFER_PARAMETERS_FOUND,
        MissingParameter: {
            "exchange_date": portalMessages.DATE_SELECTION,
            "counter_party": portalMessages.SELECT_COUNTER_PARTY,
        },
        InvalidData: {
            "exchange_date": portalMessages.INVALIED_REQUESTED_DATE,
            "buy": portalMessages.EXCHANGE_RESULTS.INVALID_BUY_VALUES,
            "sell": portalMessages.EXCHANGE_RESULTS.INVALID_SELL_VALUES,
        }
    };

    state = {
        selectedDate: this.getDefaultDate(),
        counterPartyOptions: [],
        selectedCounterParty: getLocalStorage('dailyEnergyCosts', 'dropdown', 'selectedCounterParty') || null,
        selectedCounterPartyOption: null,
        dailyEnergyCostsList: [],
        activeFilter: {
            requestedDate: null,
            counter_party: this.storedActiveFilter?.counter_party
        },
        activeFilterToDisplay: [],
        damDate: null,
        idmDate: null,
        bgnRate: null,
        ronRate: null
    }

    getDefaultDate(){
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        return today.setDate(today.getDate());
    }

    getCounterPartyOptions() {
        return Object.keys(this.counterParties).map(c => { return { value: this.counterParties[c].name, label: this.counterParties[c].label } })
    }

    showErrorMessage(error) {
        const { message } = specifyErrorMessage(error, this.errorMessages);

        if (message)
            alertError(message);
    }

    showSuccessMessage(message) {
        alertSuccess(message);
    }

    componentDidMount() {

        let selectedCounterPartyOption;
        const counterPartyOptions = this.getCounterPartyOptions();
        
        if (this.state.activeFilter.counter_party) {
            selectedCounterPartyOption = counterPartyOptions.filter(e => e.label === this.state.activeFilter.counter_party);

            if (selectedCounterPartyOption.length !== 0)
                selectedCounterPartyOption = selectedCounterPartyOption[0];
            else
                selectedCounterPartyOption = "";
        }

        if (!selectedCounterPartyOption) {
            selectedCounterPartyOption = counterPartyOptions[0];
        }

        this.setState({ counterPartyOptions, selectedCounterPartyOption});
        
        const filter = {
                requested_date: extractDate(this.state.selectedDate),
                counter_party: selectedCounterPartyOption.value
        };
        
        this.refreshDailyEnergyCostsAsync(filter);
        
    }

    onShowButtonClick = () => {

        if (!this.state.selectedDate) {
            this.showErrorMessage(portalMessages.DATE_SELECTION);
            return;
        }

        if (!this.state.selectedCounterPartyOption || isEmpty(this.state.selectedCounterPartyOption)) {
            this.showErrorMessage(portalMessages.SELECT_COUNTER_PARTY);
            return;
        }

        const filter = {
            requested_date: extractDate(this.state.selectedDate),
            counter_party: this.state.selectedCounterPartyOption.value,
        };

        this.refreshDailyEnergyCostsAsync(filter);

    }

    refreshDailyEnergyCostsAsync = async (filter) => {
        if (!filter)
            filter = {
                requested_date: this.state.activeFilter.requestedDate,
                counter_party: this.state.activeFilter.counter_party
            };

        return await this.getDailyEnergyCostsAsync(filter)
    }

    getDailyEnergyCostsAsync = async (filter) => {

        this.spinner.showSpinner('getDailyEnergyCosts');

        try {

            let response;

            try {
                response = await getCounterPartyDailyEnergyCosts(filter);
            } catch (error) {
                handleApiError(error);
                return;
            }

            if (response.data.success) {
                
                if (response.data.success.daily_energy_costs_list.length === 0){
                    let dam_date = response.data.success.dam_date;
                    let idm_date = response.data.success.idm_date;
                    alertSuccess(portalMessages.DAILY_ENERGY_COSTS.NO_DAILY_ENERGY_COSTS.replace('[DAM_DATE]', dam_date).replace('[IDM_DATE]', idm_date))
                    this.setState({
                        dailyEnergyCostsList: [],
                        damDate: response.data.success.dam_date,
                        idmDate: response.data.success.idm_date,
                        bgnRate: response.data.success.bgn_rate,
                        ronRate: response.data.success.ron_rate,                    
                        activeFilter: {
                            requestedDate: filter.requested_date,
                            counter_party: filter.counter_party,
                        },
                        activeFilterToDisplay: [
                            { label: "Date", value: filter.requested_date },
                            { label: "Counter Party", value: filter.counter_party }
                        ]
                    }, () => {
                        setLocalStorage('dailyEnergyCosts', 'filters', 'activeFilter',
                            { requestedDate: filter.requested_date, counter_party: filter.counter_party});
                    });

                }
                else{
                    this.setState({
                        dailyEnergyCostsList: response.data.success.daily_energy_costs_list,
                        damDate: response.data.success.dam_date,
                        idmDate: response.data.success.idm_date,
                        bgnRate: response.data.success.bgn_rate,
                        ronRate: response.data.success.ron_rate,                    
                        activeFilter: {
                            requestedDate: filter.requested_date,
                            counter_party: filter.counter_party,
                        },
                        activeFilterToDisplay: [
                            { label: "Date", value: filter.requested_date },
                            { label: "Counter Party", value: filter.counter_party }
                        ]
                    }, () => {
                        setLocalStorage('dailyEnergyCosts', 'filters', 'activeFilter',
                            { requestedDate: filter.requested_date, counter_party: filter.counter_party});
                    });
                }
            } else if (response.data.error) {
                this.setState({
                    dailyEnergyCostsList: [],
                    damDate: null,
                    idmDate: null,
                    bgnRate: null,
                    ronRate: null,                    
                    activeFilter: {
                        requestedDate: filter.requested_date,
                        counter_party: filter.counter_party,
                    },
                    activeFilterToDisplay: [
                        { label: "Date", value: filter.requested_date },
                        { label: "Counter Party", value: filter.counter_party }
                    ]
                }, () => {
                    setLocalStorage('dailyEnergyCosts', 'filters', 'activeFilter',
                        { requestedDate: filter.requested_date, counter_party: filter.counter_party});
                });

                this.showErrorMessage(response.data.error);

            } else {
                alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);
            }

        } catch (error) {
            alertError(portalMessages.UNEXPECTED_ERROR_OCCURED);

        } finally {
            this.spinner.hideSpinner('getDailyEnergyCosts');
        }
    }

    renderDailyEnergyCosts() {
        
        if (this.state.dailyEnergyCostsList.length > 0){
            return (
                <div>
                    <div style={{textAlign: 'center', backgroundColor: '#a5a5a5', marginTop: '30px', maxWidth: '1735px', color: 'white'}}>
                    {'Total Balance (DAM + IDM)'}
                    </div>
                    {this.renderDailyEnergyCostsTables(this.state.dailyEnergyCostsList)}
                    {this.renderCurrencyDateTable()}
                </div>
            );
        }
    }

    renderCurrencyDateTable(){

        return (<div style={{display: 'grid', marginBottom: '20px', marginTop: '20px'}}>

                    {<table className='table-striped' style={{border: 'solid 3px #dadada', maxWidth: '375px', maxHeight: '20px'}}>
                        <tbody>
                            <tr>
                            <td style={{textAlign: 'center', maxWidth: '2px', wordWrap: 'break-word', backgroundColor: '#dae3f3', border: 'solid 3px #979797'}}>{'DAM Date'}</td>
                            <td style={{textAlign: 'center', maxWidth: '5px', wordWrap: 'break-word'}}>{this.state.damDate}</td>
                            </tr>
                            <tr>
                            <td style={{textAlign: 'center', maxWidth: '2px', wordWrap: 'break-word', backgroundColor: '#dae3f3', border: 'solid 3px #979797'}}>{'IDM Date'}</td>
                            <td style={{textAlign: 'center', maxWidth: '5px', wordWrap: 'break-word'}}>{this.state.idmDate}</td>

                            </tr>
                        </tbody>
                    </table>}
                    {<table className='table-striped' style={{border: 'solid 3px #dadada', maxWidth: '200px', maxHeight: '20px'}}>
                        <tbody>
                            <tr>
                                <td style={{textAlign: 'center', maxWidth: '5px', wordWrap: 'break-word', backgroundColor: '#dae3f3', border: 'solid 3px #979797'}}>{'EUR/BGN'}</td>
                                <td style={{textAlign: 'center', maxWidth: '5px', wordWrap: 'break-word'}}>{this.state.bgnRate}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'center', maxWidth: '5px', wordWrap: 'break-word', backgroundColor: '#dae3f3', border: 'solid 3px #979797'}}>{'EUR/RON'}</td>
                                <td style={{textAlign: 'center', maxWidth: '5px', wordWrap: 'break-word'}}>{this.state.ronRate}</td>
                            </tr>         
                        </tbody>
                    </table>}
                </div>
        )
    }

    renderHeaders(headerList){
        return (
            <React.Fragment>
                <tr>
                {
                   headerList.map(h => {
                    if (h === 'Exchange'){
                        return (
                            <th style={{padding: '2px 8px', borderBottom: 'solid 3px #979797', borderRight: 'solid 3px #979797', backgroundColor: '#8faadc', textAlign: 'center', maxWidth: '10px', wordWrap: 'break-word'}}>
                                {h}
                            </th>
                        )
                    }
                    else{
                        return (
                            <th style={{padding: '2px 8px', borderBottom: 'solid 3px #979797', borderRight: 'solid 3px #979797', backgroundColor: '#dae3f3', textAlign: 'center', maxWidth: '10px', wordWrap: 'break-word'}}>
                                {h}
                            </th>
                        )    
                    }
                   })
                }
                </tr>         
            </React.Fragment>
            
        );
    }

    renderRows(data, headerList){
        let exchanges = Object.keys(data.reduce((r, {Exchange}) => (r[Exchange]='', r), {}))
        return (exchanges.map(e => {
            let exchange_data = data.find(d => d.Exchange === e);
            return (<tr>
                {headerList.map(h => {
                    if (h === 'Exchange' || h === 'Bank Info'){
                        if (h === 'Exchange'){
                            return (<td style={{backgroundColor: '#fbe5d6', textAlign: 'center', maxWidth: '5px', wordWrap: 'break-word'}}>
                                {exchange_data[h]}
                                </td>)        
    
                        }
                        else{
                            return (<td style={{textAlign: 'center', maxWidth: '5px', wordWrap: 'break-word'}}>
                                {exchange_data[h]}
                                </td>)        
    
                        }
                    }
                    else{
                        if (h !== 'Daily Total Energy Amounts (Mwhs)'){
                            if (h === 'Total Energy Cost w/VAT'){
                                return (<td style={{textAlign: 'center', maxWidth: '5px', wordWrap: 'break-word', fontWeight: 'bold'}}>
                                {exchange_data[h].toLocaleString()}
                                </td>)
                            }
                            return (<td style={{textAlign: 'center', maxWidth: '5px', wordWrap: 'break-word'}}>
                            {`${exchange_data['Currency']} ` + exchange_data[h].toLocaleString()}
                            </td>)
                        }
                        else{
                            return (<td style={{textAlign: 'center', maxWidth: '5px', wordWrap: 'break-word'}}>
                            {exchange_data[h].toLocaleString()}
                            </td>)
                        }
        
                    }
                })}
            </tr>)
            
        }))
    }

    renderDailyEnergyCostsTables(data){
        
        let types = Object.keys(data.reduce((r, {Type}) => (r[Type]='', r), {}))
        return(
            types.map(t => {
                let table_data = data.filter(d => d.Type === t);
                if (t === 'Non Local'){
                    return (
                        <div style={{display: 'grid', marginTop: '30px'}}>

                        <table className='table-striped' style={{border: 'solid 3px #dadada', maxWidth: '1240px'}}>
                            <thead>
                                {this.renderHeaders(this.dailyEnergyCostHeaderList)}
                            </thead>
                            <tbody>
                                {this.renderRows(table_data, this.dailyEnergyCostHeaderList)}
                            </tbody>
                        </table>
                        </div>
                        
                    )        
                }
                else{
                    return (
                        <div style={{display: 'grid', marginTop: '30px'}}>

                        <table className='table-striped' style={{border: 'solid 3px #dadada', maxWidth: '1735px'}}>
                            <thead>
                                {this.renderHeaders(this.localDailyEnergyCostHeaderList)}
                            </thead>
                            <tbody>
                                {this.renderRows(table_data, this.localDailyEnergyCostHeaderList)}
                            </tbody>
                        </table>
                        </div>
                    )        
                }
            })
            
            
        )
    }

    exportDailyEnergyCosts = async () => {
        const spinnerKey = this.spinner.showSpinner();

        let response;
        let body = {'counter_party': this.state.selectedCounterPartyOption.value, 'requested_date': extractDate(this.state.selectedDate), 
                    'daily_energy_cost_data': {'cost_data': this.state.dailyEnergyCostsList, 'cost_header_list': this.dailyEnergyCostHeaderList,
                                               'cost_local_header_list': this.localDailyEnergyCostHeaderList, 'dam_date': this.state.damDate, 'idm_date': this.state.idmDate,
                                               'bgn_rate': this.state.bgnRate, 'ron_rate': this.state.ronRate}}; 

        try {
            response = await getCounterPartyDailyEnergyCostsFile(body);
            
            const filename = `Energy Costs / Revenues based on Exchanges ${extractDate(this.state.selectedDate)}.xlsx`;
            const contentType = response.headers["content-type"];

            const blob = new Blob([response.data], { type: contentType });

            if (contentType === 'application/json') {
                //const error = blob.text();
                return;
            }
            
            FileSaver.saveAs(blob, filename)

            return;
        } catch (error) {
            handleApiError(error);
            return false;
        }
        finally{
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    sendDailyEnergyCosts = async () => {
        const spinnerKey = this.spinner.showSpinner();

        let response;
        const filename = `Energy Costs and Revenues ${extractDate(this.state.selectedDate)}.xlsx`;
        let body = {'counter_party': this.state.selectedCounterPartyOption.value, 'requested_date': extractDate(this.state.selectedDate), 
                    'daily_energy_cost_data': {'cost_data': this.state.dailyEnergyCostsList, 'cost_header_list': this.dailyEnergyCostHeaderList,
                                               'cost_local_header_list': this.localDailyEnergyCostHeaderList, 'dam_date': this.state.damDate, 'idm_date': this.state.idmDate,
                                               'bgn_rate': this.state.bgnRate, 'ron_rate': this.state.ronRate},
                    'daily_energy_costs_file_name': filename}; 
        
        try {
            response = await sendCounterPartyDailyEnergyCostsFile(body);
            
            if (response.data.success){
                alertSuccess(portalMessages.DAILY_ENERGY_COSTS.SEND_DAILY_ENERGY_COSTS_SUCCESSFUL);
            }
            else{
                alertError(portalMessages.DAILY_ENERGY_COSTS.SEND_DAILY_ENERGY_COSTS_ERROR);
            }
            return;
        } catch (error) {
            handleApiError(error);
            return false;
        }
        finally{
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Daily Energy Costs / Revenues based on Exchanges">
                    <VFilterContainer 
                        showActiveFilter 
                        activeFilter={this.state.activeFilterToDisplay}
                    >
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                            </div>
                            <div>
                                <VDatePicker
                                    selectedDate={this.state.selectedDate}
                                    onSelectedDateChange={(selectedDate) => this.setState({ selectedDate })}
                                    maxDate={this.getDefaultDate()}
                                />
                            </div>
                        </div>
                       
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Counter Party
                            </div>
                            <div>
                                <VDropdown
                                    width="large"
                                    options={this.state.counterPartyOptions}
                                    value={this.state.selectedCounterPartyOption}
                                    onSelectedOptionChange={(selectedCounterPartyOption) => {
                                        this.setState({
                                             selectedCounterParty: selectedCounterPartyOption.value,
                                             selectedCounterPartyOption: selectedCounterPartyOption
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={this.onShowButtonClick}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                        </div>
                    </VFilterContainer>
                    {
                        <React.Fragment>
                            <VMainContainer>
                            <div className='col-12' style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                                <button className="btn v-button v-tab-button"
                                    onClick={() => this.exportDailyEnergyCosts()}>
                                    <i aria-hidden="true"/>
                                        Export XLSX
                                </button>
                                <button className="btn v-button v-tab-button"
                                    onClick={() => this.sendDailyEnergyCosts()}>
                                    <i aria-hidden="true"/>
                                        Send PDF to Slack
                                </button>
                            </div>
                            {this.renderDailyEnergyCosts()}
                                
                            </VMainContainer>
                        </React.Fragment>
                    }
                </VContentContainer>
            </React.Fragment>
        );
    }


}

export default DailyEnergyCosts;