import React from 'react';
import VBaseModal from '../VBaseModal/VBaseModal';

const SendAuctionEmailsModal = ({ show, onHide, counterParty, onSendAllBids, onSendOnlyNewBids }) => {
    return (
        <VBaseModal
            dialogClassName="v-modal-60w"
            show={show}
            onHide={onHide}
            title='Send Bids'
            closeButton={false}
            body={
                <div className='row'>
                    <div className='col v-modal-error-icon-col'>
                        <div className='v-modal-error-icon'>
                            <i className='fa fa-question fa-3x' />
                        </div>
                    </div>
                    <div className='col-10'>
                        Which bids would you like to send to <strong>{counterParty}</strong>?
                            </div>
                </div>
            }
            actions={
                <React.Fragment>
                    <button
                        className="btn v-cancel-button"
                        onClick={onHide}>
                        Cancel
                            </button>
                    <button
                        className="btn v-button"
                        onClick={onSendOnlyNewBids}>
                        Send only new bids
                            </button>
                    <button
                        className="btn v-button"
                        onClick={onSendAllBids}>
                        Send all bids even if already sent
                            </button>
                </React.Fragment >
            }
        />
    );
}

export default SendAuctionEmailsModal;