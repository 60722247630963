import store from '../../../store';
import { SHOW_SPINNER, HIDE_SPINNER } from '../../../actions/types';
import { generateUuid } from '../../utils/common';


export class SpinnerManager {

    constructor(path) {
        this.path = path;
    }

    showSpinner(key) {
        if (!key)
            key = generateUuid();
        store.dispatch({ type: SHOW_SPINNER, payload: { path: this.path, id: this.path + key } });
        return key;
    }

    hideSpinner(key) {
        store.dispatch({ type: HIDE_SPINNER, payload: { path: this.path, id: this.path + key } });
    }

}; 