import React from 'react';
import './VContentContainer.css';

import VBaseModal from '../../modals/VBaseModal/VBaseModal';


const VContentContainer = props => {
    const [showInfo, setShowInfo] = React.useState(false);

    return (
        <React.Fragment>
            <div className="v-base-page">
                <div className="v-content-container">
                    <div className="card">
                        <div className="card-header">
                            {props.title}
                            {
                                props.info &&
                                <div className='pull-right'>
                                    <span className="text-secondary fa-stack v-font-size"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setShowInfo(true)}>
                                        <i aria-hidden="true" className="fa fa-square-o fa-stack-2x" />
                                        <i aria-hidden="true" className="fa fa-info fa-stack-1x" />
                                    </span>
                                </div>
                            }
                        </div>
                        <div className="card-body">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            <VBaseModal
                show={showInfo}
                onHide={() => setShowInfo(false)}
                title='Info'
                body={props.info} />
        </React.Fragment>
    );
};

export default VContentContainer;