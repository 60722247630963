import React from 'react';
import VFilterContainer from '../../../components/VFilterContainer/VFilterContainer';
import VTable from '../../../components/VTable/VTable';

class TestCBBreakdown extends React.Component {
    directions = ['TR-BG', 'TR-GR', 'TR-GR-BG', 'GR-BG', 'BG-GR'];

    getRandom() {
        return Math.floor(Math.random() * 10000) * [1, -1][Math.floor(Math.random() * 2)] + 1;
    }

    getCBBreakdownTable() {
        const items = {
            headers: [
                ['', 'Date', '', '01.02.2021', 'EUR/TRY', 'X.X'],
                ['', 'Hourly Cap (MW)', 'Capacity Cost', 'Daily Capacity Cost', 'Flow Pnl', 'Total Pnl']
            ],
            values: [...this.directions.map(d => {
                return [d, this.getRandom(), this.getRandom(), this.getRandom(), this.getRandom(), this.getRandom()];
            })]
        };

        return (
            <VTable
                title='Summary'
                items={items}
                showTotal
                readonlyColumnIndices={[0]}
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
            />
        );
    }

    getFilters() {
        return (
            <VFilterContainer>
                <div className='container'>
                    <div className='row'>
                        <div className="v-filter-label v-label">
                            ???
                        </div>
                    </div>
                </div>
            </VFilterContainer>
        )
    }

    getContent() {
        return (
            <div className='v-infinite-width v-table-list'>
                    {this.getCBBreakdownTable()}
                </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.getFilters()}
                {this.getContent()}
            </React.Fragment>
        )
    }
}

export default TestCBBreakdown;