import './VAlertList.css';
import React from 'react';
import AlertList from "./react-bs-notifier/alert-list";
import { connect } from 'react-redux';
import { removeAlert } from '../../actions';

//Modified component:
//https://github.com/chadly/react-bs-notifier

const VAlertList = (props) => {
    const alertTimeout = 5000;

    return (
        <div className='v-alert-list'>
            <AlertList
                position='top-right'
                alerts={props.alerts}
                timeout={alertTimeout}
                dismissTitle="Close"
                onDismiss={props.removeAlert}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        alerts: state.alerts.alerts
    };
};

export default connect(mapStateToProps, { removeAlert })(VAlertList);