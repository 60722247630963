import React, { useState } from 'react';

import TBaseModal from '../../components/TBaseModal/TBaseModal';
import TMessageBox from '../../components/TMessageBox/TMessageBox';
import { alertError } from '../../utils/errorHandler';
import { messages } from '../../utils/messages';


const MAX_BOOK_NAME_LENGTH = 50;
const MAX_BOOK_DESCRIPTION_LENGTH = 200;

const BookDetailModal = props => {

    const [bookName, setBookName] = useState(props.bookName);
    const [bookDescription, setBookDescription] = useState(props.bookDescription);

    const validateInputs = () => {
        if (!bookName) {
            alertError(messages.BOOK.EMPTY_BOOK_NAME);
            return;
        }

        props.onSaveBook(bookName, bookDescription);
    }

    return (
        <TBaseModal
            dialogClassName="t-modal-30w"
            show={props.show}
            onHide={props.onCancel}
            title="Book Details"
            closeButton={false}
            body={
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <label className="t-font f-font-size">Book Name</label>
                        </div>
                        <div className="col-8">
                            <input className="t-input" maxLength={MAX_BOOK_NAME_LENGTH} type="text" value={bookName} onChange={(e) => setBookName(e.target.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className="t-font f-font-size">Book Description</label>
                        </div>
                        <div className="col-8">
                            <TMessageBox
                                showLimit
                                defaultValue={bookDescription}
                                onChange={(bd) => setBookDescription(bd)}
                                maxLength={MAX_BOOK_DESCRIPTION_LENGTH}
                            />
                        </div>
                    </div>
                </div>
            }
            actions={
                <React.Fragment>
                    <button
                        type="button"
                        className="btn t-gray-button"
                        onClick={props.onCancel}>
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn t-orange-button"
                        onClick={validateInputs}>
                        Save
                    </button>
                </React.Fragment >
            }
        />
    );
}

BookDetailModal.defaultProps = {
    bookName : '',
    bookDescription: ''
}

export default BookDetailModal;