import React from 'react';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VDropdown from '../../components/VDropdown/VDropdown';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import {
    deleteNominations,
    getBorders,
    getLtNominations,
    saveNominations,
    sendNominations
} from '../../apis/vitusApi';
import history from '../../history';
import { alertError, alertSuccess, alertWarning, handleApiError, specifyErrorMessage } from '../../helpers/errorHelper';
import { portalMessages } from '../../helpers/portalMessages';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorageHelper';
import {
    extractDate,
    testingEnvironment,
    getCompanyOptions,
    isCompanySelectable 
} from '../../helpers/generalHelper';
import { isEmpty, orderBy, sortBy } from 'lodash';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import { sortNominationColumns } from '../PhysicalFlows/flowUtils';
import VTable from '../../components/VTable/VTable';
import VVerticalTabs, { VVerticalTab } from '../../components/VVerticalTabs/VVerticalTabs'
import AddDirectionModal from '../../modals/AddDirectionModal/AddDirectionModal';
import { createExcelWithMultipleSheets } from '../../helpers/excelHelper';
import ConfirmationModal from '../../modals/ConfirmationModal/ConfirmationModal';

/*
AuctionPeriodTypes
1 => Daily
2 => Monthly
3 => Yearly
*/

class LTPhysicalFlows extends React.Component {
    spinner = new SpinnerManager(history.location.pathname);
    storedActiveFilter = getLocalStorage('ltPhysicalFlows', 'filters', 'activeFilter') || {};
    othersHeader = "Others";
    headerClasses = ['v-colored-header-v1', 'v-colored-header-v2'];
    ltSpecialPlatforms = ['ESO', 'TCAT'];
    ltSpecialDirections = ['TR-BG', 'BG-TR'];
    defaultSpecialPeriod = "Monthly";
    longTermPeriodName = 'longterm';

    columns = {
        hour: { key: "hour", title: "Hour" },
        cet: { key: "cet", title: "Cet" },
        capacity: { key: "capacity", title: "Capacity" },
        mwh: { key: "mwh", title: "MWh" },
        max: { key: "max", title: "Max" },
        capacityId: { key: "capacityId", title: "Capacity Id" },
        total: { key: "total", title: "Total" },
    }

    counterParties = {
        axpo: {
            specialTable: true
        }
    }

    state = {
        showNeedsRefresModal: false,
        selectedDate: this.getDefaultDate(),
        activeFilter: {
            requestedDate: null,
            counterParty: this.storedActiveFilter?.counterParty,
        },
        activeFilterToDisplay: [],
        counterParties: {},
        counterPartyOptions: [],
        selectedCounterPartyOption: {},
        maxCapacities: {},
        editingNominations: false,
        newNomination: null,
        inconsistentDataCaught: false,
        expandCapacityIds: false,
        noOperationMailConfirmed: false,
        showNoOperationMailModal: false,
    }

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Default)',
        InconsistentData: portalMessages.INCONSISTENT_DATA,
    };

    showErrorMessage(error, operationName) {
        const { message, errorType } = specifyErrorMessage(error, this.errorMessages, operationName);

        if (errorType === 'InconsistentData' && !this.state.inconsistentDataCaught)
            this.setState({ inconsistentDataCaught: true });

        if (message)
            alertError(message);
    }

    componentDidMount() {
        const spinnerKey = this.spinner.showSpinner();

        getBorders({ period: this.longTermPeriodName }).then(response => {
            if (response.data.success) {
                const counterParties = {};

                response.data.success.borders.forEach(b => {
                    b.directions.forEach(d => {
                        d.counterParties.forEach(c => {
                            if (!counterParties[c])
                                counterParties[c] = [];
                            counterParties[c].push(d.direction);
                        });
                    });
                });

                const counterPartyOptions = this.getCounterPartyOptions(counterParties);

                let selectedCounterPartyOption;

                const counterParty = counterPartyOptions.find(c => c.value === this.state.activeFilter.counterParty);

                if (this.state.activeFilter?.counterParty && counterParty)
                    selectedCounterPartyOption = counterParty;
                else
                    selectedCounterPartyOption = counterPartyOptions[0];

                this.setState({
                    counterParties,
                    counterPartyOptions,
                    selectedCounterPartyOption
                });

                const filter = {
                    requested_date: extractDate(this.state.selectedDate),
                    counter_party: selectedCounterPartyOption.value,
                };

                this.refreshNominationsAsync(filter);

            } else {
                this.setState({ showNeedsRefresModal: true });
            }

            if (response.data.error)
                this.showErrorMessage(response.data.error);
        }, error => {
            handleApiError(error);
            this.setState({ showNeedsRefresModal: true });
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    async refreshNominationsAsync(filter) {
        if (!filter)
            filter = {
                requested_date: this.state.activeFilter.requestedDate,
                counter_party: this.state.activeFilter.counterParty,
            };

        return await this.getNominationsAsync(filter)
    }

    async getNominationsAsync(filter) {
        const spinnerKey = this.spinner.showSpinner();

        try {
            let response;

            try {
                response = await getLtNominations(filter);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                const maxCapacities = {};

                response.data.success.max_capacity_list.forEach(m => {
                    maxCapacities[m.direction] = m.capacity;
                });

                this.setState({
                    maxCapacities,
                    nominationList: sortNominationColumns(response.data.success.nomination_list),
                    distributionList: response.data.success.distribution_list,
                    mailSent: response.data.success.mail_sent,
                    activeFilter: {
                        requestedDate: filter.requested_date,
                        counterParty: filter.counter_party,

                    },
                    activeFilterToDisplay: [
                        { label: "Date", value: filter.requested_date },
                        { label: "Counter Party", value: filter.counter_party }
                    ]
                }, () => {
                    setLocalStorage('ltPhysicalFlows', 'filters', 'activeFilter',
                        { counterParty: filter.counter_party });
                });
            } else if (!response.data.error) {
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Nominations)');
            }

            if (response.data.error) {
                this.showErrorMessage(response.data.error);
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Nominations)');
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    showButtonEnabled() {
        return !this.state.editingNominations;
    }

    exportAllEnabled() {
        return this.showButtonEnabled()
            && (
                !isEmpty(this.state.nominationList)
                || (!this.getActiveCounterPartyConfig()?.specialTable
                    && !isEmpty(this.state.distributionList))
            );
    }

    sendMailEnabled() {
        return this.editEnabled()
            && this.showButtonEnabled();
    }

    getCounterPartyOptions(counterParties) {
        return Object.keys(counterParties).sort().map(c => { return { value: c, label: c } })
    }

    getDateWithoutHours(daysToAdd) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        today.setDate(today.getDate() + daysToAdd);
        return today;
    }

    getDefaultDate() {
        return this.getDateWithoutHours(2);
    }

    getMaxEditableDate() {
        return this.getDateWithoutHours(7);
    }

    getMinEditableDate() {
        return this.getDateWithoutHours(1);
    }

    onShowButtonClick() {
        if (!this.state.selectedDate) {
            this.showErrorMessage(portalMessages.DATE_SELECTION);
            return;
        }

        if (!this.state.selectedCounterPartyOption || isEmpty(this.state.selectedCounterPartyOption)) {
            this.showErrorMessage(portalMessages.SELECT_COUNTER_PARTY);
            return;
        }

        const filter = {
            requested_date: extractDate(this.state.selectedDate),
            counter_party: this.state.selectedCounterPartyOption.value,
        };

        this.refreshNominationsAsync(filter);
    }

    onClearButtonClick() {
        this.setState({ selectedDate: this.getDefaultDate() });
    }

    getActiveCounterPartyConfig() {
        const counterParty = this.state.activeFilter?.counterParty?.toLowerCase();

        if (!counterParty)
            return;
        return this.counterParties[counterParty];
    }

    onAddDirectionButtonClick() {
        if (!this.state.activeFilter?.counterParty)
            return;

        this.setState({ addDirectionModal: { show: true, counterParty: this.state.activeFilter?.counterParty } });
    }

    onCancelAddDirection() {
        this.setState({ addDirectionModal: { counterParty: "", show: false } });
    }

    editEnabled() {
        return this.dateIsEditable() && !this.state.editingNominations;
    }

    dateIsEditable() {
        return this.state
            && this.state.activeFilter
            && this.state.activeFilter.requestedDate
            && (testingEnvironment() || this.state.activeFilter.requestedDate >= extractDate(this.getMinEditableDate()));
    }

    convertNominationJsonToTable(direction, data) {
        const headers = [
            [this.columns.hour.title, this.columns.capacity.title],
            [this.columns.cet.title, this.columns.mwh.title],
            [this.columns.max.title, `${this.state.maxCapacities[direction] ?? 0} MW`]
        ];

        const values = data.map(d => { return [d.hour + 1, d.value] });

        return { headers, values }
    }

    onAddDirection(selectedDirection) {
        const newNomination = {
            headers: [
                [this.columns.hour.title, this.columns.capacity.title],
                [this.columns.cet.title, this.columns.mwh.title],
                [this.columns.max.title, `${this.state.maxCapacities[selectedDirection]} MW`]
            ],
            values: [],
            title: selectedDirection
        };

        for (let i = 0; i < 24; i++)
            newNomination.values.push([i + 1, 0]);

        this.setState({ newNomination });

        this.onCancelAddDirection();
    }

    onOpenNominationsEditMode() {
        this.setState({ editingNominations: true });
    }

    onCloseNominationsEditMode() {
        this.setState({ newNomination: null, editingNominations: false });

        this.updateIfInconsistent();
    }

    updateIfInconsistent = async () => {
        if (!this.state.inconsistentDataCaught)
            return;

        const spinnerKey = this.spinner.showSpinner();

        try {
            await this.refreshNominationsAsync();

            this.setState({ inconsistentDataCaught: false });
        }
        finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    convertNominationTableToJson(newData, oldJsondata) {
        const { data, ...rest } = oldJsondata;

        const newJsonData = {
            ...rest,
            previous_data: data ? data : []
        }

        newJsonData.updated_data = newData.values.map(v => {
            return {
                hour: v[0] - 1,
                value: v[1]
            };
        });

        return newJsonData;
    }

    validateNominationTableNewValues(direction, newData) {
        const maxCapacity = this.state.maxCapacities[direction];
        let totalNewCapacity = 0;

        for (let i = 0; i < newData.values.length; i++) {
            const currentValue = newData.values[i][1];
            if (isNaN(currentValue))
                return portalMessages.LT_PHYSICAL_FLOWS.NOM_NUMBER;
            if (currentValue < 0)
                return portalMessages.LT_PHYSICAL_FLOWS.NOM_NO_NEGATIVE;
            if (currentValue > maxCapacity)
                return portalMessages.LT_PHYSICAL_FLOWS.TOO_BIG_NOM;

            const numParts = currentValue.toString().split('.');

            if (numParts.length > 1 && Number(numParts[1]) !== 0)
                return portalMessages.LT_PHYSICAL_FLOWS.CAP_INTEGER;

            totalNewCapacity += currentValue;
        }

        if (totalNewCapacity === 0)
            return portalMessages.LT_PHYSICAL_FLOWS.ALL_CAP_ZERO;

        return this.validateTotals(direction, newData).message;
    }

    getInitial24HoursDict() {
        const empty24Hours = {};

        for (let i = 0; i < 24; i++)
            empty24Hours[i] = 0;

        return empty24Hours;
    }

    validateTotals(direction, newData) {
        const affectedBorders = {};
        const countries = direction.split('-');

        countries.forEach((c, idx) => {
            if (idx < countries.length - 1) {
                const currentBorder = `${countries[idx]}-${countries[idx + 1]}`;
                affectedBorders[currentBorder] = this.state.maxCapacities[currentBorder];
            }
        });

        const empty24Hours = this.getInitial24HoursDict();

        const borderTotals = {};

        Object.keys(affectedBorders)
            .forEach(twoCountryBorder => {
                borderTotals[twoCountryBorder] = { ...empty24Hours };

                this.state.nominationList
                    .filter(nom => (!newData || nom.direction !== direction) && nom.direction.includes(twoCountryBorder))
                    .forEach(nom => {
                        nom.data.forEach((row, idx) => {
                            borderTotals[twoCountryBorder][idx] += row.value;
                        })
                    });

                if (newData)
                    newData.values.forEach((newRow, idx) => {
                        borderTotals[twoCountryBorder][idx] += newRow[1];
                    })
            });

        return this.checkTotals(borderTotals);
    }

    checkTotals(borderTotals) {
        let wrongBorders = {};

        for (const border of Object.keys(borderTotals)) {
            const currentMaxCapacity = this.state.maxCapacities[border];
            for (let idx = 0; idx < Object.keys(borderTotals[border]).length; idx++) {
                const total = borderTotals[border][idx];

                if (!currentMaxCapacity && total) {
                    return { message: `${border} ${portalMessages.LT_PHYSICAL_FLOWS.NO_ALLOCATION}` }
                }

                if (currentMaxCapacity < total) {
                    if (!wrongBorders[border])
                        wrongBorders[border] = [];
                    wrongBorders[border].push(idx + 1);
                }
            };
        };

        if (!isEmpty(wrongBorders))
            return {
                wrongBorders,
                message: `${portalMessages.LT_PHYSICAL_FLOWS.EXCEED_MAX} ${Object.keys(wrongBorders).map(w => w + "(" + wrongBorders[w].join(',') + ")").join(', ')}`
            };

        return { message: "" };
    }

    async onSaveNominationsAsync(newData, oldJsondata) {
        let spinnerKey = this.spinner.showSpinner();

        try {
            newData.values = newData.values.map(n => {
                return [n[0], isNaN(n[1]) ? n[1] : Number(n[1])]
            });

            const error = this.validateNominationTableNewValues(oldJsondata.direction, newData);

            if (error) {
                this.showErrorMessage(error);
                return false;
            }

            let body = {
                requested_date: this.state.activeFilter.requestedDate,
                counter_party: this.state.activeFilter.counterParty,
                mail_sent: this.state.mailSent,
                nomination_list: [
                    this.convertNominationTableToJson(newData, oldJsondata),
                    ...this.state.nominationList
                        .filter(n => n.direction !== oldJsondata.direction)
                        .map(nom => {
                            const { data, ...rest } = nom;
                            return { ...rest, previous_data: data, updated_data: data }
                        })
                ],
                period: this.longTermPeriodName
            };

            let response;
            console.log("BODY", body)
            try {
                response = await saveNominations(body);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                await this.refreshNominationsAsync();
                alertSuccess(portalMessages.LT_PHYSICAL_FLOWS.NOMINATION_SAVED);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {}, "editValues");
                return false;
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Save Nominations)');
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    async onNominationTableDeleteAsync(nominationIds) {
        if (isEmpty(nominationIds)) {
            await this.refreshNominationsAsync();
            alertWarning(portalMessages.NO_SAVED);
            return;
        }

        let spinnerKey = this.spinner.showSpinner();

        try {
            let response;

            try {
                response = await deleteNominations({
                    requested_date: this.state.activeFilter.requestedDate,
                    counter_party: this.state.activeFilter.counterParty,
                    nomination_ids: nominationIds
                });

                if (response.data.success) {
                    await this.refreshNominationsAsync();
                    alertSuccess(portalMessages.LT_PHYSICAL_FLOWS.NOM_DELETED_SUCCESS);
                    return true;
                }
                else {
                    this.showErrorMessage(response.data.error || {});
                    return false;
                }

            } catch (error) {
                handleApiError(error);
                return false;
            }
        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Delete Nomination)');
            return;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    cummulateTablesForSheet(tableList, headerSpecifier, jsonToTableConverter) {
        if (!tableList)
            return;

        const allTables = { headers: [], values: [] };

        tableList.forEach(currentTable => {
            const tableData = jsonToTableConverter(currentTable);

            if (!allTables.headers[0])
                allTables.headers[0] = [];

            allTables.headers[0].push(headerSpecifier(currentTable))
            allTables.headers[0].push(...tableData.headers[0].map(() => ""));

            tableData.headers.forEach((headerRow, headerIdx) => {
                if (!allTables.headers[headerIdx + 1])
                    allTables.headers[headerIdx + 1] = [];
                allTables.headers[headerIdx + 1].push(...headerRow);
                allTables.headers[headerIdx + 1].push("");
            });

            tableData.values.forEach((valueRow, valueIdx) => {
                if (!allTables.values[valueIdx])
                    allTables.values[valueIdx] = [];
                allTables.values[valueIdx].push(...valueRow);
                allTables.values[valueIdx].push("");
            });
        });

        return allTables;
    }

    exportAll() {
        const fileName = `LT_Phsical_Flows_${this.state.activeFilter.counterParty}_${this.state.activeFilter.requestedDate}`;

        const sheets = [];

        if (this.getActiveCounterPartyConfig()?.specialTable) {
            sheets.push({
                name: "Nominations",
                data: this.convertSpecialNominationJsonToTable(this.state.nominationList)
            });
        }
        else {
            if (this.state.nominationList && !isEmpty(this.state.nominationList)) {
                const allNoms = this.cummulateTablesForSheet(
                    this.state.nominationList,
                    (nom) => { return nom.direction },
                    (nom) => this.convertNominationJsonToTable(nom.direction, nom.data)
                );

                sheets.push({
                    name: "Nominations",
                    data: allNoms
                });
            }

            if (this.state.distributionList && !isEmpty(this.state.distributionList)) {
                const distributionGroups = this.getDistributionGroups(this.state.distributionList);

                [...Object.keys(distributionGroups)
                    .filter(distGroup => distGroup !== this.othersHeader).sort(),
                this.othersHeader]
                    .forEach(distGroup => {
                        let currentdists;

                        if (distGroup !== this.othersHeader) {
                            currentdists = this.cummulateTablesForSheet(
                                distributionGroups[distGroup],
                                (dis) => { return dis.tso },
                                (dis) => this.convertDistributionJsonToTable(dis.capacity_id, dis.data)
                            );

                            const totalTable = this.getDistributionTotalsTable(distributionGroups[distGroup]);

                            currentdists.headers[0] = [
                                this.columns.total.title,
                                ...totalTable.headers[0].map(() => ""),
                                ...currentdists.headers[0]
                            ];

                            totalTable.headers.forEach((headerRow, headerIdx) => {
                                currentdists.headers[headerIdx + 1] = [...headerRow, "", ...currentdists.headers[headerIdx + 1]]
                            });

                            totalTable.values.forEach((valueRow, valueIdx) => {
                                currentdists.values[valueIdx] = [...valueRow, "", ...currentdists.values[valueIdx]]
                            });
                        }
                        else {
                            currentdists = this.cummulateTablesForSheet(
                                distributionGroups[distGroup],
                                (dis) => { return dis.direction },
                                (dis) => this.convertDistributionJsonToTable(dis.capacity_id, dis.data)
                            );
                        }

                        if (currentdists)
                            sheets.push({
                                name: `Distribution ${distGroup}`,
                                data: currentdists
                            });
                    });
            }
        }

        if (sheets && sheets.length > 0)
            createExcelWithMultipleSheets(fileName, sheets);
        else
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Export All)');
    }

    sendTestMail = async () => {
        return this.sendMail(true);
    }

    sendCounterPartyMail = async () => {
        return this.sendMail(false);
    }

    onCloseNoOpMailConfirmModal() {
        this.setState({
            noOperationMailConfirmed: false,
            showNoOperationMailModal: false
        });
    }

    async onConfirmNoOpMail() {
        this.setState({
            noOperationMailConfirmed: true,
            showNoOperationMailModal: false
        }, async () => await this.state.noOpMailAction());
    }

    getTwoCountryDirections() {
        const twoCountryDirections = this.state.counterParties[this.state.activeFilter.counterParty].filter(d => d.split('-').length === 2);
        return twoCountryDirections;
    }

    sendMail = async (is_test) => {
        const spinnerKey = this.spinner.showSpinner();

        try {
            let no_operation_mail = false;

            const nominationList = this.state.nominationList;

            if (!nominationList || isEmpty(nominationList) || !this.state.nominationList.find(nom => nom.data.find(d => d.value > 0))) {
                no_operation_mail = true;
            }

            if (no_operation_mail) {
                if (!this.state.noOperationMailConfirmed) {
                    this.setState({
                        showNoOperationMailModal: true,
                        noOpMailAction: () => this.sendMail(is_test)
                    });
                    return;
                }
            }
            else {
                const directions = this.getTwoCountryDirections();
                let allWrongBorders = {};
                for (const direction of directions) {
                    const { wrongBorders } = this.validateTotals(direction);
                    if (wrongBorders)
                        allWrongBorders = { ...allWrongBorders, ...wrongBorders }
                }

                if (!isEmpty(allWrongBorders)) {
                    this.showErrorMessage(`${portalMessages.LT_PHYSICAL_FLOWS.EXCEED_MAX} ${Object.keys(allWrongBorders).map(w => w + "(" + allWrongBorders[w].join(',') + ")").join(', ')}`);
                    return;
                }
            }

            const body = {
                requested_date: this.state.activeFilter.requestedDate,
                counter_party: this.state.activeFilter.counterParty,
                mail_sent: this.state.mailSent,
                period: this.longTermPeriodName,
                is_test,
                no_operation_mail,
                nomination_list: nominationList.map(nom => {
                    const { data, ...rest } = nom;
                    return { ...rest, previous_data: data };
                }),
            }

            let response;

            try {
                response = await sendNominations(body);
            } catch (error) {
                handleApiError(error);
                return;
            }

            if (response.data.success) {
                await this.refreshNominationsAsync();
                alertSuccess(portalMessages.MAIL_SENT_SUCCESSFULLY);
            }
            else {
                this.showErrorMessage(response.data.error || {}, "sendMail");
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Send Mail)');
            return;
        }
        finally {
            this.setState({ noOperationMailConfirmed: false });
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    getConfirmationMessage(message, counterParty, direction) {
        return (
            <div className='v-auction-confirmation'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            {message}
                        </div>
                    </div>
                    <div className='row'>
                        <div style={{ whiteSpace: 'nowrap' }} className='col'>
                            Counter Party:
                        </div>
                        <div className='col-7'>
                            <strong>{counterParty}</strong>
                        </div>
                    </div>
                    {
                        direction &&
                        <div className='row'>
                            <div className='col'>
                                Direction:
                            </div>
                            <div className='col-7'>
                                <strong>{direction}</strong>
                            </div>
                        </div>}
                </div>
            </div>
        );
    }

    createNominationTables() {
        const newNomination = this.state.newNomination;

        return (
            <div className='v-infinite-width v-table-list'>
                {
                    newNomination && !isEmpty(newNomination) &&
                    <VTable
                        editModeOn={true}
                        key={`New_Nom_${newNomination.title}`}
                        customColumnClasses={{ 0: "v-column-narrow-bold" }}
                        inputType='number'
                        showTotal
                        readonlyColumns={[this.columns.hour.title]}
                        items={newNomination}
                        exportFileName={`New_LT_Nominations_${this.state.activeFilter.counterParty}_${newNomination.title}_${this.state.activeFilter.requestedDate}`}
                        headerButtons={{
                            showExportExcelButton: true,
                        }}
                        onOpenEditMode={() => this.onOpenNominationsEditMode()}
                        onCloseEditMode={() => this.onCloseNominationsEditMode()}
                        onSaveChangesAsync={(newData) => this.onSaveNominationsAsync(newData, { direction: newNomination.title })}
                        onError={(message) => this.showErrorMessage(message)}
                    />
                }
                {this.state.nominationList.map(n => {
                    return (
                        <VTable
                            title={n.direction}
                            key={`Nom_${n.direction}`}
                            customColumnClasses={{ 0: "v-column-narrow-bold" }}
                            inputType='number'
                            showTotal
                            readonlyColumns={[this.columns.hour.title]}
                            items={this.convertNominationJsonToTable(n.direction, n.data)}
                            exportFileName={`LT_Nominations_${this.state.activeFilter.counterParty}_${n.direction}_${this.state.activeFilter.requestedDate}`}
                            headerButtons={{
                                showImportExcelButton: this.editEnabled(),
                                showExportExcelButton: true,
                                showDeleteTableButton: this.editEnabled(),
                                showEditButton: this.editEnabled()
                            }}
                            onOpenEditMode={() => this.onOpenNominationsEditMode()}
                            onCloseEditMode={() => this.onCloseNominationsEditMode()}
                            onSaveChangesAsync={(newData) => this.onSaveNominationsAsync(newData, n)}
                            onError={(message) => this.showErrorMessage(message)}
                            onTableDelete={() => this.onNominationTableDeleteAsync([n.nomination_id])}
                            deleteMessage={this.getConfirmationMessage(portalMessages.LT_PHYSICAL_FLOWS.DELETE_NOM,
                                this.state.activeFilter.counterParty, n.direction)}
                        />

                    );
                })}
            </div>
        );
    }

    renderRegularNominationsTables() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-12'
                        style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                        <button className="btn v-button v-tab-button"
                            disabled={!this.editEnabled() || isEmpty(this.getAvaliableDirections(this.state.activeFilter?.counterParty).filter(d => !d.isDisabled))}
                            onClick={(e) => this.onAddDirectionButtonClick()}>
                            <i aria-hidden="true" className="fa fa-plus fa-fw" />
                                    Add Direction
                                </button>
                    </div>
                </div>
                <div className='row'>
                    {
                        this.state.nominationList &&
                        (
                            (this.state.nominationList.length === 0 && !this.state.newNomination) ?
                                <span
                                    style={{
                                        fontWeight: '600',
                                        margin: '5px',
                                        padding: '5px',
                                    }}>
                                    {portalMessages.LT_PHYSICAL_FLOWS.NO_NOMINATIONS}
                                </span>
                                :
                                this.createNominationTables()
                        )
                    }
                </div>
            </div>
        );
    }

    onGetHeaderClass(colId, colCount) {
        const colorRepeatColCount = 2;

        if ((colId - 1) % colCount > 0)
            return this.headerClasses[(Math.floor((((colId - 1) % colCount) - 1) / colorRepeatColCount)) % colorRepeatColCount];
        return "";
    }

    convertSpecialNominationJsonToTable(nominationList) {
        const nominationDict = {};

        nominationList.forEach(nom => {
            if (!nominationDict[nom.platform])
                nominationDict[nom.platform] = {};
            nominationDict[nom.platform][nom.direction] = nom;
        });

        const items = {
            headers: [
                [this.columns.hour.title],
                [this.columns.cet.title],
                [this.columns.max.title],
                [""],
                [""]
            ],
            values: []
        }

        for (let i = 0; i < 24; i++)
            items.values[i] = [i + 1];

        this.ltSpecialPlatforms.forEach(platform => {
            items.headers[0].push(platform);

            this.ltSpecialDirections.slice(0, 1).forEach(() => {
                items.headers[0].push({ name: platform, html: "" });
            })

            this.ltSpecialDirections.forEach(direction => {
                items.headers[1].push(direction);
                items.headers[2].push(`${this.state.maxCapacities[direction] ?? 0} MW`);
                items.headers[3].push(this.defaultSpecialPeriod);
                items.headers[4].push(this.columns.mwh.title);

                if (nominationDict[platform] && nominationDict[platform][direction]) {
                    const data = nominationDict[platform][direction].data;

                    for (let i = 0; i < data.length; i++)
                        items.values[i].push(data[i].value);
                }
                else {
                    for (let i = 0; i < 24; i++)
                        items.values[i].push(0);
                }
            });
        });

        return items;
    }

    convertSpecialNominationTableToJson(newData, nominationList) {
        const nominationDict = {};
        const newNominationList = [];

        nominationList.forEach(oldNom => {
            if (!nominationDict[oldNom.platform])
                nominationDict[oldNom.platform] = {};
            nominationDict[oldNom.platform][oldNom.direction] = oldNom;
        });

        newData.headers[0].slice(1).forEach((newDataHeader, headerIdx) => {
            const columnId = headerIdx + 1;

            const nomDict = {
                platform: newDataHeader?.name || newDataHeader,
                direction: newData.headers[1][columnId]
            };

            if (nominationDict[nomDict.platform] && nominationDict[nomDict.platform][nomDict.direction]) {
                nomDict.previous_data = nominationDict[nomDict.platform][nomDict.direction]?.data;
                nomDict.nomination_id = nominationDict[nomDict.platform][nomDict.direction]?.nomination_id;
            }

            nomDict.updated_data = newData.values.map(newDataRow => {
                return {
                    hour: newDataRow[0] - 1,
                    value: newDataRow[columnId]
                };
            });

            newNominationList.push(nomDict);
        });

        return newNominationList;
    }

    validateSpecialNominationTableNewValues(nominationList) {
        let totalNewCapacity = 0;

        for (const nomination of nominationList) {
            const maxCapacity = this.state.maxCapacities[nomination.direction];

            for (const hourData of nomination.updated_data) {
                if (isNaN(hourData.value))
                    return portalMessages.LT_PHYSICAL_FLOWS.NOM_NUMBER;
                if (hourData.value < 0)
                    return portalMessages.LT_PHYSICAL_FLOWS.NOM_NO_NEGATIVE;
                if (hourData.value > maxCapacity)
                    return portalMessages.LT_PHYSICAL_FLOWS.TOO_BIG_NOM;

                const numParts = hourData.value.toString().split('.');

                if (numParts.length > 1 && Number(numParts[1]) !== 0)
                    return portalMessages.LT_PHYSICAL_FLOWS.CAP_INTEGER;

                totalNewCapacity += hourData.value;
            }
        }

        if (totalNewCapacity === 0)
            return portalMessages.LT_PHYSICAL_FLOWS.ALL_CAP_ZERO;

        return this.validateSpecialTotals(nominationList);
    }

    validateSpecialTotals(nominationList) {
        const borderTotals = {};
        const empty24Hours = this.getInitial24HoursDict();

        this.ltSpecialDirections.forEach(dir => {
            borderTotals[dir] = { ...empty24Hours };
        });

        nominationList.forEach(nom => {
            nom.updated_data.forEach(row => {
                borderTotals[nom.direction][row.hour] += row.value;
            });
        });

        return this.checkTotals(borderTotals).message;
    }

    async onSaveSpecialNominationsAsync(newData, oldJsondata) {
        let spinnerKey = this.spinner.showSpinner();

        try {
            newData.values = newData.values.map(n => {
                return [n[0], ...n.slice(1).map(c => isNaN(c) ? c : Number(c))]
            });

            const nomination_list = this.convertSpecialNominationTableToJson(newData, oldJsondata);

            const error = this.validateSpecialNominationTableNewValues(nomination_list);

            if (error) {
                this.showErrorMessage(error);
                return false;
            }

            let body = {
                requested_date: this.state.activeFilter.requestedDate,
                counter_party: this.state.activeFilter.counterParty,
                mail_sent: this.state.mailSent,
                nomination_list,
                period: this.longTermPeriodName
            };

            let response;

            try {
                response = await saveNominations(body);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                await this.refreshNominationsAsync();
                alertSuccess(portalMessages.LT_PHYSICAL_FLOWS.NOMINATION_SAVED);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {}, "editValues");
                return false;
            }
        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Save Nominations)');
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    renderSpecialNominationsTable() {
        const nominationList = this.state.nominationList;

        if (!nominationList)
            return null;

        const items = this.convertSpecialNominationJsonToTable(nominationList);

        return (
            <VTable
                key={"specialNominationsTable"}
                title=" "
                items={items}
                inputType='number'
                showTotal
                readonlyColumns={[this.columns.hour.title]}
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                onGetHeaderClass={(colNum, colCount) => this.onGetHeaderClass(colNum, colCount)}
                exportFileName={`Nominations_${this.state.activeFilter.counterParty}_${this.state.activeFilter.requestedDate}`}
                onReset={() => this.setState({ showResetNominationsModal: true })}
                headerButtons={{
                    showExportExcelButton: true,
                    showImportExcelButton: this.editEnabled(),
                    showDeleteTableButton: this.editEnabled(),
                    showEditButton: this.editEnabled()
                }}
                onOpenEditMode={() => this.onOpenNominationsEditMode()}
                onCloseEditMode={() => this.onCloseNominationsEditMode()}
                onSaveChangesAsync={(newData) => this.onSaveSpecialNominationsAsync(newData, nominationList)}
                onError={(message) => this.showErrorMessage(message)}
                onTableDelete={() => this.onNominationTableDeleteAsync(nominationList.map(n => n.nomination_id))}
                deleteMessage={this.getConfirmationMessage(portalMessages.LT_PHYSICAL_FLOWS.DELETE_NOM,
                    this.state.activeFilter.counterParty)}

            />
        );
    }

    renderNominationsTab() {
        return (
            <VTab key='Nominations'
                eventKey='Nominations'
                title='Nominations'>
                {
                    this.getActiveCounterPartyConfig()?.specialTable ?
                        this.renderSpecialNominationsTable()
                        :
                        this.renderRegularNominationsTables()
                }
            </VTab>
        );
    }

    convertDistributionJsonToTable(capacity_id, data, direction) {
        const headers = [
            [this.columns.hour.title, this.columns.capacity.title],
            [this.columns.cet.title, this.columns.mwh.title],
            [
                direction,
                { name: capacity_id ?? "", html: `<span title="${capacity_id}">${this.state.expandCapacityIds ? capacity_id : capacity_id ? capacity_id.slice(0, 7) + "..." : ""}</span>` }
            ],
        ];

        const values = data.map(d => { return [d.hour + 1, d.value] });

        return { headers, values }
    }

    getDistributionTotalsTable(distTables) {
        const headers = [
            [this.columns.hour.title, this.columns.capacity.title],
            [this.columns.cet.title, this.columns.mwh.title],
            ["", ""],
        ];

        const values = distTables[0].data.map(d => { return [d.hour + 1, distTables.reduce((a, b) => a + b.data[d.hour].value, 0)] });

        return { headers, values }
    }

    handleCapacityIdCheckChange() {
        this.setState({ expandCapacityIds: !this.state.expandCapacityIds });
    }

    createDistributionTables(tabName, distTables) {
        return (
            <React.Fragment>
                <div className="v-checkBox v-label checkbox">
                    <label>
                        <input type="checkbox"
                            checked={this.state.expandCapacityIds}
                            onChange={() => this.handleCapacityIdCheckChange()} />
                    Expand Capacity Ids
                </label>
                </div>
                <div className='v-table-list'>
                    {
                        tabName !== this.othersHeader &&
                        <VTable
                            title={`${this.columns.total.title} ${tabName}`}
                            customColumnClasses={{ 0: "v-column-narrow-bold" }}
                            inputType='number'
                            showTotal
                            items={this.getDistributionTotalsTable(distTables)}
                            readonlyColumns={[this.columns.hour.title]}
                            exportFileName={`LT_Distributions_${this.state.activeFilter.counterParty}_Total_${tabName}_${this.state.activeFilter.requestedDate}`}
                            onError={(message) => this.showErrorMessage(message)}
                        />
                    }
                    {distTables.map(d => {
                        return (
                            <VTable
                                title={d.tso}
                                key={`Dist_${d.direction}_${d.capacity_id}`}
                                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                                inputType='number'
                                showTotal
                                readonlyColumns={[this.columns.hour.title]}
                                items={this.convertDistributionJsonToTable(d.capacity_id, d.data, d.direction)}
                                exportFileName={`LT_Distributions_${this.state.activeFilter.counterParty}_${d.direction}_${this.state.activeFilter.requestedDate}`}
                                onError={(message) => this.showErrorMessage(message)}
                            />

                        );
                    })}
                </div>
            </React.Fragment>
        );
    }

    getDistributionGroups(distributionList) {
        const distributionGroups = {};

        distributionList.forEach(d => {
            if (d.direction.startsWith('TR') || d.direction.endsWith('TR')) {
                if (!distributionGroups[d.direction])
                    distributionGroups[d.direction] = [];
                distributionGroups[d.direction].push(d);
            } else {
                if (!distributionGroups[this.othersHeader])
                    distributionGroups[this.othersHeader] = [];
                distributionGroups[this.othersHeader].push(d);
            }
        });

        Object.keys(distributionGroups).forEach(distGroup => {
            distributionGroups[distGroup] = orderBy(distributionGroups[distGroup], ['order', 'period', 'period_start'], ['asc', 'desc', 'asc']);
        });

        return distributionGroups;
    }

    createDistributionTabs() {
        const distributionVerticalTabs = this.getDistributionGroups(this.state.distributionList);

        const verticalTabs = Object.keys(distributionVerticalTabs).filter(dTab => dTab !== this.othersHeader).map(dTab => {
            return (
                <VVerticalTab
                    key={`Dist_Tab_${dTab}`}
                    eventKey={dTab}
                    title={dTab}>
                    {this.createDistributionTables(dTab, distributionVerticalTabs[dTab])}
                </VVerticalTab>
            );
        });

        if (distributionVerticalTabs[this.othersHeader] && !isEmpty(distributionVerticalTabs[this.othersHeader]))
            verticalTabs.push(
                <VVerticalTab
                    key={`Dist_Tab_${this.othersHeader}`}
                    eventKey={this.othersHeader}
                    title={this.othersHeader}>
                    {this.createDistributionTables(this.othersHeader, distributionVerticalTabs[this.othersHeader])}
                </VVerticalTab>
            );

        return (
            <VVerticalTabs>
                {verticalTabs}
            </VVerticalTabs>
        );
    }

    renderDistributionTab() {
        if (this.getActiveCounterPartyConfig()?.specialTable)
            return null;

        return (
            <VTab key='Distribution'
                eventKey='Distribution'
                title='Distribution'>
                <div className='v-infinite-width'>
                    <div className='container'>
                        <div className='row'>
                            {
                                this.state.distributionList &&
                                (
                                    this.state.distributionList.length === 0 ?
                                        <span
                                            style={{
                                                fontWeight: '600',
                                                margin: '5px',
                                                padding: '5px',
                                            }}>
                                            {portalMessages.LT_PHYSICAL_FLOWS.NO_DISTRIBUTION}
                                        </span>
                                        :
                                        this.createDistributionTabs()
                                )
                            }
                        </div>
                    </div>
                </div>
            </VTab>
        );
    }

    getAvaliableDirections(counterParty) {
        if (!counterParty)
            return [];

        let options = this.state.counterParties[counterParty]
            .map(d => {
                return {
                    value: d,
                    label: d,
                    isDisabled: (!this.state.maxCapacities[d] || !!this.state.nominationList.find(n => n.direction === d))
                }
            });

        options = sortBy(
            orderBy(options, ['value']),
            [function (o) { return o.isDisabled.toString(); }]
        );

        return options;
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="LT Physical Flows">
                    <VFilterContainer
                        showActiveFilter
                        activeFilter={this.state.activeFilterToDisplay}
                    >
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                        </div>
                            <div>
                                <VDatePicker
                                    disabled={!this.showButtonEnabled()}
                                    selectedDate={this.state.selectedDate}
                                    onSelectedDateChange={(selectedDate) => this.setState({ selectedDate })}
                                    maxDate={this.getMaxEditableDate()}
                                />
                            </div>
                        </div>
                        
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Counter Party
                        </div>
                            <div>
                                <VDropdown
                                    disabled={!this.showButtonEnabled()}
                                    width="large"
                                    options={this.state.counterPartyOptions}
                                    value={this.state.selectedCounterPartyOption}
                                    onSelectedOptionChange={(selectedCounterPartyOption) => {
                                        this.setState({
                                            selectedCounterParty: selectedCounterPartyOption.value,
                                            selectedCounterPartyOption: selectedCounterPartyOption
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                disabled={!this.showButtonEnabled()}
                                className="btn v-cancel-button v-filter-button"
                                onClick={() => this.onClearButtonClick()}>
                                <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                        </button>
                            <button
                                disabled={!this.showButtonEnabled()}
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={() => this.onShowButtonClick()}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                        </button>
                        </div>
                    </VFilterContainer>
                    <VMainContainer>
                        <div className='col-12' style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                            <button className="btn v-button v-tab-button"
                                disabled={!this.exportAllEnabled()}
                                onClick={() => this.exportAll()}>
                                <i aria-hidden="true" className="fa fa-cloud-download fa-fw" />
                                    Export All
                                </button>
                            <button className="btn v-button v-tab-button"
                                disabled={!this.sendMailEnabled()}
                                onClick={() => this.sendTestMail()}>
                                <i aria-hidden="true" className={`fa ${this.state.mailSent ? "fa-envelope-o" : "fa-envelope"} fa-fw`} />
                                    Send Mail To Me
                                </button>
                            <button className="btn v-button v-tab-button"
                                disabled={!this.sendMailEnabled()}
                                onClick={() => this.sendCounterPartyMail()}>
                                <i aria-hidden="true" className={`fa ${this.state.mailSent ? "fa-envelope-o" : "fa-envelope"} fa-fw`} />
                                    Send Mail To Counter Party
                                </button>
                        </div>
                        <VTabs disableAllTabs={!this.showButtonEnabled()}>
                            {this.renderNominationsTab()}
                            {this.renderDistributionTab()}
                        </VTabs>
                    </VMainContainer>
                </VContentContainer>
                <NeedRefreshModal
                    show={this.state.showNeedsRefresModal}
                    message={portalMessages.COULD_NOT_GET_C_PARTIES}
                />
                {(this.state.addDirectionModal && this.state.addDirectionModal.counterParty) &&
                    <AddDirectionModal
                        show={this.state.addDirectionModal.show}
                        directions={this.getAvaliableDirections(this.state.addDirectionModal.counterParty)}
                        counterParty={this.state.addDirectionModal.counterParty}
                        onAdd={(counterParty, selectedDirection) => this.onAddDirection(selectedDirection)}
                        onCancel={() => this.onCancelAddDirection()}
                    />}
                <ConfirmationModal
                    show={this.state.showNoOperationMailModal}
                    message={this.getConfirmationMessage(portalMessages.LT_PHYSICAL_FLOWS.NO_OP_MAIL, this.state.activeFilter?.counterParty)}
                    cancelText='Cancel'
                    onCancel={() => this.onCloseNoOpMailConfirmModal()}
                    onHide={() => this.onCloseNoOpMailConfirmModal()}
                    confirmText='Send'
                    onConfirm={async () => await this.onConfirmNoOpMail()}
                />
            </React.Fragment>
        );
    }
}

export default LTPhysicalFlows;