import './TBaseModal.css';

import React from 'react';
import { Modal } from 'react-bootstrap';

const TBaseModal = ({ show, onHide, small, title, body, actions, dialogClassName, closeButton = true }) => {
    const size = small ? 'sm' : 'lg';

    return (
        <Modal
            dialogClassName={`t-modal ${dialogClassName ? dialogClassName : ""}`}
            show={show}
            onHide={onHide}
            size={size}>
            <Modal.Header closeButton={closeButton}>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            {
                body &&
                <Modal.Body className="t-modal-body">
                    {body}
                </Modal.Body>
            }
            {
                actions &&
                <Modal.Footer>
                    {actions}
                </Modal.Footer>
            }
        </Modal>
    );
}

export default TBaseModal;