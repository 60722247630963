import React from 'react';
import * as Space from "react-spaces";
import './TContentDetailContainer.css';


const setComponent = (leftSideComponent, rightSideComponent) => {

    if (leftSideComponent && rightSideComponent) {
        return (
            <Space.Fixed height={1000}>
                <Space.LeftResizable
                    size="60%"
                    minimumSize={150}
                    maximumSize={1500}
                    scrollable={true}
                >
                    {leftSideComponent}
                </Space.LeftResizable>
                <Space.Fill
                    scrollable={true}
                    minimumSize={50}
                    maximumSize={1000}
                >
                    {rightSideComponent}
                </Space.Fill>
            </Space.Fixed>
        );

    } else if (leftSideComponent) {
        return (
            <Space.Fixed height={1000} scrollable={true}>
                {leftSideComponent}
            </Space.Fixed>
        );

    } else if (rightSideComponent) {
        return (
            <Space.Fixed height={1000} scrollable={true}>
                {leftSideComponent}
            </Space.Fixed>
        );

    } else {
        return null;
    }

}


const TContentDetailContainer = ({ leftSideComponent, rightSideComponent }) => {
    return (
        setComponent(leftSideComponent, rightSideComponent)
    );
}

TContentDetailContainer.defaultProps = {
    leftSideComponent: undefined,
    rightSideComponent: undefined
}


export default TContentDetailContainer;