import React from 'react';
import Divider from '@material-ui/core/Divider';
import Checkbox from "@material-ui/core/Checkbox";
import AccordionActions from '@material-ui/core/AccordionActions';
import MultipleSelect from '../../components/MultipleSelect/MultipleSelect';
import TDatePicker from '../../components/TDatePicker/TDatePicker';
import { TDatePickerTypes } from '../../components/TDatePicker/TDatePicker';
import { DELIVERY_TYPE_ARR, CONTRACT_TYPE_ARR, TRADING_VENUE_ARR, PRODUCT_ARR, TRADER_ARR, PRICE_SOURCES, PRICE_SOURCE_ARR } from '../../utils/constants';
import moment from 'moment';
import { getLocalStorage, setLocalStorage } from '../../../helpers/localStorageHelper';
import TDropdown from '../../components/TDropdown/TDropdown';

const localStorageConfig = {
    book: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key:"selectedBooks",
        matchKey: "id"
    },
    counterParty: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key:"selectedCounterParties",
        matchKey: "id"
    },
    market: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key: "selectedMarkets",
        matchKey: "id"
    },
    company: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key: "selectedCompanies",
        matchKey: "id"
    },
    product: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key:"selectedProducts",
        matchKey: "value"
    },
    tradingVenue: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key: "selectedTradingVenues",
        matchKey: "value"
    },
    trader: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key:"selectedTraders",
        matchKey: "value"
    },
    deliveryType: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key: "selectedDeliveryTypes",
        matchKey: "value"
    },
    contractType: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key: "selectedContractTypes",
        matchKey: "value"
    },
    startDate: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key: "selectedStartDate",
    },
    endDate: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key: "selectedEndDate",
    },
    priceSource: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key: "selectedPriceSource",
    },
    cost: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key: "addCosts",
    },
    adjustment: {
        ownerPage: "dailyLiquidation",
        ownerElement: "filters",
        key: "includeAdjustment",
    }
}

class DailyLiquidationFilter extends React.Component {
    state = {
        selectedMarkets: this.getOrElseLocalStorageAsList(localStorageConfig.market, this.props.marketNames),
        selectedCompanies: this.getOrElseLocalStorageAsList(localStorageConfig.company, this.props.counterPartyNames.filter(c => c.type === "Vitus")),
        selectedCounterParties: this.getOrElseLocalStorageAsList(localStorageConfig.counterParty, this.props.counterPartyNames.filter(c => c.type !== "Vitus")),
        selectedProducts: this.getOrElseLocalStorageAsList(localStorageConfig.product, PRODUCT_ARR),
        selectedTradingVenues: this.getOrElseLocalStorageAsList(localStorageConfig.tradingVenue, TRADING_VENUE_ARR),
        selectedTraders: this.getOrElseLocalStorageAsList(localStorageConfig.trader, TRADER_ARR),
        selectedDeliveryTypes: this.getOrElseLocalStorageAsList(localStorageConfig.deliveryType, DELIVERY_TYPE_ARR),
        selectedContractTypes: this.getOrElseLocalStorageAsList(localStorageConfig.contractType, CONTRACT_TYPE_ARR),
        selectedBooks: this.getOrElseLocalStorageAsList(localStorageConfig.book, this.props.bookNames),
        selectedStartDate: new Date((new Date().setDate(new Date().getDate() + 1))).setHours(0,0,0,0),
        selectedEndDate: new Date((new Date().setDate(new Date().getDate() + 1))),
        selectedPriceSource: this.getOrElseLocalStorage(localStorageConfig.priceSource, PRICE_SOURCES.vitus),
        bookNames: this.props.bookNames,
        counterPartyNames: this.props.counterPartyNames,
        marketNames: this.props.marketNames,
        addCostChecked: this.getOrElseLocalStorage(localStorageConfig.cost, false),
        includeAdjustment: this.getOrElseLocalStorage(localStorageConfig.adjustment, true)
    };

    getOrElseLocalStorage(config, defaultValue) {
        let localStorageValue = getLocalStorage(config.ownerPage, config.ownerElement, config.key)
        if (localStorageValue){
            return localStorageValue
        }

        return defaultValue
    }

    getOrElseLocalStorageAsList(config, currentData) {
        let localStorageList = getLocalStorage(config.ownerPage, config.ownerElement, config.key)
       
        if (!localStorageList || !localStorageList.length) {
            return currentData
        } else {
            let existingList = currentData.filter(x => localStorageList.includes(x[config.matchKey]))

            if (existingList.length) {
                return existingList
            } else {
                return currentData
            }
        }
    }

    setPnlLocalStorageAsList(config, data) {
        let dataList = data.map(x => x[config.matchKey])
        setLocalStorage(config.ownerPage, config.ownerElement, config.key, dataList)
    }

    setPnlLocalStorage(config, data) {
        setLocalStorage(config.ownerPage, config.ownerElement, config.key, data)
    }

    componentDidMount() {
        this.onShowButtonClick();
    }

    onShowButtonClick() {
        let filter = {
            start_date: moment(this.state.selectedStartDate).utcOffset(0, true).format(),
            end_date: moment(this.state.selectedEndDate).utcOffset(0, true).format(),
            book_list: this.state.selectedBooks?.map(b => b.id),
            fetch_cb_pnl : this.state.selectedBooks?.length ? this.state.selectedBooks.filter(b => b.value === "Crossborder").length : true,
            has_vitus_capital : this.state.selectedCompanies?.find(c => c.value === "Vitus Capital"),
            has_monolith_capital : this.state.selectedCompanies?.find(c => c.value === "Monolith Capital"),
            has_vitus_commodities : this.state.selectedCompanies?.find(c => c.value === "Vitus Commodities"),
            product_list: this.state.selectedProducts?.map(p => p.value),
            trader_list: this.state.selectedTraders?.map(t => t.value),
            market_list: this.state.selectedMarkets?.map(m => m.id),
            company_list: this.state.selectedCompanies?.map(c => c.id),
            counter_party_list: this.state.selectedCounterParties?.map(c => c.id),
            trading_venue_list: this.state.selectedTradingVenues?.map(t => t.value),
            delivery_type_list: this.state.selectedDeliveryTypes?.map(d => d.value),
            contract_type_list: this.state.selectedContractTypes?.map(c => c.value),
            price_source: this.state.selectedPriceSource.value,
            add_costs: this.state.addCostChecked,
            include_adjustment: this.state.includeAdjustment
        }
        
        this.props.onShowButtonClick(filter)
    }

    onBookChange = books => {
        this.setState({ selectedBooks: books }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.book, books);
        });
    }

    onMarketChange = selectedMarkets => {
        this.setState({ selectedMarkets: selectedMarkets }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.market, selectedMarkets);
        });
    }

    onCompanyChange = companies => {
        this.setState({ selectedCompanies: companies }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.company, companies);
        });
    }

    onCompanyCounterParty = counterParties => {
        this.setState({ selectedCounterParties: counterParties }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.counterParty, counterParties);
        });
    }

    onCompanyChange = companies => {
        this.setState({ selectedCompanies: companies }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.company, companies);
        });
    }

    onChangeProduct = products => {
        this.setState({ selectedProducts: products }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.product, products);
        })
    }

    onChangeTradingVenue = tradingVenues => {
        this.setState({ selectedTradingVenues: tradingVenues }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.tradingVenue, tradingVenues);
        })
    }

    onChangeTrader = traders => {
        this.setState({ selectedTraders: traders }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.trader, traders);
        })
    }

    onChangeDeliveryTypes = deliveryTypes => {
        this.setState({ selectedDeliveryTypes: deliveryTypes }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.deliveryType, deliveryTypes);
        })
    }

    onChangeContractTypes = contractTypes => {
        this.setState({ selectedContractTypes: contractTypes }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.contractType, contractTypes);
        })
    }

    onChangeStartDate = startDate => {
        this.setState({ selectedStartDate: startDate }, () => {
            this.setPnlLocalStorage(localStorageConfig.startDate, startDate);
        })
    }

    onChangeEndDate = endDate => {
        this.setState({ selectedEndDate: endDate }, () => {
            this.setPnlLocalStorage(localStorageConfig.endDate, endDate);
        })
    }

    onChangePriceSource = source => {
        this.setState({ selectedPriceSource: source }, () => {
            this.setPnlLocalStorage(localStorageConfig.priceSource, source);
         })
    }

    onChangeAddCosts = (event) => {
        let cheched = event.target.checked
        this.setState({addCostChecked: cheched}, () => {
            this.setPnlLocalStorage(localStorageConfig.cost, cheched);
        });
    }

    onChangeIncludeAdjustments = (event) => {
        let cheched = event.target.checked
        this.setState({includeAdjustment: cheched}, () => {
            this.setPnlLocalStorage(localStorageConfig.adjustment, cheched);
        });
    }

    render() {
        return (
            <div style={{ "marginTop": "10px" }}>
                <div style={{ "marginTop": "10px", display: "flex", flexDirection: "row" }}>
                    <div style={{ "margin": "5px", "width": "15%" }}>
                        <MultipleSelect
                            title="Books"
                            items={this.props.bookNames}
                            onChange={this.onBookChange}
                            selectedItems={this.state.selectedBooks}
                        >
                        </MultipleSelect>
                        <MultipleSelect
                            title="Products"
                            items={PRODUCT_ARR}
                            onChange={this.onChangeProduct}
                            selectedItems={this.state.selectedProducts}
                        >
                        </MultipleSelect>
                        <MultipleSelect
                            title="Trader"
                            items={TRADER_ARR}
                            onChange={this.onChangeTrader}
                            selectedItems={this.state.selectedTraders}
                        >
                        </MultipleSelect>
                        <MultipleSelect
                            title="Trading Venue"
                            items={TRADING_VENUE_ARR}
                            onChange={this.onChangeTradingVenue}
                            selectedItems={this.state.selectedTradingVenues}
                        >
                        </MultipleSelect>
                        <MultipleSelect
                            title="Delivery Type"
                            items={DELIVERY_TYPE_ARR}
                            onChange={this.onChangeDeliveryTypes}
                            selectedItems={this.state.selectedDeliveryTypes}
                        >
                        </MultipleSelect>
                    </div>
                    <div style={{ "margin": "5px", "width": "30%" }}>
                        <MultipleSelect
                            title="Markets"
                            items={this.props.marketNames}
                            onChange={this.onMarketChange}
                            selectedItems={this.state.selectedMarkets}
                        >
                        </MultipleSelect>
                        <MultipleSelect
                            title="Companies"
                            items={this.props.counterPartyNames.filter(c => c.type === "Vitus")}
                            onChange={this.onCompanyChange}
                            selectedItems={this.state.selectedCompanies}
                        >
                        </MultipleSelect>
                        <MultipleSelect
                            title="Counter Parties"
                            items={this.props.counterPartyNames.filter(c => c.type !== "Vitus")}
                            onChange={this.onCompanyCounterParty}
                            selectedItems={this.state.selectedCounterParties}
                        >
                        </MultipleSelect>
                        <MultipleSelect
                            title="Contract Type"
                            items={CONTRACT_TYPE_ARR}
                            onChange={this.onChangeContractTypes}
                            selectedItems={this.state.selectedContractTypes}
                        >
                        </MultipleSelect>

                    </div>
                    <div style={{ "margin": "5px" }}>
                        
                        <TDatePicker
                            type={TDatePickerTypes.day}
                            label={"Start Date"}
                            selectedDate={this.state.selectedStartDate}
                            onSelectedDateChange={this.onChangeStartDate}
                        />

                        <TDatePicker
                            type={TDatePickerTypes.day}
                            label={"End Date"}
                            selectedDate={this.state.selectedEndDate}
                            onSelectedDateChange={this.onChangeEndDate}
                        />
                    </div>
                    <div style={{ "margin": "10px" }}>
                        
                        <div className="t-dropdown-content">
                            <div className="t-dropdown-label">
                                {"Add Costs"}
                            </div>
                            <div style={{display: "contents"}}>
                                <Checkbox
                                    color="primary"
                                    checked={this.state.addCostChecked}
                                    onChange={this.onChangeAddCosts}
                                    name="add_cost"
                                />
                            </div>
                        </div>
                        <div className="t-dropdown-content">
                            <div className="t-dropdown-label">
                                {"Include Adjustments"}
                            </div>
                            <div style={{display: "contents"}}>
                                <Checkbox
                                    color="primary"
                                    checked={this.state.includeAdjustment}
                                    onChange={this.onChangeIncludeAdjustments}
                                    name="include_adjustment"
                                />
                            </div>
                        </div>
                        <TDropdown
                            label='Price Source'
                            options={PRICE_SOURCE_ARR}
                            width="small"
                            value={this.state.selectedPriceSource}
                            defaultValue={this.state.selectedPriceSource}
                            onSelectedOptionChange={this.onChangePriceSource}
                            disabled={this.state.readOnly}
                        />
                    </div>
                </div>

                <div style={{ "margin": "12px", "paddingRight": "250px" }}>
                    <Divider />
                    <AccordionActions>
                        <button
                            size="small"
                            className="btn t-orange-button"
                            onClick={() => this.onShowButtonClick()}
                        >
                            Apply
                        </button>
                    </AccordionActions>
                </div>

            </div>
        );
    }
}

export default DailyLiquidationFilter;