import * as XLSX from 'xlsx';

export class ExchangeResultsExcelHelper {

    /*
        Abbreviations
        -------------
        s -> start cell
        e -> end cell
        c -> column
        r- > row

        Column Types
        ------------
        buy  -> shows buy values
        sell -> shows sell values
        all  -> shows both buy and cell values in same column. 
            if value negative, sell is positive of it and buy is 0.
            if value positive, it is a buy value and sell is 0.

        Note
        ----
        There is no sell for Green's exchange. All sell values of Green's is set 0.
    
    */

    constructor(file) {
        this.file = file;
    }

    excelAddresses = {
        "Energovia": {
            "IBEX": {
                "buy": { "s": { "c": 2, "r": 7 }, "e": { "c": 2, "r": 30 } },
                "sell": { "s": { "c": 3, "r": 7 }, "e": { "c": 3, "r": 30 } }
            },
            "SEEPEX": {
                "buy": { "s": { "c": 7, "r": 7 }, "e": { "c": 7, "r": 30 } },
                "sell": { "s": { "c": 8, "r": 7 }, "e": { "c": 8, "r": 30 } }
            },
            "HUPX": {
                "buy": { "s": { "c": 12, "r": 7 }, "e": { "c": 12, "r": 30 } },
                "sell": { "s": { "c": 13, "r": 7 }, "e": { "c": 13, "r": 30 } }
            },
            "CROPX": {
                "buy": { "s": { "c": 17, "r": 7 }, "e": { "c": 17, "r": 30 } },
                "sell": { "s": { "c": 18, "r": 7 }, "e": { "c": 18, "r": 30 } }
            },
            "IPEX": {
                "buy": { "s": { "c": 22, "r": 7 }, "e": { "c": 22, "r": 30 } },
                "sell": { "s": { "c": 23, "r": 7 }, "e": { "c": 23, "r": 30 } }
            },
            "MEPX": {
                "buy": { "s": { "c": 28, "r": 7 }, "e": { "c": 28, "r": 30 } },
                "sell": { "s": { "c": 29, "r": 7 }, "e": { "c": 29, "r": 30 } }
            },
            "OPCOM": {
                "buy": { "s": { "c": 38, "r": 7 }, "e": { "c": 38, "r": 30 } },
                "sell": { "s": { "c": 39, "r": 7 }, "e": { "c": 39, "r": 30 } }
            },
        },

        "Axpo": {
            "IBEX": {
                "all": { "s": { "c": 2, "r": 4 }, "e": { "c": 2, "r": 27 } },
            }
        }
    }

    excelDataRepresentations = {
        "Energiova": {
            exchange: null,
            buy: null,
            sell: null
        },

        "Axpo": {
            exchange: null,
            all: null
        }
    }

    excelSheetNames = {
        "Energovia": "Power_exchanges",
        "Axpo": "Exchange Result"
    }

    loadExcel(selectedCounterParty, expectedExchanges, updateTableAfterSpecialFileImport) {

        try {

            var reader = new FileReader();
            var exchangeAddressesOfSelectedCounterParty = this.excelAddresses[selectedCounterParty];
            var dataRepresentation = this.excelDataRepresentations[selectedCounterParty];
            var exchangeValues = [];
            var dayAheadValues = [];

            reader.onload = (e) => {

                try {

                    var data = e.target.result;
                    var fetched = XLSX.read(data, { type: 'binary' });
                    const ws = fetched.Sheets[this.excelSheetNames[selectedCounterParty]];

                    expectedExchanges.forEach(exchange => {

                        if (exchange in exchangeAddressesOfSelectedCounterParty) {

                            let exchangeValueObj = { ...dataRepresentation };
                            exchangeValueObj.exchange = exchange;

                            Object.keys(exchangeAddressesOfSelectedCounterParty[exchange]).forEach(columnType => {

                                let range = exchangeAddressesOfSelectedCounterParty[exchange][columnType];
                                let excelColumnValues = [];

                                for (var R = range.s.r; R <= range.e.r; ++R) {
                                    for (var C = range.s.c; C <= range.e.c; ++C) {
                                        var cell_address = { c: C, r: R };
                                        var data = XLSX.utils.encode_cell(cell_address);
                                        
                                        excelColumnValues.push(ws[data] ? ws[data] : {t: "n", v: 0, w: "0"});
                                    }
                                }
                                exchangeValueObj[columnType] = excelColumnValues;

                            });

                            exchangeValues.push(exchangeValueObj);
                        }
                    });

                    if (selectedCounterParty === "Energovia")   
                        for (var R = 7; R <= 30; ++R) {
                            for (var C = 9; C <= 9; ++C) {
                                var cell_address = { c: C, r: R };
                                var data = XLSX.utils.encode_cell(cell_address);
                                
                                if (ws[data])
                                    dayAheadValues.push(ws[data]);
                            }
                        }
                    
                    if (selectedCounterParty === "Axpo")
                        this.setAxpoBuySellValues(exchangeValues);

                    else if (selectedCounterParty === "Green")
                        this.setGreenSellValues(exchangeValues);


                    updateTableAfterSpecialFileImport(exchangeValues, dayAheadValues);

                } catch (error) {
                    updateTableAfterSpecialFileImport([]);
                }

            };

            reader.readAsBinaryString(this.file);

        } catch (error) {
            updateTableAfterSpecialFileImport([]);
        }

    }

    setAxpoBuySellValues(exchangeValues) {
        let buy = [];
        let sell = [];

        exchangeValues[0].all.forEach(i => {

            if (i.v > 0) {
                buy.push({ ...i });

                let valueForSell = { ...i };
                valueForSell.v = 0;
                valueForSell.w = "0";

                sell.push({ ...valueForSell });

            } else if (i.v < 0) {

                let valueForBuy = { ...i };
                valueForBuy.v = 0;
                valueForBuy.w = "0";

                buy.push({ ...valueForBuy });

                let valueForSell = { ...i };
                valueForSell.v = -i.v;
                valueForSell.w = `${valueForSell.v}`;

                sell.push({ ...valueForSell });

            } else {
                buy.push({ ...i });
                sell.push({ ...i });
            }
        });

        exchangeValues[0].buy = [...buy];
        exchangeValues[0].sell = [...sell];
    }

};