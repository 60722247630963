import './TFileUploadIcon.css'

import React from 'react';
import { generateUuid } from '../../utils/common';

const TFileUploadIcon = ({ className, disabled, onFileChanged, title, labeltext, ignoreSameFile = false, accept = [], size = 'fa-lg' }) => {
    const fileChanged = (e) => {
        if (!e.target.files || e.target.files.length < 1)
            return;

        onFileChanged(e);

        if (!ignoreSameFile)
            e.target.value = null;
    };

    const id = generateUuid();

    return (
        <React.Fragment>
            <input type="file"
                disabled={disabled}
                accept={(!accept || accept.length === 0) ? "*" : accept.join(',')}
                onChange={fileChanged}
                className="t-file-upload-icon"
                id={id} />
            <label htmlFor={id}
                className={`${className ? className : ""} ${disabled ? " t-disabled-link-button" : " t-link-button"}`}
                disabled={disabled}>
                <i aria-hidden="true"
                    disabled={disabled}
                    title={title}
                    className={`fa fa-cloud-upload fa-fw ${size} ${disabled ? "" : "t-icon-button"}`} />
                <span className="t-upload-file-label t-font t-font-size">{labeltext}</span>
            </label>
        </React.Fragment>
    );
};

export default TFileUploadIcon;