import MarginAnalysis from '../pages/MarginAnalysis/MarginAnalysis';
import Parameters from '../pages/Parameters/Parameters';
import AuctionBids from '../pages/AuctionBids/AuctionBids';
import AuctionAllocations from '../pages/AuctionAllocations/AuctionAllocations';
import TestPage1 from '../pages/TestPage1/TestPage1';
import TestPage2 from '../pages/TestPage2/TestPage2';
import TestPage3 from '../pages/TestPage3/TestPage3';
import ExchangeOffers from '../pages/ExchangeOffers/ExchangeOffers';
import ExchangeResults from '../pages/ExchangeResults/ExchangeResults';
import PhysicalFlows from '../pages/PhysicalFlows/PhysicalFlows';
import LTPhysicalFlows from '../pages/LTPhysicalFlows/LTPhysicalFlows';
import LTAuctionAllocations from '../pages/LTAuctionAllocations/LTAuctionAllocations';
import PnLReport from '../pages/PnLReport/PnLReport';
import CustomPnl from '../pages/CustomPnl/CustomPnl';
import DailyLiquidation from '../pages/DailyLiquidation/DailyLiquidation';
import IntradayResults from '../pages/IntradayResults/IntradayResults';
import ServiceCosts from '../pages/ServiceCosts/ServiceCosts';
import PowerSchedules from '../pages/PowerSchedules/PowerSchedules';
import DailyEnergyCosts from '../pages/DailyEnergyCosts/DailyEnergyCosts';
import TeiasDailyEntries from '../pages/TeiasDailyEntries/TeiasDailyEntries';
import CrossborderInvoice from '../pages/CrossborderInvoice/CrossborderInvoice';


const pageClasses = {
    MarginAnalysis,
    Parameters,
    AuctionBids,
    AuctionAllocations,
    TestPage1,
    TestPage2,
    TestPage3,
    ExchangeOffers,
    ExchangeResults,
    PhysicalFlows,
    LTPhysicalFlows,
    LTAuctionAllocations,
    PnLReport,
    CustomPnl,
    DailyLiquidation,
    IntradayResults,
    ServiceCosts,
    PowerSchedules,
    DailyEnergyCosts,
    TeiasDailyEntries,
    CrossborderInvoice
}

class PageFactory {
    static getPageClass(pageClassName) {
        if (!pageClasses[pageClassName])
            throw new Error(pageClassName + " is not defined in PageFactory");
        return pageClasses[pageClassName];
    }
}

export default PageFactory;