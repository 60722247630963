import React from 'react';

import history from '../../../history';
import { getBooks, saveBooks, deleteBook } from '../../api/services';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import { messages } from '../../utils/messages';
import { handleApiError, alertError, alertSuccess } from '../../utils/errorHandler';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer'
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import BookList from '../Book/BookList';
import BookDetailModal from '../../modals/Book/BookDetailModal';
import TConfirmationModal from '../../components/TConfirmationModal/TConfirmationModal';

class BookMain extends React.Component {

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        data: [],
        selectedBook: '',
        selectedBookDescription: '',
        showBookDetailModal: false,
        showBookDeleteConfirmationModal: false
    }

    componentDidMount() {
        this.callGetBookServiceAsync();
    }

    async callGetBookServiceAsync() {

        this.spinner.showSpinner('callGetBookServiceAsync');

        getBooks()
            .then(response => {

                if (response.data.success)
                    this.setState({ data: response.data.success })
                else
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);

            }, error => {
                handleApiError(error);
            })
            .finally(() => {
                this.spinner.hideSpinner('callGetBookServiceAsync');
            });
    }

    createSaveBookRequestBody(name, description) {
        return {
            data: [
                {
                    name,
                    description
                }
            ]
        };
    }

    callSaveBookServiceAsync = async (bookName, bookDescription) => {

        this.spinner.showSpinner('callSaveBookAsync');

        try {

            let response = await saveBooks(this.createSaveBookRequestBody(bookName, bookDescription));

            if (response.data.success) {

                alertSuccess(messages.BOOK.SUCCESSFULL_BOOK_SAVE);

                this.hideBookDetailModal();

                await this.callGetBookServiceAsync();

            } else {
                alertError(messages.UNEXPECTED_ERROR_OCCURED);
            }

        } catch (error) {
            alertError(messages.NETWORK_ERROR);

        } finally {
            this.spinner.hideSpinner('callSaveBookAsync');
        }

    }

    callDeleteBookServiceAsync = async () => {

        this.spinner.showSpinner('callDeleteBookServiceAsync');

        let requestBody = { book_name: this.state.selectedBook };

        try {

            let response = await deleteBook(requestBody);

            if (response.data.success) {

                alertSuccess(messages.BOOK.SUCCESSFULL_BOOK_DELETE);

                this.hideDeleteBookConfirmationModal();

                await this.callGetBookServiceAsync();

            } else {
                alertError(messages.UNEXPECTED_ERROR_OCCURED);
            }

        } catch (error) {
            alertError(messages.NETWORK_ERROR);

        } finally {
            this.spinner.hideSpinner('callDeleteBookServiceAsync');
        }
    }

    onAddNewBookButtonClick = () => this.setState({ selectedBook: '', selectedBookDescription: '', showBookDetailModal: true });

    onEditRowClick = row => this.setState({ selectedBook: row.name, selectedBookDescription: row.description, showBookDetailModal: true });

    hideBookDetailModal = () => this.setState({ showBookDetailModal: false, selectedBook: '', selectedBookDescription: '' });

    hideDeleteBookConfirmationModal = () => this.setState({ showBookDeleteConfirmationModal: false, selectedBook: '', selectedBookDescription: '' });

    showBookDeleteConfirmationModal = row => this.setState({ selectedBook: row.name, selectedBookDescription: row.description, showBookDeleteConfirmationModal: true });

    getLeftSide() {
        return (
            <LeftSideContainer
                contentDefaultExpanded
                contentLabel="Books"
                contentComponent={
                    <div className="container">
                        <div className="row">
                            <div className="col-4">
                                <button
                                    type="button"
                                    className="btn t-orange-button"
                                    onClick={this.onAddNewBookButtonClick}>
                                    Add New Book
                            </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <BookList
                                    data={this.state.data}
                                    onEditRowClick={this.onEditRowClick}
                                    onDeleteRowClick={this.showBookDeleteConfirmationModal}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                <TContentContainer>
                    <TContentDetailContainer
                        leftSideComponent={this.getLeftSide()}
                    >
                    </TContentDetailContainer>
                </TContentContainer>
                {
                    this.state.showBookDetailModal &&
                    <BookDetailModal
                        show={this.state.showBookDetailModal}
                        onHide={this.hideBookDetailModal}
                        onCancel={this.hideBookDetailModal}
                        onSaveBook={this.callSaveBookServiceAsync}
                        onDeleteBook={this.showBookDeleteConfirmationModal}
                        bookName={this.state.selectedBook}
                        bookDescription={this.state.selectedBookDescription} />
                }
                {
                    this.state.showBookDeleteConfirmationModal &&
                    <TConfirmationModal
                        show={this.state.showBookDeleteConfirmationModal}
                        message={messages.BOOK.CONFIRM_DELETE.replace("[BOOK]", this.state.selectedBook)}
                        cancelText='Cancel'
                        onHide={this.hideDeleteBookConfirmationModal}
                        onCancel={this.hideDeleteBookConfirmationModal}
                        confirmText='Delete'
                        onConfirm={this.callDeleteBookServiceAsync}
                    />
                }
            </React.Fragment>
        );
    }

}

export default BookMain;